import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Complex Sentences"

const complexone = [
  {"Kanji":"彼女と僕は映画を見に行って、買い物をして、家に帰った","Hiragana":"かのじょとぼくはえいがをみにいって、かいものをして、いえにかえった","Romaji":"Kanojo to boku wa eiga o mi ni itte, kaimono o shite, ie ni kaetta","audio":"kanojo to boku wa eiga o mi ni itte kaimono o shite ie ni kaetta","English Meaning":"She and I went to see a movie and went shopping and then went home."}
]
const complextwo = [
  {"Kanji":"明日の朝、六時に起きて、宿題をして、朝ご飯を食べて、学校に行きます","Hiragana":"あしたのあさ、ろくじにおきて、しゅくだいをして、あさごはんをたべて、がっこうにいきます","Romaji":"Ashita no asa, rokuji ni okite, shukudai o shite, asagohan o tabete, gakkou ni ikimasu","audio":"ashita no asa rokuji ni okite shukudai o shite asagohan o tabete gakkou ni ikimasu","English Meaning":"Tomorrow morning, I'm going to get up at 6am, do my homework, eat breakfast, and then go to school."},
 {"Kanji":"彼女が綺麗で、学生はみんな彼女を気に入って彼女と友達に成りたい","Hiragana":"かのじょがきれいで、がくせいはみんなかのじょをきにいってかのじょとともだちになりたい","Romaji":"Kanojo ga kirei de, gakusei wa minna kanojo o ki ni itte tomodachi ni naritai","audio":"kanojo ga kirei de gakusei wa minna kanojo o ki ni itte tomodachi ni naritai","English Meaning":"She is very pretty - all of the students really like her and want to be friends with her."},
 {"Kanji":"クリスマスが一番好きな日です。　朝、早く起きて、プレゼントを開けて、長い時間遊んで、夕食は七面鳥を食べて過ごします。","Hiragana":"クリスマスがいちばんすきなひです。　あさ、はやくおきて、プレゼントをあけて、ながいじかんあそんで、ゆうしょくはしちめんちょうをたべてすごします。","Romaji":"Kurisumasu ga ichiban suki na hi desu. Asa, hayaku okite, purezento o akete, nagai jikan asonde, yuushoku wa shichimenchou o tabete sugoshimasu.","audio":"kurisumasu ga ichiban suki na hi desu asa hayaku okite purezento o akete nagai jikan asonde yuushoku wa shichimenchou o tabete sugoshimasu","English Meaning":"Christmas is my favorite day.  I spend it by getting up early in the morning, opening presents, playing for a long time, and eating a turkey for dinner."},
 {"Kanji":"雨が降る時は、外で遊ばないで、公園にも行かないで、一人でテレビを見るだけです","Hiragana":"あめがふるときは、そとであそばないで、こうえんにもいかないで、ひとりでテレビをみるだけです","Romaji":"Ame ga furu toki wa, soto de asobanaide, kouen ni mo ikanaide, hitori de terebi o miru dake desu","audio":"ame ga furu toki wa soto de asobanaide kouen ni mo ikanaide hitori de terebi o miru dake desu","English Meaning":"When it's raining, I don't play outside, I don't go to the park, I just watch TV by myself.(You might notice in this sentence that the verb \"furu\" is modifying \"toki\".  Verbs can do that in Japanese and it is very regularly done.)"}
]
const complexthree = [
  {"Kanji":"映画","Hiragana":"えいが","Romaji":"eiga","English Meaning":"movie","audio":"eiga"},
 {"Kanji":"Base 2 + に行く","Hiragana":"Base 2 + にいく","Romaji":"Base 2 + ni iku","English Meaning":"go to verb, go and verb","audio":"ni iku"},
 {"Kanji":"買い物","Hiragana":"かいもの","Romaji":"kaimono","English Meaning":"shopping","audio":"kaimono"},
 {"Kanji":"家","Hiragana":"いえ","Romaji":"ie","English Meaning":"house, home","audio":"ie"},
 {"Kanji":"帰る","Hiragana":"かえる","Romaji":"kaeru","English Meaning":"to return (home)　[gv]","audio":"kaeru"},
 {"Kanji":"朝","Hiragana":"あさ","Romaji":"asa","English Meaning":"morning","audio":"asa"},
 {"Kanji":"起きる","Hiragana":"おきる","Romaji":"okiru","English Meaning":"to get up, to rise, to occur, to happen, to take place [iv]","audio":"okiru"},
 {"Kanji":"宿題","Hiragana":"しくだい","Romaji":"shukudai","English Meaning":"homework","audio":"shukudai"},
 {"Kanji":"朝ご飯","Hiragana":"あさごはん","Romaji":"asagohan","English Meaning":"breakfast","audio":"asagohan"},
 {"Kanji":"綺麗","Hiragana":"きれい","Romaji":"kirei","English Meaning":"pretty, clean [dn]","audio":"kirei"},
 {"Kanji":"学生","Hiragana":"がくせい","Romaji":"gakusei","English Meaning":"student(s)","audio":"gakusei"},
 {"Kanji":"みんな","Hiragana":"みんな","Romaji":"minna","English Meaning":"everyone, everybody","audio":"minna"},
 {"Kanji":"気に入る","Hiragana":"きにいる","Romaji":"ki ni iru","English Meaning":"to be pleased with, to suit one's fancy [gv]","audio":"ki ni iru"},
 {"Kanji":"成る","Hiragana":"なる","Romaji":"naru","English Meaning":"to become [gv]","audio":"naru"},
 {"Kanji":"クリスマス","Hiragana":"クリスマス","Romaji":"kurisumasu","English Meaning":"Christmas","audio":"kurisumasu"},
 {"Kanji":"一番","Hiragana":"いちばん","Romaji":"ichiban","English Meaning":"best, first, number one [dn]","audio":"ichiban"},
 {"Kanji":"好き","Hiragana":"すき","Romaji":"suki","English Meaning":"liking, fondness [dn]","audio":"suki"},
 {"Kanji":"日","Hiragana":"ひ","Romaji":"hi","English Meaning":"day","audio":"hi"},
 {"Kanji":"早い","Hiragana":"はやい","Romaji":"hayai","English Meaning":"early, quick [dv]","audio":"hayai"},
 {"Kanji":"プレゼント","Hiragana":"プレゼント","Romaji":"purezento","English Meaning":"present(s)","audio":"purezento"},
 {"Kanji":"開ける","Hiragana":"あける","Romaji":"akeru","English Meaning":"to open, to unwrap [iv]","audio":"akeru"},
 {"Kanji":"長い","Hiragana":"ながい","Romaji":"nagai","English Meaning":"long, lengthy [dv]","audio":"nagai"},
 {"Kanji":"時間","Hiragana":"じかん","Romaji":"jikan","English Meaning":"time, hours","audio":"jikan"},
 {"Kanji":"遊ぶ","Hiragana":"あそぶ","Romaji":"asobu","English Meaning":"to play [gv]","audio":"asobu"},
 {"Kanji":"夕食","Hiragana":"ゆうしょく","Romaji":"yuushoku","English Meaning":"dinner","audio":"yuushoku"},
 {"Kanji":"七面鳥","Hiragana":"しちめんちょう","Romaji":"shichimenchou","English Meaning":"turkey","audio":"shichimenchou"},
 {"Kanji":"過ごす","Hiragana":"すごす","Romaji":"sugosu","English Meaning":"to spend time [gv]","audio":"sugosu"},
 {"Kanji":"雨","Hiragana":"あめ","Romaji":"ame","English Meaning":"rain","audio":"ame"},
 {"Kanji":"降る","Hiragana":"ふる","Romaji":"furu","English Meaning":"to precipitate, to fall (rain) [gv]","audio":"furu"},
 {"Kanji":"時","Hiragana":"とき","Romaji":"toki","English Meaning":"time, hour, occasion, moment","audio":"toki"},
 {"Kanji":"外","Hiragana":"そと","Romaji":"soto","English Meaning":"outside","audio":"soto"},
 {"Kanji":"公園","Hiragana":"こうえん","Romaji":"kouen","English Meaning":"public park","audio":"kouen"},
 {"Kanji":"も","Hiragana":"も","Romaji":"mo","English Meaning":"also","audio":"mo"},
 {"Kanji":"一人","Hiragana":"ひとり","Romaji":"hitori","English Meaning":"one person, alone","audio":"hitori"},
 {"Kanji":"テレビ","Hiragana":"テレビ","Romaji":"terebi","English Meaning":"television, TV","audio":"terebi"}
]
const complexfour = [
  {"Kanji":"昼ご飯を食べてから宿題をします","Hiragana":"ひるごはんをたべてからしゅくだいをします","Romaji":"Hirugohan o tabete kara shukudai o shimasu","audio":"hirugohan o tabete kara shukudai o shimasu","English Meaning":"I will do my homework after I eat lunch.　(After I eat lunch, I will do my homework.)"},
 {"Kanji":"お茶を飲んでもいいですか","Hiragana":"おちゃをのんでもいいですか","Romaji":"O-cha o nonde mo ii desu ka","audio":"o-cha o nonde mo ii desu ka","English Meaning":"May I have some tea? (Is it ok to drink some tea?)"},
 {"Kanji":"お茶を飲んでもいいよ","Hiragana":"おちゃをのんでもいいよ","Romaji":"O-cha o nonde mo ii yo","audio":"o-cha o nonde mo ii yo","English Meaning":"Go ahead and have some tea. (It is ok to drink some tea.)"},
 {"Kanji":"昼ご飯を食べて宿題をしてから、友達の家に行ってもいいですか","Hiragana":"ひるごはんをたべてしゅくだいをしてから、ともだちのいえにいってもいいですか","Romaji":"Hirugohan o tabete shukudai o shite kara, tomodachi no ie ni itte mo ii desu ka","audio":"hirugohan o tabete shukudai o shite kara tomodachi no ie ni itte mo ii desu ka","English Meaning":"It is ok if I go to my friend's house after I eat lunch and do my homework? (After I eat lunch and do my homework, is it ok if I go to my friend's house?)"}
]
const complexfive = [
  {"Kanji":"犬が吼えている事を聞いた","Hiragana":"いぬがほえていることをきいた","Romaji":"Inu ga hoete iru koto o kiita","audio":"inu ga hoete iru koto o kiita","English Meaning":"I heard a dog barking."},
 {"Kanji":"自転車から落ちて怪我した事を思い出して、怖がった","Hiragana":"じてんしゃからおちてけがしたことをおもいだして、こわがった","Romaji":"Jitensha kara ochite kega shita koto o omoidashite, kowagatt","audio":"jitensha kara ochite kega shita koto o omoidashite kowagatta","English Meaning":"I remembered falling off my bike and getting hurt and was afraid.Note: In this sentence, \"kara\" means \"from\"."},
 {"Kanji":"彼女は私達と一緒に行かない事を両親に伝えるようにお願いします","Hiragana":"かのじょはわたしたちといっしょにいかないことをりょうしんにつたえるようにおねがいします","Romaji":"Kanojo wa watashitachi to issho ni ikanai koto o ryoushin ni tsutaeru you ni o-negai shimasu","audio":"kanojo wa watashitachi to issho ni ikanai koto o ryoushin ni tsutaeru you ni o-negai shimasu","English Meaning":"Please tell our parents that she won't be going with us."}
]
const complexsix = [
  {"Kanji":"吼える","Hiragana":"ほえる","Romaji":"hoeru","English Meaning":"to bark [iv]","audio":"hoeru"},
 {"Kanji":"自転車","Hiragana":"じてんしゃ","Romaji":"jitensha","English Meaning":"bicycle","audio":"jitensha"},
 {"Kanji":"落ちる","Hiragana":"おちる","Romaji":"ochiru","English Meaning":"to fall [iv]","audio":"ochiru"},
 {"Kanji":"怪我","Hiragana":"けが","Romaji":"kega","English Meaning":"wound, injury","audio":"kega"},
 {"Kanji":"怪我する","Hiragana":"けがする","Romaji":"kega suru","English Meaning":"to get hurt　[irv]","audio":"kega suru"},
 {"Kanji":"思い出す","Hiragana":"おもいだす","Romaji":"omoidasu","English Meaning":"to remember [gv]","audio":"omoidasu"},
 {"Kanji":"怖がる","Hiragana":"こわがる","Romaji":"kowagaru","English Meaning":"to be afraid [gv]","audio":"kowagaru"},
 {"Kanji":"私達","Hiragana":"わたしたち","Romaji":"watashitachi","English Meaning":"us/we","audio":"watashitachi"},
 {"Kanji":"一緒","Hiragana":"いっしょ","Romaji":"issho","English Meaning":"together, at the same time, same","audio":"issho"},
 {"Kanji":"一緒に","Hiragana":"いっしょに","Romaji":"issho ni","English Meaning":"together (with)","audio":"issho ni"},
 {"Kanji":"伝える","Hiragana":"つたえる","Romaji":"tsutaeru","English Meaning":"to convey, to tell, to impart [iv]","audio":"tsutaeru"}
]

const Complex = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Beginner" category="Grammar" lesson={title} />
     <div className={'title'}>
     {title}
      </div>
      <h1>{title}</h1>

<p>You have likely learned by this point that base "te", when by itself or at the end of a sentence, is the plain form command form. There are other ways that base "te" can be used as well. One of these ways is in creating complex sentences. This lesson will explore complex sentences and other grammatical uses of base "te". <strong>Note:</strong> The negative of base "te" is base 1 + "naide".</p>

<p>Base "te", when used in the middle of a sentence, allows the sentence to contain additional verbs just like adding an "and" to the sentence. Here's an example:</p>

<Table data={complexone} />

<p>Notice that the verbs in the middle of the sentence take the tense of the final verb. To do this with the verb "de aru" (desu), use "de". Here are a number of other example sentences:</p>

<Table data={complextwo} />

<h2>Vocab Breakdown</h2>

<Table data={complexthree} />

<p>Two more ways that base "te" can be used are base "te" + kara which means "after verbing" and base "te" + mo ii which means "ok to verb" (base "te" + mo = even though I verb). Here are some example sentences:</p>

<Table data={complexfour} />

<p>Base "te" + mo ii is a polite way to ask for permission to do something (in question form) and also a very polite way to ask someone to do something (in statement form). The negative form of this would be base 1 + nakute mo ii (<Play audio="hirugohan o tabenakute mo ii desu ka" />  = "Is it ok if I don't eat my lunch?").</p>

<h2>Say, Hear, Think</h2>

<p>Another type of complex sentence would be things said, heard, or thought. Such as, "He said, 'I will be home at 3pm.'" There are two ways in Japanese to state things said, heard, or thought. One is with the particle "to". This is explained in the <Link to="/members/b/grammar/sentence-construction/">Sentence Construction</Link> lesson. The other is with the word <Play audio="koto" />  which means "thing". Just as in one of the examples above, a verb can be placed in front of "koto" to modify it. It then becomes "the thing of verbing" and that phrase can now be used as a direct object. Here are some example sentences:</p>

<Table data={complexfive} />

<h2>Vocab Breakdown</h2>

<Table data={complexsix} />


    </>
  )
}

export default Complex