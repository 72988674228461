import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Other Important Grammatical Principles"

const otherone = [
  {"Kanji":"行かなければ教えてね","Hiragana":"いかなければおしえてね","Romaji":"Ikanakereba oshiete ne","audio":"ikanakereba oshiete ne","English Meaning":"Let me know if you're not going, ok."},
 {"Kanji":"彼も行きたがるに違いない","Hiragana":"かれもいきたがるにちがいない","Romaji":"Kare mo ikitagaru ni chigainai","audio":"kare mo ikitagaru ni chigainai","English Meaning":"He definitely wants to go too.(He is definitely showing signs of wanting to go too.)"},
 {"Kanji":"運動すればする程強くなる","Hiragana":"うんどうすればするほどつよくなる","Romaji":"Undou sureba suru hodo tsuyoku naru","audio":"undou sureba suru hodo tsuyoku naru","English Meaning":"The more I/one exercise(s) the stronger I/one get(s)."},
 {"Kanji":"彼女は結構寂しがる","Hiragana":"かのじょはけっこうさびしがる","Romaji":"Kanojo wa kekkou sabishigaru","audio":"kanojo wa kekkou sabishigaru","English Meaning":"She seems pretty lonely."},
 {"Kanji":"外が寒ければ遊ばないわけなの","Hiragana":"そとがさむければあそばないわけなの","Romaji":"Soto ga samukereba asobanai wake na no","audio":"soto ga samukereba asobanai wake na no","English Meaning":"So you're saying that we won't play if it's cold outside?"},
 {"Kanji":"寒くなければ外で遊んでもいいの","Hiragana":"さむくなければそとであそんでもいいの","Romaji":"Samukunakereba soto de asonde mo ii no","audio":"samukunakereba soto de asonde mo ii no","English Meaning":"Can I play outside if it isn't cold?"},
 {"Kanji":"凄く暑かったら中に残ったほうがいいと思う","Hiragana":"すごくあつかったらなかにのこったほうがいいとおもう","Romaji":"Sugoku atsukattara naka ni nokotta hou ga ii to omou","audio":"sugoku atsukattara naka ni nokotta hou ga ii to omou","English Meaning":"If it's really hot then I think you should stay inside."},
 {"Kanji":"でも暑くなかったら散歩しようか","Hiragana":"でも暑くなかったら散歩しようか","Romaji":"Demo atsukunakattara sanpo shiyou ka","audio":"demo atsukunakattara sanpo shiyou ka","English Meaning":"But if it isn't hot shall we go for a walk?"},
 {"Kanji":"あの場所は遠いけど楽しいらしい","Hiragana":"あのばしょはとおいけどたのしいらしい","Romaji":"Ano basho wa tooi kedo tanoshii rashii","audio":"ano basho wa tooi kedo tanoshii rashii","English Meaning":"That place is far away but I've heard/read it's fun."},
 {"Kanji":"それを食べたなら直ぐお腹の調子が悪くなる","Hiragana":"それをたべたならすぐおなかのちょうしがわるくなる","Romaji":"Sore o tabeta nara sugu onaka no choushi ga waruku naru","audio":"sore o tabeta nara sugu onaka no choushi ga waruku naru","English Meaning":"If you ate that you're going to get sick to your stomach soon."},
 {"Kanji":"これから先生に対してもっと敬いなさいよ","Hiragana":"これからせんせいにたいしてもっとうやまいなさいよ","Romaji":"Kore kara sensei ni taishite motto uyamainasai yo","audio":"Kore kara sensei ni taishite motto uyamainasai yo","English Meaning":"From now on, in regards to the teacher, show more respect!"},
 {"Kanji":"私にとって昼ご飯として少しずつお菓子を食べるのがいい","Hiragana":"わたしにとってひるごはんとしてすこしずつおかしをたべるのがいい","Romaji":"Watashi ni totte hirugohan toshite sukoshi zutsu okashi o taberu no ga ii","audio":"watashi ni totte hirugohan toshite sukoshi zutsu okashi o taberu no ga ii","English Meaning":"As far as I'm concerned (in my opinion), it's good to eat a little amount of snacks at a time as lunch."},
 {"Kanji":"政治に関して無言だ","Hiragana":"せいじにかんしてむごんだ","Romaji":"Seiji ni kanshite mugon da","audio":"seiji ni kanshite mugon da","English Meaning":"I don't talk about politics.(In relation to politics, I am silent.)"},
 {"Kanji":"やっと、キリンについての本を見つけて、そして、この週末できるかぎり読むつもりだ","Hiragana":"やっと、キリンについてのほんをみつけて、そして、このしゅうまつできるかぎりよむつもりだ","Romaji":"Yatto, kirin ni tsuite no hon o mitsukete, soshite, kono shuumatsu dekiru kagiri yomu tsumori da","audio":"yatto, kirin ni tsuite no hon o mitsukete, soshite, kono shuumatsu dekiru kagiri yomu tsumori da","English Meaning":"Finally, I found a book about giraffes, and now, I'm going to read as much as I can this weekend."},
 {"Kanji":"それから新幹線によって東京を通して仙台に行く","Hiragana":"それからしんかんせんによってとうきょうをとおしてせんだいにいく","Romaji":"Sore kara shinkansen ni yotte toukyou o tooshite sendai ni ik ","audio":"sore kara shinkansen ni yotte toukyou o tooshite sendai ni iku","English Meaning":"After that, we'll take the bullet train to Sendai through Tokyo.(After that, by means of the bullet train, we'll go through Tokyo to Sendai.)"},
 {"Kanji":"先生によって宿題の量が随分違う","Hiragana":"せんせいによってしゅくだいのりょうがずいぶんちがう","Romaji":"Sensei ni yotte shukudai no ryou ga zuibun chigau","audio":"sensei ni yotte shukudai no ryou ga zuibun chigau","English Meaning":"The amount of homework depends a great deal on the teacher."}
]
const othertwo = [
  {"Kanji":"","Hiragana":"ね","Romaji":"ne","English Meaning":"(sentence end) indicates emphasis, agreement, request for confirmation, etc.","audio":"ne"},
 {"Kanji":"寂しい","Hiragana":"さびしい","Romaji":"sabishii","English Meaning":"lonely, lonesome, solitary, desolate [dv] ","audio":"sabishii"},
 {"Kanji":"","Hiragana":"わけ","Romaji":"wake","English Meaning":"conclusion from reasoning, judgement or calculation based on something read or heard, reason, cause, meaning, circumstances, situation [n] ","audio":"wake"},
 {"Kanji":"凄い","Hiragana":"すごい","Romaji":"sugoi","English Meaning":"terrible, dreadful, amazing (e.g. of strength), great (e.g. of skills), wonderful, terrific, to a great extent, vast (in numbers) [dv] ","audio":"sugoi"},
 {"Kanji":"残る","Hiragana":"のこる","Romaji":"nokoru","English Meaning":"to remain, to be left [gv] ","audio":"nokoru"},
 {"Kanji":"散歩","Hiragana":"さんぽ","Romaji":"sanpo","English Meaning":"walk, stroll [n] ","audio":"sanpo"},
 {"Kanji":"散歩する","Hiragana":"さんぽする","Romaji":"sanpo suru","English Meaning":"to take a walk/stroll [irv] ","audio":"sanpo suru"},
 {"Kanji":"場所","Hiragana":"ばしょ","Romaji":"basho","English Meaning":"place, location [n] ","audio":"basho"},
 {"Kanji":"遠い","Hiragana":"とおい","Romaji":"tooi","English Meaning":"far, distant [dv] ","audio":"tooi"},
 {"Kanji":"楽しい","Hiragana":"たのしい","Romaji":"tanoshii","English Meaning":"enjoyable, fun [dv] ","audio":"tanoshii"},
 {"Kanji":"調子","Hiragana":"ちょうし","Romaji":"choushi","English Meaning":"tune, tone, key, pitch, time, rhythm, mood, way, manner, style, knack, condition, state of health, impetus, spur of the moment, strain, trend [n] ","audio":"choushi"},
 {"Kanji":"悪い","Hiragana":"わるい","Romaji":"warui","English Meaning":"bad, poor, inferior, evil, sinful, unprofitable, unbeneficial, at fault, to blame, in the wrong [dv] ","audio":"warui"},
 {"Kanji":"","Hiragana":"これから","Romaji":"kore kara","English Meaning":"after this, from now on","audio":"kore kara"},
 {"Kanji":"敬う","Hiragana":"うやまう","Romaji":"uyamau","English Meaning":"to show respect, to honor [gv] ","audio":"uyamau"},
 {"Kanji":"","Hiragana":"よ","Romaji":"yo","English Meaning":"(at sentence end) indicates certainty, emphasis, contempt, request, etc.","audio":"yo"},
 {"Kanji":"","Hiragana":"として","Romaji":"toshite","English Meaning":"as (i.e. in the role of), for (i.e. from the viewpoint of)","audio":"toshite"},
 {"Kanji":"","Hiragana":"ずつ","Romaji":"zutsu","English Meaning":"apiece, each, at a time, piecemeal","audio":"zutsu"},
 {"Kanji":"お菓子","Hiragana":"おかし","Romaji":"okashi","English Meaning":"confections, sweets, candy [n] ","audio":"okashi"},
 {"Kanji":"政治","Hiragana":"せいじ","Romaji":"seiji","English Meaning":"politics, government [n] ","audio":"seiji"},
 {"Kanji":"無言","Hiragana":"むごん","Romaji":"mugon","English Meaning":"silence [n] ","audio":"mugon"},
 {"Kanji":"","Hiragana":"やっと","Romaji":"yatto","English Meaning":"at last, at length, barely, narrowly, just, by the skin of one's teeth","audio":"yatto"},
 {"Kanji":"麒麟","Hiragana":"きりん","Romaji":"kirin","English Meaning":"giraffe [n] ","audio":"kirin"},
 {"Kanji":"見つける","Hiragana":"みつける","Romaji":"mitsukeru","English Meaning":" to discover, to find (e.g. an error in a book), to come across, to detect, to spot, to locate, to find (e.g. something missing) [iv] ","audio":"mitsukeru"},
 {"Kanji":"","Hiragana":"そして","Romaji":"soshite","English Meaning":"and, and then, thus, and now","audio":"soshite"},
 {"Kanji":"","Hiragana":"かぎり","Romaji":"kagiri","English Meaning":"limit, limits, bounds, degree, extent, scope, as far as possible, as much as possible, to the best of one's ability, very much [n] ","audio":"kagiri"},
 {"Kanji":"","Hiragana":"それから","Romaji":"sore kara","English Meaning":"and then, after that","audio":"sore kara"},
 {"Kanji":"を通して","Hiragana":"をとおして","Romaji":"o tooshite","English Meaning":"through, throughout, through (the good offices of), by way of","audio":"o tooshite"},
 {"Kanji":"量","Hiragana":"りょう","Romaji":"ryou","English Meaning":"quantity, amount, volume, portion (of food) [n] ","audio":"ryou"},
 {"Kanji":"随分","Hiragana":"ずいぶん","Romaji":"zuibun","English Meaning":"very, extremely, surprisingly, considerably","audio":"zuibun"}
]

const OtherImportant = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Intermediate" category="Grammar" lesson={title} />

      <h1>{title}</h1>

<p>Below are a number of grammatical principles that are important in becoming fluent in Japanese. Please note that the example sentences will all be done in plain form.</p>

<h2>Verbs</h2>

<table>
  <thead>
  <tr>
<th>漢字/ひらがな<br />Kanji/Hiragana <Play audio="hiragana" /></th>
<th>Romaji <Play audio="ro-maji" /><br />(English Letters)</th>
<th>English Meaning</th>
</tr>
  </thead>
<tbody>
<tr>
<td>Base 1 + なければ</td>
<td>Base 1 + <Play audio="nakereba" /></td>
<td>if not verb<br />can replace "Base 1 + nakute wa" as described <Link to="/members/b/grammar/bases02/">here</Link></td>
</tr>
<tr>
<td>Base 2 + たがる</td>
<td>Base 2 + <Play audio="tagaru" /></td>
<td>someone else wants to verb (shows signs of wanting to verb)<br />(base 2 + "tai" (want to verb) is a "dv" (as is base 1 + "nai") so this is the "drop the 'i' and add 'garu'" principle taught below)</td>
</tr>
<tr>
<td>Base 4 + ば + Base 3 + 程</td>
<td>Base 4 + ba + Base 3 + hodo <Play audio="ba hodo" /></td>
<td>the more I verb...</td>
</tr>
</tbody></table>

<h2><Link to="/members/b/grammar/descriptive01/">"DV" Adjectives</Link></h2>

<table>
<thead>
  <tr>
<th>ひらがな - Hiragana <Play audio="hiragana" /></th>
<th>Romaji <Play audio="ro-maji" /><br />(English Letters)</th>
<th>English Meaning</th>
</tr>
  </thead>
<tbody>
<tr>
<td>drop the い <br />and add がる </td>
<td>drop the "i" <Play audio="i" /><br />and add "garu" <Play audio="garu" /></td>
<td>to show signs of being - becomes a verb</td>
</tr>
<tr>
<td>drop the い <br />and add ければ </td>
<td>drop the "i" <Play audio="i" /><br />and add "kereba" <Play audio="kereba" /></td>
<td>if form</td>
</tr>
<tr>
<td>drop the い <br />and add くなければ </td>
<td>drop the "i" <Play audio="i" /><br />and add "kunakereba" <Play audio="kunakereba" /></td>
<td>negative if form (if not)</td>
</tr>
<tr>
<td>drop the い <br />and add かったら </td>
<td>drop the "i" <Play audio="i" /><br />and add "kattara" <Play audio="kattara" /></td>
<td>if and when form</td>
</tr>
<tr>
<td>drop the い <br />and add くなかったら </td>
<td>drop the "i" <Play audio="i" /><br />and add "kunakattara" <Play audio="kunakattara" /></td>
<td>negative if and when form</td>
</tr>
</tbody></table>

<h2>But, However</h2>

<p>The words <Play audio="kedo" /> , <Play audio="keredo" /> , <Play audio="keredomo" /> , and <Play audio="ga" />  (listed from least to most polite) are all quite similar and mean "but, however". They would be used after a verb or adjective. <Play audio="demo" />  and <Play audio="shikashi" />  also mean "but, however" but typically start the sentence ("shikashi" is far more polite).</p>

<h2>Rashii</h2>

<p><Play audio="rashii" />  is another way to express hearsay (something you heard or read - like saying "apparently...") in addition to the different methods described <Link to="/members/i/grammar/grammar02/">here</Link>. It can be used after verbs, adjectives, and nouns and the conjugation is typically done to the word before it but, as a "dv", it can be conjugated as well.</p>

<h2>Nara</h2>

<p>While verbs and adjectives can be conjugated into an "if form", the word <Play audio="nara" />  can also be used after verbs, adjectives, and even nouns to express "if". This is what would be used, for instance, to express a past tense "if" since the verb/adjective conjugations don't support this. You may sometimes hear a "no" before "nara" or even a "ba" after it ("naraba" is a more polite equivalent). Also, <Play audio="moshi" />  (which essentially means "if") is often placed at the beginning of a sentence that will contain an "if" but it is not always necessary.</p>

<h2>Ni Expressions</h2>

<p>There are a number of expressions in Japanese that use the particle "ni" and are used fairly frequently. One is <Play audio="ni chigainai" />  which derives from the verb <Play audio="chigau" />  (to differ (from), to vary) and means "definitely, must be, no doubt". It can be used after verbs, adjectives, and nouns to indicate something one is sure of. Others are <Play audio="ni taishite" />  which means "regarding", <Play audio="ni totte" />  which means "concerning, as far as ... is concerned" (and can be used to express an opinion), <Play audio="ni kanshite" />  which means "in relation to", <Play audio="ni tsuite" />  which means "about", and <Play audio="ni yotte" />  which means "through, by means of, depending on". These are generally used after a noun.</p>

<h2>Example sentences:</h2>

<Table data={otherone} />

<h2>Vocab Breakdown</h2>

<Table data={othertwo} />

    </>
  )
}

export default OtherImportant