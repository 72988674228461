import React from "react"
import { Router } from "@reach/router"
import { login, isAuthenticated } from "../src/utils/auth"
import LoadingProvider from "../src/components/mdxComponents/loading"
import Layout from "./components/layout"
import Profile from "./profile"
import GettingStarted from "./getting-started"
import Main from "./main"
import PrivateRoute from "./components/PrivateRoute"
import VocabOneB from "./b/vocab/vocab01"
import VocabTwoB from "./b/vocab/vocab02"
import VocabAnimals from "./b/vocab/animals-colors-shapes"
import VocabFamily from "./b/vocab/family"
import VocabSchool from "./b/vocab/school"
import VocabHouse from "./b/vocab/house-kitchen"
import NumbersTimeB from "./b/vocab/numbers"
import BasesOne from "./b/grammar/bases01"
import BasesTwo from "./b/grammar/bases02"
import DescriptiveOne from "./b/grammar/descriptive01"
import GodanVerbs from "./b/grammar/godan-verbs"
import IchidanVerbs from "./b/grammar/ichidan-verbs"
import BasicGrammar from "./b/grammar/basic-grammar"
import MoreParticles from "./b/grammar/more-particles"
import SentenceConstruction from "./b/grammar/sentence-construction"
import CommonOne from "./b/common/common01"
import CommonTwo from "./b/common/common02"
import CommonThree from "./b/common/common03"
import CommonFour from "./b/common/common04"
import Conversations from "./b/common/conversations"
import Meeting from "./b/common/meeting"
import Hiragana from "./b/basics/hiragana"
import HiraganaWritingOne from "./b/basics/hiragana-writing01"
import HiraganaWritingTwo from "./b/basics/hiragana-writing02"
import HiraganaWritingThree from "./b/basics/hiragana-writing03"
import HiraganaWritingFour from "./b/basics/hiragana-writing04"
import HiraganaWritingFive from "./b/basics/hiragana-writing05"
import Katakana from "./b/basics/katakana"
import NamesInJapanese from "./b/basics/names"
import BNamesInJapanese from "./b/basics/names/b"
import CNamesInJapanese from "./b/basics/names/c"
import DNamesInJapanese from "./b/basics/names/d"
import ENamesInJapanese from "./b/basics/names/e"
import FNamesInJapanese from "./b/basics/names/f"
import GNamesInJapanese from "./b/basics/names/g"
import HNamesInJapanese from "./b/basics/names/h"
import INamesInJapanese from "./b/basics/names/i"
import JNamesInJapanese from "./b/basics/names/j"
import KNamesInJapanese from "./b/basics/names/k"
import LNamesInJapanese from "./b/basics/names/l"
import MNamesInJapanese from "./b/basics/names/m"
import NNamesInJapanese from "./b/basics/names/n"
import ONamesInJapanese from "./b/basics/names/o"
import PNamesInJapanese from "./b/basics/names/p"
import QNamesInJapanese from "./b/basics/names/q"
import RNamesInJapanese from "./b/basics/names/r"
import SNamesInJapanese from "./b/basics/names/s"
import TNamesInJapanese from "./b/basics/names/t"
import UNamesInJapanese from "./b/basics/names/u"
import VNamesInJapanese from "./b/basics/names/v"
import WNamesInJapanese from "./b/basics/names/w"
import XNamesInJapanese from "./b/basics/names/x"
import YNamesInJapanese from "./b/basics/names/y"
import ZNamesInJapanese from "./b/basics/names/z"
import BusinessVocab from "./i/vocab/business"
import SoundWords from "./i/vocab/sound"
import Idioms from "./i/vocab/idioms"
import BodyParts from "./i/vocab/body"
import Honorifics from "./i/vocab/honorifics"
import Restaurant from "./i/vocab/restaurant"
import SimilarOne from "./i/vocab/similar01"
import SimilarTwo from "./i/vocab/similar02"
import Questions from "./i/grammar/questions01"
import BasesThree from "./i/grammar/bases03"
import Complex from "./i/grammar/complex01"
import DakeShika from "./i/grammar/grammar01"
import DescribingComparing from "./i/grammar/grammar02"
import Transitive from "./i/grammar/grammar03"
import TimeBased from "./i/grammar/grammar04"
import OtherImportant from "./i/grammar/grammar05"
import Counters from "./i/grammar/counters01"
import KanjiIntro from "./a/kanji/intro"
import GradeOneOne from "./a/kanji/grade01-01"
import GradeOneTwo from "./a/kanji/grade01-02"
import GradeTwoOne from "./a/kanji/grade02-01"
import GradeTwoTwo from "./a/kanji/grade02-02"
import GradeTwoThree from "./a/kanji/grade02-03"
import GradeTwoFour from "./a/kanji/grade02-04"
import GradeThreeOne from "./a/kanji/grade03-01"
import GradeThreeTwo from "./a/kanji/grade03-02"
import GradeThreeThree from "./a/kanji/grade03-03"
import GradeThreeFour from "./a/kanji/grade03-04"
import GradeThreeFive from "./a/kanji/grade03-05"
import GradeFourOne from "./a/kanji/grade04-01"
import GradeFourTwo from "./a/kanji/grade04-02"
import GradeFourThree from "./a/kanji/grade04-03"
import GradeFourFour from "./a/kanji/grade04-04"
import GradeFourFive from "./a/kanji/grade04-05"
import GradeFiveOne from "./a/kanji/grade05-01"
import GradeFiveTwo from "./a/kanji/grade05-02"
import GradeFiveThree from "./a/kanji/grade05-03"
import GradeFiveFour from "./a/kanji/grade05-04"
import GradeFiveFive from "./a/kanji/grade05-05"
import GradeSixOne from "./a/kanji/grade06-01"
import GradeSixTwo from "./a/kanji/grade06-02"

const App = () => {
  if (!isAuthenticated()) {
    login()
    return <LoadingProvider />
  }

  return (
    <Layout lang="en" location="/members/">
      <Router>
        <PrivateRoute path="/members/profile/" component={Profile} />
        <PrivateRoute path="/members/getting-started/" component={GettingStarted} />
        <PrivateRoute path="/members/" component={Main} />
        <PrivateRoute path="/members/b/vocab/vocab01/" component={VocabOneB} />
        <PrivateRoute premium path="/members/b/vocab/vocab02/" component={VocabTwoB} />
        <PrivateRoute premium path="/members/b/vocab/animals-colors-shapes/" component={VocabAnimals} />
        <PrivateRoute premium path="/members/b/vocab/family/" component={VocabFamily} />
        <PrivateRoute premium path="/members/b/vocab/school/" component={VocabSchool} />
        <PrivateRoute premium path="/members/b/vocab/house-kitchen/" component={VocabHouse} />
        <PrivateRoute path="/members/b/vocab/numbers/" component={NumbersTimeB} />
        <PrivateRoute path="/members/b/grammar/bases01/" component={BasesOne} />
        <PrivateRoute premium path="/members/b/grammar/bases02/" component={BasesTwo} />
        <PrivateRoute path="/members/b/grammar/descriptive01/" component={DescriptiveOne} />
        <PrivateRoute premium path="/members/b/grammar/godan-verbs/" component={GodanVerbs} />
        <PrivateRoute premium path="/members/b/grammar/ichidan-verbs/" component={IchidanVerbs} />
        <PrivateRoute premium path="/members/b/grammar/basic-grammar/" component={BasicGrammar} />
        <PrivateRoute premium premium path="/members/b/grammar/more-particles/" component={MoreParticles} />
        <PrivateRoute path="/members/b/grammar/sentence-construction/" component={SentenceConstruction} />
        <PrivateRoute path="/members/b/common/common01/" component={CommonOne} />
        <PrivateRoute premium path="/members/b/common/common02/" component={CommonTwo} />
        <PrivateRoute premium path="/members/b/common/common03/" component={CommonThree} />
        <PrivateRoute premium path="/members/b/common/common04/" component={CommonFour} />
        <PrivateRoute path="/members/b/common/meeting/" component={Meeting} />
        <PrivateRoute premium path="/members/b/common/conversations/" component={Conversations} />
        <PrivateRoute path="/members/b/basics/hiragana/" component={Hiragana} />
        <PrivateRoute path="/members/b/basics/hiragana-writing01/" component={HiraganaWritingOne} />
        <PrivateRoute premium path="/members/b/basics/hiragana-writing02/" component={HiraganaWritingTwo} />
        <PrivateRoute premium path="/members/b/basics/hiragana-writing03/" component={HiraganaWritingThree} />
        <PrivateRoute premium path="/members/b/basics/hiragana-writing04/" component={HiraganaWritingFour} />
        <PrivateRoute premium path="/members/b/basics/hiragana-writing05/" component={HiraganaWritingFive} />
        <PrivateRoute path="/members/b/basics/katakana/" component={Katakana} />
        <PrivateRoute path="/members/b/basics/names/" component={NamesInJapanese} />
        <PrivateRoute path="/members/b/basics/names/b/" component={BNamesInJapanese} />
        <PrivateRoute path="/members/b/basics/names/c/" component={CNamesInJapanese} />
        <PrivateRoute path="/members/b/basics/names/d/" component={DNamesInJapanese} />
        <PrivateRoute path="/members/b/basics/names/e/" component={ENamesInJapanese} />
        <PrivateRoute path="/members/b/basics/names/f/" component={FNamesInJapanese} />
        <PrivateRoute path="/members/b/basics/names/g/" component={GNamesInJapanese} />
        <PrivateRoute path="/members/b/basics/names/h/" component={HNamesInJapanese} />
        <PrivateRoute path="/members/b/basics/names/i/" component={INamesInJapanese} />
        <PrivateRoute path="/members/b/basics/names/j/" component={JNamesInJapanese} />
        <PrivateRoute path="/members/b/basics/names/k/" component={KNamesInJapanese} />
        <PrivateRoute path="/members/b/basics/names/l/" component={LNamesInJapanese} />
        <PrivateRoute path="/members/b/basics/names/m/" component={MNamesInJapanese} />
        <PrivateRoute path="/members/b/basics/names/n/" component={NNamesInJapanese} />
        <PrivateRoute path="/members/b/basics/names/o/" component={ONamesInJapanese} />
        <PrivateRoute path="/members/b/basics/names/p/" component={PNamesInJapanese} />
        <PrivateRoute path="/members/b/basics/names/q/" component={QNamesInJapanese} />
        <PrivateRoute path="/members/b/basics/names/r/" component={RNamesInJapanese} />
        <PrivateRoute path="/members/b/basics/names/s/" component={SNamesInJapanese} />
        <PrivateRoute path="/members/b/basics/names/t/" component={TNamesInJapanese} />
        <PrivateRoute path="/members/b/basics/names/u/" component={UNamesInJapanese} />
        <PrivateRoute path="/members/b/basics/names/v/" component={VNamesInJapanese} />
        <PrivateRoute path="/members/b/basics/names/w/" component={WNamesInJapanese} />
        <PrivateRoute path="/members/b/basics/names/x/" component={XNamesInJapanese} />
        <PrivateRoute path="/members/b/basics/names/y/" component={YNamesInJapanese} />
        <PrivateRoute path="/members/b/basics/names/z/" component={ZNamesInJapanese} />
        <PrivateRoute premium path="/members/i/vocab/business/" component={BusinessVocab} />
        <PrivateRoute path="/members/i/vocab/sound/" component={SoundWords} />
        <PrivateRoute premium path="/members/i/vocab/idioms/" component={Idioms} />
        <PrivateRoute premium path="/members/i/vocab/body/" component={BodyParts} />
        <PrivateRoute premium path="/members/i/vocab/honorifics/" component={Honorifics} />
        <PrivateRoute premium path="/members/i/vocab/restaurant/" component={Restaurant} />
        <PrivateRoute premium path="/members/i/vocab/similar01/" component={SimilarOne} />
        <PrivateRoute premium path="/members/i/vocab/similar02/" component={SimilarTwo} />
        <PrivateRoute premium path="/members/i/grammar/questions01/" component={Questions} />
        <PrivateRoute premium path="/members/i/grammar/bases03/" component={BasesThree} />
        <PrivateRoute premium path="/members/i/grammar/complex01/" component={Complex} />
        <PrivateRoute premium path="/members/i/grammar/grammar01/" component={DakeShika} />
        <PrivateRoute premium path="/members/i/grammar/grammar02/" component={DescribingComparing} />
        <PrivateRoute premium path="/members/i/grammar/grammar03/" component={Transitive} />
        <PrivateRoute premium path="/members/i/grammar/grammar04/" component={TimeBased} />
        <PrivateRoute premium path="/members/i/grammar/grammar05/" component={OtherImportant} />
        <PrivateRoute premium path="/members/i/grammar/counters01/" component={Counters} />
        <PrivateRoute path="/members/a/kanji/intro/" component={KanjiIntro} />
        <PrivateRoute path="/members/a/kanji/grade01-01/" component={GradeOneOne} />
        <PrivateRoute path="/members/a/kanji/grade01-02/" component={GradeOneTwo} />
        <PrivateRoute path="/members/a/kanji/grade02-01/" component={GradeTwoOne} />
        <PrivateRoute premium path="/members/a/kanji/grade02-02/" component={GradeTwoTwo} />
        <PrivateRoute premium path="/members/a/kanji/grade02-03/" component={GradeTwoThree} />
        <PrivateRoute premium path="/members/a/kanji/grade02-04/" component={GradeTwoFour} />
        <PrivateRoute premium path="/members/a/kanji/grade03-01/" component={GradeThreeOne} />
        <PrivateRoute premium path="/members/a/kanji/grade03-02/" component={GradeThreeTwo} />
        <PrivateRoute premium path="/members/a/kanji/grade03-03/" component={GradeThreeThree} />
        <PrivateRoute premium path="/members/a/kanji/grade03-04/" component={GradeThreeFour} />
        <PrivateRoute premium path="/members/a/kanji/grade03-05/" component={GradeThreeFive} />
        <PrivateRoute premium path="/members/a/kanji/grade04-01/" component={GradeFourOne} />
        <PrivateRoute premium path="/members/a/kanji/grade04-02/" component={GradeFourTwo} />
        <PrivateRoute premium path="/members/a/kanji/grade04-03/" component={GradeFourThree} />
        <PrivateRoute premium path="/members/a/kanji/grade04-04/" component={GradeFourFour} />
        <PrivateRoute premium path="/members/a/kanji/grade04-05/" component={GradeFourFive} />
        <PrivateRoute premium path="/members/a/kanji/grade05-01/" component={GradeFiveOne} />
        <PrivateRoute premium path="/members/a/kanji/grade05-02/" component={GradeFiveTwo} />
        <PrivateRoute premium path="/members/a/kanji/grade05-03/" component={GradeFiveThree} />
        <PrivateRoute premium path="/members/a/kanji/grade05-04/" component={GradeFiveFour} />
        <PrivateRoute premium path="/members/a/kanji/grade05-05/" component={GradeFiveFive} />
        <PrivateRoute premium path="/members/a/kanji/grade06-01/" component={GradeSixOne} />
        <PrivateRoute premium path="/members/a/kanji/grade06-02/" component={GradeSixTwo} />
      </Router>
    </Layout>
  )
}

export default App
