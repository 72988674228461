import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"

const title = "Introduction to Kanji"

const KanjiIntro = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Advanced" category="Grammar" lesson={title} />

      <h1>{title}</h1>

<p>Kanji are Chinese characters and a core part of the Japanese writing system. A typical Japanese sentence will contain both Kanji and Hiragana with the occasional Katakana mixed in. We will teach the Kanji by the grade in which the Japanese learn them and will start with 40 Kanji at a time so that it doesn't become overwhelming.</p>

<p>In these lessons, we will teach all of the readings for a particular Kanji that you need to know in order to learn how to read Japanese fluently. We will also provide at least one vocabulary example for each necessary reading. There are over 2,000 regularly used Kanji so it can take quite a while to learn them all.</p>

<p>Once we have covered all of the 1,000+ Kanji taught in Elementary School (grades 1-6), we will move into the 1,100+ Kanji taught in Secondary School (Junior High School and High School). These are not split up by grade and we will cover 60 Kanji per lesson.</p>

<p>Kanji have 2 categories of readings - "On" (音) and "Kun" (訓).</p>

<p><strong>"On" Reading:</strong></p>

<p>The "On" reading was borrowed from Chinese and then modified to fit within the sounds of the Japanese language. The "On" reading is typically the one used when the Kanji is combined with another (we will try to point out exceptions to this rule in the examples). One common exception to this rule is when the Kanji does not have a "Kun" reading without trailing Hiragana. Most Kanji have only one "On" reading but some have two or three. We will display the "On" reading(s) in Katakana.</p>

<p><strong>"Kun" Reading:</strong></p>

<p>The "Kun" reading is typically used when the Kanji is by itself. It is also the reading used in verbs that begin with that Kanji. One Kanji can have a number of different "Kun" readings depending on how it is used although most Kanji only have one. We will display the "Kun" reading(s) in Hiragana and separate the reading from the trailing Hiragana (the Hiragana which follows the Kanji and causes it to have that reading) by a "・".</p>

<p><strong>Note:</strong> Examples will be written out in Hiragana regardless of which reading they are displaying.</p>

<p>Also note that many Japanese nouns can be made into a verb simply by adding "suru" (to do). For instance, the noun 勉強 (benkyou - "study"), which is covered in <Link to="/members/a/kanji/grade03-01/">Grade 3 Kanji - Part 1 of 5</Link>, can be turned into "to study" by adding "suru" thereby making it 勉強する (benkyou suru - "to study"). Most of these should be pretty easy to identify by the English translation (especially if it ends in "ing").</p>

    </>
  )
}

export default KanjiIntro