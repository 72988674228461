import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Grade 5 Kanji - Part 2 of 5"

const GradeFiveTwo = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Advanced" category="Kanji" lesson={title} />
     
      <h1>{title}</h1>

      <p>Below are Kanji the Japanese learn in grade 5 of elementary school.
Be sure to read the <Link to="/members/a/kanji/intro/">Introduction to Kanji</Link> if you haven't already.</p>

<p>Legend:</p>
<p>[n] = Noun</p>
<p>[dv] = Descriptive word (true adjective) - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[dn] = Descriptive noun - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[gv] = Godan verb - see <Link to="/members/b/grammar/godan-verbs/">Verb Conjugation (Godan)</Link></p>
<p>[iv] = Ichidan verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[irv] = Irregular verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[c] = Counter</p>

<table>
<thead>
  <tr>
<th>漢字<br /><Play audio="kanji" /></th>
<th>音読み <Play audio="onyomi" /><br />"On" Reading</th>
<th>訓読み <Play audio="kunyomi" /><br />"Kun" Reading</th>
<th>English Meaning</th>
</tr>
  </thead>
<tbody>
<tr>
<th><span style={{fontSize:`4rem`}}>容</span></th>
<td>ヨウ (you)</td>
<td></td>
<td>
Contain
<br />
<table><tbody><tr><td>Examples:
<br />
内容 (ないよう - naiyou - subject, contents, matter, substance, detail) [n] <Play audio="naiyou" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>寄</span></th>
<td>キ (ki)</td>
<td>よ・る (yoru)<br />よ・せる (yoseru)<br />よ・せ (yose)</td>
<td>
Approach
<br />
<table><tbody><tr><td>Examples:
<br />
寄付 (きふ - kifu - contribution, donation) [n] <Play audio="kifu" />
<br />
寄る (よる - yoru - to approach, to draw near, to come near, to be close to, to gather (in one place), to come together, to stop by (while on one's way to another place), to drop by, to make a short visit) [gv] <Play audio="yoru" />
<br />
寄せる (よせる - yoseru - to come near, to let someone approach, to bring near, to bring together, to collect, to gather, to let someone drop by) [iv] <Play audio="yoseru" />
<br />
寄せ (よせ - yose - last moves (in go, shogi, or chess), endgame, gathering, collecting, coming together) [n] <Play audio="yose" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>富</span></th>
<td>フ (fu)<br />フウ (fuu)</td>
<td>と・む (tomu)<br />とみ (tomi)</td>
<td>
Rich
<br />
<table><tbody><tr><td>Examples:
<br />
豊富 (ほうふ - houfu - abundance, wealth, plenty, bounty) [dn] <Play audio="houfu" />
<br />
富貴 (ふうき - fuuki - riches and honors, wealth and rank) [dn] <Play audio="fuuki" />
<br />
富む (とむ - tomu - to be rich, to become rich) [gv] <Play audio="tomu" />
<br />
富 (とみ - tomi - wealth, fortune) [n] <Play audio="tomi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>導</span></th>
<td>ドウ (dou)</td>
<td>みちび・く (michibiku)</td>
<td>
Guide
<br />
<table><tbody><tr><td>Examples:
<br />
指導 (しどう - shidou - leadership, guidance, coaching) [n] <Play audio="shidou" />
<br />
導く (みちびく - michibiku - to guide, to lead, to show the way, to conduct) [gv] <Play audio="michibiku" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>居</span></th>
<td>キョ (kyo)</td>
<td>い・る (iru)<br /></td>
<td>
Reside
<br />
<table><tbody><tr><td>Examples:
<br />
住居 (じゅうきょ - juukyo - dwelling, house, residence, address) [n] <Play audio="juukyo" />
<br />
居る (いる - iru - to be (of animate objects), to exist) [iv] <Play audio="iru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>属</span></th>
<td>ゾク (zoku)</td>
<td></td>
<td>
Belong
<br />
<table><tbody><tr><td>Examples:
<br />
所属 (しょぞく - shozoku - attached to, belong to, member) [n] <Play audio="shozoku" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>布</span></th>
<td>フ (fu)</td>
<td>ぬの (nuno)</td>
<td>
Linen
<br />
<table><tbody><tr><td>Examples:
<br />
毛布 (もうふ - moufu - blanket) [n] <Play audio="moufu" />
<br />
布 (ぬの - nuno - cloth) [n] <Play audio="nuno" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>師</span></th>
<td>シ (shi)</td>
<td></td>
<td>
Expert
<br />
<table><tbody><tr><td>Examples:
<br />
教師 (きょうし - kyoushi - teacher (classroom)) [n] <Play audio="kyoushi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>常</span></th>
<td>ジョウ (jou)</td>
<td>つね (tsune)<br />とこ (toko)</td>
<td>
Normal
<br />
<table><tbody><tr><td>Examples:
<br />
日常 (にちじょう - nichijou - ordinary, regular, everyday, usual) <Play audio="nichijou" />
<br />
常 (つね - tsune - usual state of things) [n] <Play audio="tsune" />
<br />
常滑 (とこなめ - tokoname - Tokoname (city)) [n] <Play audio="tokoname" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>幹</span></th>
<td>カン (kan)</td>
<td>みき (miki)</td>
<td>
Tree-trunk
<br />
<table><tbody><tr><td>Examples:
<br />
幹線 (かんせん - kansen - main line, trunk line) [n] <Play audio="kansen" />
<br />
新幹線 (しんかんせん - shinkansen - bullet train (very high speed)) [n] <Play audio="shinkansen" />
<br />
幹 (みき - miki - (tree) trunk, (arrow) shaft, (tool) handle, backbone, base) [n] <Play audio="miki" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>序</span></th>
<td>ジョ (jo)</td>
<td></td>
<td>
Preface
<br />
<table><tbody><tr><td>Examples:
<br />
順序 (じゅんじょ - junjo - order, sequence, procedure) [n] <Play audio="junjo" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>弁</span></th>
<td>ベン (ben)</td>
<td></td>
<td>
Valve
<br />
<table><tbody><tr><td>Examples:
<br />
心臓弁 (しんぞうべん - shinzouben - heart valve, cardiac valve) [n] <Play audio="shinzouben" />
<br />
弁当 (べんとう - bentou - Japanese box lunch) [n] <Play audio="bentou" />
<br />
関西弁 (かんさいべん - kansaiben - Kansai dialect) [n] <Play audio="kansaiben" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>張</span></th>
<td>チョウ (chou)</td>
<td>は・る (haru)<br />は・り (hari)</td>
<td>
Stretch
<br />
<table><tbody><tr><td>Examples:
<br />
緊張 (きんちょう - kinchou - tension, mental strain, nervousness) [n] <Play audio="kinchou" />
<br />
張る (はる - haru - to stick, to paste, to affix, to stretch, to spread, to strain, to tighten) [gv] <Play audio="haru" />
<br />
張り (はり - hari - stretch, tension) [n] <Play audio="hari" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>往</span></th>
<td>オウ (ou)</td>
<td></td>
<td>
Journey
<br />
<table><tbody><tr><td>Examples:
<br />
往復 (おうふく - oufuku - making a round trip, going and returning, coming and going) [n] <Play audio="oufuku" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>復</span></th>
<td>フク (fuku)</td>
<td></td>
<td>
Repeating
<br />
<table><tbody><tr><td>Examples:
<br />
回復 (かいふく - kaifuku - restoration, rehabilitation, recovery, return, improvement) [n] <Play audio="kaifuku" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>徳</span></th>
<td>トク (toku)</td>
<td></td>
<td>
Virtue
<br />
<table><tbody><tr><td>Examples:
<br />
道徳 (どうとく - doutoku - morals) [n] <Play audio="doutoku" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>志</span></th>
<td>シ (shi)</td>
<td>こころざ・す (kokorozasu)<br />こころざし (kokorozashi)</td>
<td>
Intention
<br />
<table><tbody><tr><td>Examples:
<br />
意志 (いし - ishi - will, volition, intention, intent, determination) [n] <Play audio="ishi" />
<br />
志す (こころざす - kokorozasu - to plan, to intend, to aspire to, to set aims (sights on)) [gv] <Play audio="kokorozasu" />
<br />
志 (こころざし - kokorozashi - will, intention, motive) [n] <Play audio="kokorozashi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>応</span></th>
<td>オウ (ou)</td>
<td></td>
<td>
Respond
<br />
<table><tbody><tr><td>Examples:
<br />
応答 (おうとう - outou - reply, answer, response) [n] <Play audio="outou" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>快</span></th>
<td>カイ (kai)</td>
<td>こころよ・い (kokoroyoi)</td>
<td>
Cheerful
<br />
<table><tbody><tr><td>Examples:
<br />
快調 (かいちょう - kaichou - harmony, excellent condition) [dn] <Play audio="kaichou" />
<br />
快い (こころよい - kokoroyoi - pleasant, agreeable) [dv] <Play audio="kokoroyoi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>性</span></th>
<td>ショウ (shou)<br />セイ (sei)</td>
<td>さが (saga)</td>
<td>
Gender
<br />
<table><tbody><tr><td>Examples:
<br />
相性 (あいしょう - aishou - affinity, compatibility) [n] <Play audio="aishou" />
<br />
性格 (せいかく - seikaku - character, personality, disposition, nature) [n] <Play audio="seikaku" />
<br />
男性 (だんせい - dansei - man, male) [n] <Play audio="dansei" />
<br />
女性 (じょせい - josei - woman, female) [n] <Play audio="josei" />
<br />
性 (さが - saga - one's nature, one's destiny, custom, tradition, habit) [n] <Play audio="saga" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>恩</span></th>
<td>オン (on)</td>
<td></td>
<td>
Grace
<br />
<table><tbody><tr><td>Examples:
<br />
恩恵 (おんけい - onkei - grace, favor, blessing, benefit) [n] <Play audio="onkei" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>情</span></th>
<td>ジョウ (jou)<br />セイ (sei - rarely used)</td>
<td>なさ・け (nasake)</td>
<td>
Feelings
<br />
<table><tbody><tr><td>Examples:
<br />
同情 (どうじょう - doujou - sympathy, compassion, pity) [n] <Play audio="doujou" />
<br />
情け (なさけ - nasake - pity, sympathy, compassion, affection) [n] <Play audio="nasake" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>態</span></th>
<td>タイ (tai)</td>
<td></td>
<td>
Condition
<br />
<table><tbody><tr><td>Examples:
<br />
態度 (たいど - taido - attitude, manner, behavior) [n] <Play audio="taido" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>慣</span></th>
<td>カン (kan)</td>
<td>な・れる (nareru)<br />な・らす (narasu)<br />な・れ (nare)</td>
<td>
Accustomed
<br />
<table><tbody><tr><td>Examples:
<br />
習慣 (しゅうかん - shuukan - custom, habit, manners) [n] <Play audio="shuukan" />
<br />
慣れる (なれる - nareru - to grow accustomed (to), to get too familiar with, to get used (to), to become domesticated, to become tame) [iv] <Play audio="nareru" />
<br />
慣らす (ならす - narasu - to accustom) [gv] <Play audio="narasu" />
<br />
慣れ (なれ - nare - practice, experience) [n] <Play audio="nare" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>承</span></th>
<td>ショウ (shou)</td>
<td>うけたまわ・る (uketamawaru)</td>
<td>
Acquiesce
<br />
<table><tbody><tr><td>Examples:
<br />
承知 (しょうち - shouchi - acknowledgment, awareness, consent, acceptance, assent, admitting, compliance, agreement) [n] <Play audio="shouchi" />
<br />
承る (うけたまわる - uketamawaru - to hear, to be told, to know, to receive (order), to undertake, to comply, to take (a reservation, etc.)) [gv] <Play audio="uketamawaru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>技</span></th>
<td>ギ (gi)</td>
<td>わざ (waza)</td>
<td>
Skill
<br />
<table><tbody><tr><td>Examples:
<br />
特技 (とくぎ - tokugi - special skill) [n] <Play audio="tokugi" />
<br />
技 (わざ - waza - technique, art) [n] <Play audio="waza" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>招</span></th>
<td>ショウ (shou)</td>
<td>まね・く (maneku)</td>
<td>
Beckon
<br />
<table><tbody><tr><td>Examples:
<br />
招待 (しょうたい - shoutai - invitation) [n] <Play audio="shoutai" />
<br />
招く (まねく - maneku - to invite, to ask, to beckon, to wave someone in, to gesture to, to call in, to send for, to summon, to bring on oneself, to cause, to incur, to lead to, to result in) [gv] <Play audio="maneku" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>授</span></th>
<td>ジュ (ju)</td>
<td>さず・ける (sazukeru)<br />さず・かる (sazukaru)</td>
<td>
Instruct
<br />
<table><tbody><tr><td>Examples:
<br />
教授 (きょうじゅ - kyouju - professor, teaching, instruction) [n] <Play audio="kyouju" />
<br />
授ける (さずける - sazukeru - to grant, to award, to teach) [iv] <Play audio="sazukeru" />
<br />
授かる (さずかる - sazukaru - to be awarded (e.g. a prize), to be given an award, to receive (e.g. a title, to be gifted or endowed (e.g. with a talent), to be blessed (e.g. with a child), to be initiated (e.g. into a secret)) [gv] <Play audio="sazukaru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>採</span></th>
<td>サイ (sai)</td>
<td>と・る (toru)</td>
<td>
Pick
<br />
<table><tbody><tr><td>Examples:
<br />
採取 (さいしゅ - saishu - picking, collecting, harvesting) [n] <Play audio="saishu" />
<br />
採る (とる - toru - to adopt (measure, proposal), to pick (e.g. fruit), to catch (e.g. insects), to take (e.g. a sample), to assume (attitude), to take on (i.e. hire), to engage) [gv] <Play audio="toru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>接</span></th>
<td>セツ (setsu)</td>
<td>つ・ぐ (tsugu)<br />せっ・する (sessuru)</td>
<td>
Contact
<br />
<table><tbody><tr><td>Examples:
<br />
直接 (ちょくせつ - chokusetsu - direct, immediate, personal, firsthand) [dn] <Play audio="chokusetsu" />
<br />
接ぐ (つぐ - tsugu - to join, to piece together, to set (bones), to graft (trees)) [gv] <Play audio="tsugu" />
<br />
接する (せっする - sessuru - to come in contact with, to connect (with), to attend (to), to receive (visitors)) [irv] <Play audio="sessuru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>提</span></th>
<td>テイ (tei)</td>
<td>さ・げる (sageru)</td>
<td>
Present
<br />
<table><tbody><tr><td>Examples:
<br />
提案 (ていあん - teian - proposal, proposition, suggestion) [n] <Play audio="teian" />
<br />
提げる (さげる - sageru - to take along, to hold in the hand) [iv] <Play audio="sageru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>損</span></th>
<td>ソン (son)</td>
<td>そこ・なう (sokonau)<br />そこ・ねる (sokoneru)</td>
<td>
Loss
<br />
<table><tbody><tr><td>Examples:
<br />
損害 (そんがい - songai - damage, injury, loss) [n] <Play audio="songai" />
<br />
損なう (そこなう - sokonau - to harm, to hurt, to injure, to damage, to fail in doing) [gv] <Play audio="sokonau" />
<br />
損ねる (そこねる - sokoneru - to harm, to hurt, to injure, to wreck) [iv] <Play audio="sokoneru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>支</span></th>
<td>シ (shi)</td>
<td>ささ・える (sasaeru)</td>
<td>
Branch, Support
<br />
<table><tbody><tr><td>Examples:
<br />
支持 (しじ - shiji - support, holding up, propping, backing, endorsing) [n] <Play audio="shiji" />
<br />
支える (ささえる - sasaeru - to support, to prop, to sustain, to underlay, to hold up, to hold at bay, to stem) [iv] <Play audio="sasaeru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>政</span></th>
<td>ショウ (shou - rarely used)<br />セイ (sei)</td>
<td>まつりごと (matsurigoto)</td>
<td>
Politics
<br />
<table><tbody><tr><td>Examples:
<br />
政治 (せいじ - seiji - politics, government) [n] <Play audio="seiji" />
<br />
政 (まつりごと - matsurigoto - rule, government) [n] <Play audio="matsurigoto" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>故</span></th>
<td>コ (ko)</td>
<td>ゆえ (yue)</td>
<td>
Circumstances
<br />
<table><tbody><tr><td>Examples:
<br />
故郷 (こきょう - kokyou - home town, birthplace, old village, historic village, native place, one's old home) [n] <Play audio="kokyou" />
<br />
故 (ゆえ - yue - reason, cause, circumstances) [n] <Play audio="yue" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>敵</span></th>
<td>テキ (teki)</td>
<td>かたき (kataki)</td>
<td>
Enemy
<br />
<table><tbody><tr><td>Examples:
<br />
敵対 (てきたい - tekitai - hostility, antagonism, opposition) [n] <Play audio="tekitai" />
<br />
敵 (かたき - kataki - rival, opponent, adversary, competitor, enemy (esp. one with which there is longstanding enmity), foe, revenge) [n] <Play audio="kataki" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>断</span></th>
<td>ダン (dan)</td>
<td>た・つ (tatsu)<br />ことわ・る (kotowaru)</td>
<td>
Cut Off
<br />
<table><tbody><tr><td>Examples:
<br />
断食 (だんじき - danjiki - fasting) [n] <Play audio="danjiki" />
<br />
断つ (たつ - tatsu - to sever, to cut off, to suppress, to abstain (from)) [gv] <Play audio="tatsu" />
<br />
断る (ことわる - kotowaru - to refuse, to reject, to dismiss, to turn down, to decline, to inform, to give notice, to tell in advance, to ask leave, to excuse oneself (from)) [gv] <Play audio="kotowaru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>旧</span></th>
<td>キュウ (kyuu)</td>
<td></td>
<td>
Old Times
<br />
<table><tbody><tr><td>Examples:
<br />
旧年 (きゅうねん - kyuunen - the old year, last year) [n] <Play audio="kyuunen" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>易</span></th>
<td>エキ (eki)</td>
<td>やさ・しい (yasashii)</td>
<td>
Easy
<br />
<table><tbody><tr><td>Examples:
<br />
貿易 (ぼうえき - boueki - trade (foreign)) [n] <Play audio="boueki" />
<br />
易しい (やさしい - yasashii - easy, plain, simple) [dv] <Play audio="yasashii" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>暴</span></th>
<td>ボウ (bou)<br />バク (baku)</td>
<td>あば・く (abaku)<br />あば・れる (abareru)</td>
<td>
Outburst
<br />
<table><tbody><tr><td>Examples:
<br />
暴走 (ぼうそう - bousou - running wildly, reckless driving, runaway, rampage) [n] <Play audio="bousou" />
<br />
暴露 (ばくろ - bakuro - disclosure, exposure, revelation) [n] <Play audio="bakuro" />
<br />
暴く (あばく - abaku - to disclose, to divulge, to expose) [gv] <Play audio="abaku" />
<br />
暴れる (あばれる - abareru - to act violently, to rage, to struggle, to be riotous) [iv] <Play audio="abareru" />
</td></tr></tbody></table></td>
</tr>
</tbody></table>

    </>
  )
}

export default GradeFiveTwo