import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"

const title = "The Other Japanese Alphabet"

const katakanaone = [
  {"Katakana":"ア","Romaji":"a","audio":"a"},
 {"Katakana":"イ","Romaji":"i","audio":"i"},
 {"Katakana":"ウ","Romaji":"u","audio":"u"},
 {"Katakana":"エ","Romaji":"e","audio":"e"},
 {"Katakana":"オ","Romaji":"o","audio":"o"},
 {"Katakana":"カ","Romaji":"ka","audio":"ka"},
 {"Katakana":"キ","Romaji":"ki","audio":"ki"},
 {"Katakana":"ク","Romaji":"ku","audio":"ku"},
 {"Katakana":"ケ","Romaji":"ke","audio":"ke"},
 {"Katakana":"コ","Romaji":"ko","audio":"ko"},
 {"Katakana":"ガ","Romaji":"ga","audio":"ga"},
 {"Katakana":"ギ","Romaji":"gi","audio":"gi"},
 {"Katakana":"グ","Romaji":"gu","audio":"gu"},
 {"Katakana":"ゲ","Romaji":"ge","audio":"ge"},
 {"Katakana":"ゴ","Romaji":"go","audio":"go"},
 {"Katakana":"サ","Romaji":"sa","audio":"sa"},
 {"Katakana":"シ","Romaji":"shi","audio":"shi"},
 {"Katakana":"ス","Romaji":"su","audio":"su"},
 {"Katakana":"セ","Romaji":"se","audio":"se"},
 {"Katakana":"ソ","Romaji":"so","audio":"so"},
 {"Katakana":"ザ","Romaji":"za","audio":"za"},
 {"Katakana":"ジ","Romaji":"ji","audio":"ji"},
 {"Katakana":"ズ","Romaji":"zu","audio":"zu"},
 {"Katakana":"ゼ","Romaji":"ze","audio":"ze"},
 {"Katakana":"ゾ","Romaji":"zo","audio":"zo"},
 {"Katakana":"タ","Romaji":"ta","audio":"ta"},
 {"Katakana":"チ","Romaji":"chi","audio":"chi"},
 {"Katakana":"ツ","Romaji":"tsu","audio":"tsu"},
 {"Katakana":"テ","Romaji":"te","audio":"te"},
 {"Katakana":"ト","Romaji":"to","audio":"to"},
 {"Katakana":"ダ","Romaji":"da","audio":"da"},
 {"Katakana":"ヂ","Romaji":"ji","audio":"ji"},
 {"Katakana":"ヅ","Romaji":"zu","audio":"zu"},
 {"Katakana":"デ","Romaji":"de","audio":"de"},
 {"Katakana":"ド","Romaji":"do","audio":"do"},
 {"Katakana":"ナ","Romaji":"na","audio":"na"},
 {"Katakana":"ニ","Romaji":"ni","audio":"ni"},
 {"Katakana":"ヌ","Romaji":"nu","audio":"nu"},
 {"Katakana":"ネ","Romaji":"ne","audio":"ne"},
 {"Katakana":"ノ","Romaji":"no","audio":"no"},
 {"Katakana":"ハ","Romaji":"ha","audio":"ha"},
 {"Katakana":"ヒ","Romaji":"hi","audio":"hi"},
 {"Katakana":"フ","Romaji":"fu","audio":"fu"},
 {"Katakana":"ヘ","Romaji":"he","audio":"he"},
 {"Katakana":"ホ","Romaji":"ho","audio":"ho"},
 {"Katakana":"バ","Romaji":"ba","audio":"ba"},
 {"Katakana":"ビ","Romaji":"bi","audio":"bi"},
 {"Katakana":"ブ","Romaji":"bu","audio":"bu"},
 {"Katakana":"ベ","Romaji":"be","audio":"be"},
 {"Katakana":"ボ","Romaji":"bo","audio":"bo"},
 {"Katakana":"パ","Romaji":"pa","audio":"pa"},
 {"Katakana":"ピ","Romaji":"pi","audio":"pi"},
 {"Katakana":"プ","Romaji":"pu","audio":"pu"},
 {"Katakana":"ペ","Romaji":"pe","audio":"pe"},
 {"Katakana":"ポ","Romaji":"po","audio":"po"},
 {"Katakana":"マ","Romaji":"ma","audio":"ma"},
 {"Katakana":"ミ","Romaji":"mi","audio":"mi"},
 {"Katakana":"ム","Romaji":"mu","audio":"mu"},
 {"Katakana":"メ","Romaji":"me","audio":"me"},
 {"Katakana":"モ","Romaji":"mo","audio":"mo"},
 {"Katakana":"ヤ","Romaji":"ya","audio":"ya"},
 {"Katakana":"ユ","Romaji":"yu","audio":"yu"},
 {"Katakana":"ヨ","Romaji":"yo","audio":"yo"},
 {"Katakana":"ラ","Romaji":"ra","audio":"ra"},
 {"Katakana":"リ","Romaji":"ri","audio":"ri"},
 {"Katakana":"ル","Romaji":"ru","audio":"ru"},
 {"Katakana":"レ","Romaji":"re","audio":"re"},
 {"Katakana":"ロ","Romaji":"ro","audio":"ro"},
 {"Katakana":"ワ","Romaji":"wa","audio":"wa"},
 {"Katakana":"ヲ","Romaji":"wo","audio":"wo"},
 {"Katakana":"ン","Romaji":"n/m","audio":"n/m"}
]

const katakanatwo = [
  {"Katakana":"キャ","Romaji":"kya","audio":"kya"},
 {"Katakana":"キュ","Romaji":"kyu","audio":"kyu"},
 {"Katakana":"キョ","Romaji":"kyo","audio":"kyo"},
 {"Katakana":"ギャ","Romaji":"gya","audio":"gya"},
 {"Katakana":"ギュ","Romaji":"gyu","audio":"gyu"},
 {"Katakana":"ギョ","Romaji":"gyo","audio":"gyo"},
 {"Katakana":"シャ","Romaji":"sha","audio":"sha"},
 {"Katakana":"シュ","Romaji":"shu","audio":"shu"},
 {"Katakana":"ショ","Romaji":"sho","audio":"sho"},
 {"Katakana":"ジャ","Romaji":"ja","audio":"ja"},
 {"Katakana":"ジュ","Romaji":"ju","audio":"ju"},
 {"Katakana":"ジョ","Romaji":"jo","audio":"jo"},
 {"Katakana":"チャ","Romaji":"cha","audio":"cha"},
 {"Katakana":"チュ","Romaji":"chu","audio":"chu"},
 {"Katakana":"チョ","Romaji":"cho","audio":"cho"},
 {"Katakana":"ニャ","Romaji":"nya","audio":"nya"},
 {"Katakana":"ニュ","Romaji":"nyu","audio":"nyu"},
 {"Katakana":"ニョ","Romaji":"nyo","audio":"nyo"},
 {"Katakana":"ヒャ","Romaji":"hya","audio":"hya"},
 {"Katakana":"ヒュ","Romaji":"hyu","audio":"hyu"},
 {"Katakana":"ヒョ","Romaji":"hyo","audio":"hyo"},
 {"Katakana":"ビャ","Romaji":"bya","audio":"bya"},
 {"Katakana":"ビュ","Romaji":"byu","audio":"byu"},
 {"Katakana":"ビョ","Romaji":"byo","audio":"byo"},
 {"Katakana":"ピャ","Romaji":"pya","audio":"pya"},
 {"Katakana":"ピュ","Romaji":"pyu","audio":"pyu"},
 {"Katakana":"ピョ","Romaji":"pyo","audio":"pyo"},
 {"Katakana":"ミャ","Romaji":"mya","audio":"mya"},
 {"Katakana":"ミュ","Romaji":"myu","audio":"myu"},
 {"Katakana":"ミョ","Romaji":"myo","audio":"myo"},
 {"Katakana":"リャ","Romaji":"rya","audio":"rya"},
 {"Katakana":"リュ","Romaji":"ryu","audio":"ryu"},
 {"Katakana":"リョ","Romaji":"ryo","audio":"ryo"}
]

const Katakana = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Beginner" category="Phrases" lesson={title} />
     <div className={'title'}>
     Katakana
      </div>
      <h1>{title}</h1>

      <h2>Katakana - カタカナ</h2>

<p>If you are unable to hear the audio, click here for the alternate audio method.  
As mentioned <Link to="/members/b/basics/hiragana/">in the Hiragana lesson</Link>, Katakana  (as seen in the chart below) is mainly used for foreign words such as foreign names and words that have been borrowed from other languages such as コンピューター  (kompyu-ta- = computer). Katakana is the least frequently used of the three character sets in Japanese. The Japanese language does not have as many sounds as the English language so, when creating a name in Katakana , for instance, the name must be sound out to the closest Japanese equivalent. Katakana , Hiragana , and Furigana  (when Hiragana  is written small next to a Kanji  character to show how that character is read) are collectively known as Kana . Don't worry too much about Katakana (beyond maybe learning your name) for now.</p>

<p>There are some special Katakana characters that exist only for foreign names (such as シェ (she) or ティ (ti)). These special characters are not in the chart below (which contains only the standard set of Katakana characters) but can be seen in our <Link to="/members/b/basics/names/">Names in Japanese lesson</Link>. There are also the characters ヰ (wi) and ヱ (we) which were deemed obsolete in 1946.</p>

<p>Click on the image next to each character to hear how they are pronounced.</p>

<Table data={katakanaone} />

<h2>Combinations</h2>

<Table data={katakanatwo} />

    </>
  )
}

export default Katakana