import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"
import theme from "../../../src/components/theme"

const title = "Grade 3 Kanji - Part 1 of 5"

const GradeThreeOne = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Advanced" category="Kanji" lesson={title} />

      <h1>{title}</h1>

      <p>Below are Kanji the Japanese learn in grade 3 of elementary school.
Be sure to read the <Link to="/members/a/kanji/intro/">Introduction to Kanji</Link> if you haven't already.</p>

<p><strong>Legend:</strong></p>
<p>[n] = Noun</p>
<p>[dv] = Descriptive word (true adjective) - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[dn] = Descriptive noun - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[gv] = Godan verb - see <Link to="/members/b/grammar/godan-verbs/">Verb Conjugation (Godan)</Link></p>
<p>[iv] = Ichidan verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[irv] = Irregular verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[c] = Counter</p>

<table>
<thead>
  <tr>
<th>漢字<br /><Play audio="kanji" /></th>
<th>音読み <Play audio="onyomi" /><br />"On" Reading</th>
<th>訓読み <Play audio="kunyomi" /><br />"Kun" Reading</th>
<th>English Meaning</th>
</tr>
  </thead>
<tbody>
<tr>
<th><span style={{fontSize:`4rem`}}>丁</span></th>
<td>チョウ (chou)<br />テイ (tei)</td>
<td></td>
<td>
Pair
<br />
<table><tbody><tr><td>Examples:
<br />
丁度 (ちょうど - choudo - just, right, exactly) [dn] <Play audio="choudo" />
<br />
丁寧 (ていねい - teinei - polite, courteous, careful, care, kind, close, thorough, conscientious) [dn] <Play audio="teinei" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>世</span></th>
<td>セ (se)<br />セイ (sei)</td>
<td>よ (yo)</td>
<td>
World
<br />
<table><tbody><tr><td>Examples:
<br />
世界 (せかい - sekai - the world, society, the universe) [n] <Play audio="sekai" />
<br />
一世 (いっせい - issei - a lifetime, a generation, foreign immigrant, Japanese immigrant to USA) [n] <Play audio="issei" />
<br />
世 (よ - yo - world, society, age, generation) [n] <Play audio="yo" />
<br />
世の中 (よのなか - yo no naka - society, the world, the times) [n] <Play audio="yo no naka" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>両</span></th>
<td>リョウ (ryou)</td>
<td></td>
<td>
Both
<br />
<table><tbody><tr><td>Examples:
<br />
両親 (りょうしん - ryoushin - parents, both parents) [n] <Play audio="ryoushin" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>主</span></th>
<td>シュ (shu)</td>
<td>ぬし (nushi)<br />おも (omo)</td>
<td>
Master
<br />
<table><tbody><tr><td>Examples:
<br />
主人 (しゅじん - shujin - head (of a household), one's husband, (one's) master) [n] <Play audio="shujin" />
<br />
主 (ぬし - nushi - head (of a household, etc.), leader, master) [n] <Play audio="nushi" />
<br />
主 (おも - omo - chief, main, principal, important) [dn] <Play audio="omo" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>乗</span></th>
<td>ジョウ (jou)</td>
<td>の・る (noru)<br />の・せる (noseru)</td>
<td>
Ride
<br />
<table><tbody><tr><td>Examples:
<br />
乗客 (じょうきゃく - joukyaku - passenger) [n] <Play audio="joukyaku" />
<br />
乗る (のる - noru - to get on, to ride in, to board, to mount, to get up on) [gv] <Play audio="noru" />
<br />
乗せる (のせる - noseru - to place on (something), to give (someone) a ride, to give a lift, to help on board) [iv] <Play audio="noseru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>予</span></th>
<td>ヨ (yo)</td>
<td></td>
<td>
In Advance
<br />
<table><tbody><tr><td>Examples:
<br />
予算 (よさん - yosan - estimate, budget) [n] <Play audio="yosan" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>事</span></th>
<td>ジ (ji)<br />ズ (zu - rarely used)</td>
<td>こと (koto)</td>
<td>
Abstract Thing
<br />
<table><tbody><tr><td>Examples:
<br />
事故 (じこ - jiko - accident, incident, trouble) [n] <Play audio="jiko" />
<br />
事 (こと - koto - thing, matter, fact, circumstances) [n] <Play audio="koto" />
<br />
出来事 (できごと - dekigoto - incident, happening, event) [n] <Play audio="dekigoto" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>仕</span></th>
<td>ジ (ji)<br />シ (shi)</td>
<td>つか・える (tsukaeru)</td>
<td>
Serve
<br />
<table><tbody><tr><td>Examples:
<br />
給仕 (きゅうじ - kyuuji - office boy (girl), page, waiter) [n] <Play audio="kyuuji" />
<br />
仕度 (したく - shitaku - preparation, arrangements) [n] <Play audio="shitaku" />
<br />
仕える (つかえる - tsukaeru - to serve, to work for) [iv] <Play audio="tsukaeru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>他</span></th>
<td>タ (ta)</td>
<td>ほか (hoka)</td>
<td>
Other
<br />
<table><tbody><tr><td>Examples:
<br />
他人 (たにん - tanin - another person, unrelated person, outsider, stranger) [n] <Play audio="tanin" />
<br />
他 (ほか - hoka - other (esp. places and things), the rest) [n] <Play audio="hoka" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>代</span></th>
<td>ダイ (dai)<br />タイ (tai)</td>
<td>か・わる (kawaru)<br />か・える (kaeru)<br />よ (yo)<br />しろ (shiro)</td>
<td>
Substitute
<br />
<table><tbody><tr><td>Examples:
<br />
代行 (だいこう - daikou - acting as agent) [n] <Play audio="daikou" />
<br />
交代 (こうたい - koutai - alternation, change, relief, relay, shift, substitution (sports, etc.)) [n] <Play audio="koutai" />
<br />
代わる (かわる - kawaru - to be substituted for) [gv] <Play audio="kawaru" />
<br />
代える (かえる - kaeru - to substitute) [iv] <Play audio="kaeru" />
<br />
代 (よ - yo - world, society, age, generation) [n] <Play audio="yo" />
<br />
代 (しろ - shiro - substitution, material, price, margin (e.g. for stapling, etc.), area required for something) [n] <Play audio="shiro" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>住</span></th>
<td>ジュウ (juu)</td>
<td>す・む (sumu)<br />す・まう (sumau)</td>
<td>
Dwell
<br />
<table><tbody><tr><td>Examples:
<br />
住所 (じゅうしょ - juusho - address (e.g. of house), residence, domicile) [n] <Play audio="juusho" />
<br />
住む (すむ - sumu - to reside, to inhabit, to dwell, to abide) [gv] <Play audio="sumu" />
<br />
住まう (すまう - sumau - to reside, to inhabit) [gv] <Play audio="sumau" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>使</span></th>
<td>シ (shi)</td>
<td>つか・う (tsukau)</td>
<td>
Use
<br />
<table><tbody><tr><td>Examples:
<br />
使命 (しめい - shimei - mission, errand, message) [n] <Play audio="shimei" />
<br />
使う (つかう - tsukau - to use (a thing, method, etc.), to make use of, to put to use) [gv] <Play audio="tsukau" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>係</span></th>
<td>ケイ (kei)</td>
<td>かか・る (kakaru)<br />かかり (kakari)</td>
<td>
Person In Charge
<br />
<table><tbody><tr><td>Examples:
<br />
関係 (かんけい - kankei - relation, connection) [n] <Play audio="kankei" />
<br />
係る (かかる - kakaru - to be the work of, to be the result of, to be done by, to concern, to affect, to involve, to relate to) [gv] <Play audio="kakaru" />
<br />
係/係り (かかり - kakari - official, duty, person in charge) [n] <Play audio="kakari" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>倍</span></th>
<td>バイ (bai)</td>
<td></td>
<td>
Double
<br />
<table><tbody><tr><td>Examples:
<br />
倍率 (ばいりつ - bairitsu - magnification, amplification, scaling factor, scale factor) [n] <Play audio="bairitsu" />
<br />
三倍 (さんばい - sanbai - three-fold, three times) [n] <Play audio="sanbai" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>全</span></th>
<td>ゼン (zen)</td>
<td>まった・く (mattaku)</td>
<td>
Whole
<br />
<table><tbody><tr><td>Examples:
<br />
全部 (ぜんぶ - zenbu - all, entire, whole, altogether) [n] <Play audio="zenbu" />
<br />
安全 (あんぜん - anzen - safety, security) [dn] <Play audio="anzen" />
<br />
全く (まったく - mattaku - really, truly, entirely, completely, wholly, perfectly) <Play audio="mattaku" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>具</span></th>
<td>グ (gu)</td>
<td></td>
<td>
Tool
<br />
<table><tbody><tr><td>Examples:
<br />
道具 (どうぐ - dougu - implement, tool, means) [n] <Play audio="dougu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>写</span></th>
<td>シャ (sha)</td>
<td>うつ・す (utsusu)<br />うつ・る (utsuru)</td>
<td>
Copy
<br />
<table><tbody><tr><td>Examples:
<br />
写真 (しゃしん - shashin - photograph, photo) [n] <Play audio="shashin" />
<br />
写真を撮る (しゃしんをとる - shashin o toru - to take a photo) [gv] <Play audio="shashin o toru" />
<br />
写す (うつす - utsusu - to transcribe, to duplicate, to reproduce, to imitate, to trace, to film, to picture, to photograph) [gv] <Play audio="utsusu" />
<br />
写る (うつる - utsuru - to be photographed, to be projected) [gv] <Play audio="utsuru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>列</span></th>
<td>レツ (retsu)</td>
<td></td>
<td>
Row
<br />
<table><tbody><tr><td>Examples:
<br />
整列 (せいれつ - seiretsu - standing in a row, forming a line, alignment) [n] <Play audio="seiretsu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>助</span></th>
<td>ジョ (jo)</td>
<td>たす・ける (tasukeru)<br />たす・かる (tasukaru)<br />たす・け (tasuke)<br />すけ (suke)</td>
<td>
Help
<br />
<table><tbody><tr><td>Examples:
<br />
援助 (えんじょ - enjo - assistance, aid, support) [n] <Play audio="enjo" />
<br />
助ける (たすける - tasukeru - to help, to save, to rescue, to give relief to, to reinforce) [iv] <Play audio="tasukeru" />
<br />
助かる (たすかる - tasukaru - to be saved, to be rescued, to survive, to be helpful) [gv] <Play audio="tasukaru" />
<br />
助け (たすけ - tasuke - assistance) [n] <Play audio="tasuke" />
<br />
助 (すけ - suke - assistance) [n] <Play audio="suke" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>勉</span></th>
<td>ベン (ben)</td>
<td>つと・める (tsutomeru)</td>
<td>
Diligence
<br />
<table><tbody><tr><td>Examples:
<br />
勉強 (べんきょう - benkyou - study, diligence) [n] <Play audio="benkyou" />
<br />
勉強する (べんきょうする - benkyou suru - to study) [irv] <Play audio="benkyou suru" />
<br />
勉める (つとめる - tsutomeru - to endeavor, to try, to strive, to make an effort, to exert oneself, to be diligent) [iv] <Play audio="tsutomeru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>動</span></th>
<td>ドウ (dou)</td>
<td>うご・く (ugoku)<br />うご・かす (ugokasu)</td>
<td>
Move
<br />
<table><tbody><tr><td>Examples:
<br />
動物 (どうぶつ - doubutsu - animal(s)) [n] <Play audio="doubutsu" />
<br />
動く (うごく - ugoku - to move (one's body)) [gv] <Play audio="ugoku" />
<br />
動かす (うごかす - ugokasu - to cause to move, to set in motion, to inspire, to influence) [gv] <Play audio="ugokasu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>勝</span></th>
<td>ショウ (shou)</td>
<td>か・つ (katsu)<br />まさ・る (masaru)</td>
<td>
Win
<br />
<table><tbody><tr><td>Examples:
<br />
勝負 (しょうぶ - shoubu - victory or defeat, match, contest, game, bout) [n] <Play audio="shoubu" />
<br />
勝つ (かつ - katsu - to win, to gain victory) [gv] <Play audio="katsu" />
<br />
勝る (まさる - masaru - to excel, to surpass, to exceed, to have an edge, to be superior) [gv] <Play audio="masaru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>化</span></th>
<td>ケ (ke)<br />カ (ka)</td>
<td>ば・ける (bakeru)<br />ば・かす (bakasu)</td>
<td>
Disguise
<br />
<table><tbody><tr><td>Examples:
<br />
化粧 (けしょう - keshou - make-up, makeup, cosmetics) [n] <Play audio="keshou" />
<br />
化学 (かがく - kagaku - chemistry) [n] <Play audio="kagaku" />
<br />
化ける (ばける - bakeru - to appear in disguise, to take the form of, to change for the worse, to corrupt) [iv] <Play audio="bakeru" />
<br />
化かす (ばかす - bakasu - to bewitch, to confuse, to enchant, to delude) [gv] <Play audio="bakasu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>区</span></th>
<td>ク (ku)<br />オウ (ou - rarely used)<br />コウ (kou - rarely used)</td>
<td></td>
<td>
Ward
<br />
<table><tbody><tr><td>Examples:
<br />
区 (く - ku - ward, district, section) [n] <Play audio="ku" />
<br />
区域 (くいき - kuiki - limits, boundary, domain, zone, sphere, territory) [n] <Play audio="kuiki" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>医</span></th>
<td>イ (i)</td>
<td></td>
<td>
Doctor
<br />
<table><tbody><tr><td>Examples:
<br />
医学 (いがく - igaku - medical science, the practice of medicine) [n] <Play audio="igaku" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>去</span></th>
<td>コ (ko)<br />キョ (kyo)</td>
<td>さ・る (saru)</td>
<td>
Leave
<br />
<table><tbody><tr><td>Examples:
<br />
過去　(かこ - kako - the past, the previous, one's past) [n] <Play audio="kako" />
<br />
逝去　(せいきょ - seikyo - death) [n] <Play audio="seikyo" />
<br />
去る (さる - saru - to leave, to go away, to elapse, to be distant) [gv] <Play audio="saru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>反</span></th>
<td>ホン (hon)<br />ハン (han)<br />タン (tan)</td>
<td>そ・る　(soru)<br />そ・らす (sorasu)</td>
<td>
Anti-
<br />
<table><tbody><tr><td>Examples:
<br />
謀反 (むほん - muhon - rebellion, uprising, insurrection) [n] <Play audio="muhon" />
<br />
反対 (はんたい - hantai - opposition, resistance, antagonism, hostility, contrast, objection, dissension, reverse, opposite, vice versa) [dn] <Play audio="hantai" />
<br />
一反 (いったん - ittan - one-tenth hectare) [n] <Play audio="ittan" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>取</span></th>
<td>シュ (shu)</td>
<td>と・る (toru)</td>
<td>
Take
<br />
<table><tbody><tr><td>Examples:
<br />
取得 (しゅとく - shutoku - acquisition) [n] <Play audio="shutoku" />
<br />
取る (とる - toru - to take, to pick up, to harvest, to earn, to choose) [gv] <Play audio="toru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>受</span></th>
<td>ジュ (shu)</td>
<td>う・ける (ukeru)<br />う・かる (ukaru)</td>
<td>
Receive
<br />
<table><tbody><tr><td>Examples:
<br />
受章 (じゅしょう - jushou - reception of a decoration or order) [n] <Play audio="jushou" />
<br />
受ける (うける - ukeru - to receive, to accept, to get, to inherit) [iv] <Play audio="ukeru" />
<br />
受かる (うかる - ukaru - to pass (an examination)) [gv] <Play audio="ukaru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>号</span></th>
<td>ゴウ (gou)</td>
<td></td>
<td>
Number
<br />
<table><tbody><tr><td>Examples:
<br />
号泣 (ごうきゅう - goukyuu - crying aloud, lamentation, wailing) [n] <Play audio="goukyuu" />
<br />
番号 (ばんごう - bangou - number, series of digits) [n] <Play audio="bangou" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>向</span></th>
<td>コウ (kou)</td>
<td>む・く (muku)<br />む・き (muki)<br />む・ける (mukeru)<br />む・かう (mukau)<br />む・こう (mukou)</td>
<td>
To Face
<br />
<table><tbody><tr><td>Examples:
<br />
方向 (ほうこう - houkou - direction, orientation, bearing, way) [n] <Play audio="houkou" />
<br />
向く (むく - muku - to face, to turn toward, to be suited to, to be fit for) [gv] <Play audio="muku" />
<br />
向き (むき - muki - direction, orientation, aspect, situation, exposure, suitability, tendency) [dn] <Play audio="muki" />
<br />
向ける (むける - mukeru - to turn towards, to point) [iv] <Play audio="mukeru" />
<br />
向かう (むかう - mukau - to face, to go towards) [gv] <Play audio="mukau" />
<br />
向こう (むこう - mukou - opposite side, other side, opposite direction, over there, that way, far away, beyond, the other party, the other person, future (starting now)) [n] <Play audio="mukou" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>君</span></th>
<td>クン (kun)</td>
<td>きみ (kimi)</td>
<td>
You
<br />
<table><tbody><tr><td>Examples:
<br />
君子 (くんし - kunshi - man of virtue, person of high rank, wise man) [n] <Play audio="kunshi" />
<br />
君 (きみ - kimi - familiar form of "you", buddy, pal) [n] <Play audio="kimi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>味</span></th>
<td>ミ (mi)</td>
<td>あじ (aji)<br />あじ・わう (ajiwau)</td>
<td>
Flavor
<br />
<table><tbody><tr><td>Examples:
<br />
味覚 (みかく - mikaku - taste, palate, sense of taste) [dn] <Play audio="mikaku" />
<br />
味 (あじ - aji - as noun: flavor, taste, charm, style, experience; as descriptive noun: smart, clever, witty, strange) [dn] <Play audio="aji" />
<br />
味わう (あじわう - ajiwau - to taste, to savor, to relish) [gv] <Play audio="ajiwau" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>命</span></th>
<td>ミョウ (myou)<br />メイ (mei)</td>
<td>いのち (inochi)</td>
<td>
Life
<br />
<table><tbody><tr><td>Examples:
<br />
寿命 (じゅみょう - jumyou - life span) [n] <Play audio="jumyou" />
<br />
命令 (めいれい - meirei - order, command, decree, directive) [n] <Play audio="meirei" />
<br />
命 (いのち - inochi - (mortal) life) [n] <Play audio="inochi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>和</span></th>
<td>ワ (wa)<br />オ (o)</td>
<td>やわ・らぐ (yawaragu)<br />やわ・らげる (yawarageru)<br />なご・む (nagomu)<br />なご・やか (nagoyaka)</td>
<td>
Peace, Sum
<br />
<table><tbody><tr><td>Examples:
<br />
調和 (ちょうわ - chouwa - harmony) [n] <Play audio="chouwa" />
<br />
和尚 (おしょう - oshou - preceptor or Buddhist high priest) [n] <Play audio="oshou" />
<br />
和らぐ (やわらぐ - yawaragu - to soften, to calm down, to be mitigated) [gv] <Play audio="yawaragu" />
<br />
和らげる (やわらげる - yawarageru - to soften, to moderate, to relieve) [iv] <Play audio="yawarageru" />
<br />
和む (なごむ - nagomu - to be softened, to calm down) [gv] <Play audio="nagomu" />
<br />
和やか (なごやか - nagoyaka - mild, calm, gentle, quiet, harmonious) [dn] <Play audio="nagoyaka" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>品</span></th>
<td>ヒン (hin)</td>
<td>しな (shina)</td>
<td>
Article
<br />
<table><tbody><tr><td>Examples:
<br />
食品 (しょくひん - shokuhin - commodity, foodstuff) [n] <Play audio="shokuhin" />
<br />
品物 (しなもの - shinamono - goods, article, thing) [n] <Play audio="shinamono" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>員</span></th>
<td>イン (in)</td>
<td></td>
<td>
Member
<br />
<table><tbody><tr><td>Examples:
<br />
全員 (ぜんいん - zenin (zen'in) - all members (unanimity), all hands, the whole crew, everyone, everybody) [n] <Play audio="zen-in" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>商</span></th>
<td>ショウ (shou)</td>
<td>あきな・う (akinau)<br />あきな・い (akinai)</td>
<td>
Commerce
<br />
<table><tbody><tr><td>Examples:
<br />
商売 (しょうばい - shoubai - trade, business, commerce, transaction, occupation) [n] <Play audio="shoubai" />
<br />
商う (あきなう - akinau - to sell, to handle, to trade in) [gv] <Play audio="akinau" />
<br />
商い (あきない - akinai - trade, business, trading, dealing) [n] <Play audio="akinai" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>問</span></th>
<td>モン (mon)<br />ブン (bun - rarely used)</td>
<td>と・う (tou)<br />と・い (toi)<br />とん (ton)</td>
<td>
Question
<br />
<table><tbody><tr><td>Examples:
<br />
問題 (もんだい - mondai - problem, question) [n] <Play audio="mondai" />
<br />
質問 (しつもん - shitsumon - question, inquiry) [n] <Play audio="shitsumon" />
<br />
問う (とう - tou - to ask, to question, to inquire, to charge (i.e. with a crime), to accuse, to care (about), without regard to (with negative verb)) [gv] <Play audio="tou" />
<br />
問い (とい - toi - question, query) [n] <Play audio="toi" />
<br />
問屋 (とんや - tonya - wholesale store) [n] <Play audio="tonya" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>坂</span></th>
<td>ハン (han)</td>
<td>さか (saka)</td>
<td>
Slope
<br />
<table><tbody><tr><td>Examples:
<br />
登坂 (とはん - tohan - climbing a slope (hill), ascending a hill) [n] <Play audio="tohan" />
<br />
坂 (さか - saka - slope, hill) [n] <Play audio="saka" />
</td></tr></tbody></table></td>
</tr>
</tbody></table>

    </>
  )
}

export default GradeThreeOne