import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

import { isPremiumUser } from "../../src/utils/auth"
import Checkout from "../../src/components/Checkout"

const PrivateRoute = ({ component: Component, premium, ...rest }) => {
  const paid = isPremiumUser()

  return premium && !paid ? (
    <>
      <div style={{ position: "relative" }}>
        <UpgradeOverlay>
          <Component {...rest} />
        </UpgradeOverlay>
      </div>

      <BuyBox>
        <h3>
          Upgrade to a <span style={{color:`#f44336`}}>premium membership</span> to unlock
          this lesson
        </h3>

        <div className="buy-box-description">
          For all our best Japanese lessons, high quality audio, and additional
          support, consider upgrading to a Premium Membership for a
          small, one time only fee of{" "}
          {process.env.GATSBY_PREFERRED_MEMBERSHIP_PRICE} USD. Receive lifetime access to some of the highest quality Japanese material available.
        </div>

        <Checkout
          text={`UPGRADE - ${process.env.GATSBY_PREFERRED_MEMBERSHIP_PRICE}`}
        />
      </BuyBox>
    </>
  ) : (
    <Component {...rest} />
  )
}

export default PrivateRoute

const UpgradeOverlay = styled.div`
  overflow: hidden;

  height: 350px;

  ::after {
    content: "";

    position: absolute;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background: linear-gradient(
      180deg,
      rgba(244, 244, 244, 0) 70px,
      rgba(244, 244, 244, 0.7) 140px,
      rgba(244, 244, 244, 0.98)
    );
  }
`

const BuyBox = styled.div`
  display: grid;

  gap: 32px;
  align-items: center;
  justify-items: center;

  padding: 48px 32px;

  border-radius: 2px;

  background-color: #262626;
  color: #fff;

  .buy-box-description {
    max-width: 525px;

    font-size: 14px;
    text-align: center;

    opacity: 0.7;
  }
`
