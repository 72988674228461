import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "100 Most Useful Phrases Part 1 of 4"

const commonone1 = [
  {"漢字 - Kanji":"お早うございます ","ひらがな - Hiragana":"おはようございます ","Romaji (English Letters)":"ohayou gozaimasu","English Meaning":"Good morning", "audio": "ohayou gozaimasu"}
]
const commonone2 = [
  {"漢字 - Kanji":"今日は ","ひらがな - Hiragana":"こんにちは ","Romaji (English Letters)":"konnichiwa","English Meaning":"Hello", "audio": "konnichiwa"}
]
const commonone3 = [
  {"漢字 - Kanji":"今晩は ","ひらがな - Hiragana":"こんばんは ","Romaji (English Letters)":"konbanwa","English Meaning":"Good evening", "audio": "konbanwa"}
]
const commonone4 = [
  {"漢字 - Kanji":"お休みなさい ","ひらがな - Hiragana":"おやすみなさい ","Romaji (English Letters)":"oyasuminasai","English Meaning":"Good night", "audio": "oyasuminasai"}
]
const commonone5 = [
  {"漢字 - Kanji":"頂きます ","ひらがな - Hiragana":"いただきます ","Romaji (English Letters)":"itadakimasu","English Meaning":"expression of gratitude before meals", "audio": "itadakimasu"}
]
const commonone6 = [
  {"漢字 - Kanji":"ご馳走様でした ","ひらがな - Hiragana":"ごちそうさまでした ","Romaji (English Letters)":"gochisousama deshita","English Meaning":"said after meals", "audio": "gochisousama deshita"}
]
const commonone7 = [
  {"漢字 - Kanji":"有難うございます ","ひらがな - Hiragana":"ありがとうございます ","Romaji (English Letters)":"arigatou gozaimasu","English Meaning":"Thank you", "audio": "arigatou gozaimasu"}
]
const commonone8 = [
  {"漢字 - Kanji":"御免なさい ","ひらがな - Hiragana":"ごめんなさい ","Romaji (English Letters)":"gomen nasai","English Meaning":"I beg your pardon, I'm sorry", "audio": "gomen nasai"}
]
const commonone9 = [
  {"漢字 - Kanji":"失礼します ","ひらがな - Hiragana":"しつれいします ","Romaji (English Letters)":"shitsurei shimasu","English Meaning":"a way of saying \"Excuse me\"", "audio": "shitsurei shimasu"}
]
const commonone10 = [
  {"漢字 - Kanji":"すみません ","ひらがな - Hiragana":"すみません ","Romaji (English Letters)":"sumimasen","English Meaning":"another way of saying \"Excuse me\" or \"I'm sorry\"", "audio": "sumimasen"}
]
const commonone11 = [
  {"漢字 - Kanji":"行って来ます ","ひらがな - Hiragana":"いってきます ","Romaji (English Letters)":"itte kimasu","English Meaning":"a way of saying \"See you later\"", "audio": ""}
]
const commonone12 = [
  {"漢字 - Kanji":"行ってらっしゃい ","ひらがな - Hiragana":"いってらっしゃい ","Romaji (English Letters)":"itte rasshai","English Meaning":"a different way of saying \"See you later\"", "audio": "itte rasshai"}
]
const commonone13 = [
  {"漢字 - Kanji":"唯今 ","ひらがな - Hiragana":"ただいま ","Romaji (English Letters)":"tadaima","English Meaning":"I'm home!", "audio": "tadaima"}
]
const commonone14 = [
  {"漢字 - Kanji":"お帰りなさい ","ひらがな - Hiragana":"おかえりなさい ","Romaji (English Letters)":"okaerinasai","English Meaning":"Welcome home", "audio": "okaerinasai"}
]
const commonone15 = [
  {"漢字 - Kanji":"御免下さい ","ひらがな - Hiragana":"ごめんください ","Romaji (English Letters)":"gomen kudasai","English Meaning":"a way of saying \"May I come in?\"", "audio": "gomen kudasai"}
]
const commonone16 = [
  {"漢字 - Kanji":"今、何時ですか ","ひらがな - Hiragana":"いま、なんじですか ","Romaji (English Letters)":"ima, nanji desu ka","English Meaning":"What time is it (now)?", "audio": "ima, nanji desu ka"}
]
const commonone17 = [
  {"漢字 - Kanji":"お腹が空いています ","ひらがな - Hiragana":"おなかがすいています ","Romaji (English Letters)":"onaka ga suite imasu","English Meaning":"I'm hungry", "audio": "onaka ga suite imasu"}
]
const commonone18 = [
  {"漢字 - Kanji":"お代わり下さい ","ひらがな - Hiragana":"おかわりください ","Romaji (English Letters)":"okawari kudasai","English Meaning":"a request for second helpings", "audio": "okawari kudasai"}
]
const commonone19 = [
  {"漢字 - Kanji":"幾らですか ","ひらがな - Hiragana":"いくらですか ","Romaji (English Letters)":"ikura desu ka","English Meaning":"How much?", "audio": "ikura desu ka"}
]
const commonone20 = [
  {"漢字 - Kanji":"お釣りは幾らですか ","ひらがな - Hiragana":"おつりはいくらですか ","Romaji (English Letters)":"otsuri wa ikura desu ka","English Meaning":"How much is the change?", "audio": "otsuri wa ikura desu ka"}
]
const commonone21 = [
  {"漢字 - Kanji":"お手洗いは何処ですか ","ひらがな - Hiragana":"おてあらいはどこですか ","Romaji (English Letters)":"otearai wa doko desu ka","English Meaning":"Where is the bathroom/restroom?", "audio": "otearai wa doko desu ka"}
]
const commonone22 = [
  {"漢字 - Kanji":"お風呂に入ります ","ひらがな - Hiragana":"おふろにはいります ","Romaji (English Letters)":"ofuro ni hairimasu","English Meaning":"I'm going to take a bath", "audio": "ofuro ni hairimasu"}
]
const commonone23 = [
  {"漢字 - Kanji":"顔を洗います ","ひらがな - Hiragana":"かおをあらいます ","Romaji (English Letters)":"kao o araimasu","English Meaning":"I'm going to wash my face", "audio": "kao o araimasu"}
]
const commonone24 = [
  {"漢字 - Kanji":"歯を磨きます ","ひらがな - Hiragana":"はをみがきます ","Romaji (English Letters)":"ha o migakimasu","English Meaning":"I'm going to brush my teeth", "audio": "ha o migakimasu"}
]
const commonone25 = [
  {"漢字 - Kanji":"うがいをします ","ひらがな - Hiragana":"うがいをします ","Romaji (English Letters)":"ugai o shimasu","English Meaning":"I'm going to gargle", "audio": "ugai o shimasu"}
]

const CommonOne = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Beginner" category="Phrases" lesson={title} />
     <div className={'title'}>
     {title}
      </div>
      <h1>{title}</h1>

<p>Below are 25 of the most common/useful phrases in the Japanese language and a breakdown/explanation of each.</p>

<Table data={commonone1} />

<p><strong>Explanation:</strong></p>

<p>"ohayou gozaimsu" can be used both formally and informally. It can also be shortened to simply <Play audio="ohayou" />  which is less polite but in no way rude. It can be shortened further to <Play audio="ohayo" />  which is less polite than "ohayou" and more on the informal side.</p>

<p>"ohayou" comes from the adjective 早い (<Play audio="hayai" />) which means "early". <Play audio="gozaru" />  (which becomes "gozaimasu" in polite form) is the honorific version of "aru" (to exist (inanimate)). Putting these two together, "ohayou gozaimasu" literally means "it honorably exists early".</p>

<Table data={commonone2} />
<p><strong>Explanation:</strong></p>
<p>"konnichiwa" is made up of the Kanji for "now", the Kanji for "day/sun", and the topic marker "wa" (the Hiragana "ha"). It is probably closer to "Good day" but used similar to how "Hello" is used in English.</p>
<Table data={commonone3} />
<p><strong>Explanation:</strong></p>
<p>"konbanwa" is made up of the Kanji for "now", the Kanji for "evening", and the topic marker "wa" (the Hiragana "ha"). "konban" by itself means "this evening".</p>
<Table data={commonone4} />
<p><strong>Explanation:</strong></p>
<p>"oyasuminasai" is derived from the verb 休む (<Play audio="yasumu" /> ) which means "to be absent (from work or school), to rest, to go to bed". It is a farewell and implies that the person you're saying it to will soon go to sleep. It appears similar to base 2 + nasai which is a very informal (rude in most situations) command form but the "o" at the beginning (which is 御, an honorific prefix, in Kanji but not typically written as such due to the complexity of the Kanji) elevates it to polite form. "yasuminasai" by itself would simply mean "Take a break!"</p>
<Table data={commonone5} />
<p><strong>Explanation:</strong></p>
<p>"itadakimasu" is commonly said just before eating a meal. It expresses gratitude for the meal and is the polite form of the verb 頂く (itadaku - to humbly receive/accept). "itadaku" is the humble version of 貰う (morau - to receive/accept).</p>
<Table data={commonone6} />
<p><strong>Explanation:</strong></p>
<p>"gochisousama deshita" is commonly said just after eating a meal. The "go" at the beginning would be written in Kanji with the same honorific prefix that we saw on "oyasuminasai" (御) but it is read "go" instead of "o" in this case. 馳走 (chisou) means "banquet, feast" even though the Kanji are for "hurry" and "run/race". 様 (sama) is the same as the honorific suffix that could be used after a person's name.</p>
<Table data={commonone7} />
<p><strong>Explanation:</strong></p>
<p>"arigatou" is made up of the Kanji for "aru" (to exist (inanimate)) and 難しい (<Play audio="muzukashii" />  - difficult). It literally means "difficulty exists" or "it is difficult". どうも (doumo - a more informal way of saying "Thank you") could be added to the beginning of "arigatou gozaimasu" to make it even more polite.</p>

<p>The different words for "Thank you" from most polite to least polite are as follows:</p>

<p><Play audio="doumo arigatou gozaimasu" /></p>
<p><Play audio="arigatou gozaimasu" /></p>
<p><Play audio="doumo arigatou" /></p>
<p><Play audio="arigatou" /></p>
<p><Play audio="doumo" /></p>

<Table data={commonone8} />
<p><strong>Explanation:</strong></p>
<p>You probably recognize the honorific prefix at the beginning of "gomen nasai". It is similar to "oyasuminasai" in that it kind of sounds like a command but it is not. 免 (men) means "dismissal" so "gomen nasai" literally means "I request your honorable dismissal". The shorter and less polite version is "gomen" by itself.</p>
<Table data={commonone9} />
<p><strong>Explanation:</strong></p>
<p>失礼 (shitsurei) is a "dn" (descriptive noun) that means "rude/rudeness". 失礼する (shitsurei suru ("shimasu" in polite form)) means "to be rude or impolite" so this literally means "I'm going to be rude". It is often used when one is about to hang up the phone to end a conversation or a way to ask for forgiveness for something one is about to do (like squeeze past someone on a train). To be more polite, use the humble version of "suru", 致す (itasu) and say <Play audio="shitsurei itashimasu" />  instead.</p>
<Table data={commonone10} />
<p><strong>Explanation:</strong></p>
<p>The verb behind "sumimasen" is 済む (sumu) which means "to finish, to end, to be completed". "sumimasen" literally means "it will not be ended". It is a common way of asking for forgiveness for troubling someone. A very similar phrase would be the previous phrase in past tense - <Play audio="shitsurei shimashita" /></p>
<Table data={commonone11} />
<p><strong>Explanation:</strong></p>
<p>"itte kimasu" combines the verbs 行く (iku - to go) and 来る (kuru - to come). A verb in base "te" form (as "iku" is here) can be used to continue a sentence or phrase (like using "and" in English). "itte kimasu" literally means "I will go and come back". It is often said by the father as he leaves for work, children as they leave for school, etc.</p>
<Table data={commonone12} />
<p><strong>Explanation:</strong></p>
<p>"itte rasshai" is the response to "itte kimasu". The first part is exactly the same but the second part uses an abbreviated form of the verb いらっしゃる (irassharu) which is the honorific version of "kuru". "rasshai" can only be used with another verb in base "te" right before it. In the form "irasshai" (unabbreviated) is it kind of like a command so this is literally "go and honorably come back!". "irasshai" is often said by itself to welcome someone. The more polite version, "irasshaimase" is often said by those working in a shop to welcome customers.</p>
<Table data={commonone13} />
<p><strong>Explanation:</strong></p>
<p>"tadaima" is the opposite of "itte kimasu" and is said by the person upon their return. It is typically written in Hiragana but the Kanji mean "only, just" and "now". It literally means "presently" or "just now" and can be used this way as part of a longer sentence.</p>
<Table data={commonone14} />
<p><strong>Explanation:</strong></p>
<p>"okaerinasai" is the response for "tadaima". It is derived from the verb 帰る (kaeru) which means "to return home" and is very similar to how "oyasuminasai" is structured.</p>
<Table data={commonone15} />
<p><strong>Explanation:</strong></p>
<p>"gomen kudasai" is very similar to "gomen nasai" but, instead of a way to ask for forgiveness, it is a way to ask permission to enter someone's residence. It is often said by visitors as they step up from the 玄関 (<Play audio="genkan" />  - entranceway) into the residence (leaving their shoes behind, of course).</p>
<Table data={commonone16} />
<p><strong>Explanation:</strong></p>
<p>今 is a Kanji we've seen in a number of the greetings above. By itself, it is read "ima" and means "now". "nanji" means "what time".</p>
<Table data={commonone17} />
<p><strong>Explanation:</strong></p>
<p>腹 by itself is read "hara" and means "abdomen, belly, stomach". With the honorific "o" at the beginning, it becomes "onaka" (stomach). The verb 空く (<Play audio="suku" /> ) means "to become less crowded, to thin out, to get empty" so "onaka ga suite imasu" means "my stomach is empty". Another way to say "I'm hungry" is "onaka ga peko peko desu" (see Sound Words). Like with most polite form statements, this can be turned into a question by adding "ka" on the end.</p>
<Table data={commonone18} />
<p><strong>Explanation:</strong></p>
<p>The verb 代わる (<Play audio="kawaru" />) means "to take the place of, to be substituted for, to be exchanged, to change places with, to take turns, to be replaced". The noun form "kawari" typically means "substitute, proxy, alternate" but can also mean "second helping". Especially when it is prefixed with the honorific "o". "okawari kudasai" is like "may I please have seconds?" in English but it is not a question.</p>
<Table data={commonone19} />
<p><strong>Explanation:</strong></p>
<p>"kore wa" could be added to the beginning to make this "how much is this?" or "sore wa" could be added to the beginning to make this "how much is that?".</p>
<Table data={commonone20} />
<p><strong>Explanation:</strong></p>
<p>This is kind of an interesting phrase since 釣り (tsuri) means "fishing" but this is how you would ask how much change is coming back to you from a purchase.</p>
<Table data={commonone21} />
<p><strong>Explanation:</strong></p>
<p>手 (te) means "hand(s)" and the verb 洗う (<Play audio="arau" />) means "to wash" so "otearai" literally means "the honorable hand washing place". 何処 (doko - commonly written in Hiragana) means "where".</p>
<Table data={commonone22} />
<p><strong>Explanation:</strong></p>
<p>Taking baths is big in Japan. This is usually done after taking a shower to clean yourself. 風呂 (furo) is "bathtub" (standardly prefixed with the honorific "o") and the verb 入る (<Play audio="hairu" /> - which happens to be a godan verb that sounds like an ichidan verb) means "to enter".</p>
<Table data={commonone23} />
<p><strong>Explanation:</strong></p>
<p>顔 (kao) means "face" and this is the same 洗う (arau - to wash) that we saw above.</p>
<Table data={commonone24} />
<p><strong>Explanation:</strong></p>
<p>歯 (ha) means "tooth/teeth" and the verb 磨く (<Play audio="migaku" />) means "to polish, to shine".</p>
<Table data={commonone25} />
<p><strong>Explanation:</strong></p>
<p>"ugai" means "gargling". The Kanji is 嗽 but it is typically written in Hiragana due to the complexity.</p>

    </>
  )
}

export default CommonOne