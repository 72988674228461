import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"

const title = "The Japanese Alphabet"

const hiraganaone = [
  {"Hiragana":"あ","Romaji":"a","audio":"a"},
 {"Hiragana":"い","Romaji":"i","audio":"i"},
 {"Hiragana":"う","Romaji":"u","audio":"u"},
 {"Hiragana":"え","Romaji":"e","audio":"e"},
 {"Hiragana":"お","Romaji":"o","audio":"o"},
 {"Hiragana":"か","Romaji":"ka","audio":"ka"},
 {"Hiragana":"き","Romaji":"ki","audio":"ki"},
 {"Hiragana":"く","Romaji":"ku","audio":"ku"},
 {"Hiragana":"け","Romaji":"ke","audio":"ke"},
 {"Hiragana":"こ","Romaji":"ko","audio":"ko"},
 {"Hiragana":"が","Romaji":"ga","audio":"ga"},
 {"Hiragana":"ぎ","Romaji":"gi","audio":"gi"},
 {"Hiragana":"ぐ","Romaji":"gu","audio":"gu"},
 {"Hiragana":"げ","Romaji":"ge","audio":"ge"},
 {"Hiragana":"ご","Romaji":"go","audio":"go"},
 {"Hiragana":"さ","Romaji":"sa","audio":"sa"},
 {"Hiragana":"し","Romaji":"shi","audio":"shi"},
 {"Hiragana":"す","Romaji":"su","audio":"su"},
 {"Hiragana":"せ","Romaji":"se","audio":"se"},
 {"Hiragana":"そ","Romaji":"so","audio":"so"},
 {"Hiragana":"ざ","Romaji":"za","audio":"za"},
 {"Hiragana":"じ","Romaji":"ji","audio":"ji"},
 {"Hiragana":"ず","Romaji":"zu","audio":"zu"},
 {"Hiragana":"ぜ","Romaji":"ze","audio":"ze"},
 {"Hiragana":"ぞ","Romaji":"zo","audio":"zo"},
 {"Hiragana":"た","Romaji":"ta","audio":"ta"},
 {"Hiragana":"ち","Romaji":"chi","audio":"chi"},
 {"Hiragana":"つ","Romaji":"tsu","audio":"tsu"},
 {"Hiragana":"て","Romaji":"te","audio":"te"},
 {"Hiragana":"と","Romaji":"to","audio":"to"},
 {"Hiragana":"だ","Romaji":"da","audio":"da"},
 {"Hiragana":"ぢ","Romaji":"ji","audio":"ji"},
 {"Hiragana":"づ","Romaji":"zu","audio":"zu"},
 {"Hiragana":"で","Romaji":"de","audio":"de"},
 {"Hiragana":"ど","Romaji":"do","audio":"do"},
 {"Hiragana":"な","Romaji":"na","audio":"na"},
 {"Hiragana":"に","Romaji":"ni","audio":"ni"},
 {"Hiragana":"ぬ","Romaji":"nu","audio":"nu"},
 {"Hiragana":"ね","Romaji":"ne","audio":"ne"},
 {"Hiragana":"の","Romaji":"no","audio":"no"},
 {"Hiragana":"は","Romaji":"ha","audio":"ha"},
 {"Hiragana":"ひ","Romaji":"hi","audio":"hi"},
 {"Hiragana":"ふ","Romaji":"fu","audio":"fu"},
 {"Hiragana":"へ","Romaji":"he","audio":"he"},
 {"Hiragana":"ほ","Romaji":"ho","audio":"ho"},
 {"Hiragana":"ば","Romaji":"ba","audio":"ba"},
 {"Hiragana":"び","Romaji":"bi","audio":"bi"},
 {"Hiragana":"ぶ","Romaji":"bu","audio":"bu"},
 {"Hiragana":"べ","Romaji":"be","audio":"be"},
 {"Hiragana":"ぼ","Romaji":"bo","audio":"bo"},
 {"Hiragana":"ぱ","Romaji":"pa","audio":"pa"},
 {"Hiragana":"ぴ","Romaji":"pi","audio":"pi"},
 {"Hiragana":"ぷ","Romaji":"pu","audio":"pu"},
 {"Hiragana":"ぺ","Romaji":"pe","audio":"pe"},
 {"Hiragana":"ぽ","Romaji":"po","audio":"po"},
 {"Hiragana":"ま","Romaji":"ma","audio":"ma"},
 {"Hiragana":"み","Romaji":"mi","audio":"mi"},
 {"Hiragana":"む","Romaji":"mu","audio":"mu"},
 {"Hiragana":"め","Romaji":"me","audio":"me"},
 {"Hiragana":"も","Romaji":"mo","audio":"mo"},
 {"Hiragana":"や","Romaji":"ya","audio":"ya"},
 {"Hiragana":"ゆ","Romaji":"yu","audio":"yu"},
 {"Hiragana":"よ","Romaji":"yo","audio":"yo"},
 {"Hiragana":"ら","Romaji":"ra","audio":"ra"},
 {"Hiragana":"り","Romaji":"ri","audio":"ri"},
 {"Hiragana":"る","Romaji":"ru","audio":"ru"},
 {"Hiragana":"れ","Romaji":"re","audio":"re"},
 {"Hiragana":"ろ","Romaji":"ro","audio":"ro"},
 {"Hiragana":"わ","Romaji":"wa","audio":"wa"},
 {"Hiragana":"を","Romaji":"wo","audio":"wo"},
 {"Hiragana":"ん","Romaji":"n/m","audio":"n/m"}
]

const hiraganatwo = [
  {"Hiragana":"きゃ","Romaji":"kya","audio":"kya"},
 {"Hiragana":"きゅ","Romaji":"kyu","audio":"kyu"},
 {"Hiragana":"きょ","Romaji":"kyo","audio":"kyo"},
 {"Hiragana":"ぎゃ","Romaji":"gya","audio":"gya"},
 {"Hiragana":"ぎゅ","Romaji":"gyu","audio":"gyu"},
 {"Hiragana":"ぎょ","Romaji":"gyo","audio":"gyo"},
 {"Hiragana":"しゃ","Romaji":"sha","audio":"sha"},
 {"Hiragana":"しゅ","Romaji":"shu","audio":"shu"},
 {"Hiragana":"しょ","Romaji":"sho","audio":"sho"},
 {"Hiragana":"じゃ","Romaji":"ja","audio":"ja"},
 {"Hiragana":"じゅ","Romaji":"ju","audio":"ju"},
 {"Hiragana":"じょ","Romaji":"jo","audio":"jo"},
 {"Hiragana":"ちゃ","Romaji":"cha","audio":"cha"},
 {"Hiragana":"ちゅ","Romaji":"chu","audio":"chu"},
 {"Hiragana":"ちょ","Romaji":"cho","audio":"cho"},
 {"Hiragana":"にゃ","Romaji":"nya","audio":"nya"},
 {"Hiragana":"にゅ","Romaji":"nyu","audio":"nyu"},
 {"Hiragana":"にょ","Romaji":"nyo","audio":"nyo"},
 {"Hiragana":"ひゃ","Romaji":"hya","audio":"hya"},
 {"Hiragana":"ひゅ","Romaji":"hyu","audio":"hyu"},
 {"Hiragana":"ひょ","Romaji":"hyo","audio":"hyo"},
 {"Hiragana":"びゃ","Romaji":"bya","audio":"bya"},
 {"Hiragana":"びゅ","Romaji":"byu","audio":"byu"},
 {"Hiragana":"びょ","Romaji":"byo","audio":"byo"},
 {"Hiragana":"ぴゃ","Romaji":"pya","audio":"pya"},
 {"Hiragana":"ぴゅ","Romaji":"pyu","audio":"pyu"},
 {"Hiragana":"ぴょ","Romaji":"pyo","audio":"pyo"},
 {"Hiragana":"みゃ","Romaji":"mya","audio":"mya"},
 {"Hiragana":"みゅ","Romaji":"myu","audio":"myu"},
 {"Hiragana":"みょ","Romaji":"myo","audio":"myo"},
 {"Hiragana":"りゃ","Romaji":"rya","audio":"rya"},
 {"Hiragana":"りゅ","Romaji":"ryu","audio":"ryu"},
 {"Hiragana":"りょ","Romaji":"ryo","audio":"ryo"}
]

const Hiragana = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Beginner" category="Phrases" lesson={title} />
     <div className={'title'}>
     Hiragana
      </div>
      <h1>{title}</h1>

      <h2>Hiragana - ひらがな</h2>

<p>The first step to learning the Japanese language is to learn the alphabet. Or, at least, to learn the sounds that exist in the language. There are absolutely no "tones" in Japanese like in many other asian languages and there are only 2 exceptions within the alphabet which will be explained later. The Japanese alphabet does not contain letters but, instead, contains characters and, technically, they are not alphabets but character sets. The characters in the chart below are called Hiragana . Hiragana is the main alphabet or character set for Japanese. Japanese also consists of two other character sets - Kanji  (Chinese characters), which we will get into later, and another alphabet/character set, Katakana , which is mainly used for foreign words. Katakana  will be covered in <Link to="/members/b/basics/katakana/">the Katakana lesson</Link>. Don't wait to move on until you have all Hiragana characters memorized - learn them as you continue to go through the other lessons.</p>

<p>There are 5 vowels in Japanese. (a) , pronounced "ahh", (i) , pronounced like "e" in "eat", (u) , pronounced like "oo" in "soon", (e) , pronounced like "e" in "elk", and (o) , pronounced "oh". All Hiragana  characters end with one of these vowels, with the exception of (n) . The only "consonant" that does not resemble that of English is the Japanese "r". It is slightly "rolled" as if it were a combination of a "d", "r", and "l".</p>

<p>Click on the  image next to each Hiragana character to hear how they are pronounced.</p>

<Table data={hiraganaone} />

<h2>Combinations</h2>

<Table data={hiraganatwo} />

<p>Here is a <Link to="/printable-hiragana-chart.pdf">Printable Hiragana Chart</Link> (PDF - get Adobe Acrobat Reader).</p>

<p><strong>Exceptions:</strong></p>

<ol>

<li>The Hiragana は (ha)  is pronounced "wa"  when it immediately follows the topic of the sentence. This character is usually only pronounced "ha"  when it is part of a word.</li>

<li>The Hiragana へ (he)  is pronounced "e"  when it immediately follows a place or direction. Both of these are very simple to detect.</li>

</ol>

<div className="alertbox">

<p>In the classic Japanese language the "h" sound was pronounced like "w", "h", and "f" all put together. The sound for the "ha", "hi", "fu", "he", "ho" evolved one way and the particles, which sounded closer to "wa" and "we", went a different route. They finally ended up taking sounds slightly different then the hiragana was normally pronounced which were also sounds already found in the Japanese language so these two exceptions are often very confusing to outsiders.</p>

</div>

<p><strong>Note:</strong> You probably noticed in the chart above that there are 2 characters pronounced "zu"  and 2 characters pronounced "ji" . The characters づ (zu)  and ぢ (ji)  are very rarely used. づ (zu)  only occurs when there is a つ (tsu)  in front of it like in つづく (tsuzuku  - to continue) or when a Kanji  (Chinese character) that has a reading which starts with つ (tsu)  is paired at the end with another character changing the つ (tsu)  to a づ (zu) . The same applies for the Hiragana ぢ (ji) . Since they are used so rarely I wouldn't worry about them too much. I will let you know whenever we come upon a word in which they are used.</p>

<p>Some people wonder why "yi", "ye", "wi", "wu", and "we" are missing. There aren't characters for "yi", "ye", or "wu". There is a ゐ (wi) and a ゑ (we) but these were deemed obsolete in 1946 and were replaced by い (i) and え (e) respectively.</p>



    </>
  )
}

export default Hiragana