import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Descriptive Words - Part 1"

const descriptiveone = [
  {"漢字 - Kanji":"赤い車 ","ひらがな - Hiragana":"あかいくるま ","Romaji (English Letters)":"akai kuruma","English Meaning":"red car","audio":"akai kuruma"}
]

const descriptivethree = [
  {"漢字 - Kanji":"赤い ","ひらがな - Hiragana":"あかい ","Romaji (English Letters)":"akai  (dv)","English Meaning":"red", "audio": "akai"},
 {"漢字 - Kanji":"黄色い ","ひらがな - Hiragana":"きいろい ","Romaji (English Letters)":"kiiroi  (dv)","English Meaning":"yellow", "audio": "kiiroi"},
 {"漢字 - Kanji":"青い ","ひらがな - Hiragana":"あおい ","Romaji (English Letters)":"aoi  (dv)","English Meaning":"blue", "audio": "aoi"},
 {"漢字 - Kanji":"黒い ","ひらがな - Hiragana":"くろい ","Romaji (English Letters)":"kuroi  (dv)","English Meaning":"black", "audio": "kuroi"},
 {"漢字 - Kanji":"白い ","ひらがな - Hiragana":"しろい ","Romaji (English Letters)":"shiroi  (dv)","English Meaning":"white", "audio": "shiroi"},
 {"漢字 - Kanji":"面白い ","ひらがな - Hiragana":"おもしろい ","Romaji (English Letters)":"omoshiroi  (dv)","English Meaning":"interesting, funny", "audio": "omoshiroi"},
 {"漢字 - Kanji":"暑い ","ひらがな - Hiragana":"あつい ","Romaji (English Letters)":"atsui  (dv)","English Meaning":"hot", "audio": "atsui"},
 {"漢字 - Kanji":"寒い ","ひらがな - Hiragana":"さむい ","Romaji (English Letters)":"samui  (dv)","English Meaning":"cold", "audio": "samui"},
 {"漢字 - Kanji":"大きい ","ひらがな - Hiragana":"おおきい ","Romaji (English Letters)":"ookii  (dv)","English Meaning":"big", "audio": "ookii"},
 {"漢字 - Kanji":"小さい ","ひらがな - Hiragana":"ちいさい ","Romaji (English Letters)":"chiisai  (dv)","English Meaning":"small", "audio": "chiisai"},
 {"漢字 - Kanji":"自然 ","ひらがな - Hiragana":"しぜん ","Romaji (English Letters)":"shizen  (dn)","English Meaning":"nature", "audio": "shizen"},
 {"漢字 - Kanji":"綺麗 ","ひらがな - Hiragana":"きれい ","Romaji (English Letters)":"kirei  (dn)","English Meaning":"pretty, clean", "audio": "kirei"},
 {"漢字 - Kanji":"失礼 ","ひらがな - Hiragana":"しつれい ","Romaji (English Letters)":"shitsurei  (dn)","English Meaning":"rude", "audio": "shitsurei"},
 {"漢字 - Kanji":"静か ","ひらがな - Hiragana":"しずか ","Romaji (English Letters)":"shizuka  (dn)","English Meaning":"quiet", "audio": "shizuka"},
 {"漢字 - Kanji":"特別 ","ひらがな - Hiragana":"とくべつ ","Romaji (English Letters)":"tokubetsu  (dn)","English Meaning":"special", "audio": "tokubetsu"},
 {"漢字 - Kanji":"元気 ","ひらがな - Hiragana":"げんき ","Romaji (English Letters)":"genki  (dn)","English Meaning":"in good spirits", "audio": "genki"},
 {"漢字 - Kanji":"変 ","ひらがな - Hiragana":"へん ","Romaji (English Letters)":"hen  (dn)","English Meaning":"weird, strange", "audio": "hen"},
 {"漢字 - Kanji":"上手 ","ひらがな - Hiragana":"じょうず ","Romaji (English Letters)":"jouzu  (dn)","English Meaning":"skillful", "audio": "jouzu"},
 {"漢字 - Kanji":"親切 ","ひらがな - Hiragana":"しんせつ ","Romaji (English Letters)":"shinsetsu  (dn)","English Meaning":"kind, nice", "audio": "shinsetsu"},
 {"漢字 - Kanji":"素敵 ","ひらがな - Hiragana":"すてき ","Romaji (English Letters)":"suteki  (dn)","English Meaning":"\"cool\", good looking", "audio": "suteki"}
]

const DescriptiveOne = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Beginner" category="Grammar" lesson={title} />
     <div className={'title'}>
     {title}
      </div>
      <h1>{title}</h1>

      <p>There are two different types of Japanese words that can be used to modify nouns (adjectives) and verbs (adverbs). One group is much like what we would call an adjective but they can also be conjugated to modify verbs as well. We will refer to these as "dv" (for "descriptive verb"). These are often referred to as "true adjectives" or "i-adjectives". The other group can, in most cases, stand alone like a noun but can also be used to modify nouns and verbs. We will refer to these as "dn" (for "descriptive noun"). These are often referred to as "quasi adjectives" or "na-adjectives".</p>

<p>All adjectives in the "dv" group always end with い <Play audio="i" /> . No exceptions. These are placed in front of a noun in order to modify it. For example:</p>

<Table data={descriptiveone} />



<p>These adjectives can be conjugated to form different tenses, turned into adverbs (modify verbs), etc. The conjugation rules for "true" Japanese adjectives are as follows:</p>

<table>
<thead>
  <tr>
    <th>ひらがな - Hiragana <Play audio="hiragana" /></th>
    <th>Romaji <Play audio="ro-maji" /><br />(English Letters)</th>
    <th>English Meaning</th>
    </tr>
</thead>
<tbody>
<tr>
<td>drop the い <Play audio="i" /><br />and add く <Play audio="ku" /></td>
<td>drop the "i" <Play audio="i" /><br />and add "ku" <Play audio="ku" /></td>
<td>modifies a verb (adverb form)<br />(akaku natta <Play audio="akaku natta" /> -&gt; became red)<br />(naru <Play audio="naru" /> is the verb "to become")</td>
</tr>
<tr>
<td>drop the い <Play audio="i" /><br />and add くない <Play audio="kunai" /></td>
<td>drop the "i" <Play audio="i" /><br />and add "kunai" <Play audio="kunai" /></td>
<td>present tense negative<br />(akakunai <Play audio="akakunai" /> -&gt; is not red)<br />(becomes a new "dv")</td>
</tr>
<tr>
<td>drop the い <Play audio="i" /><br />and add かった <Play audio="katta" /></td>
<td>drop the "i" <Play audio="i" /><br />and add "katta" <Play audio="katta" /></td>
<td>past tense<br />(akakatta <Play audio="akakatta" /> -&gt; was red)</td>
</tr>
<tr>
<td>drop the い <Play audio="i" /><br />and add くなかった <Play audio="kunakatta" /></td>
<td>drop the "i" <Play audio="i" /><br />and add "kunakatta" <Play audio="kunakatta" /></td>
<td>past tense negative<br />(akakunakatta <Play audio="akakunakatta" /> -&gt; was not red)<br />(This one might take a little practice saying.)</td>
</tr>
</tbody>
</table>

<p>It's not as easy to recognize "dn" adjectives but I will point them out in the vocabulary lists. Sometimes you'll even find a "dn" that ends in い <Play audio="i" />  (ex. <Play audio="kirei" />  - pretty). As mentioned before, these words can, in most cases, be used by themselves like a noun (ex. <Play audio="shizen" />  - nature). By adding a な <Play audio="na" />  to the end of these words they can be used to modify a noun (ex. shizen na kankyou<Play audio="shizen na kankyou" />  - a natural environment). And by adding a に <Play audio="ni" />  to the end of these words they can be used to modify a verb as an adverb (ex. shizen ni aruku<Play audio="shizen ni aruku" />  - to walk naturally).</p>

<p>Here's a list of some commonly used "dv" and "dn" adjectives:</p>

<Table data={descriptivethree} />


    </>
  )
}

export default DescriptiveOne