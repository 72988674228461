import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"

const title = "Animals, Colors, and Shapes"

const tableanimals = [
  {"漢字 - Kanji":"犬","ひらがな - Hiragana":"いぬ","Romaji (English Letters)":"inu","audio":"inu","English Meaning":"dog"},
 {"漢字 - Kanji":"猫","ひらがな - Hiragana":"ねこ","Romaji (English Letters)":"neko","audio":"neko","English Meaning":"cat"},
 {"漢字 - Kanji":"兎","ひらがな - Hiragana":"うさぎ","Romaji (English Letters)":"usagi","audio":"usagi","English Meaning":"rabbit"},
 {"漢字 - Kanji":"牛","ひらがな - Hiragana":"うし","Romaji (English Letters)":"ushi","audio":"ushi","English Meaning":"cow"},
 {"漢字 - Kanji":"牡牛","ひらがな - Hiragana":"おうし","Romaji (English Letters)":"oushi","audio":"oushi","English Meaning":"bull, steer"},
 {"漢字 - Kanji":"馬","ひらがな - Hiragana":"うま","Romaji (English Letters)":"uma","audio":"uma","English Meaning":"horse"},
 {"漢字 - Kanji":"縞馬 (typically written in katakana)","ひらがな - Hiragana":"しまうま","Romaji (English Letters)":"shimauma","audio":"shimauma","English Meaning":"zebra (\"shima\" means \"stripe\")"},
 {"漢字 - Kanji":"驢馬","ひらがな - Hiragana":"ろば","Romaji (English Letters)":"roba","audio":"roba","English Meaning":"donkey"},
 {"漢字 - Kanji":"河馬","ひらがな - Hiragana":"かば","Romaji (English Letters)":"kaba","audio":"kaba","English Meaning":"hippopotamus"},
 {"漢字 - Kanji":"鹿","ひらがな - Hiragana":"しか","Romaji (English Letters)":"shika","audio":"shika","English Meaning":"deer"},
 {"漢字 - Kanji":"豚","ひらがな - Hiragana":"ぶた","Romaji (English Letters)":"buta","audio":"buta","English Meaning":"pig"},
 {"漢字 - Kanji":"ライオン","ひらがな - Hiragana":"ライオン","Romaji (English Letters)":"raion","audio":"raion","English Meaning":"lion (katakana)The original Japanese word for \"lion\" is 獅子 (しし - shishi) but \"raion\" is more commonly used now"},
 {"漢字 - Kanji":"虎","ひらがな - Hiragana":"とら","Romaji (English Letters)":"tora","audio":"tora","English Meaning":"tiger"},
 {"漢字 - Kanji":"麒麟 (typically written in katakana)","ひらがな - Hiragana":"きりん","Romaji (English Letters)":"kirin","audio":"kirin","English Meaning":"giraffe"},
 {"漢字 - Kanji":"象","ひらがな - Hiragana":"ぞう","Romaji (English Letters)":"zou","audio":"zou","English Meaning":"elephant"},
 {"漢字 - Kanji":"犀","ひらがな - Hiragana":"さい","Romaji (English Letters)":"sai","audio":"sai","English Meaning":"rhinoceros"},
 {"漢字 - Kanji":"猿","ひらがな - Hiragana":"さる","Romaji (English Letters)":"saru","audio":"saru","English Meaning":"monkey"},
 {"漢字 - Kanji":"熊","ひらがな - Hiragana":"くま","Romaji (English Letters)":"kuma","audio":"kuma","English Meaning":"bear"},
 {"漢字 - Kanji":"狼","ひらがな - Hiragana":"おおかみ","Romaji (English Letters)":"ookami","audio":"ookami","English Meaning":"wolf"},
 {"漢字 - Kanji":"鼠","ひらがな - Hiragana":"ねずみ","Romaji (English Letters)":"nezumi","audio":"nezumi","English Meaning":"mouse, rat"},
 {"漢字 - Kanji":"羊","ひらがな - Hiragana":"ひつじ","Romaji (English Letters)":"hitsuji","audio":"hitsuji","English Meaning":"sheep"},
 {"漢字 - Kanji":"蝙蝠 (typically written in katakana)","ひらがな - Hiragana":"こうもり","Romaji (English Letters)":"koumori","audio":"koumori","English Meaning":"bat"},
 {"漢字 - Kanji":"蛇","ひらがな - Hiragana":"へび","Romaji (English Letters)":"hebi","audio":"hebi","English Meaning":"snake"},
 {"漢字 - Kanji":"蜥蜴 (typically written in katakana)","ひらがな - Hiragana":"とかげ","Romaji (English Letters)":"tokage","audio":"tokage","English Meaning":"lizard"},
 {"漢字 - Kanji":"亀","ひらがな - Hiragana":"かめ","Romaji (English Letters)":"kame","audio":"kame","English Meaning":"turtle"},
 {"漢字 - Kanji":"蛙","ひらがな - Hiragana":"かえる","Romaji (English Letters)":"kaeru","audio":"kaeru","English Meaning":"frog"},
 {"漢字 - Kanji":"烏賊 (typically written in katakana)","ひらがな - Hiragana":"いか","Romaji (English Letters)":"ika","audio":"ika","English Meaning":"squid"},
 {"漢字 - Kanji":"蛸","ひらがな - Hiragana":"たこ","Romaji (English Letters)":"tako","audio":"tako","English Meaning":"octopus"},
 {"漢字 - Kanji":"魚","ひらがな - Hiragana":"さかな","Romaji (English Letters)":"sakana","audio":"sakana","English Meaning":"fish"},
 {"漢字 - Kanji":"鮫","ひらがな - Hiragana":"さめ","Romaji (English Letters)":"same","audio":"same","English Meaning":"shark"},
 {"漢字 - Kanji":"鯨","ひらがな - Hiragana":"くじら","Romaji (English Letters)":"kujira","audio":"kujira","English Meaning":"whale"},
 {"漢字 - Kanji":"海豚 (typically written in katakana)","ひらがな - Hiragana":"いるか","Romaji (English Letters)":"iruka","audio":"iruka","English Meaning":"dolphin(The kanji mean \"ocean pig\")"},
 {"漢字 - Kanji":"鮭","ひらがな - Hiragana":"さけ","Romaji (English Letters)":"sake","audio":"sake","English Meaning":"salmon"},
 {"漢字 - Kanji":"鳥","ひらがな - Hiragana":"とり","Romaji (English Letters)":"tori","audio":"tori","English Meaning":"bird"},
 {"漢字 - Kanji":"鶏","ひらがな - Hiragana":"にわとり","Romaji (English Letters)":"niwatori","audio":"niwatori","English Meaning":"chicken"},
 {"漢字 - Kanji":"鷲","ひらがな - Hiragana":"わし","Romaji (English Letters)":"washi","audio":"washi","English Meaning":"eagle"},
 {"漢字 - Kanji":"鷹","ひらがな - Hiragana":"たか","Romaji (English Letters)":"taka","audio":"taka","English Meaning":"falcon, hawk"},
 {"漢字 - Kanji":"鶴","ひらがな - Hiragana":"つる","Romaji (English Letters)":"tsuru","audio":"tsuru","English Meaning":"crane"},
 {"漢字 - Kanji":"七面鳥","ひらがな - Hiragana":"しちめんちょう","Romaji (English Letters)":"shichimenchou","audio":"shichimenchou","English Meaning":"turkey(The kanji mean \"bird with 7 faces\")"},
 {"漢字 - Kanji":"孔雀 (typically written in katakana)","ひらがな - Hiragana":"くじゃく","Romaji (English Letters)":"kujaku","audio":"kujaku","English Meaning":"peacock"},
 {"漢字 - Kanji":"梟 (typically written in katakana)","ひらがな - Hiragana":"ふくろう","Romaji (English Letters)":"fukurou","audio":"fukurou","English Meaning":"owl"},
 {"漢字 - Kanji":"雀","ひらがな - Hiragana":"すずめ","Romaji (English Letters)":"suzume","audio":"suzume","English Meaning":"sparrow"},
 {"漢字 - Kanji":"燕 (typically written in katakana)","ひらがな - Hiragana":"つばめ","Romaji (English Letters)":"tsubame","audio":"tsubame","English Meaning":"swallow"},
 {"漢字 - Kanji":"鳩","ひらがな - Hiragana":"はと","Romaji (English Letters)":"hato","audio":"hato","English Meaning":"pigeon, dove"},
 {"漢字 - Kanji":"烏 (typically written in katakana)","ひらがな - Hiragana":"からす","Romaji (English Letters)":"karasu","audio":"karasu","English Meaning":"crow, raven"},
 {"漢字 - Kanji":"白鳥","ひらがな - Hiragana":"はくちょう","Romaji (English Letters)":"hakuchou","audio":"hakuchou","English Meaning":"swan"},
 {"漢字 - Kanji":"虫","ひらがな - Hiragana":"むし","Romaji (English Letters)":"mushi","audio":"mushi","English Meaning":"bug, insect"},
 {"漢字 - Kanji":"蜂","ひらがな - Hiragana":"はち","Romaji (English Letters)":"hachi","audio":"hachi","English Meaning":"bee"},
 {"漢字 - Kanji":"蟻","ひらがな - Hiragana":"あり","Romaji (English Letters)":"ari","audio":"ari","English Meaning":"ant"},
 {"漢字 - Kanji":"蝶々","ひらがな - Hiragana":"ちょうちょう","Romaji (English Letters)":"chouchou","audio":"chouchou","English Meaning":"butterfly"},
 {"漢字 - Kanji":"蝿","ひらがな - Hiragana":"はえ","Romaji (English Letters)":"hae","audio":"hae","English Meaning":"fly"},
 {"漢字 - Kanji":"蚤","ひらがな - Hiragana":"のみ","Romaji (English Letters)":"nomi","audio":"nomi","English Meaning":"flea"},
 {"漢字 - Kanji":"天道虫 (typically written in katakana)","ひらがな - Hiragana":"てんとうむし","Romaji (English Letters)":"tentoumushi","audio":"tentoumushi","English Meaning":"ladybug"},
 {"漢字 - Kanji":"蛾","ひらがな - Hiragana":"が","Romaji (English Letters)":"ga","audio":"ga","English Meaning":"moth"},
 {"漢字 - Kanji":"蚊","ひらがな - Hiragana":"か","Romaji (English Letters)":"ka","audio":"ka","English Meaning":"mosquito"},
 {"漢字 - Kanji":"蜻蛉 (typically written in katakana)","ひらがな - Hiragana":"とんぼ","Romaji (English Letters)":"tonbo","audio":"tonbo","English Meaning":"dragonfly"},
 {"漢字 - Kanji":"蠍","ひらがな - Hiragana":"さそり","Romaji (English Letters)":"sasori","audio":"sasori","English Meaning":"scorpion"},
 {"漢字 - Kanji":"蛍","ひらがな - Hiragana":"ほたる","Romaji (English Letters)":"hotaru","audio":"hotaru","English Meaning":"firefly"},
 {"漢字 - Kanji":"蜘蛛 (typically written in katakana)","ひらがな - Hiragana":"くも","Romaji (English Letters)":"kumo","audio":"kumo","English Meaning":"spider"},
 {"漢字 - Kanji":"蚯蚓 (typically written in katakana)","ひらがな - Hiragana":"みみず","Romaji (English Letters)":"mimizu","audio":"mimizu","English Meaning":"earthworm"},
 {"漢字 - Kanji":"蝸牛 (typically written in katakana)","ひらがな - Hiragana":"かたつむり","Romaji (English Letters)":"katatsumuri","audio":"katatsumuri","English Meaning":"snail"},
 {"漢字 - Kanji":"蛞蝓 (typically written in katakana)","ひらがな - Hiragana":"なめくじ","Romaji (English Letters)":"namekuji","audio":"namekuji","English Meaning":"slug"}
]

const tablecolors = [
  {"漢字 - Kanji":"色","ひらがな - Hiragana":"いろ","Romaji (English Letters)":"iro","audio":"iro","English Meaning":"color"},
 {"漢字 - Kanji":"赤","ひらがな - Hiragana":"あか","Romaji (English Letters)":"aka","audio":"aka","English Meaning":"red (the color)"},
 {"漢字 - Kanji":"赤い","ひらがな - Hiragana":"あかい","Romaji (English Letters)":"akai","audio":"akai","English Meaning":"red [dv]"},
 {"漢字 - Kanji":"オレンジ","ひらがな - Hiragana":"おれんじ","Romaji (English Letters)":"orenji","audio":"orenji","English Meaning":"orange (katakana)"},
 {"漢字 - Kanji":"黄色","ひらがな - Hiragana":"きいろ","Romaji (English Letters)":"kiiro","audio":"kiiro","English Meaning":"yellow (the color)"},
 {"漢字 - Kanji":"黄色い","ひらがな - Hiragana":"きいろい","Romaji (English Letters)":"kiiroi","audio":"kiiroi","English Meaning":"yellow [dv]"},
 {"漢字 - Kanji":"黄緑","ひらがな - Hiragana":"きみどり","Romaji (English Letters)":"kimidori","audio":"kimidori","English Meaning":"pea green, yellow-green"},
 {"漢字 - Kanji":"緑","ひらがな - Hiragana":"みどり","Romaji (English Letters)":"midori","audio":"midori","English Meaning":"green"},
 {"漢字 - Kanji":"浅緑","ひらがな - Hiragana":"あさみどり","Romaji (English Letters)":"asamidori","audio":"asamidori","English Meaning":"light green"},
 {"漢字 - Kanji":"深緑","ひらがな - Hiragana":"ふかみどり","Romaji (English Letters)":"fukamidori","audio":"fukamidori","English Meaning":"dark green"},
 {"漢字 - Kanji":"青","ひらがな - Hiragana":"あお","Romaji (English Letters)":"ao","audio":"ao","English Meaning":"blue (the color)"},
 {"漢字 - Kanji":"青い","ひらがな - Hiragana":"あおい","Romaji (English Letters)":"aoi","audio":"aoi","English Meaning":"blue [dv]"},
 {"漢字 - Kanji":"青紫","ひらがな - Hiragana":"あおむらさき","Romaji (English Letters)":"aomurasaki","audio":"aomurasaki","English Meaning":"bluish purple"},
 {"漢字 - Kanji":"紫","ひらがな - Hiragana":"むらさき","Romaji (English Letters)":"murasaki","audio":"murasaki","English Meaning":"purple"},
 {"漢字 - Kanji":"浅紫","ひらがな - Hiragana":"あさむらさき","Romaji (English Letters)":"asamurasaki","audio":"asamurasaki","English Meaning":"light purple"},
 {"漢字 - Kanji":"ピンク","ひらがな - Hiragana":"ぴんく","Romaji (English Letters)":"pinku","audio":"pinku","English Meaning":"pink (katakana)"},
 {"漢字 - Kanji":"黒","ひらがな - Hiragana":"くろ","Romaji (English Letters)":"kuro","audio":"kuro","English Meaning":"black (the color)"},
 {"漢字 - Kanji":"黒い","ひらがな - Hiragana":"くろい","Romaji (English Letters)":"kuroi","audio":"kuroi","English Meaning":"black [dv]"},
 {"漢字 - Kanji":"白","ひらがな - Hiragana":"しろ","Romaji (English Letters)":"shiro","audio":"shiro","English Meaning":"white (the color)"},
 {"漢字 - Kanji":"白い","ひらがな - Hiragana":"しろい","Romaji (English Letters)":"shiroi","audio":"shiroi","English Meaning":"white [dv]"},
 {"漢字 - Kanji":"茶色","ひらがな - Hiragana":"ちゃいろ","Romaji (English Letters)":"chairo","audio":"chairo","English Meaning":"brown (the color)"},
 {"漢字 - Kanji":"茶色い","ひらがな - Hiragana":"ちゃいろい","Romaji (English Letters)":"chairoi","audio":"chairoi","English Meaning":"brown [dv]"},
 {"漢字 - Kanji":"灰色","ひらがな - Hiragana":"はいいろ","Romaji (English Letters)":"haiiro","audio":"haiiro","English Meaning":"gray"},
 {"漢字 - Kanji":"銀色","ひらがな - Hiragana":"ぎんいろ","Romaji (English Letters)":"giniro (gin'iro)","audio":"giniro (gin'iro)","English Meaning":"silver (the color)"},
 {"漢字 - Kanji":"金色","ひらがな - Hiragana":"きんいろ","Romaji (English Letters)":"kiniro (kin'iro)","audio":"kiniro (kin'iro)","English Meaning":"gold (the color)"},
 {"漢字 - Kanji":"桃色","ひらがな - Hiragana":"ももいろ","Romaji (English Letters)":"momoiro","audio":"momoiro","English Meaning":"peach (the color)"},
 {"漢字 - Kanji":"水色","ひらがな - Hiragana":"みずいろ","Romaji (English Letters)":"mizuiro","audio":"mizuiro","English Meaning":"light blue"},
 {"漢字 - Kanji":"空色","ひらがな - Hiragana":"そらいろ","Romaji (English Letters)":"sorairo","audio":"sorairo","English Meaning":"sky-blue"},
 {"漢字 - Kanji":"紺色","ひらがな - Hiragana":"こんいろ","Romaji (English Letters)":"koniro (kon'iro)","audio":"koniro (kon'iro)","English Meaning":"deep blue"},
 {"漢字 - Kanji":"藍色","ひらがな - Hiragana":"あいいろ","Romaji (English Letters)":"aiiro","audio":"aiiro","English Meaning":"indigo blue"},
 {"漢字 - Kanji":"琥珀色","ひらがな - Hiragana":"こはくいろ","Romaji (English Letters)":"kohakuiro","audio":"kohakuiro","English Meaning":"amber (the color)"},
 {"漢字 - Kanji":"朱色","ひらがな - Hiragana":"しゅいろ","Romaji (English Letters)":"shuiro","audio":"shuiro","English Meaning":"scarlet"}
]

const tableshapes = [
  {"漢字 - Kanji":"丸","ひらがな - Hiragana":"まる","Romaji (English Letters)":"maru","audio":"maru","English Meaning":"circle"},
 {"漢字 - Kanji":"三角","ひらがな - Hiragana":"さんかく","Romaji (English Letters)":"sankaku","audio":"sankaku","English Meaning":"triangle"},
 {"漢字 - Kanji":"四角","ひらがな - Hiragana":"しかく","Romaji (English Letters)":"shikaku","audio":"shikaku","English Meaning":"square"},
 {"漢字 - Kanji":"長方形","ひらがな - Hiragana":"ちょうほうけい","Romaji (English Letters)":"chouhoukei","audio":"chouhoukei","English Meaning":"rectangle"},
 {"漢字 - Kanji":"五角","ひらがな - Hiragana":"ごかく","Romaji (English Letters)":"gokaku","audio":"gokaku","English Meaning":"pentagon"},
 {"漢字 - Kanji":"六角","ひらがな - Hiragana":"ろっかく","Romaji (English Letters)":"rokkaku","audio":"rokkaku","English Meaning":"hexagon"},
 {"漢字 - Kanji":"八角","ひらがな - Hiragana":"はっかく","Romaji (English Letters)":"hakkaku","audio":"hakkaku","English Meaning":"octagon"},
 {"漢字 - Kanji":"台形","ひらがな - Hiragana":"だいけい","Romaji (English Letters)":"daikei","audio":"daikei","English Meaning":"trapezoid"},
 {"漢字 - Kanji":"菱形","ひらがな - Hiragana":"ひしがた","Romaji (English Letters)":"hishigata","audio":"hishigata","English Meaning":"diamond shape"},
 {"漢字 - Kanji":"楕円","ひらがな - Hiragana":"だえん","Romaji (English Letters)":"daen","audio":"daen","English Meaning":"ellipse, oval"}
]

const tablesentences = [
  {"Kanji":"三匹の黒い犬を飼っている(います)","Hiragana":" さんびきのくろいいぬをかっている(います)","Romaji":" Sanbiki no kuroi inu o katte iru (imasu).","audio":"sanbiki no kuroi inu o katte iru (imasu)"},
 {"Kanji":"紙に紫の三角を書いて(書いて下さい)","Hiragana":" かみにむらさきのさんかくをかいて(かいてください)","Romaji":" Kami ni murasaki no sankaku o kaite (kaite kudasai).","audio":"kami ni murasaki no sankaku o kaite (kaite kudasai)"},
 {"Kanji":"蚊に刺された(螫されました)","Hiragana":" かにさされた(さされました)","Romaji":" Ka ni sasareta (sasaremashita).","audio":"ka ni sasareta (sasaremashita)"}
]

const tablevocab = [
  {"Kanji":"飼う","Hiragana":"かう","Romaji":"kau","English Meaning":"to have, raise, or keep a pet or other animal) [gv]","audio":"kau"},
 {"Kanji":"紙","Hiragana":"かみ","Romaji":"kami","English Meaning":"paper) [n]","audio":"kami"},
 {"Kanji":"螫す","Hiragana":"さす","Romaji":"sasu","English Meaning":"to sting (i.e. bee, wasp, etc.)) [gv]","audio":"sasu"}
]

const VocabAnimals = () => {

  return (
    <>
      <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Beginner" category="Vocabulary" lesson="Beginning Vocabulary Part 1" />
     <div className={'title'}>
     Basic Vocabulary and Phrases
      </div>
      <h1>{title}</h1>
      <p>
      Below are a number of Japanese vocabulary words associated with animals, colors, and shapes. All words are nouns unless otherwise specified. Please note that the example sentences will be done in plain form with the polite form in parentheses. Due to the complexity of the kanji for many animals, they are often written in katakana although they are not borrowed words. We will identify those where the kanji is rarely used.
      </p>
      <h2>Animals</h2>

      <Table data={tableanimals} />

      <h2>Colors</h2>

      <Table data={tablecolors} />

      <h2>Shapes</h2>

      <Table data={tableshapes} />

      <p>Here are some example sentences:</p>

      <Table data={tablesentences} />

      <Table data={tablevocab} />

    </>
  )
}

export default VocabAnimals