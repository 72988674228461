import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Grade 1 Kanji  - Part 1 of 2"

const basesone = [
  {"漢字 - Kanji": "", "ひらがな - Hiragana": "", "Romaji (English Letters)": "", "English Meaning": "", "audio": ""}
]

const GradeOneOne = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Advanced" category="Grammar" lesson={title} />

      <h1>{title}</h1>

<p>Below are Kanji the Japanese learn in grade 1 of elementary school.
Be sure to read the <Link to="/members/a/kanji/intro/">Introduction to Kanji</Link> if you haven't already.</p>

<p><strong>Legend:</strong></p>
<p>[n] = Noun</p>
<p>[dv] = Descriptive word (true adjective) - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[dn] = Descriptive noun - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[gv] = Godan verb - see <Link to="/members/b/grammar/godan-verbs/">Verb Conjugation (Godan)</Link></p>
<p>[iv] = Ichidan verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[irv] = Irregular verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[c] = Counter</p>

<table>
  <thead>
  <tr>
<th>漢字<br /><Play audio="kanji" /></th>
<th>音読み <Play audio="onyomi" /><br />"On" Reading</th>
<th>訓読み <Play audio="kunyomi" /><br />"Kun" Reading</th>
<th>English Meaning</th>
</tr>
  </thead>
<tbody>
<tr>
<th><span style={{fontSize:`4rem`}}>一</span></th>
<td>イチ (ichi)<br />イツ (itsu)</td>
<td>ひと (hito)<br />ひと・つ (hitotsu)</td>
<td>
One
<br />
<table><tbody><tr><td>Examples:
<br />
一 (いち - ichi - one) [n] <Play audio="ichi" />
<br />
一回 (いっかい - ikkai - one time) [c] <Play audio="ikkai" />
<br />
一人 (ひとり - hitori - one person) [c] <Play audio="hitori" />
<br />
一つ (ひとつ - hitotsu - general counter of one) [c] <Play audio="hitotsu" />
<br />
一日 (ついたち - tsuitachi - the first day of the month) [n] <Play audio="tsuitachi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>二</span></th>
<td>ニ (ni)<br />ジ (ji)</td>
<td>ふた (futa)<br />ふた・つ (futatsu)<br />ふつ (futsu)</td>
<td>
Two
<br />
<table><tbody><tr><td>Examples:
<br />
二 (に - ni - two) [n] <Play audio="ni" />
<br />
二男 (じなん - jinan - second son) [n] <Play audio="jinan" />
<br />
二重 (ふたえ - futae - two-fold, two layers) [n] <Play audio="futae" />
<br />
二つ (ふたつ - futatsu - general counter of two) [c] <Play audio="futatsu" />
<br />
二日 (ふつか - futsuka - the second day of the month) [n] <Play audio="futsuka" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>三</span></th>
<td>サン (san)</td>
<td>み (mi)<br />みつ (mitsu)<br />みっ・つ　(mittsu)</td>
<td>
Three
<br />
<table><tbody><tr><td>Examples:
<br />
三 (さん - san - three) [n] <Play audio="san" />
<br />
三日月 (みかづき - mikazuki - a new moon) [n] <Play audio="mikazuki" />
<br />
三日 (みっか - mikka - the third day of the month) [n] <Play audio="mikka" />
<br />
三つ (みっつ - mittsu - general counter of three) [c] <Play audio="mittsu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>四</span></th>
<td>シ (shi)</td>
<td>よ (yo)<br />よ・つ (yotsu)<br />よっ・つ (yottsu)<br />よん (yon)</td>
<td>
Four
<br />
<table><tbody><tr><td>Examples:
<br />
四 (し - shi - four) [n] <Play audio="shi" />
<br />
四隅 (よすみ - yosumi - four corners) [n] <Play audio="yosumi" />
<br />
四日 (よっか - yokka - the fourth day of the month) [n] <Play audio="yokka" />
<br />
四つ (よっつ - yottsu - general counter of four) [c] <Play audio="yottsu" />
<br />
十四 (じゅうよん - juuyon - fourteen) [n] <Play audio="juuyon" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>五</span></th>
<td>ゴ (go)</td>
<td>いつ (itsu)<br />いつ・つ (itsutsu)</td>
<td>
Five
<br />
<table><tbody><tr><td>Examples:
<br />
五 (ご - go - five) [n] <Play audio="go" />
<br />
五日 (いつか - itsuka - the fifth day of the month) [n] <Play audio="itsuka" />
<br />
五つ (いつつ - itsutsu - general counter of five) [c] <Play audio="itsutsu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>六</span></th>
<td>ロク (roku)</td>
<td>む (mu)<br />む・つ (mutsu)<br />むっ・つ (muttsu)<br />むい (mui)</td>
<td>
Six
<br />
<table><tbody><tr><td>Examples:
<br />
六 (ろく - roku - six) [n] <Play audio="roku" />
<br />
六十路 (むそじ - musoji - sixty years old) [n] <Play audio="musoji" />
<br />
六つ切り (むつぎり - mutsugiri - one sixth) [n] <Play audio="mutsugiri" />
<br />
六つ (むっつ - muttsu - general counter of six) [c] <Play audio="muttsu" />
<br />
六日 (むいか - muika - the sixth day of the month) [n] <Play audio="muika" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>七</span></th>
<td>シチ (shichi)</td>
<td>なな (nana)<br />なな・つ (nanatsu)<br />なの (nano)</td>
<td>
Seven
<br />
<table><tbody><tr><td>Examples:
<br />
七 (しち - shichi - seven) [n] <Play audio="shichi" />
<br />
七つ (ななつ - nanatsu - general counter of seven) [c] <Play audio="nanatsu" />
<br />
七日 (なのか - nanoka - seventh day of the month) [n] <Play audio="nanoka" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>八</span></th>
<td>ハチ (hachi)</td>
<td>や (ya)<br />や・つ (yatsu)<br />やっ・つ (yattsu)<br />よう (you)</td>
<td>
Eight
<br />
<table><tbody><tr><td>Examples:
<br />
八 (はち - hachi - eight) [n] <Play audio="hachi" />
<br />
八千代 (やちよ - yachiyo - eternity, thousands of years) [n] <Play audio="yachiyo" />
<br />
八つ当たりする (やつあたりする - yatsuatari suru - to take it out on anyone) [irv] <Play audio="yatsuatari suru" />
<br />
八つ (やっつ - yattsu - general counter of eight) [c] <Play audio="yattsu" />
<br />
八日 (ようか - youka - the eighth day of the month) [n] <Play audio="youka" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>九</span></th>
<td>ク (ku)<br />キュウ (kyuu)</td>
<td>ここの (kokono)<br />ここの・つ (kokonotsu)</td>
<td>
Nine
<br />
<table><tbody><tr><td>Examples:
<br />
九 (きゅう - kyuu - nine) [n] <Play audio="kyuu" />
<br />
九月 (くがつ - kugatsu - September) [n] <Play audio="kugatsu" />
<br />
九日　(ここのか - kokonoka - the ninth day of the month) [n] <Play audio="kokonoka" />
<br />
九つ (ここのつ - kokonotsu - general counter of nine) [c] <Play audio="kokonotsu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>十</span></th>
<td>ジュウ (juu)<br />ジッ (ji - with a small "tsu")</td>
<td>と (to)<br />とお (too)</td>
<td>
Ten
<br />
<table><tbody><tr><td>Examples:
<br />
十 (じゅう - juu - ten) [n] <Play audio="juu" />
<br />
十種競技 (じっしゅきょうぎ - jisshukyougi - decathlon) [n] <Play audio="jisshukyougi" />
<br />
十重 (とえ - toe - tenfold) [n] <Play audio="toe" />
<br />
十日 (とおか - tooka - the tenth day of the month) [n] <Play audio="tooka" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>百</span></th>
<td>ヒャク (hyaku)</td>
<td></td>
<td>
Hundred
<br />
<table><tbody><tr><td>Examples:
<br />
九百 (きゅうひゃく - kyuuhyaku - nine hundred) [n] <Play audio="kyuuhyaku" />
<br />
三百 (さんびゃく - sanbyaku - three hundred) [n] <Play audio="sanbyaku" />
<br />
六百 (ろっぴゃく - roppyaku - six hundred) [n] <Play audio="roppyaku" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>千</span></th>
<td>セン (sen)</td>
<td>ち (chi)</td>
<td>
Thousand
<br />
<table><tbody><tr><td>Examples:
<br />
五千 (ごせん - gosen - five thousand) [n] <Play audio="gosen" />
<br />
一千 (いっせん - issen - one thousand) [n] <Play audio="issen" />
<br />
三千 (さんぜん - sanzen - three thousand) [n] <Play audio="sanzen" />
<br />
千鳥足 (ちどりあし - chidoriashi - drunken staggering) [n] <Play audio="chidoriashi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>上</span></th>
<td>ジョウ (jou)<br />ショウ (shou)</td>
<td>うえ (ue)<br />うわ (uwa)<br />かみ (kami)<br />あ・げる (ageru)<br />あ・がる (agaru)<br />のぼ・る (noboru)<br />のぼ・せる (noboseru)<br />のぼ・す (nobosu)</td>
<td>
Up
<br />
<table><tbody><tr><td>Examples:
<br />
上手 (じょうず - jouzu - skillfulness) [dn] <Play audio="jouzu" />
<br />
上人 (しょうにん - shounin - a saint) [n] <Play audio="shounin" />
<br />
上 (うえ - ue - above, top) [n] <Play audio="ue" />
<br />
上顎 (うわあご - uwaago - upper jaw, palate) [n] <Play audio="uwaago" />
<br />
上座 (かみざ - kamiza - seat of honor) [n] <Play audio="kamiza" />
<br />
上げる　(あげる - ageru - to raise, to elevate) [iv] <Play audio="ageru" />
<br />
上がる (あがる - agaru - to rise, to go up) [gv] <Play audio="agaru" />
<br />
上る (のぼる - noboru - to ascend, to climb) [gv] <Play audio="noboru" />
<br />
上せる (のぼせる - noboseru - to raise, to record, to bring up (a matter), to serve (food)) [iv] <Play audio="noboseru" />
<br />
上す (のぼす - nobosu - to raise, to record, to bring up (a matter), to serve (food)) [gv] <Play audio="nobosu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>下</span></th>
<td>カ (ka)<br />ゲ (ge)</td>
<td>した (shita)<br />しも (shimo)<br />もと (moto - rarely used)<br />さ・げる (sageru)<br />さ・がる(sagaru)<br />くだ・る (kudaru)<br />くだ・す (kudasu)<br />くだ・さる (kudasaru)<br />くだ・さい (kudasai)<br />お・ろす (orosu)<br />お・りる (oriru)</td>
<td>
Down
<br />
<table><tbody><tr><td>Examples:
<br />
地下鉄 (ちかてつ - chikatetsu - subway) [n] <Play audio="chikatetsu" />
<br />
下痢 (げり - geri - diarrhea) [n] <Play audio="geri" />
<br />
下 (した - shita - below, under) [n] <Play audio="shita" />
<br />
下半期 (しもはんき - shimohanki - the latter half of the year) [n] <Play audio="shimohanki" />
<br />
下げる (さげる - sageru - to hang, to lower) [iv] <Play audio="sageru" />
<br />
下がる (さがる - sagaru - to hang down) [gv] <Play audio="sagaru" />
<br />
下る (くだる - kudaru - to descend) [gv] <Play audio="kudaru" />
<br />
下す (くだす - kudasu - to hand down (orders, etc.)) [gv] <Play audio="kudasu" />
<br />
下さる (くださる - kudasaru - to give, to confer) [gv] <Play audio="kudasaru" />
<br />
下さい (ください - kudasai - used to make a command more polite) <Play audio="kudasai" />
<br />
下ろす (おろす - orosu - to take down, to unload) [gv] <Play audio="orosu" />
<br />
下りる (おりる - oriru - to go down, to dismount) [iv] <Play audio="oriru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>左</span></th>
<td>サ (sa)</td>
<td>ひだり (hidari)</td>
<td>
Left
<br />
<table><tbody><tr><td>Examples:
<br />
左方 (さほう - sahou - left side) [n] <Play audio="sahou" />
<br />
左 (ひだり - hidari - left) [n] <Play audio="hidari" />
<br />
左手 (ひだりて - hidarite - left hand) [n] <Play audio="hidarite" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>右</span></th>
<td>ウ (u)<br />ユウ (yuu)</td>
<td>みぎ (migi)</td>
<td>
Right
<br />
<table><tbody><tr><td>Examples:
<br />
左右 (さゆう - sayuu - left and right; influence, control) [n] <Play audio="sayuu" />
<br />
右 (みぎ - migi - right) [n] <Play audio="migi" />
<br />
右目 (みぎめ - migime - right eye) [n] <Play audio="migime" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>中</span></th>
<td>チュウ (chuu)</td>
<td>なか (naka)</td>
<td>
Inside, Middle
<br />
<table><tbody><tr><td>Examples:
<br />
中学校 (ちゅうがっこう - chuugakkou - junior high school) [n] <Play audio="chuugakkou" />
<br />
背中 (せなか - senaka - back (of body)) [n] <Play audio="senaka" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>大</span></th>
<td>ダイ (dai)<br />タイ (tai)</td>
<td>おお (oo)<br />おお・きい (ookii)<br />おお・いに (ooini)</td>
<td>
Big, Large
<br />
<table><tbody><tr><td>Examples:
<br />
大学 (だいがく - daigaku - college) [n] <Play audio="daigaku" />
<br />
大丈夫 (だいじょうぶ - daijoubu - ok, fine) [dn] <Play audio="daijoubu" />
<br />
大会 (たいかい - taikai - convention, tournament, conference) [n] <Play audio="taikai" />
<br />
大晦日 (おおみそか - oomisoka - New Year's Eve) [n] <Play audio="oomisoka" />
<br />
大きい (おおきい - ookii - big, large) [dv] <Play audio="ookii" />
<br />
大いに (おおいに - ooini - very, much, greatly) <Play audio="ooini" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>小</span></th>
<td>ショウ (shou)</td>
<td>ちい・さい (chiisai)<br />こ (ko)<br />お (o)</td>
<td>
Little, Small
<br />
<table><tbody><tr><td>Examples:
<br />
小学校 (しょうがっこう - shougakkou - elementary school) [n] <Play audio="shougakkou" />
<br />
小さい (ちいさい - chiisai - little, small) [dv] <Play audio="chiisai" />
<br />
小声 (こごえ - kogoe - low voice, whisper) [n] <Play audio="kogoe" />
<br />
小川 (おがわ - ogawa - stream) [n] <Play audio="ogawa" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>月</span></th>
<td>ガツ (gatsu)<br />ゲツ (getsu)</td>
<td>つき (tsuki)</td>
<td>
Moon, Month
<br />
<table><tbody><tr><td>Examples:
<br />
三月 (さんがつ - sangatsu - March) [n] <Play audio="sangatsu" />
<br />
月曜日 (げつようび - getsuyoubi - Monday) [n] <Play audio="getsuyoubi" />
<br />
月 (つき - tsuki - moon) [n] <Play audio="tsuki" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>日</span></th>
<td>ニチ (nichi)<br />ジツ (jitsu)</td>
<td>ひ (hi)<br />か (ka)</td>
<td>
Day, Sun
<br />
<table><tbody><tr><td>Examples:
<br />
日曜日 (にちようび - nichiyoubi - Sunday) [n] <Play audio="nichiyoubi" />
<br />
日本 (にほん - nihon - Japan) [n] <Play audio="nihon" />
<br />
本日 (ほんじつ - honjitsu - today) [n] <Play audio="honjitsu" />
<br />
日 (ひ - hi - day) [n] <Play audio="hi" />
<br />
二十日 (はつか - hatsuka - the twentieth day of the month) [n] <Play audio="hatsuka" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>年</span></th>
<td>ネン (nen)</td>
<td>とし (toshi)</td>
<td>
Year
<br />
<table><tbody><tr><td>Examples:
<br />
去年 (きょねん - kyonen - last year) [n] <Play audio="kyonen" />
<br />
今年 (ことし - kotoshi - this year) [n] <Play audio="kotoshi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>早</span></th>
<td>ソウ (sou)<br />サッ (sa - with a small "tsu")</td>
<td>はや (haya)<br />はや・い (hayai)<br />はや・まる (hayamaru)<br />はや・める (hayameru)</td>
<td>
Early, Fast
<br />
<table><tbody><tr><td>Examples:
<br />
早退する (そうたいする - soutai suru - to leave early) [irv] <Play audio="soutai suru" />
<br />
早速 (さっそく - sassoku - immediately) <Play audio="sassoku" />
<br />
早口言葉 (はやくちことば - hayakuchikotoba - tongue twister) [n] <Play audio="hayakuchikotoba" />
<br />
早い (はやい - hayai - early, fast) [dv] <Play audio="hayai" />
<br />
早まる (はやまる - hayamaru - to quicken, to speed up) [gv] <Play audio="hayamaru" />
<br />
早める (はやめる - hayameru - to expedite, to accelerate) [iv] <Play audio="hayameru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>木</span></th>
<td>モク (moku)<br />ボク (boku)</td>
<td>き (ki)<br />こ (ko)</td>
<td>
Tree
<br />
<table><tbody><tr><td>Examples:
<br />
木曜日 (もくようび - mokuyoubi - Thursday) [n] <Play audio="mokuyoubi" />
<br />
木刀 (ぼくとう - bokutou - wooden sword) [n] <Play audio="bokutou" />
<br />
木 (き - ki - tree) [n] <Play audio="ki" />
<br />
木陰 (こかげ - kokage - shade of tree) [n] <Play audio="kokage" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>林</span></th>
<td>リン (rin)</td>
<td>はやし (hayashi)</td>
<td>
Woods
<br />
<table><tbody><tr><td>Examples:
<br />
林檎 (りんご - ringo - apple) [n] <Play audio="ringo" />
<br />
林 (はやし - hayashi - woods, thicket) [n] <Play audio="hayashi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>山</span></th>
<td>サン (san)</td>
<td>やま (yama)</td>
<td>
Mountain
<br />
<table><tbody><tr><td>Examples:
<br />
富士山 (ふじさん - fujisan - Mount Fuji) [n] <Play audio="fujisan" />
<br />
山 (やま - yama - mountain) [n] <Play audio="yama" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>川</span></th>
<td>セン (sen)</td>
<td>かわ (kawa)</td>
<td>
River
<br />
<table><tbody><tr><td>Examples:
<br />
河川 (かせん - kasen - rivers) [n] <Play audio="kasen" />
<br />
川 (かわ - kawa - river) [n] <Play audio="kawa" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>土</span></th>
<td>ド (do)<br />ト (to)</td>
<td>つち (tsuchi)</td>
<td>
Soil, Dirt
<br />
<table><tbody><tr><td>Examples:
<br />
土曜日 (どようび - doyoubi - Saturday) [n] <Play audio="doyoubi" />
<br />
土地 (とち - tochi - plot of land, lot) [n] <Play audio="tochi" />
<br />
土 (つち - tsuchi - soil, dirt) [n] <Play audio="tsuchi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>空</span></th>
<td>クウ (kuu)</td>
<td>そら (sora)<br />あ・く (aku)<br />あ・ける (akeru)<br />から (kara)</td>
<td>
Sky
<br />
<table><tbody><tr><td>Examples:
<br />
空中 (くうちゅう - kuuchuu - sky, air) [n] <Play audio="kuuchuu" />
<br />
空 (そら - sora - sky, the heavens) [n] <Play audio="sora" />
<br />
空く (あく - aku - to be empty) [gv] <Play audio="aku" />
<br />
お腹が空く (おなかがすく - o-naka ga suku - to become hungry) [gv] <Play audio="o-naka ga suku" />
<br />
空ける (あける - akeru - to empty, to make space) [iv] <Play audio="akeru" />
<br />
空 (から - kara - emptiness, empty) [dn] <Play audio="kara" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>田</span></th>
<td>デン (den)</td>
<td>た (ta)</td>
<td>
Rice paddy
<br />
<table><tbody><tr><td>Examples:
<br />
水田 (すいでん - suiden - water-filled paddy field) [n] <Play audio="suiden" />
<br />
田中 (たなか - Tanaka - common Japanese family name) <Play audio="tanaka" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>天</span></th>
<td>テン (ten)</td>
<td>あめ (ame - rarely used)<br />あま (ama)</td>
<td>
Heaven, Sky
<br />
<table><tbody><tr><td>Examples:
<br />
天 (てん - ten - heaven, sky) [n] <Play audio="ten" />
<br />
天使 (てんし - tenshi - angel) [n] <Play audio="tenshi" />
<br />
天下り (あまくだり - amakudari - retiring high-ranking government officials taking a lucrative job in a private or semi-private corporation) [n] <Play audio="amakudari" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>生</span></th>
<td>ショウ (shou)<br />セイ (sei)</td>
<td>い・きる (ikiru)<br />い・かす (ikasu)<br />い・ける (ikeru)<br />う・まれる (umareru)<br />う・む (umu)<br />お・う (ou)<br />は・える (haeru)<br />は・やす (hayasu)<br />き (ki)<br />なま (nama)</td>
<td>
Life
<br />
<table><tbody><tr><td>Examples:
<br />
一生懸命 (いっしょうけんめい - isshoukenmei - with all one's might) [dn] <Play audio="isshoukenmei" />
<br />
生活 (せいかつ - seikatsu - life, one's daily existence) [n] <Play audio="seikatsu" />
<br />
生きる (いきる - ikiru - to live) [iv] <Play audio="ikiru" />
<br />
生ける (いける - ikeru - to arrange flowers, to plant) [iv] <Play audio="ikeru" />
<br />
生まれる (うまれる - umareru - to be born) [iv] <Play audio="umareru" />
<br />
生む (うむ - umu - to give birth) [gv] <Play audio="umu" />
<br />
生う (おう - ou - to grow, to spring up) [gv] <Play audio="ou" />
<br />
生える (はえる - haeru - to grow, to spring up) [iv] <Play audio="haeru" />
<br />
生やす (はやす - hayasu - to grow, to cultivate) [gv] <Play audio="hayasu" />
<br />
生地 (きじ - kiji - cloth, material) [n] <Play audio="kiji" />
<br />
生卵 (なまたまご - namatamago - raw egg) [n] <Play audio="namatamago" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>花</span></th>
<td>カ (ka)</td>
<td>はな (hana)</td>
<td>
Flower
<br />
<table><tbody><tr><td>Examples:
<br />
花瓶 (かびん - kabin - vase) [n] <Play audio="kabin" />
<br />
花 (はな - hana - flower) [n] <Play audio="hana" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>草</span></th>
<td>ソウ (sou)</td>
<td>くさ (kusa)</td>
<td>
Grass
<br />
<table><tbody><tr><td>Examples:
<br />
雑草 (ざっそう - zassou - weed) [n] <Play audio="zassou" />
<br />
草 (くさ - kusa - grass) [n] <Play audio="kusa" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>虫</span></th>
<td>チュウ (chuu)</td>
<td>むし (mushi)</td>
<td>
Insect
<br />
<table><tbody><tr><td>Examples:
<br />
昆虫 (こんちゅう - konchuu - insect) [n] <Play audio="konchuu" />
<br />
虫 (むし - mushi - bug) [n] <Play audio="mushi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>犬</span></th>
<td>ケン (ken)</td>
<td>いぬ (inu)</td>
<td>
Dog
<br />
<table><tbody><tr><td>Examples:
<br />
犬歯 (けんし - kenshi - canine tooth) [n] <Play audio="kenshi" />
<br />
犬 (いぬ - inu - dog) [n] <Play audio="inu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>人</span></th>
<td>ニン (nin)<br />ジン (jin)</td>
<td>ひと (hito)</td>
<td>
Person
<br />
<table><tbody><tr><td>Examples:
<br />
人間 (にんげん - ningen - human being) [n] <Play audio="ningen" />
<br />
人名 (じんめい - jinmei - person's name) [n] <Play audio="jinmei" />
<br />
人 (ひと - hito - person) [n] <Play audio="hito" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>名</span></th>
<td>ミョウ (myou)<br />メイ (mei)</td>
<td>な (na)</td>
<td>
Name
<br />
<table><tbody><tr><td>Examples:
<br />
名字 (みょうじ - myouji - surname, family/last name) [n] <Play audio="myouji" />
<br />
名人 (めいじん - meijin - master, expert) [n] <Play audio="meijin" />
<br />
名前 (なまえ - namae - name, full name or given/first name) [n] <Play audio="namae" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>女</span></th>
<td>ニョ (nyo)<br />ジョ (jo)<br />ニョウ (nyou)</td>
<td>おんな (onna)<br />め (me)</td>
<td>
Female
<br />
<table><tbody><tr><td>Examples:
<br />
老若男女 (ろうにゃくなんにょ - rounyakunannyo - men and women of all ages) [n] <Play audio="rounyakunannyo" />
<br />
女性 (じょせい - josei - woman, female) [n] <Play audio="josei" />
<br />
女房 (にょうぼう - nyoubou - wife) [n] <Play audio="nyoubou" />
<br />
女 (おんな - onna - woman) [n] <Play audio="onna" />
<br />
女の子 (おんなのこ - onna no ko - girl) [n] <Play audio="onna no ko" />
<br />
女神 (めがみ - megami - goddess) [n] <Play audio="megami" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>男</span></th>
<td>ナン (nan)<br />ダン (dan)</td>
<td>おとこ (otoko)</td>
<td>
Male
<br />
<table><tbody><tr><td>Examples:
<br />
長男 (ちょうなん - chounan - eldest son) [n] <Play audio="chounan" />
<br />
男性 (だんせい - dansei - man, male) [n] <Play audio="dansei" />
<br />
男 (おとこ - otoko - man) [n] <Play audio="otoko" />
<br />
男の子 (おとこのこ - otoko no ko - boy) [n] <Play audio="otoko no ko" />
</td></tr></tbody></table></td>
</tr>
</tbody></table>

    </>
  )
}

export default GradeOneOne