import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Using the Verb Bases Part 3"

const basesthree1 = [
  {"ひらがな - Hiragana":"Base 1 + す","Romaji (English Letters)":"Base 1 + su","audio":"su","English Meaning":"cause to verb (Godan verbs only)"},
 {"ひらがな - Hiragana":"Base 1 + さす","Romaji (English Letters)":"Base 1 + sasu","audio":"sasu","English Meaning":"cause to verb (Ichidan verbs only)"},
 {"ひらがな - Hiragana":"Base 2 + やすい","Romaji (English Letters)":"Base 2 + yasui","audio":"yasui","English Meaning":"easy to verb(becomes a \"dv\")"},
 {"ひらがな - Hiragana":"Base 2 + にくい","Romaji (English Letters)":"Base 2 + nikui","audio":"nikui","English Meaning":"hard to verb(becomes a \"dv\" - be advised that \"minikui\" does not mean \"hard to see\" (which would be \"mienikui\") but, instead, means \"ugly\")"},
 {"ひらがな - Hiragana":"Base 2 + はじめる","Romaji (English Letters)":"Base 2 + hajimeru","audio":"hajimeru","English Meaning":"start verbing(\"hajimeru\" is an Ichidan verb)"},
 {"ひらがな - Hiragana":"Base 2 + おわる","Romaji (English Letters)":"Base 2 + owaru","audio":"owaru","English Meaning":"finish verbing"},
 {"ひらがな - Hiragana":"Base 2 + かた","Romaji (English Letters)":"Base 2 + kata","audio":"kata","English Meaning":"way of verbing, how to verb(becomes a noun)"},
 {"ひらがな - Hiragana":"Base 2 + すぎる","Romaji (English Letters)":"Base 2 + sugiru","audio":"sugiru","English Meaning":"verb too much(\"sugiru\" is an Ichidan verb)"},
 {"ひらがな - Hiragana":"Base 3 + ことになる","Romaji (English Letters)":"Base 3 + koto ni naru","audio":"koto ni naru","English Meaning":"the verbing will be decided, will come about(can be made negative with Base 1 + nai)"},
 {"ひらがな - Hiragana":"Base 3 + ことにする","Romaji (English Letters)":"Base 3 + koto ni suru","audio":"koto ni suru","English Meaning":"decide to verb(can be made negative with Base 1 + nai)"},
 {"ひらがな - Hiragana":"Base 3 + ことができる","Romaji (English Letters)":"Base 3 + koto ga dekiru","audio":"koto ga dekiru","English Meaning":"can verb(\"dekiru\" is an Ichidan verb)"},
 {"ひらがな - Hiragana":"Base 3 + ことがある","Romaji (English Letters)":"Base 3 + koto ga aru","audio":"koto ga aru","English Meaning":"have occasion to verb"},
 {"ひらがな - Hiragana":"Base \"ta\" + ことがある","Romaji (English Letters)":"Base \"ta\" + koto ga aru","audio":"koto ga aru","English Meaning":"have verbed before"},
 {"ひらがな - Hiragana":"Base 3 + のに","Romaji (English Letters)":"Base 3 + noni","audio":"noni","English Meaning":"although one verbs(can be made negative with Base 1 + nai, past negative with Base 1 + nakatta, or past tense with Base \"ta\")"},
 {"ひらがな - Hiragana":"Base 3 + のに","Romaji (English Letters)":"Base 3 + noni","audio":"noni","English Meaning":"in order to verb"},
 {"ひらがな - Hiragana":"Base 3 + ために","Romaji (English Letters)":"Base 3 + tame ni","audio":"tame ni","English Meaning":"in order to verb"},
 {"ひらがな - Hiragana":"Base 3 + ようになる","Romaji (English Letters)":"Base 3 + you ni naru","audio":"you ni naru","English Meaning":"finally reach the point of verbing"},
 {"ひらがな - Hiragana":"Base 5 + とおもう","Romaji (English Letters)":"Base 5 + to omou","audio":"to omou","English Meaning":"desire or deside to verb"},
 {"ひらがな - Hiragana":"Base \"te\" + おく","Romaji (English Letters)":"Base \"te\" + oku","audio":"oku","English Meaning":"verb in advance, go ahead and verb"},
 {"ひらがな - Hiragana":"Base \"te\" + しまう","Romaji (English Letters)":"Base \"te\" + shimau","audio":"shimau","English Meaning":"complete verbing (sometimes by accident or mistakenly)"},
 {"ひらがな - Hiragana":"replace last character of Base \"ta\" with ちゃう","Romaji (English Letters)":"replace last character of Base \"ta\" with chau","audio":"chau","English Meaning":"short form of Base \"te\" + shimau"},
 {"ひらがな - Hiragana":"Base \"te\" + もらう","Romaji (English Letters)":"Base \"te\" + morau","audio":"morau","English Meaning":"have someone verb for you"}
]
const basesthree2 = [
  {"Kanji":"赤ちゃんに牛乳を飲まして","Hiragana":"あかちゃんにぎゅうにゅうをのまして","Romaji":"Akachan ni gyuunyuu o nomashite","audio":"akachan ni gyuunyuu o nomashite","English Meaning":"Feed the baby milk (via drinking)."},
  {"Kanji":"赤ちゃんに何かを食べさして","Hiragana":"あかちゃんになにかをたべさして","Romaji":"Akachan ni nanika o tabesashite","audio":"akachan ni nanika o tabesashite","English Meaning":"Feed the baby something."},
  {"Kanji":"この靴は歩きやすい","Hiragana":"このくつはあるきやすい","Romaji":"Kono kutsu wa arukiyasui","audio":"kono kutsu wa arukiyasui","English Meaning":"These shoes are easy to walk in."},
  {"Kanji":"彼女の携帯電話は使いにくい","Hiragana":"かのじょのけいたいでんわはつかいにくい","Romaji":"Kanojo no keitaidenwa wa tsukainikui","audio":"kanojo no keitaidenwa wa tsukainikui","English Meaning":"Her cell phone is hard to use."},
  {"Kanji":"図書館から帰ったら直ぐに借りた本を読み始めるつもりだ","Hiragana":"としょかんからかえったらすぐにかりたほんをよみはじめるつもりだ","Romaji":"Toshokan kara kaettara sugu ni karita hon o yomihajimeru tsumori da","audio":"toshokan kara kaettara sugu ni karita hon o yomihajimeru tsumori da","English Meaning":"I plan on starting to read the book I checked out as soon as I get home from the library."},
  {"Kanji":"子供の机を作り終わったの","Hiragana":"こどものつくえをつくりおわったの","Romaji":"Kodomo no tsukue o tsukuriowatta no","audio":"kodomo no tsukue o tsukuriowatta no","English Meaning":"Have you finished building the kids' desk?"},
  {"Kanji":"日本語の一番いい学び方を教えて","Hiragana":"にほんごのいちばんいいまなびかたをおしえて","Romaji":"Nihongo no ichiban ii manabikata o oshiete","audio":"nihongo no ichiban ii manabikata o oshie","English Meaning":"Tell/teach me the best way to learn Japanese."},
  {"Kanji":"お正月のご馳走を食べ過ぎないでね","Hiragana":"おしょうがつのごちそうをたべすぎないでね","Romaji":"Oshougatsu no gochisou o tabesuginaide ne","audio":"oshougatsu no gochisou o tabesuginaide ","English Meaning":"Don't eat too much of our New Year's feast, ok."},
  {"Kanji":"仕事の転勤で日本に引っ越すことになるかも知れない","Hiragana":"しごとのてんきんでにほんにひっこすことになるかもしれない","Romaji":"Shigoto no tenkin de nihon ni hikkosu koto ni naru kamo shiren","audio":"shigoto no tenkin de nihon ni hikkosu koto ni naru kamo shirenai","English Meaning":"They may have me move to Japan for a work transfer."},
  {"Kanji":"お金がないから日本に戻らないことにした","Hiragana":"おかねがないからにほんにもどらないことにした","Romaji":"Okane ga nai kara nihon ni modoranai koto ni shita","audio":"okane ga nai kara nihon ni modoranai koto ni shita","English Meaning":"We have no money so we decided not to return to Japan."},
  {"Kanji":"一輪車に乗ることができると思う？", "Hiragana": "いちりんしゃにのることができるとおもう？", "Romaji": "Ichirinsha ni noru koto ga dekiru to omou?", "audio":"ichirinsha ni noru koto ga dekiru to omou--","English Meaning":"Do you think I/you (depending on context) can/could ride a unicycle?"},
  {"Kanji":"時々ビールを飲むことがある","Hiragana":"とこどきビールをのむことがある","Romaji":"Tokidoki bi-ru o nomu koto ga aru","audio":"tokidoki bi-ru o nomu koto ga aru","English Meaning":"I have occasion to drink beer sometimes."},
  {"Kanji":"東京のディズニーランドに行ったことがあるの","Hiragana":"とうきょうのディズニーランドにいったことがあるの","Romaji":"Toukyou no dizuni-rando ni itta koto ga aru no","audio":"toukyou no dizuni-rando ni itta koto ga aru no","English Meaning":"Have you ever been to Tokyo Disneyland before?"},
  {"Kanji":"昨日、このシャツを洗濯したのにもう汚れてしまった","Hiragana":"きのう、このシャツをせんたくしたのにもうよごれてしまった","Romaji":"Kinou, kono shatsu o sentaku shita noni mou yogorete shimat","audio":"kinou kono shatsu o sentaku shita noni mou yogorete shimatta","English Meaning":"I just washed this shirt yesterday but it's already gotten dirty."},
  {"Kanji":"日本語を学ぶのにたくさん勉強する","Hiragana":"にほんごをまなぶのにたくさんべんきょうする","Romaji":"Nihongo o manabu noni takusan benkyou suru","audio":"nihongo o manabu noni takusan benkyou su","English Meaning":"In order to learn Japanese, I'm going to study a lot."},
  {"Kanji":"日本語を学ぶためにたくさん勉強する","Hiragana":"にほんごをまなぶためにたくさんべんきょうする","Romaji":"Nihongo o manabu tame ni takusan benkyou suru","audio":"nihongo o manabu tame ni takusan benkyou suru","English Meaning":"In order to learn Japanese, I'm going to study a lot."},
  {"Kanji":"眼鏡を買ってから見えるようになった","Hiragana":"めがねをかってからみえるようになった","Romaji":"Megane o katte kara mieru you ni natta","audio":"megane o katte kara mieru you ni natta","English Meaning":"After I bought glasses I was finally able to see."},
  {"Kanji":"暑かったから泳ごうと思った","Hiragana":"あつかったからおよごうとおもった","Romaji":"Atsukatta kara oyogou to omotta","audio":"atsukatta kara oyogou to omotta","English Meaning":"It was hot so I decided to go swimming."},
  {"Kanji":"再来月、日本に行くからお金を貯めて置く","Hiragana":"さらいげつ、にほんにいくからおかねをためておく","Romaji":"Saraigetsu, nihon ni iku kara okane o tamete oku","audio":"saraigetsu nihon ni iku kara okane o tamete oku","English Meaning":"I'm going to Japan the month after next so I'm going to save up money in advance/preparation."},
  {"Kanji":"昨日、このシャツを洗濯したのにもう汚れちゃった","Hiragana":"きのう、このシャツをせんたくしたのにもうよごれちゃった","Romaji":"Kinou, kono shatsu o sentaku shita noni mou yogorechatta","audio":"kinou kono shatsu o sentaku shita noni mou yogorechatta","English Meaning":"I just washed this shirt yesterday but it's already gotten dirty."},
  {"Kanji":"バスに乗り遅れたのでいとこに送ってもらった","Hiragana":"バスにのりおくれたのでいとこにおくってもらった","Romaji":"Basu ni noriokureta node itoko ni okutte moratta","audio":"basu ni noriokureta node itoko ni okutte moratta","English Meaning":"I was late for the bus so I got my cousin to give me a ride."}
]
const basesthree3 = [
  {"Kanji":"赤ちゃん","Hiragana":"あかちゃん","Romaji":"akachan","English Meaning":"baby, infant [n]","audio":"akachan"},
 {"Kanji":"牛乳","Hiragana":"ぎゅうにゅう","Romaji":"gyuunyuu","English Meaning":"(cow's) milk [n]","audio":"gyuunyuu"},
 {"Kanji":"何か","Hiragana":"なにか","Romaji":"nanika","English Meaning":"something","audio":"nanika"},
 {"Kanji":"靴","Hiragana":"くつ","Romaji":"kutsu","English Meaning":"shoes, footwear [n]","audio":"kutsu"},
 {"Kanji":"歩く","Hiragana":"あるく","Romaji":"aruku","English Meaning":"to walk　[gv]","audio":"aruku"},
 {"Kanji":"携帯電話","Hiragana":"けいたいでんわ","Romaji":"keitaidenwa","English Meaning":"mobile telephone, cellular telephone [n]","audio":"keitaidenwa"},
 {"Kanji":"使う","Hiragana":"つかう","Romaji":"tsukau","English Meaning":"to use (a thing, method, etc.), to make use of, to put to use [gv]","audio":"tsukau"},
 {"Kanji":"図書館","Hiragana":"としょかん","Romaji":"toshokan","English Meaning":"library [n]","audio":"toshokan"},
 {"Kanji":"帰る","Hiragana":"かえる","Romaji":"kaeru","English Meaning":"to return home [gv]","audio":"kaeru"},
 {"Kanji":"直ぐに","Hiragana":"すぐに","Romaji":"sugu ni","English Meaning":"instantly, immediately","audio":"sugu ni"},
 {"Kanji":"借りる","Hiragana":"かりる","Romaji":"kariru","English Meaning":"to borrow, to have a loan, to rent [iv]","audio":"kariru"},
 {"Kanji":"読む","Hiragana":"よむ","Romaji":"yomu","English Meaning":"to read [gv]","audio":"yomu"},
 {"Kanji":"始める","Hiragana":"はじめる","Romaji":"hajimeru","English Meaning":"to start, to begin [iv]","audio":"hajimeru"},
 {"Kanji":"つもり","Hiragana":"つもり","Romaji":"tsumori","English Meaning":"intention, plan [n]","audio":"tsumori"},
 {"Kanji":"机","Hiragana":"つくえ","Romaji":"tsukue","English Meaning":"desk [n]","audio":"tsukue"},
 {"Kanji":"作る","Hiragana":"つくる","Romaji":"tsukuru","English Meaning":"to make, to produce, to manufacture, to build, to construct [gv]","audio":"tsukuru"},
 {"Kanji":"終わる","Hiragana":"おわる","Romaji":"owaru","English Meaning":"to finish, to end, to close [gv]","audio":"owaru"},
 {"Kanji":"一番","Hiragana":"いちばん","Romaji":"ichiban","English Meaning":"best, first, number one","audio":"ichiban"},
 {"Kanji":"学ぶ","Hiragana":"まなぶ","Romaji":"manabu","English Meaning":"to study (in depth), to learn, to take lessons in [gv]","audio":"manabu"},
 {"Kanji":"教える","Hiragana":"おしえる","Romaji":"oshieru","English Meaning":"to teach, to inform, to instruct [iv]","audio":"oshieru"},
 {"Kanji":"お正月","Hiragana":"おしょうがつ","Romaji":"oshougatsu","English Meaning":"New Year, New Year's Day, the first month, January [n]","audio":"oshougatsu"},
 {"Kanji":"ご馳走","Hiragana":"ごちそう","Romaji":"gochisou","English Meaning":"feast, treating (someone) [n]","audio":"gochisou"},
 {"Kanji":"過ぎる","Hiragana":"すぎる","Romaji":"sugiru","English Meaning":"to pass by, to go beyond, to exceed, to surpass [iv]","audio":"sugiru"},
 {"Kanji":"仕事","Hiragana":"しごと","Romaji":"shigoto","English Meaning":"work, job, business, occupation, employment [n]","audio":"shigoto"},
 {"Kanji":"転勤","Hiragana":"てんきん","Romaji":"tenkin","English Meaning":"job transfer, job relocation, intra-company transfer [n]","audio":"tenkin"},
 {"Kanji":"引っ越す","Hiragana":"ひっこす","Romaji":"hikkosu","English Meaning":"to move (house), to change residence [gv]","audio":"hikkosu"},
 {"Kanji":"お金","Hiragana":"おかね","Romaji":"okane","English Meaning":"money [n]","audio":"okane"},
 {"Kanji":"戻る","Hiragana":"もどる","Romaji":"modoru","English Meaning":"to return, to go back [gv]","audio":"modoru"},
 {"Kanji":"一輪車","Hiragana":"いちりんしゃ","Romaji":"ichirinsha","English Meaning":"unicycle [n]","audio":"ichirinsha"},
 {"Kanji":"乗る","Hiragana":"のる","Romaji":"noru","English Meaning":"to get on (train, plane, bus, ship, etc.), to ride [gv]","audio":"noru"},
 {"Kanji":"時々","Hiragana":"ときどき","Romaji":"tokidoki","English Meaning":"sometimes, at times","audio":"tokidoki"},
 {"Kanji":"ビール","Hiragana":"ビール","Romaji":"bi-ru","English Meaning":"beer [n]","audio":"bi-ru"},
 {"Kanji":"シャツ","Hiragana":"シャツ","Romaji":"shatsu","English Meaning":"shirt [n]","audio":"shatsu"},
 {"Kanji":"洗濯","Hiragana":"せんたく","Romaji":"sentaku","English Meaning":"washing, laundry [n]","audio":"sentaku"},
 {"Kanji":"もう","Hiragana":"もう","Romaji":"mou","English Meaning":"already, anymore, soon, shortly, more, further, other, again","audio":"mou"},
 {"Kanji":"汚れる","Hiragana":"よごれる","Romaji":"yogoreru","English Meaning":"to get dirty, to become dirty [iv]","audio":"yogoreru"},
 {"Kanji":"沢山","Hiragana":"たくさん","Romaji":"takusan","English Meaning":"many, a lot, much","audio":"takusan"},
 {"Kanji":"勉強","Hiragana":"べんきょう","Romaji":"benkyou","English Meaning":"study [n]","audio":"benkyou"},
 {"Kanji":"為","Hiragana":"ため","Romaji":"tame","English Meaning":"benefit, sake, purpose, objective [n]","audio":"tame"},
 {"Kanji":"眼鏡","Hiragana":"めがね","Romaji":"megane","English Meaning":"spectacles, glasses [n]","audio":"megane"},
 {"Kanji":"買う","Hiragana":"かう","Romaji":"kau","English Meaning":"to buy, to purchase [gv]","audio":"kau"},
 {"Kanji":"見える","Hiragana":"みえる","Romaji":"mieru","English Meaning":"to be seen, to be in sight [iv]","audio":"mieru"},
 {"Kanji":"泳ぐ","Hiragana":"およぐ","Romaji":"oyogu","English Meaning":"to swim [gv]","audio":"oyogu"},
 {"Kanji":"再来月","Hiragana":"さらいげつ","Romaji":"saraigetsu","English Meaning":"month after next [n]","audio":"saraigetsu"},
 {"Kanji":"来月","Hiragana":"らいげつ","Romaji":"raigetsu","English Meaning":"next month [n]","audio":"raigetsu"},
 {"Kanji":"貯める","Hiragana":"ためる","Romaji":"tameru","English Meaning":"to save (i.e. in a bank) [iv]","audio":"tameru"},
 {"Kanji":"置く","Hiragana":"おく","Romaji":"oku","English Meaning":"to put, to place [gv]","audio":"oku"},
 {"Kanji":"バス","Hiragana":"バス","Romaji":"basu","English Meaning":"bus [n]","audio":"basu"},
 {"Kanji":"遅れる","Hiragana":"おくれる","Romaji":"okureru","English Meaning":"to be late, to be delayed, to fall behind schedule, to be overdue [iv]","audio":"okureru"},
 {"Kanji":"乗り遅れる","Hiragana":"のりおくれる","Romaji":"noriokureru","English Meaning":"to miss (train, bus, etc.) [iv]","audio":"noriokureru"},
 {"Kanji":"いとこ","Hiragana":"いとこ","Romaji":"itoko","English Meaning":"cousin [n]","audio":"itoko"},
 {"Kanji":"送る","Hiragana":"おくる","Romaji":"okuru","English Meaning":"to send (a thing), to take or escort (a person somewhere) [gv]","audio":"okuru"},
 {"Kanji":"貰う","Hiragana":"もらう","Romaji":"morau","English Meaning":"to receive, to take, to accept [gv]","audio":"morau"}
]

const BasesThree = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Intermediate" category="Grammar" lesson={title} />
     <div className={'title'}>
     {title}
      </div>
      <h1>{title}</h1>

<p>Here are some additional ways that the verb bases can be used. You may want to regularly refer to <Link to="/members/b/grammar/godan-verbs/">Verb Conjugation (Godan)</Link> and <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link> while learning these. The following chart applies to all verbs unless otherwise noted. Be sure to check out the example sentences at the end of the lesson. Please note that the example sentences will all be done in plain form.</p>

<Table data={basesthree1} />

<p>Example sentences:</p>

<Table data={basesthree2} />

<h2>Vocab Breakdown</h2>

<Table data={basesthree3} />

    </>
  )
}

export default BasesThree