import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Ichidan and Irregular Verbs"

const ichidanverbs1 = [
  {"漢字 - Kanji":"食べる","ひらがな - Hiragana":"たべる","Romaji (English Letters)":"taberu  (to eat)","audio":"taberu  (to eat)","Verb Base":""},
 {"漢字 - Kanji":"食べ","ひらがな - Hiragana":"たべ","Romaji (English Letters)":"tabe","audio":"tabe","Verb Base":"Base 1"},
 {"漢字 - Kanji":"食べ","ひらがな - Hiragana":"たべ","Romaji (English Letters)":"tabe","audio":"tabe","Verb Base":"Base 2"},
 {"漢字 - Kanji":"食べる","ひらがな - Hiragana":"たべる","Romaji (English Letters)":"taberu","audio":"taberu","Verb Base":"Base 3"},
 {"漢字 - Kanji":"食べれ","ひらがな - Hiragana":"たべれ","Romaji (English Letters)":"tabere","audio":"tabere","Verb Base":"Base 4"},
 {"漢字 - Kanji":"食べよう","ひらがな - Hiragana":"たべよう","Romaji (English Letters)":"tabeyou","audio":"tabeyou","Verb Base":"Base 5"},
 {"漢字 - Kanji":"食べて","ひらがな - Hiragana":"たべて","Romaji (English Letters)":"tabete","audio":"tabete","Verb Base":"Base \"te\""},
 {"漢字 - Kanji":"食べた","ひらがな - Hiragana":"たべた","Romaji (English Letters)":"tabeta","audio":"tabeta","Verb Base":"Base \"ta\""}
]
const ichidanverbs2 = [
  {"漢字 - Kanji":"いる","ひらがな - Hiragana":"いる","Romaji (English Letters)":"iru","audio":"iru","English Meaning":"to exist (animate), to be somewhere"},
  {"漢字 - Kanji":"見る","ひらがな - Hiragana":"みる","Romaji (English Letters)":"miru","audio":"miru","English Meaning":"to see"},
  {"漢字 - Kanji":"寝る","ひらがな - Hiragana":"ねる","Romaji (English Letters)":"neru","audio":"neru","English Meaning":"to sleep"},
  {"漢字 - Kanji":"着る","ひらがな - Hiragana":"きる","Romaji (English Letters)":"kiru","audio":"kiru","English Meaning":"to wear, put on"},
  {"漢字 - Kanji":"出来る","ひらがな - Hiragana":"できる","Romaji (English Letters)":"dekiru","audio":"dekiru","English Meaning":"can do"},
  {"漢字 - Kanji":"覚える","ひらがな - Hiragana":"おぼえる","Romaji (English Letters)":"oboeru","audio":"oboeru","English Meaning":"to remember"}
]
const ichidanverbs3 = [
  {"漢字 - Kanji":"する","ひらがな - Hiragana":"する","Romaji (English Letters)":"suru  (to do)","audio":"suru  (to do)","Verb Base":""},
 {"漢字 - Kanji":"し","ひらがな - Hiragana":"し","Romaji (English Letters)":"shi","audio":"shi","Verb Base":"Base 1"},
 {"漢字 - Kanji":"し","ひらがな - Hiragana":"し","Romaji (English Letters)":"shi","audio":"shi","Verb Base":"Base 2"},
 {"漢字 - Kanji":"する","ひらがな - Hiragana":"する","Romaji (English Letters)":"suru","audio":"suru","Verb Base":"Base 3"},
 {"漢字 - Kanji":"すれ","ひらがな - Hiragana":"すれ","Romaji (English Letters)":"sure","audio":"sure","Verb Base":"Base 4"},
 {"漢字 - Kanji":"しよう","ひらがな - Hiragana":"しよう","Romaji (English Letters)":"shiyou","audio":"shiyou","Verb Base":"Base 5"},
 {"漢字 - Kanji":"して","ひらがな - Hiragana":"して","Romaji (English Letters)":"shite","audio":"shite","Verb Base":"Base \"te\""},
 {"漢字 - Kanji":"した","ひらがな - Hiragana":"した","Romaji (English Letters)":"shita","audio":"shita","Verb Base":"Base \"ta\""}
]
const ichidanverbs4 = [
  {"漢字 - Kanji":"来る","ひらがな - Hiragana":"くる","Romaji (English Letters)":"kuru  (to come)","audio":"kuru  (to come)","Verb Base":""},
 {"漢字 - Kanji":"来","ひらがな - Hiragana":"こ","Romaji (English Letters)":"ko","audio":"ko","Verb Base":"Base 1"},
 {"漢字 - Kanji":"来","ひらがな - Hiragana":"き","Romaji (English Letters)":"ki","audio":"ki","Verb Base":"Base 2"},
 {"漢字 - Kanji":"来る","ひらがな - Hiragana":"くる","Romaji (English Letters)":"kuru","audio":"kuru","Verb Base":"Base 3"},
 {"漢字 - Kanji":"来れ","ひらがな - Hiragana":"くれ","Romaji (English Letters)":"kure","audio":"kure","Verb Base":"Base 4"},
 {"漢字 - Kanji":"来よう","ひらがな - Hiragana":"こよう","Romaji (English Letters)":"koyou","audio":"koyou","Verb Base":"Base 5"},
 {"漢字 - Kanji":"来て","ひらがな - Hiragana":"きて","Romaji (English Letters)":"kite","audio":"kite","Verb Base":"Base \"te\""},
 {"漢字 - Kanji":"来た","ひらがな - Hiragana":"きた","Romaji (English Letters)":"kita","audio":"kita","Verb Base":"Base \"ta\""}
]

const IchidanVerbs = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Beginner" category="Grammar" lesson={title} />
     <div className={'title'}>
     Verb Conjugation
      </div>
      <h1>{title}</h1>

<p>The second group of Japanese verbs are called <Play audio="ichidan" /> (一段)  verbs. It is usually an Ichidan verb if it ends with the sound <Play audio="iru" />  or <Play audio="eru" /> . Some well used Japanese verbs which appear to be Ichidan but are really Godan are listed here. Ichidan verbs also follow a simple conjugation pattern that is somewhat similar to that of the Godan verbs. Below is a verb conjugation chart for Japanese Ichidan verbs:</p>

<Table data={ichidanverbs1} />

<p><strong>Base 1 and Base 2:</strong> As you can see, Base 1 and 2 are the same. Just like with the Godan verbs, a negative can be made by adding <Play audio="nai" />  (ex. <Play audio="tabenai" />  - I will not eat.) and this base, or root form, is also used for the formal form of the verb.</p>

<p><strong>Base 3:</strong> Base 3 is the same as with Godan verbs.</p>

<p><strong>Base 4:</strong> Base 4, however, can not be used by itself like with Godan verbs. It is only used for "if verb" by adding -ba. (ex. <Play audio="tabereba" />  - If I eat it...) It is not the low command form for Ichidan verbs. The low command form for Ichidan verbs is Base 1 + ろ <Play audio="ro" /> .</p>

<p><strong>Base 5:</strong> Base 5 is the same as with Godan verbs.</p>

<p><strong>Base "te" and Base "ta":</strong> The Base "te" and "ta" forms for Ichidan verbs are a lot easier than with Godan verbs. Simply take off the "ru" and add a "te" for Base "te" and a "ta" for Base "ta". These have the same functions as with Godan verbs.</p>

<p>Here are some Ichidan verbs. Try conjugating them on a piece of paper using what you have just learned. Click here for the answers.</p>

<Table data={ichidanverbs2} />

<h2>Irregular Verbs</h2>

<p>The last type of verbs are the Irregular verbs but there are only 2 of them in the entire language so just memorize their charts below. The first one is <Play audio="suru" /> (to do something). Many nouns can be used as a verb simply by putting "suru" right after them. It is probably the most used of all Japanese verbs. The second Irregular verb is <Play audio="kuru" /> (to come).</p>

<Table data={ichidanverbs3} />

<p>The functions for these bases are the same as for the Godan verbs except that Base 4 can only be used as the "if verb".
The rude command form is <Play audio="shiro" />.</p>

<Table data={ichidanverbs4} />

<p>The functions for these bases are the same as for the Godan verbs except that Base 4 can only be used as the "if verb". The rude command form is <Play audio="koi" /> and really should only be used on animals.</p>

    </>
  )
}

export default IchidanVerbs