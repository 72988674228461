import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Grade 6 Kanji - Part 1 of 2"

const GradeSixOne = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Advanced" category="Kanji" lesson={title} />

      <h1>{title}</h1>

      <p>Below are Kanji the Japanese learn in grade 6 of elementary school.
Be sure to read the <Link to="/members/a/kanji/intro/">Introduction to Kanji</Link> if you haven't already.</p>

<p>Legend:</p>
<p>[n] = Noun</p>
<p>[dv] = Descriptive word (true adjective) - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[dn] = Descriptive noun - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[gv] = Godan verb - see <Link to="/members/b/grammar/godan-verbs/">Verb Conjugation (Godan)</Link></p>
<p>[iv] = Ichidan verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[irv] = Irregular verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[c] = Counter</p>

  <table>
  <thead>
  <tr>
<th>漢字<br /><Play audio="kanji" /></th>
<th>音読み <Play audio="onyomi" /><br />"On" Reading</th>
<th>訓読み <Play audio="kunyomi" /><br />"Kun" Reading</th>
<th>English Meaning</th>
</tr>
  </thead>
<tbody>
<tr>
<th><span style={{fontSize:`4rem`}}>並</span></th>
<td>ヘイ (hei)</td>
<td>なみ (nami)<br />なら・ぶ (narabu)<br />なら・べる (naraberu)<br />なら・びに (narabini)</td>
<td>
Row
<br />
<table><tbody><tr><td>Examples:
<br />
並列 (へいれつ - heiretsu - arrangement, parallel, abreast) [n] <Play audio="heiretsu" />
<br />
並 (なみ - nami - average, medium, common, ordinary, line, row of (e.g. houses), mid-grade, same level, equal, each (e.g. month), set of (e.g. teeth)) [n] <Play audio="nami" />
<br />
並ぶ (ならぶ - narabu - to line up, to stand in a line, to rival, to match, to equal) [gv] <Play audio="narabu" />
<br />
並べる (ならべる - naraberu - to line up, to set up, to enumerate, to itemize) [iv] <Play audio="naraberu" />
<br />
並びに (ならびに - narabini - and (also), both ... and, as well as) <Play audio="narabini" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>乱</span></th>
<td>ラン (ran)</td>
<td>みだ・す (midasu)<br />みだ・る (midaru - rarely used)<br />みだ・れる (midareru)</td>
<td>
Riot
<br />
<table><tbody><tr><td>Examples:
<br />
混乱 (こんらん - konran - disorder, chaos, confusion, mayhem) [n] <Play audio="konran" />
<br />
乱す (みだす - midasu - to throw out of order, to disarrange, to disturb) [gv] <Play audio="midasu" />
<br />
乱れる (みだれる - midareru - to be disordered, to be disarranged, to be disarrayed, to be disheveled, to be dishevelled, to be discomposed, to be upset, to get confused, to be disturbed, to lapse into chaos (due to war, etc.)) [iv] <Play audio="midareru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>乳</span></th>
<td>ニュウ (nyuu)</td>
<td>ちち (chichi)<br />ち (chi)</td>
<td>
Milk
<br />
<table><tbody><tr><td>Examples:
<br />
牛乳 (ぎゅうにゅう - gyuunyuu - (cow's) milk) [n] <Play audio="gyuunyuu" />
<br />
乳 (ちち - chichi - milk, breast) [n] <Play audio="chichi" />
<br />
乳 (ち - chi - loop, decorative bump (on a hanging bell)) [n] <Play audio="chi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>亡</span></th>
<td>モウ (mou)<br />ボウ (bou)</td>
<td>な・い (nai)<br />な・くす (nakusu)<br />な・くする (nakusuru - rarely used)<br />な・くなる (nakunaru)</td>
<td>
Deceased
<br />
<table><tbody><tr><td>Examples:
<br />
亡者 (もうしゃ - mousha - blind person) [n] <Play audio="mousha" />
<br />
死亡 (しぼう - shibou - death, mortality) [n] <Play audio="shibou" />
<br />
亡い (ない - nai - dead) [dv] <Play audio="nai" />
<br />
亡くす (なくす - nakusu - to lose someone (wife, child, etc.)) [gv] <Play audio="nakusu" />
<br />
亡くなる (なくなる - nakunaru - to die) [gv] <Play audio="nakunaru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>仁</span></th>
<td>ジン (jin)</td>
<td></td>
<td>
Kindness
<br />
<table><tbody><tr><td>Examples:
<br />
仁徳 (じんとく - jintoku - benevolence, goodness) [n] <Play audio="jintoku" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>供</span></th>
<td>ク (ku)<br />キョウ (kyou)</td>
<td>そな・える (sonaeru)<br />とも (tomo)</td>
<td>
Offer
<br />
<table><tbody><tr><td>Examples:
<br />
供養 (くよう - kuyou - memorial service for the dead, holding a service) [n] <Play audio="kuyou" />
<br />
試供品 (しきょうひん - shikyouhin - specimen, sample) [n] <Play audio="shikyouhin" />
<br />
供える (そなえる - sonaeru - to offer, to sacrifice, to dedicate) [iv] <Play audio="sonaeru" />
<br />
供 (とも - tomo - companion, follower, attendant, retinue) [n] <Play audio="tomo" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>俳</span></th>
<td>ハイ (hai)</td>
<td></td>
<td>
Actor
<br />
<table><tbody><tr><td>Examples:
<br />
俳優 (はいゆう - haiyuu - actor, actress, player, performer) [n] <Play audio="haiyuu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>値</span></th>
<td>チ (chi)</td>
<td>ね (ne)<br />あたい (atai)</td>
<td>
Value
<br />
<table><tbody><tr><td>Examples:
<br />
価値 (かち - kachi - value, worth, merit) [n] <Play audio="kachi" />
<br />
値 (ね - ne - price, cost, value, worth, merit) [n] <Play audio="ne" />
<br />
値段 (ねだん - nedan - price, cost) [n] <Play audio="nedan" />
<br />
値 (あたい - atai - price, cost, value, worth, merit, count, number, variable (computer programming)) [n] <Play audio="atai" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>傷</span></th>
<td>ショウ (shou)</td>
<td>きず (kizu)<br />いた・む (itamu)<br />いた・み (itami)<br />いた・める (itameru)<br />いた・ましい (itamashii)</td>
<td>
Wound
<br />
<table><tbody><tr><td>Examples:
<br />
傷害 (しょうがい - shougai - wound, injury, accident, casualty, assault) [n] <Play audio="shougai" />
<br />
傷 (きず - kizu - wound, injury, cut, gash, bruise, scratch, scrape, scar, chip, crack, scratch, nick) [n] <Play audio="kizu" />
<br />
傷む (いたむ - itamu - to be injured, to be spoiled (e.g. food)) [gv] <Play audio="itamu" />
<br />
傷み (いたみ - itami - grief, distress, damage, bruise) [n] <Play audio="itami" />
<br />
傷める (いためる - itameru - to damage, to impair, to spoil) [iv] <Play audio="itameru" />
<br />
傷ましい (いたましい - itamashii - pitiful, heartbreaking, tragical) [dv] <Play audio="itamashii" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>優</span></th>
<td>ユウ (yuu)</td>
<td>やさ・しい (yasashii)<br />すぐ・れる (sugureru)</td>
<td>
Superior
<br />
<table><tbody><tr><td>Examples:
<br />
優先 (ゆうせん - yuusen - preference, priority, precedence) [n] <Play audio="yuusen" />
<br />
優しい (やさしい - yasashii - tender, kind, gentle, graceful, affectionate, amiable) [dv] <Play audio="yasashii" />
<br />
優れる (すぐれる - sugureru - to surpass, to outstrip, to excel) [iv] <Play audio="sugureru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>党</span></th>
<td>トウ (tou)</td>
<td></td>
<td>
Political Party
<br />
<table><tbody><tr><td>Examples:
<br />
野党 (やとう - yatou - opposition party, political opposition, opposition) [n] <Play audio="yatou" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>冊</span></th>
<td>サク (saku - rarely used)<br />サツ (satsu)</td>
<td></td>
<td>
Counter for Books
<br />
<table><tbody><tr><td>Examples:
<br />
合冊 (がっさつ - gassatsu - collection in one volume) [n] <Play audio="gassatsu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>処</span></th>
<td>ショ (sho)</td>
<td></td>
<td>
Dispose
<br />
<table><tbody><tr><td>Examples:
<br />
対処 (たいしょ - taisho - deal with, cope) [n] <Play audio="taisho" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>刻</span></th>
<td>コク (koku)</td>
<td>きざ・む (kizamu)</td>
<td>
Engrave
<br />
<table><tbody><tr><td>Examples:
<br />
時刻 (じこく - jikoku - instant, time, moment) [n] <Play audio="jikoku" />
<br />
刻む (きざむ - kizamu - to mince, to cut fine, to chop up, to hash, to shred, to carve, to engrave, to chisel, to notch, to tick away (time), to record the passing moments) [gv] <Play audio="kizamu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>割</span></th>
<td>カツ (katsu)</td>
<td>わ・る (waru)<br />わり (wari)<br />わ・れる (wareru)<br />さ・く (saku)</td>
<td>
Divide
<br />
<table><tbody><tr><td>Examples:
<br />
分割 (ぶんかつ - bunkatsu - partition, division, separation, segmenting, splitting) [n] <Play audio="bunkatsu" />
<br />
割る (わる - waru - to divide, to cut, to break, to halve, to separate, to split, to rip, to crack, to smash, to dilute) [gv] <Play audio="waru" />
<br />
割 (わり - wari - rate, ratio, proportion, percentage, profit, assignment, 10%, unit of ten percent) [n] <Play audio="wari" />
<br />
割れる (われる - wareru - to break, to be smashed, to split, to crack, to fissure, to be torn, to be divided, to cleave, to come to light, to be identified, to be established, to become clear) [iv] <Play audio="wareru" />
<br />
割く (さく - saku - to spare (time, money, etc.), to use part of something) [gv] <Play audio="saku" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>創</span></th>
<td>ソウ (sou)</td>
<td>つく・る (tsukuru)</td>
<td>
Create
<br />
<table><tbody><tr><td>Examples:
<br />
創業 (そうぎょう - sougyou - establishment (of a business, etc.)) [n] <Play audio="sougyou" />
<br />
創る (つくる - tsukuru - (usu. for creating) to make, to produce, to manufacture, to build, to construct) [gv] <Play audio="tsukuru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>劇</span></th>
<td>ゲキ (geki)</td>
<td></td>
<td>
Drama
<br />
<table><tbody><tr><td>Examples:
<br />
劇場 (げきじょう - gekijou - theater, playhouse) [n] <Play audio="gekijou" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>勤</span></th>
<td>ゴン (gon)<br />キン (kin)</td>
<td>つと・める (tsutomeru)<br />つと・め (tsutome)<br />つと・まる (tsutomaru)</td>
<td>
Diligence
<br />
<table><tbody><tr><td>Examples:
<br />
勤行 (ごんぎょう - gongyou - religious service (usu. Buddhist)) [n] <Play audio="gongyou" />
<br />
勤務 (きんむ - kinmu - service, duty, work) [n] <Play audio="kinmu" />
<br />
勤める (つとめる - tsutomeru - to work (for), to be employed (at), to serve (in), to conduct a religious service) [iv] <Play audio="tsutomeru" />
<br />
勤め (つとめ - tsutome - service, duty, business, responsibility, task, Buddhist religious services) [n] <Play audio="tsutome" />
<br />
勤まる (つとまる - tsutomaru - to be fit for, to be equal to, to function properly) [gv] <Play audio="tsutomaru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>危</span></th>
<td>キ (ki)</td>
<td>あぶ・ない (abunai)<br />あや・うい (ayaui)<br />あや・ぶむ (ayabumu)</td>
<td>
Dangerous
<br />
<table><tbody><tr><td>Examples:
<br />
危険 (きけん - kiken - danger, peril, hazard, dangerous, hazardous) [dn] <Play audio="kiken" />
<br />
危ない (あぶない - abunai - dangerous, risky, hazardous, perilous, precarious, in danger, in jeopardy, critical, grave, at risk, uncertain, unreliable, insecure, unsteady, doubtful, close (i.e. a close call), narrow, look out!, watch out!) [dv] <Play audio="abunai" />
<br />
危うい (あやうい - ayaui - dangerous, risky, hazardous, perilous, precarious, in danger, in jeopardy, critical, grave, at risk, uncertain, unreliable, insecure, unsteady, doubtful, close (i.e. a close call), narrow) [dv] <Play audio="ayaui" />
<br />
危ぶむ (あやぶむ - ayabumu - to fear, to have misgivings, to be doubtful, to mistrust) [gv] <Play audio="ayabumu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>卵</span></th>
<td>ラン (ran)</td>
<td>たまご (tamago)</td>
<td>
Egg
<br />
<table><tbody><tr><td>Examples:
<br />
鶏卵 (けいらん - keiran - hens egg) [n] <Play audio="keiran" />
<br />
卵 (たまご - tamago - eggs, egg, spawn, roe, (an expert) in the making, beginning, origin, infancy) [n] <Play audio="tamago" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>厳</span></th>
<td>ゴン (gon)<br />ゲン (gen)</td>
<td>おごそ・か (ogosoka)<br />きび・しい (kibishii)</td>
<td>
Strict
<br />
<table><tbody><tr><td>Examples:
<br />
荘厳 (そうごん - sougon - solemnity, gravity, impressiveness) [dn] <Play audio="sougon" />
<br />
厳格 (げんかく - genkaku - severe, rigid, stern, strictness, rigor, austerity) [dn] <Play audio="genkaku" />
<br />
厳か (おごそか - ogosoka - solemn (e.g. ceremony, atmosphere), austere, majestic, dignified, stately, awful, impressive) [dn] <Play audio="ogosoka" />
<br />
厳しい (きびしい - kibishii - severe, strict, rigid, unsparing, relentless, stern, austere, grave, solemn, majestic, intense (cold)) [dv] <Play audio="kibishii" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>収</span></th>
<td>シュウ (shuu)</td>
<td>おさ・める (osameru)<br />おさ・まる (osamaru)</td>
<td>
Take In
<br />
<table><tbody><tr><td>Examples:
<br />
収穫 (しゅうかく - shuukaku - harvest, crop, ingathering) [n] <Play audio="shuukaku" />
<br />
収める (おさめる - osameru - to dedicate, to make an offering, to pay (fees), to supply, to store, to finish, to bring to a close, to restore (something to its place), to achieve (e.g. a result)) [iv] <Play audio="osameru" />
<br />
収まる (おさまる - osamaru - to be in one's place, to be installed, to settle into, to be fit tightly into (e.g. a frame), to be sheathed (in a scabbard)) [gv] <Play audio="osamaru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>后</span></th>
<td>ゴウ (gou)<br />ゴ (go - rarely used)</td>
<td>きさき (kisaki)</td>
<td>
Queen
<br />
<table><tbody><tr><td>Examples:
<br />
皇后 (こうごう - kougou - (Japanese) empress, queen) [n] <Play audio="kougou" />
<br />
后 (きさき - kisaki - empress, queen) [n] <Play audio="kisaki" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>否</span></th>
<td>ヒ (hi)</td>
<td>いな (ina)<br />いな・む (inamu)<br />いや (iya)</td>
<td>
Negate
<br />
<table><tbody><tr><td>Examples:
<br />
拒否 (きょひ - kyohi - denial, veto, rejection, refusal) [n] <Play audio="kyohi" />
<br />
否 (いな - ina - no, nay, well, er, why) <Play audio="ina" />
<br />
否む (いなむ - inamu - to refuse, to decline, to deny) [gv] <Play audio="inamu" />
<br />
否 (いや - iya - no, nay, well, er, why) <Play audio="iya" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>吸</span></th>
<td>キュウ (kyuu)</td>
<td>す・う (suu)</td>
<td>
Suck
<br />
<table><tbody><tr><td>Examples:
<br />
吸収 (きゅうしゅう - kyuushuu - absorption, suction, attraction) [n] <Play audio="kyuushuu" />
<br />
吸う (すう - suu - to smoke, to breathe in, to inhale, to suck, to sip, to slurp, to absorb, to soak up) [gv] <Play audio="suu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>呼</span></th>
<td>コ (ko)</td>
<td>よ・ぶ (yobu)<br />よ・ばわる (yobawaru)</td>
<td>
Call
<br />
<table><tbody><tr><td>Examples:
<br />
呼吸 (こきゅう - kokyuu - breath, respiration, knack, trick, secret (of doing something)) [n] <Play audio="kokyuu" />
<br />
呼ぶ (よぶ - yobu - to call out (to), to call, to invoke, to summon (a doctor, etc.), to invite, to designate, to name, to brand, to garner (support, etc.), to gather) [gv] <Play audio="yobu" />
<br />
呼ばわる (よばわる - yobawaru - to call out, to shout) [gv] <Play audio="yobawaru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>善</span></th>
<td>ゼン (zen)</td>
<td>よ・い (yoi)<br />よ・し (yoshi)</td>
<td>
Good
<br />
<table><tbody><tr><td>Examples:
<br />
善悪 (ぜんあく - zen'aku - good and evil) [n] <Play audio="zen-aku" />
<br />
善い (よい - yoi - good, excellent, fine, nice, pleasant, agreeable) [dv] <Play audio="yoi" />
<br />
善し (よし - yoshi - good, excellent, fine, nice, pleasant, agreeable) <Play audio="yosh" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>困</span></th>
<td>コン (kon)</td>
<td>こま・る (komaru)</td>
<td>
Become Distressed
<br />
<table><tbody><tr><td>Examples:
<br />
困窮 (こんきゅう - konkyuu - poverty, distress) [n] <Play audio="konkyuu" />
<br />
困る (こまる - komaru - to be troubled, to be worried, to be bothered, to be embarrassed, to be stumped) [gv] <Play audio="komaru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>垂</span></th>
<td>スイ (sui)</td>
<td>た・れる (tareru)</td>
<td>
Droop
<br />
<table><tbody><tr><td>Examples:
<br />
垂直 (すいちょく - suichoku - vertical, perpendicular) [dn] <Play audio="suichoku" />
<br />
垂れる (たれる - tareru - to hang, to droop, to dangle, to sag, to lower, to pull down, to leave behind (at death), to give, to confer, to drip, to ooze, to trickle, to drop) [iv] <Play audio="tareru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>城</span></th>
<td>ジョウ (jou)</td>
<td>しろ (shiro)</td>
<td>
Castle
<br />
<table><tbody><tr><td>Examples:
<br />
王城 (おうじょう - oujou - royal castle) [n] <Play audio="oujou" />
<br />
城 (しろ - shiro - castle) [n] <Play audio="shiro" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>域</span></th>
<td>イキ (iki)</td>
<td></td>
<td>
Range
<br />
<table><tbody><tr><td>Examples:
<br />
区域 (くいき - kuiki - limits, boundary, domain, zone, sphere, territory) [n] <Play audio="kuiki" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>奏</span></th>
<td>ソウ (sou)</td>
<td>かな・でる (kanaderu)</td>
<td>
Play Music
<br />
<table><tbody><tr><td>Examples:
<br />
演奏 (えんそう - ensou - musical performance) [n] <Play audio="ensou" />
<br />
奏でる (かなでる - kanaderu - to play an instrument (esp. string instruments)) [iv] <Play audio="kanaderu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>奮</span></th>
<td>フン (fun)</td>
<td>ふる・う (furuu)</td>
<td>
Stirred Up
<br />
<table><tbody><tr><td>Examples:
<br />
奮闘 (ふんとう - funtou - hard struggle, strenuous effort) [n] <Play audio="funtou" />
<br />
奮う (ふるう - furuu - to muster (e.g. one's courage), to call forth, to rouse up, to be enlivened, to be invigorated) [gv] <Play audio="furuu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>姿</span></th>
<td>シ (shi)</td>
<td>すがた (sugata)</td>
<td>
Shape
<br />
<table><tbody><tr><td>Examples:
<br />
姿勢 (しせい - shisei - attitude, posture, stance, approach, carriage (of the body)) [n] <Play audio="shisei" />
<br />
姿 (すがた - sugata - figure, form, shape, appearance, dress, guise, state, condition, picture, image) [n] <Play audio="sugata" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>存</span></th>
<td>ゾン (zon)<br />ソン (son)</td>
<td></td>
<td>
Suppose
<br />
<table><tbody><tr><td>Examples:
<br />
依存 (いぞん - izon - dependence, dependent, reliance) [n] <Play audio="izon" />
<br />
存在 (そんざい - sonzai - existence, being) [n] <Play audio="sonzai" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>孝</span></th>
<td>コウ (kou)</td>
<td></td>
<td>
Filial Piety
<br />
<table><tbody><tr><td>Examples:
<br />
親孝行 (おやこうこう - oyakoukou - filial piety) [dn] <Play audio="oyakoukou" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>宅</span></th>
<td>タク (taku)</td>
<td>いえ (ie - rarely used)</td>
<td>
Home
<br />
<table><tbody><tr><td>Examples:
<br />
宅配 (たくはい - takuhai - home delivery) [n] <Play audio="takuhai" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>宇</span></th>
<td>ウ (u)</td>
<td></td>
<td>
Eaves
<br />
<table><tbody><tr><td>Examples:
<br />
宇宙 (うちゅう - uchuu - universe, cosmos, space) [n] <Play audio="uchuu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>宗</span></th>
<td>シュウ (shuu)<br />ソウ (sou)</td>
<td></td>
<td>
Religion
<br />
<table><tbody><tr><td>Examples:
<br />
宗教 (しゅうきょう - shuukyou - religion, religious affiliation, belief, faith, creed, religious activity) [n] <Play audio="shuukyou" />
<br />
宗匠 (そうしょう - soushou - master, teacher) [n] <Play audio="soushou" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>宙</span></th>
<td>チュウ (chuu)</td>
<td></td>
<td>
Mid-air
<br />
<table><tbody><tr><td>Examples:
<br />
宇宙 (うちゅう - uchuu - universe, cosmos, space) [n] <Play audio="uchuu" />
</td></tr></tbody></table></td>
</tr>
</tbody></table>

    </>
  )
}

export default GradeSixOne