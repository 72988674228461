import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Grade 3 Kanji - Part 3 of 5"

const GradeThreeThree = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Advanced" category="Kanji" lesson={title} />

      <h1>{title}</h1>

      <p>Below are Kanji the Japanese learn in grade 3 of elementary school.
Be sure to read the <Link to="/members/a/kanji/intro/">Introduction to Kanji</Link> if you haven't already.</p>

<p>Legend:</p>
<p>[n] = Noun</p>
<p>[dv] = Descriptive word (true adjective) - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[dn] = Descriptive noun - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[gv] = Godan verb - see <Link to="/members/b/grammar/godan-verbs/">Verb Conjugation (Godan)</Link></p>
<p>[iv] = Ichidan verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[irv] = Irregular verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[c] = Counter</p>

<table>
<thead>
  <tr>
<th>漢字<br /><Play audio="kanji" /></th>
<th>音読み <Play audio="onyomi" /><br />"On" Reading</th>
<th>訓読み <Play audio="kunyomi" /><br />"Kun" Reading</th>
<th>English Meaning</th>
</tr>
  </thead>
<tbody>
<tr>
<th><span style={{fontSize:`4rem`}}>整</span></th>
<td>セイ (sei)</td>
<td>ととの・う (totonou)<br />ととの・える (totonoeru)</td>
<td>
<strong>Put In Order</strong>
<br />
<table><tbody><tr><td>Examples:
<br />
整然 (せいぜん - seizen - orderly, regular, systematic, well-organized, tidy, accurate)  <Play audio="seizen" />
<br />
整う (ととのう - totonou - to be prepared, to be in order, to be put in order, to be arranged) [gv] <Play audio="totonou" />
<br />
整える (ととのえる - totonoeru - to put in order, to arrange, to adjust, to get ready, to prepare) [iv] <Play audio="totonoeru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>旅</span></th>
<td>リョ (ryo)</td>
<td>たび (tabi)</td>
<td>
<strong>Trip</strong>
<br />
<table><tbody><tr><td>Examples:
<br />
旅行 (りょこう - ryokou - travel, trip) [n] <Play audio="ryokou" />
<br />
旅 (たび - tabi - travel, trip, journey) [n] <Play audio="tabi" />
<br />
旅路 (たびじ - tabiji - journey) [n] <Play audio="tabiji" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>族</span></th>
<td>ゾク (zoku)</td>
<td></td>
<td>
<strong>Tribe</strong>
<br />
<table><tbody><tr><td>Examples:
<br />
家族 (かぞく - kazoku - family, members of a family) [n] <Play audio="kazoku" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>昔</span></th>
<td>シャク (shaku)<br />セキ (seki)</td>
<td>むかし (mukashi)</td>
<td>
<strong>Long Ago</strong>
<br />
<table><tbody><tr><td>Examples:
<br />
今昔 (こんじゃく - konjaku - past and present) [n] <Play audio="konjaku" />
<br />
古昔 (こせき - koseki - ancient times) [n] <Play audio="koseki" />
<br />
昔 (むかし - mukashi - olden days, former) [n] <Play audio="mukashi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>昭</span></th>
<td>ショウ (shou)</td>
<td></td>
<td>
<strong>Clear</strong>
<br />
<table><tbody><tr><td>Examples:
<br />
昭然 (しょうぜん - shouzen - manifest, clear, evident, obvious)  <Play audio="shouzen" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>暑</span></th>
<td>ショ (sho)</td>
<td>あつ・い (atsui)</td>
<td>
<strong>Hot</strong>
<br />
<table><tbody><tr><td>Examples:
<br />
猛暑 (もうしょ - mousho - heat wave, fierce heat) [n] <Play audio="mousho" />
<br />
暑い (あつい - atsui - hot (weather, etc.), warm) [dv] <Play audio="atsui" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>暗</span></th>
<td>アン (an)</td>
<td>くら・い (kurai)<br />くら・がり (kuragari)<br />くら・ます (kuramasu)</td>
<td>
<strong>Dark</strong>
<br />
<table><tbody><tr><td>Examples:
<br />
暗黒 (あんこく - ankoku - darkness) [dn] <Play audio="ankoku" />
<br />
暗い (くらい - kurai - dark, gloomy) [dv] <Play audio="kurai" />
<br />
暗がり (くらがり - kuragari - darkness) [n] <Play audio="kuragari" />
<br />
暗ます (くらます - kuramasu - to abscond, to conceal oneself, to deceive, to dissemble, to fool) [gv] <Play audio="kuramasu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>曲</span></th>
<td>キョク (kyoku)</td>
<td>ま・がる (magaru)<br />ま・げる (mageru)</td>
<td>
<strong>Sheet Music, Crooked</strong>
<br />
<table><tbody><tr><td>Examples:
<br />
作曲 (さっきょく - sakkyoku - composition, setting (of music)) [n] <Play audio="sakkyoku" />
<br />
曲がる (まがる - magaru - to bend, to curve, to warp, to wind, to twist, to turn, to be awry, to be askew, to be crooked) [gv] <Play audio="magaru" />
<br />
曲げる (まげる - mageru - to bend, to crook, to lean, to yield (a point), to depart (from a principle)) [iv] <Play audio="mageru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>有</span></th>
<td>ウ (u)<br />ユウ (yuu)</td>
<td>あ・る (aru)</td>
<td>
<strong>Be</strong>
<br />
<table><tbody><tr><td>Examples:
<br />
有無 (うむ - umu - existence or nonexistence, presence or absence) [n] <Play audio="umu" />
<br />
有意義 (ゆういぎ - yuuigi - significant, useful, meaningful, worthwhile, valuable, of interest) [dn] <Play audio="yuuigi" />
<br />
有る (ある - aru - to be (usu. of inanimate objects), to exist - typically written in hiragana) [gv] <Play audio="aru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>服</span></th>
<td>フク (fuku)</td>
<td></td>
<td>
<strong>Clothes</strong>
<br />
<table><tbody><tr><td>Examples:
<br />
服 (ふく - fuku - clothes) [n] <Play audio="fuku" />
<br />
服装 (ふくそう - fukusou - garments, attire) [n] <Play audio="fukusou" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>期</span></th>
<td>ゴ (go)<br />キ (ki)</td>
<td></td>
<td>
<strong>Period of Time</strong>
<br />
<table><tbody><tr><td>Examples:
<br />
一期 (いちご - ichigo - one's life time, lifetime) [n] <Play audio="ichigo" />
<br />
画期的 (かっきてき - kakkiteki - ground-breaking, epoch-making) [dn] <Play audio="kakkiteki" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>板</span></th>
<td>ハン (han)<br />バン (ban)</td>
<td>いた (ita)</td>
<td>
<strong>Board</strong>
<br />
<table><tbody><tr><td>Examples:
<br />
合板 (ごうはん - gouhan - veneer board, plywood, joint publication) [n] <Play audio="gouhan" />
<br />
黒板 (こくばん - kokuban - blackboard) [n] <Play audio="kokuban" />
<br />
板 (いた - ita - board, plank, sheet (of metal), plate (of glass), pane, slab) [n] <Play audio="ita" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>柱</span></th>
<td>チュウ (chuu)</td>
<td>はしら (hashira)</td>
<td>
<strong>Pillar</strong>
<br />
<table><tbody><tr><td>Examples:
<br />
電柱 (でんちゅう - denchuu - telephone pole, telegraph pole, lightpole) [n] <Play audio="denchuu" />
<br />
柱 (はしら - hashira - pillar, post, support, prop, mainstay) [n] <Play audio="hashira" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>根</span></th>
<td>コン (kon)</td>
<td>ね (ne)</td>
<td>
<strong>Root</strong>
<br />
<table><tbody><tr><td>Examples:
<br />
根拠 (こんきょ - konkyo - basis, foundation) [n] <Play audio="konkyo" />
<br />
大根 (だいこん - daikon - daikon radish) [n] <Play audio="daikon" />
<br />
屋根 (やね - yane - roof) [n] <Play audio="yane" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>植</span></th>
<td>ショク (shoku)</td>
<td>う・える (ueru)<br />う・わる (uwaru)</td>
<td>
<strong>Plant</strong>
<br />
<table><tbody><tr><td>Examples:
<br />
植民地 (しょくみんち - shokuminchi - colony, colonial) [n] <Play audio="shokuminchi" />
<br />
植える (うえる - ueru - to plant, to grow) [iv] <Play audio="ueru" />
<br />
植わる (うわる - uwaru - to be planted) [gv] <Play audio="uwaru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>業</span></th>
<td>ゴウ (gou)<br />ギョウ (gyou)</td>
<td>わざ (waza)</td>
<td>
<strong>Business</strong>
<br />
<table><tbody><tr><td>Examples:
<br />
自業自得 (じごうじとく - jigoujitoku - paying for one's mistakes, getting one's just deserts, suffering the consequences (of one's own actions), reap what you sow) [dn] <Play audio="jigoujitoku" />
<br />
職業 (しょくぎょう - shokugyou - occupation, business) [n] <Play audio="shokugyou" />
<br />
仕業 (しわざ - shiwaza - act, action, deed) [n] <Play audio="shiwaza" />
<br />
神業 (かみわざ - kamiwaza - divine work, miracle, superhuman feat) [n] <Play audio="kamiwaza" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>様</span></th>
<td>ヨウ (you)</td>
<td>さま (sama)</td>
<td>
<strong>Appearance or Mr (Mrs, Ms)</strong>
<br />
<table><tbody><tr><td>Examples:
<br />
様子 (ようす - yousu - state, state of affairs, situation, circumstances, appearance, look, aspect, sign, indication) [n] <Play audio="yousu" />
<br />
逆様 (さかさま - sakasama - inversion, upside down) [dn] <Play audio="sakasama" />
<br />
____様 (____さま - ____sama - Mr (Mrs, Ms) ____ (name suffix - honorific form)) <Play audio="sama" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>横</span></th>
<td>オウ (ou)</td>
<td>よこ (yoko)</td>
<td>
<strong>Side</strong>
<br />
<table><tbody><tr><td>Examples:
<br />
横断 (おうだん - oudan - crossing, transverse) [n] <Play audio="oudan" />
<br />
横 (よこ - yoko - side (of an object), beside, aside, next to) [n] <Play audio="yoko" />
<br />
横になる (よこになる - yoko ni naru - to lie down (and rest)) [gv] <Play audio="yoko ni naru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>橋</span></th>
<td>キョウ (kyou)</td>
<td>はし (hashi)</td>
<td>
<strong>Bridge</strong>
<br />
<table><tbody><tr><td>Examples:
<br />
歩道橋 (ほどうきょう - hodoukyou - pedestrian bridge) [n] <Play audio="hodoukyou" />
<br />
橋 (はし - hashi - bridge) [n] <Play audio="hashi-bridge" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>次</span></th>
<td>シ (shi)<br />ジ (ji)</td>
<td>つ・ぐ (tsugu)<br />つぎ (tsugi)</td>
<td>
<strong>Next</strong>
<br />
<table><tbody><tr><td>Examples:
<br />
次第 (しだい - shidai - dependent upon, as soon as, immediately (upon), circumstances, order, precedence, program, agenda) [n] <Play audio="shidai" />
<br />
目次 (もくじ - mokuji - table of contents) [n] <Play audio="mokuji" />
<br />
次ぐ (つぐ - tsugu - to rank next to, to come after) [gv] <Play audio="tsugu" />
<br />
次 (つぎ - tsugi - next, following, subsequent) [n] <Play audio="tsugi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>歯</span></th>
<td>シ (shi)</td>
<td>は (ha)</td>
<td>
<strong>Tooth</strong>
<br />
<table><tbody><tr><td>Examples:
<br />
歯科 (しか - shika - dentistry) [n] <Play audio="shika" />
<br />
歯 (は - ha - tooth) <Play audio="ha" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>死</span></th>
<td>シ (shi)</td>
<td>し・ぬ (shinu)</td>
<td>
<strong>Death</strong>
<br />
<table><tbody><tr><td>Examples:
<br />
必死 (ひっし - hisshi - frantic, desperate, inevitable death) [dn] <Play audio="hisshi" />
<br />
死ぬ (しぬ - shinu - to die) [gv] <Play audio="shinu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>氷</span></th>
<td>ヒョウ (hyou)</td>
<td>こおり (koori)<br />ひ (hi)</td>
<td>
<strong>Ice</strong>
<br />
<table><tbody><tr><td>Examples:
<br />
氷河 (ひょうが - hyouga - glacier) [n] <Play audio="hyouga" />
<br />
氷 (こおり - koori - ice) [n] <Play audio="koori" />
<br />
氷雨 (ひさめ - hisame - hail, really cold rain) [n] <Play audio="hisame" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>決</span></th>
<td>ケツ (ketsu)</td>
<td>き・める (kimeru)<br />き・め (kime)<br />き・まる (kimaru)<br />き・まり (kimari)</td>
<td>
<strong>Decide</strong>
<br />
<table><tbody><tr><td>Examples:
<br />
解決 (かいけつ - kaiketsu - settlement, solution, resolution) [n] <Play audio="kaiketsu" />
<br />
決める (きめる - kimeru - to decide) [iv] <Play audio="kimeru" />
<br />
決め (きめ - kime - agreement, rule) [n] <Play audio="kime" />
<br />
決まる (きまる - kimaru - to be decided, to be settled) [gv] <Play audio="kimaru" />
<br />
決まり (きまり - kimari - rule, regulation, settlement, conclusion, end, agreement, arrangement) [n] <Play audio="kimari" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>油</span></th>
<td>ユ (yu)</td>
<td>あぶら (abura)</td>
<td>
<strong>Oil</strong>
<br />
<table><tbody><tr><td>Examples:
<br />
油断 (ゆだん - yudan - negligence, unpreparedness) [n] <Play audio="yudan" />
<br />
油 (あぶら - abura - oil) [n] <Play audio="abura" />
<br />
胡麻油 (ごまあぶら - gomaabura - sesame oil) [n] <Play audio="gomaabura" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>波</span></th>
<td>ハ (ha)</td>
<td>なみ (nami)</td>
<td>
<strong>Wave</strong>
<br />
<table><tbody><tr><td>Examples:
<br />
防波堤　(ぼうはてい - bouhatei - breakwater, mole) [n] <Play audio="bouhatei" />
<br />
波　(なみ - nami - wave) [n] <Play audio="nami" />
<br />
津波　(つなみ - tsunami - tidal wave) [n] <Play audio="tsunami" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>注</span></th>
<td>チュウ (chuu)</td>
<td>そそ・ぐ　(sosogu)</td>
<td>
<strong>Pour</strong>
<br />
<table><tbody><tr><td>Examples:
<br />
注射 (ちゅうしゃ - chuusha - injection) [n] <Play audio="chuusha" />
<br />
注ぐ (そそぐ - sosogu - to pour (into), to fill) [gv] <Play audio="sosogu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>泳</span></th>
<td>エイ (ei)</td>
<td>およ・ぐ (oyogu)<br />およ・ぎ (oyogi)</td>
<td>
<strong>Swim</strong>
<br />
<table><tbody><tr><td>Examples:
<br />
水泳 (すいえい - suiei - swimming) [n] <Play audio="suiei" />
<br />
泳ぐ (およぐ - oyogu - to swim) [gv] <Play audio="oyogu" />
<br />
泳ぎ (およぎ - oyogi - swimming) [n] <Play audio="oyogi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>洋</span></th>
<td>ヨウ (you)</td>
<td></td>
<td>
<strong>Ocean, Western</strong>
<br />
<table><tbody><tr><td>Examples:
<br />
洋画 (ようが - youga - Western paintings, Western films, Western movies) [n] <Play audio="youga" />
<br />
洋服 (ようふく - youfuku - Western-style clothes) [n] <Play audio="youfuku" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>流</span></th>
<td>ル (ru)<br />リュウ (ryuu)</td>
<td>なが・れる (nagareru)<br />なが・れ (nagare)<br />なが・す (nagasu)<br />なが・し (nagashi)</td>
<td>
<strong>Stream</strong>
<br />
<table><tbody><tr><td>Examples:
<br />
流浪 (るろう - rurou - vagrancy, wandering, nomadism) [n] <Play audio="rurou" />
<br />
逆流 (ぎゃくりゅう - gyakuryuu - counter-current, adverse tide, regurgitation) [n] <Play audio="gyakuryuu" />
<br />
流れる (ながれる - nagareru - to stream, to flow (liquid, time, etc.), to run (ink), to be washed away) [iv] <Play audio="nagareru" />
<br />
流れ (ながれ - nagare - stream, current, flow) [n] <Play audio="nagare" />
<br />
流す (ながす - nagasu - to drain, to pour, to spill, to shed (blood, tears), to wash away, to distribute (e.g. electricity over wires, music over a PA system, etc.), to circulate, to broadcast, to beam) [gv] <Play audio="nagasu" />
<br />
流し (ながし - nagashi - sink) [n] <Play audio="nagashi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>消</span></th>
<td>ショウ (shou)</td>
<td>き・える (kieru)<br />け・す (kesu)</td>
<td>
<strong>Extinguish</strong>
<br />
<table><tbody><tr><td>Examples:
<br />
消毒 (しょうどく - shoudoku - disinfection, sterilization) [n] <Play audio="shoudoku" />
<br />
消える (きえる - kieru - to go out, to vanish, to disappear) [iv] <Play audio="kieru" />
<br />
消す (けす - kesu - to erase, to delete, to cross out, to turn off power, to extinguish, to put out) [gv] <Play audio="kesu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>深</span></th>
<td>シン (shin)</td>
<td>ふか・い (fukai)<br />ふか・まる (fukamaru)<br />ふか・める (fukameru)</td>
<td>
<strong>Deep</strong>
<br />
<table><tbody><tr><td>Examples:
<br />
深呼吸 (しんこきゅう - shinkokyuu - deep breath) [n] <Play audio="shinkokyuu" />
<br />
深い (ふかい - fukai - deep, profound) [dv] <Play audio="fukai" />
<br />
深まる (ふかまる - fukamaru - to be deepened, to be heightened, to be intensified) [gv] <Play audio="fukamaru" />
<br />
深める (ふかめる - fukameru - to deepen, to heighten, to intensify) [iv] <Play audio="fukameru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>温</span></th>
<td>オン (on)</td>
<td>あたた・か (atataka)<br />あたた・かい (atatakai)<br />あたた・まる (atatamaru)<br />あたた・める (atatameru)</td>
<td>
<strong>Warm</strong>
<br />
<table><tbody><tr><td>Examples:
<br />
温泉 (おんせん - onsen - spa, hot spring) [n] <Play audio="onsen" />
<br />
気温 (きおん - kion - atmospheric temperature) [n] <Play audio="kion" />
<br />
温か (あたたか - atataka - warm, mild, genial) [dn] <Play audio="atataka" />
<br />
温かい (あたたかい - atatakai - warm, mild, genial) [dv] <Play audio="atatakai" />
<br />
温まる (あたたまる - atatamaru - to warm oneself, to sun oneself, to warm up, to get warm) [gv] <Play audio="atatamaru" />
<br />
温める (あたためる - atatameru - to warm, to heat) [iv] <Play audio="atatameru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>港</span></th>
<td>コウ (kou)</td>
<td>みなと (minato)</td>
<td>
<strong>Harbor</strong>
<br />
<table><tbody><tr><td>Examples:
<br />
空港 (くうこう - kuukou - airport) [n] <Play audio="kuukou" />
<br />
港 (みなと - minato - harbor, port) [n] <Play audio="minato" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>湖</span></th>
<td>コ (ko)</td>
<td>みずうみ (mizuumi)</td>
<td>
<strong>Lake</strong>
<br />
<table><tbody><tr><td>Examples:
<br />
湖畔 (こはん - kohan - lake shore) [n] <Play audio="kohan" />
<br />
湖 (みずうみ - mizuumi - lake) [n] <Play audio="mizuumi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>湯</span></th>
<td>トウ (tou)</td>
<td>ゆ (yu)</td>
<td>
<strong>Hot Water</strong>
<br />
<table><tbody><tr><td>Examples:
<br />
熱湯 (ねっとう - nettou - boiling water) [n] <Play audio="nettou" />
<br />
湯 (ゆ - yu - hot water, hot bath, hot spring - typically prefixed with "o") [n] <Play audio="yu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>漢</span></th>
<td>カン (kan)</td>
<td></td>
<td>
<strong>China</strong>
<br />
<table><tbody><tr><td>Examples:
<br />
漢字 (かんじ - kanji - Chinese characters) [n] <Play audio="kanji" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>炭</span></th>
<td>タン (tan)</td>
<td>すみ (sumi)</td>
<td>
<strong>Charcoal</strong>
<br />
<table><tbody><tr><td>Examples:
<br />
炭酸 (たんさん - tansan - carbonic acid) [n] <Play audio="tansan" />
<br />
炭 (すみ - sumi - charcoal) [n] <Play audio="sumi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>物</span></th>
<td>モツ (motsu)<br />ブツ (butsu)</td>
<td>もの (mono)</td>
<td>
<strong>Thing</strong>
<br />
<table><tbody><tr><td>Examples:
<br />
禁物 (きんもつ - kinmotsu - taboo, forbidden thing) [n] <Play audio="kinmotsu" />
<br />
動物 (どうぶつ - doubutsu - animal(s)) [n] <Play audio="doubutsu" />
<br />
物 (もの - mono - thing, object) [n] <Play audio="mono" />
<br />
果物 (くだもの - kudamono - fruit) [n] <Play audio="kudamono" />
<br />
食べ物 (たべもの - tabemono - food) [n] <Play audio="tabemono" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>球</span></th>
<td>キュウ (kyuu)</td>
<td>たま (tama)</td>
<td>
<strong>Ball</strong>
<br />
<table><tbody><tr><td>Examples:
<br />
野球 (やきゅう - yakyuu - baseball) [n] <Play audio="yakyuu" />
<br />
地球 (ちきゅう - chikyuu - the earth, the globe) [n] <Play audio="chikyuu" />
<br />
球 (たま - tama - ball, sphere, globe, orb) [n] <Play audio="tama" />
</td></tr></tbody></table></td>
</tr>
</tbody></table>

    </>
  )
}

export default GradeThreeThree