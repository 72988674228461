import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "100 Most Useful Phrases Part 2 of 4"

const commontwo1 = [
  {"漢字 - Kanji":"ご飯を食べます ","ひらがな - Hiragana":"ごはんをたべます ","Romaji (English Letters)":"gohan o tabemasu","English Meaning":"I'm going to eat (a meal)", "audio": "gohan o tabemasu"}
]
const commontwo2 = [
  {"漢字 - Kanji":"お昼ご飯は何処で食べたいですか ","ひらがな - Hiragana":"おひるごはんはどこでたべたいですか ","Romaji (English Letters)":"ohirugohan wa doko de tabetai desu ka","English Meaning":"Where would you like to eat lunch?", "audio": "ohirugohan wa doko de tabetai desu ka"}
]
const commontwo3 = [
  {"漢字 - Kanji":"お昼は何にしますか ","ひらがな - Hiragana":"おひるはなににしますか ","Romaji (English Letters)":"ohiru wa nani ni shimasu ka","English Meaning":"What shall we do for lunch?", "audio": "ohiru wa nani ni shimasu ka"}
]
const commontwo4 = [
  {"漢字 - Kanji":"今日は私のおごりです ","ひらがな - Hiragana":"きょうはわたしのおごりです ","Romaji (English Letters)":"kyou wa watashi no ogori desu","English Meaning":"It's my treat today/The meal is on me", "audio": "kyou wa watashi no ogori desu"}
]
const commontwo5 = [
  {"漢字 - Kanji":"このレストランのランチはとても美味しいです ","ひらがな - Hiragana":"このれすとらんのらんちはとてもおいしいです ","Romaji (English Letters)":"kono resutoran no ranchi wa totemo oishii desu","English Meaning":"The lunch at this restaurant is great (very delicious/tasty)", "audio": "kono resutoran no ranchi wa totemo oishii desu"}
]
const commontwo6 = [
  {"漢字 - Kanji":"ラーメンをお願いします ","ひらがな - Hiragana":"らーめんをおねがいします ","Romaji (English Letters)":"ramen o onegai shimasu","English Meaning":"Ramen please", "audio": "ramen o onegai shimasu"}
]
const commontwo7 = [
  {"漢字 - Kanji":"喉が渇いています ","ひらがな - Hiragana":"のどがかわいています ","Romaji (English Letters)":"nodo ga kawaite imasu","English Meaning":"I'm thirsty", "audio": "nodo ga kawaite imasu"}
]
const commontwo8 = [
  {"漢字 - Kanji":"御水を一杯頂けますか ","ひらがな - Hiragana":"おみずをいっぱいいただけますか ","Romaji (English Letters)":"omizu o ippai itadakemasu ka","English Meaning":"May I please have a glass of water?", "audio": "omizu o ippai itadakemasu ka"}
]
const commontwo9 = [
  {"漢字 - Kanji":"宜しくお願いします ","ひらがな - Hiragana":"よろしくおねがいします ","Romaji (English Letters)":"yoroshiku onegai shimasu","English Meaning":"no real equivalent in English", "audio": "yoroshiku onegai shimasu"}
]
const commontwo10 = [
  {"漢字 - Kanji":"休憩します ","ひらがな - Hiragana":"きゅうけいします ","Romaji (English Letters)":"kyuukei shimasu","English Meaning":"I'm going to take a break", "audio": "kyuukei shimasu"}
]
const commontwo11 = [
  {"漢字 - Kanji":"散歩に行って来ます ","ひらがな - Hiragana":"さんぽにいってきます ","Romaji (English Letters)":"sanpo ni itte kimasu","English Meaning":"I'm going to take a walk/stroll", "audio": "sanpo ni itte kimasu"}
]
const commontwo12 = [
  {"漢字 - Kanji":"ちょっとここで一休みします ","ひらがな - Hiragana":"ちょっとここでひとやすみします ","Romaji (English Letters)":"chotto koko de hitoyasumi shimasu","English Meaning":"I'm going to rest here for a bit", "audio": "chotto koko de hitoyasumi shimasu"}
]
const commontwo13 = [
  {"漢字 - Kanji":"一番近い駅は何処ですか ","ひらがな - Hiragana":"いちばんちかいえきはどこですか ","Romaji (English Letters)":"ichiban chikai eki wa doko desu ka","English Meaning":"Where is the closest/nearest train station?", "audio": "ichiban chikai eki wa doko desu ka"}
]
const commontwo14 = [
  {"漢字 - Kanji":"このバスは何処で止まりますか ","ひらがな - Hiragana":"このばすはどこでとまりますか ","Romaji (English Letters)":"kono basu wa doko de tomarimasu ka","English Meaning":"Where does this bus stop?", "audio": "kono basu wa doko de tomarimasu ka"}
]
const commontwo15 = [
  {"漢字 - Kanji":"車で駅まで送ってくれますか ","ひらがな - Hiragana":"くるまでえきまでおくってくれますか ","Romaji (English Letters)":"kuruma de eki made okutte kuremasu ka","English Meaning":"Could you please take me to the train station (in your car)?", "audio": "kuruma de eki made okutte kuremasu ka"}
]
const commontwo16 = [
  {"漢字 - Kanji":"あの駅で待ち合わせしましょう ","ひらがな - Hiragana":"あのえきでまちあわせしましょう ","Romaji (English Letters)":"ano eki de machiawase shimashou","English Meaning":"Let's meet at that train station", "audio": "ano eki de machiawase shimashou"}
]
const commontwo17 = [
  {"漢字 - Kanji":"その電車は何時に離れますか ","ひらがな - Hiragana":"そのでんしゃはなんじにはなれますか ","Romaji (English Letters)":"sono densha wa nanji ni hanaremasu ka","English Meaning":"What time does that train leave?", "audio": "sono densha wa nanji ni hanaremasu ka"}
]
const commontwo18 = [
  {"漢字 - Kanji":"二番線の八時の電車に乗ります ","ひらがな - Hiragana":"にばんせんのはちじのでんしゃにのります ","Romaji (English Letters)":"nibansen no hachiji no densha ni norimasu","English Meaning":"I will be taking the 8 o'clock train from platform 2", "audio": "nibansen no hachiji no densha ni norimasu"}
]
const commontwo19 = [
  {"漢字 - Kanji":"アメリカ行きの飛行機はいつ出発しますか ","ひらがな - Hiragana":"あめりかゆきのひこうきはいつしゅっぱつしますか ","Romaji (English Letters)":"amerika yuki no hikouki wa itsu shuppatsu shimasu ka","English Meaning":"When does the plane to America depart?", "audio": "amerika yuki no hikouki wa itsu shuppatsu shimasu ka"}
]
const commontwo20 = [
  {"漢字 - Kanji":"タクシーを呼んでくれますか ","ひらがな - Hiragana":"たくしーをよんでくれますか ","Romaji (English Letters)":"takushi- o yonde kuremasu ka","English Meaning":"Could you please call me a taxi?", "audio": "takushi- o yonde kuremasu ka"}
]
const commontwo21 = [
  {"漢字 - Kanji":"空港に近いホテルは何処ですか ","ひらがな - Hiragana":"くうこうにちかいほてるはどこですか ","Romaji (English Letters)":"kuukou ni chikai hoteru wa doko desu ka","English Meaning":"Where is a hotel close to the airport?", "audio": "kuukou ni chikai hoteru wa doko desu ka"}
]
const commontwo22 = [
  {"漢字 - Kanji":"ホテルの予約ができますか ","ひらがな - Hiragana":"ほてるのよやくができますか ","Romaji (English Letters)":"hoteru no yoyaku ga dekimasu ka","English Meaning":"Can I make a reservation (for this hotel)?", "audio": "hoteru no yoyaku ga dekimasu ka"}
]
const commontwo23 = [
  {"漢字 - Kanji":"英語が分かりますか ","ひらがな - Hiragana":"えいごがわかりますか ","Romaji (English Letters)":"eigo ga wakarimasu ka","English Meaning":"Do you speak English?", "audio": "eigo ga wakarimasu ka"}
]
const commontwo24 = [
  {"漢字 - Kanji":"____は、どういう意味ですか ","ひらがな - Hiragana":"____は、どういう意味ですか ","Romaji (English Letters)":"____ wa, dou iu imi desu ka","English Meaning":"What does ____ mean?", "audio": "wa dou iu imi desu ka"}
]
const commontwo25 = [
  {"漢字 - Kanji":"もう一度言ってくれますか ","ひらがな - Hiragana":"もういちどいってくれますか ","Romaji (English Letters)":"mouichido itte kuremasu ka","English Meaning":"Could you please say that again?", "audio": "mouichido itte kuremasu ka"}
]

const CommonTwo = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Beginner" category="Phrases" lesson={title} />
     <div className={'title'}>
     {title}
      </div>
      <h1>{title}</h1>

      <p>Below are 25 of the most common/useful phrases in the Japanese language and a breakdown/explanation of each. To some extent, this lesson assumes that you have already gone through <Link to="/members/b/common/common01/">Part 1</Link>.</p>

    <Table data={commontwo1} />

    <p><strong>Explanation:</strong></p>
<p>This sentence could work for "I'm going to eat dinner now", or "I'm going to eat lunch now", etc. The "now" is implied and which meal is also implied based on the time of day. Like with most polite form statements, this can be turned into a question by adding "ka" on the end. It could also be turned into a command by changing the verb to "te" form ("kudasai" could be added to the end to make it more polite).</p>

    <Table data={commontwo2} />

    <p><strong>Explanation:</strong></p>
<p>You generally shouldn't state that someone other than yourself wants to do something (using base 2 + "tai") but it's fine to ask. If you wanted to turn this into a statement then it needs to be followed by "to omoimasu" ("omou" means "to think") instead of "desu" - "kare wa soko de tabetai to omoimasu". In Japanese, one should never assume to know what someone else does or doesn't want.</p>

    <Table data={commontwo3} />

    <p><strong>Explanation:</strong></p>
<p>"nani" means "what". "nani ni shimasu ka" by itself means "What would you like to do?" and is used quite frequently.</p>

    <Table data={commontwo4} />

    <p><strong>Explanation:</strong></p>
<p>"ogori" comes from the verb 奢る <Play audio="ogoru" /> which means "to give (someone) a treat, to be extravagant, to live luxuriously". It is typically written in hiragana because the kanji isn't widely known. Like with many verbs, base 2 by itself can be a noun.</p>

    <Table data={commontwo5} />

    <p><strong>Explanation:</strong></p>
    <p>While Japanese has it's own word for "lunch", using English words (especially in pop songs) is very "cool". "ranchi" would be the closest pronunciation of the English word "lunch". "resutoran", on the other hand, is a borrowed word (from "restaurant") and the typical Japanese word for "restaurant". "totemo" means "very, exceedingly" and "oishii" means "delicious, tasty" (the kanji are for "beautiful" and "taste").</p>

    <Table data={commontwo6} />

    <p><strong>Explanation:</strong></p>
    <p>願う <Play audio="negau" /> is a verb which means "to desire, to wish, to hope, to beg, to request, to implore". Using base 2 of a verb with the honorific "o" on the front and "shimasu" on the end essentially creates the humble form of the verb. It is literally "I humbly request ramen". The word "ramen" could be replaced to request or order (in a restaurant) anything. The word for "order" is 注文 <Play audio="chuumon" /> so listen for that when you are in a Japanese restaurant.</p>

    <Table data={commontwo7} />

    <p><strong>Explanation:</strong></p>
    <p>This sentence is very similar to "onaka ga suite imasu" (learned in part 1). 喉 (<Play audio="nodo" />  means "throat" and 渇く <Play audio="kawaku" /> means "to be thirsty". The primary "kawaku" (乾く) means "to get dry" so (although it uses a different kanji) "nodo ga kawaite imasu" has origins in "my throat is dry".</p>

    <Table data={commontwo8} />

    <p><strong>Explanation:</strong></p>
<p>"mizu" (cold water) is often prefixed with the honorific "o". お湯 (<Play audio="oyu" />  - which could also be written 御湯 if you prefer to use the kanji for the honorific) is the word for "hot water" and rarely has the "o" left off. 一杯 (<Play audio="ippai" />  can mean "a lot", "full", or "cupful". This sentence could mean both "may I please have a lot of water?" or "may I please have a cupful (glass) of water?" but the second makes more sense. "itadaku" is in base 4 + "ru" (can verb) form.</p>

    <Table data={commontwo9} />

    <p><strong>Explanation:</strong></p>
<p>"yoroshiku onegai shimasu" is one of the most commonly spoken phrases in Japanese but it doesn't really have an equivalent in English. It is basically "please be my friend" or "please treat me well". It can be used right after introducing yourself to someone (in which case it has more of a "pleased to make your acquaintance" vibe), when you see someone again after a period of time, or as part of a farewell. <Play audio="yoroshii" />  means "good, OK, all right, fine, very well". It is essentially the honorific form of "ii/yoi". With the last "i" dropped and a "ku" added, it is in adverb form so "yoroshiku onegai shimasu" literally means "I request/beg goodly from you".</p>

    <Table data={commontwo10} />

    <p><strong>Explanation:</strong></p>
<p>休憩 <Play audio="kyuukei" /> means "rest, break" and "kyuukei suru" means "to rest, to take a break". Statements such as this ("I'm going to...") are often used in Japanese in places where we might say "I need to..." in English so this could also be translated as "I need to take a break".</p>

    <Table data={commontwo11} />

    <p><strong>Explanation:</strong></p>
<p>While "itte kimasu" by itself is a common farewell for someone leaving the house, it is also often used with a specific "destination" to indicate that they are going somewhere and then coming back. While "walk/stroll" [n] is not a destination and "I'm going to a stroll" sounds weird in English, this is a very common sort of thing in Japanese.</p>


    <Table data={commontwo12} />

    <p><strong>Explanation:</strong></p>
<p>"chotto" is a great and very regularly used Japanese word. It means "just a minute, short time, just a little, somewhat". "koko" means "here" and is very closely related to "kore" (this) and "kono" (this - descriptive). These words could be written in kanji as 此処 (koko), 此れ (kore), and 此の (kono) but they are typically written in hiragana. The word "hitoyasumi" is a combination of the character for "one" and the character for "rest" (which you might recognize from "kyuukei" and "yasumu").</p>

    <Table data={commontwo13} />

    <p><strong>Explanation:</strong></p>
<p>Trains are a very common form of transportation in Japan and the train station is called an "eki". While "ichiban" can mean "number 1" or "the best" it can also be used with adjectives (such as "chikai" (close, near) in this case) to mean ____est (closest, nearest in this case).</p>

    <Table data={commontwo14} />

    <p><strong>Explanation:</strong></p>
<p>This is basically the Japanese equivalent of "where does this bus go?". Just because a particular verb is used in one language to express a particular idea does not mean the same verb might be used in another language. 止まる <Play audio="tomaru" /> means "to stop, to come to a halt". Japanese stop signs are red upside down triangles which have 止まれ ("Stop!!!") written on them. "basu" is another borrowed word from the English word "bus".</p>


    <Table data={commontwo15} />

    <p><strong>Explanation:</strong></p>
<p>As taught in <Link to="/members/b/grammar/bases02/">Using Verb Bases - Part 2</Link>, base "te" + "kureru" ("kuremasu" in polite form) means that someone else verbs for you or another person. When used as a question it is a very polite way to ask for someone to do something for you. 送る <Play audio="okuru" /> means "to send (a thing), to dispatch, to despatch, to take or escort (a person somewhere), to see off (a person)" and is the same verb that could be used for sending a package, for instance. The particle "made" means "until" - see the lesson More Particles for more information on this and the particle "de".</p>

    <Table data={commontwo16} />

    <p><strong>Explanation:</strong></p>
<p>"ano" is related to "kono" (this - descriptive) and "sono" (that - descriptive) and essentially means "that over there". It's kind of like "that thing that we talked about before". "machiawase" is the noun form (base 2) of the verb 待ち合わせる <Play audio="machiawaseru" /> which means "to rendezvous, to meet at a prearranged place and time, to arrange to meet" and is a compound verb made up from the verbs 待つ(<Play audio="matsu" />  - "to wait") and 合う (<Play audio="au" />  - "to come together, to merge, to unite, to meet") in a causitive form (to force, to allow) - 合わせる (<Play audio="awaseru" />  - "to join together, to unite, to combine"). Base 2 + "mashou" is the polite form equivalent of base 5 (let's verb).</p>

    <Table data={commontwo17} />

    <p><strong>Explanation:</strong></p>
<p>"densha" means "train" but, more specifically, an electric train. 電 is the kanji for "electricity" and 車 is the kanji for "wheel" or "car". 離れる <Play audio="hanareru" /> means "to be separated from, to leave, to go away".</p>

    <Table data={commontwo18} />

    <p><strong>Explanation:</strong></p>
<p>"___bansen" is how you would say which track/platform your train will be on. 番 is the kanji for "number" (like "number 1 (#1)", "number 2 (#2)", etc.) and 線 is the kanji for "line, wire, beam". "___ji" indicates the time - for more information see <Link to="/members/b/vocab/numbers/">Numbers, Time, and Dates</Link>. The verb 乗る <Play audio="noru" /> means "to get on, to ride".</p>

    <Table data={commontwo19} />

    <p><strong>Explanation:</strong></p>
<p>While 行く is generally read "iku" it can also be read "yuku". When in base 2 and combined with another noun, it is read "yuki" and means "going to ____" as a compound noun. You can see the same kanji 行 as the middle kanji for 飛行機 (<Play audio="hikouki" />  - airplane). The kanji are 飛 ("to fly"), 行 ("to go"), and 機 ("machine"). 出発する <Play audio="shuppatsu suru" /> means "to depart".</p>

    <Table data={commontwo20} />

    <p><strong>Explanation:</strong></p>
<p>"takushi-" is also a borrowed word from the English word "taxi". The verb 呼ぶ <Play audio="yobu" /> means "to call out (to), to summon (a doctor, etc.)".</p>

    <Table data={commontwo21} />

    <p><strong>Explanation:</strong></p>
<p>空港 <Play audio="kuukou" /> means "airport" and is made up of the kanji 空 ("sky") and 港 ("harbor"). Once again we have a borrowed word "hoteru" that comes from the English word "hotel".</p>

    <Table data={commontwo22} />

    <p><strong>Explanation:</strong></p>
<p>予約 <Play audio="yoyaku" /> means "reservation". In Japanese, the verb "to make" isn't used but the verb "dekiru" (can do) can be used instead.</p>

    <Table data={commontwo23} />

    <p><strong>Explanation:</strong></p>
<p>In Japanese, the verb 分かる (<Play audio="wakaru" />  - "to understand") is used instead of the verb "to speak".</p>

    <Table data={commontwo24} />

    <p><strong>Explanation:</strong></p>
<p>意味 ("imi") means "meaning" (noun) so "____ wa, dou iu imi desu ka" technically means "____... what sort of meaning is it?".</p>

    <Table data={commontwo25} />

    <p><strong>Explanation:</strong></p>
<p>一度 ("ichido") by itself means "once, one time" but when paired with "mou" (which means "more, further, other, again" in this case) it means "once more, again". The verb 言う <Play audio="iu" /> means "to say".</p>


    </>
  )
}

export default CommonTwo