import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Grade 4 Kanji - Part 1 of 5"

const GradeFourOne = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Advanced" category="Kanji" lesson={title} />
 
      <h1>{title}</h1>

      <p>Below are Kanji the Japanese learn in grade 4 of elementary school.
Be sure to read the <Link to="/members/a/kanji/intro/">Introduction to Kanji</Link> if you haven't already.</p>

<p>Legend:</p>
<p>[n] = Noun</p>
<p>[dv] = Descriptive word (true adjective) - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[dn] = Descriptive noun - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[gv] = Godan verb - see <Link to="/members/b/grammar/godan-verbs/">Verb Conjugation (Godan)</Link></p>
<p>[iv] = Ichidan verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[irv] = Irregular verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[c] = Counter</p>

<table>
<thead>
  <tr>
<th>漢字<br /><Play audio="kanji" /></th>
<th>音読み <Play audio="onyomi" /><br />"On" Reading</th>
<th>訓読み <Play audio="kunyomi" /><br />"Kun" Reading</th>
<th>English Meaning</th>
</tr>
  </thead>
<tbody>
<tr>
<th><span style={{fontSize:`4rem`}}>不</span></th>
<td>フ (fu)<br />ブ (bu)</td>
<td></td>
<td>
Not
<br />
<table><tbody><tr><td>Examples:
<br />
不安 (ふあん - fuan - anxiety, uneasiness, insecurity, suspense) [dn] <Play audio="fuan" />
<br />
不用心 (ぶようじん - buyoujin - insecurity, carelessness) [dn] <Play audio="buyoujin" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>争</span></th>
<td>ソウ (sou)</td>
<td>あらそ・う (arasou)</td>
<td>
Conflict
<br />
<table><tbody><tr><td>Examples:
<br />
競争 (きょうそう - kyousou - competition, contest) [n] <Play audio="kyousou" />
<br />
戦争 (せんそう - sensou - war) [n] <Play audio="sensou" />
<br />
争う (あらそう - arasou - to compete, to contest, to contend, to quarrel, to argue, to dispute) [gv] <Play audio="arasou" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>付</span></th>
<td>フ (fu)</td>
<td>つ・く (tsuku)<br />つ・ける (tsukeru)</td>
<td>
Attach
<br />
<table><tbody><tr><td>Examples:
<br />
寄付 (きふ - kifu - contribution, donation) [n] <Play audio="kifu" />
<br />
付く (つく - tsuku - to be attached, to be connected with, to adhere, to cling) [gv] <Play audio="tsuku" />
<br />
付ける (つける - tsukeru - to attach, to join, to append, to affix, to fasten) [iv] <Play audio="tsukeru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>令</span></th>
<td>レイ (rei)</td>
<td></td>
<td>
Orders
<br />
<table><tbody><tr><td>Examples:
<br />
命令 (めいれい - meirei - order, command, decree, directive) [n] <Play audio="meirei" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>以</span></th>
<td>イ (i)</td>
<td></td>
<td>
Since
<br />
<table><tbody><tr><td>Examples:
<br />
以来 (いらい - irai - since, henceforth) <Play audio="irai" />
<br />
以外 (いがい - igai - with the exception of, excepting) <Play audio="igai" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>仲</span></th>
<td>チュウ (chuu)</td>
<td>なか (naka)</td>
<td>
Go-Between
<br />
<table><tbody><tr><td>Examples:
<br />
仲介 (ちゅうかい - chuukai - agency, intermediation) [n] <Play audio="chuukai" />
<br />
仲間 (なかま - nakama - colleague, associate, comrade, circle of friends) [n] <Play audio="nakama" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>伝</span></th>
<td>デン (den)</td>
<td>つた・わる (tsutawaru)<br />つた・える (tsutaeru)<br />つた・え (tsutae)<br />つた・う (tsutau)</td>
<td>
Transmit
<br />
<table><tbody><tr><td>Examples:
<br />
駅伝 (えきでん - ekiden - stagecoach, post horse) [n] <Play audio="ekiden" />
<br />
伝わる (つたわる - tsutawaru - to be handed down, to be introduced, to be transmitted, to be circulated) [gv] <Play audio="tsutawaru" />
<br />
伝える (つたえる - tsutaeru - to convey, to report, to transmit, to communicate, to tell, to impart) [iv] <Play audio="tsutaeru" />
<br />
伝え (つたえ - tsutae - legend, tradition) [n] <Play audio="tsutae" />
<br />
伝う (つたう - tsutau - to go along, to walk along, to follow) [gv] <Play audio="tsutau" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>位</span></th>
<td>イ (i)</td>
<td>くらい (kurai)</td>
<td>
Rank
<br />
<table><tbody><tr><td>Examples:
<br />
一位 (いちい - ichii - first place, first rank) [n] <Play audio="ichii" />
<br />
位 (くらい - kurai - approximately, about, around, or so) <Play audio="kurai" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>低</span></th>
<td>テイ (tei)</td>
<td>ひく・い (hikui)<br />ひく・める (hikumeru)<br />ひく・まる (hikumaru)</td>
<td>
Low
<br />
<table><tbody><tr><td>Examples:
<br />
低温 (ていおん - teion - low temperature) [n] <Play audio="teion" />
<br />
低い (ひくい - hikui - low (height, tone, rank, degree, cost, etc.), short) [dv] <Play audio="hikui" />
<br />
低める (ひくめる - hikumeru - to lower) [iv] <Play audio="hikumeru" />
<br />
低まる (ひくまる - hikumaru - to be lowered) [gv] <Play audio="hikumaru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>例</span></th>
<td>レイ (rei)</td>
<td>たと・える (tatoeru)</td>
<td>
Example
<br />
<table><tbody><tr><td>Examples:
<br />
例年 (れいねん - reinen - average (normal, ordinary) year, every year, annually) [n] <Play audio="reinen" />
<br />
例える (たとえる - tatoeru - to compare, to liken, to speak figuratively, to illustrate, to use a simile) [iv] <Play audio="tatoeru" />
<br />
例え (たとえ - tatoe - example, simile, metaphor, allegory, parable) [n] <Play audio="tatoe" />
<br />
例えば (たとえば - tatoeba - for example...) <Play audio="tatoeba" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>便</span></th>
<td>ベン (ben)<br />ビン (bin)</td>
<td>たよ・り (tayori)</td>
<td>
Convenience
<br />
<table><tbody><tr><td>Examples:
<br />
便利 (べんり - benri - convenient, handy, useful) [dn] <Play audio="benri" />
<br />
不便 (ふべん - fuben - inconvenience, inconvenient) [dn] <Play audio="fuben" />
<br />
郵便 (ゆうびん - yuubin - mail, postal service) [n] <Play audio="yuubin" />
<br />
便り (たより - tayori - news, tidings, information, correspondence) [n] <Play audio="tayori" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>信</span></th>
<td>シン (shin)</td>
<td></td>
<td>
Trust
<br />
<table><tbody><tr><td>Examples:
<br />
信頼 (しんらい - shinrai - reliance, trust, faith, confidence) [n] <Play audio="shinrai" />
<br />
信じる (しんじる - shinjiru - to believe, to believe in, to place trust in, to confide in, to have faith in) [iv] <Play audio="shinjiru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>倉</span></th>
<td>ソウ (sou)</td>
<td>くら (kura)</td>
<td>
Storage
<br />
<table><tbody><tr><td>Examples:
<br />
倉庫 (そうこ - souko - storehouse, warehouse) [n] <Play audio="souko" />
<br />
倉 (くら - kura - warehouse, cellar, granary, depository, treasury) [n] <Play audio="kura" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>候</span></th>
<td>コウ (kou)</td>
<td></td>
<td>
Climate
<br />
<table><tbody><tr><td>Examples:
<br />
兆候 (ちょうこう - choukou - sign, indication, omen, symptom) [n] <Play audio="choukou" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>借</span></th>
<td>シャク (shaku)</td>
<td>か・りる (kariru)<br />か・る (karu)</td>
<td>
Borrow
<br />
<table><tbody><tr><td>Examples:
<br />
借地 (しゃくち - shakuchi - leased land) [n] <Play audio="shakuchi" />
<br />
借りる (かりる - kariru - to borrow, to have a loan, to rent) [iv] <Play audio="kariru" />
<br />
借る (かる - karu - to borrow, to have a loan, to rent) [gv] <Play audio="karu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>停</span></th>
<td>テイ (tei)</td>
<td></td>
<td>
Halt
<br />
<table><tbody><tr><td>Examples:
<br />
停電 (ていでん - teiden - power outage, electricity outage, blackout, failure of electricity supply) [n] <Play audio="teiden" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>健</span></th>
<td>ケン (ken)</td>
<td>すこ・やか (sukoyaka)</td>
<td>
Healthy
<br />
<table><tbody><tr><td>Examples:
<br />
健康 (けんこう - kenkou - health, wholesome) [dn] <Play audio="kenkou" />
<br />
健康的 (けんこうてき - kenkouteki - hygienic, healthy, sanitary) [dn] <Play audio="kenkouteki" />
<br />
健やか (すこやか - sukoyaka - vigorous, healthy) [dn] <Play audio="sukoyaka" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>側</span></th>
<td>ソク (soku)</td>
<td>かわ (kawa)</td>
<td>
Side
<br />
<table><tbody><tr><td>Examples:
<br />
側面 (そくめん - sokumen - side, flank, sidelight, lateral) [n] <Play audio="sokumen" />
<br />
裏側 (うらがわ - uragawa - the reverse, other side) [n] <Play audio="uragawa" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>働</span></th>
<td>ドウ (dou)</td>
<td>はたら・く (hataraku)<br />はたら・かす (hatarakasu)</td>
<td>
Work
<br />
<table><tbody><tr><td>Examples:
<br />
実働 (じつどう - jitsudou - actual work) [n] <Play audio="jitsudou" />
<br />
働く (はたらく - hataraku - to work, to labor, to function, to operate) [gv] <Play audio="hataraku" />
<br />
働かす (はたらかす - hatarakasu - to put someone to work, to make someone work) [gv] <Play audio="hatarakasu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>億</span></th>
<td>オク (oku)</td>
<td></td>
<td>
Hundred Million
<br />
<table><tbody><tr><td>Examples:
<br />
億万長者 (おくまんちょうじゃ - okumanchouja - billionaire) [n] <Play audio="okumanchouja" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>兆</span></th>
<td>チョウ (chou)</td>
<td>きざ・す (kizasu)<br />きざ・し (kizashi)</td>
<td>
Portent or Trillion
<br />
<table><tbody><tr><td>Examples:
<br />
兆候 (ちょうこう - choukou - sign, indication, omen, symptom) [n] <Play audio="choukou" />
<br />
一兆 (いっちょう - itchou - 1,000,000,000,000, one trillion) [n] <Play audio="itchou" />
<br />
兆す (きざす - kizasu - to show signs (symptoms)) [gv] <Play audio="kizasu" />
<br />
兆し (きざし - kizashi - signs, omen, symptoms) [n] <Play audio="kizashi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>児</span></th>
<td>ニ (ni)<br />ジ (ji)</td>
<td></td>
<td>
Child
<br />
<table><tbody><tr><td>Examples:
<br />
小児科 (しょうにか - shounika - pediatrics) [n] <Play audio="shounika" />
<br />
幼児 (ようじ - youji - infant, baby, child) [n] <Play audio="youji" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>共</span></th>
<td>キョウ (kyou)</td>
<td>とも (tomo)</td>
<td>
Together
<br />
<table><tbody><tr><td>Examples:
<br />
共通 (きょうつう - kyoutsuu - commonness, community) [dn] <Play audio="kyoutsuu" />
<br />
共に (ともに - tomo ni - together, jointly) <Play audio="tomo ni" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>兵</span></th>
<td>ヒョウ (hyou)<br />ヘイ (hei)</td>
<td>つわもの (tsuwamono)</td>
<td>
Soldier
<br />
<table><tbody><tr><td>Examples:
<br />
小兵 (こひょう - kohyou - small build or stature) [n] <Play audio="kohyou" />
<br />
兵隊 (へいたい - heitai - soldier, sailor) [n] <Play audio="heitai" />
<br />
兵 (つわもの - tsuwamono - soldier, warrior) [n] <Play audio="tsuwamono" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>典</span></th>
<td>テン (ten)</td>
<td></td>
<td>
Code
<br />
<table><tbody><tr><td>Examples:
<br />
典型的 (てんけいてき - tenkeiteki - typical, ideal) [dn] <Play audio="tenkeiteki" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>冷</span></th>
<td>レイ (rei)</td>
<td>つめ・たい (tsumetai)<br />ひ・える (hieru)<br />ひ・や (hiya)<br />ひ・やす (hiyasu)<br />ひ・やかす (hiyakasu)<br />さ・める (sameru)<br />さ・ます (samasu)</td>
<td>
Cool
<br />
<table><tbody><tr><td>Examples:
<br />
冷房 (れいぼう - reibou - cooling, air-conditioning) [n] <Play audio="reibou" />
<br />
冷たい (つめたい - tsumetai - cold (to the touch), chilly, icy, freezing, coldhearted, unfeeling) [dv] <Play audio="tsumetai" />
<br />
冷える (ひえる - hieru - to grow cold (from room temperature, e.g. in refrigerator), to get chilly, to cool down) [iv] <Play audio="hieru" />
<br />
冷や (ひや - hiya - cold, cool, chilled, unheated) (prefix) <Play audio="hiya" />
<br />
冷やす (ひやす - hiyasu - to cool (from room temperature), to chill, to refrigerate) [gv] <Play audio="hiyasu" />
<br />
冷やかす (ひやかす - hiyakasu - to banter, to make fun of, to jeer at, to cool, to refrigerate) [gv] <Play audio="hiyakasu" />
<br />
冷める (さめる - sameru - to become cool (e.g. from a high temperature to room temperature), to come down (fever)) [iv] <Play audio="sameru" />
<br />
冷ます (さます - samasu - to cool (e.g. from a high temperature to room temperature), to let cool) [gv] <Play audio="samasu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>初</span></th>
<td>ショ (sho)</td>
<td>はじ・め (hajime)<br />はじ・めて (hajimete)<br />はつ (hatsu)<br />うい (ui - rarely used)<br />そ・める (someru)</td>
<td>
First
<br />
<table><tbody><tr><td>Examples:
<br />
初対面 (しょたいめん - shotaimen - first meeting, first interview with) [n] <Play audio="shotaimen" />
<br />
初め (はじめ - hajime - beginning, start, outset, opening) [n] <Play audio="hajime" />
<br />
初めて (はじめて - hajimete - for the first time) <Play audio="hajimete" />
<br />
初 (はつ - hatsu - first, new) (prefix) <Play audio="hatsu" />
<br />
初める (そめる - someru - to begin to) [iv] <Play audio="someru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>別</span></th>
<td>ベツ (betsu)</td>
<td>わか・れる (wakareru)</td>
<td>
Separate
<br />
<table><tbody><tr><td>Examples:
<br />
差別 (さべつ - sabetsu - discrimination, distinction, differentiation) [n] <Play audio="sabetsu" />
<br />
特別 (とくべつ - tokubetsu - special) [dn] <Play audio="tokubetsu" />
<br />
別れる (わかれる - wakareru - to be divided, to part from, to separate, to bid farewell) [iv] <Play audio="wakareru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>利</span></th>
<td>リ (ri)</td>
<td>き・く (kiku)<br />き・き (kiki)</td>
<td>
Profit
<br />
<table><tbody><tr><td>Examples:
<br />
利益 (りえき - rieki - profit, gains, benefit, advantage, interest (of the public, etc.)) [n] <Play audio="rieki" />
<br />
利く (きく - kiku - to do its work, to carry out its function well) [gv] <Play audio="kiku" />
<br />
利き (きき - kiki - work, efficacy, effective) [n] <Play audio="kiki" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>刷</span></th>
<td>サツ (satsu)</td>
<td>す・る (suru)</td>
<td>
Printing
<br />
<table><tbody><tr><td>Examples:
<br />
印刷 (いんさつ - insatsu - printing) [n] <Play audio="insatsu" />
<br />
刷る (する - suru - to print, to color or pattern fabric using a wooden mold) [gv] <Play audio="suru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>副</span></th>
<td>フク (fuku)</td>
<td></td>
<td>
Vice-
<br />
<table><tbody><tr><td>Examples:
<br />
副業 (ふくぎょう - fukugyou - side job, subsidiary business) [n] <Play audio="fukugyou" />
<br />
副大統領 (ふくだいとうりょう - fukudaitouryou - vice president (of a country)) [n] <Play audio="fukudaitouryou" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>功</span></th>
<td>コウ (kou)</td>
<td></td>
<td>
Achievement
<br />
<table><tbody><tr><td>Examples:
<br />
功績 (こうせき - kouseki - achievements, merit) [n] <Play audio="kouseki" />
<br />
成功 (せいこう - seikou - success) [n] <Play audio="seikou" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>加</span></th>
<td>カ (ka)</td>
<td>くわ・える (kuwaeru)<br />くわ・わる (kuwawaru)</td>
<td>
Add
<br />
<table><tbody><tr><td>Examples:
<br />
加入 (かにゅう - kanyuu (ka'nyuu) - becoming a member, joining, admission, affiliation) [n] <Play audio="ka-nyuu" />
<br />
加える (くわえる - kuwaeru - to append, to sum up, to add (up)) [iv] <Play audio="kuwaeru" />
<br />
加わる (くわわる - kuwawaru - to be added to, to be appended, to join in (e.g. a group of friends), to participate) [gv] <Play audio="kuwawaru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>努</span></th>
<td>ド (do)</td>
<td>つと・める (tsutomeru)</td>
<td>
Toil
<br />
<table><tbody><tr><td>Examples:
<br />
努力 (どりょく - doryoku - great effort, exertion) [n] <Play audio="doryoku" />
<br />
努める (つとめる - tsutomeru - to endeavor, to try, to strive, to make an effort, to exert oneself, to be diligent) [iv] <Play audio="tsutomeru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>労</span></th>
<td>ロウ (rou)</td>
<td>ねぎら・う (negirau)</td>
<td>
Labor
<br />
<table><tbody><tr><td>Examples:
<br />
労働 (ろうどう - roudou - manual labor, toil, work) [n] <Play audio="roudou" />
<br />
苦労 (くろう - kurou - troubles, hardships) [n] <Play audio="kurou" />
<br />
労う (ねぎらう - negirau - to thank for, for reward for) [gv] <Play audio="negirau" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>勇</span></th>
<td>ユウ (yuu)</td>
<td>いさ・む (isamu)<br />いさ・ましい (isamashii)</td>
<td>
Courage
<br />
<table><tbody><tr><td>Examples:
<br />
勇気 (ゆうき - yuuki - courage, bravery) [n] <Play audio="yuuki" />
<br />
勇む (いさむ - isamu - to be in high spirits, to be encouraged) [gv] <Play audio="isamu" />
<br />
勇ましい (いさましい - isamashii - brave, valiant, gallant, courageous) [dv] <Play audio="isamashii" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>包</span></th>
<td>ホウ (hou)</td>
<td>つつ・む (tsutsumu)<br />つつ・み (tsutsumi)</td>
<td>
Wrap
<br />
<table><tbody><tr><td>Examples:
<br />
包装 (ほうそう - housou - packing, wrapping) [n] <Play audio="housou" />
<br />
包む (つつむ - tsutsumu - to wrap up, to pack) [gv] <Play audio="tsutsumu" />
<br />
包み (つつみ - tsutsumi - bundle, package, parcel) [n] <Play audio="tsutsumi" /></td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>卒</span></th>
<td>ソツ (sotsu)</td>
<td></td>
<td>
Graduate
<br />
<table><tbody><tr><td>Examples:
<br />
卒業 (そつぎょう - sotsugyou - graduation) [n] <Play audio="sotsugyou" />
<br />
卒業式 (そつぎょうしき - sotsugyoushiki - graduation ceremony) [n] <Play audio="sotsugyoushiki" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>協</span></th>
<td>キョウ (kyou)</td>
<td></td>
<td>
Cooperation
<br />
<table><tbody><tr><td>Examples:
<br />
妥協 (だきょう - dakyou - compromise, giving in) [n] <Play audio="dakyou" />
<br />
協会 (きょうかい - kyoukai - association, society, organization) [n] <Play audio="kyoukai" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>単</span></th>
<td>タン (tan)</td>
<td></td>
<td>
Simple
<br />
<table><tbody><tr><td>Examples:
<br />
単独 (たんどく - tandoku - sole, independence, single) [n] <Play audio="tandoku" />
<br />
簡単 (かんたん - kantan - simple, easy, brief, uncomplicated) [dn] <Play audio="kantan" />
</td></tr></tbody></table></td>
</tr>
</tbody></table>

    </>
  )
}

export default GradeFourOne