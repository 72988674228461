import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Grade 1 Kanji  - Part 2 of 2"

const GradeOneTwo = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Advanced" category="Grammar" lesson={title} />
    
      <h1>{title}</h1>

      <p>Below are Kanji the Japanese learn in grade 1 of elementary school.
Be sure to read the <Link to="/members/a/kanji/intro/">Introduction to Kanji</Link> if you haven't already.</p>

<p><strong>Legend:</strong></p>
<p>[n] = Noun</p>
<p>[dv] = Descriptive word (true adjective) - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[dn] = Descriptive noun - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[gv] = Godan verb - see <Link to="/members/b/grammar/godan-verbs/">Verb Conjugation (Godan)</Link></p>
<p>[iv] = Ichidan verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[irv] = Irregular verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[c] = Counter</p>

<table>
<thead>
  <tr>
<th>漢字<br /><Play audio="kanji" /></th>
<th>音読み <Play audio="onyomi" /><br />"On" Reading</th>
<th>訓読み <Play audio="kunyomi" /><br />"Kun" Reading</th>
<th>English Meaning</th>
</tr>
  </thead>
<tbody>
<tr>
<th><span style={{fontSize:`4rem`}}>子</span></th>
<td>シ (shi)<br />ス (su)</td>
<td>こ (ko)</td>
<td>
Child
<br />
<table><tbody><tr><td>Examples:
<br />
母子家庭 (ぼしかてい - boshikatei - fatherless family) [n] <Play audio="boshikatei" />
<br />
様子 (ようす - yousu - state, appearance) [n] <Play audio="yousu" />
<br />
子 (こ - ko - child) [n] <Play audio="ko" />
<br />
子供 (こども - kodomo - children) [n] <Play audio="kodomo" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>目</span></th>
<td>モク (moku)<br />ボク (boku)</td>
<td>め (me)<br />ま (ma)</td>
<td>
Eye
<br />
<table><tbody><tr><td>Examples:
<br />
目撃する (もくげきする - mokugeki suru - to observe, to witness) [irv] <Play audio="mokugeki suru" />
<br />
面目 (めんぼく - menboku - honor, reputation) [n] <Play audio="menboku" />
<br />
目 (め - me - eye) [n] <Play audio="me" />
<br />
目深 (まぶか - mabuka - wearing low over one's eyes) [dn] <Play audio="mabuka" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>耳</span></th>
<td>ジ (ji)</td>
<td>みみ (mimi)</td>
<td>
Ear
<br />
<table><tbody><tr><td>Examples:
<br />
耳鼻科 (じびか - jibika - otolaryngology　(concerning the ear, nose, and throat)) [n] <Play audio="jibika" />
<br />
耳 (みみ - mimi - ear) [n] <Play audio="mimi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>口</span></th>
<td>ク (ku)<br />コウ (kou)</td>
<td>くち (kuchi)</td>
<td>
Mouth
<br />
<table><tbody><tr><td>Examples:
<br />
口調 (くちょう - kuchou - tone of voice, verbal expression) [n] <Play audio="kuchou" />
<br />
口内炎 (こうないえん - kounaien - canker) [n] <Play audio="kounaien" />
<br />
口 (くち - kuchi - mouth) [n] <Play audio="kuchi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>手</span></th>
<td>シュ (shu)</td>
<td>て (te)<br />た (ta)</td>
<td>
Hand
<br />
<table><tbody><tr><td>Examples:
<br />
拍手する (はくしゅする - hakushu suru - to applause) [irv] <Play audio="hakushu suru" />
<br />
手 (て - te - hand) [n] <Play audio="te" />
<br />
下手 (へた - heta - unskillful) [dn] <Play audio="heta" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>足</span></th>
<td>ソク (soku)</td>
<td>あし (ashi)<br />た・りる (tariru)<br />た・る (taru)<br />た・す (tasu)</td>
<td>
Foot, Leg
<br />
<table><tbody><tr><td>Examples:
<br />
遠足 (えんそく - ensoku - excursion, outing, trip) [n] <Play audio="ensoku" />
<br />
足 (あし - ashi - foot, leg) [n] <Play audio="ashi" />
<br />
足りる (たりる - tariru - to be sufficient, to be enough) [iv] <Play audio="tariru" />
<br />
足る (たる - taru - to be sufficient, to be enough) [gv] <Play audio="taru" />
<br />
足す (たす - tasu - to add numbers, to add something) [gv] <Play audio="tasu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>見</span></th>
<td>ケン (ken)</td>
<td>み・る (miru)<br />み・える (mieru)<br />み・せる (miseru)</td>
<td>
See
<br />
<table><tbody><tr><td>Examples:
<br />
見学 (けんがく - kengaku - inspection, field trip) [n] <Play audio="kengaku" />
<br />
見る (みる - miru - to see, to look, to watch) [iv] <Play audio="miru" />
<br />
見える (みえる - mieru - to be seen, to be in sight) [iv] <Play audio="mieru" />
<br />
見せる (みせる - miseru - to show, to display) [iv] <Play audio="miseru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>音</span></th>
<td>オン (on)<br />イン (in)</td>
<td>おと (oto)<br />ね (ne)</td>
<td>
Sound
<br />
<table><tbody><tr><td>Examples:
<br />
音楽 (おんがく - ongaku - music) [n] <Play audio="ongaku" />
<br />
母音 (ぼいん - boin - vowel) [n] <Play audio="boin" />
<br />
音 (おと - oto - sound) [n] <Play audio="oto" />
<br />
本音 (ほんね - honne - intention, motive) [n] <Play audio="honne" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>力</span></th>
<td>リキ (riki)<br />リョク (ryoku)</td>
<td>ちから (chikara)</td>
<td>
Power
<br />
<table><tbody><tr><td>Examples:
<br />
力士 (りきし - rikishi - sumo wrestler) [n] <Play audio="rikishi" />
<br />
努力する (どりょくする - doryoku suru - to put forth great effort, to endeavor) [irv] <Play audio="doryoku suru" />
<br />
力 (ちから - chikara - power) [n] <Play audio="chikara" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>気</span></th>
<td>ケ (ke)<br />キ (ki)</td>
<td></td>
<td>
Spirit
<br />
<table><tbody><tr><td>Examples:
<br />
湿気 (しっけ - shikke - moisture, humidity) [n] <Play audio="shikke" />
<br />
元気 (げんき - genki - health, vitality, stamina) [dn] <Play audio="genki" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>円</span></th>
<td>エン (en)</td>
<td>まる (maru)<br />まる・い (marui)</td>
<td>
Circle, Yen (Japanese currency)
<br />
<table><tbody><tr><td>Examples:
<br />
百円 (ひゃくえん - hyakuen - 100 yen) [n] <Play audio="hyakuen" />
<br />
円 (まる - maru - circle) [n] <Play audio="maru" />
<br />
円い (まるい - marui - round, circular) [dv] <Play audio="marui" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>入</span></th>
<td>ニュウ (nyuu)</td>
<td>い・る (iru)<br />い・れる (ireru)<br />はい・る (hairu)</td>
<td>
Enter
<br />
<table><tbody><tr><td>Examples:
<br />
入場 (にゅうじょう - nyuujou - entrance) [n] <Play audio="nyuujou" />
<br />
入る (いる - iru - to get in, to go in) [gv] <Play audio="iru" />
<br />
入れる (いれる - ireru - to put in, to let in, to take in) [iv] <Play audio="ireru" />
<br />
入る (はいる - hairu - to enter, to join) [gv] <Play audio="hairu" />
<br />
入口 (いりぐち - iriguchi - entrance) [n] <Play audio="iriguchi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>出</span></th>
<td>シュツ (shutsu)<br />スイ (sui)</td>
<td>で・る (deru)<br />だ・す (dasu)</td>
<td>
Exit
<br />
<table><tbody><tr><td>Examples:
<br />
出発 (しゅっぱつ - shuppatsu - departure) [n] <Play audio="shuppatsu" />
<br />
出納 (すいとう - suitou - receipts and expenditure) [n] <Play audio="suitou" />
<br />
出る (でる - deru - to go out, to exit) [iv] <Play audio="deru" />
<br />
出す (だす - dasu - to take out, to get out) [gv] <Play audio="dasu" />
<br />
出口 (でぐち - deguchi - exit) [n] <Play audio="deguchi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>立</span></th>
<td>リュウ (ryuu)<br />リツ (ritsu)</td>
<td>た・つ (tatsu)<br />た・てる (tateru)</td>
<td>
Stand Up
<br />
<table><tbody><tr><td>Examples:
<br />
建立 (こんりゅう - konryuu - act of building) [n] <Play audio="konryuu" />
<br />
立案 (りつあん - ritsuan - draft, rough design) [n] <Play audio="ritsuan" />
<br />
立つ (たつ - tatsu - to stand, to rise) [gv] <Play audio="tatsu" />
<br />
立てる (たてる - tateru - to stand something up) [iv] <Play audio="tateru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>休</span></th>
<td>キュウ (kyuu)</td>
<td>やす・む (yasumu)<br />やす・まる (yasumaru)<br />やす・める (yasumeru)</td>
<td>
Rest
<br />
<table><tbody><tr><td>Examples:
<br />
休憩 (きゅうけい - kyuukei - break, rest) [n] <Play audio="kyuukei" />
<br />
休む (やすむ - yasumu - to be absent, to rest, to take a break, to go to bed) [gv] <Play audio="yasumu" />
<br />
休まる (やすまる - yasumaru - to be rested) [gv] <Play audio="yasumaru" />
<br />
休める (やすめる - yasumeru - to give relief) [iv] <Play audio="yasumeru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>先</span></th>
<td>セン (sen)</td>
<td>さき (saki)</td>
<td>
Previous
<br />
<table><tbody><tr><td>Examples:
<br />
先生 (せんせい - sensei - teacher, master, doctor) [n] <Play audio="sensei" />
<br />
先 (さき - saki - previous, prior, former) [n] <Play audio="saki" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>夕</span></th>
<td>セキ (seki)</td>
<td>ゆう (yuu)</td>
<td>
Evening
<br />
<table><tbody><tr><td>Examples:
<br />
夕陽 (せきよう - sekiyou - setting sun) [n] <Play audio="sekiyou" />
<br />
夕べ (ゆうべ - yuube - evening) [n] <Play audio="yuube" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>本</span></th>
<td>ホン (hon)</td>
<td>もと (moto)</td>
<td>
Book
<br />
<table><tbody><tr><td>Examples:
<br />
日本語 (にほんご - nihongo - Japanese language) [n] <Play audio="nihongo" />
<br />
本木 (もとき - motoki - original stock) [n] <Play audio="motoki" />
<br />
山本 (やまもと - Yamamoto - common Japanese family name) <Play audio="yamamoto" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>文</span></th>
<td>モン (mon)<br />ブン (bun)</td>
<td>ふみ (fumi)</td>
<td>
Writing
<br />
<table><tbody><tr><td>Examples:
<br />
文部省 (もんぶしょう - monbushou - Ministry of Education, Culture, Sports, Science and Technology) [n] <Play audio="monbushou" />
<br />
文章 (ぶんしょう - bunshou - sentence) [n] <Play audio="bunshou" />
<br />
文月 (ふみづき - fumizuki - another word for July) [n] <Play audio="fumizuki" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>字</span></th>
<td>ジ (ji)</td>
<td>あざ (aza)</td>
<td>
Character
<br />
<table><tbody><tr><td>Examples:
<br />
漢字 (かんじ - kanji - Chinese characters) [n] <Play audio="kanji" />
<br />
字 (あざ - aza - section of village) [n] <Play audio="aza" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>学</span></th>
<td>ガク (gaku)</td>
<td>まな・ぶ (manabu)</td>
<td>
Study
<br />
<table><tbody><tr><td>Examples:
<br />
学生 (がくせい - gakusei - student) [n] <Play audio="gakusei" />
<br />
学ぶ (まなぶ - manabu - to study, to learn) [gv] <Play audio="manabu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>校</span></th>
<td>コウ (kou)</td>
<td></td>
<td>
School
<br />
<table><tbody><tr><td>Examples:
<br />
学校 (がっこう - gakkou - school) [n] <Play audio="gakkou" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>村</span></th>
<td>ソン (son)</td>
<td>むら (mura)</td>
<td>
Village
<br />
<table><tbody><tr><td>Examples:
<br />
村民 (そんみん - sonmin - villager) [n] <Play audio="sonmin" />
<br />
村 (むら - mura - village) [n] <Play audio="mura" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>町</span></th>
<td>チョウ (chou)</td>
<td>まち (machi)</td>
<td>
Town
<br />
<table><tbody><tr><td>Examples:
<br />
町名 (ちょうめい - choumei - name of a town or street) [n] <Play audio="choumei" />
<br />
町 (まち - machi - town) [n] <Play audio="machi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>森</span></th>
<td>シン (shin)</td>
<td>もり (mori)</td>
<td>
Forest
<br />
<table><tbody><tr><td>Examples:
<br />
森林 (しんりん - shinrin - forest, woods) [n] <Play audio="shinrin" />
<br />
森 (もり - mori - forest) [n] <Play audio="mori" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>正</span></th>
<td>ショウ (shou)<br />セイ (sei)</td>
<td>ただ・しい (tadashii)<br />ただ・す (tadasu)<br />まさ (masa)<br />まさ・に (masa ni)</td>
<td>
Correct
<br />
<table><tbody><tr><td>Examples:
<br />
正面 (しょうめん - shoumen - front, facade) [n] <Play audio="shoumen" />
<br />
正義 (せいぎ - seigi - justice, right, righteousness) [n] <Play audio="seigi" />
<br />
正しい (ただしい - tadashii - right, just, correct) [dv] <Play audio="tadashii" />
<br />
正す (ただす - tadasu - to correct) [gv] <Play audio="tadasu" />
<br />
正夢 (まさゆめ - masayume - dream that comes true) [n] <Play audio="masayume" />
<br />
正に (まさに - masa ni - exactly, surely, certainly) <Play audio="masa ni" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>水</span></th>
<td>スイ (sui)</td>
<td>みず (mizu)</td>
<td>
Water
<br />
<table><tbody><tr><td>Examples:
<br />
水曜日 (すいようび - suiyoubi - Wednesday) [n] <Play audio="suiyoubi" />
<br />
水 (みず - mizu - water) [n] <Play audio="mizu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>火</span></th>
<td>カ (ka)</td>
<td>ひ (hi)<br />ほ (ho)</td>
<td>
Fire
<br />
<table><tbody><tr><td>Examples:
<br />
火曜日 (かようび - kayoubi - Tuesday) [n] <Play audio="kayoubi" />
<br />
火 (ひ - hi - fire, flame) [n] <Play audio="hi" />
<br />
火影 (ほかげ - hokage - firelight, shadows or forms moving in firelight) [n] <Play audio="hokage" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>玉</span></th>
<td>ギョク (gyoku)</td>
<td>たま (tama)</td>
<td>
Ball
<br />
<table><tbody><tr><td>Examples:
<br />
玉砕 (ぎょくさい - gyokusai - honorable defeat, honorable death) [n] <Play audio="gyokusai" />
<br />
玉葱 (たまねぎ - tamanegi - onion) [n] <Play audio="tamanegi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>王</span></th>
<td>オウ (ou)</td>
<td></td>
<td>
King
<br />
<table><tbody><tr><td>Examples:
<br />
王様 (おうさま - ousama - king) [n] <Play audio="ousama" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>石</span></th>
<td>シャク (shaku)<br />セキ (seki)<br />コク (koku)</td>
<td>いし (ishi)</td>
<td>
Stone
<br />
<table><tbody><tr><td>Examples:
<br />
磁石 (じしゃく - jishaku - magnet) [n] <Play audio="jishaku" />
<br />
石油 (せきゆ - sekiyu - oil, petroleum) [n] <Play audio="sekiyu" />
<br />
石高 (こくだか - kokudaka - crop yield, salary) [n] <Play audio="kokudaka" />
<br />
石 (いし - ishi - rock, stone) [n] <Play audio="ishi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>竹</span></th>
<td>チク (chiku)</td>
<td>たけ (take)</td>
<td>
Bamboo
<br />
<table><tbody><tr><td>Examples:
<br />
竹馬 (ちくば - chikuba - stilts) [n] <Play audio="chikuba" />
<br />
竹 (たけ - take - bamboo) [n] <Play audio="take" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>糸</span></th>
<td>シ (shi)</td>
<td>いと (ito)</td>
<td>
Thread
<br />
<table><tbody><tr><td>Examples:
<br />
金糸 (きんし - kinshi - gold thread) [n] <Play audio="kinshi" />
<br />
糸 (いと - ito - thread) [n] <Play audio="ito" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>貝</span></th>
<td></td>
<td>かい (kai)</td>
<td>
Shellfish
<br />
<table><tbody><tr><td>Examples:
<br />
貝殻 (かいがら - kaigara - shell) [n] <Play audio="kaigara" />
<br />
帆立貝 (ほたてがい - hotategai - scallop) [n] <Play audio="hotategai" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>車</span></th>
<td>シャ (sha)</td>
<td>くるま (kuruma)</td>
<td>
Wheeled Vehicle
<br />
<table><tbody><tr><td>Examples:
<br />
自転車 (じてんしゃ - jitensha - bicycle) [n] <Play audio="jitensha" />
<br />
車 (くるま - kuruma - car, automobile) [n] <Play audio="kuruma" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>金</span></th>
<td>コン (kon)<br />キン (kin)</td>
<td>かね (kane)<br />かな (kana)</td>
<td>
Gold
<br />
<table><tbody><tr><td>Examples:
<br />
金剛石 (こんごうせき - kongouseki - diamond) [n] <Play audio="kongouseki" />
<br />
金曜日 (きんようび - kinyoubi - Friday) [n] <Play audio="kinyoubi" />
<br />
お金 (おかね - okane - money) [n] <Play audio="okane" />
<br />
金槌 (かなづち - kanazuchi - iron hammer) [n] <Play audio="kanazuchi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>雨</span></th>
<td>ウ (u)</td>
<td>あめ (ame)<br />あま (ama)</td>
<td>
Rain
<br />
<table><tbody><tr><td>Examples:
<br />
雷雨 (らいう - raiu - thunderstorm) [n] <Play audio="raiu" />
<br />
雨 (あめ - ame - rain) [n] <Play audio="ame" />
<br />
雨漏り (あまもり - amamori - roof leak) [n] <Play audio="amamori" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>赤</span></th>
<td>シャク (shaku)<br />セキ (seki)</td>
<td>あか (aka)<br />あか・い (akai)<br />あか・らむ (akaramu)<br />あか・らめる (akarameru)</td>
<td>
Red
<br />
<table><tbody><tr><td>Examples:
<br />
赤銅 (しゃくどう - shakudou - gold-copper alloy) [n] <Play audio="shakudou" />
<br />
赤面 (せきめん - sekimen - blushing, getting red in the face, embarassment) [n] <Play audio="sekimen" />
<br />
赤 (あか - aka - red) [n] <Play audio="aka" />
<br />
赤い (あかい - akai - red) [dv] <Play audio="akai" />
<br />
赤らむ (あからむ - akaramu - to become red, to blush) [gv] <Play audio="akaramu" />
<br />
赤らめる (あからめる - akarameru - to blush, to redden) [iv] <Play audio="akarameru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>青</span></th>
<td>ショウ (shou)<br />セイ (sei)</td>
<td>あお (ao)<br />あお・い (aoi)</td>
<td>
Blue
<br />
<table><tbody><tr><td>Examples:
<br />
朝青龍 (あさしょうりゅう - Asashouryuu - famous Sumo Wrestler) [n] <Play audio="asashouryuu" />
<br />
青少年 (せいしょうねん - seishounen - youth, young person) [n] <Play audio="seishounen" />
<br />
青 (あお - ao - blue) [n] <Play audio="ao" />
<br />
青い (あおい - aoi - blue) [dv] <Play audio="aoi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>白</span></th>
<td>ビャク (byaku)<br />ハク (haku)</td>
<td>しろ (shiro)<br />しろ・い (shiroi)<br />しら (shira)<br />しら・ける (shirakeru)<br />しら・む (shiramu)</td>
<td>
White
<br />
<table><tbody><tr><td>Examples:
<br />
白蓮 (びゃくれん - byakuren - white lotus, purity) [n] <Play audio="byakuren" />
<br />
白鳥 (はくちょう - hakuchou - swan) [n] <Play audio="hakuchou" />
<br />
白 (しろ - shiro - white) [n] <Play audio="shiro" />
<br />
白い (しろい - shiroi - white) [dv] <Play audio="shiroi" />
<br />
白髪 (しらが - shiraga - gray hair) [n] <Play audio="shiraga" />
<br />
白ける (しらける - shirakeru - ) [iv] <Play audio="shirakeru" />
<br />
白む (しらむ - shiramu - to grow light, to become white, to turn gray) [gv] <Play audio="shiramu" />
</td></tr></tbody></table></td>
</tr>
</tbody></table>

    </>
  )
}

export default GradeOneTwo