import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Time-Based Grammar"

const timebasedone = [
  {"漢字/ひらがな Kanji/Hiragana":"Base 3 + 前","Romaji (English Letters)":"Base 3 + mae","audio":"mae","English Meaning":"before verbing"},
 {"漢字/ひらがな Kanji/Hiragana":"Time + 前","Romaji (English Letters)":"Time + mae","audio":"mae","English Meaning":"before the time"},
 {"漢字/ひらがな Kanji/Hiragana":"Noun + の前","Romaji (English Letters)":"Noun + no mae","audio":"no mae","English Meaning":"before the noun"},
 {"漢字/ひらがな Kanji/Hiragana":"Base 3 + まで","Romaji (English Letters)":"Base 3 + made","audio":"made","English Meaning":"by the time of verbing(without the particle \"ni\", this means \"until the time of verbing\")"},
 {"漢字/ひらがな Kanji/Hiragana":"Time or Event + まで","Romaji (English Letters)":"Time or Event + made","audio":"made","English Meaning":"by the time or event(without the particle \"ni\", this means \"until the time or event\")"},
 {"漢字/ひらがな Kanji/Hiragana":"Base \"ta\" + 後","Romaji (English Letters)":"Base \"ta\" + ato","audio":"ato","English Meaning":"after verbing(Base \"te\" + \"kara\" (no particle needed) means the same thing)"},
 {"漢字/ひらがな Kanji/Hiragana":"Noun or Time + の後","Romaji (English Letters)":"Noun or Time + no ato","audio":"no ato","English Meaning":"after the noun or time"},
 {"漢字/ひらがな Kanji/Hiragana":"Base 3 + ごと","Romaji (English Letters)":"Base 3 + goto","audio":"goto","English Meaning":"every time I verb(Base 3 + \"tabi\" means the same thing)"},
 {"漢字/ひらがな Kanji/Hiragana":"Period of time + ごと","Romaji (English Letters)":"Period of time + goto","audio":"goto","English Meaning":"every period of time(Period of time + \"tabi\" means the same thing)"},
 {"漢字/ひらがな Kanji/Hiragana":"Base 3 + おき","Romaji (English Letters)":"Base 3 + oki","audio":"oki","English Meaning":"every other time I verb"},
 {"漢字/ひらがな Kanji/Hiragana":"Period of time + おき","Romaji (English Letters)":"Period of time + oki","audio":"oki","English Meaning":"every other period of time"},
 {"漢字/ひらがな Kanji/Hiragana":"Base 3 + 間","Romaji (English Letters)":"Base 3 + aida","audio":"aida","English Meaning":"the duration of verbing, while verbing"},
 {"漢字/ひらがな Kanji/Hiragana":"Base 3 + 内","Romaji (English Letters)":"Base 3 + uchi","audio":"uchi","English Meaning":"while verbing, at some point while verbing"},
 {"漢字/ひらがな Kanji/Hiragana":"Base 3 + 時","Romaji (English Letters)":"Base 3 + toki","audio":"toki","English Meaning":"when verbing"},
 {"漢字/ひらがな Kanji/Hiragana":"Base \"ta\" + 時","Romaji (English Letters)":"Base \"ta\" + toki","audio":"toki","English Meaning":"when verbed"}
]
const timebasedtwo = [
  {"Kanji":"","Hiragana":"また","Romaji":"mata","English Meaning":"again, and, also","audio":"mata"},
 {"Kanji":"","Hiragana":"シャワー","Romaji":"shawa-","English Meaning":"shower [n]","audio":"shawa-"},
 {"Kanji":"お土産","Hiragana":"おみやげ","Romaji":"omiyage","English Meaning":"present, souvenir [n]","audio":"omiyage"},
 {"Kanji":"生まれる","Hiragana":"うまれる","Romaji":"umareru","English Meaning":"to be born [iv]","audio":"umareru"},
 {"Kanji":"","Hiragana":"まだ","Romaji":"mada","English Meaning":"as yet, hitherto, only, still","audio":"mada"},
 {"Kanji":"毛布","Hiragana":"もうふ","Romaji":"moufu","English Meaning":"blanket [n]","audio":"moufu"}
]
const timebasedthree = [
  {"Kanji":"お腹が空くころにはご飯の準備が終わるだろう","Hiragana":"おなかがすくころにはごはんのじゅんびがおわるだろう","Romaji":"Onaka ga suku koro niwa gohan no junbi ga owaru darou","audio":"onaka ga suku koro niwa gohan no junbi ga owaru darou","English Meaning":"The food/meal should be ready by the time we get hungry."},
 {"Kanji":"午後六時ごろに夜ご飯を食べよう","Hiragana":"ごごろくじごろによるごはんをたべよう","Romaji":"Gogo rokuji goro ni yorugohan o tabeyou","audio":"gogo rokuji goro ni yorugohan o tabeyou","English Meaning":"Let's eat dinner around 6:00pm."}
]
const timebasedfour = [
  {"Kanji":"三十分くらいで帰って来て","Hiragana":"さんじゅっぷんくらいでかえってきて","Romaji":"Sanjuppun kurai de kaette kite","audio":"sanjuppun kurai de kaette kite","English Meaning":"Come home after about 30 minutes."},
 {"Kanji":"百ページぐらいの本を二時間ぐらいで読める","Hiragana":"ひゃくページぐらいのほんをにじかんぐらいでよめる","Romaji":"Hyaku pe-ji gurai no hon o nijikan gurai de yomeru","audio":"hyaku pe-ji gurai no hon o nijikan gurai de yomeru","English Meaning":"I can read a book of around 100 pages in about 2 hours or so."}
]

const TimeBased = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Intermediate" category="Grammar" lesson={title} />

      <h1>{title}</h1>

<p>Below are a number of time-specific grammatical principles such as "before verbing", "after verbing", "every time I verb", "every other time I verb", etc. Since these mark a time, they would use the particle "ni" if used as part of a sentence (except for "ato" which typically uses "de" for some reason - unless used with an exact time). Generally, the verb at the end of the sentence will determine the tense. Please note that the example sentences will all be done in plain form.</p>

<Table data={timebasedone} />

<p>Example sentences:</p>

<table>
<thead>
<tr>
<th>Japanese (In Kanji, Hiragana, and Romaji)</th>
<th>English Meaning</th>
</tr>
</thead>
<tbody>
<tr>
<td>

食べる前に手を洗う。
<br />
たべるまえにてをあらう。
<br />
<Play audio="taberu mae ni te o arau" />

</td>
<td>
I will wash my hands before eating.
</td>
</tr>
<tr>
<td>

食べる前に手を洗った。
<br />
たべるまえにてをあらった。
<br />
<Play audio="taberu mae ni te o aratta" />

</td>
<td>
I washed my hands before eating.
</td>
</tr>
<tr>
<td>

六時前に手を洗って。
<br />
ろくじまえにてをあらって。
<br />
<Play audio="rokuji mae ni te o aratte" />

</td>
<td>
Wash your hands before 6:00.
</td>
</tr>
<tr>
<td>

夜ご飯の前に手を洗って。
<br />
よるごはんのまえにてをあらって。
<br />
<Play audio="yorugohan no mae ni te o aratte" />

</td>
<td>
Wash your hands before dinner.
</td>
</tr>
<tr>
<td>

食べるまでに手を洗う。
<br />
たべるまでにてをあらう。
<br />
<Play audio="taberu made ni te o arau" />

</td>
<td>
I will wash my hands by the time we eat.
</td>
</tr>
<tr>
<td>

食べるまで手を洗う。
<br />
たべるまでてをあらう。
<br />
<Play audio="taberu made te o arau" />

</td>
<td>
I will wash my hands until we eat.
</td>
</tr>
<tr>
<td>

六時までに手を洗って。
<br />
ろくじまでにてをあらって。
<br />
<Play audio="rokuji made ni te o aratte" />

</td>
<td>
Wash your hands by 6:00.
</td>
</tr>
<tr>
<td>

夜ご飯までに手を洗って。
<br />
よるごはんまでにてをあらって。
<br />
<Play audio="yorugohan made ni te o aratte" />

</td>
<td>
Wash your hands by dinner time.
</td>
</tr>
<tr>
<td>

食べた後でまた手を洗って。
<br />
たべたあとでまたてをあらって。
<br />
<Play audio="tabeta ato de mata te o aratte" />

</td>
<td>
Wash your hands again after you eat.
</td>
</tr>
<tr>
<td>

食べてからまた手を洗って。
<br />
たべてからまたてをあらって。
<br />
<Play audio="tabete kara mata te o aratte" />

</td>
<td>
Wash your hands again after you eat.
</td>
</tr>
<tr>
<td>

食べた後で宿題をする。
<br />
たべたあとでしゅくだいをする。
<br />
<Play audio="tabeta ato de shukudai o suru" />

</td>
<td>
I will do my homework after I eat.
<br /><br />
("tabeta ato de" could be replaced by "tabete kara")
</td>
</tr>
<tr>
<td>

シャワーをした後で朝ご飯を食べた。
<br />
シャワーをしたあとであさごはんをたべた。
<br />
<Play audio="shawa- o shita ato de asagohan o tabeta" />

</td>
<td>
I ate breakfast after showering.
<br /><br />
("shita ato de" could be replaced by "shite kara")
</td>
</tr>
<tr>
<td>

六時半の後に食べよう。
<br />
ろくじはんのあとにたべよう。
<br />
<Play audio="rokujihan no ato ni tabeyou" />

</td>
<td>
Let's eat after 6:30.
</td>
</tr>
<tr>
<td>

勉強の後で食べよう。
<br />
べんきょうのあとでたべよう。
<br />
<Play audio="benkyou no ato de tabeyou" />

</td>
<td>
Let's eat after our studies.
</td>
</tr>
<tr>
<td>

走るごとに足が痛くなる。
<br />
はしるごとにあしがいたくなる。
<br />
<Play audio="hashiru goto ni ashi ga itaku naru" />

</td>
<td>
Every time I run my legs hurt.
</td>
</tr>
<tr>
<td>

走るたびに足が痛くなる。
<br />
はしるたびにあしがいたくなる。
<br />
<Play audio="hashiru tabi ni ashi ga itaku naru" />

</td>
<td>
Every time I run my legs hurt.
</td>
</tr>
<tr>
<td>

五分ごとに電話が鳴る。
<br />
ごふんごとにでんわがなる。
<br />
<Play audio="gofun goto ni denwa ga naru" />

</td>
<td>
The phone is ringing every 5 minutes.
<br /><br />
("tabi" could be used in place of "goto")
</td>
</tr>
<tr>
<td>

食べるおきにビールを飲む。
<br />
たべるおきにビールをのむ。
<br />
<Play audio="taberu oki ni bi-ru o nomu" />

</td>
<td>
I drink beer every other time I eat (with every other meal).
</td>
</tr>
<tr>
<td>

一日おきに本を読む。
<br />
いちにちおきにほんをよむ。
<br />
<Play audio="ichinichi oki ni hon o yomu" />

</td>
<td>
I read a book every other day.
<br /><br />
(the <Link to="/members/i/grammar/counters01/">Counter</Link> for "Period of days" is being used here)
</td>
</tr>
<tr>
<td>

二日おきに本を読む。
<br />
ふつかおきにほんをよむ。
<br />
<Play audio="futsuka oki ni hon o yomu" />

</td>
<td>
I read a book every third day.
<br /><br />
(the <Link to="/members/i/grammar/counters01/">Counter</Link> for "Days of the month" is being used here.  "futsuka goto ni" would be the same thing as "ichinichi oki ni" but "futsukakan goto ni" would express "every 2 days".)
</td>
</tr>
<tr>
<td>

二日間ごとに本を読む。
<br />
ふつかかんごとにほんをよむ。
<br />
<Play audio="futsukakan goto ni hon o yomu" />

</td>
<td>
I read a book every two days.
</td>
</tr>
<tr>
<td>

二日間おきに本を読む。
<br />
ふつかかんおきにほんをよむ。
<br />
<Play audio="futsukakan oki ni hon o yomu" />

</td>
<td>
I read a book every other two days.
</td>
</tr>
<tr>
<td>

この本を借りている間に読み終えるつもりです。
<br />
このほんをかりているあいだによみおえるつもりです。
<br />
<Play audio="kono hon o karite iru aida ni yomioeru tsumori desu" />

</td>
<td>
I plan on reading (finish reading) this book while I have it checked out.
<br /><br />
(Base 2 + "oeru" is the <Link to="/members/i/grammar/grammar03/">transitive</Link> form of <Link to="/members/b/grammar/bases01/">Base 2 + "owaru"</Link>.  This differs from <Link to="/members/b/grammar/bases02/">Base 2 + "nagara"</Link> in that, with "nagara", both actions are continuous - for example, "hashirinagara ongaku o kiku" = "I listen to music while I run".)
</td>
</tr>
<tr>
<td>

走っている内にお腹が痛くなった。
<br />
はしっているうちにおなかがいたくなった。
<br />
<Play audio="hashitte iru uchi ni onaka ga itaku natta" />

</td>
<td>
My stomach started hurting while I was running.
<br /><br />
This can be very similar to "aida ni" but can also differ and indicate "at some point during".
</td>
</tr>
<tr>
<td>

日本に行く時にお土産をたくさん買いたい。
<br />
にほんにいくときにおみやげをたくさんかいたい。
<br />
<Play audio="nihon ni iku toki ni omiyage o takusan kaitai" />

</td>
<td>
When I go to Japan I want to buy lots of souvenirs.
</td>
</tr>
<tr>
<td>

彼女が生まれた時からまだ赤ちゃんの毛布を使っている。
<br />
かのじょがうまれたときからまだあかちゃんのもうふをつかっている。
<br />
<Play audio="kanojo ga umareta toki kara mada akachan no moufu o tsukatte iru" />

</td>
<td>
She still uses her baby blanket since when she was born.
</td>
</tr>
</tbody></table>

<Table data={timebasedtwo} />

<h2>Gozen/Gogo</h2>

<p>These are pretty straightforward. 午前 <Play audio="gozen" /> means "AM" and 午後 <Play audio="gogo" /> means "PM". Place them in front of a time to specify. Without them, the next occurrence of that time that makes sense is implied.</p>

<h2>Mai</h2>

<p>毎 (mai) can be used with a number of different time-based words to create "every ____". For instance, 毎日 <Play audio="mainichi" /> = every day, 毎週 <Play audio="maishuu" /> = every week, 毎月 <Play audio="maitsuki" /> / <Play audio="maigetsu" /> = every month, 毎年 <Play audio="maitoshi" /> / <Play audio="mainen" /> = every year, 毎時 <Play audio="maiji" /> = every hour, 毎分 <Play audio="maifun" /> = every minute, 毎秒 <Play audio="maibyou" /> = every second, 毎回 <Play audio="maikai" /> = every time, 毎朝 <Play audio="maiasa" /> = every morning, 毎晩 <Play audio="maiban" /> = every evening, 毎食 <Play audio="maishoku" /> = every meal, etc. This is also the character for the "goto" taught above although it's typically written in hiragana in this case.</p>

<h2>Koro/Goro</h2>

<p>The word 頃 <Play audio="koro" /> means "time of year, season" and becomes <Play audio="goro" />  when used as a suffix to indicate "(approximate) time, around, about".</p>

<p>Example sentences:</p>

<Table data={timebasedthree} />

<h2>Kurai/Gurai</h2>

<p>While "goro" is used with times, <Play audio="kurai" /> / <Play audio="gurai" /> is typically used with amounts (for instance, amounts of time). They mean "approximately, about, around, or so" and can be used almost interchangeably but one will typically sound better than the other depending on the situation.</p>

<p>Example sentences:</p>

<Table data={timebasedfour} />

    </>
  )
}

export default TimeBased