import React from "react"
import {Link, navigate} from "gatsby"
import styled from "@emotion/styled"

import {StripeLogo} from "../src/components/images/StripeLogo"
import Checkout from "../src/components/Checkout"
import CheckoutLink from "../src/components/CheckoutLink"
import {logout, isPremiumUser} from "../src/utils/auth"

function handleLogout() {
  logout()
  navigate(`/`)
}

function Main() {
  return (
    <>
      <div className="titleWrapper">
        <div className="title">Welcome</div>
        <h1>Free Japanese Lessons</h1>
      </div>

      <div className="mainWrapper">
        <p className="paragraph">
          First time here? Check out the{" "}
          <Link to="/members/getting-started/">Getting Started Guide</Link>.
        </p>
        <p className="paragraph">
          Below you will find the ten public lessons, organized appropriately,
          as well as new lessons that are only available in the Members Area.
          The lessons are separated by skill level and category. Click the skill
          level to see only lessons for that skill level.
        </p>

        {isPremiumUser() ? null : (
          <>
            <p>
              <strong>
                <CheckoutLink text="Click here" /> to become a premium member
                now for unlimited access to our best Japanese audio lessons.
              </strong>
            </p>
          </>
        )}

        <table>
          <tbody>
            <tr>
              <td>🔊 = with sound</td>
              <td>🚩 = members only</td>
              <td>⭐ = premium members only</td>
            </tr>
          </tbody>
        </table>

        <table>
          <thead>
            <tr>
              <th>Beginner</th>
              <th>Intermediate</th>
              <th>Advanced</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <strong>Basics</strong>
              </td>
              <td>
                <strong>Vocabulary</strong>
              </td>
              <td>
                <strong>Kanji</strong>
              </td>
            </tr>
            <tr>
              {/*<td><Link to="/members/b/basics/names/">Names in Japanese</Link> 🔊</td>*/}
              <td></td>
              <td>
                <Link to="/members/i/vocab/sound/">Sound words</Link> 🔊 🚩
              </td>
              <td>
                <Link to="/members/a/kanji/intro">Introduction to Kanji</Link>
              </td>
            </tr>
            <tr>
              <td>
                <Link to="/members/b/basics/hiragana/">Hiragana</Link> 🔊
              </td>
              <td>
                <Link to="/members/i/vocab/business/">Business Vocab</Link> 🔊
                ⭐
              </td>
              <td>
                <strong>Grade 1 Kanji</strong>
              </td>
            </tr>
            <tr>
              <td>
                <Link to="/members/b/basics/hiragana-writing01/">
                  Writing Hiragana (A-Ko)
                </Link>{" "}
                🔊 🚩
              </td>
              <td>
                <Link to="/members/i/vocab/idioms/">
                  Idioms and Figures of Speech
                </Link>{" "}
                🔊 ⭐
              </td>
              <td>
                <Link to="/members/a/kanji/grade01-01/">Part 1 of 2</Link> 🔊 🚩
              </td>
            </tr>
            <tr>
              <td>
                <Link to="/members/b/basics/hiragana-writing02/">
                  Writing Hiragana (Sa-To)
                </Link>{" "}
                🔊 ⭐
              </td>
              <td>
                <Link to="/members/i/vocab/similar01/">
                  Words That Sound Similar
                </Link>{" "}
                🔊 ⭐
              </td>
              <td>
                <Link to="/members/a/kanji/grade01-02/">Part 2 of 2</Link> 🔊 🚩
              </td>
            </tr>
            <tr>
              <td>
                <Link to="/members/b/basics/hiragana-writing03/">
                  Writing Hiragana (Na-Ho)
                </Link>{" "}
                🔊 ⭐
              </td>
              <td>
                <Link to="/members/i/vocab/similar02/">
                  More Words That Sound Similar
                </Link>{" "}
                🔊 ⭐
              </td>
              <td>
                <strong>Grade 2 Kanji</strong>
              </td>
            </tr>
            <tr>
              <td>
                <Link to="/members/b/basics/hiragana-writing04/">
                  Writing Hiragana (Ma-Yo)
                </Link>{" "}
                🔊 ⭐
              </td>
              <td>
                <Link to="/members/i/vocab/restaurant/">
                  Going to a Restaurant
                </Link>{" "}
                🔊 ⭐
              </td>
              <td>
                <Link to="/members/a/kanji/grade02-01/">Part 1 of 4</Link> 🔊 🚩
              </td>
            </tr>
            <tr>
              <td>
                <Link to="/members/b/basics/hiragana-writing05/">
                  Writing Hiragana (Ra-N)
                </Link>{" "}
                🔊 ⭐
              </td>
              <td>
                <Link to="/members/i/vocab/body/">Parts of the Body</Link> 🔊 ⭐
              </td>
              <td>
                <Link to="/members/a/kanji/grade02-02/">Part 2 of 4</Link> 🔊 ⭐
              </td>
            </tr>
            <tr>
              <td>
                <Link to="/members/b/basics/katakana/">Katakana</Link> 🔊
              </td>
              <td>
                <Link to="/members/i/vocab/honorifics/">Honorifics</Link> 🔊 ⭐
              </td>
              <td>
                <Link to="/members/a/kanji/grade02-03/">Part 3 of 4</Link> 🔊 ⭐
              </td>
            </tr>
            <tr>
              <td>
                <strong>Vocabulary</strong>
              </td>
              <td>
                <strong>Grammar</strong>
              </td>
              <td>
                <Link to="/members/a/kanji/grade02-04/">Part 4 of 4</Link> 🔊 ⭐
              </td>
            </tr>
            <tr>
              <td>
                <Link to="/members/b/vocab/vocab01/">
                  Beginning Vocabulary Part 1
                </Link>{" "}
                🔊
              </td>
              <td>
                <Link to="/members/i/grammar/bases03/">
                  Using Verb Bases Part 3
                </Link>{" "}
                🔊 ⭐
              </td>
              <td>
                <strong>Grade 3 Kanji</strong>
              </td>
            </tr>
            <tr>
              <td>
                <Link to="/members/b/vocab/vocab02/">
                  Beginning Vocabulary Part 2
                </Link>{" "}
                🔊 ⭐
              </td>
              <td>
                <Link to="/members/i/grammar/grammar01/">
                  Dake, Shika, Tokoro, and Bakari
                </Link>{" "}
                🔊
              </td>
              <td>
                <Link to="/members/a/kanji/grade03-01/">Part 1 of 5</Link> 🔊 ⭐
              </td>
            </tr>
            <tr>
              <td>
                <Link to="/members/b/vocab/numbers/">
                  Numbers, Time, and Dates
                </Link>{" "}
                🔊
              </td>
              <td>
                <Link to="/members/i/grammar/counters01/">Counters</Link> 🔊 ⭐
              </td>
              <td>
                <Link to="/members/a/kanji/grade03-02/">Part 2 of 5</Link> 🔊 ⭐
              </td>
            </tr>
            <tr>
              <td>
                <Link to="/members/b/vocab/family/">Family Vocab</Link> 🔊 ⭐
              </td>
              <td>
                <Link to="/members/i/grammar/complex01/">
                  Complex Sentences
                </Link>{" "}
                🔊 ⭐
              </td>
              <td>
                <Link to="/members/a/kanji/grade03-03/">Part 3 of 5</Link> 🔊 ⭐
              </td>
            </tr>
            <tr>
              <td>
                <Link to="/members/b/vocab/school/">School Vocab</Link> 🔊 ⭐
              </td>
              <td>
                <Link to="/members/i/grammar/grammar02/">
                  Describing and Comparing
                </Link>{" "}
                🔊 ⭐
              </td>
              <td>
                <Link to="/members/a/kanji/grade03-04/">Part 4 of 5</Link> 🔊 ⭐
              </td>
            </tr>
            <tr>
              <td>
                <Link to="/members/b/vocab/house-kitchen/">
                  House/Kitchen Vocab
                </Link>{" "}
                🔊 ⭐
              </td>
              <td>
                <Link to="/members/i/grammar/grammar03/">
                  Transitive, Intransitive, and Passive Verbs
                </Link>{" "}
                🔊 ⭐
              </td>
              <td>
                <Link to="/members/a/kanji/grade03-05/">Part 5 of 5</Link> 🔊 ⭐
              </td>
            </tr>
            <tr>
              <td>
                <Link to="/members/b/vocab/animals-colors-shapes/">
                  Animals, Colors, and Shapes
                </Link>{" "}
                🔊 ⭐
              </td>
              <td>
                <Link to="/members/i/grammar/questions01/">
                  Questions and Uncertainty
                </Link>{" "}
                🔊 ⭐
              </td>
              <td>
                <strong>Grade 4 Kanji</strong>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Grammar</strong>
              </td>
              <td>
                <Link to="/members/i/grammar/grammar04/">
                  Time-Based Grammar
                </Link>{" "}
                🔊 ⭐
              </td>
              <td>
                <Link to="/members/a/kanji/grade04-01/">Part 1 of 5</Link> 🔊 ⭐
              </td>
            </tr>
            <tr>
              <td>
                <Link to="/members/b/grammar/basic-grammar/">
                  Basic Grammar
                </Link>{" "}
                🔊
              </td>
              <td>
                <Link to="/members/i/grammar/grammar05/">
                  Other Important Grammatical Principles
                </Link>{" "}
                🔊 ⭐
              </td>
              <td>
                <Link to="/members/a/kanji/grade04-02/">Part 2 of 5</Link> 🔊 ⭐
              </td>
            </tr>
            <tr>
              <td>
                <Link to="/members/b/grammar/godan-verbs/">
                  Verb Conjugation Part 1
                </Link>{" "}
                🔊
              </td>
              <td></td>
              <td>
                <Link to="/members/a/kanji/grade04-03/">Part 3 of 5</Link> 🔊 ⭐
              </td>
            </tr>
            <tr>
              <td>
                <Link to="/members/b/grammar/ichidan-verbs/">
                  Verb Conjugation Part 2
                </Link>{" "}
                🔊
              </td>
              <td></td>
              <td>
                <Link to="/members/a/kanji/grade04-04/">Part 4 of 5</Link> 🔊 ⭐
              </td>
            </tr>
            <tr>
              <td>
                <Link to="/members/b/grammar/bases01/">
                  Using the Verb Bases Part 1
                </Link>{" "}
                🔊
              </td>
              <td></td>
              <td>
                <Link to="/members/a/kanji/grade04-05/">Part 5 of 5</Link> 🔊 ⭐
              </td>
            </tr>
            <tr>
              <td>
                <Link to="/members/b/grammar/bases02/">
                  Using the Verb Bases Part 2
                </Link>{" "}
                🔊 ⭐
              </td>
              <td></td>
              <td>
                <strong>Grade 5 Kanji</strong>
              </td>
            </tr>
            <tr>
              <td>
                <Link to="/members/b/grammar/descriptive01/">
                  Descriptive Words Part 1
                </Link>{" "}
                🔊
              </td>
              <td></td>
              <td>
                <Link to="/members/a/kanji/grade05-01/">Part 1 of 5</Link> 🔊 ⭐
              </td>
            </tr>
            <tr>
              <td>
                <Link to="/members/b/grammar/sentence-construction/">
                  Sentence Construction
                </Link>{" "}
                🔊 🚩
              </td>
              <td></td>
              <td>
                <Link to="/members/a/kanji/grade05-02/">Part 2 of 5</Link> 🔊 ⭐
              </td>
            </tr>
            <tr>
              <td>
                <Link to="/members/b/grammar/more-particles/">
                  More Particles
                </Link>{" "}
                🔊 ⭐
              </td>
              <td></td>
              <td>
                <Link to="/members/a/kanji/grade05-03/">Part 3 of 5</Link> 🔊 ⭐
              </td>
            </tr>
            <tr>
              <td>
                <strong>Common Phrases</strong>
              </td>
              <td></td>
              <td>
                <Link to="/members/a/kanji/grade05-04/">Part 4 of 5</Link> 🔊 ⭐
              </td>
            </tr>
            <tr>
              <td>
                <Link to="/members/b/common/meeting/">Meeting Someone New</Link>{" "}
                🔊
              </td>
              <td></td>
              <td>
                <Link to="/members/a/kanji/grade05-05/">Part 5 of 5</Link> 🔊 ⭐
              </td>
            </tr>
            <tr>
              <td>
                <Link to="/members/b/common/common01/">
                  100 Most Useful Phrases (1 of 4)
                </Link>{" "}
                🔊 🚩
              </td>
              <td></td>
              <td>
                <strong>Grade 6 Kanji</strong>
              </td>
            </tr>
            <tr>
              <td>
                <Link to="/members/b/common/common02/">
                  100 Most Useful Phrases (2 of 4)
                </Link>{" "}
                🔊 ⭐
              </td>
              <td></td>
              <td>
                <Link to="/members/a/kanji/grade06-01/">Part 1 of 2</Link> 🔊 ⭐
              </td>
            </tr>
            <tr>
              <td>
                <Link to="/members/b/common/common03/">
                  100 Most Useful Phrases (3 of 4)
                </Link>{" "}
                🔊 ⭐
              </td>
              <td></td>
              <td>
                <Link to="/members/a/kanji/grade06-02/">Part 2 of 2</Link> 🔊 ⭐
              </td>
            </tr>
            <tr>
              <td>
                <Link to="/members/b/common/common04/">
                  100 Most Useful Phrases (4 of 4)
                </Link>{" "}
                🔊 ⭐
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>
                <Link to="/members/b/common/conversations/">
                  Basic Conversations
                </Link>{" "}
                🔊 ⭐
              </td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>

        {isPremiumUser() ? null : (
          <BuyBox style={{textAlign: `center`}}>
            <div className="gridblocks">
              <h2>Want our best Japanese content?</h2>
              <hr
                style={{
                  border: `1px dashed #646464`,
                  width: `50%`,
                  margin: `auto`,
                  marginTop: `10px`,
                  marginBottom: `10px`,
                  opacity: `.5`
                }}
              />
              <p>
                Enjoy <strong>unlimited access</strong> to all our high quality
                audio lessons (Beginner, Intermediate and Advanced) for a small,
                one-time upgrade payment of only:
              </p>

              <p>
                <span
                  style={{
                    color: `#f44336`,
                    fontWeight: `600`,
                    fontSize: `1.4rem`
                  }}>
                  {process.env.GATSBY_PREFERRED_MEMBERSHIP_PRICE}
                </span>{" "}
                <small>USD</small>
              </p>

              <p>
                This is a single, <strong>non-recurring</strong> fee that grants
                you <strong>lifetime access</strong> to all our Japanese
                language material and also helps us continue to improve our
                content. We provide more Japanese learning material than most
                expensive online courses for a fraction of what they charge.
              </p>
            </div>

            <div className="gridblocks" style={{marginBottom: `10px`}}>
              <Checkout />
            </div>

            <div className="gridblocks" style={{opacity: `.5`}}>
              <StripeLogo width="120" height="auto" />
            </div>
          </BuyBox>
        )}

        <button onClick={() => handleLogout()} style={{width: 100}}>
          log out
        </button>
        <div className={"copyright"}>
          © Free Japanese Lessons, {new Date().getFullYear()}. NAGEL LLC.{" "}
          <Link to="/privacy-policy/">Privacy</Link>.
        </div>
      </div>
    </>
  )
}

export default Main

const BuyBox = styled.div`
  padding: 30px;
  background-color: #262626;
  border-radius: 2px;
  color: #fff;
  display: grid;

  grid-gap: 10px;
  align-items: center;
  @media (min-width: 600px) {
    .gridblocks {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`
