import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Dake, Shika, Tokoro, and Bakari"

const dakeone = [
  {"Kanji":"荷物","Hiragana":"にもつ","Romaji":"nimotsu","English Meaning":"luggage","audio":"nimotsu"},
 {"Kanji":"持つ","Hiragana":"もつ","Romaji":"motsu","English Meaning":"to hold, to carry","audio":"motsu"},
 {"Kanji":"持って行く","Hiragana":"もっていく","Romaji":"motte iku","English Meaning":"to take somewhere","audio":"motte iku"},
 {"Kanji":"犬","Hiragana":"いぬ","Romaji":"inu","English Meaning":"dog","audio":"inu"},
 {"Kanji":"好き","Hiragana":"すき","Romaji":"suki","English Meaning":"liking, fondness","audio":"suki"},
 {"Kanji":"家族","Hiragana":"かぞく","Romaji":"kazoku","English Meaning":"family","audio":"kazoku"},
 {"Kanji":"中","Hiragana":"なか","Romaji":"naka","English Meaning":"inside, within","audio":"naka"},
 {"Kanji":"八月","Hiragana":"はちがつ","Romaji":"hachigatsu","English Meaning":"August","audio":"hachigatsu"},
 {"Kanji":"生まれる","Hiragana":"うまれる","Romaji":"umareru","English Meaning":"to be born","audio":"umareru"},
 {"Kanji":"映画","Hiragana":"えいが","Romaji":"eiga","English Meaning":"movie","audio":"eiga"},
 {"Kanji":"見に行く","Hiragana":"みにいく","Romaji":"mi ni iku","English Meaning":"go to see","audio":"mi ni iku"}
]
const daketwo = [
  {"Kanji":"醤油ラーメン","Hiragana":"しょうゆラーメン","Romaji":"shouyu ramen","English Meaning":"soy sauce ramen (the most common flavor in Japan)","audio":"shouyu ramen"},
 {"Kanji":"","Hiragana":"じゃない","Romaji":"ja nai","English Meaning":"the negative form of \"da\"/\"de aru\" (the plain form of \"desu\")","audio":"ja nai"},
 {"Kanji":"","Hiragana":"ではありません","Romaji":"dewa arimasen","English Meaning":"the negative form of \"desu\")","audio":"dewa arimasen"},
 {"Kanji":"","Hiragana":"から","Romaji":"kara","English Meaning":"from","audio":"kara"},
 {"Kanji":"歩く","Hiragana":"あるく","Romaji":"aruku","English Meaning":"to walk","audio":"aruku"},
 {"Kanji":"歩いて行く","Hiragana":"あるいていく","Romaji":"aruite iku","English Meaning":"to go by walking","audio":"aruite iku"},
 {"Kanji":"白い","Hiragana":"しろい","Romaji":"shiroi","English Meaning":"white","audio":"shiroi"},
 {"Kanji":"花","Hiragana":"はな","Romaji":"hana","English Meaning":"flower","audio":"hana"},
 {"Kanji":"","Hiragana":"ない","Romaji":"nai","English Meaning":"negative form of \"aru\"","audio":"nai"}
]
const dakethree = [
  {"Kanji":"昼","Hiragana":"ひる","Romaji":"hiru","English Meaning":"afternoon","audio":"hiru"},
 {"Kanji":"ご飯","Hiragana":"ごはん","Romaji":"gohan","English Meaning":"cooked rice, meal","audio":"gohan"},
 {"Kanji":"昼ご飯","Hiragana":"ひるごはん","Romaji":"hirugohan","English Meaning":"lunch","audio":"hirugohan"},
 {"Kanji":"","Hiragana":"だった","Romaji":"datta","English Meaning":"the base \"ta\" form of \"da\"/\"de aru\"","audio":"datta"},
 {"Kanji":"","Hiragana":"でした","Romaji":"deshita","English Meaning":"the past tense of \"desu\"","audio":"deshita"},
 {"Kanji":"","Hiragana":"いた","Romaji":"ita","English Meaning":"the base \"ta\" form of \"iru\"","audio":"ita"}
]
const dakefour = [
  {"Kanji":"水","Hiragana":"みず","Romaji":"mizu","English Meaning":"water", "audio":"mizu"},
 {"Kanji":"飲む","Hiragana":"のむ","Romaji":"nomu","English Meaning":"to drink","audio":"nomu"}
]

const DakeShika = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Intermediate" category="Grammar" lesson={title} />

      <h1>{title}</h1>

<p>This lesson explores the usage of the Japanese words <Play audio="dake" />, <Play audio="shika" />, <Play audio="tokoro" />, and <Play audio="bakari" />. These words are fairly similar in many ways and can be used to enhance the grammatical nature of your Japanese phrases. Please note that the verbs in the example sentences will be done in plain form with the polite form in parentheses.</p>

<h2>Dake</h2>

<p><Play audio="dake" /> (だけ) is a very well-used word in the Japanese language. It means "only". Here are some example sentences:</p>

<table>
<thead>
<tr>
<th>Japanese (In Kanji, Hiragana, and Romaji)</th>
<th>English Meaning</th>
</tr>
</thead>
<tbody>
<tr>
<td>

この荷物だけを持って行く(行きます)。
<br />
このにもつだけをもっていく(いきます)。
<br />
<Play audio="kono nimotsu dake o motte iku" /> (<Play audio="kono nimotsu dake o motte ikimasu" />)

</td>
<td>
I'm taking only this luggage.
</td>
</tr>
<tr>
<td>

犬だけが好きだ(です)。
<br />
いぬだけがすきだ(です)。
<br />
<Play audio="inu dake ga suki da" /> (<Play audio="inu dake ga suki desu" />)

</td>
<td>
I only like dogs.
</td>
</tr>
<tr>
<td>

家族の中で私だけが八月に生まれた(生まれました)。
<br />
かぞくのなかでわたしだけがはちがつにうまれた(うまれました)。
<br />
<Play audio="kazoku no naka de watashi dake ga hachigatsu ni umareta" /> (<Play audio="kazoku no naka de watashi dake ga hachigatsu ni umaremashita" />)

</td>
<td>
Within my family, I am the only one that was born in August.
</td>
</tr>
<tr>
<td>

映画を見に行くだけだ(です)。
<br />
えいがをみにいくだけだ(です)。
<br />
<Play audio="eiga o mi ni iku dake da" /> (<Play audio="eiga o mi ni iku dake desu" />)

</td>
<td>
I am only going to see a movie.
<br /><br />
Base 3 + dake da/desu = will only verb
</td>
</tr>
<tr>
<td>

映画を見に行っただけだ(です)。
<br />
えいがをみにいっただけだ(です)。
<br />
<Play audio="eiga o mi ni itta dake da" /> (<Play audio="eiga o mi ni itta dake desu" />)

</td>
<td>
I only went to see a movie.
<br /><br />
Base "ta" + dake da/desu = has only verbed
</td>
</tr>
<tr>
<td>

映画を見に行くだけだった(でした)。
<br />
えいがをみにいくだけだった(でした)。
<br />
<Play audio="eiga o mi ni iku dake datta" /> (<Play audio="eiga o mi ni iku dake deshita" />)

</td>
<td>
I was only going to see a movie.
<br /><br />
Base 3 + dake datta/deshita = intended to only verb
</td>
</tr>
</tbody></table>

<Table data={dakeone} />

<h2>Shika</h2>

<p><Play audio="shika" /> (しか)  is very similar to "dake"  but holds a stronger meaning and is not quite as straightforward. It basically means "nothing but" and must be used with a verb in the negative tense. Here are some example sentences:</p>

<table>
<thead>
<tr>
<th>Japanese (In Kanji, Hiragana, and Romaji)</th>
<th>English Meaning</th>
</tr>
</thead>
<tbody>
<tr>
<td>

醤油ラーメンしか好きじゃない(ではありません)。
<br />
しょうゆラーメンしかすきじゃない(ではありません)。
<br />
<Play audio="shouyu ramen shika suki ja nai" /> (<Play audio="shouyu ramen shika suki dewa arimasen" />)

</td>
<td>
I only like soy sauce ramen.<br />(I like nothing but soy sauce ramen.)
</td>
</tr>
<tr>
<td>

ここからは歩いて行くことしかできない(できません)。
<br />
ここからはあるいていくことしかできない(できません)。
<br />
<Play audio="koko kara wa aruite iku koto shika dekinai" /> (<Play audio="koko kara wa aruite iku koto shika dekimasen" />)

</td>
<td>
You can only get there from here by walking.
</td>
</tr>
<tr>
<td>

白い花しかなかった(ありませんでした)。
<br />
しろいはなしかなかった(ありませんでした)。
<br />
<Play audio="shiroi hana shika nakatta" /> (<Play audio="shiroi hana shika arimasen deshita" />)

</td>
<td>
There were only white flowers left.<br />(There was nothing but white flowers left.)
</td>
</tr>
</tbody></table>

<Table data={daketwo} />

<p>Note: "dake" and "shika" can be combined into "dake shika". It works the same as "shika" but has an even stronger meaning.</p>

<h2>Tokoro</h2>

<p><Play audio="tokoro" />  as a noun means "place" or "location". When used after a verb it can change the sentence in a number of different ways. Here are some example sentences (we will use very similar sentences so that you can see the differences):</p>

<table>
<thead>
<tr>
<th>Japanese (In Kanji, Hiragana, and Romaji)</th>
<th>English Meaning</th>
</tr>
</thead>
<tbody>
<tr>
<td>

昼ご飯を食べるところだ(です)。
<br />
ひるごはんをたべるところだ(です)。
<br />
<Play audio="hirugohan o taberu tokoro da" /> (<Play audio="hirugohan o taberu tokoro desu" />)

</td>
<td>
I am just about to eat lunch.
<br /><br />
Base 3 + tokoro da/desu = is about to verb
</td>
</tr>
<tr>
<td>

昼ご飯を食べるところだった(でした)。
<br />
ひるごはんをたべるところだった(でした)。
<br />
<Play audio="hirugohan o taberu tokoro datta" /> (<Play audio="hirugohan o taberu tokoro deshita" />)

</td>
<td>
I was just about to eat lunch.
<br /><br />
Base 3 + tokoro datta/deshita = was about to verb
</td>
</tr>
<tr>
<td>

昼ご飯を食べているところだ(です)。
<br />
ひるごはんをたべているところだ(です)。
<br />
<Play audio="hirugohan o tabete iru tokoro da" /> (<Play audio="hirugohan o tabete iru tokoro desu" />)

</td>
<td>
I am right in the middle of eating lunch.
<br /><br />
Base "te" + iru tokoro da/desu = is in the middle of verbing
</td>
</tr>
<tr>
<td>

昼ご飯を食べたところだ(です)。
<br />
ひるごはんをたべたところだ(です)。
<br />
<Play audio="hirugohan o tabeta tokoro da" /> (<Play audio="hirugohan o tabeta tokoro desu" />)

</td>
<td>
I just finished eating lunch.
<br /><br />
Base "ta" + tokoro da/desu = just finished verbing
</td>
</tr>
<tr>
<td>

昼ご飯を食べていたところだ(です)。
<br />
ひるごはんをたべていたところだ(です)。
<br />
<Play audio="hirugohan o tabete ita tokoro da" /> (<Play audio="hirugohan o tabete ita tokoro desu" />)

</td>
<td>
I have been eating lunch.
<br /><br />
Base "te" + ita tokoro da/desu = has been verbing
</td>
</tr>
</tbody></table>

<Table data={dakethree} />

<h2>Bakari</h2>

<p><Play audio="bakari" />  has similarities to both "dake"  and "tokoro"  but also has it's own unique usages. Here are some example sentences:</p>

<table>
<thead>
<tr>
<th>Japanese (In Kanji, Hiragana, and Romaji)</th>
<th>English Meaning</th>
</tr>
</thead>
<tbody>
<tr>
<td>

水ばかり飲む(飲みます)。
<br />
みずばかりのむ(のみます)。
<br />
<Play audio="mizu bakari nomu" /> (<Play audio="mizu bakari nomimasu" />)

</td>
<td>
I only drink water.
</td>
</tr>
<tr>
<td>

昼ご飯を食べるばかりだ(です)。
<br />
ひるごはんをたべるばかりだ(です)。
<br />
<Play audio="hirugohan o taberu bakari da" /> (<Play audio="hirugohan o taberu bakari desu" />)

</td>
<td>
I am just about to eat lunch.
<br /><br />
Base 3 + bakari da/desu = is about to verb
</td>
</tr>
<tr>
<td>

彼は食べてばかりいる(います)。
<br />
かれはたべてばかりいる(います)。
<br />
<Play audio="kare wa tabete bakari iru" /> (<Play audio="kare wa tabete bakari imasu" />)

</td>
<td>
The only thing he does is eat.
<br /><br />
Base "te" + bakari iru/imasu = only verbs
</td>
</tr>
<tr>
<td>

昼ご飯を食べたばかりだ(です)。
<br />
ひるごはんをたべたばかりだ(です)。
<br />
<Play audio="hirugohan o tabeta bakari da" /> (<Play audio="hirugohan o tabeta bakari desu" />)

</td>
<td>
I just finished eating lunch.
<br /><br />
Base "ta" + bakari da/desu = just finished verbing
</td>
</tr>
</tbody></table>

<Table data={dakefour} />

    </>
  )
}

export default DakeShika