import React, {useState} from "react"
import styled from "styled-components"
import {getProfile, isAuthenticated} from "../utils/auth"
import getStripe from "../utils/stripe"

const CheckoutLink = ({text}) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const redirectToCheckout = async event => {
    event.preventDefault()
    setLoading(true)
    setError(null)

    try {
      if (!isAuthenticated()) {
        throw new Error("user not authenticated")
      }

      const user = getProfile()

      const stripe = await getStripe()

      const response = await fetch(
        "/.netlify/functions/start-checkout-session",
        {method: "POST", body: user.sub}
      )
      const sessionId = await response.text()
      const {error} = await stripe.redirectToCheckout({sessionId})

      if (error) {
        throw new Error(error)
      }
    } catch (e) {
      setError(
        typeof e === "string"
          ? e
          : ((e && e.message) || "").replace(
              /stripe\.redirectToCheckout\: Invalid value for sessionId\. You specified /gi,
              ""
            ) || "unknown error"
      )
      setLoading(false)
      return
    }
  }

  return (
    <>
      {!error ? null : <div style={{marginBottom: 8}}>{error}</div>}

      <BuyButton disabled={loading} onClick={redirectToCheckout}>
        {text}
      </BuyButton>
    </>
  )
}

export default CheckoutLink

const BuyButton = styled.a`
  &:hover {
    cursor: pointer;
  }
`
