import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Idioms and Figures of Speech"

const idiomsone = [
  {"漢字 - Kanji":"悪戦苦闘","ひらがな - Hiragana":"あくせんくとう","Romaji (English Letters)":"akusenkutou","audio":"akusenkutou","English Meaning":"hard struggle, fighting against heavy odds"},
 {"漢字 - Kanji":"異口同音","ひらがな - Hiragana":"いくどうおん","Romaji (English Letters)":"ikudouon","audio":"ikudouon","English Meaning":"with one voice, unanimously"},
 {"漢字 - Kanji":"以心伝心","ひらがな - Hiragana":"いしんでんしん","Romaji (English Letters)":"ishindenshin","audio":"ishindenshin","English Meaning":"communion of mind with mind, sympathy"},
 {"漢字 - Kanji":"一網打尽","ひらがな - Hiragana":"いちもうだじん","Romaji (English Letters)":"ichimoudajin","audio":"ichimoudajin","English Meaning":"big haul, catching the whole herd with one throw"},
 {"漢字 - Kanji":"一目瞭然","ひらがな - Hiragana":"いちもくりょうぜん","Romaji (English Letters)":"ichimokuryouzen","audio":"ichimokuryouzen","English Meaning":"apparent, obvious, very clear [dn]"},
 {"漢字 - Kanji":"一生懸命","ひらがな - Hiragana":"いっしょうけんめい","Romaji (English Letters)":"isshoukenmei","audio":"isshoukenmei","English Meaning":"with utmost effort, with all one's might, for dear life [dn]"},
 {"漢字 - Kanji":"一切合切","ひらがな - Hiragana":"いっさいがっさい","Romaji (English Letters)":"issaigassai","audio":"issaigassai","English Meaning":"any and every thing, altogether, the whole shooting match, the whole kit and caboodle, without reserve"},
 {"漢字 - Kanji":"一心同体","ひらがな - Hiragana":"いっしんどうたい","Romaji (English Letters)":"isshindoutai","audio":"isshindoutai","English Meaning":"being one in body and soul, of one flesh, two hearts beating as one"},
 {"漢字 - Kanji":"一心不乱","ひらがな - Hiragana":"いっしんふらん","Romaji (English Letters)":"isshinfuran","audio":"isshinfuran","English Meaning":"wholeheartedly, with heart and soul, intently, with undivided attention, with intense concentration, single-mindedly [dn]"},
 {"漢字 - Kanji":"一石二鳥","ひらがな - Hiragana":"いっせきにちょう","Romaji (English Letters)":"issekinichou","audio":"issekinichou","English Meaning":"killing two birds with one stone"},
 {"漢字 - Kanji":"一長一短","ひらがな - Hiragana":"いっちょういったん","Romaji (English Letters)":"icchouittan","audio":"icchouittan","English Meaning":"merits and demerits, advantages and disadvantages, good points and shortcomings"},
 {"漢字 - Kanji":"感慨無量","ひらがな - Hiragana":"かんがいむりょう","Romaji (English Letters)":"kangaimuryou","audio":"kangaimuryou","English Meaning":"deep emotion, one's mind is filled with a thousand emotions [dn]"},
 {"漢字 - Kanji":"完全無欠","ひらがな - Hiragana":"かんぜんむけつ","Romaji (English Letters)":"kanzenmuketsu","audio":"kanzenmuketsu","English Meaning":"flawless, absolute perfection [dn]"},
 {"漢字 - Kanji":"喜怒哀楽","ひらがな - Hiragana":"きどあいらく","Romaji (English Letters)":"kidoairaku","audio":"kidoairaku","English Meaning":"human emotions (joy, anger, pathos, and humor)"},
 {"漢字 - Kanji":"興味津々","ひらがな - Hiragana":"きょうみしんしん","Romaji (English Letters)":"kyoumishinshin","audio":"kyoumishinshin","English Meaning":"very interesting, of absorbing interest, having a keen interest (in), being immensely curious (about)"}
]

const idiomstwo = [
  {"Kanji":"芸は身を助ける","Hiragana":"げいはみをたすける","Romaji":"Gei wa mi o tasukeru.","audio":"gei wa mi o tasukeru","English Meaning":"Art brings bread."},
 {"Kanji":"門前の小僧習わぬ経を読む","Hiragana":"もんぜんのこぞうならわぬきょうをよむ","Romaji":"Monzen no kozou narawanu kyou o yomu.","audio":"monzen no kozou narawanu kyou o yomu","English Meaning":"You learn, without realising it, from what is around you."},
 {"Kanji":"旅は道連れ世は情け","Hiragana":"たびはみちづれよはなさけ","Romaji":"Tabi wa michizure yo wa nasake.","audio":"tabi wa michizure yo wa nasake","English Meaning":"Just as it is reassuring to have a companion when traveling, it is important for us to care for each other as we pass through this life."},
 {"Kanji":"無理が通れば道理引っ込む","Hiragana":"むりがとおればどうりひっこむ","Romaji":"Muri ga tooreba douri hikkomu. )","audio":"muri ga tooreba douri hikkomu","English Meaning":"Where might is master, justice is servant."},
 {"Kanji":"油断大敵","Hiragana":"ゆだんたいてき","Romaji":"Yudan taiteki.","audio":"yudan taiteki","English Meaning":"Unpreparedness is one's greatest enemy.He that is too secure is not safe."},
 {"Kanji":"礼儀も過ぎれば失礼になる","Hiragana":"れいぎもすぎればしつれいになる","Romaji":"Reigi mo sugireba shitsurei ni naru.","audio":"reigi mo sugireba shitsurei ni naru","English Meaning":"Too much courtesy becomes rudeness."},
 {"Kanji":"得手に帆を揚げる","Hiragana":"えてにほをあげる","Romaji":"Ete ni ho o ageru.","audio":"ete ni ho o ageru","English Meaning":"Hoist your sail when the wind is fair."},
 {"Kanji":"目の上のこぶ","Hiragana": "めのうえのこぶ", "Romaji":"Me no ue no kobu","English Meaning":"a thorn in one's side, a pain in the butt", "audio":"me no ue no kobu"},
 {"Kanji":"聞いて極楽見て地獄","Hiragana":"きいてごくらくみてじごく","Romaji":"Kiite gokuraku mite jigoku.","audio":"kiite gokuraku mite jigoku","English Meaning":"A paradise on hearsay, a hell at sight."},
 {"Kanji":"可愛い子には旅をさせよ","Hiragana":"かわいいこにはたびをさせよ","Romaji":"Kawaii ko niwa tabi o saseyo.","audio":"kawaii ko niwa tabi o saseyo","English Meaning":"Spare the rod and spoil the child."},
 {"Kanji":"花より団子","Hiragana":"はなよりだんご","Romaji":"Hana yori dango.","audio":"hana yori dango","English Meaning":"People are more interested in the practical over the aesthetic."},
 {"Kanji":"身から出た錆","Hiragana":"みからでたさび","Romaji":"Mi kara deta sabi.","audio":"mi kara deta sabi","English Meaning":"paying for one's mistakes, getting one's just deserts, reap what you sow"},
 {"Kanji":"河豚は食い度し命は惜しし","Hiragana":"ふぐはくいたしいのちはおしし","Romaji":"Fugu wa kuitashi inochi wa oshishi.","audio":"fugu wa kuitashi inochi wa oshishi","English Meaning":"Honey is sweet, but the bee stings."},
 {"Kanji":"貧乏暇なし","Hiragana":"びんぼうひまなし","Romaji":"Binbou hima nashi.","audio":"binbou hima nashi","English Meaning":"There is no leisure for the poor."},
 {"Kanji":"下手の長談義","Hiragana":"へたのながだんぎ","Romaji":"Heta no nagadangi.","audio":"heta no nagadangi","English Meaning":"Brevity is the soul of wit."},
 {"Kanji":"嘘から出た誠","Hiragana":"うそからでたまこと","Romaji":"Uso kara deta makoto.","audio":"uso kara deta makoto","English Meaning":"Truth comes out of falsehood."},
 {"Kanji":"憎まれっ子世に憚る","Hiragana":"にくまれっこよにはばかる","Romaji":"Nikumarekko yo ni habakaru.","audio":"nikumarekko yo ni habakaru","English Meaning":"Ill weeds grow apace. (bad grass thrives)"},
 {"Kanji":"楽あれば苦あり","Hiragana":"らくあればくあり","Romaji":"Raku areba ku ari.","audio":"raku areba ku ari","English Meaning":"With the good comes the bad."},
 {"Kanji":"好きこそ物の上手なれ","Hiragana":"すきこそもののじょうずなれ","Romaji":"Sukikoso mono no jouzu nare.","audio":"sukikoso mono no jouzu nare","English Meaning":"What one likes, one will do well."},
 {"Kanji":"背に腹は代えられない","Hiragana":"せにはらはかえられない","Romaji":"Se ni hara wa kaerarenai.","audio":"se ni hara wa kaerarenai","English Meaning":"not able to escape an urgent problem without making some sacrifice"},
 {"Kanji":"塵積もって山となる","Hiragana":"ちりつもってやまとなる","Romaji":"Chiri tsumotte yama to naru.","audio":"chiri tsumotte yama to naru","English Meaning":"Even dust becomes a mountain when piled up."},
 {"Kanji":"転ばぬ先の杖","Hiragana":"ころばぬさきのつえ","Romaji":"Korobanu saki no tsue.","audio":"korobanu saki no tsue","English Meaning":"Prevention is better than cure."},
 {"Kanji":"糠に釘","Hiragana":"ぬかにくぎ","Romaji":"Nuka ni kugi.","audio":"nuka ni kugi","English Meaning":"All is lost that is given to a fool.It is like beating the air."},
 {"Kanji":"負けるは勝","Hiragana":"まけるはかち","Romaji":"Makeru wa kachi.","audio":"makeru wa kachi","English Meaning":"Yielding is sometimes the best way of succeeding.To stoop to conquer."},
 {"Kanji":"良薬は口に苦し","Hiragana":"りょうやくはくちににがし","Romaji":"Ryouyaku wa kuchi ni nigashi.","audio":"ryouyaku wa kuchi ni nigashi","English Meaning":"Good medicine tastes bitter.The most helpful advice may be difficult to listen to."},
 {"Kanji":"月とすっぽん","Hiragana":"つきとすっぽん","Romaji":"Tsuki to suppon.","audio":"tsuki to suppon","English Meaning":"Like the difference between heaven and hell."},
 {"Kanji":"知らぬが仏","Hiragana":"しらぬがほとけ","Romaji":"Shiranu ga hotoke.","audio":"shiranu ga hotoke","English Meaning":"Ignorance is bliss."},
 {"Kanji":"安物買いの銭失い","Hiragana":"やすものかいのぜにうしない","Romaji":"Yasumono kai no zeni ushinai.","audio":"yasumono kai no zeni ushinai","English Meaning":"You get what you pay for."},
 {"Kanji":"犬も歩けば棒に当たる","Hiragana":"いぬもあるけばぼうにあたる","Romaji":"Inu mo arukeba bou ni ataru.","audio":"inu mo arukeba bou ni ataru","English Meaning":"No matter what you attempt, tragedy may befall you."},
 {"Kanji":"泣きっ面に蜂が刺す","Hiragana":"なきっつらにはちがさす","Romaji":"Nakittsura ni hachi ga sasu.","audio":"nakittsura ni hachi ga sasu","English Meaning":"When it rains, it pours. (misfortunes seldom come alone)"},
 {"Kanji":"損して得取る","Hiragana":"そんしてとくとる","Romaji":"Son shite toku toru.","audio":"son shite toku toru","English Meaning":"You must lose a fly to catch a trout.One step back, two steps forward."},
 {"Kanji":"頭隠して尻隠さず","Hiragana":"あたまかくしてしりかくさず","Romaji":"Atama kakushite shiri kakusazu.","audio":"atama kakushite shiri kakusazu","English Meaning":"Don't cover your head and leave your bottom exposed."},
 {"Kanji":"鬼に金棒","Hiragana":"おににかなぼう","Romaji":"Oni ni kanabou.","audio":"oni ni kanabou","English Meaning":"Making a strong person even stronger."},
 {"Kanji":"論より証拠","Hiragana":"ろんよりしょうこ","Romaji":"Ron yori shouko.","audio":"ron yori shouko","English Meaning":"The proof of the pudding is in the eating."},
 {"Kanji":"喉元過ぎれば熱さを忘れる","Hiragana":"のどもとすぎればあつさをわすれる","Romaji":"Nodomoto sugireba atsusa o wasureru.","audio":"nodomoto sugireba atsusa o wasureru","English Meaning":"Danger past and God forgotten."},
 {"Kanji":"臭い物に蓋","Hiragana":"くさいものにふた","Romaji":"Kusai mono ni futa.","audio":"kusai mono ni futa","English Meaning":"Looking the other way.Solving a problem by ignoring it."},
 {"Kanji":"笑う門には福来る","Hiragana":"わらうかどにはふくきたる","Romaji":"Warau kado niwa fuku kitaru.","audio":"warau kado niwa fuku kitaru","English Meaning":"Good fortune and happiness will come to the home of those who smile."},
 {"Kanji":"出る杭は打たれる","Hiragana":"でるくいはうたれる","Romaji":"Deru kui wa utareru.","audio":"deru kui wa utareru","English Meaning":"The nail that sticks out gets hammered in.The best policy is to keep your head down."},
 {"Kanji":"骨折り損のくたびれ儲け","Hiragana":"ほねおりぞんのくたびれもうけ","Romaji":"Hone ori zon no kutabire mouke.","audio":"hone ori zon no kutabire mouke","English Meaning":"Beat one's head against a brick wall."},
 {"Kanji":"灯台下暗し","Hiragana":"とうだいもとくらし","Romaji":"Toudai motokurashi.","audio":"toudai motokurashi","English Meaning":"Can't see the forest through the trees.Under someone's very nose."}
]

const Idioms = () => {

  return (
    <>
      <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Beginner" category="Vocabulary" lesson="Idioms and Figures of Speech - 四字熟語と言葉のあや " />
     <div className={'title'}>
     Idioms and Figures of Speech - 四字熟語と言葉のあや <Play audio="yojijukugo to kotoba no aya" />
      </div>
      <h1>{title}</h1>

<p>Below are a number of Japanese 4-character idioms (四字熟語 - <Play audio="yojijukugo" /> ) and figures of speech (言葉のあや - <Play audio="kotoba no aya" /> ). Use them in conversations to really wow with your Japanese!</p>

<p><strong>Note:</strong> The 4-character idioms are nouns unless otherwise specified. The figures of speech are in plain form.</p>

<Table data={idiomsone} />

<h2>Figures of Speech</h2>

<Table data={idiomstwo} />

    </>
  )
}

export default Idioms