import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Grade 3 Kanji - Part 4 of 5"

const GradeThreeFour = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Advanced" category="Kanji" lesson={title} />
 
      <h1>{title}</h1>

      <p>Below are Kanji the Japanese learn in grade 3 of elementary school.
Be sure to read the <Link to="/members/a/kanji/intro/">Introduction to Kanji</Link> if you haven't already.</p>

<p>Legend:</p>
<p>[n] = Noun</p>
<p>[dv] = Descriptive word (true adjective) - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[dn] = Descriptive noun - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[gv] = Godan verb - see <Link to="/members/b/grammar/godan-verbs/">Verb Conjugation (Godan)</Link></p>
<p>[iv] = Ichidan verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[irv] = Irregular verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[c] = Counter</p>

<table>
<thead>
  <tr>
<th>漢字<br /><Play audio="kanji" /></th>
<th>音読み <Play audio="onyomi" /><br />"On" Reading</th>
<th>訓読み <Play audio="kunyomi" /><br />"Kun" Reading</th>
<th>English Meaning</th>
</tr>
  </thead>
<tbody>
<tr>
<th><span style={{fontSize:`4rem`}}>由</span></th>
<td>ユ (yu)<br />ユウ (yuu)<br />ユイ (yui)</td>
<td>よし (yoshi)</td>
<td>
Reason
<br />
<table><tbody><tr><td>Examples:
<br />
由来 (ゆらい - yurai - origin, source, derivation) [n] <Play audio="yurai" />
<br />
理由 (りゆう - riyuu - reason, pretext, motive) [n] <Play audio="riyuu" />
<br />
由緒 (ゆいしょ - yuisho - history, pedigree, lineage) [n] <Play audio="yuisho" />
<br />
由 (よし - yoshi - reason, significance, cause) [n] <Play audio="yoshi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>申</span></th>
<td>シン (shin)</td>
<td>もう・す (mousu)</td>
<td>
Say
<br />
<table><tbody><tr><td>Examples:
<br />
追申 (ついしん - tsuishin - postscript) [n] <Play audio="tsuishin" />
<br />
申す (もうす - mousu - to say, to be called - honorific form of 言う (iu)) [gv] <Play audio="mousu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>界</span></th>
<td>カイ (kai)</td>
<td></td>
<td>
World
<br />
<table><tbody><tr><td>Examples:
<br />
世界 (せかい - sekai - the world, society, the universe) [n] <Play audio="sekai" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>畑</span></th>
<td></td>
<td>はた (hata)<br />はたけ (hatake)</td>
<td>
Field (Agricultural)
<br />
<table><tbody><tr><td>Examples:
<br />
畑 (はた - hata - field (for fruits, vegetables, etc.), cultivated land, vegetable plot, kitchen garden, plantation) [n] <Play audio="hata" />
<br />
畑 (はたけ - hatake - field (for fruits, vegetables, etc.), cultivated land, vegetable plot, kitchen garden, plantation) [n] <Play audio="hatake" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>病</span></th>
<td>ビョウ (byou)<br />ヘイ (hei - rarely used)</td>
<td>や・む (yamu)<br />やまい (yamai)</td>
<td>
Sick
<br />
<table><tbody><tr><td>Examples:
<br />
病気 (びょうき - byouki - illness, disease, sickness) [n] <Play audio="byouki" />
<br />
病院 (びょういん - byouin - hospital) [n] <Play audio="byouin" />
<br />
病む (やむ - yamu - to fall ill, to be ill) [gv] <Play audio="yamu" />
<br />
病 (やまい - yamai - illness, disease) [n] <Play audio="yamai" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>発</span></th>
<td>ホツ (hotsu)<br />ハツ (hatsu)</td>
<td></td>
<td>
Departure
<br />
<table><tbody><tr><td>Examples:
<br />
発作 (ほっさ - hossa - fit, spasm) [n] <Play audio="hossa" />
<br />
発熱 (はつねつ - hatsunetsu - generation of heat, (attack of) fever) [n] <Play audio="hatsunetsu" />
<br />
出発 (しゅっぱつ - shuppatsu - departure) [n] <Play audio="shuppatsu" />
<br />
出発する (しゅっぱつする - shuppatsu suru - to depart) [irv] <Play audio="shuppatsu suru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>登</span></th>
<td>トウ (tou)<br />ト (to)</td>
<td>のぼ・る (noboru)</td>
<td>
Climb
<br />
<table><tbody><tr><td>Examples:
<br />
登録 (とうろく - touroku - registration, accession, register, entry, record) [n] <Play audio="touroku" />
<br />
登山 (とざん - tozan - mountain climbing) [n] <Play audio="tozan" />
<br />
登る (のぼる - noboru - to ascend, to go up, to climb) [gv] <Play audio="noboru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>皮</span></th>
<td>ヒ (hi)</td>
<td>かわ (kawa)</td>
<td>
Skin
<br />
<table><tbody><tr><td>Examples:
<br />
皮膚 (ひふ - hifu - skin) [n] <Play audio="hifu" />
<br />
皮肉 (ひにく - hiniku - cynicism, sarcasm, irony, satire) [dn] <Play audio="hiniku" />
<br />
皮 (かわ - kawa - skin, hide, pelt, fur, rind, peel, husk, bark, shell, sheath, wrapping) [n] <Play audio="kawa" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>皿</span></th>
<td></td>
<td>さら (sara)</td>
<td>
Dish
<br />
<table><tbody><tr><td>Examples:
<br />
皿 (さら - sara - plate, dish, platter, disc) [n] <Play audio="sara" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>相</span></th>
<td>ソウ (sou)<br />ショウ (shou)</td>
<td>あい (ai)</td>
<td>
Mutual
<br />
<table><tbody><tr><td>Examples:
<br />
相談 (そうだん - soudan - consultation, discussion) [n] <Play audio="soudan" />
<br />
首相 (しゅしょう - shushou - Prime Minister, Chancellor (Germany, Austria, etc.), Premier) [n] <Play audio="shushou" />
<br />
相 (あい - ai - together, mutually, fellow) (prefix) <Play audio="ai" />
<br />
相手 (あいて - aite - companion, partner, company, other party, addressee, opponent (sports, etc.)) [n] <Play audio="aite" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>県</span></th>
<td>ケン (ken)</td>
<td></td>
<td>
Prefecture
<br />
<table><tbody><tr><td>Examples:
<br />
県令 (けんれい - kenrei - prefectural ordinance) [n] <Play audio="kenrei" />
<br />
愛知県 (あいちけん - aichiken - the Aichi prefecture) [n] <Play audio="aichiken" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>真</span></th>
<td>シン (shin)</td>
<td>ま (ma)</td>
<td>
True
<br />
<table><tbody><tr><td>Examples:
<br />
真理 (しんり - shinri - truth) [n] <Play audio="shinri" />
<br />
写真 (しゃしん - shashin - photograph, photo) [n] <Play audio="shashin" />
<br />
真似 (まね - mane - mimicry, imitation, behavior, pretense) [n] <Play audio="mane" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>着</span></th>
<td>ジャク (jaku - rarely used)<br />チャク (chaku)</td>
<td>き・る (kiru)<br />き・せる (kiseru)<br />つ・く (tsuku)<br />つ・ける (tsukeru)</td>
<td>
Wear or Arrive
<br />
<table><tbody><tr><td>Examples:
<br />
試着 (しちゃく - shichaku - wearing clothes to try on) [n] <Play audio="shichaku" />
<br />
着る (きる - kiru - to wear, to put on) [iv] <Play audio="kiru" />
<br />
着せる (きせる - kiseru - to put clothes on (someone)) [iv] <Play audio="kiseru" />
<br />
着く (つく - tsuku - to arrive at, to reach a destination) [gv] <Play audio="tsuku" />
<br />
着ける (つける - tsukeru - to wear, to put on (earring, etc.)) [iv] <Play audio="tsukeru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>短</span></th>
<td>タン (tan)</td>
<td>みじか・い (mijikai)</td>
<td>
Short
<br />
<table><tbody><tr><td>Examples:
<br />
短見 (たんけん - tanken - narrow view) [n] <Play audio="tanken" />
<br />
短い (みじかい - mijikai - short) [dv] <Play audio="mijikai" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>研</span></th>
<td>ケン (ken)</td>
<td>と・ぐ (togu)</td>
<td>
Sharpen
<br />
<table><tbody><tr><td>Examples:
<br />
研究 (けんきゅう - kenkyuu - study, research, investigation) [n] <Play audio="kenkyuu" />
<br />
研ぐ (とぐ - togu - to sharpen, to hone, to whet, to grind) [gv] <Play audio="togu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>礼</span></th>
<td>ライ (rai)<br />レイ (rei)</td>
<td></td>
<td>
Thanks
<br />
<table><tbody><tr><td>Examples:
<br />
礼賛 (らいさん - raisan - praise, worship, adoration, glorification) [n] <Play audio="raisan" />
<br />
礼拝 (れいはい - reihai - worship, adoration, divine service) [n] <Play audio="reihai" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>神</span></th>
<td>ジン (jin)<br />シン (shin)</td>
<td>かみ (kami)<br />かん (kan)<br />こう (kou)</td>
<td>
God(s)
<br />
<table><tbody><tr><td>Examples:
<br />
神社 (じんじゃ - jinja - Shinto shrine) [n] <Play audio="jinja" />
<br />
神話 (しんわ - shinwa - myth, legend) [n] <Play audio="shinwa" />
<br />
神 (かみ - kami - god, deity) [n] <Play audio="kami" />
<br />
神様 (かみさま - kamisama - God) [n] <Play audio="kamisama" />
<br />
神無月 (かんなづき - kannazuki - tenth month of the lunar calendar (October)) [n] <Play audio="kannazuki" />
<br />
神戸 (こうべ - koube - Kobe (port city near Osaka)) [n] <Play audio="koube" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>祭</span></th>
<td>サイ (sai)</td>
<td>まつ・る (matsuru)<br />まつ・り (matsuri)</td>
<td>
Festival
<br />
<table><tbody><tr><td>Examples:
<br />
祭日 (さいじつ - saijitsu - national holiday, festival day) [n] <Play audio="saijitsu" />
<br />
祭る (まつる - matsuru - to deify, to enshrine, to pray, to worship) [gv] <Play audio="matsuru" />
<br />
祭り (まつり - matsuri - festival) [n] <Play audio="matsuri" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>福</span></th>
<td>フク (fuku)</td>
<td></td>
<td>
Luck
<br />
<table><tbody><tr><td>Examples:
<br />
福利 (ふくり - fukuri - welfare) [n] <Play audio="fukuri" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>秒</span></th>
<td>ビョウ (byou)</td>
<td></td>
<td>
Second
<br />
<table><tbody><tr><td>Examples:
<br />
秒速 (びょうそく - byousoku - per second) [n] <Play audio="byousoku" />
<br />
三秒 (さんびょう - sanbyou - 3 seconds) [n] <Play audio="sanbyou" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>究</span></th>
<td>キュウ (kyuu)</td>
<td>きわ・める (kiwameru)<br />きわ・まる (kiwamaru - rarely used)</td>
<td>
Research
<br />
<table><tbody><tr><td>Examples:
<br />
追究 (ついきゅう - tsuikyuu - investigation (e.g. academically, of the unknown), inquiry) [n] <Play audio="tsuikyuu" />
<br />
研究 (けんきゅう - kenkyuu - study, research, investigation) [n] <Play audio="kenkyuu" />
<br />
究める (きわめる - kiwameru - to investigate thoroughly, to master) [iv] <Play audio="kiwameru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>章</span></th>
<td>ショウ (shou)</td>
<td></td>
<td>
Chapter
<br />
<table><tbody><tr><td>Examples:
<br />
文章 (ぶんしょう - bunshou - sentence, article, composition) [n] <Play audio="bunshou" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>童</span></th>
<td>ドウ (dou)</td>
<td>わらべ (warabe)</td>
<td>
Juvenile
<br />
<table><tbody><tr><td>Examples:
<br />
童話 (どうわ - douwa - fairy-tale) [n] <Play audio="douwa" />
<br />
童 (わらべ - warabe - child) [n] <Play audio="warabe" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>笛</span></th>
<td>テキ (teki)</td>
<td>ふえ (fue)</td>
<td>
Whistle
<br />
<table><tbody><tr><td>Examples:
<br />
汽笛 (きてき - kiteki - steam whistle) [n] <Play audio="kiteki" />
<br />
笛 (ふえ - fue - flute, pipe, whistle) [n] <Play audio="fue" />
<br />
口笛 (くちぶえ - kuchibue - whistle (from mouth)) [n] <Play audio="kuchibue" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>第</span></th>
<td>ダイ (dai)</td>
<td></td>
<td>
Ordinal number prefix
<br />
<table><tbody><tr><td>Examples:
<br />
落第 (らくだい - rakudai - failure, dropping out of a class) [n] <Play audio="rakudai" />
<br />
第一 (だいいち - daiichi - first, foremost, number one) [n] <Play audio="daiichi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>筆</span></th>
<td>ヒツ (hitsu)</td>
<td>ふで (fude)</td>
<td>
Writing Brush
<br />
<table><tbody><tr><td>Examples:
<br />
毛筆 (もうひつ - mouhitsu - (writing, painting) brush) [n] <Play audio="mouhitsu" />
<br />
鉛筆 (えんぴつ - enpitsu/empitsu - pencil) [n] <Play audio="enpitsu" />
<br />
筆 (ふで - fude - writing brush) [n] <Play audio="fude" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>等</span></th>
<td>トウ (tou)</td>
<td>ひと・しい　(hitoshii)</td>
<td>
Class
<br />
<table><tbody><tr><td>Examples:
<br />
等分 (とうぶん - toubun - division into equal parts) [n] <Play audio="toubun" />
<br />
等しい (ひとしい - hitoshii - equal, similar, like, equivalent) [dv] <Play audio="hitoshii" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>箱</span></th>
<td></td>
<td>はこ (hako)</td>
<td>
Box
<br />
<table><tbody><tr><td>Examples:
<br />
箱 (はこ - hako - box) [n] <Play audio="hako" />
<br />
筆箱 (ふでばこ - fudebako - pencil box) [n] <Play audio="fudebako" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>級</span></th>
<td>キュウ (kyuu)</td>
<td></td>
<td>
Rank
<br />
<table><tbody><tr><td>Examples:
<br />
初級 (しょきゅう - shokyuu - elementary level) [n] <Play audio="shokyuu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>終</span></th>
<td>シュウ (shuu)</td>
<td>お・わる (owaru)<br />お・える (oeru)<br />お・わり (owari)</td>
<td>
End
<br />
<table><tbody><tr><td>Examples:
<br />
終了 (しゅうりょう - shuuryou - end, close, termination) [n] <Play audio="shuuryou" />
<br />
終わる (おわる - owaru - to finish, to end, to close) [gv] <Play audio="owaru" />
<br />
終える (おえる - oeru - to finish (something)) [iv] <Play audio="oeru" />
<br />
終わり (おわり - owari - the end) [n] <Play audio="owari" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>緑</span></th>
<td>ロク (roku)<br />リョク (ryoku)</td>
<td>みどり (midori)</td>
<td>
Green
<br />
<table><tbody><tr><td>Examples:
<br />
緑青 (ろくしょう - rokushou - verdigris, green rust, copper rust) [n] <Play audio="rokushou" />
<br />
緑内障 (りょくないしょう - ryokunaishou - glaucoma) [n] <Play audio="ryokunaishou" />
<br />
緑 (みどり - midori - green, greenery) [n] <Play audio="midori" />
<br />
黄緑 (きみどり - kimidori - pea green, yellow-green) [n] <Play audio="kimidori" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>練</span></th>
<td>レン (ren)</td>
<td>ね・る (neru)</td>
<td>
Practice
<br />
<table><tbody><tr><td>Examples:
<br />
試練 (しれん - shiren - test, trial, probation, ordeal, tribulation) [n] <Play audio="shiren" />
<br />
練習 (れんしゅう - renshuu - practice) [n] <Play audio="renshuu" />
<br />
練る (ねる - neru - to knead, to work over, to polish up (e.g. a plan), to drill, to train) [gv] <Play audio="neru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>羊</span></th>
<td>ヨウ (you)</td>
<td>ひつじ (hitsuji)</td>
<td>
Sheep
<br />
<table><tbody><tr><td>Examples:
<br />
羊毛 (ようもう - youmou - wool) [n] <Play audio="youmou" />
<br />
羊 (ひつじ - hitsuji - sheep) [n] <Play audio="hitsuji" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>美</span></th>
<td>ビ (bi)</td>
<td>うつく・しい (utsukushii)</td>
<td>
Beauty
<br />
<table><tbody><tr><td>Examples:
<br />
美人 (びじん - bijin - beautiful person (woman)) [n] <Play audio="bijin" />
<br />
美しい (うつくしい - utsukushii - beautiful, lovely) [dv] <Play audio="utsukushii" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>習</span></th>
<td>シュウ (shuu)</td>
<td>なら・う (narau)<br />なら・い (narai)<br />なら・わし (narawashi)</td>
<td>
Learn
<br />
<table><tbody><tr><td>Examples:
<br />
練習 (れんしゅう - renshuu - practice) [n] <Play audio="renshuu" />
<br />
復習 (ふくしゅう - fukushuu - review, revision) [n] <Play audio="fukushuu" />
<br />
習う (ならう - narau - to learn) [gv] <Play audio="narau" />
<br />
習い (ならい - narai - as is habit, the way life normally is) [n] <Play audio="narai" />
<br />
習わし (ならわし - narawashi - customary practice, habit, traditional event) [n] <Play audio="narawashi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>者</span></th>
<td>シャ (sha)</td>
<td>もの (mono)</td>
<td>
Someone
<br />
<table><tbody><tr><td>Examples:
<br />
医者 (いしゃ - isha - (medical) doctor, physician) [n] <Play audio="isha" />
<br />
者 (もの - mono - person (rarely used without a qualifier)) [n] <Play audio="mono" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>育</span></th>
<td>イク (iku)</td>
<td>そだ・つ (sodatsu)<br />そだ・てる (sodateru)</td>
<td>
Nurture
<br />
<table><tbody><tr><td>Examples:
<br />
育児 (いくじ - ikuji - childcare, nursing, upbringing) [n] <Play audio="ikuji" />
<br />
保育園 (ほいくえん - hoikuen - nursery school, day nursery) [n] <Play audio="hoikuen" />
<br />
育つ (そだつ - sodatsu - to be raised (e.g. child), to be brought up, to grow (up)) [gv] <Play audio="sodatsu" />
<br />
育てる (そだてる - sodateru - to raise, to rear, to bring up) [iv] <Play audio="sodateru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>苦</span></th>
<td>ク (ku)</td>
<td>くる・しい (kurushii)<br />くる・しむ (kurushimu)<br />くる・しめる (kurushimeru)<br />にが・い (nigai)<br />にが・る (nigaru)</td>
<td>
Suffer
<br />
<table><tbody><tr><td>Examples:
<br />
苦難 (くなん - kunan - suffering, distress, hardship, trial) [n] <Play audio="kunan" />
<br />
苦しい (くるしい - kurushii - painful, difficult) [dv] <Play audio="kurushii" />
<br />
苦しむ (くるしむ - kurushimu - to suffer, to groan, to be worried) [gv] <Play audio="kurushimu" />
<br />
苦しめる (くるしめる - kurushimeru - to torment, to harass, to inflict pain) [iv] <Play audio="kurushimeru" />
<br />
苦い (にがい - nigai - bitter) [dv] <Play audio="nigai" />
<br />
苦る (にがる - nigaru - to feel bitter, to scowl) [gv] <Play audio="nigaru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>荷</span></th>
<td>カ (ka)</td>
<td>に (ni)</td>
<td>
Luggage
<br />
<table><tbody><tr><td>Examples:
<br />
出荷 (しゅっか - shukka - shipping, outgoing freight, forwarding) [n] <Play audio="shukka" />
<br />
荷物 (にもつ - nimotsu - luggage, baggage) [n] <Play audio="nimotsu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>落</span></th>
<td>ラク (raku)</td>
<td>お・ちる (ochiru)<br />お・とす (otosu)</td>
<td>
Fall
<br />
<table><tbody><tr><td>Examples:
<br />
転落 (てんらく - tenraku - fall, degradation, slump, spill) [n] <Play audio="tenraku" />
<br />
落ちる (おちる - ochiru - to fall down) [iv] <Play audio="ochiru" />
<br />
落とす (おとす - otosu - to drop, to let fall) [gv] <Play audio="otosu" />
</td></tr></tbody></table></td>
</tr>
</tbody></table>

    </>
  )
}

export default GradeThreeFour