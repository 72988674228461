import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"

const title = "More Particles"

const moreparticles1 = [
  {"Kanji":"日本で会おう","Hiragana":"にほんであおう","Romaji":"Nihon de aou. ", "English Meaning": "Let's meet in Japan.", "audio":"nihon de aou"},
 {"Kanji":"後二時間で学校が終わる","Hiragana":"あとにじかんでがっこうがおわる","Romaji":"Ato nijikan de gakkou ga owaru. ", "English Meaning": "School ends in 2 hours.","audio":"ato nijikan de gakkou ga owaru"},
 {"Kanji":"来月で結婚して二十年になる","Hiragana":"らいげつでけっこんしてにじゅうねんになる","Romaji":"Raigetsu de kekkon shite nijuunen ni naru. ", "English Meaning": "We will have been married 20 years next month.","audio":"raigetsu de kekkon shite nijuunen ni naru"},
 {"Kanji":"紙でこの帽子を作った","Hiragana":"かみでこのぼうしをつくった","Romaji":"Kami de kono boushi o tsukutta. ", "English Meaning": "I made this hat out of paper.","audio":"kami de kono boushi o tsukutta"}
]
const moreparticles2 = [
  {"Kanji":"後","Hiragana":"あと","Romaji":"ato","English Meaning":"after...","audio":"ato"},
 {"Kanji":"二時間","Hiragana":"にじかん","Romaji":"nijikan","English Meaning":"two hours","audio":"nijikan"},
 {"Kanji":"学校","Hiragana":"がっこう","Romaji":"gakkou","English Meaning":"school","audio":"gakkou"},
 {"Kanji":"終わる","Hiragana":"おわる","Romaji":"owaru","English Meaning":"to end","audio":"owaru"},
 {"Kanji":"来月","Hiragana":"らいげつ","Romaji":"raigetsu","English Meaning":"next month","audio":"raigetsu"},
 {"Kanji":"結婚する","Hiragana":"けっこんする","Romaji":"kekkon suru","English Meaning":"to get married","audio":"kekkon suru"},
 {"Kanji":"二十年","Hiragana":"にじゅうねん","Romaji":"nijuunen","English Meaning":"twenty years","audio":"nijuunen"},
 {"Kanji":"紙","Hiragana":"かみ","Romaji":"kami","English Meaning":"paper","audio":"kami"},
 {"Kanji":"帽子","Hiragana":"ぼうし","Romaji":"boushi","English Meaning":"hat","audio":"boushi"},
 {"Kanji":"作る","Hiragana":"つくる","Romaji":"tsukuru","English Meaning":"to make something","audio":"tsukuru"}
]
const moreparticles3 = [
  {
  "Kanji": "彼も店に行く",
  "Hiragana": "かれもみせにいく",
  "Romaji": "Kare mo mise ni iku.", 
  "English Meaning": "He is also going to the store.",
  "audio": "kare mo mise ni iku"
},
 {
   "Kanji": "フランスにもスペインにも行きたい",
   "Hiragana": "フランスにもスペインにもいきたい",
   "Romaji": "Furansu nimo supein nimo ikitai.", 
   "English Meaning": "I want to go to France as well as to Spain.",
   "audio": "furansu nimo supein nimo ikitai"
  },
 {
   "Kanji": "この電話は地下でも使える",
   "Hiragana": "このでんわはちかでもつかえる",
   "Romaji": "Kono denwa wa chika demo tsukaeru.", 
   "English Meaning": "This phone can be used within the basement as well.",
   "audio": "kono denwa wa chika demo tsukaeru"
  }
]
const moreparticles4 = [
  {"Kanji":"店","Hiragana":"みせ","Romaji":"mise","English Meaning":"store, shop","audio":"mise"},
 {"Kanji":"フランス","Hiragana":"フランス","Romaji":"furansu","English Meaning":"France","audio":"furansu"},
 {"Kanji":"スペイン","Hiragana":"スペイン","Romaji":"supein","English Meaning":"Spain","audio":"supein"},
 {"Kanji":"電話","Hiragana":"でんわ","Romaji":"denwa","English Meaning":"telephone","audio":"denwa"},
 {"Kanji":"地下","Hiragana":"ちか","Romaji":"chika","English Meaning":"basement","audio":"chika"},
 {"Kanji":"使う","Hiragana":"つかう","Romaji":"tsukau","English Meaning":"to use","audio":"tsukau"}
]
const moreparticles5 = [
  {"Kanji":"彼と私は店に行く","Hiragana":"かれとわたしはみせにいく","Romaji":"Kare to watashi wa mise ni iku. ","audio":"kare to watashi wa mise ni iku"},
 {"Kanji":"彼は私と店に行く","Hiragana":"かれはわたしとみせにいく","Romaji":"Kare wa watashi to mise ni iku. ","audio":"kare wa watashi to mise ni iku"},
 {"Kanji":"私は彼が店に行くと聞いた","Hiragana":"わたしはかれがみせにいくときいた","Romaji":"Watashi wa kare ga mise ni iku to kiita. ","audio":"watashi wa kare ga mise ni iku to kiita"}
]
const moreparticles6 = [
  {"Kanji":"彼女は学校から歩いた","Hiragana":"かのじょはがっこうからあるいた","Romaji":"Kanojo wa gakkou kara aruita. ","audio":"kanojo wa gakkou kara aruita"},
 {"Kanji":"子供たちは三時から遊んでいる","Hiragana":"こどもたちはさんじからあそんでいる","Romaji":"Kodomotachi wa sanji kara asonde iru. ","audio":"kodomotachi wa sanji kara asonde iru"},
 {"Kanji":"サッカーをしたいから公園に行こう","Hiragana":"サッカーをしたいからこうえんにいこう","Romaji":"Sakka- o shitai kara kouen ni ikou. ","audio":"sakka- o shitai kara kouen ni ikou"}
]
const moreparticles7 = [
  {"Kanji":"歩く","Hiragana":"あるく","Romaji":"aruku","English Meaning":"to walk","audio":"aruku"},
 {"Kanji":"子供たち","Hiragana":"こどもたち","Romaji":"kodomotachi","English Meaning":"children","audio":"kodomotachi"},
 {"Kanji":"遊ぶ","Hiragana":"あそぶ","Romaji":"asobu","English Meaning":"to play","audio":"asobu"},
 {"Kanji":"サッカー","Hiragana":"サッカー","Romaji":"sakka-","English Meaning":"soccer","audio":"sakka-"},
 {"Kanji":"公園","Hiragana":"こうえん","Romaji":"kouen","English Meaning":"park","audio":"kouen"}
]
const moreparticles8 = [
  {"Kanji":"彼女は学校まで歩いた","Hiragana":"かのじょはがっこうまであるいた","Romaji":"Kanojo wa gakkou made aruita. ","audio":"kanojo wa gakkou made aruita"},
 {"Kanji":"彼女は家から学校まで一人で歩いた","Hiragana":"かのじょはいえからがっこうまでひとりであるいた","Romaji":"Kanojo wa ie kara gakkou made hitori de aruita. ","audio":"kanojo wa ie kara gakkou made hitori de aruita"},
 {"Kanji":"子供たちは四時まで遊んだ","Hiragana":"こどもたちはよじまであそんだ","Romaji":"Kodomotachi wa yoji made asonda. ","audio":"kodomotachi wa yoji made asonda"},
 {"Kanji":"子供たちは三時から四時まで遊んだ","Hiragana":"こどもたちはさんじからよじまであそんだ","Romaji":"Kodomotachi wa sanji kara yoji made asonda. ","audio":"kodomotachi wa sanji kara yoji made asonda"}
]
const moreparticles9 = [
  {"Kanji":"家","Hiragana":"いえ","Romaji":"ie","English Meaning":"house","audio":"ie"},
 {"Kanji":"一人","Hiragana":"ひとり","Romaji":"hitori","English Meaning":"one person","audio":"hitori"},
 {"Kanji":"一人で","Hiragana":"ひとりで","Romaji":"hitori de","English Meaning":"by oneself","audio":"hitori de"}
]
const moreparticles10 = [
  {"Kanji":"サッカーをしたいので公園に行こう","Hiragana":"サッカーをしたいのでこうえんにいこう","Romaji":"Sakka- o shitai node kouen ni ikou. ","audio":"sakka- o shitai node kouen ni ikou"},
 {"Kanji":"交通事故にあったので自転車を使わなくてはならない","Hiragana":"こうつうじこにあったのでじてんしゃをつかわなくてはならない","Romaji":"koutsuujiko ni atta node jitensha o tsukawanakute wa naranai","audio":"koutsuujiko ni atta node jitensha o tsukawanakute wa naranai"}
]
const moreparticles11 = [
  {"Kanji":"交通","Hiragana":"こうつう","Romaji":"koutsuu","English Meaning":"traffic","audio":"koutsuu"},
 {"Kanji":"事故","Hiragana":"じこ","Romaji":"jiko","English Meaning":"accident","audio":"jiko"},
 {"Kanji":"交通事故","Hiragana":"こうつうじこ","Romaji":"koutsuujiko","English Meaning":"traffic accident","audio":"koutsuujiko"},
 {"Kanji":"交通事故にあう","Hiragana":"こうつうじこにあう","Romaji":"koutsuujiko ni au","English Meaning":"to get into a traffic accident","audio":"koutsuujiko ni au"},
 {"Kanji":"自転車","Hiragana":"じてんしゃ","Romaji":"jitensha","English Meaning":"bicycle","audio":"jitensha"}
]

const MoreParticles = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Beginner" category="Grammar" lesson={title} />
     <div className={'title'}>
     {title}
      </div>
      <h1>{title}</h1>

<p><strong>Attention:</strong> Be sure to study the <Link to="/members/b/grammar/basic-grammar/">Basic Grammar</Link> and <Link to="/members/b/grammar/sentence-construction/">Sentence Construction</Link> lessons before moving on to this one.</p>

<h2>The particle "de" (で)</h2>

<p>The particle "de" can be used to mark the location of an action, the time of an action, or the means of an action. When dealing with time, it differs from the particle "ni" in that "ni" deals with a precise time ("at 3 o'clock", for instance) whereas the particle "de" typically deals with amounts of time or statements like "as of a particular time".</p>

<p>Here are some example sentences (all in plain form):</p>

<Table data={moreparticles1} />

<h3>Vocab Breakdown</h3>

<Table data={moreparticles2} />

<h2>The particle "mo" (も)</h2>

<p>The particle "mo" can be used in place of "wa" and "ga". It means "also", "too", or "as well". It can also be combined with the particle "ni" or the particle "de" to add an "also" meaning to these particles.</p>

<p><strong>Note:</strong> See <Link to="/members/b/grammar/bases02/">Using Verb Bases - Part 2</Link> for more uses of "mo".</p>

<p>Here are some example sentences (all in plain form):</p>

<Table data={moreparticles3} />

<h3>Vocab Breakdown</h3>

<Table data={moreparticles4} />

<h2>The particle "to" (と)</h2>

<p>The particle "to" can mean "with" and can be used to mark something heard, said, or thought. "To" can also mean "and". The meaning depends on placement. If it's used between two nouns and before a topic, subject, or direct object marker then it is probably "and". If it's right in front of the verb "kiku" (to hear), "iu" (to say), or "omou" (to think) then it is likely marking something heard, said, or thought. Otherwise, it is probably acting as "with".</p>

<p><strong>Note:</strong> See <Link to="/members/b/grammar/sentence-construction/">Sentence Construction</Link> for more information on "to".</p>

<p>Here are some example sentences (all in plain form):</p>

<Table data={moreparticles5} />

<h2>The particle "kara" (から)</h2>

<p>The particle "kara" means "from". "Kara" can also mean "because" and "empty" (when used as a descriptive noun - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link>).
Note: See <Link to="/members/b/grammar/bases02/">Using Verb Bases - Part 2</Link> for more uses of "kara".</p>

<p>Here are some example sentences (all in plain form):</p>

<Table data={moreparticles6} />

<h3>Vocab Breakdown</h3>

<Table data={moreparticles7} />

<h2>The particle "made" (まで)</h2>

<p>The particle "made" means "until". Similar to "ni" when acting as a direction marker, "made" can act as a location marker.</p>

<p>Here are some example sentences (all in plain form):</p>

<Table data={moreparticles8} />

<h3>Vocab Breakdown</h3>

<Table data={moreparticles9} />

<h2>"Node" (ので)</h2>

<p>"Node" isn't a particle but it is another (and more typically used) way of saying "because" so we thought this would be a good lesson to address it.</p>

<p>Here are some example sentences (all in plain form):</p>

<Table data={moreparticles10} />

<h3>Vocab Breakdown</h3>

<Table data={moreparticles11} />

    </>
  )
}

export default MoreParticles