import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import LetterNav from "../../components/LetterNav"

const title = "Names In Japanese"

export const namesA = [
  {"Name": "Aaron", "Katakana": "アーロン", "Romaji": "a-ron", "audio": "a-ron"},
  {"Name": "Abdul", "Katakana": "アブドゥル", "Romaji": "abuduru", "audio": "abuduru"},
  {"Name": "Adam", "Katakana": "アダム", "Romaji": "adamu", "audio": "adamu"},
  {"Name": "Adrian", "Katakana": "エイドリアン", "Romaji": "eidorian", "audio": "eidorian"},
  {"Name": "Adriana", "Katakana": "エイドリアナ", "Romaji": "eidoriana", "audio": "eidoriana"},
  {"Name": "Ahmed", "Katakana": "アーメド", "Romaji": "a-medo", "audio": "a-medo"},
  {"Name": "Alan/Allan", "Katakana": "アラン", "Romaji": "aran", "audio": "aran"},
  {"Name": "Albert", "Katakana": "アルバート", "Romaji": "aruba-to", "audio": "aruba-to"},
  {"Name": "Alejandro", "Katakana": "アレハンドロ", "Romaji": "arehandoro", "audio": "arehandoro"},
  {"Name": "Alex", "Katakana": "アレックス", "Romaji": "arekkusu", "audio": "arekkusu"},
  {"Name": "Alexander", "Katakana": "アレキサンダー", "Romaji": "arekisanda-", "audio": "arekisanda-"},
  {"Name": "Alexandra", "Katakana": "アレキサンドラ", "Romaji": "arekisandora", "audio": "arekisandora"},
  {"Name": "Alexandria", "Katakana": "アレキサンドリア", "Romaji": "arekisandoria", "audio": "arekisandoria"},
  {"Name": "Alexis", "Katakana": "アレクシス", "Romaji": "arekushisu", "audio": "arekushisu"},
  {"Name": "Ali", "Katakana": "アリ", "Romaji": "ari", "audio": "ari"},
  {"Name": "Alice", "Katakana": "アリス", "Romaji": "arisu", "audio": "arisu"},
  {"Name": "Alicia", "Katakana": "アリシア", "Romaji": "arishia", "audio": "arishia"},
  {"Name": "Allison/Alison", "Katakana": "アリソン", "Romaji": "arison", "audio": "arison"},
  {"Name": "Alyssa", "Katakana": "アリサ", "Romaji": "arisa", "audio": "arisa"},
  {"Name": "Amanda", "Katakana": "アマンダ", "Romaji": "amanda", "audio": "amanda"},
  {"Name": "Amber", "Katakana": "アンバー", "Romaji": "amba-", "audio": "amba-"},
  {"Name": "Amy/Aimee", "Katakana": "エイミー", "Romaji": "eimi-", "audio": "eimi-"},
  {"Name": "Andre", "Katakana": "アンドレ", "Romaji": "andore", "audio": "andore"},
  {"Name": "Andrea", "Katakana": "アンドレア", "Romaji": "andorea", "audio": "andorea"},
  {"Name": "Andrew", "Katakana": "アンドリュー", "Romaji": "andoryu-", "audio": "andoryu-"},
  {"Name": "Andy", "Katakana": "アンディー", "Romaji": "andi-", "audio": "andi-"},
  {"Name": "Angel", "Katakana": "エンジェル", "Romaji": "enjeru", "audio": "enjeru"},
  {"Name": "Angela", "Katakana": "アンジェラ", "Romaji": "anjera", "audio": "anjera"},
  {"Name": "Angelica", "Katakana": "アンジェリカ", "Romaji": "anjerika", "audio": "anjerika"},
  {"Name": "Anita", "Katakana": "アニタ", "Romaji": "anita", "audio": "anita"},
  {"Name": "Anna/Ana", "Katakana": "アンナ", "Romaji": "anna", "audio": "anna"},
  {"Name": "Anne/Ann", "Katakana": "アン", "Romaji": "an", "audio": "an"},
  {"Name": "Annie", "Katakana": "アニー", "Romaji": "ani-", "audio": "ani-"},
  {"Name": "Anthony", "Katakana": "アンソニー", "Romaji": "ansoni-", "audio": "ansoni-"},
  {"Name": "Antonio", "Katakana": "アントニオ", "Romaji": "antonio", "audio": "antonio"},
  {"Name": "April", "Katakana": "エイプル", "Romaji": "eipuru", "audio": "eipuru"},
  {"Name": "Ariel", "Katakana": "アリエル", "Romaji": "arieru", "audio": "arieru"},
  {"Name": "Arthur", "Katakana": "アーサー", "Romaji": "a-sa-", "audio": "a-sa-"},
  {"Name": "Ashley", "Katakana": "アシュリー", "Romaji": "ashuri-", "audio": "ashuri-"},
  {"Name": "Austin", "Katakana": "オーステン", "Romaji": "o-suten", "audio": "o-suten"}
]

const NamesInJapanese = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Beginner" category="Vocab" lesson={title} />
     <div className={'title'}>
     {title}
      </div>
      <h1>{title}</h1>

      <p>You might be wondering "what is my name in Japanese?" or "how do I say my name in Japanese?". If so, this lesson should be able to help. Once you know your name in Japanese, we will also teach you how to introduce yourself in Japanese.</p>

<p>There aren't direct equivalents of foreign names in Japanese but foreign names can be sounded out using the sounds in the Japanese language creating a "closest pronunciation equivalent". It doesn't matter how the name is spelled but only how it is pronounced. Below are a number of common names sounded out in Japanese. Foreign names are written out in [Katakana](/katakana/) which is covered in Lesson 2. The ー character (called a "bou" 🔊) elongates the vowel sound of the character in front of it. Remember, these are not actual Japanese names. Please don't name your son "ma-ku" because you love Japan and the name "Mark".</p>

<p>Note: You may notice certain combinations (such as シェ (she) or ティ (ti)) that aren't part of the standard Katakana character set. These are special exceptions for foreign names only.</p>

<p>Click on the 🔊 image to hear how to say your name in Japanese.</p>

<LetterNav />

<Table data={namesA} />

<p>Now, how do you introduce yourself using your name? Many places teach "watashi wa ______ desu." but this has a problem:</p>

<p>"watashi wa ______ desu." means both "I am ______." and "I am a ______." Unless the Japanese person you are introducing yourself to is familiar with your name (which isn't always too likely) they won't know that you are telling them your name. This is particularly a problem with names that have the same sound as actual words in the Japanese language. For instance, "jimu" (Jim) means "business" and "gym/gymnasium" in Japanese (depending on the context) so "watashi wa jimu desu." could also mean "I am a business." or "I am a gymnasium."</p>

<p>So what is the better way to introduce yourself? "watashi wa ______ to iimasu."  means "I am called ______." and makes it very clear that the "______" is your name. It can be either your first name, last name, or nickname - whatever you want them to call you by.</p>

    </>
  )
}

export default NamesInJapanese