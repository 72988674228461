import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Counters"

const countersone = [
  {"漢字 - Kanji": "", "ひらがな - Hiragana": "", "Romaji (English Letters)": "", "English Meaning": "", "audio": ""}
]

const Counters = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Intermediate" category="Grammar" lesson={title} />
     
      <h1>{title}</h1>

<p>Attention: Be sure to study the <Link to="/members/b/vocab/numbers/">Numbers, Time, and Dates</Link> lesson before moving on to this one.</p>

<p>Counters are one of the more complex aspects of the Japanese language. While, in English, we just use one, two, three, four, etc. to count items, in Japanese, the correct counter must be used. There are hundreds of different counters with the more commonly used ones covered here. There is a generic counter (which will be addressed at the end of this lesson) but it should be used very rarely. For each counter, the main reading of the counter will be indicated along with any exceptions. In most cases, only numbers 1-10 will be addressed. Since numbers repeat themselves in Japanese, exceptions for 1 will also apply to 11, 21, 31, etc.; exceptions for 2 will also apply to 12, 22, 32, etc.; and so forth. Cases where this rule does not apply will be identified. Assume that 4 is "yon" and 7 is "nana" unless otherwise noted.</p>

<table>
<tbody><tr>
<th>
<br /><span style={{fontSize:`5rem`}}>人</span>
</th>
<td>
<table>
<tbody><tr><td>Kanji Meaning: </td><td>Person</td></tr>
<tr><td>Counter Usage: </td><td>People</td></tr>
<tr><td>Counter Reading: </td><td>にん (nin) <Play audio="nin" /></td></tr>
<tr><td>Exceptions: </td><td>
一人 (ひとり - hitori - 1 person) <Play audio="hitori" /><br />
二人 (ふたり - futari - 2 people) <Play audio="futari" /><br />
四人 (よにん - yonin - 4 people) <Play audio="yonin" /><br />
Exceptions for 1 and 2 do not repeat.
</td></tr>
<tr><td>Example: </td><td>
そこに四人の男の人がいる。<br />
そこによにんのおとこのひとがいる。<br />
<Play audio="soko ni yonin no otoko no hito ga iru" /><br />
There are 4 guys over there.
</td></tr>
</tbody></table>
</td>
</tr>
</tbody></table>

<table>
<tbody><tr>
<th>
<br /><span style={{fontSize:`5rem`}}>日</span>
</th>
<td>
<table>
<tbody><tr><td>Kanji Meaning: </td><td>Day, sun</td></tr>
<tr><td>Counter Usage: </td><td>Days of the month</td></tr>
<tr><td>Counter Reading: </td><td>にち (nichi) <Play audio="nichi" /></td></tr>
<tr><td>Exceptions: </td><td>
一日 (ついたち - tsuitachi - 1st day of the month) <Play audio="tsuitachi" /><br />
二日 (ふつか - futsuka - 2nd day of the month) <Play audio="futsuka" /><br />
三日 (みっか - mikka - 3rd day of the month) <Play audio="mikka" /><br />
四日 (よっか - yokka - 4th day of the month) <Play audio="yokka" /><br />
五日 (いつか - itsuka - 5th day of the month) <Play audio="itsuka" /><br />
六日 (むいか - muika - 6th day of the month) <Play audio="muika" /><br />
七日 (なのか - nanoka - 7th day of the month) <Play audio="nanoka" /><br />
八日 (ようか - youka - 8th day of the month) <Play audio="youka" /><br />
九日 (ここのか - kokonoka - 9th day of the month) <Play audio="kokonoka" /><br />
十日 (とおか - tooka - 10th day of the month) <Play audio="tooka" /><br />
十四日 (じゅうよっか - juuyokka - 14th day of the month) <Play audio="juuyokka" /><br />
十七日 (じゅうしちにち - juushichinichi - 17th day of the month) <Play audio="juushichinichi" /><br />
十九日 (じゅうくにち - juukunichi - 19th day of the month) <Play audio="juukunichi" /><br />
二十日 (はつか - hatsuka - 20th day of the month) <Play audio="hatsuka" /><br />
Exceptions for 1-10 do not repeat but those for 14, 17, and 19 do.
</td></tr>
<tr><td>Example: </td><td>
五月二十四日に生まれた。<br />
ごがつにじゅうよっかにうまれた。<br />
<Play audio="gogatsu nijuuyokka ni umareta" /><br />
I was born on May 24th.
</td></tr>
</tbody></table>
</td>
</tr>
</tbody></table>

<table>
<tbody><tr>
<th>
<br /><span style={{fontSize:`5rem`}}>日間</span>
</th>
<td>
<table>
<tbody><tr><td>Kanji Meaning: </td><td>Period of days</td></tr>
<tr><td>Counter Usage: </td><td>Days</td></tr>
<tr><td>Counter Reading: </td><td>にちかん (nichikan) <Play audio="nichikan" /></td></tr>
<tr><td>Exceptions: </td><td>
一日 (いちにち - ichinichi - 1 day) <Play audio="ichinichi" /><br />
二日間 (ふつかかん - futsukakan - 2 days) <Play audio="futsukakan" /><br />
三日間 (みっかかん - mikkakan - 3 days) <Play audio="mikkakan" /><br />
四日間 (よっかかん - yokkakan - 4 days) <Play audio="yokkakan" /><br />
五日間 (いつかかん - itsukakan - 5 days) <Play audio="itsukakan" /><br />
六日間 (むいかかん - muikakan - 6 days) <Play audio="muikakan" /><br />
七日間 (なのかかん - nanokakan - 7 days) <Play audio="nanokakan" /><br />
八日間 (ようかかん - youkakan - 8 days) <Play audio="youkakan" /><br />
九日間 (ここのかかん - kokonokakan - 9 days) <Play audio="kokonokakan" /><br />
十日間 (とおかかん - tookakan - 10 days) <Play audio="tookakan" /><br />
十四日間 (じゅうよっかかん - juuyokkakan - 14 days) <Play audio="juuyokkakan" /><br />
十七日間 (じゅうしちにちかん - juushichinichikan - 17 days) <Play audio="juushichinichikan" /><br />
十九日間 (じゅうくにちかん - juukunichikan - 19 days) <Play audio="juukunichikan" /><br />
二十日間 (はつかかん - hatsukakan - 20 days) <Play audio="hatsukakan" /><br />
Exceptions for 1-10 do not repeat but those for 14, 17, and 19 do.
</td></tr>
<tr><td>Example: </td><td>
十日間日本にいました。<br />
とおかかんにほんにいました。<br />
<Play audio="tookakan nihon ni imashita" /><br />
I was in Japan for 10 days.
</td></tr>
</tbody></table>
</td>
</tr>
</tbody></table>

<table>
<tbody><tr>
<th>
<br /><span style={{fontSize:`5rem`}}>週間</span>
</th>
<td>
<table>
<tbody><tr><td>Kanji Meaning: </td><td>Period of weeks</td></tr>
<tr><td>Counter Usage: </td><td>Weeks</td></tr>
<tr><td>Counter Reading: </td><td>しゅうかん (shuukan) <Play audio="shuukan" /></td></tr>
<tr><td>Exceptions: </td><td>
一週間 (いっしゅうかん - isshuukan - 1 week) <Play audio="isshuukan" /><br />
八週間 (はっしゅうかん - hasshuukan - 8 weeks) <Play audio="hasshuukan" /><br />
十週間 (じゅっしゅうかん - jusshuukan - 10 weeks) <Play audio="jusshuukan" />
</td></tr>
<tr><td>Example: </td><td>
後三週間で日本に行きます。<br />
あとさんしゅうかんでにほんにいきます。<br />
<Play audio="ato sanshuukan de nihon ni ikimasu" /><br />
I will be going to Japan in 3 weeks.
</td></tr>
</tbody></table>
</td>
</tr>
</tbody></table>

<table>
<tbody><tr>
<th>
<br /><span style={{fontSize:`5rem`}}>ヶ月</span>
</th>
<td>
<table>
<tbody><tr><td>Kanji Meaning: </td><td>Period of months</td></tr>
<tr><td>Counter Usage: </td><td>Months</td></tr>
<tr><td>Counter Reading: </td><td>かげつ (kagetsu) <Play audio="kagetsu" /></td></tr>
<tr><td>Exceptions: </td><td>
一ヶ月 (いっかげつ - ikkagetsu - 1 month) <Play audio="ikkagetsu" /><br />
六ヶ月 (ろっかげつ - rokkagetsu - 6 months) <Play audio="rokkagetsu" /><br />
十ヶ月 (じゅっかげつ - jukkagetsu - 10 months) <Play audio="jukkagetsu" />
</td></tr>
<tr><td>Example: </td><td>
誕生日が一ヶ月前だった。<br />
たんじょうびがいっかげつまえだった。<br />
<Play audio="tanjoubi ga ikkagetsu mae datta" /><br />
My birthday was a month ago.
</td></tr>
</tbody></table>
</td>
</tr>
</tbody></table>

<table>
<tbody><tr>
<th>
<br /><span style={{fontSize:`5rem`}}>個</span>
</th>
<td>
<table>
<tbody><tr><td>Kanji Meaning: </td><td>Counter for objects</td></tr>
<tr><td>Counter Usage: </td><td>Small, solid objects - apples, toys, etc.</td></tr>
<tr><td>Counter Reading: </td><td>こ (ko) <Play audio="ko" /></td></tr>
<tr><td>Exceptions: </td><td>
一個 (いっこ - ikko - 1 object) <Play audio="ikko" /><br />
八個 (はっこ - hakko - 8 objects) <Play audio="hakko" /><br />
十個 (じゅっこ - jukko - 10 objects) <Play audio="jukko" />
</td></tr>
<tr><td>Example: </td><td>
七個のリンゴを買った。<br />
ななこのリンゴをかった。<br />
<Play audio="nanako no ringo o katta" /><br />
I bought 7 apples.
</td></tr>
</tbody></table>
</td>
</tr>
</tbody></table>

<table>
<tbody><tr>
<th>
<br /><span style={{fontSize:`5rem`}}>枚</span>
</th>
<td>
<table>
<tbody><tr><td>Kanji Meaning: </td><td>Counter for flat objects</td></tr>
<tr><td>Counter Usage: </td><td>Flat objects - sheets of paper, etc.</td></tr>
<tr><td>Counter Reading: </td><td>まい (mai) <Play audio="mai" /></td></tr>
<tr><td>Exceptions: </td><td>
None
</td></tr>
<tr><td>Example: </td><td>
八枚の紙をプリンターに入れてくれませんか。<br />
はちまいのかみをプリンターにいれてくれませんか。<br />
<Play audio="hachimai no kami o purinta- ni irete kuremasen ka" /><br />
Could you please put 8 pieces of paper into the printer?
</td></tr>
</tbody></table>
</td>
</tr>
</tbody></table>

<table>
<tbody><tr>
<th>
<br /><span style={{fontSize:`5rem`}}>本</span>
</th>
<td>
<table>
<tbody><tr><td>Kanji Meaning: </td><td>Book, main</td></tr>
<tr><td>Counter Usage: </td><td>Cylindrical objects - pencils, pens, arms, fingers, etc.</td></tr>
<tr><td>Counter Reading: </td><td>ほん (hon) <Play audio="hon" /></td></tr>
<tr><td>Exceptions: </td><td>
一本 (いっぽん - ippon - 1 cylindrical object) <Play audio="ippon" /><br />
三本 (さんぼん - sanbon - 3 cylindrical objects) <Play audio="sanbon" /><br />
六本 (ろっぽん - roppon - 6 cylindrical objects) <Play audio="roppon" /><br />
八本 (はっぽん - happon - 8 cylindrical objects) <Play audio="happon" /><br />
十本 (じゅっぽん - juppon - 10 cylindrical objects) <Play audio="juppon" />
</td></tr>
<tr><td>Example: </td><td>
二本の鉛筆を持っている。<br />
にほんのえんぴつをもっている。<br />
<Play audio="nihon no enpitsu o motte iru" /><br />
I have 2 pencils.
</td></tr>
</tbody></table>
</td>
</tr>
</tbody></table>

<table>
<tbody><tr>
<th>
<br /><span style={{fontSize:`5rem`}}>冊</span>
</th>
<td>
<table>
<tbody><tr><td>Kanji Meaning: </td><td>Counter for books</td></tr>
<tr><td>Counter Usage: </td><td>Books</td></tr>
<tr><td>Counter Reading: </td><td>さつ (satsu) <Play audio="satsu" /></td></tr>
<tr><td>Exceptions: </td><td>
一冊 (いっさつ - issatsu - 1 book) <Play audio="issatsu" /><br />
八冊 (はっさつ - hassatsu - 8 books) <Play audio="hassatsu" /><br />
十冊 (じゅっさつ - jussatsu - 10 books) <Play audio="jussatsu" />
</td></tr>
<tr><td>Example: </td><td>
大好きな本は十冊あった。<br />
だいすきなほんはじゅっさつあった。<br />
<Play audio="daisuki na hon wa jussatsu atta" /><br />
They had 10 copies of my favorite book.
</td></tr>
</tbody></table>
</td>
</tr>
</tbody></table>

<table>
<tbody><tr>
<th>
<br /><span style={{fontSize:`5rem`}}>杯</span>
</th>
<td>
<table>
<tbody><tr><td>Kanji Meaning: </td><td>Cup</td></tr>
<tr><td>Counter Usage: </td><td>Cupfuls</td></tr>
<tr><td>Counter Reading: </td><td>はい (hai) <Play audio="hai" /></td></tr>
<tr><td>Exceptions: </td><td>
一杯 (いっぱい - ippai - 1 cup　(also used to indicate when one's stomach is full)) <Play audio="ippai" /><br />
三杯 (さんばい - sanbai - 3 cups) <Play audio="sanbai" /><br />
六杯 (ろっぱい - roppai - 6 cups) <Play audio="roppai" /><br />
十杯 (じゅっぱい - juppai - 10 cups) <Play audio="juppai" />
</td></tr>
<tr><td>Example: </td><td>
水を一杯お願いします。<br />
みずをいっぱいおねがいします。<br />
<Play audio="mizu o ippai onegai shimasu" /><br />
I would like to have a glass full of water.
</td></tr>
</tbody></table>
</td>
</tr>
</tbody></table>

<table>
<tbody><tr>
<th>
<br /><span style={{fontSize:`5rem`}}>匹</span>
</th>
<td>
<table>
<tbody><tr><td>Kanji Meaning: </td><td>Counter for small animals</td></tr>
<tr><td>Counter Usage: </td><td>Small animals - dogs, cats, etc.</td></tr>
<tr><td>Counter Reading: </td><td>ひき (hiki) <Play audio="hiki" /></td></tr>
<tr><td>Exceptions: </td><td>
一匹 (いっぴき - ippiki - 1 small animal) <Play audio="ippiki" /><br />
三匹 (さんびき - sanbiki - 3 small animals) <Play audio="sanbiki" /><br />
六匹 (ろっぴき - roppiki - 6 small animals) <Play audio="roppiki" /><br />
十匹 (じゅっぴき - juppiki - 10 small animals) <Play audio="juppiki" />
</td></tr>
<tr><td>Example: </td><td>
ペットとして三匹の犬を飼っている。<br />
ペットとしてさんびきのいぬをかっている。<br />
<Play audio="petto toshite sanbiki no inu o katte iru" /><br />
I have 3 dogs as pets.
</td></tr>
</tbody></table>
</td>
</tr>
</tbody></table>

<table>
<tbody><tr>
<th>
<br /><span style={{fontSize:`5rem`}}>頭</span>
</th>
<td>
<table>
<tbody><tr><td>Kanji Meaning: </td><td>Head</td></tr>
<tr><td>Counter Usage: </td><td>Large animals - cows, horses, etc.</td></tr>
<tr><td>Counter Reading: </td><td>とう (tou) <Play audio="tou" /></td></tr>
<tr><td>Exceptions: </td><td>
一頭 (いっとう - ittou - 1 large animal) <Play audio="ittou" /><br />
十頭 (じゅっとう - juttou - 10 large animals) <Play audio="juttou" />
</td></tr>
<tr><td>Example: </td><td>
四頭の馬がいる。<br />
よんとうのうまがいる。<br />
<Play audio="yontou no uma ga iru" /><br />
We have 4 horses.
</td></tr>
</tbody></table>
</td>
</tr>
</tbody></table>

<table>
<tbody><tr>
<th>
<br /><span style={{fontSize:`5rem`}}>羽</span>
</th>
<td>
<table>
<tbody><tr><td>Kanji Meaning: </td><td>Wing</td></tr>
<tr><td>Counter Usage: </td><td>Birds (and rabbits for some reason)</td></tr>
<tr><td>Counter Reading: </td><td>わ (wa) <Play audio="wa" /></td></tr>
<tr><td>Exceptions: </td><td>
None
</td></tr>
<tr><td>Example: </td><td>
庭には二羽の鶏がいる。<br />
にわにはにわのにわとりがいる。<br />
<Play audio="niwa niwa niwa no niwatori ga iru" /><br />
There are 2 chickens in my yard.
</td></tr>
</tbody></table>
</td>
</tr>
</tbody></table>

<table>
<tbody><tr>
<th>
<br /><span style={{fontSize:`5rem`}}>才</span>
</th>
<td>
<table>
<tbody><tr><td>Kanji Meaning: </td><td>Talent</td></tr>
<tr><td>Counter Usage: </td><td>Age</td></tr>
<tr><td>Counter Reading: </td><td>さい (sai) <Play audio="sai" /></td></tr>
<tr><td>Exceptions: </td><td>
一才 (いっさい - issai - 1 year old) <Play audio="issai" /><br />
八才 (はっさい - hassai - 8 years old) <Play audio="hassai" /><br />
十才 (じゅっさい - jussai - 10 years old) <Play audio="jussai" /><br />二十才 (はたち - hatachi - 20 years old) <Play audio="hatachi" />
</td></tr>
<tr><td>Example: </td><td>
先週、妹が十一才になった。<br />
せんしゅう、いもうとがじゅういっさいになった。<br />
<Play audio="senshuu imouto ga juuissai ni natta" /><br />
My little sister turned 11 last week.
</td></tr>
</tbody></table>
</td>
</tr>
</tbody></table>

<table>
<tbody><tr>
<th>
<br /><span style={{fontSize:`5rem`}}>口</span>
</th>
<td>
<table>
<tbody><tr><td>Kanji Meaning: </td><td>Mouth</td></tr>
<tr><td>Counter Usage: </td><td>Mouthfuls, bites</td></tr>
<tr><td>Counter Reading: </td><td>くち (kuchi) <Play audio="kuchi" /></td></tr>
<tr><td>Exceptions: </td><td>
一口 (ひとくち - hitokuchi - 1 bite) <Play audio="hitokuchi" /><br />
二口 (ふたくち - futakuchi - 2 bites) <Play audio="futakuchi" /><br />
Note: Rarely would anyone count too high on this.
</td></tr>
<tr><td>Example: </td><td>
あのピザを三口で食べることができる。<br />
あのピザをさんくちでたべることができる。<br />
<Play audio="ano piza o sankuchi de taberu koto ga dekiru" /><br />
I could eat that whole pizza in 3 bites.
</td></tr>
</tbody></table>
</td>
</tr>
</tbody></table>

<table>
<tbody><tr>
<th>
<br /><span style={{fontSize:`5rem`}}>足</span>
</th>
<td>
<table>
<tbody><tr><td>Kanji Meaning: </td><td>Leg, foot</td></tr>
<tr><td>Counter Usage: </td><td>Shoes and socks</td></tr>
<tr><td>Counter Reading: </td><td>そく (soku) <Play audio="soku" /></td></tr>
<tr><td>Exceptions: </td><td>
一足 (いっそく - issoku - 1 shoe or sock) <Play audio="issoku" /><br />
三足 (さんぞく - sanzoku - 3 shoes or socks) <Play audio="sanzoku" /><br />
十足 (じゅっそく - jussoku - 10 shoes or socks) <Play audio="jussoku" />
</td></tr>
<tr><td>Example: </td><td>
荷物から五足の靴下がなくなった。<br />
にもつからごそくのくつしたがなくなった。<br />
<Play audio="nimotsu kara gosoku no kutsushita ga naku natta" /><br />
5 socks are missing from my luggage.
</td></tr>
</tbody></table>
</td>
</tr>
</tbody></table>

<table>
<tbody><tr>
<th>
<br /><span style={{fontSize:`5rem`}}>台</span>
</th>
<td>
<table>
<tbody><tr><td>Kanji Meaning: </td><td>Tower, stand, pedestal</td></tr>
<tr><td>Counter Usage: </td><td>Cars</td></tr>
<tr><td>Counter Reading: </td><td>だい (dai) <Play audio="dai" /></td></tr>
<tr><td>Exceptions: </td><td>
None
</td></tr>
<tr><td>Example: </td><td>
七台の車が事故にあった。<br />
ななだいのくるまがじこにあった。<br />
<Play audio="nanadai no kuruma ga jiko ni atta" /><br />
7 cars got into an accident.
</td></tr>
</tbody></table>
</td>
</tr>
</tbody></table>

<table>
<tbody><tr>
<th>
<br /><span style={{fontSize:`5rem`}}>級</span>
</th>
<td>
<table>
<tbody><tr><td>Kanji Meaning: </td><td>Class, grade, rank</td></tr>
<tr><td>Counter Usage: </td><td>Class, grade, rank</td></tr>
<tr><td>Counter Reading: </td><td>きゅう (kyuu) <Play audio="kyuu" /></td></tr>
<tr><td>Exceptions: </td><td>
一級 (いっきゅう - ikkyuu - rank 1 (highest)) <Play audio="ikkyuu" /><br />
六級 (ろっきゅう - rokkyuu - rank 6) <Play audio="rokkyuu" /><br />
十級 (じゅっきゅう - jukkyuu - rank 10) <Play audio="jukkyuu" />
</td></tr>
<tr><td>Example: </td><td>
日本語能力試験二級のために勉強している。<br />
にほんごのうりょくしけんにきゅうのためにべんきょうしている。<br />
<Play audio="nihongo nouryoku shiken nikyuu no tame ni benkyou shite iru" /><br />
I am studying for the JLPT level 2 test.
</td></tr>
</tbody></table>
</td>
</tr>
</tbody></table>

<table>
<tbody><tr>
<th>
<br /><span style={{fontSize:`5rem`}}>泊</span>
</th>
<td>
<table>
<tbody><tr><td>Kanji Meaning: </td><td>Counter for nights of a stay</td></tr>
<tr><td>Counter Usage: </td><td>Counter for nights of a stay</td></tr>
<tr><td>Counter Reading: </td><td>はく (haku) <Play audio="haku" /></td></tr>
<tr><td>Exceptions: </td><td>
一泊 (いっぱく - ippaku - 1 night) <Play audio="ippaku" /><br />
三泊 (さんぱく - sanpaku - 3 nights) <Play audio="sanpaku" /><br />
六泊 (ろっぱく - roppaku - 6 nights) <Play audio="roppaku" /><br />
八泊 (はっぱく - happaku - 8 nights) <Play audio="happaku" /><br />
十泊 (じゅっぱく - juppaku - 10 nights) <Play audio="juppaku" />
</td></tr>
<tr><td>Example: </td><td>
ホテルで一泊だけする。<br />
ホテルでいっぱくだけする。<br />
<Play audio="hoteru de ippaku dake suru" /><br />
I'm only staying in a hotel one night.
</td></tr>
</tbody></table>
</td>
</tr>
</tbody></table>

<table>
<thead>
<tr>
<th colspan="2">
Generic Counters
</th>
</tr>
</thead>
<tbody>
<tr>
<td></td>
<td>
一つ (ひとつ - hitotsu - generic counter for 1) <Play audio="hitotsu" /><br />
二つ (ふたつ - futatsu - generic counter for 2) <Play audio="futatsu" /><br />
三つ (みっつ - mittsu - generic counter for 3) <Play audio="mittsu" /><br />
四つ (よっつ - yottsu - generic counter for 4) <Play audio="yottsu" /><br />
五つ (いつつ - itsutsu - generic counter for 5) <Play audio="itsutsu" /><br />
六つ (むっつ - muttsu - generic counter for 6) <Play audio="muttsu" /><br />
七つ (ななつ - nanatsu - generic counter for 7) <Play audio="nanatsu" /><br />
八つ (やっつ - yattsu - generic counter for 8) <Play audio="yattsu" /><br />
九つ (ここのつ - kokonotsu - generic counter for 9) <Play audio="kokonotsu" /><br />
十 (とお - too - generic counter for 10) <Play audio="too" />
</td>
</tr>
<tr>
<td colspan="2">
Note: The generic counters don't go past 10.
</td>
</tr>
</tbody></table>

    </>
  )
}

export default Counters