import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Grade 4 Kanji - Part 2 of 5"

const GradeFourTwo = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Advanced" category="Kanji" lesson={title} />
    
      <h1>{title}</h1>

      <p>Below are Kanji the Japanese learn in grade 4 of elementary school.
Be sure to read the <Link to="/members/a/kanji/intro/">Introduction to Kanji</Link> if you haven't already.</p>

<p>Legend:</p>
<p>[n] = Noun</p>
<p>[dv] = Descriptive word (true adjective) - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[dn] = Descriptive noun - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[gv] = Godan verb - see <Link to="/members/b/grammar/godan-verbs/">Verb Conjugation (Godan)</Link></p>
<p>[iv] = Ichidan verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[irv] = Irregular verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[c] = Counter</p>

<table>
<thead>
  <tr>
<th>漢字<br /><Play audio="kanji" /></th>
<th>音読み <Play audio="onyomi" /><br />"On" Reading</th>
<th>訓読み <Play audio="kunyomi" /><br />"Kun" Reading</th>
<th>English Meaning</th>
</tr>
  </thead>
<tbody>
<tr>
<th><span style={{fontSize:`4rem`}}>博</span></th>
<td>ハク (haku)<br />バク (baku)</td>
<td></td>
<td>
Dr.
<br />
<table><tbody><tr><td>Examples:
<br />
博覧 (はくらん - hakuran - extensive reading, wide knowledge) [n] <Play audio="hakuran" />
<br />
賭博 (とばく - tobaku - gambling) [n] <Play audio="tobaku" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>印</span></th>
<td>イン (in)</td>
<td>しるし (shirushi)</td>
<td>
Mark
<br />
<table><tbody><tr><td>Examples:
<br />
印鑑 (いんかん - inkan - stamp, seal - used in Japan for signatures) [n] <Play audio="inkan" />
<br />
印 (しるし - shirushi - mark, sign) [n] <Play audio="shirushi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>参</span></th>
<td>サン (san)</td>
<td>まい・る (mairu)</td>
<td>
Participate
<br />
<table><tbody><tr><td>Examples:
<br />
参加 (さんか - sanka - participation) [n] <Play audio="sanka" />
<br />
参る (まいる - mairu - to go, to come - honorific (humble) form of 行く (iku - to go) and 来る (kuru - to come)) [gv] <Play audio="mairu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>史</span></th>
<td>シ (shi)</td>
<td></td>
<td>
History
<br />
<table><tbody><tr><td>Examples:
<br />
史学 (しがく - shigaku - study of history) [n] <Play audio="shigaku" />
<br />
歴史 (れきし - rekishi - history) [n] <Play audio="rekishi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>司</span></th>
<td>シ (shi)</td>
<td></td>
<td>
Director
<br />
<table><tbody><tr><td>Examples:
<br />
司会 (しかい - shikai - chairmanship, leading a meeting, presenter, host) [n] <Play audio="shikai" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>各</span></th>
<td>カク (kaku)</td>
<td>おの・おの (onoono)</td>
<td>
Each
<br />
<table><tbody><tr><td>Examples:
<br />
各位 (かくい - kakui - everyone, each and every one (of you), ladies and gentlemen) [n] <Play audio="chuukai" />
<br />
各々 (おのおの - onoono - each, every, either, respectively) [n] <Play audio="onoono" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>告</span></th>
<td>コク (koku)</td>
<td>つ・げる (tsugeru)</td>
<td>
Tell
<br />
<table><tbody><tr><td>Examples:
<br />
勧告 (かんこく - kankoku - advice, counsel, recommendation) [n] <Play audio="kankoku" />
<br />
告げる (つげる - tsugeru - to inform, to tell, to ring (bell, alarm, warning, etc.)) [iv] <Play audio="tsugeru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>周</span></th>
<td>シュウ (shuu)</td>
<td>まわ・り (mawari)</td>
<td>
Circumference
<br />
<table><tbody><tr><td>Examples:
<br />
周囲 (しゅうい - shuui - surroundings, circumference) [n] <Play audio="shuui" />
<br />
周り (まわり - mawari - circumference, perimeter, edge, surroundings, locality, neighborhood) [n] <Play audio="mawari" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>唱</span></th>
<td>ショウ (shou)</td>
<td>とな・える (tonaeru)</td>
<td>
Chant
<br />
<table><tbody><tr><td>Examples:
<br />
合唱 (がっしょう - gasshou - chorus, singing in a chorus) [n] <Play audio="gasshou" />
<br />
唱える (となえる - tonaeru - to recite, to chant, to cry, to yell, to shout, to advocate, to preach, to insist) [iv] <Play audio="tonaeru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>喜</span></th>
<td>キ (ki)</td>
<td>よろこ・ぶ (yorokobu)<br />よろこ・ばしい (yorokobashii)</td>
<td>
Rejoice
<br />
<table><tbody><tr><td>Examples:
<br />
喜怒哀楽 (きどあいらく - kidoairaku - human emotions (joy, anger, pathos, and humor)) [n] <Play audio="kidoairaku" />
<br />
喜ぶ (よろこぶ - yorokobu - to be delighted, to be glad, to be pleased) [gv] <Play audio="yorokobu" />
<br />
喜ばしい (よろこばしい - yorokobashii - delightful, joyous, happy) [dv] <Play audio="yorokobashii" />
<br />
喜び (よろこび - yorokobi - joy, delight) [n] <Play audio="yorokobi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>器</span></th>
<td>キ (ki)</td>
<td>うつわ (utsuwa)</td>
<td>
Container
<br />
<table><tbody><tr><td>Examples:
<br />
武器 (ぶき - buki - weapon, arms, ordnance) [n] <Play audio="buki" />
<br />
器 (うつわ - utsuwa - bowl, vessel, container, ability, capacity) [n] <Play audio="utsuwa" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>囲</span></th>
<td>イ (i)</td>
<td>かこ・む (kakomu)<br />かこ・み (kakomi)<br />かこ・う (kakou)<br />かこ・い (kakoi)</td>
<td>
Surround
<br />
<table><tbody><tr><td>Examples:
<br />
雰囲気 (ふんいき - funiki (fun'iki) - atmosphere (e.g. musical), mood, ambiance) [n] <Play audio="fun-iki" />
<br />
囲む (かこむ - kakomu - to surround, to encircle) [gv] <Play audio="kakomu" />
<br />
囲み (かこみ - kakomi - enclosure, box) [n] <Play audio="kakomi" />
<br />
囲う (かこう - kakou - to enclose) [gv] <Play audio="kakou" />
<br />
囲い (かこい - kakoi - enclosure, storage) [n] <Play audio="kakoi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>固</span></th>
<td>コ (ko)</td>
<td>かた・い (katai)<br />かた・まる (katamaru)<br />かた・める (katameru)</td>
<td>
Harden
<br />
<table><tbody><tr><td>Examples:
<br />
頑固 (がんこ - ganko - stubbornness, obstinacy) [dn] <Play audio="ganko" />
<br />
固い (かたい - katai - hard, solid, tough, stiff, tight) [dv] <Play audio="katai" />
<br />
固まる (かたまる - katamaru - to harden, to solidify, to become firm) [gv] <Play audio="katamaru" />
<br />
固める (かためる - katameru - to harden something, to freeze, to strengthen, to solidify) [iv] <Play audio="katameru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>型</span></th>
<td>ケイ (kei)</td>
<td>かた (kata)</td>
<td>
Model
<br />
<table><tbody><tr><td>Examples:
<br />
体型 (たいけい - taikei - figure (body)) [n] <Play audio="taikei" />
<br />
型 (かた - kata - model, type (e.g. of machine, goods, etc.), style, pattern, mold, template) [n] <Play audio="kata" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>堂</span></th>
<td>ドウ (dou)</td>
<td></td>
<td>
Public Chamber
<br />
<table><tbody><tr><td>Examples:
<br />
堂々 (どうどう - doudou - magnificent, grand, impressive, dignified, majestic, imposing, stately) <Play audio="doudou" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>塩</span></th>
<td>エン (en)</td>
<td>しお (shio)</td>
<td>
Salt
<br />
<table><tbody><tr><td>Examples:
<br />
食塩 (しょくえん - shokuen - table salt) [n] <Play audio="shokuen" />
<br />
塩 (しお - shio - salt (i.e. sodium chloride), common salt, table salt) [n] <Play audio="shio" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>士</span></th>
<td>シ (shi)</td>
<td></td>
<td>
Gentleman
<br />
<table><tbody><tr><td>Examples:
<br />
弁護士 (べんごし - bengoshi - lawyer, attorney) [n] <Play audio="bengoshi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>変</span></th>
<td>ヘン (hen)</td>
<td>か・わる (kawaru)<br />か・える (kaeru)</td>
<td>
Change
<br />
<table><tbody><tr><td>Examples:
<br />
変 (へん - hen - strange, odd, peculiar, weird) [dn] <Play audio="hen" />
<br />
変化 (へんか - henka - change, variation, alteration, mutation, transition, transformation, transfiguration, metamorphosis) [n] <Play audio="henka" />
<br />
変わる (かわる - kawaru - to change, to be transformed, to be altered, to vary) [gv] <Play audio="kawaru" />
<br />
変える (かえる - kaeru - to change something, to alter, to convert, to revise, to transform) [iv] <Play audio="kaeru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>夫</span></th>
<td>フ (fu)<br />フウ (fuu)<br />ブ (bu)</td>
<td>おっと (otto)</td>
<td>
Husband
<br />
<table><tbody><tr><td>Examples:
<br />
夫人 (ふじん - fujin - wife, Mrs, madam) [n] <Play audio="fujin" />
<br />
夫婦 (ふうふ - fuufu - married couple, spouses, husband and wife, couple, pair) [n] <Play audio="fuufu" />
<br />
丈夫 (じょうぶ - joubu - healthy, robust, strong, solid, durable) [dn] <Play audio="joubu" />
<br />
夫 (おっと - otto - husband) [n] <Play audio="otto" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>失</span></th>
<td>シツ (shitsu)</td>
<td>うしな・う (ushinau)</td>
<td>
Lose
<br />
<table><tbody><tr><td>Examples:
<br />
失礼 (しつれい - shitsurei - discourtesy, impoliteness, rudeness) [dn] <Play audio="shitsurei" />
<br />
失う (うしなう - ushinau - to lose, to part with) [gv] <Play audio="ushinau" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>好</span></th>
<td>コウ (kou)</td>
<td>この・む (konomu)<br />この・み (konomi)<br />この・ましい (konomashii)<br />す・く (suku)<br />す・き (suki)</td>
<td>
Like
<br />
<table><tbody><tr><td>Examples:
<br />
好評 (こうひょう - kouhyou - popularity, favorable reputation) [dn] <Play audio="kouhyou" />
<br />
好む (このむ - konomu - to like, to prefer) [gv] <Play audio="konomu" />
<br />
好み (このみ - konomi - liking, taste, choice) [n] <Play audio="konomi" />
<br />
好ましい (このましい - konomashii - nice, likeable, desirable) [dv] <Play audio="konomashii" />
<br />
好く (すく - suku - to like, to love, to be fond of) [gv] <Play audio="suku" />
<br />
好き (すき - suki - liking, fondness, love) [dn] <Play audio="suki" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>季</span></th>
<td>キ (ki)</td>
<td></td>
<td>
Seasons
<br />
<table><tbody><tr><td>Examples:
<br />
季節 (きせつ - kisetsu - season) [n] <Play audio="kisetsu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>孫</span></th>
<td>ソン (son)</td>
<td>まご (mago)</td>
<td>
Grandchild
<br />
<table><tbody><tr><td>Examples:
<br />
子孫 (しそん - shison - descendants, posterity, offspring) [n] <Play audio="shison" />
<br />
孫 (まご - mago - grandchild) [n] <Play audio="mago" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>完</span></th>
<td>カン (kan)</td>
<td></td>
<td>
Perfect
<br />
<table><tbody><tr><td>Examples:
<br />
完璧 (かんぺき - kanpeki - perfect, complete, flawless) [dn] <Play audio="kanpeki" />
<br />
完全 (かんぜん - kanzen - perfection, completeness) [dn] <Play audio="kanzen" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>官</span></th>
<td>カン (kan)</td>
<td></td>
<td>
Government Official
<br />
<table><tbody><tr><td>Examples:
<br />
警官 (けいかん - keikan - policeman) [n] <Play audio="keikan" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>害</span></th>
<td>ガイ (gai)</td>
<td></td>
<td>
Harm
<br />
<table><tbody><tr><td>Examples:
<br />
災害 (さいがい - saigai - calamity, disaster, misfortune) [n] <Play audio="saigai" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>察</span></th>
<td>サツ (satsu)</td>
<td></td>
<td>
Guess
<br />
<table><tbody><tr><td>Examples:
<br />
観察 (かんさつ - kansatsu - observation, survey) [n] <Play audio="kansatsu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>巣</span></th>
<td>ソウ (sou)</td>
<td>す (su)</td>
<td>
Nest
<br />
<table><tbody><tr><td>Examples:
<br />
卵巣 (らんそう - ransou - ovary) [n] <Play audio="ransou" />
<br />
巣 (す - su - nest, rookery, breeding place, hive) [n] <Play audio="su" />
<br />
蜂の巣 (はちのす - hachi no su - beehive) [n] <Play audio="hachi no su" />
<br />
蜘蛛の巣 (くものす - kumo no su - spiderweb) [n] <Play audio="kumo no su" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>差</span></th>
<td>サ (sa)</td>
<td></td>
<td>
Distinction
<br />
<table><tbody><tr><td>Examples:
<br />
差別 (さべつ - sabetsu - discrimination, distinction, differentiation) [n] <Play audio="sabetsu" />
<br />
個人差 (こじんさ - kojinsa - individual differences) [n] <Play audio="kojinsa" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>希</span></th>
<td>キ (ki)</td>
<td>まれ (mare)</td>
<td>
Hope
<br />
<table><tbody><tr><td>Examples:
<br />
希望 (きぼう - kibou - hope, wish, aspiration) [n] <Play audio="kibou" />
<br />
希 (まれ - mare - rare, seldom) [dn] <Play audio="mare" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>席</span></th>
<td>セキ (seki)</td>
<td></td>
<td>
Seat
<br />
<table><tbody><tr><td>Examples:
<br />
席 (せき - seki - seat) [n] <Play audio="seki" />
<br />
欠席 (けっせき - kesseki - absence, non-attendance) [n] <Play audio="kesseki" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>帯</span></th>
<td>タイ (tai)</td>
<td>お・びる (obiru)<br />おび (obi)</td>
<td>
Sash
<br />
<table><tbody><tr><td>Examples:
<br />
携帯 (けいたい - keitai - something carried (in the hand), handheld) [n] <Play audio="keitai" />
<br />
携帯電話 (けいたいでんわ - keitai denwa - mobile telephone, cell phone) [n] <Play audio="keitai denwa" />
<br />
帯びる (おびる - obiru - to wear (sword, decoration, etc.), to carry, to be entrusted (e.g. with a mission), to take on, to have a trace of, to be tinged with) [iv] <Play audio="obiru" />
<br />
帯 (おび - obi - kimono sash) [n] <Play audio="obi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>底</span></th>
<td>テイ (tei)</td>
<td>そこ (soko)</td>
<td>
Bottom
<br />
<table><tbody><tr><td>Examples:
<br />
徹底 (てってい - tettei - thoroughness, completeness, consistency) [n] <Play audio="tettei" />
<br />
底 (そこ - soko - bottom, sole) [n] <Play audio="soko" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>府</span></th>
<td>フ (fu)</td>
<td></td>
<td>
Urban Prefecture
<br />
<table><tbody><tr><td>Examples:
<br />
政府 (せいふ - seifu - government, administration) [n] <Play audio="seifu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>康</span></th>
<td>コウ (kou)</td>
<td></td>
<td>
Ease
<br />
<table><tbody><tr><td>Examples:
<br />
健康 (けんこう - kenkou - health, sound, wholesome) [dn] <Play audio="kenkou" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>建</span></th>
<td>コン (kon)<br />ケン (ken)</td>
<td>た・てる (tateru)<br />た・つ (tatsu)</td>
<td>
Build
<br />
<table><tbody><tr><td>Examples:
<br />
建立 (こんりゅう - konryuu - (act of) building (temple, monument, etc.)) [n] <Play audio="konryuu" />
<br />
建設 (けんせつ - kensetsu - construction, establishment) [n] <Play audio="kensetsu" />
<br />
建てる (たてる - tateru - to build, to construct) [iv] <Play audio="tateru" />
<br />
建つ (たつ - tatsu - to be erected, to be built) [gv] <Play audio="tatsu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>径</span></th>
<td>ケイ (kei)</td>
<td></td>
<td>
Diameter
<br />
<table><tbody><tr><td>Examples:
<br />
直径 (ちょっけい - chokkei - diameter) [n] <Play audio="chokkei" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>徒</span></th>
<td>ト (to)</td>
<td></td>
<td>
Junior
<br />
<table><tbody><tr><td>Examples:
<br />
生徒 (せいと - seito - pupil) [n] <Play audio="seito" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>得</span></th>
<td>トク (toku)</td>
<td>え・る (eru)<br />う・る (uru - rarely used)</td>
<td>
Acquire
<br />
<table><tbody><tr><td>Examples:
<br />
獲得 (かくとく - kakutoku - acquisition, possession) [n] <Play audio="kakutoku" />
<br />
得る (える - eru - to get, to acquire, to obtain, to procure, to earn, to gain, to secure, to attain) [iv] <Play audio="eru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>必</span></th>
<td>ヒツ (hitsu)</td>
<td>かなら・ず (kanarazu)</td>
<td>
Without Fail
<br />
<table><tbody><tr><td>Examples:
<br />
必勝 (ひっしょう - hisshou - certain victory) [n] <Play audio="hisshou" />
<br />
必ず (かならず - kanarazu - always, without exception, necessarily, certainly, without fail, positively, invariably) <Play audio="kanarazu" />
</td></tr></tbody></table></td>
</tr>
</tbody></table>

    </>
  )
}

export default GradeFourTwo