import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Grade 6 Kanji - Part 1 of 2"

const GradeSixTwo = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Advanced" category="Kanji" lesson={title} />
     
      <h1>{title}</h1>

      <p>Below are Kanji the Japanese learn in grade 6 of elementary school.
Be sure to read the <Link to="/members/a/kanji/intro/">Introduction to Kanji</Link> if you haven't already.</p>

<p>Legend:</p>
<p>[n] = Noun</p>
<p>[dv] = Descriptive word (true adjective) - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[dn] = Descriptive noun - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[gv] = Godan verb - see <Link to="/members/b/grammar/godan-verbs/">Verb Conjugation (Godan)</Link></p>
<p>[iv] = Ichidan verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[irv] = Irregular verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[c] = Counter</p>



  <table>
  <thead>
  <tr>
<th>漢字<br /><Play audio="kanji" /></th>
<th>音読み <Play audio="onyomi" /><br />"On" Reading</th>
<th>訓読み <Play audio="kunyomi" /><br />"Kun" Reading</th>
<th>English Meaning</th>
</tr>
  </thead>
<tbody>


<tr>
<th><span style={{fontSize:`4rem`}}>宝</span></th>
<td>



	
		ホウ (hou)
		
	

</td>
<td>



	
		たから (takara)
		
	

</td>
<td>
Treasure
<br />
<table><tbody><tr><td>Examples:




<br />
宝石 (ほうせき - houseki - gem, jewel, precious stone)


 [n]

 <Play audio="houseki" />



<br />
宝 (たから - takara - treasure)


 [n]

 <Play audio="takara" />

</td></tr></tbody></table></td>
</tr>


<tr>
<th><span style={{fontSize:`4rem`}}>宣</span></th>
<td>



	
		セン (sen)
		
	

</td>
<td>



	
		のたま・う (notamau)
		
	

</td>
<td>
Proclaim
<br />
<table><tbody><tr><td>Examples:




<br />
宣言 (せんげん - sengen - declaration, proclamation, announcement)


 [n]

 <Play audio="sengen" />



<br />
宣う (のたまう - notamau - to say, to be pleased to say (hint of sarcasm))


 [gv]

 <Play audio="notamau" />

</td></tr></tbody></table></td>
</tr>


<tr>
<th><span style={{fontSize:`4rem`}}>密</span></th>
<td>



	
		ミツ (mitsu)
		
	

</td>
<td>



</td>
<td>
Secrecy
<br />
<table><tbody><tr><td>Examples:




<br />
秘密 (ひみつ - himitsu - secret, secrecy, confidentiality, privacy, mystery)


 [dn]

 <Play audio="himitsu" />

</td></tr></tbody></table></td>
</tr>


<tr>
<th><span style={{fontSize:`4rem`}}>寸</span></th>
<td>



	
		スン (sun)
		
	

</td>
<td>



</td>
<td>
Measurement
<br />
<table><tbody><tr><td>Examples:




<br />
寸法 (すんぽう - sunpou - measurement, size, extent, dimension, plan, intention, arrangement, schedule)


 [n]

 <Play audio="sunpou" />

</td></tr></tbody></table></td>
</tr>


<tr>
<th><span style={{fontSize:`4rem`}}>専</span></th>
<td>



	
		セン (sen)
		
	

</td>
<td>



	
		もっぱ・ら (moppara)
		
	

</td>
<td>
Specialty
<br />
<table><tbody><tr><td>Examples:




<br />
専門 (せんもん - senmon - speciality, specialty, subject of study, expert)


 [n]

 <Play audio="senmon" />



<br />
専ら (もっぱら - moppara - wholly, solely, entirely, exclusively, devotedly, fixedly, principally, mostly, chiefly, mainly)


 <Play audio="moppara" />

</td></tr></tbody></table></td>
</tr>


<tr>
<th><span style={{fontSize:`4rem`}}>射</span></th>
<td>



	
		シャ (sha)
		
	

</td>
<td>



	
		い・る (iru)
		
	

</td>
<td>
Shoot
<br />
<table><tbody><tr><td>Examples:




<br />
射撃 (しゃげき - shageki - firing, shooting, fire, gunshot, marksmanship)


 [n]

 <Play audio="shageki" />



<br />
射る (いる - iru - to shoot)


 [iv]

 <Play audio="iru" />

</td></tr></tbody></table></td>
</tr>


<tr>
<th><span style={{fontSize:`4rem`}}>将</span></th>
<td>



	
		ショウ
		
	

</td>
<td>



</td>
<td>
Leader
<br />
<table><tbody><tr><td>Examples:




<br />
将軍 (しょうぐん - shougun - general, shogun)


 [n]

 <Play audio="shougun" />

</td></tr></tbody></table></td>
</tr>


<tr>
<th><span style={{fontSize:`4rem`}}>尊</span></th>
<td>



	
		ソン (son)
		
	

</td>
<td>



	
		たっと・い (tattoi)
		
			<br />
		
	

	
		とうと・い (toutoi)
		
			<br />
		
	

	
		たっと・ぶ (tattobu)
		
			<br />
		
	

	
		とうと・ぶ (toutobu)
		
	

</td>
<td>
Revered
<br />
<table><tbody><tr><td>Examples:




<br />
尊敬 (そんけい - sonkei - respect, esteem, reverence, honor)


 [n]

 <Play audio="sonkei" />



<br />
尊い (たっとい - tattoi - precious, valuable, priceless, noble, exalted, sacred)


 [dv]

 <Play audio="tattoi" />



<br />
尊い (とうとい - toutoi - precious, valuable, priceless, noble, exalted, sacred)


 [dv]

 <Play audio="toutoi" />



<br />
尊ぶ (たっとぶ - tattobu - to value, to prize, to esteem, to respect)


 [gv]

 <Play audio="tattobu" />



<br />
尊ぶ (とうとぶ - toutobu - to value, to prize, to esteem, to respect)


 [gv]

 <Play audio="toutobu" />

</td></tr></tbody></table></td>
</tr>


<tr>
<th><span style={{fontSize:`4rem`}}>就</span></th>
<td>



	
		ジュ (ju)
		
			<br />
		
	

	
		シュウ (shuu)
		
	

</td>
<td>



	
		つ・く (tsuku)
		
			<br />
		
	

	
		つ・ける (tsukeru)
		
	

</td>
<td>
Concerning
<br />
<table><tbody><tr><td>Examples:




<br />
成就 (じょうじゅ - jouju - fulfillment, fulfilment, realization, realisation, completion)


 [n]

 <Play audio="jouju" />



<br />
就職 (しゅうしょく - shuushoku - finding employment, getting a job)


 [n]

 <Play audio="shuushoku" />



<br />
就く (つく - tsuku - to ascend (the throne), to accede, to take (seat, position, course, etc.), to assume, to start (on a journey), to commence, to depart, to study (under teacher), to be an apprentice)


 [gv]

 <Play audio="tsuku" />



<br />
就ける (つける - tsukeru - to install (a king, emperor, etc.), to appoint (to a post), to assign (to study under))


 [iv]

 <Play audio="tsukeru" />

</td></tr></tbody></table></td>
</tr>


<tr>
<th><span style={{fontSize:`4rem`}}>尺</span></th>
<td>



	
		シャク (shaku)
		
	

</td>
<td>



</td>
<td>
Measure Of Length
<br />
<table><tbody><tr><td>Examples:




<br />
尺八 (しゃくはち - shakuhachi - end-blown fippleless bamboo flute)


 [n]

 <Play audio="shakuhachi" />

</td></tr></tbody></table></td>
</tr>


<tr>
<th><span style={{fontSize:`4rem`}}>届</span></th>
<td>



	
		カイ (kai - rarely used)
		
	

</td>
<td>



	
		とど・く (todoku)
		
			<br />
		
	

	
		とど・ける (todokeru)
		
			<br />
		
	

	
		とど・け (todoke)
		
	

</td>
<td>
Deliver
<br />
<table><tbody><tr><td>Examples:




<br />
届く (とどく - todoku - to reach, to arrive, to get through, to get at, to be attentive, to pay attention, to be delivered, to carry (e.g. sound))


 [gv]

 <Play audio="todoku" />



<br />
届ける (とどける - todokeru - to deliver, to forward, to send, to report, to notify, to file notice (to the authorities), to give notice, to register)


 [iv]

 <Play audio="todokeru" />



<br />
届け (とどけ - todoke - report, notification, registration)


 [n]

 <Play audio="todoke" />

</td></tr></tbody></table></td>
</tr>


<tr>
<th><span style={{fontSize:`4rem`}}>展</span></th>
<td>



	
		テン (ten)
		
	

</td>
<td>



</td>
<td>
Expand
<br />
<table><tbody><tr><td>Examples:




<br />
展望 (てんぼう - tenbou - view, outlook, prospect)


 [n]

 <Play audio="tenbou" />

</td></tr></tbody></table></td>
</tr>


<tr>
<th><span style={{fontSize:`4rem`}}>層</span></th>
<td>



	
		ソウ (sou)
		
	

</td>
<td>



</td>
<td>
Stratum
<br />
<table><tbody><tr><td>Examples:




<br />
客層 (きゃくそう - kyakusou - quality of customers)


 [n]

 <Play audio="kyakusou" />

</td></tr></tbody></table></td>
</tr>


<tr>
<th><span style={{fontSize:`4rem`}}>己</span></th>
<td>



	
		コ (ko)
		
			<br />
		
	

	
		キ (ki)
		
	

</td>
<td>



	
		おのれ (onore)
		
	

</td>
<td>
Self
<br />
<table><tbody><tr><td>Examples:




<br />
自己 (じこ - jiko - self, oneself)


 [n]

 <Play audio="jiko" />



<br />
知己 (ちき - chiki - acquaintance, appreciative friend)


 [n]

 <Play audio="chiki" />



<br />
己 (おのれ - onore - oneself (itself, etc.), I, me, you, by oneself (itself, etc.), interjection expressing anger or chagrin)


 <Play audio="onore" />

</td></tr></tbody></table></td>
</tr>


<tr>
<th><span style={{fontSize:`4rem`}}>巻</span></th>
<td>



	
		カン (kan)
		
	

</td>
<td>



	
		ま・く (maku)
		
			<br />
		
	

	
		まき (maki)
		
	

</td>
<td>
Scroll
<br />
<table><tbody><tr><td>Examples:




<br />
一巻 (いっかん - ikkan - one roll, one book, one volume, first volume, rolling once, wrapping once around)


 [n]

 <Play audio="ikkan" />



<br />
巻く (まく - maku - to wind, to coil, to roll, to wear (e.g. turban, scarf), to envelope, to shroud, to outflank, to skirt)


 [gv]

 <Play audio="maku" />



<br />
巻 (まき - maki -  roll (e.g. of cloth), winding, volume (of book))


 [n]

 <Play audio="maki" />

</td></tr></tbody></table></td>
</tr>


<tr>
<th><span style={{fontSize:`4rem`}}>幕</span></th>
<td>



	
		マク (maku)
		
			<br />
		
	

	
		バク (baku)
		
	

</td>
<td>



</td>
<td>
Curtain
<br />
<table><tbody><tr><td>Examples:




<br />
字幕 (じまく - jimaku - subtitle (movie))


 [n]

 <Play audio="jimaku" />



<br />
幕府 (ばくふ - bakufu - shogunate)


 [n]

 <Play audio="bakufu" />

</td></tr></tbody></table></td>
</tr>


<tr>
<th><span style={{fontSize:`4rem`}}>干</span></th>
<td>



	
		カン (kan)
		
	

</td>
<td>



	
		ほ・す (hosu)
		
	

</td>
<td>
Dry 
<br />
<table><tbody><tr><td>Examples:




<br />
干渉 (かんしょう - kanshou -  interference, intervention, meddling)


 [n]

 <Play audio="kanshou" />



<br />
干す (ほす - hosu - to air, to dry, to desiccate, to drain (off), to drink up, to deprive of a role, job, etc. (usu. in the passive))


 [gv]

 <Play audio="hosu" />

</td></tr></tbody></table></td>
</tr>


<tr>
<th><span style={{fontSize:`4rem`}}>幼</span></th>
<td>



	
		ヨウ
		
	

</td>
<td>



	
		おさな・い
		
	

</td>
<td>
Infancy
<br />
<table><tbody><tr><td>Examples:




<br />
幼児 (ようじ - youji - infant, baby, child, child over 1 but not yet of school age)


 [n]

 <Play audio="youji" />



<br />
幼い (おさない - osanai - very young, childish, immature)


 [dv]

 <Play audio="osanai" />

</td></tr></tbody></table></td>
</tr>


<tr>
<th><span style={{fontSize:`4rem`}}>庁</span></th>
<td>



	
		チョウ (chou)
		
	

</td>
<td>



</td>
<td>
Goverment Office
<br />
<table><tbody><tr><td>Examples:




<br />
気象庁 (きしょうちょう - kishouchou - (Japanese) Meteorological Agency, JMA)


 [n]

 <Play audio="kishouchou" />

</td></tr></tbody></table></td>
</tr>


<tr>
<th><span style={{fontSize:`4rem`}}>座</span></th>
<td>



	
		ザ (za)
		
	

</td>
<td>



	
		すわ・る (suwaru)
		
	

</td>
<td>
Sit
<br />
<table><tbody><tr><td>Examples:




<br />
座談会 (ざだんかい - zadankai - symposium, round-table discussion)


 [n]

 <Play audio="zadankai" />



<br />
座る (すわる - suwaru - to sit, to squat, to assume (a position), to hold steady, to hold still)


 [gv]

 <Play audio="suwaru" />

</td></tr></tbody></table></td>
</tr>


<tr>
<th><span style={{fontSize:`4rem`}}>延</span></th>
<td>



	
		エン (en)
		
	

</td>
<td>



	
		の・びる (nobiru)
		
			<br />
		
	

	
		の・べる (noberu)
		
			<br />
		
	

	
		の・ばす (nobasu)
		
			<br />
		
	

	
		の・べ (nobe)
		
	

</td>
<td>
Prolong
<br />
<table><tbody><tr><td>Examples:




<br />
延期 (えんき - enki - postponement, adjournment)


 [n]

 <Play audio="enki" />



<br />
延びる (のびる - nobiru - to stretch, to extend, to lengthen, to spread, to make progress, to grow (beard, body height), to grow stale (soba), to be straightened, to be flattened, to be smoothed, to be exhausted, to be postponed, to be prolonged)


 [iv]

 <Play audio="nobiru" />



<br />
延べる (のべる - noberu - to lay out (a futon), to make (bed), to spread out, to stretch, to widen, to postpone, to extend)


 [iv]

 <Play audio="noberu" />



<br />
延ばす (のばす - nobasu - to grow long (e.g. hair, nails), to lengthen, to extend, to stretch, to reach out, to hold out, to straighten, to smooth out, to spread evenly (dough, cream, etc.), to dilute, to thin out, to postpone, to prolong, to strengthen, to develop, to expand)


 [gv]

 <Play audio="nobasu" />



<br />
延べ (のべ - nobe - futures, credit (buying), stretching, total (preceding counter, unit, etc.), aggregate, gross)


 [n]

 <Play audio="nobe" />

</td></tr></tbody></table></td>
</tr>


<tr>
<th><span style={{fontSize:`4rem`}}>律</span></th>
<td>



	
		リチ (richi - rarely used)
		
			<br />
		
	

	
		リツ (ritsu)
		
	

</td>
<td>



</td>
<td>
Rhythm
<br />
<table><tbody><tr><td>Examples:




<br />
規律 (きりつ - kiritsu - order, observance, discipline, rules, law, regulations)


 [n]

 <Play audio="kiritsu" />

</td></tr></tbody></table></td>
</tr>


<tr>
<th><span style={{fontSize:`4rem`}}>従</span></th>
<td>



	
		ジュウ (juu)
		
			<br />
		
	

	
		ショウ (shou)
		
			<br />
		
	

	
		ジュ (ju - rarely used)
		
	

</td>
<td>



	
		したが・う (shitagau)
		
			<br />
		
	

	
		したが・える (shitagaeru)
		
	

</td>
<td>
Obey
<br />
<table><tbody><tr><td>Examples:




<br />
従順 (じゅうじゅん - juujun - obedient, gentle, meek, submissive, docile)


 [dn]

 <Play audio="juujun" />



<br />
従容 (しょうよう - shouyou - calm, composed, tranquil)


 <Play audio="shouyou" />



<br />
従う (したがう - shitagau - to abide (by the rules), to obey, to follow, to accompany)


 [gv]

 <Play audio="shitagau" />



<br />
従える (したがえる - shitagaeru - to be accompanied by, to subdue)


 [iv]

 <Play audio="shitagaeru" />

</td></tr></tbody></table></td>
</tr>


<tr>
<th><span style={{fontSize:`4rem`}}>忘</span></th>
<td>



	
		ボウ (bou)
		
	

</td>
<td>



	
		わす・れる (wasureru)
		
	

</td>
<td>
Forget
<br />
<table><tbody><tr><td>Examples:




<br />
忘却 (ぼうきゃく - boukyaku - lapse of memory, forgetfulness, oblivion)


 [n]

 <Play audio="boukyaku" />



<br />
忘れる (わすれる - wasureru - to forget, to leave carelessly, to be forgetful of, to forget about, to forget)


 [iv]

 <Play audio="wasureru" />

</td></tr></tbody></table></td>
</tr>


<tr>
<th><span style={{fontSize:`4rem`}}>忠</span></th>
<td>



	
		チュウ (chuu)
		
	

</td>
<td>



</td>
<td>
Loyalty
<br />
<table><tbody><tr><td>Examples:




<br />
忠告 (ちゅうこく - chuukoku - advice, warning)


 [n]

 <Play audio="chuukoku" />

</td></tr></tbody></table></td>
</tr>


<tr>
<th><span style={{fontSize:`4rem`}}>憲</span></th>
<td>



	
		ケン (ken)
		
	

</td>
<td>



</td>
<td>
Constitution
<br />
<table><tbody><tr><td>Examples:




<br />
憲法 (けんぽう - kenpou - constitution)


 [n]

 <Play audio="kenpou" />

</td></tr></tbody></table></td>
</tr>


<tr>
<th><span style={{fontSize:`4rem`}}>我</span></th>
<td>



	
		ガ (ga)
		
	

</td>
<td>



	
		われ (ware)
		
			<br />
		
	

	
		わ (wa)
		
	

</td>
<td>
Ego
<br />
<table><tbody><tr><td>Examples:




<br />
我慢 (がまん - gaman - patience, endurance, perseverance, tolerance, self-control, self-denial)


 [n]

 <Play audio="gaman" />



<br />
我 (われ - ware - I, me, oneself (fairly old))


 <Play audio="ware" />



<br />
我 (わ - wa - I, me, oneself (even older))


 <Play audio="wa" />

</td></tr></tbody></table></td>
</tr>


<tr>
<th><span style={{fontSize:`4rem`}}>批</span></th>
<td>



	
		ヒ (hi)
		
	

</td>
<td>



</td>
<td>
Criticism
<br />
<table><tbody><tr><td>Examples:




<br />
批判 (ひはん - hihan - criticism, judgement, judgment, comment)


 [n]

 <Play audio="hihan" />

</td></tr></tbody></table></td>
</tr>


<tr>
<th><span style={{fontSize:`4rem`}}>担</span></th>
<td>



	
		タン (tan)
		
	

</td>
<td>



	
		かつ・ぐ (katsugu)
		
			<br />
		
	

	
		にな・う (ninau)
		
	

</td>
<td>
Shouldering
<br />
<table><tbody><tr><td>Examples:




<br />
担当 (たんとう - tantou - being in charge (of an area of responsibility), being responsible, etc.)


 [n]

 <Play audio="tantou" />



<br />
担ぐ (かつぐ - katsugu - to shoulder, to carry on one's shoulder, to take someone for a ride, to deceive, to take in)


 [gv]

 <Play audio="katsugu" />



<br />
担う (になう - ninau - to carry on shoulder, to bear (burden), to shoulder)


 [gv]

 <Play audio="ninau" />

</td></tr></tbody></table></td>
</tr>


<tr>
<th><span style={{fontSize:`4rem`}}>拝</span></th>
<td>



	
		ハイ(hai)
		
	

</td>
<td>



	
		おが・む (ogamu)
		
	

</td>
<td>
Worship
<br />
<table><tbody><tr><td>Examples:




<br />
礼拝 (れいはい - reihai - only worship, adoration, divine service, worship)


 [n]

 <Play audio="reihai" />



<br />
拝む (おがむ - ogamu - to assume the posture of praying, to press the palms and fingers of both hands together, to do reverence, to pay one's respects, to beg, to make a supplication, (sometimes used sarcastically in modern Japanese) to see (something or someone of high status))


 [gv]

 <Play audio="ogamu" />

</td></tr></tbody></table></td>
</tr>


<tr>
<th><span style={{fontSize:`4rem`}}>拡</span></th>
<td>



	
		カク　(kaku)
		
	

</td>
<td>



	
		ひろ・げる(hirogeru)
		
	

</td>
<td>
Broaden
<br />
<table><tbody><tr><td>Examples:




<br />
拡大 (かくだい - kakudai - magnification, enlargement, expansion, amplification)


 [n]

 <Play audio="kakudai" />



<br />
拡げる (ひろげる - hirogeru - to spread, to extend, to expand, to enlarge, to widen, to broaden, to unfold, to open, to unroll, to unwrap, to scatter about, to spread around, to make flourish, to cause to prosper)


 [iv]

 <Play audio="hirogeru" />

</td></tr></tbody></table></td>
</tr>


<tr>
<th><span style={{fontSize:`4rem`}}>捨</span></th>
<td>



	
		シャ (sha)
		
	

</td>
<td>



	
		す・てる (suteru)
		
	

</td>
<td>
Throw Away
<br />
<table><tbody><tr><td>Examples:




<br />
捨象 (しゃしょう - shashou - abstraction)


 [n]

 <Play audio="shashou" />



<br />
捨てる (すてる - suteru -  to throw away, to cast away, to dump, to discard, to abandon, to desert, to leave, to give up, to resign)


 [iv]

 <Play audio="suteru" />

</td></tr></tbody></table></td>
</tr>


<tr>
<th><span style={{fontSize:`4rem`}}>探</span></th>
<td>



	
		タン (tan)
		
	

</td>
<td>



	
		さが・す (sagasu)
		
	

</td>
<td>
Grope
<br />
<table><tbody><tr><td>Examples:




<br />
探求 (たんきゅう - tankyuu -  search, research, enquiry, inquiry)


 [n]

 <Play audio="tankyuu" />



<br />
探す (さがす - sagasu -  to search (for something lost), to seek (a suspect in a crime), to search (for something desired, needed), to look for)


 [gv]

 <Play audio="sagasu" />

</td></tr></tbody></table></td>
</tr>


<tr>
<th><span style={{fontSize:`4rem`}}>推</span></th>
<td>



	
		スイ (sui)
		
	

</td>
<td>



	
		お・す (osu)
		
	

</td>
<td>
Infer
<br />
<table><tbody><tr><td>Examples:




<br />
推進 (すいしん - suishin -  propulsion, driving force, implementation, promotion)


 [n]

 <Play audio="suishin" />



<br />
推す (おす - osu - to recommend, to endorse (e.g. a candidate), to nominate, to infer, to conclude, to conjecture, to surmise, to think something through, to ponder deeply)


 [gv]

 <Play audio="osu" />

</td></tr></tbody></table></td>
</tr>


<tr>
<th><span style={{fontSize:`4rem`}}>揮</span></th>
<td>



	
		キ (ki)
		
	

</td>
<td>



</td>
<td>
Command
<br />
<table><tbody><tr><td>Examples:




<br />
指揮 (しき - shiki - command, direction)


 [n]

 <Play audio="shiki" />

</td></tr></tbody></table></td>
</tr>


<tr>
<th><span style={{fontSize:`4rem`}}>操</span></th>
<td>



	
		ソウ (sou)
		
	

</td>
<td>



	
		あやつ・る (ayatsuru)
		
			<br />
		
	

	
		みさお (misao)
		
	

</td>
<td>
Maneuver
<br />
<table><tbody><tr><td>Examples:




<br />
体操 (たいそう - taisou - gymnastics, physical exercises, calisthenics)


 [n]

 <Play audio="taisou" />



<br />
操る (あやつる - ayatsuru - to manipulate, to operate, to handle, to operate (e.g. a machine), to steer, to have a good command of (a language), to be fluent in, to operate a puppet, to pull the strings of a puppet, to mastermind, to pull the strings, to control from the shadows, to play (a musical instrument))


 [gv]

 <Play audio="ayatsuru" />



<br />
操 (みさお - misao - chastity, fidelity, honour, honor, faith)


 [n]

 <Play audio="misao" />

</td></tr></tbody></table></td>
</tr>


<tr>
<th><span style={{fontSize:`4rem`}}>敬</span></th>
<td>



	
		ケイ (kei)
		
	

</td>
<td>



	
		うやま・う (uyamau)
		
	

</td>
<td>
Respect
<br />
<table><tbody><tr><td>Examples:




<br />
敬具 (けいぐ - keigu - Yours sincerely, Yours truly, Sincerely yours)


 [n]

 <Play audio="keigu" />



<br />
敬う (うやまう - uyamau - to show respect, to honor)


 [gv]

 <Play audio="uyamau" />

</td></tr></tbody></table></td>
</tr>


<tr>
<th><span style={{fontSize:`4rem`}}>映</span></th>
<td>



	
		エイ (ei)
		
	

</td>
<td>



	
		うつ・る (utsuru)
		
			<br />
		
	

	
		うつ・す (utsusu)
		
			<br />
		
	

	
		は・える (haeru)
		
	

</td>
<td>
Reflect
<br />
<table><tbody><tr><td>Examples:




<br />
映画 (えいが - eiga - movie, film)


 [n]

 <Play audio="eiga" />



<br />
映る (うつる - utsuru - to be reflected, to harmonize with, to come out (photo), to be projected, to be displayed (on a screen))


 [gv]

 <Play audio="utsuru" />



<br />
映す (うつす - utsusu - to project, to reflect, to cast (shadow))


 [gv]

 <Play audio="utsusu" />



<br />
映える (はえる - haeru - to shine, to look attractive, to look pretty)


 [iv]

 <Play audio="haeru" />

</td></tr></tbody></table></td>
</tr>


<tr>
<th><span style={{fontSize:`4rem`}}>晩</span></th>
<td>



	
		バン (ban)
		
	

</td>
<td>



</td>
<td>
Nightfall
<br />
<table><tbody><tr><td>Examples:




<br />
毎晩 (まいばん - maiban -  every night)


 [n]

 <Play audio="maiban" />

</td></tr></tbody></table></td>
</tr>


<tr>
<th><span style={{fontSize:`4rem`}}>暖</span></th>
<td>



	
		ダン (dan)
		
	

</td>
<td>



	
		あたた・か (atataka)
		
			<br />
		
	

	
		あたた・かい (atatakai)
		
			<br />
		
	

	
		あたた・まる (atatamaru)
		
			<br />
		
	

	
		あたた・める (atatameru)
		
	

</td>
<td>
Warmth
<br />
<table><tbody><tr><td>Examples:




<br />
温暖 (おんだん - ondan - warmth)


 [dn]

 <Play audio="ondan" />



<br />
暖か (あたたか - atataka - warm, mild, genial)


 [dn]

 <Play audio="atataka" />



<br />
暖かい (あたたかい - atatakai - (usu. refer to air temperature) warm, mild, genial)


 [dv]

 <Play audio="atatakai" />



<br />
暖まる (あたたまる - atatamaru - to warm oneself, to sun oneself, to warm up, to get warm)


 [gv]

 <Play audio="atatamaru" />



<br />
暖める (あたためる - atatameru - to warm, to heat)


 [iv]

 <Play audio="atatameru" />

</td></tr></tbody></table></td>
</tr>

</tbody></table>

    </>
  )
}

export default GradeSixTwo