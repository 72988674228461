import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Parts of the Body"

const bodyone = [
  {"漢字 - Kanji":"頭","ひらがな - Hiragana":"あたま","Romaji (English Letters)":"atama","audio":"atama","English Meaning":"head"},
 {"漢字 - Kanji":"髪","ひらがな - Hiragana":"かみ","Romaji (English Letters)":"kami","audio":"kami","English Meaning":"hair"},
 {"漢字 - Kanji":"髪の毛","ひらがな - Hiragana":"かみのけ","Romaji (English Letters)":"kami no ke","audio":"kami no ke","English Meaning":"hair (on one's head)"},
 {"漢字 - Kanji":"額","ひらがな - Hiragana":"ひたい","Romaji (English Letters)":"hitai","audio":"hitai","English Meaning":"forehead"},
 {"漢字 - Kanji":"目","ひらがな - Hiragana":"め","Romaji (English Letters)":"me","audio":"me","English Meaning":"eye"},
 {"漢字 - Kanji":"白目","ひらがな - Hiragana":"しろめ","Romaji (English Letters)":"shirome","audio":"shirome","English Meaning":"whites of the eyes"},
 {"漢字 - Kanji":"虹彩","ひらがな - Hiragana":"こうさい","Romaji (English Letters)":"kousai","audio":"kousai","English Meaning":"iris (of the eye)"},
 {"漢字 - Kanji":"瞳","ひらがな - Hiragana":"ひとみ","Romaji (English Letters)":"hitomi","audio":"hitomi","English Meaning":"pupil (of the eye)"},
 {"漢字 - Kanji":"眉毛","ひらがな - Hiragana":"まゆげ","Romaji (English Letters)":"mayuge","audio":"mayuge","English Meaning":"eyebrows"},
 {"漢字 - Kanji":"睫毛","ひらがな - Hiragana":"まつげ","Romaji (English Letters)":"matsuge","audio":"matsuge","English Meaning":"eyelashes"},
 {"漢字 - Kanji":"鼻","ひらがな - Hiragana":"はな","Romaji (English Letters)":"hana","audio":"hana","English Meaning":"nose"},
 {"漢字 - Kanji":"鼻の穴","ひらがな - Hiragana":"はなのあな","Romaji (English Letters)":"hana no ana","audio":"hana no ana","English Meaning":"nostril"},
 {"漢字 - Kanji":"口","ひらがな - Hiragana":"くち","Romaji (English Letters)":"kuchi","audio":"kuchi","English Meaning":"mouth"},
 {"漢字 - Kanji":"唇","ひらがな - Hiragana":"くちびる","Romaji (English Letters)":"kuchibiru","audio":"kuchibiru","English Meaning":"lips"},
 {"漢字 - Kanji":"舌","ひらがな - Hiragana":"した","Romaji (English Letters)":"shita","audio":"shita","English Meaning":"tongue"},
 {"漢字 - Kanji":"歯","ひらがな - Hiragana":"は","Romaji (English Letters)":"ha","audio":"ha","English Meaning":"tooth/teeth"},
 {"漢字 - Kanji":"顎","ひらがな - Hiragana":"あご","Romaji (English Letters)":"ago","audio":"ago","English Meaning":"chin, jaw"},
 {"漢字 - Kanji":"髭","ひらがな - Hiragana":"ひげ","Romaji (English Letters)":"hige","audio":"hige","English Meaning":"moustache, beard, whiskers"},
 {"漢字 - Kanji":"耳","ひらがな - Hiragana":"みみ","Romaji (English Letters)":"mimi","audio":"mimi","English Meaning":"ear"},
 {"漢字 - Kanji":"耳朶","ひらがな - Hiragana":"みみたぶ","Romaji (English Letters)":"mimitabu","audio":"mimitabu","English Meaning":"earlobe"},
 {"漢字 - Kanji":"耳の穴","ひらがな - Hiragana":"みみのあな","Romaji (English Letters)":"mimi no ana","audio":"mimi no ana","English Meaning":"ear hole"},
 {"漢字 - Kanji":"頬","ひらがな - Hiragana":"ほお","Romaji (English Letters)":"hoo","audio":"hoo","English Meaning":"cheek"},
 {"漢字 - Kanji":"頬っぺた","ひらがな - Hiragana":"ほっぺた","Romaji (English Letters)":"hoppeta","audio":"hoppeta","English Meaning":"cheek (more informal)"},
 {"漢字 - Kanji":"首","ひらがな - Hiragana":"くび","Romaji (English Letters)":"kubi","audio":"kubi","English Meaning":"neck"},
 {"漢字 - Kanji":"喉","ひらがな - Hiragana":"のど","Romaji (English Letters)":"nodo","audio":"nodo","English Meaning":"throat"},
 {"漢字 - Kanji":"肩","ひらがな - Hiragana":"かた","Romaji (English Letters)":"kata","audio":"kata","English Meaning":"shoulder"},
 {"漢字 - Kanji":"脇の下","ひらがな - Hiragana":"わきのした","Romaji (English Letters)":"waki no shita","audio":"waki no shita","English Meaning":"armpit, underarm"},
 {"漢字 - Kanji":"腕","ひらがな - Hiragana":"うで","Romaji (English Letters)":"ude","audio":"ude","English Meaning":"arm"},
 {"漢字 - Kanji":"二の腕","ひらがな - Hiragana":"にのうで","Romaji (English Letters)":"ni no ude","audio":"ni no ude","English Meaning":"upper arm"},
 {"漢字 - Kanji":"肘","ひらがな - Hiragana":"ひじ","Romaji (English Letters)":"hiji","audio":"hiji","English Meaning":"elbow"},
 {"漢字 - Kanji":"前腕","ひらがな - Hiragana":"ぜんわん","Romaji (English Letters)":"zenwan","audio":"zenwan","English Meaning":"forearm"},
 {"漢字 - Kanji":"手","ひらがな - Hiragana":"て","Romaji (English Letters)":"te","audio":"te","English Meaning":"hand"},
 {"漢字 - Kanji":"手首","ひらがな - Hiragana":"てくび","Romaji (English Letters)":"tekubi","audio":"tekubi","English Meaning":"wrist"},
 {"漢字 - Kanji":"手の平","ひらがな - Hiragana":"てのひら","Romaji (English Letters)":"te no hira","audio":"te no hira","English Meaning":"palm"},
 {"漢字 - Kanji":"指","ひらがな - Hiragana":"ゆび","Romaji (English Letters)":"yubi","audio":"yubi","English Meaning":"finger"},
 {"漢字 - Kanji":"親指","ひらがな - Hiragana":"おやゆび","Romaji (English Letters)":"oyayubi","audio":"oyayubi","English Meaning":"thumb"},
 {"漢字 - Kanji":"指の節","ひらがな - Hiragana":"ゆびのふし","Romaji (English Letters)":"yubi no fushi","audio":"yubi no fushi","English Meaning":"knuckle"},
 {"漢字 - Kanji":"爪","ひらがな - Hiragana":"つめ","Romaji (English Letters)":"tsume","audio":"tsume","English Meaning":"fingernail/toenail"},
 {"漢字 - Kanji":"背中","ひらがな - Hiragana":"せなか","Romaji (English Letters)":"senaka","audio":"senaka","English Meaning":"back (of body)"},
 {"漢字 - Kanji":"腰","ひらがな - Hiragana":"こし","Romaji (English Letters)":"koshi","audio":"koshi","English Meaning":"hip"},
 {"漢字 - Kanji":"お尻","ひらがな - Hiragana":"おしり","Romaji (English Letters)":"oshiri","audio":"oshiri","English Meaning":"butt"},
 {"漢字 - Kanji":"脚","ひらがな - Hiragana":"あし","Romaji (English Letters)":"ashi","audio":"ashi","English Meaning":"leg"},
 {"漢字 - Kanji":"太股","ひらがな - Hiragana":"ふともも","Romaji (English Letters)":"futomomo","audio":"futomomo","English Meaning":"thigh"},
 {"漢字 - Kanji":"膝","ひらがな - Hiragana":"ひざ","Romaji (English Letters)":"hiza","audio":"hiza","English Meaning":"knee"},
 {"漢字 - Kanji":"脹脛","ひらがな - Hiragana":"ふくらはぎ","Romaji (English Letters)":"fukurahagi","audio":"fukurahagi","English Meaning":"calf (of leg)"},
 {"漢字 - Kanji":"足首","ひらがな - Hiragana":"あしくび","Romaji (English Letters)":"ashikubi","audio":"ashikubi","English Meaning":"ankle"},
 {"漢字 - Kanji":"アキレス腱","ひらがな - Hiragana":"あきれすけん","Romaji (English Letters)":"akiresuken","audio":"akiresuken","English Meaning":"Achilles tendon, Achilles' heel (vulnerable point)"},
 {"漢字 - Kanji":"足","ひらがな - Hiragana":"あし","Romaji (English Letters)":"ashi","audio":"ashi","English Meaning":"foot"},
 {"漢字 - Kanji":"足指","ひらがな - Hiragana":"あしゆび","Romaji (English Letters)":"ashiyubi","audio":"ashiyubi","English Meaning":"toe"},
 {"漢字 - Kanji":"足の裏","ひらがな - Hiragana":"あしのうら","Romaji (English Letters)":"ashi no ura","audio":"ashi no ura","English Meaning":"sole of foot"},
 {"漢字 - Kanji":"脳","ひらがな - Hiragana":"のう","Romaji (English Letters)":"nou","audio":"nou","English Meaning":"brain"},
 {"漢字 - Kanji":"心臓","ひらがな - Hiragana":"しんぞう","Romaji (English Letters)":"shinzou","audio":"shinzou","English Meaning":"heart (organ)"},
 {"漢字 - Kanji":"肺","ひらがな - Hiragana":"はい","Romaji (English Letters)":"hai","audio":"hai","English Meaning":"lung"},
 {"漢字 - Kanji":"肝臓","ひらがな - Hiragana":"かんぞう","Romaji (English Letters)":"kanzou","audio":"kanzou","English Meaning":"liver"},
 {"漢字 - Kanji":"胆嚢","ひらがな - Hiragana":"たんのう","Romaji (English Letters)":"tannou","audio":"tannou","English Meaning":"gall bladder"},
 {"漢字 - Kanji":"脾臓","ひらがな - Hiragana":"ひぞう","Romaji (English Letters)":"hizou","audio":"hizou","English Meaning":"spleen"},
 {"漢字 - Kanji":"腎臓","ひらがな - Hiragana":"じんぞう","Romaji (English Letters)":"jinzou","audio":"jinzou","English Meaning":"kidney"},
 {"漢字 - Kanji":"膵臓","ひらがな - Hiragana":"すいぞう","Romaji (English Letters)":"suizou","audio":"suizou","English Meaning":"pancreas"},
 {"漢字 - Kanji":"胃","ひらがな - Hiragana":"い","Romaji (English Letters)":"i","audio":"i","English Meaning":"stomach"},
 {"漢字 - Kanji":"お腹","ひらがな - Hiragana":"おなか","Romaji (English Letters)":"onaka","audio":"onaka","English Meaning":"belly, stomach(this is what you would use for \"my stomach hurts\" (onaka ga itai) or \"I'm hungry\" (onaka ga suita))"},
 {"漢字 - Kanji":"腸","ひらがな - Hiragana":"ちょう","Romaji (English Letters)":"chou","audio":"chou","English Meaning":"intestines"},
 {"漢字 - Kanji":"小腸","ひらがな - Hiragana":"しょうちょう","Romaji (English Letters)":"shouchou","audio":"shouchou","English Meaning":"small intestine"},
 {"漢字 - Kanji":"大腸","ひらがな - Hiragana":"だいちょう","Romaji (English Letters)":"daichou","audio":"daichou","English Meaning":"large intestine"},
 {"漢字 - Kanji":"結腸","ひらがな - Hiragana":"けっちょう","Romaji (English Letters)":"kecchou","audio":"kecchou","English Meaning":"colon"},
 {"漢字 - Kanji":"骨","ひらがな - Hiragana":"ほね","Romaji (English Letters)":"hone","audio":"hone","English Meaning":"bone"},
 {"漢字 - Kanji":"皮膚","ひらがな - Hiragana":"ひふ","Romaji (English Letters)":"hifu","audio":"hifu","English Meaning":"skin"},
 {"漢字 - Kanji":"血","ひらがな - Hiragana":"ち","Romaji (English Letters)":"chi","audio":"chi","English Meaning":"blood"},
 {"漢字 - Kanji":"血管","ひらがな - Hiragana":"けっかん","Romaji (English Letters)":"kekkan","audio":"kekkan","English Meaning":"blood vessel"},
 {"漢字 - Kanji":"赤血球","ひらがな - Hiragana":"せっけっきゅう","Romaji (English Letters)":"sekkekkyuu","audio":"sekkekkyuu","English Meaning":"red blood cell"},
 {"漢字 - Kanji":"白血球","ひらがな - Hiragana":"はっけっきゅう","Romaji (English Letters)":"hakkekkyuu","audio":"hakkekkyuu","English Meaning":"white blood cell"},
 {"漢字 - Kanji":"筋肉","ひらがな - Hiragana":"きんにく","Romaji (English Letters)":"kinniku","audio":"kinniku","English Meaning":"muscle, sinew"}
]
const bodytwo = [
  {"Kanji":"痛い","Hiragana":"いたい","Romaji":"itai","English Meaning":"painful, sore [dv] ","audio":"itai"},
 {"Kanji":"心臓発作","Hiragana":"しんぞうほっさ","Romaji":"shinzouhossa","English Meaning":"heart attack [n] ","audio":"shinzouhossa"},
 {"Kanji":"起こる","Hiragana":"おこる","Romaji":"okoru","English Meaning":"to occur, to happen [gv] ","audio":"okoru"},
 {"Kanji":"切る","Hiragana":"きる","Romaji":"kiru","English Meaning":"to cut, to sever [gv] ","audio":"kiru"},
 {"Kanji":"","Hiragana":"Base \"te\" + しまう","Romaji":"Base \"te\" + shimau","English Meaning":"**See Below** [gv] ","audio":"shimau"},
 {"Kanji":"落ちる","Hiragana":"おちる","Romaji":"ochiru","English Meaning":"to fall down, to drop [iv] ","audio":"ochiru"},
 {"Kanji":"折る","Hiragana":"おる","Romaji":"oru","English Meaning":"to break, to fracture [gv] ","audio":"oru"},
 {"Kanji":"なくなる","Hiragana":"なくなる","Romaji":"naku naru","English Meaning":"to pass away **See Below** [gv] ","audio":"naku naru"},
 {"Kanji":"避ける","Hiragana":"さける","Romaji":"sakeru","English Meaning":"to avoid [iv] ","audio":"sakeru"},
 {"Kanji":"歯医者","Hiragana":"はいしゃ","Romaji":"haisha","English Meaning":"dentist [n] ","audio":"haisha"},
 {"Kanji":"虫歯","Hiragana":"むしば","Romaji":"mushiba","English Meaning":"cavity, tooth decay [n] ","audio":"mushiba"},
 {"Kanji":"虫","Hiragana":"むし","Romaji":"mushi","English Meaning":"insect, bug [n] ","audio":"mushi"},
 {"Kanji":"みたい","Hiragana":"みたい","Romaji":"mitai","English Meaning":"-like (suffix), looks like, sort of, similar to, resembling [dn] ","audio":"mitai"},
 {"Kanji":"鳥肌","Hiragana":"とりはだ","Romaji":"torihada","English Meaning":"goosebumps, gooseflesh, goose pimples, chicken skin [n] ","audio":"torihada"},
 {"Kanji":"鳥肌が立つ","Hiragana":"とりはだがたつ","Romaji":"torihada ga tatsu","English Meaning":"to get goosebumps [gv] ","audio":"torihada ga tatsu"},
 {"Kanji":"立つ","Hiragana":"たつ","Romaji":"tatsu","English Meaning":"to stand, to rise, to stand up [gv] ","audio":"tatsu"},
 {"Kanji":"捻る","Hiragana":"ひねる","Romaji":"hineru","English Meaning":"to twist, to wrench [gv] ","audio":"hineru"},
 {"Kanji":"腫れる","Hiragana":"はれる","Romaji":"hareru","English Meaning":"to swell (from inflammation), to become swollen [iv] ","audio":"hareru"}
]

const BodyParts = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    
    <NavBar level="Beginner" category="Vocabulary" lesson={title} />

      <h1>{title}</h1>

<p>Below are a number of Japanese vocabulary words and phrases associated with the body. All words are nouns unless otherwise specified. As with most Japanese nouns, the plural is the same as the singluar. Please note that the example sentences will be done in plain form with the polite form in parentheses.</p>

<Table data={bodyone} />

<p>Here are some example sentences:</p>

<table>
<thead>
<tr>
<th>Japanese (In Kanji, Hiragana, and Romaji)</th>
<th>English Meaning</th>
</tr>
</thead>
<tbody>
<tr>
<td>

背中が痛い(です)。
<br />
せなかがいたい(です)。
<br />
<Play audio="senaka ga itai" /> (<Play audio="senaka ga itai desu" />)

</td>
<td>
My back hurts.
</td>
</tr>
<tr>
<td>

彼は心臓発作が起こった(起こりました)。
<br />
かれはしんぞうほっさがおこった(おこりました)。
<br />
<Play audio="kare wa shinzouhossa ga okotta" /> (<Play audio="kare wa shinzouhossa ga okorimashita" />)

</td>
<td>
He had a heart attack.
</td>
</tr><tr>
<td>

アキレス腱を切ってしまった(しまいました)。
<br />
あきれすけんをきってしまった(しまいました)。
<br />
<Play audio="akiresuken o kitte shimatta" /> (<Play audio="akiresuken o kitte shimaimashita" />)

</td>
<td>
I tore my Achilles tendon!
</td>
</tr><tr>
<td>

彼女は木から落ちて脚を折った(折りました)。
<br />
かのじょはきからおちてあしをおった(おりました)。
<br />
<Play audio="kanojo wa ki kara ochite ashi o otta" /> (<Play audio="kanojo wa ki kara ochite ashi o orimashita" />)

</td>
<td>
She fell from a tree and broke her leg.
</td>
</tr><tr>
<td>

彼のお爺さんがなくなった(なりました)。
<br />
かれのおじいさんがなくなった(なりました)。
<br />
<Play audio="kare no ojiisan ga naku natta" /> (<Play audio="kare no ojiisan ga naku narimashita" />)

</td>
<td>
His grandfather passed away.
</td>
</tr><tr>
<td>

猫舌の人は熱いものを避けたほうがいい(です)。
<br />
ねこじたのひとはあついものをさけたほうがいい(です)。
<br />
<Play audio="nekojita no hito wa atsui mono o saketa hou ga ii" /> (<Play audio="nekojita no hito wa atsui mono o saketa hou ga ii desu" />)

</td>
<td>
People with "sensitive tongues" should avoid hot food.
</td>
</tr><tr>
<td>

歯医者に行って虫歯があるみたいだ(です)。
<br />
はいしゃにいってむしばがあるみたいだ(です)。
<br />
<Play audio="haisha ni itte mushiba ga aru mitai da" /> (<Play audio="haisha ni itte mushiba ga aru mitai desu" />)

</td>
<td>
I went to the dentist and it looks like I have cavities.
</td>
</tr><tr>
<td>

鳥肌が立つ程おびえていた(いました)。
<br />
とりはだがたつほどおびえていた(いました)。
<br />
<Play audio="torihada ga tatsu hodo obiete ita" /> (<Play audio="torihada ga tatsu hodo obiete imashita" />)

</td>
<td>
That gave me goosebumps.
<br />
(I was frightened to the point of goosebumps.)
</td>
</tr><tr>
<td>

足首を捻って腫れた(腫れました)。
<br />
あしくびをひねってはれた(はれました)。
<br />
<Play audio="ashikubi o hinette hareta" /> (<Play audio="ashikubi o hinette haremashita" />)

</td>
<td>
I twisted my ankle and it swelled up.
</td>
</tr>
</tbody>
</table>

<Table data={bodytwo} />

<h2>Base "te" + shimau</h2>

<p>Base "te" + shimau has two potential meanings based on context:</p>

<p><strong>1.</strong> verbed completely - sono mizu o zenbu nonde shimatta = I completely drank all of that water.</p>

<p><strong>2.</strong> verbed accidentally (something was done that shouldn't have been or something happened that shouldn't have) - See the Achilles tendon example above. The example used in meaning #1 could also be interpreted in this way (I accidentally drank all of that water) if the person is showing remorse.</p>

<h2>Naku naru</h2>

<p>"Nai" is the negative form of "aru" (to exist - inanimate). It can now be conjugated like a "dv" so, by removing the "i" and replacing it with "ku", we have an adverb form. "Naru" is "to become" so "naku naru" is literally "to become non-existent" - it is a much more polite way of saying someone died than using the verb 死ぬ (shinu - to die). But why isn't the verb "iru" (to exist - animate) used in this case since he was alive? Using the verb "iru" indicates that he is still alive and "inaku natta" means "he left/he is no longer here".</p>

    </>
  )
}

export default BodyParts