import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Godan Verbs"

const godanone = [
  {"漢字 - Kanji":"話す","ひらがな - Hiragana":"はなす","Romaji (English Letters)":"hanasu  (to speak)","audio":"hanasu","Verb Base":""},
 {"漢字 - Kanji":"話さ","ひらがな - Hiragana":"はなさ","Romaji (English Letters)":"hanasa","audio":"hanasa","Verb Base":"Base 1"},
 {"漢字 - Kanji":"話し","ひらがな - Hiragana":"はなし","Romaji (English Letters)":"hanashi","audio":"hanashi","Verb Base":"Base 2"},
 {"漢字 - Kanji":"話す","ひらがな - Hiragana":"はなす","Romaji (English Letters)":"hanasu","audio":"hanasu","Verb Base":"Base 3"},
 {"漢字 - Kanji":"話せ","ひらがな - Hiragana":"はなせ","Romaji (English Letters)":"hanase","audio":"hanase","Verb Base":"Base 4"},
 {"漢字 - Kanji":"話そう","ひらがな - Hiragana":"はなそう","Romaji (English Letters)":"hanasou","audio":"hanasou","Verb Base":"Base 5"},
 {"漢字 - Kanji":"話して","ひらがな - Hiragana":"はなして","Romaji (English Letters)":"hanashite","audio":"hanashite","Verb Base":"Base \"te\""},
 {"漢字 - Kanji":"話した","ひらがな - Hiragana":"はなした","Romaji (English Letters)":"hanashita","audio":"hanashita","Verb Base":"Base \"ta\""}
]
const godantwo = [
  {"漢字 - Kanji":"会う","ひらがな - Hiragana":"あう","Romaji (English Letters)":"au","audio":"au","English Meaning":"to meet"},
 {"漢字 - Kanji":"勝つ","ひらがな - Hiragana":"かつ","Romaji (English Letters)":"katsu","audio":"katsu","English Meaning":"to win"},
 {"漢字 - Kanji":"売る","ひらがな - Hiragana":"うる","Romaji (English Letters)":"uru","audio":"uru","English Meaning":"to sell"},
 {"漢字 - Kanji":"遊ぶ","ひらがな - Hiragana":"あそぶ","Romaji (English Letters)":"asobu","audio":"asobu","English Meaning":"to play"},
 {"漢字 - Kanji":"飲む","ひらがな - Hiragana":"のむ","Romaji (English Letters)":"nomu","audio":"nomu","English Meaning":"to drink"},
 {"漢字 - Kanji":"死ぬ","ひらがな - Hiragana":"しぬ","Romaji (English Letters)":"shinu","audio":"shinu","English Meaning":"to die"},
 {"漢字 - Kanji":"歩く","ひらがな - Hiragana":"あるく","Romaji (English Letters)":"aruku","audio":"aruku","English Meaning":"to walk"},
 {"漢字 - Kanji":"泳ぐ","ひらがな - Hiragana":"およぐ","Romaji (English Letters)":"oyogu","audio":"oyogu","English Meaning":"to swim"},
 {"漢字 - Kanji":"消す","ひらがな - Hiragana":"けす","Romaji (English Letters)":"kesu","audio":"kesu","English Meaning":"to erase, turn off"},
 {"漢字 - Kanji":"行く","ひらがな - Hiragana":"いく","Romaji (English Letters)":"iku","audio":"iku","English Meaning":"to go(Remember the exception for Bases \"te\"and \"ta\")"}
]

const GodanVerbs = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Beginner" category="Grammar" lesson={title} />
     <div className={'title'}>
     Verb Conjugation
      </div>
      <h1>{title}</h1>

<p>Now, before you can start making up sentences of your own, you need to learn how to conjugate Japanese verbs. Verbs are the most important part of the Japanese sentence. Often times Japanese people will leave out everything but the verb.</p>

<p>They are very big on leaving out the obvious and sometimes not so obvious which can get confusing at times. There are only 3 types of verbs in the Japanese language and they each follow a pattern that is very simple and very rarely has any exceptions. Most Japanese verbs fall into the first group, the <Play audio="godan" /> (五段)  verbs. These verbs always conjugate the same way with only one exception. These verbs have five changes that follow the order of the Japanese vowels (i.e. the characters for that section of the hiragana chart), hence the name Godan (meaning 5 levels or steps), and then the "te" and "ta" forms that are common to all verbs. The chart below shows how to conjugate Japanese Godan verbs:</p>

<Table data={godanone} />

<p><strong>Base 1:</strong> Base 1 can not be used by itself but becomes the plain form negative simply by adding -nai. (ex. <Play audio="hanasanai" />  - I won't say anything.) If the verb ends in う <Play audio="u" />  then the end for Base 1 becomes わ <Play audio="wa" /> . (ex. au (Base 1) - <Play audio="awa" /> )
(Plain form is what people use when talking to a friend. It would not be proper to use in a business environment. We will go over the polite form in Using the Verb Bases - Part 1.)</p>

<p><strong>Base 2:</strong> Base 2 is, in most cases, a noun when used by itself but is primarily used with the polite form of the verb.</p>

<p><strong>Base 3:</strong> Base 3 is the main form (the one that would be found in the dictionary) and is also the plain form present/future tense.</p>

<p><strong>Base 4:</strong> Base 4 is most often used as "if verb" by adding -ba. (ex. <Play audio="hanaseba" />  - If he'd just say something.) It can also be used by itself as a command form but it is extremely rude and I recommend not using it at all.</p>

<p><strong>Base 5:</strong> Base 5 is used by itself as the "let's" form. (ex. <Play audio="hanasou" />  - Let's talk.) We will get into other ways it's used in later lessons.</p>

<p><strong>Base "te":</strong> Base "te" can be used by itself as a plain form command. It is not rude but should only be used with close friends and children. By adding <Play audio="kudasai" />  it becomes the polite form command. Base "te" can also be used in other ways that we will get into in later lessons.</p>

<p><strong>Base "ta":</strong> Base "ta" is merely Base "te" with an "a" sound on the end instead of an "e" sound. It is mainly used by itself as the plain form past tense. (ex. <Play audio="hanashita" />  - I talked.) We will get into other ways it's used in later lessons.</p>

<p><strong>Notice:</strong> There is one thing that you'll need to learn in order to conjugate the "te" and "ta" forms correctly. Basically, for all Godan verbs ending in う (u), つ (tsu), or る (ru); the う (u), つ (tsu), or る (ru) becomes って (tte) in the "te" form and った (tta) in the "ta" form. (ex. <Play audio="katsu" />  (to win) - <Play audio="katte" />  (Win!), <Play audio="katta" />  (We won!))</p>

<p>For all Godan verbs ending in ぶ (bu), む (mu), or ぬ (nu); the ぶ (bu), む (mu), or ぬ (nu) becomes んで (nde) in the "te" form and んだ (nda) in the "ta" form. (ex. <Play audio="yomu" />  (to read - <Play audio="yonde" />  (Read it.), <Play audio="yonda" />  (I read it.))</p>

<p>For all Godan verbs ending in く (ku), the く (ku) becomes いて (ite) in the "te" form and いた (ita) in the "ta" form. (ex. <Play audio="aruku" />  (to walk) - <Play audio="aruite" />  (Walk!), <Play audio="aruita" />  (I walked here.)) The only exception to this rule is for the verb iku (to go) which becomes いって, いった (<Play audio="itte" />/<Play audio="itta" /> ).</p>

<p>For all Godan verbs ending in ぐ (gu), the ぐ (gu) becomes いで (ide) in the "te" form and いだ (ida) in the "ta" form. (ex. <Play audio="oyogu" />  (to swim) - <Play audio="oyoide" />  (Swim!), <Play audio="oyoida" /> (I swam.))</p>

<p>For all Godan verbs ending in す (su), the す (su) becomes して (shite) in the "te" form and した (shita) in the "ta" form. (ex. <Play audio="hanasu" />  (to talk) - <Play audio="hanashite" />  (Say something!), <Play audio="hanashita" />  (I talked (to him).))</p>

<p>Here are some Godan verbs. Try conjugating them on a piece of paper using what you have just learned. Click here for the answers.</p>

<Table data={godantwo} />

    </>
  )
}

export default GodanVerbs