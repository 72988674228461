import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "More Words That Sound Similar"

const similarone = [
  {"漢字 - Kanji":"上がる","ひらがな - Hiragana":"あがる","Romaji (English Letters)":"agaru","audio":"agaru","English Meaning":"to rise, to go up, to ascend, to be raised [gv]"},
 {"漢字 - Kanji":"挙がる","ひらがな - Hiragana":"あがる","Romaji (English Letters)":"agaru","audio":"agaru","English Meaning":"to be arrested, to turn up (of evidence, etc.), to be listed (as a candidate) [gv]"},
 {"漢字 - Kanji":"揚がる","ひらがな - Hiragana":"あがる","Romaji (English Letters)":"agaru","audio":"agaru","English Meaning":"to be deep fried [gv]"},
 {"漢字 - Kanji":"上げる","ひらがな - Hiragana":"あげる","Romaji (English Letters)":"ageru","audio":"ageru","English Meaning":"to raise, to elevate [iv]"},
 {"漢字 - Kanji":"挙げる","ひらがな - Hiragana":"あげる","Romaji (English Letters)":"ageru","audio":"ageru","English Meaning":"to nominate [iv]"},
 {"漢字 - Kanji":"揚げる","ひらがな - Hiragana":"あげる","Romaji (English Letters)":"ageru","audio":"ageru","English Meaning":"to deep-fry [iv]"},
 {"漢字 - Kanji":"赤","ひらがな - Hiragana":"あか","Romaji (English Letters)":"aka","audio":"aka","English Meaning":"red, crimson, scarlet [n]"},
 {"漢字 - Kanji":"垢","ひらがな - Hiragana":"あか","Romaji (English Letters)":"aka","audio":"aka","English Meaning":"dirt, filth, grime [n]"},
 {"漢字 - Kanji":"秋","ひらがな - Hiragana":"あき","Romaji (English Letters)":"aki","audio":"aki","English Meaning":"autumn, fall [n]"},
 {"漢字 - Kanji":"空き","ひらがな - Hiragana":"あき","Romaji (English Letters)":"aki","audio":"aki","English Meaning":"space, room, emptiness, gap, opening, vacancy, empty seat, free time, time to spare [n]"},
 {"漢字 - Kanji":"飽き","ひらがな - Hiragana":"あき","Romaji (English Letters)":"aki","audio":"aki","English Meaning":"weariness, tiresomeness [n]"},
 {"漢字 - Kanji":"悪","ひらがな - Hiragana":"あく","Romaji (English Letters)":"aku","audio":"aku","English Meaning":"evil, wickedness [dn]"},
 {"漢字 - Kanji":"開く","ひらがな - Hiragana":"あく","Romaji (English Letters)":"aku","audio":"aku","English Meaning":"to open (e.g. doors, business, etc.) [gv]"},
 {"漢字 - Kanji":"空く","ひらがな - Hiragana":"あく","Romaji (English Letters)":"aku","audio":"aku","English Meaning":"to be empty, to be vacant, to be available, to be free [gv]"},
 {"漢字 - Kanji":"握手","ひらがな - Hiragana":"握手","Romaji (English Letters)":"akushu","audio":"akushu","English Meaning":"handshake [n]"},
 {"漢字 - Kanji":"拍手","ひらがな - Hiragana":"はくしゅ","Romaji (English Letters)":"hakushu","audio":"hakushu","English Meaning":"clapping hands, applause [n]"},
 {"漢字 - Kanji":"後","ひらがな - Hiragana":"あと","Romaji (English Letters)":"ato","audio":"ato","English Meaning":"behind, rear, after, later [n]"},
 {"漢字 - Kanji":"跡","ひらがな - Hiragana":"あと","Romaji (English Letters)":"ato","audio":"ato","English Meaning":"trace, tracks, mark, sign, remains, ruins [n]"},
 {"漢字 - Kanji":"厚い","ひらがな - Hiragana":"あつい","Romaji (English Letters)":"atsui","audio":"atsui","English Meaning":"thick, deep [dv]"},
 {"漢字 - Kanji":"暑い","ひらがな - Hiragana":"あつい","Romaji (English Letters)":"atsui","audio":"atsui","English Meaning":"hot (weather, etc.), warm [dv]"},
 {"漢字 - Kanji":"熱い","ひらがな - Hiragana":"あつい","Romaji (English Letters)":"atsui","audio":"atsui","English Meaning":"hot (thing) [dv]"},
 {"漢字 - Kanji":"会う","ひらがな - Hiragana":"あう","Romaji (English Letters)":"au","audio":"au","English Meaning":"to meet, to encounter [gv]"},
 {"漢字 - Kanji":"合う","ひらがな - Hiragana":"あう","Romaji (English Letters)":"au","audio":"au","English Meaning":"to come together, to merge, to unite [gv]"},
 {"漢字 - Kanji":"誤る","ひらがな - Hiragana":"あやまる","Romaji (English Letters)":"ayamaru","audio":"ayamaru","English Meaning":"to make a mistake, to err, to mislead, to misguide [gv]"},
 {"漢字 - Kanji":"謝る","ひらがな - Hiragana":"あやまる","Romaji (English Letters)":"ayamaru","audio":"ayamaru","English Meaning":"to apologize [gv]"},
 {"漢字 - Kanji":"電気","ひらがな - Hiragana":"でんき","Romaji (English Letters)":"denki","audio":"denki","English Meaning":"electricity, (electric) light [n]"},
 {"漢字 - Kanji":"電器","ひらがな - Hiragana":"でんき","Romaji (English Letters)":"denki","audio":"denki","English Meaning":"electric (goods) [n]"},
 {"漢字 - Kanji":"電機","ひらがな - Hiragana":"でんき","Romaji (English Letters)":"denki","audio":"denki","English Meaning":"electrical machinery, appliances [n]"},
 {"漢字 - Kanji":"伝記","ひらがな - Hiragana":"でんき","Romaji (English Letters)":"denki","audio":"denki","English Meaning":"biography, life story [n]"},
 {"漢字 - Kanji":"液","ひらがな - Hiragana":"えき","Romaji (English Letters)":"eki","audio":"eki","English Meaning":"liquid, fluid [n]"},
 {"漢字 - Kanji":"駅","ひらがな - Hiragana":"えき","Romaji (English Letters)":"eki","audio":"eki","English Meaning":"station [n]"},
 {"漢字 - Kanji":"益","ひらがな - Hiragana":"えき","Romaji (English Letters)":"eki","audio":"eki","English Meaning":"benefit, use, advantage, gain, profit [n]"},
 {"漢字 - Kanji":"円","ひらがな - Hiragana":"えん","Romaji (English Letters)":"en","audio":"en","English Meaning":"yen (Japanese monetary unit) [n]"},
 {"漢字 - Kanji":"縁","ひらがな - Hiragana":"えん","Romaji (English Letters)":"en","audio":"en","English Meaning":"fate, destiny [n]"},
 {"漢字 - Kanji":"楽器","ひらがな - Hiragana":"がっき","Romaji (English Letters)":"gakki","audio":"gakki","English Meaning":"musical instrument [n]"},
 {"漢字 - Kanji":"学期","ひらがな - Hiragana":"がっき","Romaji (English Letters)":"gakki","audio":"gakki","English Meaning":"school term, semester [n]"},
 {"漢字 - Kanji":"誤解","ひらがな - Hiragana":"ごかい","Romaji (English Letters)":"gokai","audio":"gokai","English Meaning":"misunderstanding [n]"},
 {"漢字 - Kanji":"五回","ひらがな - Hiragana":"ごかい","Romaji (English Letters)":"gokai","audio":"gokai","English Meaning":"5 times [c]"},
 {"漢字 - Kanji":"五階","ひらがな - Hiragana":"ごかい","Romaji (English Letters)":"gokai","audio":"gokai","English Meaning":"5th floor [c]"},
 {"漢字 - Kanji":"灰","ひらがな - Hiragana":"はい","Romaji (English Letters)":"hai","audio":"hai","English Meaning":"ash, ashes [n]"},
 {"漢字 - Kanji":"肺","ひらがな - Hiragana":"はい","Romaji (English Letters)":"hai","audio":"hai","English Meaning":"lung [n]"},
 {"漢字 - Kanji":"胚","ひらがな - Hiragana":"はい","Romaji (English Letters)":"hai","audio":"hai","English Meaning":"embryo (zoological or botanical), germ (e.g. wheat germ) [n]"},
 {"漢字 - Kanji":"はい","ひらがな - Hiragana":"はい","Romaji (English Letters)":"hai","audio":"hai","English Meaning":"yes, okay"},
 {"漢字 - Kanji":"履く","ひらがな - Hiragana":"はく","Romaji (English Letters)":"haku","audio":"haku","English Meaning":"to put on (or wear) lower-body clothing (i.e. pants, skirt, etc.), to put on (or wear) footwear [gv]"},
 {"漢字 - Kanji":"吐く","ひらがな - Hiragana":"はく","Romaji (English Letters)":"haku","audio":"haku","English Meaning":"to vomit, to throw up, to spit up, to emit, to send forth, to breathe out [gv]"},
 {"漢字 - Kanji":"掃く","ひらがな - Hiragana":"はく","Romaji (English Letters)":"haku","audio":"haku","English Meaning":"to sweep, to brush, to gather up [gv]"},
 {"漢字 - Kanji":"春","ひらがな - Hiragana":"はる","Romaji (English Letters)":"haru","audio":"haru","English Meaning":"spring, springtime [n]"},
 {"漢字 - Kanji":"張る","ひらがな - Hiragana":"はる","Romaji (English Letters)":"haru","audio":"haru","English Meaning":"to stretch, to spread, to strain, to tighten [gv]"},
 {"漢字 - Kanji":"貼る","ひらがな - Hiragana":"はる","Romaji (English Letters)":"haru","audio":"haru","English Meaning":"to stick, to paste, to affix, to link (e.g. in WWW forums) [gv]"},
 {"漢字 - Kanji":"一","ひらがな - Hiragana":"いち","Romaji (English Letters)":"ichi","audio":"ichi","English Meaning":"one"},
 {"漢字 - Kanji":"位置","ひらがな - Hiragana":"いち","Romaji (English Letters)":"ichi","audio":"ichi","English Meaning":"place, situation, position, location [n]"},
 {"漢字 - Kanji":"以下","ひらがな - Hiragana":"いか","Romaji (English Letters)":"ika","audio":"ika","English Meaning":"not exceeding, and downward, ... and below, below (e.g. standard), under (e.g. a level) [n]"},
 {"漢字 - Kanji":"烏賊","ひらがな - Hiragana":"いか","Romaji (English Letters)":"ika","audio":"ika","English Meaning":"cuttlefish, squid [n]"},
 {"漢字 - Kanji":"幾ら","ひらがな - Hiragana":"いくら","Romaji (English Letters)":"ikura","audio":"ikura","English Meaning":"how much?, how many?"},
 {"漢字 - Kanji":"イクラ","ひらがな - Hiragana":"いくら","Romaji (English Letters)":"ikura","audio":"ikura","English Meaning":"salted salmon roe (i.e. individual mature eggs) [n]"},
 {"漢字 - Kanji":"今","ひらがな - Hiragana":"いま","Romaji (English Letters)":"ima","audio":"ima","English Meaning":"now, the present time, just now, soon, immediately [n]"},
 {"漢字 - Kanji":"居間","ひらがな - Hiragana":"いま","Romaji (English Letters)":"ima","audio":"ima","English Meaning":"living room (western style) [n]"},
 {"漢字 - Kanji":"印象","ひらがな - Hiragana":"いんしょう","Romaji (English Letters)":"inshou","audio":"inshou","English Meaning":"impression [n]"},
 {"漢字 - Kanji":"印章","ひらがな - Hiragana":"いんしょう","Romaji (English Letters)":"inshou","audio":"inshou","English Meaning":"stamp, seal [n]"},
 {"漢字 - Kanji":"射る","ひらがな - Hiragana":"いる","Romaji (English Letters)":"iru","audio":"iru","English Meaning":"to shoot [iv]"},
 {"漢字 - Kanji":"要る","ひらがな - Hiragana":"いる","Romaji (English Letters)":"iru","audio":"iru","English Meaning":"to need, to want [gv]"},
 {"漢字 - Kanji":"煎る","ひらがな - Hiragana":"いる","Romaji (English Letters)":"iru","audio":"iru","English Meaning":"to parch, to fry, to fire, to broil, to roast, to boil down (in oil) [gv]"},
 {"漢字 - Kanji":"いる","ひらがな - Hiragana":"いる","Romaji (English Letters)":"iru","audio":"iru","English Meaning":"to exist (animate) [iv]"},
 {"漢字 - Kanji":"意思","ひらがな - Hiragana":"いし","Romaji (English Letters)":"ishi","audio":"ishi","English Meaning":"intention, purpose [n]"},
 {"漢字 - Kanji":"石","ひらがな - Hiragana":"いし","Romaji (English Letters)":"ishi","audio":"ishi","English Meaning":"stone, gem, jewel [n]"},
 {"漢字 - Kanji":"医師","ひらがな - Hiragana":"いし","Romaji (English Letters)":"ishi","audio":"ishi","English Meaning":"doctor, physician [n]"},
 {"漢字 - Kanji":"痛い","ひらがな - Hiragana":"いたい","Romaji (English Letters)":"itai","audio":"itai","English Meaning":"painful, sore [dv]"},
 {"漢字 - Kanji":"遺体","ひらがな - Hiragana":"いたい","Romaji (English Letters)":"itai","audio":"itai","English Meaning":"corpse, remains [n]"},
 {"漢字 - Kanji":"炒める","ひらがな - Hiragana":"いためる","Romaji (English Letters)":"itameru","audio":"itameru","English Meaning":"to cook, to fry, to saute, to stir-fry [iv]"},
 {"漢字 - Kanji":"痛める","ひらがな - Hiragana":"いためる","Romaji (English Letters)":"itameru","audio":"itameru","English Meaning":"to hurt, to injure, to cause pain, to worry, to bother, to afflict, to be grieved over [iv]"},
 {"漢字 - Kanji":"意図","ひらがな - Hiragana":"いと","Romaji (English Letters)":"ito","audio":"ito","English Meaning":"intention, aim, design [n]"},
 {"漢字 - Kanji":"糸","ひらがな - Hiragana":"いと","Romaji (English Letters)":"ito","audio":"ito","English Meaning":"thread, yarn, string [n]"},
 {"漢字 - Kanji":"価値","ひらがな - Hiragana":"かち","Romaji (English Letters)":"kachi","audio":"kachi","English Meaning":"value, worth, merit [n]"},
 {"漢字 - Kanji":"勝ち","ひらがな - Hiragana":"かち","Romaji (English Letters)":"kachi","audio":"kachi","English Meaning":"win, victory [n]"},
 {"漢字 - Kanji":"蛙","ひらがな - Hiragana":"かえる","Romaji (English Letters)":"kaeru","audio":"kaeru","English Meaning":"frog [n]"},
 {"漢字 - Kanji":"返る","ひらがな - Hiragana":"かえる","Romaji (English Letters)":"kaeru","audio":"kaeru","English Meaning":"to return, to come back, to go back [gv]"},
 {"漢字 - Kanji":"帰る","ひらがな - Hiragana":"かえる","Romaji (English Letters)":"kaeru","audio":"kaeru","English Meaning":"to come home, to go home [gv]"},
 {"漢字 - Kanji":"変える","ひらがな - Hiragana":"かえる","Romaji (English Letters)":"kaeru","audio":"kaeru","English Meaning":"to change, to alter, to vary, to convert, to revise, to amend, to transform [iv]"},
 {"漢字 - Kanji":"換える","ひらがな - Hiragana":"かえる","Romaji (English Letters)":"kaeru","audio":"kaeru","English Meaning":"to exchange, to interchange [iv]"},
 {"漢字 - Kanji":"替える","ひらがな - Hiragana":"かえる","Romaji (English Letters)":"kaeru","audio":"kaeru","English Meaning":"to replace [iv]"},
 {"漢字 - Kanji":"代える","ひらがな - Hiragana":"かえる","Romaji (English Letters)":"kaeru","audio":"kaeru","English Meaning":"to substitute [iv]"},
 {"漢字 - Kanji":"化学","ひらがな - Hiragana":"かがく","Romaji (English Letters)":"kagaku","audio":"kagaku","English Meaning":"chemistry [n]"},
 {"漢字 - Kanji":"科学","ひらがな - Hiragana":"かがく","Romaji (English Letters)":"kagaku","audio":"kagaku","English Meaning":"science [n]"},
 {"漢字 - Kanji":"家事","ひらがな - Hiragana":"かじ","Romaji (English Letters)":"kaji","audio":"kaji","English Meaning":"housework, domestic chores, family affairs, household matters [n]"},
 {"漢字 - Kanji":"火事","ひらがな - Hiragana":"かじ","Romaji (English Letters)":"kaji","audio":"kaji","English Meaning":"fire [n]"},
 {"漢字 - Kanji":"欠ける","ひらがな - Hiragana":"かける","Romaji (English Letters)":"kakeru","audio":"kakeru","English Meaning":"to be chipped, to be damaged, to be broken, to be lacking, to be missing, to be insufficient, to be deficient, to be negligent toward [iv]"},
 {"漢字 - Kanji":"賭ける","ひらがな - Hiragana":"かける","Romaji (English Letters)":"kakeru","audio":"kakeru","English Meaning":"to wager, to bet, to risk, to stake, to gamble [iv]"},
 {"漢字 - Kanji":"掛ける","ひらがな - Hiragana":"かける","Romaji (English Letters)":"kakeru","audio":"kakeru","English Meaning":"to multiply, to hang (e.g. picture), to secure (e.g. lock), to put on (glasses, etc.), to cover, to turn on (an engine, etc.), to set (a dial, an alarm clock, etc.), etc. [iv]"},
 {"漢字 - Kanji":"駆ける","ひらがな - Hiragana":"かける","Romaji (English Letters)":"kakeru","audio":"kakeru","English Meaning":"to run (race, esp. horse), to dash, to gallop (one's horse), to canter [iv]"},
 {"漢字 - Kanji":"架ける","ひらがな - Hiragana":"かける","Romaji (English Letters)":"kakeru","audio":"kakeru","English Meaning":"to suspend between two points, to build (a bridge, etc.), to put up on something (e.g. legs up on table) [iv]"},
 {"漢字 - Kanji":"書く","ひらがな - Hiragana":"かく","Romaji (English Letters)":"kaku","audio":"kaku","English Meaning":"to write [gv]"},
 {"漢字 - Kanji":"欠く","ひらがな - Hiragana":"かく","Romaji (English Letters)":"kaku","audio":"kaku","English Meaning":"to chip, to nick, to break, to crack, to lack [gv]"},
 {"漢字 - Kanji":"掻く","ひらがな - Hiragana":"かく","Romaji (English Letters)":"kaku","audio":"kaku","English Meaning":"to scratch, to perspire [gv]"},
 {"漢字 - Kanji":"各","ひらがな - Hiragana":"かく","Romaji (English Letters)":"kaku","audio":"kaku","English Meaning":"each, every, all [prefix]"},
 {"漢字 - Kanji":"韓国","ひらがな - Hiragana":"かんこく","Romaji (English Letters)":"kankoku","audio":"kankoku","English Meaning":"(South) Korea [n]"},
 {"漢字 - Kanji":"勧告","ひらがな - Hiragana":"かんこく","Romaji (English Letters)":"kankoku","audio":"kankoku","English Meaning":"advice, counsel, remonstrance, recommendation [n]"},
 {"漢字 - Kanji":"可能","ひらがな - Hiragana":"かのう","Romaji (English Letters)":"kanou","audio":"kanou","English Meaning":"potential, possible, practicable, feasible [dn]"},
 {"漢字 - Kanji":"化膿","ひらがな - Hiragana":"かのう","Romaji (English Letters)":"kanou","audio":"kanou","English Meaning":"suppuration, festering, coming to head [n]"},
 {"漢字 - Kanji":"乾燥","ひらがな - Hiragana":"かんそう","Romaji (English Letters)":"kansou","audio":"kansou","English Meaning":"dryness, aridity, insipidity, dehydration, desiccation [n]"},
 {"漢字 - Kanji":"感想","ひらがな - Hiragana":"かんそう","Romaji (English Letters)":"kansou","audio":"kansou","English Meaning":"impressions, thoughts [n]"},
 {"漢字 - Kanji":"簡単","ひらがな - Hiragana":"かんたん","Romaji (English Letters)":"kantan","audio":"kantan","English Meaning":"simple, easy, brief, uncomplicated [dn]"},
 {"漢字 - Kanji":"感嘆","ひらがな - Hiragana":"かんたん","Romaji (English Letters)":"kantan","audio":"kantan","English Meaning":"admiration, wonder [n]"},
 {"漢字 - Kanji":"から","ひらがな - Hiragana":"から","Romaji (English Letters)":"kara","audio":"kara","English Meaning":"from, since, because [particle]"},
 {"漢字 - Kanji":"殻","ひらがな - Hiragana":"から","Romaji (English Letters)":"kara","audio":"kara","English Meaning":"shell, husk, hull, pod, chaff [n]"},
 {"漢字 - Kanji":"空","ひらがな - Hiragana":"から","Romaji (English Letters)":"kara","audio":"kara","English Meaning":"emptiness, vacuum, blank [n]"},
 {"漢字 - Kanji":"烏","ひらがな - Hiragana":"からす","Romaji (English Letters)":"karasu","audio":"karasu","English Meaning":"crow, raven [n]"},
 {"漢字 - Kanji":"嗄らす","ひらがな - Hiragana":"からす","Romaji (English Letters)":"karasu","audio":"karasu","English Meaning":"to talk (or shout) oneself hoarse [gv]"},
 {"漢字 - Kanji":"枯らす","ひらがな - Hiragana":"からす","Romaji (English Letters)":"karasu","audio":"karasu","English Meaning":"to let dry, to kill (vegetation), to season (lumber) [gv]"},
 {"漢字 - Kanji":"方","ひらがな - Hiragana":"かた","Romaji (English Letters)":"kata","audio":"kata","English Meaning":"person, lady, gentleman [n]"},
 {"漢字 - Kanji":"肩","ひらがな - Hiragana":"かた","Romaji (English Letters)":"kata","audio":"kata","English Meaning":"shoulder [n]"},
 {"漢字 - Kanji":"型","ひらがな - Hiragana":"かた","Romaji (English Letters)":"kata","audio":"kata","English Meaning":"model, type (e.g. of machine, goods, etc.) [n]"},
 {"漢字 - Kanji":"川","ひらがな - Hiragana":"かわ","Romaji (English Letters)":"kawa","audio":"kawa","English Meaning":"river, stream [n]"},
 {"漢字 - Kanji":"皮","ひらがな - Hiragana":"かわ","Romaji (English Letters)":"kawa","audio":"kawa","English Meaning":"skin, hide, pelt, fur, rind, peel, husk, bark [n]"},
 {"漢字 - Kanji":"数","ひらがな - Hiragana":"かず","Romaji (English Letters)":"kazu","audio":"kazu","English Meaning":"number, amount [n]"},
 {"漢字 - Kanji":"下図","ひらがな - Hiragana":"かず","Romaji (English Letters)":"kazu","audio":"kazu","English Meaning":"the lower illustration [n]"},
 {"漢字 - Kanji":"機械","ひらがな - Hiragana":"きかい","Romaji (English Letters)":"kikai","audio":"kikai","English Meaning":"machine, mechanism [n]"},
 {"漢字 - Kanji":"器械","ひらがな - Hiragana":"きかい","Romaji (English Letters)":"kikai","audio":"kikai","English Meaning":"appliance, apparatus [n]"},
 {"漢字 - Kanji":"機会","ひらがな - Hiragana":"きかい","Romaji (English Letters)":"kikai","audio":"kikai","English Meaning":"chance, opportunity [n]"},
 {"漢字 - Kanji":"聞く","ひらがな - Hiragana":"きく","Romaji (English Letters)":"kiku","audio":"kiku","English Meaning":"to hear, to listen, to ask [gv]"},
 {"漢字 - Kanji":"効く","ひらがな - Hiragana":"きく","Romaji (English Letters)":"kiku","audio":"kiku","English Meaning":"to be effective, to show effect [gv]"},
 {"漢字 - Kanji":"菊","ひらがな - Hiragana":"きく","Romaji (English Letters)":"kiku","audio":"kiku","English Meaning":"chrysanthemum [n]"},
 {"漢字 - Kanji":"昨日","ひらがな - Hiragana":"きのう","Romaji (English Letters)":"kinou","audio":"kinou","English Meaning":"yesterday [n]"},
 {"漢字 - Kanji":"機能","ひらがな - Hiragana":"きのう","Romaji (English Letters)":"kinou","audio":"kinou","English Meaning":"function, feature [n]"},
 {"漢字 - Kanji":"嫌い","ひらがな - Hiragana":"きらい","Romaji (English Letters)":"kirai","audio":"kirai","English Meaning":"dislike, hate [dn]"},
 {"漢字 - Kanji":"帰来","ひらがな - Hiragana":"きらい","Romaji (English Letters)":"kirai","audio":"kirai","English Meaning":"returning [n]"},
 {"漢字 - Kanji":"機雷","ひらがな - Hiragana":"きらい","Romaji (English Letters)":"kirai","audio":"kirai","English Meaning":"(sea) mine [n]"},
 {"漢字 - Kanji":"切る","ひらがな - Hiragana":"きる","Romaji (English Letters)":"kiru","audio":"kiru","English Meaning":"to cut (usu. to cut through), to sever (connections, ties), to turn off (i.e. the light), to terminate (i.e. a conversation), to hang up (the phone), to disconnect [gv]"},
 {"漢字 - Kanji":"着る","ひらがな - Hiragana":"きる","Romaji (English Letters)":"kiru","audio":"kiru","English Meaning":"to wear, to put on [iv]"},
 {"漢字 - Kanji":"記者","ひらがな - Hiragana":"きしゃ","Romaji (English Letters)":"kisha","audio":"kisha","English Meaning":"reporter [n]"},
 {"漢字 - Kanji":"汽車","ひらがな - Hiragana":"きしゃ","Romaji (English Letters)":"kisha","audio":"kisha","English Meaning":"train (sometimes specifically a steam train) [n]"},
 {"漢字 - Kanji":"貴社","ひらがな - Hiragana":"きしゃ","Romaji (English Letters)":"kisha","audio":"kisha","English Meaning":"(your) company [n - honorific]"},
 {"漢字 - Kanji":"雲","ひらがな - Hiragana":"くも","Romaji (English Letters)":"kumo","audio":"kumo","English Meaning":"cloud [n]"},
 {"漢字 - Kanji":"蜘蛛","ひらがな - Hiragana":"くも","Romaji (English Letters)":"kumo","audio":"kumo","English Meaning":"spider [n]"},
 {"漢字 - Kanji":"クラス","ひらがな - Hiragana":"くらす","Romaji (English Letters)":"kurasu","audio":"kurasu","English Meaning":"class [n]"},
 {"漢字 - Kanji":"暮らす","ひらがな - Hiragana":"くらす","Romaji (English Letters)":"kurasu","audio":"kurasu","English Meaning":"to live, to get along, to spend (time) [gv]"},
 {"漢字 - Kanji":"駆使","ひらがな - Hiragana":"くし","Romaji (English Letters)":"kushi","audio":"kushi","English Meaning":"free use [n]"},
 {"漢字 - Kanji":"串","ひらがな - Hiragana":"くし","Romaji (English Letters)":"kushi","audio":"kushi","English Meaning":"spit, skewer [n]"},
 {"漢字 - Kanji":"櫛","ひらがな - Hiragana":"くし","Romaji (English Letters)":"kushi","audio":"kushi","English Meaning":"comb [n]"},
 {"漢字 - Kanji":"もし","ひらがな - Hiragana":"もし","Romaji (English Letters)":"moshi","audio":"moshi","English Meaning":"if, in case, supposing"},
 {"漢字 - Kanji":"虫","ひらがな - Hiragana":"むし","Romaji (English Letters)":"mushi","audio":"mushi","English Meaning":"insect, bug [n]"},
 {"漢字 - Kanji":"寝る","ひらがな - Hiragana":"ねる","Romaji (English Letters)":"neru","audio":"neru","English Meaning":"to lie down, to go to bed, to lie in bed [iv]"},
 {"漢字 - Kanji":"練る","ひらがな - Hiragana":"ねる","Romaji (English Letters)":"neru","audio":"neru","English Meaning":"to knead, to thicken into a paste (stirring over a flame) [gv]"},
 {"漢字 - Kanji":"には","ひらがな - Hiragana":"には","Romaji (English Letters)":"niwa","audio":"niwa","English Meaning":"for (in regard to), in order to, within [particle]"},
 {"漢字 - Kanji":"庭","ひらがな - Hiragana":"にわ","Romaji (English Letters)":"niwa","audio":"niwa","English Meaning":"garden [n]"},
 {"漢字 - Kanji":"二羽","ひらがな - Hiragana":"にわ","Romaji (English Letters)":"niwa","audio":"niwa","English Meaning":"two birds (or rabbits) [c]"},
 {"漢字 - Kanji":"のみ","ひらがな - Hiragana":"のみ","Romaji (English Letters)":"nomi","audio":"nomi","English Meaning":"only, nothing but [particle]"},
 {"漢字 - Kanji":"蚤","ひらがな - Hiragana":"のみ","Romaji (English Letters)":"nomi","audio":"nomi","English Meaning":"flea [n]"},
 {"漢字 - Kanji":"起る","ひらがな - Hiragana":"おこる","Romaji (English Letters)":"okoru","audio":"okoru","English Meaning":"to occur, to happen [gv]"},
 {"漢字 - Kanji":"怒る","ひらがな - Hiragana":"おこる","Romaji (English Letters)":"okoru","audio":"okoru","English Meaning":"to get angry, to get mad [gv]"},
 {"漢字 - Kanji":"置く","ひらがな - Hiragana":"おく","Romaji (English Letters)":"oku","audio":"oku","English Meaning":"to put, to place, to leave (behind), to do something in advance (following base \"te\" verb) [gv]"},
 {"漢字 - Kanji":"奥","ひらがな - Hiragana":"おく","Romaji (English Letters)":"oku","audio":"oku","English Meaning":"interior, inner part, inside [n]"},
 {"漢字 - Kanji":"億","ひらがな - Hiragana":"おく","Romaji (English Letters)":"oku","audio":"oku","English Meaning":"100,000,000, hundred million [n]"},
 {"漢字 - Kanji":"送る","ひらがな - Hiragana":"おくる","Romaji (English Letters)":"okuru","audio":"okuru","English Meaning":"to send (a thing), to dispatch, to take or escort (a person somewhere), to see off (a person) [gv]"},
 {"漢字 - Kanji":"贈る","ひらがな - Hiragana":"おくる","Romaji (English Letters)":"okuru","audio":"okuru","English Meaning":"to send, to give to, to award to, to confer on [gv]"},
 {"漢字 - Kanji":"折る","ひらがな - Hiragana":"おる","Romaji (English Letters)":"oru","audio":"oru","English Meaning":"to break, to fracture, to break off, to snap off, to fold, to bend [gv]"},
 {"漢字 - Kanji":"織る","ひらがな - Hiragana":"おる","Romaji (English Letters)":"oru","audio":"oru","English Meaning":"to weave [gv]"},
 {"漢字 - Kanji":"おる","ひらがな - Hiragana":"おる","Romaji (English Letters)":"oru","audio":"oru","English Meaning":"to exist (animate - humble form of \"iru\") [gv]"},
 {"漢字 - Kanji":"収まる","ひらがな - Hiragana":"おさまる","Romaji (English Letters)":"osamaru","audio":"osamaru","English Meaning":"to be in one's place, to be installed, to settle into [gv]"},
 {"漢字 - Kanji":"納まる","ひらがな - Hiragana":"おさまる","Romaji (English Letters)":"osamaru","audio":"osamaru","English Meaning":"to be delivered, to be obtained, to be paid [gv]"},
 {"漢字 - Kanji":"治まる","ひらがな - Hiragana":"おさまる","Romaji (English Letters)":"osamaru","audio":"osamaru","English Meaning":"to be settled, to be sorted out, to lessen (e.g. of storms, pain), to calm down [gv]"},
 {"漢字 - Kanji":"王","ひらがな - Hiragana":"おう","Romaji (English Letters)":"ou","audio":"ou","English Meaning":"king, ruler, sovereign, monarch [n]"},
 {"漢字 - Kanji":"追う","ひらがな - Hiragana":"おう","Romaji (English Letters)":"ou","audio":"ou","English Meaning":"to chase, to run after, to pursue, to follow (i.e. a set order, a trend) [gv]"},
 {"漢字 - Kanji":"負う","ひらがな - Hiragana":"おう","Romaji (English Letters)":"ou","audio":"ou","English Meaning":"to bear, to carry on one's back, to take responsibility for, to accept a duty [gv]"},
 {"漢字 - Kanji":"財布","ひらがな - Hiragana":"さいふ","Romaji (English Letters)":"saifu","audio":"saifu","English Meaning":"purse, handbag, wallet [n]"},
 {"漢字 - Kanji":"政府","ひらがな - Hiragana":"せいふ","Romaji (English Letters)":"seifu","audio":"seifu","English Meaning":"government, administration [n]"},
 {"漢字 - Kanji":"触る","ひらがな - Hiragana":"さわる","Romaji (English Letters)":"sawaru","audio":"sawaru","English Meaning":"to touch, to feel [gv]"},
 {"漢字 - Kanji":"座る","ひらがな - Hiragana":"すわる","Romaji (English Letters)":"suwaru","audio":"suwaru","English Meaning":"to sit, to squat [gv]"},
 {"漢字 - Kanji":"避ける","ひらがな - Hiragana":"さける","Romaji (English Letters)":"sakeru","audio":"sakeru","English Meaning":"to avoid (physical contact with) [iv]"},
 {"漢字 - Kanji":"裂ける","ひらがな - Hiragana":"さける","Romaji (English Letters)":"sakeru","audio":"sakeru","English Meaning":"to split, to tear, to burst [iv]"},
 {"漢字 - Kanji":"席","ひらがな - Hiragana":"せき","Romaji (English Letters)":"seki","audio":"seki","English Meaning":"seat [n]"},
 {"漢字 - Kanji":"咳","ひらがな - Hiragana":"せき","Romaji (English Letters)":"seki","audio":"seki","English Meaning":"cough, coughing, tussis [n]"},
 {"漢字 - Kanji":"選択","ひらがな - Hiragana":"せんたく","Romaji (English Letters)":"sentaku","audio":"sentaku","English Meaning":"selection, choice, option [n]"},
 {"漢字 - Kanji":"洗濯","ひらがな - Hiragana":"せんたく","Romaji (English Letters)":"sentaku","audio":"sentaku","English Meaning":"washing, laundry [n]"},
 {"漢字 - Kanji":"下","ひらがな - Hiragana":"した","Romaji (English Letters)":"shita","audio":"shita","English Meaning":"below, down, under, younger (e.g. daughter), bottom, beneath, underneath [n]"},
 {"漢字 - Kanji":"舌","ひらがな - Hiragana":"した","Romaji (English Letters)":"shita","audio":"shita","English Meaning":"tongue [n]"},
 {"漢字 - Kanji":"掃除","ひらがな - Hiragana":"そうじ","Romaji (English Letters)":"souji","audio":"souji","English Meaning":"cleaning, sweeping [n]"},
 {"漢字 - Kanji":"相似","ひらがな - Hiragana":"そうじ","Romaji (English Letters)":"souji","audio":"souji","English Meaning":"resemblance, likeness, similitude [dn]"},
 {"漢字 - Kanji":"送辞","ひらがな - Hiragana":"そうじ","Romaji (English Letters)":"souji","audio":"souji","English Meaning":"farewell address [n]"},
 {"漢字 - Kanji":"住む","ひらがな - Hiragana":"すむ","Romaji (English Letters)":"sumu","audio":"sumu","English Meaning":"to live (of humans), to reside, to inhabit, to dwell, to abide [gv]"},
 {"漢字 - Kanji":"済む","ひらがな - Hiragana":"すむ","Romaji (English Letters)":"sumu","audio":"sumu","English Meaning":"to finish, to end, to be completed [gv]"},
 {"漢字 - Kanji":"澄む","ひらがな - Hiragana":"すむ","Romaji (English Letters)":"sumu","audio":"sumu","English Meaning":"to clear (e.g. weather), to become transparent [gv]"},
 {"漢字 - Kanji":"勧める","ひらがな - Hiragana":"すすめる","Romaji (English Letters)":"susumeru","audio":"susumeru","English Meaning":"to recommend, to advise, to encourage [iv]"},
 {"漢字 - Kanji":"進める","ひらがな - Hiragana":"すすめる","Romaji (English Letters)":"susumeru","audio":"susumeru","English Meaning":"to advance, to promote, to hasten [iv]"},
 {"漢字 - Kanji":"旅","ひらがな - Hiragana":"たび","Romaji (English Letters)":"tabi","audio":"tabi","English Meaning":"travel, trip, journey [n]"},
 {"漢字 - Kanji":"度","ひらがな - Hiragana":"たび","Romaji (English Letters)":"tabi","audio":"tabi","English Meaning":"time (three times, each time, etc.), times [n]"},
 {"漢字 - Kanji":"足袋","ひらがな - Hiragana":"たび","Romaji (English Letters)":"tabi","audio":"tabi","English Meaning":"Japanese socks (with split toes) [n]"},
 {"漢字 - Kanji":"太陽","ひらがな - Hiragana":"たいよう","Romaji (English Letters)":"taiyou","audio":"taiyou","English Meaning":"sun, solar [n]"},
 {"漢字 - Kanji":"大洋","ひらがな - Hiragana":"たいよう","Romaji (English Letters)":"taiyou","audio":"taiyou","English Meaning":"ocean [n]"},
 {"漢字 - Kanji":"態様","ひらがな - Hiragana":"たいよう","Romaji (English Letters)":"taiyou","audio":"taiyou","English Meaning":"situation, terms [n]"},
 {"漢字 - Kanji":"大要","ひらがな - Hiragana":"たいよう","Romaji (English Letters)":"taiyou","audio":"taiyou","English Meaning":"summary, outline [n]"},
 {"漢字 - Kanji":"高い","ひらがな - Hiragana":"たかい","Romaji (English Letters)":"takai","audio":"takai","English Meaning":"high, tall, expensive [dv]"},
 {"漢字 - Kanji":"他界","ひらがな - Hiragana":"たかい","Romaji (English Letters)":"takai","audio":"takai","English Meaning":"death, the next world [n]"},
 {"漢字 - Kanji":"天","ひらがな - Hiragana":"てん","Romaji (English Letters)":"ten","audio":"ten","English Meaning":"sky, heaven [n]"},
 {"漢字 - Kanji":"点","ひらがな - Hiragana":"てん","Romaji (English Letters)":"ten","audio":"ten","English Meaning":"spot, mark, point, dot, mark (e.g. in exam), score, points [n]"},
 {"漢字 - Kanji":"天下","ひらがな - Hiragana":"てんか","Romaji (English Letters)":"tenka","audio":"tenka","English Meaning":"the whole world, the whole country, society, the public [n]"},
 {"漢字 - Kanji":"添加","ひらがな - Hiragana":"てんか","Romaji (English Letters)":"tenka","audio":"tenka","English Meaning":"addition, annexing [n]"},
 {"漢字 - Kanji":"点火","ひらがな - Hiragana":"てんか","Romaji (English Letters)":"tenka","audio":"tenka","English Meaning":"ignition, lighting, set fire to [n]"},
 {"漢字 - Kanji":"転嫁","ひらがな - Hiragana":"てんか","Romaji (English Letters)":"tenka","audio":"tenka","English Meaning":"imputation, shifting (e.g. blame, costs), passing the buck [n]"},
 {"漢字 - Kanji":"天気","ひらがな - Hiragana":"てんき","Romaji (English Letters)":"tenki","audio":"tenki","English Meaning":"weather, the elements [n]"},
 {"漢字 - Kanji":"転機","ひらがな - Hiragana":"てんき","Romaji (English Letters)":"tenki","audio":"tenki","English Meaning":"turning point [n]"},
 {"漢字 - Kanji":"転記","ひらがな - Hiragana":"てんき","Romaji (English Letters)":"tenki","audio":"tenki","English Meaning":"posting (of notes, notices, etc.), transcription [n]"},
 {"漢字 - Kanji":"付ける","ひらがな - Hiragana":"つける","Romaji (English Letters)":"tsukeru","audio":"tsukeru","English Meaning":"to attach, to join, to add, to append, to affix, to stick, to glue, to fasten, to sew on, to apply (ointment) [iv]"},
 {"漢字 - Kanji":"漬ける","ひらがな - Hiragana":"つける","Romaji (English Letters)":"tsukeru","audio":"tsukeru","English Meaning":"to soak, to seep, to dip in, to pickle [iv]"},
 {"漢字 - Kanji":"鶴","ひらがな - Hiragana":"つる","Romaji (English Letters)":"tsuru","audio":"tsuru","English Meaning":"crane [n]"},
 {"漢字 - Kanji":"蔓","ひらがな - Hiragana":"つる","Romaji (English Letters)":"tsuru","audio":"tsuru","English Meaning":"vine, tendril [n]"},
 {"漢字 - Kanji":"釣る","ひらがな - Hiragana":"つる","Romaji (English Letters)":"tsuru","audio":"tsuru","English Meaning":"to fish, to angle, to catch [gv]"},
 {"漢字 - Kanji":"上","ひらがな - Hiragana":"うえ","Romaji (English Letters)":"ue","audio":"ue","English Meaning":"above, up, over, elder (e.g. daughter), top, summit [n]"},
 {"漢字 - Kanji":"飢え","ひらがな - Hiragana":"うえ","Romaji (English Letters)":"ue","audio":"ue","English Meaning":"hunger, starvation [n]"},
 {"漢字 - Kanji":"海","ひらがな - Hiragana":"うみ","Romaji (English Letters)":"umi","audio":"umi","English Meaning":"sea, beach [n]"},
 {"漢字 - Kanji":"膿","ひらがな - Hiragana":"うみ","Romaji (English Letters)":"umi","audio":"umi","English Meaning":"pus [n]"},
 {"漢字 - Kanji":"運","ひらがな - Hiragana":"うん","Romaji (English Letters)":"un","audio":"un","English Meaning":"fortune, luck [n]"},
 {"漢字 - Kanji":"うん","ひらがな - Hiragana":"うん","Romaji (English Letters)":"un","audio":"un","English Meaning":"yes, yeah, uh huh"},
 {"漢字 - Kanji":"全焼","ひらがな - Hiragana":"ぜんしょう","Romaji (English Letters)":"zenshou","audio":"zenshou","English Meaning":"burned down, entirely destroyed [n]"},
 {"漢字 - Kanji":"全勝","ひらがな - Hiragana":"ぜんしょう","Romaji (English Letters)":"zenshou","audio":"zenshou","English Meaning":"complete victory [n]"}
]

const SimilarTwo = () => {

  return (
    <>
      <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Beginner" category="Vocabulary" lesson={title} />
     <div className={'title'}>
     More Words That Sound Similar
      </div>
      <h1>{title}</h1>

      <p>Lots of words in the Japanese language sound similar or exactly the same but have very different meanings. Here are many more words that fall into these categories. Be sure to study the <Link to="/members/i/vocab/similar01/">Words That Sound Similar</Link> lesson before moving on to this one.</p>

<p><strong>Legend:</strong></p>
<p>[n] = Noun</p>
<p>[dv] = Descriptive word (true adjective) - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[dn] = Descriptive noun - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[gv] = Godan verb - see <Link to="/members/b/grammar/godan-verbs/">Verb Conjugation (Godan)</Link></p>
<p>[iv] = Ichidan verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[irv] = Irregular verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[c] = Counter</p>

<Table data={similarone} />

    </>
  )
}

export default SimilarTwo