import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Sentence Construction"

const sentenceconstruction1 = [
  {"Kanji":"明日、子供たちは学校に戻る","Hiragana":"あした、こどもたちはがっこうにもどる","Romaji":"Ashita, kodomotachi wa gakkou ni modoru. ","audio":"ashita, kodomotachi wa gakkou ni modoru"},
 {"Kanji":"三時半に彼は彼女の家に行く","Hiragana":"さんじはんにかれはかのじょのいえにいく","Romaji":"Sanjihan ni kare wa kanojo no ie ni iku. ","audio":"sanjihan ni kare wa kanojo no ie ni iku"},
 {"Kanji":"五時十五分に彼女は麦茶を飲んだ","Hiragana":"ごじじゅうごふんにかのじょはむぎちゃをのんだ","Romaji":"Gojijuugofun ni kanojo wa mugicha o nonda. ","audio":"gojijuugofun ni kanojo wa mugicha o nonda"},
 {"Kanji":"二時に彼女は彼が車を運転していることを見た","Hiragana":"にじにかのじょはかれがくるまをうんてんしていることをみた","Romaji":"Niji ni kanojo wa kare ga kuruma o unten shite iru koto o mita. ","audio":"niji ni kanojo wa kare ga kuruma o unten shite iru koto o mita"}
]

const sentenceconstruction2 = [
  {"Kanji":"明日","Hiragana":"あした","Romaji":"ashita","English Meaning":"tomorrow","audio":"ashita"},
  {"Kanji":"子供たち","Hiragana":"こどもたち","Romaji":"kodomotachi","English Meaning":"children","audio":"kodomotachi"},
  {"Kanji":"学校","Hiragana":"がっこう","Romaji":"gakkou","English Meaning":"school","audio":"gakkou"},
  {"Kanji":"戻る","Hiragana":"もどる","Romaji":"modoru","English Meaning":"to return to","audio":"modoru"},
  {"Kanji":"三時半","Hiragana":"さんじはん","Romaji":"sanjihan","English Meaning":"3:30","audio":"sanjihan"},
  {"Kanji":"彼","Hiragana":"かれ","Romaji":"kare","English Meaning":"he/him","audio":"kare"},
  {"Kanji":"彼女","Hiragana":"かのじょ","Romaji":"kanojo","English Meaning":"she/her","audio":"kanojo"},
  {"Kanji":"家","Hiragana":"いえ","Romaji":"ie","English Meaning":"house","audio":"ie"},
  {"Kanji":"行く","Hiragana":"いく","Romaji":"iku","English Meaning":"to go","audio":"iku"},
  {"Kanji":"麦茶","Hiragana":"むぎちゃ","Romaji":"mugicha","English Meaning":"wheat tea","audio":"mugicha"},
  {"Kanji":"飲む","Hiragana":"のむ","Romaji":"nomu","English Meaning":"to drink","audio":"nomu"},
  {"Kanji":"車","Hiragana":"くるま","Romaji":"kuruma","English Meaning":"car","audio":"kuruma"},
  {"Kanji":"運転する","Hiragana":"うんてんする","Romaji":"unten suru","English Meaning":"to drive","audio":"unten suru"},
  {"Kanji":"見る","Hiragana":"みる","Romaji":"miru","English Meaning":"to see","audio":"miru"}
]

const SentenceConstruction = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Beginner" category="Grammar" lesson={title} />
     <div className={'title'}>
     {title}
      </div>
      <h1>{title}</h1>

<p><strong>Attention:</strong> Be sure to study the <Link to="/members/b/grammar/basic-grammar/">Basic Grammar</Link> lesson before moving on to this one.</p>

<p>Japanese sentence construction will generally follow the pattern below (elements not applicable to a particular sentence can be left out):</p>

<p>[time] に <Play audio="ni" /> [topic] は <Play audio="wa" /> [subject] が <Play audio="ga" /> [direct object] を <Play audio="o" /> [indirect object or direction] に <Play audio="ni" /> [verb].</p>

<p>The verb is the most important part of a Japanese sentence. Be sure to study the lessons on verb conjugation and verb bases in great detail.</p>

<p><strong>Note:</strong> For times such as "today" (今日 - きょう - <Play audio="kyou" /> ), "tomorrow" (明日 - あした - <Play audio="ashita" /> ), "yesterday" (昨日 - きのう - <Play audio="kinou" /> ), "the day after tomorrow" (明後日 - あさって - <Play audio="asatte" /> ), and "the day before yesterday" (一昨日 - おととい - <Play audio="ototoi" /> ), a time marker is not necessary.</p>

<p>Here are some example sentences (all in plain form):</p>

<Table data={sentenceconstruction1} />

<Table data={sentenceconstruction2} />

<h2>To, Ya, and No</h2>

<p><Play audio="to" /> (と)  is a particle that is used in many ways in the Japanese language. "To" can mean "and". For instance, <Play audio="ringo to orenji" />  (apples and oranges). <Play audio="ya" />  can also mean "and" but implies that there are items not being mentioned. For instance, "Ringo ya orenji"  (apples and oranges and...). "To"  can also be used as "with". For instance, <Play audio="kare to iku" />  (I'm going with him). "To"  can also act as "quotes" for something heard, said, or thought. <Play audio="kanojo ga byouki da to kiita" />  (I heard she is sick), <Play audio="kare wa ikitakunai to itta" />  (He said he doesn't want to go), <Play audio="kanojo wa boku no koto ga suki ka na to omotte ita" />  (I was thinking, "I wonder if she likes me").</p>

<p>The main function for the particle <Play audio="no" />  is to join nouns together. For instance, in an example sentence above we used <Play audio="kanojo no ie" /> . The "no"  connects "she/her" to "house" making it "her house". <Play audio="boku no ie" />/<Play audio="watashi no ie" /> / would be "my house" and <Play audio="" />  would be "his house". "No"  can also be used at the end of a plain form sentence to turn the sentence into a question (in the same way that <Play audio="ka" />  would be used in the polite form).</p>

<h2>To Be</h2>

<p>The verb <Play audio="de aru" />　(である)  (<Play audio="desu" /> (です)  in the polite form) is generally considered to be the "to be" verb (am, is, be, etc.). <Play audio="de aru" />  is typically "da" (だ)  in the plain form　and is generally only found as "de aru"  when it is being used to modify a noun (which is something Japanese verbs can do). For instance, <Play audio="kanojo ga kirei de aru koto ga suki desu" />  (I like that she is pretty). The negative form of "de aru"  is <Play audio="dewa nai" /> (ではない)  or <Play audio="ja nai" /> (じゃない)  (more commonly spoken). The base "ta" form (past tense by itself) is <Play audio="datta" /> (だった)  and the past tense negative is <Play audio="" /> (ではなかった)  or <Play audio="ja nakatta" /> (じゃなかった)  which, as you might notice, follows the conjugation rules for "dv"/true adjectives (see Descriptive Words). Other uses will generally follow the conjugation rules for godan verbs - see Verb Conjugation (Godan). For "desu"<Play audio="desu" />  (polite form), the negative is <Play audio="dewa arimasen" /> (ではありません) , the past tense is <Play audio="deshita" /> (でした) , and the past tense negative is <Play audio="dewa arimasen deshita" /> (ではありませんでした) . "De aru"  is not the only thing that can convey "to be", however. The verbs <Play audio="iru" />  (to exist, animate) and "aru"  (to exit, inanimate) are also used in ways where "to be" would be used in English. For instance, <Play audio="kare wa soto ni iru" />  which is literally "He exists outside" is how the Japanese would say "He is outside". Also, the last <Play audio="i" />  in every "dv"/true adjective (see Descriptive Words) acts as "to be" and can end the sentence by itself. A "desu"  can be added to make the sentence more polite but it is not needed (in plain form).</p>

    </>
  )
}

export default SentenceConstruction