import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Questions and Uncertainty"

const questionsone = [
  {"Kanji":"彼女がどこに住んでいるか知っています","Hiragana":"かのじょがどこにすんでいるかしっています","Romaji":"Kanojo ga doko ni sunde iru ka shitte imasu","audio":"kanojo ga doko ni sunde iru ka shitte imasu","English Meaning":"I know where she lives."},
 {"Kanji":"みんなは何を食べたいか分からない","Hiragana":"みんなはなにをたべたいかわからない","Romaji":"Minna wa nani o tabetai ka wakaranai","audio":"minna wa nani o tabetai ka wakaranai","English Meaning":"I'm not sure what everyone wants to eat."},
 {"Kanji":"みんなはどこで食べたいかが分かりません","Hiragana":"みんなはどこでたべたいかがわかりません","Romaji":"Minna wa doko de tabetai ka ga wakarimasen","audio":"minna wa doko de tabetai ka ga wakarimasen","English Meaning":"I'm not sure where everyone wants to eat.(The \"ga\" would typically be left off when spoken)"}
]
const questionstwo = [
  {"Kanji":"彼も行くかな","Hiragana":"かれもいくかな","Romaji":"Kare mo iku ka na","audio":"kare mo iku ka na","English Meaning":"I wonder if he's going too."},
 {"Kanji":"時間に間に合うかな","Hiragana":"じかんにまにあうかな","Romaji":"Jikan ni ma ni au ka na","audio":"jikan ni ma ni au ka na","English Meaning":"I wonder if I'll/we'll make it on time."}
]
const questionsthree = [
  {"Kanji":"彼も行くかもしれない","Hiragana":"かれもいくかもしれない","Romaji":"Kare mo iku kamo shirenai","audio":"kare mo iku kamo shirenai","English Meaning":"He might go too."},
 {"Kanji":"時間に間に合うかもしれません","Hiragana":"じかんにまにあうかもしれません","Romaji":"Jikan ni ma ni au kamo shiremasen","audio":"jikan ni ma ni au kamo shiremasen","English Meaning":"I/we might make it on time."}
]
const questionsfour = [
  {"Kanji":"彼も行くかどうか聞いて見ます","Hiragana":"かれもいくかどうかきいてみます","Romaji":"Kare mo iku ka dou ka kiite mimasu","audio":"kare mo iku ka dou ka kiite mimasu","English Meaning":"I'll check and see whether or not he is going too."},
 {"Kanji":"時間に間に合うかどうか分からない","Hiragana":"じかんにまにあうかどうかわからない","Romaji":"Jikan ni ma ni au ka dou ka wakaranai","audio":"jikan ni ma ni au ka dou ka wakaranai","English Meaning":"I don't know whether or not I'll/we'll make it on time."}
]
const questionsfive = [
  {"Kanji":"彼も行くでしょう","Hiragana":"かれもいくでしょう","Romaji":"Kare mo iku deshou","audio":"kare mo iku deshou","English Meaning":"He's probably going too."},
 {"Kanji":"彼も行くでしょうか","Hiragana":"かれもいくでしょうか","Romaji":"Kare mo iku deshou ka","audio":"kare mo iku deshou ka","English Meaning":"He's going too, right? (polite form)"},
 {"Kanji":"彼も行くでしょ","Hiragana":"かれもいくでしょ","Romaji":"Kare mo iku desho","audio":"kare mo iku desho","English Meaning":"He's going too, right? (plain form)"}
]
const questionssix = [
  {"Kanji":"彼も行くだろう","Hiragana":"かれもいくだろう","Romaji":"Kare mo iku darou","audio":"kare mo iku darou","English Meaning":"I guess he's going too."},
 {"Kanji":"彼も行くだろうか","Hiragana":"かれもいくだろうか","Romaji":"Kare mo iku darou ka","audio":"kare mo iku darou ka","English Meaning":"I wonder if he's going too."}
]
const questionsseven = [
  {"Kanji":"決める","Hiragana":"きめる","Romaji":"kimeru","English Meaning":"to decide, to choose, to determine [iv] ","audio":"kimeru"},
 {"Kanji":"","Hiragana":"みんな","Romaji":"minna","English Meaning":"everyone, everybody [n] ","audio":"minna"},
 {"Kanji":"分かる","Hiragana":"わかる","Romaji":"wakaru","English Meaning":"to be understood, to be known [gv] ","audio":"wakaru"},
 {"Kanji":"間に合う","Hiragana":"まにあう","Romaji":"ma ni au","English Meaning":"to be in time for [gv] ","audio":"ma ni au"},
 {"Kanji":"聞いて見る","Hiragana":"きいてみる","Romaji":"kiite miru","English Meaning":"to inquire (to ask and see) [iv] ","audio":"kiite miru"}
]

const Questions = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Beginner" category="Grammar" lesson={title} />
     <div className={'title'}>
     {title}
      </div>
      <h1>{title}</h1>

<p>Learning how to ask questions is an important part of learning any language but especially Japanese since there are 4 different ways to do it and when to use which is very important.</p>

<p>Here are the 4 methods and an explanation of each:</p>

<table>
<thead>
<tr>
<th>Japanese (In Kanji, Hiragana, and Romaji)</th>
<th>English Meaning</th>
</tr>
</thead>
<tbody>
<tr>
<td>

行きますか。
<br />
いきますか。
<br />
<Play audio="ikimasu ka" />

</td>
<td>
Are you going?
</td>
</tr>
<tr>
<td colspan="2">
<b>Explanation: </b>As covered in other lessons, the primary way to change a statement to a question in polite form is to add a "ka" on the end of the sentence.
</td>
</tr>
<tr>
<td>

行きます？
<br />
いきます？
<br />
<Play audio="ikimasu--" />

</td>
<td>
Are you going?
</td>
</tr>
<tr>
<td colspan="2">
<b>Explanation: </b>
Generally, the "u" sound at the end of "masu" is not pronounced but another way to ask a question in polite form is to pronounce the "u" sound on the end and inflect it up.  This is hard to explain so you will need to rely on the audio for this one.  We will reflect this sort of question with a question mark on the end.  This isn't quite as polite as adding a "ka" on the end but it is still polite form.
<br /><br />
Here is the statement "ikimasu" <Play audio="ikimasu" /> (I will go) so you can hear the difference.
</td>
</tr>
<tr>
<td>

行くの。
<br />
いくの。
<br />
<Play audio="iku no" />

</td>
<td>
Are you going?
</td>
</tr>
<tr>
<td colspan="2">
<b>Explanation: </b>
For plain form, a "no" is added instead of a "ka" but this is very informal and can be rude to use even with people you speak plain form with (friends and family) - it is generally only used on children.
</td>
</tr>
<tr>
<td>

行く？
<br />
いく？
<br />
<Play audio="iku--" />

</td>
<td>
Are you going?
</td>
</tr>
<tr>
<td colspan="2">
<b>Explanation: </b>
The most common way to ask a question in plain form is to inflect the end of the verb.  Like with the polite form equivalent above, this is represented by a question mark on the end and you will need to listen to the audio to fully understand.
<br /><br />
Here is the statement "iku" <Play audio="iku" /> (I will go) so you can hear the difference.
</td>
</tr>
</tbody></table>

<p>This can also be done with descriptive words. Here is an example with a "dn" (descriptive noun):</p>

<table>
<tbody><tr>
<td>Japanese<br />(In Kanji, Hiragana, and Romaji)</td>
<td>English Meaning</td>
</tr>
<tr>
<td>

綺麗ですか。
<br />
きれいですか。
<br />
<Play audio="kirei desu ka" />

</td>
<td>
Is it clean (or pretty)?
</td>
</tr>
<tr>
<td colspan="2">
<b>Explanation: </b>
This would be the standard way to ask this in polite form (inflection doesn't work with "desu").
</td>
</tr>
<tr>
<td>

綺麗なの。
<br />
きれいなの。
<br />
<Play audio="kirei na no" />

</td>
<td>
Is it clean (or pretty)?
</td>
</tr>
<tr>
<td colspan="2">
<b>Explanation: </b>
While the plain form equivalent of "desu" is "da", it doesn't work in question form and "na" is used instead.
</td>
</tr>
<tr>
<td>

綺麗？
<br />
きれい？
<br />
<Play audio="kirei--" />

</td>
<td>
Is it clean (or pretty)?
</td>
</tr>
<tr>
<td colspan="2">
<b>Explanation: </b>
This is the normal way one would ask this in plain form.
</td>
</tr>
</tbody></table>

<p>Here are some examples with a "dv" (descriptive verb/regular adjective):</p>

<table>
<thead>
<tr>
<th>Japanese (In Kanji, Hiragana, and Romaji)</th>
<th>English Meaning</th>
</tr>
</thead>
<tbody>
<tr>
<td>

寒いですか。
<br />
さむいですか。
<br />
<Play audio="samui desu ka" />

</td>
<td>
Is it cold (outside)?
</td>
</tr>
<tr>
<td colspan="2">
<b>Explanation: </b>
The final "i" of a "dv" acts as the "to be" so "desu" is only added to make this sentence polite form.
</td>
</tr>
<tr>
<td>

寒いの。
<br />
さむいの。
<br />
<Play audio="samui no" />

</td>
<td>
Is it cold (outside)?
</td>
</tr>
<tr>
<td colspan="2">
<b>Explanation: </b>
Since "desu" isn't needed with a "dv" (except to make it polite form), no "na" is needed either.
</td>
</tr>
<tr>
<td>

寒い？
<br />
さむい？
<br />
<Play audio="samui--" />

</td>
<td>
Is it cold (outside)?
</td>
</tr>
<tr>
<td colspan="2">
<b>Explanation: </b>
This is the normal way one would ask this in plain form.
</td>
</tr>
<tr>
<td>

行きたいですか。
<br />
いきたいですか。
<br />
<Play audio="ikitai desu ka" />

</td>
<td>
Do you want to go?
</td>
</tr>
<tr>
<td colspan="2">
<b>Explanation: </b>
Base 2 + tai (the "want to verb" form) is a "dv" so it works the same way.
</td>
</tr>
<tr>
<td>

行きたいの。
<br />
いきたいの。
<br />
<Play audio="ikitai no" />

</td>
<td>
Do you want to go?
</td>
</tr>
<tr>
<td>

行きたい？
<br />
いきたい？
<br />
<Play audio="ikitai--" />

</td>
<td>
Do you want to go?
</td>
</tr>
<tr>
<td>

痛かったですか。
<br />
いたかったですか。
<br />
<Play audio="itakatta desu ka" />

</td>
<td>
Did that hurt?
</td>
</tr>
<tr>
<td colspan="2">
<b>Explanation: </b>
The past tense of a "dv" (drop the "i" and add "katta") works the same way.
</td>
</tr>
<tr>
<td>

痛かったの。
<br />
いたかったの。
<br />
<Play audio="itakatta no" />

</td>
<td>
Did that hurt?
</td>
</tr>
<tr>
<td>

痛かった？
<br />
いたかった？
<br />
<Play audio="itakatta--" />

</td>
<td>
Did that hurt?
</td>
</tr>
</tbody></table>

<h2>Embedded Questions</h2>

<p>Embedded questions are a way of taking a question and converting it into an object that can serve as a subject or direct object in a Japanese sentence. They are a combination of a polite form question and a plain form question because they always use "ka" but also always use the plain form verb. For instance, let's say we were to change the sentence "Where are you going to go?" into an embedded question. "Where are you going to go?" would be "doko ni ikimasu ka" in polite form and "doko ni iku no" in plain form. For the embedded question, this becomes "doko ni iku ka" and we can now use this phrase as an object - "doko ni iku ka kimemashita ka"  = "Have you decided where you're going to go?" An を (o) direct object marker (or a が (ga) subject marker depending on the verb) could be placed after the embedded question (doko ni iku ka o kimemashita ka) but this is generally left off when spoken. Here are some more example sentences:</p>

<Table data={questionsone} />

<h2>Ka Na</h2>

<p>Uncertainty is a very common part of Japanese sentences. One reason for this is that one should not state what someone else will or will not do or wants to do. For instance, one shouldn't say "He's going" or "He wants to go" but rather "I think he's going" (or some other use of uncertainty, such as "I wonder...") or "I think he wants to go".</p>

<p>While "ka na" sounds similar to the polite form question marker "ka", it is fairly different and only used in plain form. We will cover polite form equivalents below. It means "I wonder" and is one way to show uncertainty. Here are some example sentences:</p>

<Table data={questionstwo} />

<h2>Kamo Shirenai</h2>

<p>You'll notice that "ka" is used a lot in uncertainty. An interesting sidenote is that "ka" by itself can mean "or" (ringo ka orenji = apples or oranges). When combined with "mo" it becomes "might, maybe". This is pretty informal by itself and is often combined with "shirenai"  in plain form or "shiremasen"  in polite form.</p>
  
<p>This is base 4 of the verb 知る (しる - shiru - to know) + "ru", which then becomes an ichidan verb, in negative form - meaning "cannot know". Here are some example sentences:</p>

<Table data={questionsthree} />

<h2>Ka Dou Ka</h2>

<p>"Ka dou ka" is a type of embedded question but the added "dou ka" changes the meaning to "whether or not". Here are some example sentences:</p>

<Table data={questionsfour} />

<h2>Deshou</h2>

<p>"Deshou" is a very commonly used word in Japanese. It is like "probably" and can be used in both plain form conversations as well as polite form conversations. It just goes at the end of the sentence where a "desu" or "masu" might go. You can also add a "ka" on the end to make it a question (polite form only) or it can also be shortened to "desho" (plain form only) to make it like "right?". Here are some example sentences:</p>

<Table data={questionsfive} />

<h2>Darou</h2>

<p>Where "deshou" is like "probably", "darou" is like "possibly" but, like "deshou", it's hard to define by one word. It can also have an "I guess" or "seems like" vibe as well. It is used much the same way as "deshou" and "darou ka" is essentially the polite form equivalent of "ka na". Here are some example sentences:</p>

<Table data={questionssix} />

<h3>Vocab breakdown</h3>

<Table data={questionsseven} />

    </>
  )
}

export default Questions