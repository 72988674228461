import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Basic Conversations"

const weatherpolite1 = [
  {"":"Person 1:","Kanji":"明日の天気はどうですか。 雨が降りそうです。","Hiragana":"あしたのてんきはどうですか。 あめがふりそうです。","Romaji":"Ashita no tenki wa dou desu ka.  Ame ga furisou desu.","English Meaning":"What is the weather going to be like tomorrow?  It looks like it might rain.", "audio": "ashita no tenki wa dou desu ka ame ga furisou desu", "audio": ""},
  {"":"Person 2:","Kanji":"そうですね。 結構曇っていますね。","Hiragana":"そうですね。 けっこうくもっていますね。","Romaji":"Sou desu ne.  Kekkou kumotte imasu ne.","English Meaning":"Sure does.  It's pretty cloudy, huh.", "audio": "sou desu ne kekkou kumotte imasu ne"},
  {"":"Person 2:","Kanji":"やっぱり、雨が降ると言っています。","Hiragana":"やっぱり、あめがふるといっています。","Romaji":"Yappari, ame ga furu to itte imasu.","English Meaning":"Yep, they are saying it's going to rain.", "audio": "yappari ame ga furu to itte imasu"},
  {"":"Person 1:","Kanji":"残念ですね。 テニスをするつもりでした。","Hiragana":"ざんねんですね。 てにすをするつもりでした。","Romaji":"Zannen desu ne.  Tenisu o suru tsumori deshita.","English Meaning":"That's too bad.  I was planning on playing tennis.", "audio": "zannen desu ne tenisu o suru tsumori deshita"},
  {"":"Person 2:","Kanji":"がっかりしないでね。","Hiragana":" この週末はいい天気になるはずですからゴルフをしに行きましょう！がっかりしないでね。","Romaji":"Gakkari shinaide ne.   Kono shuumatsu wa ii tenki ni naru hazu desu kara gorufu o shi ni ikimashou!","English Meaning":"Don't be discouraged.  It should be good weather this weekend so let's go golfing!", "audio": "gakkari shinaide ne kono shuumatsu wa ii tenki ni naru hazu desu kara gorufu o shi ni ikimashou"}
]

const weatherplain1 = [
  {"":"Person 1:","Kanji":"今日の天気はどう？", "Hiragana":"きょうのてんきはどう？", "Romaji":"Kyou no tenki wa dou?", "English Meaning":"How's the weather today?", "audio": "kyou no tenki wa dou--"},
 {"":"Person 2:","Kanji":"雨が降りそうだから、傘を持って行ったほうがいいよ。","Hiragana":"あめがふりそうだから、かさをもっていったほうがいいよ。","Romaji":"Ame ga furisou da kara, kasa o motte itta hou ga ii yo.","English Meaning":"It looks like rain so you should take an umbrella.", "audio": "ame ga furisou da kara kasa o motte itta hou ga ii yo"},
 {"":"Person 1:","Kanji":"それじゃ傘を持って行くね。","Hiragana":"それじゃかさをもっていくね。","Romaji":"Sore ja kasa o motte iku ne.","English Meaning":"Ok, I'll take an umbrella.", "audio": "sore ja kasa o motte iku ne"},
 {"":"Person 2:","Kanji":"雷が鳴るかもしれないから気をつけて帰ってきなさいね。","Hiragana":"かみなりがなるかもしれないからきをつけてかえってきなさいね。","Romaji":"Kaminari ga naru kamo shirenai kara ki o tsukete kaette kinasai ne.","English Meaning":"There might be thunder so be careful on your way home!", "audio": "kaminari ga naru kamo shirenai kara ki o tsukete kaette kinasai ne"}
]

const vocabbreakdown1 = [
  {"Kanji":"天気","Hiragana":"てんき","Romaji":"tenki","English Meaning":"weather","audio":"tenki"},
 {"Kanji":"雨","Hiragana":"あめ","Romaji":"ame","English Meaning":"rain","audio":"ame"},
 {"Kanji":"降る","Hiragana":"ふる","Romaji":"furu","English Meaning":"to precipitate, to fall (e.g. rain)","audio":"furu"},
 {"Kanji":"結構","Hiragana":"けっこう","Romaji":"kekkou","English Meaning":"(depending on context) splendid, nice, wonderful, sufficient, fine (in the sense of \"I'm fine\"), (by implication) no thank you, well enough, OK, tolerable, reasonably, fairly, tolerably","audio":"kekkou"},
 {"Kanji":"曇る","Hiragana":"くもる","Romaji":"kumoru","English Meaning":"to get cloudy, to cloud over, to become overcast","audio":"kumoru"},
 {"Kanji":"雲","Hiragana":"くも","Romaji":"kumo","English Meaning":"cloud","audio":"kumo"},
 {"Kanji":"","Hiragana":"やっぱり","Romaji":"yappari","English Meaning":"as I thought, still, in spite of, absolutely, of course","audio":"yappari"},
 {"Kanji":"残念","Hiragana":"ざんねん","Romaji":"zannen","English Meaning":"deplorable, bad luck, regret, disappointment","audio":"zannen"},
 {"Kanji":"","Hiragana":"つもり","Romaji":"tsumori","English Meaning":"intention, plan","audio":"tsumori"},
 {"Kanji":"","Hiragana":"がっかりする","Romaji":"gakkari suru","English Meaning":"to be disappointed or discouraged","audio":"gakkari suru"},
 {"Kanji":"週末","Hiragana":"しゅうまつ","Romaji":"shuumatsu","English Meaning":"weekend","audio":"shuumatsu"},
 {"Kanji":"","Hiragana":"はず","Romaji":"hazu","English Meaning":"expectation that something took place/will take place or was in some state, it should be so, bound to be, expected to be, must be","audio":"hazu"},
 {"Kanji":"","Hiragana":"から","Romaji":"kara","English Meaning":"because","audio":"kara"},
 {"Kanji":"傘","Hiragana":"かさ","Romaji":"kasa","English Meaning":"umbrella","audio":"kasa"},
 {"Kanji":"雷","Hiragana":"かみなり","Romaji":"kaminari","English Meaning":"thunder","audio":"kaminari"},
 {"Kanji":"鳴る","Hiragana":"なる","Romaji":"naru","English Meaning":"to sound, to ring, to resound, to echo, to roar, to rumble","audio":"naru"},
 {"Kanji":"","Hiragana":"かもしれない","Romaji":"kamo shirenai","English Meaning":"maybe, might","audio":"kamo shirenai"},
 {"Kanji":"気をつける","Hiragana":"きをつける","Romaji":"ki o tsukeru","English Meaning":"to be careful, to pay attention, to take care","audio":"ki o tsukeru"}
]

const phonepolite = [
  {"":"Person 1:","Kanji":"もしもし、田中株式会社の山田です。","Hiragana":"もしもし、たなかかぶしきがいしゃのやまだです。","Romaji":"Moshi moshi, tanaka kabushikigaisha no yamada desu. ","English Meaning":"Hello.  This is Yamada with the Tanaka Corporation."},
 {"":"Person 2:","Kanji":"田中さんはいますか。","Hiragana":"たなかさんはいますか。","Romaji":"Tanakasan wa imasu ka. ","English Meaning":"Is Mr. Tanaka there?"},
 {"":"Person 1:","Kanji":"田中は只今席を外しております。　もし宜しければ、ご用件を承ります。","Hiragana":"たなかはただいませきをはずしております。　もしよろしければ、ごようけんをうけたまわります。","Romaji":"Tanaka wa tadaima seki o hazushite orimasu.  Moshi yoroshikereba, goyouken o uketamawarimasu. ","English Meaning":"Tanaka is away from his seat at the moment.  If acceptable, I could take a message."},
 {"":"Person 2:","Kanji":"結構です。 また後で、折り返し電話します。","Hiragana":"けっこうです。 またあとで、おりかえしでんわします。","Romaji":"Kekkou desu.  Mata ato de, orikaeshi denwa shimasu. ","English Meaning":"That's ok.  I'll call back later."},
 {"":"Person 1:","Kanji":"ありがとうございます。 失礼します。","Hiragana":"ありがとうございます。 しつれいします。","Romaji":"Arigatou gozaimasu.   Shitsurei shimasu. ","English Meaning":"Thank you.  Goodbye."},
 {"":"Person 2:","Kanji":"はい、失礼します。","Hiragana":"はい、しつれいします。","Romaji":"Hai, shitsurei shimasu. ","English Meaning":"Goodbye."}
]

const phoneplain = [
  {"":"Person 1:","Kanji":"もしもし。","Hiragana":"もしもし。","Romaji":"Moshi moshi. ","English Meaning":"Hello?"},
 {"":"Person 2:","Kanji":"洋子ちゃんいる？","Hiragana":"ようこちゃんいる？", "Romaji":"Youkochan iru?", "English Meaning":"Is Yoko there?"},
 {"":"Person 1:","Kanji":"どちら様ですか。","Hiragana":"どちらさまですか。","Romaji":"Dochirasama desu ka. ","English Meaning":"May I ask who's calling? (polite form)"},
 {"":"Person 2:","Kanji":"ごめんね。 浩之だよ。","Hiragana":"ごめんね。 ひろゆきだよ。","Romaji":"Gomen ne.  Hiroyuki da yo. ","English Meaning":"Sorry.  It's Hiroyuki."},
 {"":"Person 1:","Kanji":"あっ、ひろ君、洋子はいないけど。","Hiragana":"あっ、ひろくん、ようこはいないけど。","Romaji":"A, hirokun, youko wa inai kedo. ","English Meaning":"Ah, Hiro.  Yoko isn't here right now."},
 {"":"Person 2:","Kanji":"いつ帰って来ると思う？","Hiragana":"いつかえってくるとおもう？", "Romaji":"Itsu kaette kuru to omou?", "English Meaning":"When do you think she'll be home?"},
 {"":"Person 1:","Kanji":"三時ごろ。","Hiragana":"さんじごろ。","Romaji":"Sanji goro. ","English Meaning":"Around 3."},
 {"":"Person 2:","Kanji":"いいよ、また電話するね。 じゃあね。","Hiragana":"いいよ、またでんわするね。 じゃあね。","Romaji":"Ii yo, mata denwa suru ne.  Jaa ne. ","English Meaning":"Ok, I'll call back.  See ya."},
 {"":"Person 1:","Kanji":"じゃあね。","Hiragana":"じゃあね。","Romaji":"Jaa ne. ","English Meaning":"See ya."}
]

const vocabbreakdown2 = [
  {"Kanji":"","Hiragana":"もしもし","Romaji":"moshi moshi","English Meaning":"hello (phone only)","audio":"moshi moshi"},
 {"Kanji":"株式会社","Hiragana":"かぶしきがいしゃ","Romaji":"kabushikigaisha","English Meaning":"public company, orporation","audio":"kabushikigaisha"},
 {"Kanji":"只今","Hiragana":"ただいま","Romaji":"tadaima","English Meaning":"presently, right away, right now, just now","audio":"tadaima"},
 {"Kanji":"席","Hiragana":"せき","Romaji":"seki","English Meaning":"seat","audio":"seki"},
 {"Kanji":"外す","Hiragana":"はずす","Romaji":"hazusu","English Meaning":"to unfasten, to undo, to remove, to take off, to deinstall, to leave, to step out, to slip away","audio":"hazusu"},
 {"Kanji":"","Hiragana":"もし","Romaji":"moshi","English Meaning":"if","audio":"moshi"},
 {"Kanji":"宜しい","Hiragana":"よろしい","Romaji":"yoroshii","English Meaning":"good, OK, all right, fine, very well","audio":"yoroshii"},
 {"Kanji":"用件","Hiragana":"ようけん","Romaji":"youken","English Meaning":"business, thing to be done, something that should be perfomed, information that should be conveyed","audio":"youken"},
 {"Kanji":"承る","Hiragana":"うけたまわる","Romaji":"uketamawaru","English Meaning":"to hear, to be told, to know, to receive (order), to undertake, to comply, to take (a reservation, etc.)","audio":"uketamawaru"},
 {"Kanji":"","Hiragana":"また","Romaji":"mata","English Meaning":"again, and, also","audio":"mata"},
 {"Kanji":"後で","Hiragana":"あとで","Romaji":"ato de","English Meaning":"afterwards","audio":"ato de"},
 {"Kanji":"折り返し","Hiragana":"おりかえし","Romaji":"orikaeshi","English Meaning":"by return","audio":"orikaeshi"},
 {"Kanji":"電話する","Hiragana":"でんわする","Romaji":"denwa suru","English Meaning":"to make a phone call","audio":"denwa suru"},
 {"Kanji":"折り返し電話する","Hiragana":"おりかえしでんわする","Romaji":"orikaeshi denwa suru","English Meaning":"to call back","audio":"orikaeshi denwa suru"},
 {"Kanji":"失礼する","Hiragana":"しつれいする","Romaji":"shitsurei suru","English Meaning":"to be rude (used on the phone to indicate one is going to hang up)","audio":"shitsurei suru"}
]

const datingpolite = [
  {"":"Person 1:","Kanji":"あのう、今週の金曜日の夜は空いていますか。","Hiragana":"あのう、こんしゅうのきんようびのよるはあいていますか。","Romaji":"Anou, konshuu no kinyoubi no yoru wa aite imasu ka. ","English Meaning":"Um, are you available this Friday night?"},
 {"":"Person 2:","Kanji":"はい、空いています。 どうしてですか。","Hiragana":"はい、あいています。　どうしてですか。","Romaji":"Hai, aite imasu. 　Doushite desu ka. ","English Meaning":"Yes, I'm available.  Why do you ask?"},
 {"":"Person 1:","Kanji":"一緒に食べに行ったらどうかなと思っています。","Hiragana":"いっしょにたべにいったらどうかなとおもっています。","Romaji":"Issho ni tabe ni ittara dou ka na to omotte imasu. ","English Meaning":"I was thinking maybe we could go out to eat."},
 {"":"Person 2:","Kanji":"いいですよ。 何時に会いましょうか。","Hiragana":"いいですよ。 なんじにあいましょうか。","Romaji":"Ii desu yo.  Nanji ni aimashou ka. ","English Meaning":"Sure.  What time shall we meet?"},
 {"":"Person 1:","Kanji":"七時半に迎えに行ってもいいですか。","Hiragana":"しちじはんにむかえにいってもいいですか。","Romaji":"Shichijihan ni mukae ni itte mo ii desu ka. ","English Meaning":"Is it ok if I pick you up at 7:30?"},
 {"":"Person 2:","Kanji":"はい、楽しみにしています。","Hiragana":"はい、たのしみにしています。","Romaji":"Hai, tanoshimi ni shite imasu. ","English Meaning":"Sure, looking forward to it."}
]

const datingplain = [
  {"":"Person 1:","Kanji":"土曜日にデートしようか。","Hiragana":"どようびにでーとしようか。","Romaji":"Doyoubi ni de-to shiyou ka. ","English Meaning":"Want to go out this Saturday?"},
 {"":"Person 2:","Kanji":"いいよ、これは何回目のデートになる？", "Hiragana":"いいよ、これはなんかいめのでーとになる？", "Romaji":"Ii yo, kore wa nankaime no de-to ni naru?", "English Meaning":"Sure, how many dates will this make?"},
 {"":"Person 1:","Kanji":"君は知ってるでしょ。 五回目のデートだよ。","Hiragana":"きみはしってるでしょ。 ごかいめのでーとだよ。","Romaji":"Kimi wa shitte ru desho.  Gokaime no de-to da yo. ","English Meaning":"You know.  This will be our fifth date!"},
 {"":"Person 2:","Kanji":"そうだね。 いい関係になってきてるね。","Hiragana":"そうだね。 いいかんけいになってきてるね。","Romaji":"Sou da ne.  Ii kankei ni natte kite ru ne. ","English Meaning":"Yep.  This relationship is starting to get serious, huh."},
 {"":"Person 1:","Kanji":"うん、結構君のことが好きになってきてるよ。","Hiragana":"うん、けっこうきみのことがすきになってきてるよ。","Romaji":"Un, kekkou kimi no koto ga suki ni natte kite ru yo. ","English Meaning":"Yeah, I'm really starting to like you."},
 {"":"Person 2:","Kanji":"私もだよ。","Hiragana":"わたしもだよ。","Romaji":"Watashi mo da yo.  ","English Meaning":"Me too!"}
]

const vocabbreakdown3 = [
  {"Kanji":"","Hiragana":"あのう","Romaji":"anou","English Meaning":"um, errr","audio":"anou"},
 {"Kanji":"空く","Hiragana":"あく","Romaji":"aku","English Meaning":"to be empty, to be vacant, to be available, to be free","audio":"aku"},
 {"Kanji":"一緒に","Hiragana":"いっしょに","Romaji":"issho ni","English Meaning":"together (with)","audio":"issho ni"},
 {"Kanji":"迎えに行く","Hiragana":"むかえにいく","Romaji":"mukae ni iku","English Meaning":"to go to meet somebody, to pick someone up","audio":"mukae ni iku"},
 {"Kanji":"楽しむ","Hiragana":"たのしむ","Romaji":"tanoshimu","English Meaning":"to enjoy (oneself)","audio":"tanoshimu"},
 {"Kanji":"楽しみにする","Hiragana":"たのしみにする","Romaji":"tanoshimi ni suru","English Meaning":"to look forward to something","audio":"tanoshimi ni suru"},
 {"Kanji":"デート","Hiragana":"でーと","Romaji":"de-to","English Meaning":"date (katakana)","audio":"de-to"},
 {"Kanji":"回目","Hiragana":"かいめ","Romaji":"kaime","English Meaning":"-Nth time around","audio":"kaime"},
 {"Kanji":"関係","Hiragana":"かんけい","Romaji":"kankei","English Meaning":"relation, relationship, connection","audio":"kankei"}
]

const Conversations = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Beginner" category="Phrases" lesson={title} />
     <div className={'title'}>
     {title}
      </div>
      <h1>{title}</h1>

<h2>Weather (polite form)</h2>

<table>
<thead>
<tr>
<th></th>
<th>Japanese(In Kanji, Hiragana, and Romaji)</th>
<th>English Meaning</th>
</tr>
</thead>
<tbody>

<tr>
<td>
<b>Person 1:</b>
</td>
<td>

明日の天気はどうですか。 雨が降りそうです。<br />

あしたのてんきはどうですか。 あめがふりそうです。<br />

<Play audio="ashita no tenki wa dou desu ka" /> <Play audio="ame ga furisou desu" />

</td>
<td>
What is the weather going to be like tomorrow?  It looks like it might rain.
</td>
</tr>
<tr>
<td>
<b>Person 2:</b>
</td>
<td>

そうですね。結構曇っていますね。<br />

そうですね。けっこうくもっていますね。<br />

<Play audio="sou desu ne" /> <Play audio="kekkou kumotte imasu ne" />

</td>
<td>
Sure does.  It's pretty cloudy, huh.
</td>
</tr>
<tr>
<td colspan="3">

<b>[Person 2 checks something]</b>

</td>
</tr>
<tr>
<td>
<b>Person 2:</b>
</td>
<td>

やっぱり、雨が降ると言っています。<br />

やっぱり、あめがふるといっています。<br />

<Play audio="yappari ame ga furu to itte imasu" />

</td>
<td>
Yep, they are saying it's going to rain.
</td>
</tr>
<tr>
<td>
<b>Person 1:</b>
</td>
<td>

残念ですね。テニスをするつもりでした。<br />

ざんねんですね。てにすをするつもりでした。<br />

<Play audio="zannen desu ne" /> <Play audio="tenisu o suru tsumori deshita" />

</td>
<td>
That's too bad.  I was planning on playing tennis.
</td>
</tr>
<tr>
<td>
<b>Person 2:</b>
</td>
<td>

がっかりしないでね。この週末はいい天気になるはずですからゴルフをしに行きましょう！<br />

がっかりしないでね。このしゅうまつはいいてんきになるはずですからごるふをしにいきましょう！<br />

<Play audio="gakkari shinaide ne" /> <Play audio="kono shuumatsu wa ii tenki ni naru hazu desu kara gorufu o shi ni ikimashou" />

</td>
<td>
Don't be discouraged.  It should be good weather this weekend so let's go golfing!
</td>
</tr>
</tbody></table>

      <h2>Weather (plain form)</h2>

      <Table data={weatherplain1} />

<h3>Vocab Breakdown</h3>

      <Table data={vocabbreakdown1} />

<h2>Phone (polite form)</h2>

<table>
<thead>
<tr>
<th></th>
<th>Japanese(In Kanji, Hiragana, and Romaji)</th>
<th>English Meaning</th>
</tr>
</thead>
<tbody>
<tr>
<td>
<b>Person 1:</b>
</td>
<td>

もしもし、田中株式会社の山田です。<br />

もしもし、たなかかぶしきがいしゃのやまだです。<br />

<Play audio="moshi moshi tanaka kabushikigaisha no yamada desu" />

</td>
<td>
Hello.  This is Yamada with the Tanaka Corporation.
</td>
</tr>
<tr>
<td>
<b>Person 2:</b>
</td>
<td>

田中さんはいますか。<br />

たなかさんはいますか。<br />

<Play audio="tanakasan wa imasu ka" />

</td>
<td>
Is Mr. Tanaka there?
</td>
</tr>
<tr>
<td>
<b>Person 1:</b>
</td>
<td>

田中は只今席を外しております。もし宜しければ、ご用件を承ります。<br />

たなかはただいませきをはずしております。もしよろしければ、ごようけんをうけたまわります。<br />

<Play audio="tanaka wa tadaima seki o hazushite orimasu" /> <Play audio="moshi yoroshikereba goyouken o uketamawarimasu" />

</td>
<td>
Tanaka is away from his seat at the moment.  If acceptable, I could take a message.
</td>
</tr>
<tr>
<td>
<b>Person 2:</b>
</td>
<td>

結構です。また後で、折り返し電話します。<br />

けっこうです。またあとで、おりかえしでんわします。<br />

<Play audio="kekkou desu" /> <Play audio="mata ato de orikaeshi denwa shimasu" />

</td>
<td>
That's ok.  I'll call back later.
</td>
</tr>
<tr>
<td>
<b>Person 1:</b>
</td>
<td>

ありがとうございます。失礼します。<br />

ありがとうございます。しつれいします。<br />

<Play audio="arigatou gozaimasu" /> <Play audio="shitsurei shimasu" />

</td>
<td>
Thank you.  Goodbye.
</td>
</tr>
<tr>
<td>
<b>Person 2:</b>
</td>
<td>

はい、失礼します。<br />

はい、しつれいします。<br />

<Play audio="hai shitsurei shimasu" />

</td>
<td>
Goodbye.
</td>
</tr>
</tbody></table>

      <h2>Phone (plain form)</h2>

      <table>
      <thead>
<tr>
<th></th>
<th>Japanese(In Kanji, Hiragana, and Romaji)</th>
<th>English Meaning</th>
</tr>
</thead>
<tbody>
  
<tr>
<td>
<b>Person 1:</b>
</td>
<td>

もしもし。<br />

もしもし。<br />

<Play audio="moshi moshi" />

</td>
<td>
Hello?
</td>
</tr>
<tr>
<td>
<b>Person 2:</b>
</td>
<td>

洋子ちゃんいる？<br />

ようこちゃんいる？<br />

<Play audio="youkochan iru--" />

</td>
<td>
Is Yoko there?
</td>
</tr>
<tr>
<td>
<b>Person 1:</b>
</td>
<td>

どちら様ですか。<br />

どちらさまですか。<br />

<Play audio="dochirasama desu ka" />

</td>
<td>
May I ask who's calling? (polite form)
</td>
</tr>
<tr>
<td>
<b>Person 2:</b>
</td>
<td>

ごめんね。浩之だよ。<br />

ごめんね。ひろゆきだよ。<br />

<Play audio="gomen ne" /> <Play audio="hiroyuki da yo" />

</td>
<td>
Sorry.  It's Hiroyuki.
</td>
</tr>
<tr>
<td>
<b>Person 1:</b>
</td>
<td>

あっ、ひろ君、洋子はいないけど。<br />

あっ、ひろくん、ようこはいないけど。<br />

<Play audio="a hirokun youko wa inai kedo" />

</td>
<td>
Ah, Hiro.  Yoko isn't here right now.
</td>
</tr>
<tr>
<td>
<b>Person 2:</b>
</td>
<td>

いつ帰って来ると思う？<br />

いつかえってくるとおもう？<br />

<Play audio="itsu kaette kuru to omou--" /> 

</td>
<td>
When do you think she'll be home?
</td>
</tr>
<tr>
<td>
<b>Person 1:</b>
</td>
<td>

三時ごろ。<br />

さんじごろ。<br />

<Play audio="sanji goro" />

</td>
<td>
Around 3.
</td>
</tr>
<tr>
<td>
<b>Person 2:</b>
</td>
<td>

いいよ、また電話するね。じゃあね。<br />

いいよ、またでんわするね。じゃあね。<br />

<Play audio="ii yo mata denwa suru ne" /> <Play audio="jaa ne" />

</td>
<td>
Ok, I'll call back.  See ya.
</td>
</tr>
<tr>
<td>
<b>Person 1:</b>
</td>
<td>

じゃあね。<br />

じゃあね。<br />

<Play audio="jaa ne" />

</td>
<td>
See ya.
</td>
</tr>
</tbody></table>

      <h3>Vocab Breakdown</h3>

      <Table data={vocabbreakdown2} />

      <h2>Dating (polite form)</h2>

      <table>
      <thead>
<tr>
<th></th>
<th>Japanese(In Kanji, Hiragana, and Romaji)</th>
<th>English Meaning</th>
</tr>
</thead>
<tbody>
<tr>
<td>
<b>Person 1:</b>
</td>
<td>

あのう、今週の金曜日の夜は空いていますか。
<br />
あのう、こんしゅうのきんようびのよるはあいていますか。
<br />
<Play audio="anou konshuu no kinyoubi no yoru wa aite imasu ka" />

</td>
<td>
Um, are you available this Friday night?
</td>
</tr>
<tr>
<td>
<b>Person 2:</b>
</td>
<td>

はい、空いています。どうしてですか。
<br />
はい、あいています。どうしてですか。
<br />
<Play audio="hai aite imasu" />　<Play audio="doushite desu ka" />

</td>
<td>
Yes, I'm available.  Why do you ask?
</td>
</tr>
<tr>
<td>
<b>Person 1:</b>
</td>
<td>

一緒に食べに行ったらどうかなと思っています。
<br />
いっしょにたべにいったらどうかなとおもっています。
<br />
<Play audio="issho ni tabe ni ittara dou ka na to omotte imasu" />

</td>
<td>
I was thinking maybe we could go out to eat.
</td>
</tr>
<tr>
<td>
<b>Person 2:</b>
</td>
<td>

いいですよ。何時に会いましょうか。
<br />
いいですよ。なんじにあいましょうか。
<br />
<Play audio="ii desu yo" /> <Play audio="nanji ni aimashou ka" />

</td>
<td>
Sure.  What time shall we meet?
</td>
</tr>
<tr>
<td>
<b>Person 1:</b>
</td>
<td>

七時半に迎えに行ってもいいですか。
<br />
しちじはんにむかえにいってもいいですか。
<br />
<Play audio="shichijihan ni mukae ni itte mo ii desu ka" />

</td>
<td>
Is it ok if I pick you up at 7:30?
</td>
</tr>
<tr>
<td>
<b>Person 2:</b>
</td>
<td>

はい、楽しみにしています。
<br />
はい、たのしみにしています。
<br />
<Play audio="hai tanoshimi ni shite imasu" />

</td>
<td>
Sure, looking forward to it.
</td>
</tr>
</tbody></table>

      <h2>Dating (plain form)</h2>

      <table>
      <thead>
<tr>
<th></th>
<th>Japanese(In Kanji, Hiragana, and Romaji)</th>
<th>English Meaning</th>
</tr>
</thead>
<tbody>
<tr>
<td>
<b>Person 1:</b>
</td>
<td>

土曜日にデートしようか。
<br />
どようびにでーとしようか。
<br />
<Play audio="doyoubi ni de-to shiyou ka" />

</td>
<td>
Want to go out this Saturday?
</td>
</tr>
<tr>
<td>
<b>Person 2:</b>
</td>
<td>

いいよ、これは何回目のデートになる？
<br />
いいよ、これはなんかいめのでーとになる？
<br />
<Play audio="ii yo kore wa nankaime no de-to ni naru--" />

</td>
<td>
Sure, how many dates will this make?
</td>
</tr>
<tr>
<td>
<b>Person 1:</b>
</td>
<td>

君は知ってるでしょ。五回目のデートだよ。
<br />
きみはしってるでしょ。ごかいめのでーとだよ。
<br />
<Play audio="kimi wa shitte ru desho" /> <Play audio="gokaime no de-to da yo" />

</td>
<td>
You know.  This will be our fifth date!
</td>
</tr>
<tr>
<td>
<b>Person 2:</b>
</td>
<td>

そうだね。いい関係になってきてるね。
<br />
そうだね。いいかんけいになってきてるね。
<br />
<Play audio="sou da ne" /> <Play audio="ii kankei ni natte kite ru ne" />

</td>
<td>
Yep.  This relationship is starting to get serious, huh.
</td>
</tr>
<tr>
<td>
<b>Person 1:</b>
</td>
<td>

うん、結構君のことが好きになってきてるよ。
<br />
うん、けっこうきみのことがすきになってきてるよ。
<br />
<Play audio="un kekkou kimi no koto ga suki ni natte kite ru yo" />

</td>
<td>
Yeah, I'm really starting to like you.
</td>
</tr>
<tr>
<td>
<b>Person 2:</b>
</td>
<td>

私もだよ。
<br />
わたしもだよ。
<br />
<Play audio="watashi mo da yo" /> 

</td>
<td>
Me too!
</td>
</tr>
</tbody></table>

      <h3>Vocab Breakdown</h3>

      <Table data={vocabbreakdown3} />



    </>
  )
}

export default Conversations