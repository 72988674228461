import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Japanese Honorifics"

const honorone = [
  {"Kanji":"失礼致します","Hiragana":"しつれいいたします","Romaji":"Shitsurei itashimasu.","audio":"shitsurei itashimasu","English Meaning":"Excuse me.(Humble form of \"shitsurei shimasu\")"},
 {"Kanji":"お荷物を運んで差し上げます","Hiragana":"おにもつをはこんでさしあげます","Romaji":"Onimotsu o hakonde sashiagemasu.","audio":"onimotsu o hakonde sashiagemasu","English Meaning":"I will carry your luggage for you.(Humble form of \"nimotsu o hakonde agemasu\")"},
 {"Kanji":"お父さんはおられますか","Hiragana":"おとうさんはおられますか","Romaji":"Otousan wa oraremasu ka.","audio":"otousan wa oraremasu ka","English Meaning":"Is your father here/there?(Honorific form of \"otousan wa imasu ka\")"},
 {"Kanji":"お客様のお名前は何とおっしゃいますか","Hiragana":"おきゃくさまのおなまえはなんとおっしゃいますか","Romaji":"Okyakusama no onamae wa nan to osshaimasu ka.","audio":"okyakusama no onamae wa nan to osshaimasu ka","English Meaning":"What is your name?(Honorific form of \"anata no namae wa nan to iimasu ka\")"},
 {"Kanji":"友達が下さった本をご覧になって下さい","Hiragana":"ともだちがくださったほんをごらんになってください","Romaji":"Tomodachi ga kudasatta hon o goran ni natte kudasai.","audio":"tomodachi ga kudasatta hon o goran ni natte kudasai","English Meaning":"Please look at this book my friend gave me.(Honorific form of \"tomodachi ga kureta hon o mite kudasai\")"},
 {"Kanji":"この方をご存知ですか","Hiragana":"このかたをごぞんじですか","Romaji":"Kono kata o gozonji desu ka.","audio":"kono kata o gozonji desu ka","English Meaning":"Do you know this person?(Honorific form of \"kono hito o shitte imasu ka\")"},
 {"Kanji":"申し訳御座いません","Hiragana":"もうしわけございません","Romaji":"Moushiwake gozaimasen.","audio":"moushiwake gozaimasen","English Meaning":"I'm sorry/(it's) inexcusable.(Honorific form of \"moushiwake arimasen\")"},
 {"Kanji":"どうぞ、お召し上がり下さい","Hiragana":"どうぞ、おめしあがりください","Romaji":"Douzo, omeshiagari kudasai.","audio":"douzo omeshiagari kudasai","English Meaning":"Please, go ahead and eat.(Honorific form of \"douzo, tabete kudasai\" - higher than \"douzo, meshiagatte kudasai\")"}
]
const honortwo = [
  {"Kanji":"運ぶ","Hiragana":"はこぶ","Romaji":"hakobu","English Meaning":"to carry, to transport, to convey","audio":"hakobu"},
 {"Kanji":"申し訳","Hiragana":"もうしわけ","Romaji":"moushiwake","English Meaning":"apology, excuse","audio":"moushiwake"},
 {"Kanji":"お + Base 2 + 下さい","Hiragana":"お + Base 2 + ください","Romaji":"o + Base 2 + kudasai","English Meaning":"honorific command form","audio":"kudasai"}
]


const Honorifics = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    
    <NavBar level="Intermediate" category="Vocabulary" lesson="Honorifics" />

      <h1>{title}</h1>

      <p>The Japanese language has mainly layers of politeness and using the wrong level can be incredibly rude. For instance, plain form should only be used on friends and family - those you know well. Polite form should be used on those you don't know well. Honorifics take this one step further into an ultra polite form. They are great for business situations or for cases when you just want to be extra polite when getting to know someone. It's a great way to impress!</p>

      <p>There are two main types of honorifics - those which humble and those which elevate. Those which humble should only be used on yourself and those that elevate should only be used on others. This is VERY important. Honorifics should also only be used in polite form so the example sentences will be done accordingly. While most name suffixes aren't technically honorifics, they fall into the category of things which should only be used on others (never on yourself) and we will cover them in this lesson.</p>

<h2>Name Suffixes</h2>

<p>Name suffixes are placed at the end of someone's name - typically their "family name" (what we would call a "last name" in English but it actually comes first in Japanese). As mentioned above, name suffixes should only be used on others and should not be used on yourself. They are used to elevate the other person so using them on yourself is considered rude. Name suffixes are used quite a bit since, in Japanese, the word for "you" is not generally used if you know the person's name and you call them by name instead (even when talking directly to them).</p>

<table>
  <thead>
<tr>
<th>漢字 - Kanji</th>
<th>ひらがな - Hiragana</th>
<th>Romaji (English Letters)</th>
<th>English Meaning</th>
</tr>
</thead>
<tbody>
<tr>
<td>様</td>
<td>さま</td>
<td><Play audio="sama" /></td>
<td>This is the only name suffix that is technically an honorific.  It can be used with men or women and is the ultra polite version of "san".  There are name suffixes higher than "sama" but they are used so infrequently that there's really no point in covering them.</td>
</tr>
<tr>
<td>さん</td>
<td>さん</td>
<td><Play audio="san" /></td>
<td>This is the typical name suffix.</td>
</tr>
<tr>
<td>君</td>
<td>くん</td>
<td><Play audio="kun" /></td>
<td>"kun" is the standard name suffix for young males.  It is generally only used in plain form and with those you feel comfortable speaking informally with.</td>
</tr>
<tr>
<td>ちゃん</td>
<td>ちゃん</td>
<td><Play audio="chan" /></td>
<td>"chan" is typically only used on young girls and children.  It is very "cutesy" so you could maybe get away with using it on a girlfriend.</td>
</tr>
</tbody></table>


<h2>Humble Honorifics</h2>

<p>As mentioned above, humble honorifics should only be used on yourself. By humbling yourself, you elevate the other person.</p>

<table>
<thead>
<tr>
<th>漢字 - Kanji</th>
<th>ひらがな - Hiragana</th>
<th>Romaji (English Letters)</th>
<th>English Meaning</th>
</tr>
</thead>
<tbody>
<tr>
<td>おる</td>
<td>おる</td>
<td><Play audio="oru" /></td>
<td>This is the humble form of "iru" (to exist - animate).  It can be used anywhere you might typically use "iru" like base "te" + "iru" (currently verbing).  The kanji form of "oru" is 居る but it is typically just written in hiragana since this is also the kanji form of "iru".</td>
</tr>
<tr>
<td>致す</td>
<td>いたす</td>
<td><Play audio="itasu" /></td>
<td>This is the humble form of "suru" (to do).</td>
</tr>
<tr>
<td>参る</td>
<td>まいる</td>
<td><Play audio="mairu" /></td>
<td>This is the humble form of "iku" (to go) and "kuru" (to come).  The context in which it is used determines the meaning.  While this looks like an ichidan verb, it is actually a godan verb so be sure to conjugate it as such.</td>
</tr>
<tr>
<td>申す</td>
<td>もうす</td>
<td><Play audio="mousu" /></td>
<td>This is the humble form of "iu" (to say).  You might remember this from the lesson <a href="/members/b/common/meeting/">Meeting Someone New</a>.</td>
</tr>
<tr>
<td>頂く</td>
<td>いただく</td>
<td><Play audio="itadaku" /></td>
<td>This is the humble form of "morau" (to receive).  It is often used right before eating or drinking something ("itadakimasu!" <Play audio="itadakimasu" /> - "I humbly receive!").</td>
</tr>
<tr>
<td>差し上げる</td>
<td>さしあげる</td>
<td><Play audio="sashiageru" /></td>
<td>This is the humble form of "ageru" (to give).  It is most commonly used in place of "ageru" in base "te" + "ageru" (you verb for someone else).</td>
</tr>
<tr>
<td>拝見する</td>
<td>はいけんする</td>
<td><Play audio="haiken suru" /></td>
<td>This is the humble form of "miru" (to see, to watch).</td>
</tr>
<tr>
<td>お目にかかる</td>
<td>おめにかかる</td>
<td><Play audio="ome ni kakaru" /></td>
<td>This is the humble form of "au" (to meet).  The "o" in front of "me" (eye) is also an honorific.  It essentially means "to come eye to eye".</td>
</tr>
<tr>
<td>お + Base 2 + する</td>
<td>お + Base 2 + する</td>
<td>o + Base 2 + suru</td>
<td>Any verb can be made humble by using "o" + base 2 + "suru".  One of the most commonly heard forms of this is "onegai shimasu" which is the "o" + base 2 + "suru" form of the verb "negau" (to request).  This form can be made even more polite by replacing "suru" with "itasu".</td>
</tr>
</tbody></table>

<h2>Elevating Honorifics</h2>

<p>As mentioned above, elevating honorifics should only be used on others. By elevating the other person, you humble yourself.</p>

<table>
<thead>
<tr>
<th>漢字 - Kanji</th>
<th>ひらがな - Hiragana</th>
<th>Romaji (English Letters)</th>
<th>English Meaning</th>
</tr>
</thead>
<tbody>
<tr>
<td>御座る</td>
<td>ござる</td>
<td><Play audio="gozaru" /></td>
<td>This is the honorific form of "aru" (to exist - inanimate).  When conjugated into polite form, base 2 + "masu" is "gozaimasu".</td>
</tr>
<tr>
<td>で御座る</td>
<td>でござる</td>
<td><Play audio="de gozaru" /></td>
<td>This is the honorific form of "de aru" (to be) and "de gozaimasu" can be used in place of "desu" (the polite form of "da"/"de aru").</td>
</tr>
<tr>
<td>なさる</td>
<td>なさる</td>
<td><Play audio="nasaru" /></td>
<td>This is the honorific form of "suru" (to do).  When conjugated into polite form, base 2 + "masu" is "nasaimasu".</td>
</tr>
<tr>
<td>いらっしゃる</td>
<td>いらっしゃる</td>
<td><Play audio="irassharu" /></td>
<td>This is the honorific form of "iku" (to go), "kuru" (to come), and "iru" (to exist - animate).  When conjugated into polite form, base 2 + "masu" is "irasshaimasu".  A form of this ("irasshaimase") is often heard greeting customers as they walk into shops or restaurants.</td>
</tr>
<tr>
<td>おいでになる</td>
<td>おいでになる</td>
<td><Play audio="oide ni naru" /></td>
<td>This is another honorific form of "iku" (to go), "kuru" (to come), and "iru" (to exist - animate).  "oide" by itself is the plain form command for "kuru".</td>
</tr>
<tr>
<td>おっしゃる</td>
<td>おっしゃる</td>
<td><Play audio="ossharu" /></td>
<td>This is the honorific form of "iu" (to say).  When conjugated into polite form, base 2 + "masu" is "osshaimasu".</td>
</tr>
<tr>
<td>下さる</td>
<td>くださる</td>
<td><Play audio="kudasaru" /></td>
<td>This is the honorific form of "kureru" (to be given).  It is most commonly used in place of "kureru" in base "te" + "kureru" (someone else verbs for you or another person).  When conjugated into polite form, base 2 + "masu" is "kudasaimasu".  The "kudasai" used with base "te" to create polite form commands derives from this verb.</td>
</tr>
<tr>
<td>ご覧になる</td>
<td>ごらんになる</td>
<td><Play audio="goran ni naru" /></td>
<td>This is the honorific form of "miru" (to see, to watch).  The "go" in front of "ran" is also an honorific.</td>
</tr>
<tr>
<td>召し上がる</td>
<td>めしあがる</td>
<td><Play audio="meshiagaru" /></td>
<td>This is the honorific form of "taberu" (to eat).  "meshi" means "summons".  Not sure how this fits in with eating.</td>
</tr>
<tr>
<td>お召しになる</td>
<td>おめしになる</td>
<td><Play audio="omeshi ni naru" /></td>
<td>This is the honorific form of "kiru" (to wear).  This "meshi" is the same as the one above with an honorific "o" tossed on the front.  Not really sure how this fits in with wearing clothes either.</td>
</tr>
<tr>
<td>おなくなりになる</td>
<td>おなくなりになる</td>
<td><Play audio="onakunari ni naru" /></td>
<td>This is the honorific form of "shinu" (to die).  It is essentially "naku naru" (a polite way to say "shinu") in the "o" + base 2 + "naru" form taught below.</td>
</tr>
<tr>
<td>ご存知です</td>
<td>ごぞんじです</td>
<td><Play audio="gozonji desu" /></td>
<td>This is the honorific form of "shitte iru/imasu" (to know - the base "te" + "iru" form of the verb "shiru" [gv]).</td>
</tr>
<tr>
<td>お + Base 2 + なる</td>
<td>お + Base 2 + なる</td>
<td>o + Base 2 + naru</td>
<td>Any verb can be made into an elevating honorific by using "o" + base 2 + "naru".  The <a href="/members/i/grammar/grammar03/">passive form</a> of a verb can also become an honorific if used like a transitive verb.  For "iru", this would be "orareru" (not "irareru") and can be used like "irassharu".</td>
</tr>
<tr>
<td>宜しい</td>
<td>よろしい</td>
<td><Play audio="yoroshii" /></td>
<td>This is the honorific form of "ii/yoi" (good [dv]).</td>
</tr>
</tbody></table>

<p>Here are some example sentences:</p>

<Table data={honorone} />
<Table data={honortwo} />

    </>
  )
}

export default Honorifics