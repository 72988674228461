import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Transitive, Intransitive, and Passive Verbs"

const transitiveone = [
  {"Kanji":"木から落ちて脚を折った","Hiragana":"きからおちてあしをおった","Romaji":"Ki kara ochite ashi o otta","audio":"ki kara ochite ashi o otta","English Meaning":"I fell out of a tree and broke my leg."},
 {"Kanji":"木から落ちた時に脚が折れた","Hiragana":"きからおちたときにあしがおれた","Romaji":"Ki kara ochita toki ni ashi ga oreta","audio":"ki kara ochita toki ni ashi ga oreta","English Meaning":"My leg broke when I fell out of a tree."},
 {"Kanji":"どっちを食べるかを決めようとしている","Hiragana":"どっちをたべるかをきめようとしている","Romaji":"Dotchi o taberu ka o kimeyou to shite iru","audio":"dotchi o taberu ka o kimeyou to shite ir ","English Meaning":"I'm trying to decide which to eat."},
 {"Kanji":"夜ご飯が決まったら教えて","Hiragana":"よるごはんがきまったらおしえて","Romaji":"Yorugohan ga kimattara oshiete","audio":"yorugohan ga kimattara oshiete","English Meaning":"Let me know when dinner has been decided."},
 {"Kanji":"新しい服を汚さないで","Hiragana":"あたらしいふくをよごさないで","Romaji":"Atarashii fuku o yogosanaide","audio":"atarashii fuku o yogosanaide","English Meaning":"Don't get your new clothes dirty."},
 {"Kanji":"新しい服が汚れると母に叱られる","Hiragana":"あたらしいふくがよごれるとははにしかられる","Romaji":"Atarashii fuku ga yogoreru to haha ni shikarareru","audio":"atarashii fuku ga yogoreru to haha ni shikarareru","English Meaning":"If I get my new clothes dirty I'll be chewed out by my mom."},
 {"Kanji":"寝る前に電気を消してね","Hiragana":"ねるまえにでんきをけしてね","Romaji":"Neru mae ni denki o keshite ne","audio":"neru mae ni denki o keshite ne","English Meaning":"Turn off the lights before you go to sleep, ok."},
 {"Kanji":"電気が消えて何も見えない","Hiragana":"でんきがきえてなにもみえない","Romaji":"Denki ga kiete nanimo mienai","audio":"denki ga kiete nanimo mienai","English Meaning":"The lights went out and I can't see anything."}
]
const transitivetwo = [
  {"Kanji":"","Hiragana":"どっち","Romaji":"dotchi/docchi","English Meaning":"which one","audio":"dotchi"},
 {"Kanji":"新しい","Hiragana":"あたらしい","Romaji":"atarashii","English Meaning":"new [dv]","audio":"atarashii"},
 {"Kanji":"服","Hiragana":"ふく","Romaji":"fuku","English Meaning":"clothes [n]","audio":"fuku"},
 {"Kanji":"何も","Hiragana":"なにも","Romaji":"nanimo","English Meaning":"nothing (with negative verbs)","audio":"nanimo"}
]
const transitivethree = [
  {"Kanji":"彼女はあのリンゴを食べた","Hiragana":"かのじょはあのリンゴをたべた","Romaji":"Kanojo wa ano ringo o tabeta","audio":"kanojo wa ano ringo o tabeta","English Meaning":"She ate the apple."},
 {"Kanji":"あのリンゴが彼女に食べられた","Hiragana":"あのリンゴがかのじょにたべられた","Romaji":"Ano ringo ga kanojo ni taberareta","audio":"ano ringo ga kanojo ni taberareta","English Meaning":"The apple was eaten by her."},
 {"Kanji":"そのままにしたら彼は僕の財布を盗むと思う","Hiragana":"そのままにしたらかれはぼくのさいふをぬすむとおもう","Romaji":"Sono mama ni shitara kare wa boku no saifu o nusumu to omou","audio":"sono mama ni shitara kare wa boku no saifu o nusumu to omou","English Meaning":"If I leave it where it is I think he will steal my wallet."},
 {"Kanji":"そのままにしたら僕の財布は彼に盗まれると思う","Hiragana":"そのままにしたらぼくのさいふはかれにぬすまれるとおもう","Romaji":"Sono mama ni shitara boku no saifu wa kare ni nusumareru to omo ","audio":"sono mama ni shitara boku no saifu wa kare ni nusumareru to omou","English Meaning":"If I leave it where it is I think my wallet will be stolen by him."},
 {"Kanji":"クッキーを焼いてから食べてもいいよ","Hiragana":"クッキーをやいてからたべてもいいよ","Romaji":"Kukki- o yaite kara tabete mo ii yo","audio":"kukki- o yaite kara tabete mo ii yo","English Meaning":"You may eat the cookies after I bake them."},
 {"Kanji":"クッキーが焼かれてから食べてもいいよ","Hiragana":"クッキーがやかれてからたべてもいいよ","Romaji":"Kukki- ga yakarete kara tabete mo ii yo","audio":"kukki- ga yakarete kara tabete mo ii y ","English Meaning":"You may eat the cookies after they have been baked."},
 {"Kanji":"ピアノを弾くことを学びたい","Hiragana":"ピアノをひくことをまなびたい","Romaji":"Piano o hiku koto o manabitai","audio":"piano o hiku koto o manabitai","English Meaning":"I want to learn how to play the piano."},
 {"Kanji":"ピアノが弾かれることは綺麗だと思う","Hiragana":"ピアノがひかれることはきれいだとおもう","Romaji":"Piano ga hikareru koto wa kirei da to omou","audio":"piano ga hikareru koto wa kirei da to omo ","English Meaning":"I think the sound of a piano being played is beautiful."}
]
const transitivefour = [
  {"Kanji":"","Hiragana":"そのまま","Romaji":"sono mama","English Meaning":"without change, as it is (i.e. now)","audio":"sono mama"},
 {"Kanji":"財布","Hiragana":"さいふ","Romaji":"saifu","English Meaning":"purse, handbag, wallet [n]","audio":"saifu"},
 {"Kanji":"盗む","Hiragana":"ぬすむ","Romaji":"nusumu","English Meaning":"to steal [gv]","audio":"nusumu"},
 {"Kanji":"クッキー","Hiragana":"くっきー","Romaji":"kukki-","English Meaning":"cookie, cookies [n]","audio":"kukki-"},
 {"Kanji":"弾く","Hiragana":"ひく","Romaji":"hiku","English Meaning":"to play (piano, guitar) [gv]","audio":"hiku"},
 {"Kanji":"学ぶ","Hiragana":"まなぶ","Romaji":"manabu","English Meaning":"to study (in depth), to learn [gv]","audio":"manabu"},
 {"Kanji":"綺麗","Hiragana":"きれい","Romaji":"kirei","English Meaning":"pretty, lovely, beautiful, clean [dn]","audio":"kirei"}
]

const Transitive = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Intermediate" category="Grammar" lesson={title} />

      <h1>{title}</h1>

<p>Understanding the difference between transitive and intransitive Japanese verbs is very important. The same goes for the passive form - which essentially turns a transitive verb into an intransitive verb (for instance, when that verb doesn't have an intransitive equivalent). Let's cover transitive and intransitive verbs first.</p>

<p>A transitive verb is something performed by the topic/subject on an object - like "kare o okoshita" (I woke him up). An intransitive verb is something that the topic/subject does itself - like "kare wa okita" (he woke up). In this particular example (okosu (to wake someone up), okiru (to wake up)) is one of many transitive/intransitive verb pairs in Japanese (the pairs always share a kanji). One of the verbs in each pair is almost always an ichidan verb while the other is typically a godan verb. Which is which doesn't follow verb standard rules, however. One rule of thumb is that when the verb ends in "su" it is the transitive and the other (which is often an ichidan) is the intransitive. Otherwise, the ichidan is almost always (but not always) the transitive and the other is the intransitive. Japanese dictionaries will often list both with the same or similar definitions and not say which is which. Here is a list of many transitive/intransitive pairs:</p>

<table>
<thead>
<tr>
<th colspan="2">Transitive</th>
<th colspan="2">Intransitive</th>
</tr>
<tr>
<th>Japanese (In Kanji, Hiragana, and Romaji)</th>
<th>English Meaning</th>
<th>Japanese (In Kanji, Hiragana, and Romaji)</th>
<th>English Meaning</th>
</tr>
  </thead>
<tbody>
<tr>
<td>

起こす<br />(おこす - okosu) [gv] <Play audio="okosu" />

</td>
<td>
to raise, to cause, to wake someone
</td>
<td>

起きる<br />(おきる - okiru) [iv] <Play audio="okiru" />

</td>
<td>
to get up, to rise, to wake up, to happen
</td>
</tr>
<tr>
<td>

出す<br />(だす - dasu) [gv] <Play audio="dasu" />

</td>
<td>
to expel or put something out, to reveal something, to show something
</td>
<td>

出る<br />(でる - deru) [iv] <Play audio="deru" />

</td>
<td>
to go out, to exit, to leave
</td>
</tr>
<tr>
<td>

溶かす<br />(とかす - tokasu) [gv] <Play audio="tokasu" />

</td>
<td>
to melt something, to dissolve something
</td>
<td>

溶ける<br />(とける - tokeru) [iv] <Play audio="tokeru" />

</td>
<td>
to melt, to thaw, to dissolve
</td>
</tr>
<tr>
<td>

過ごす<br />(すごす - sugosu) [gv] <Play audio="sugosu" />

</td>
<td>
to spend time
</td>
<td>

過ぎる<br />(すぎる - sugiru) [iv] <Play audio="sugiru" />

</td>
<td>
to elapse, to go beyond, to be over, to surpass
</td>
</tr>
<tr>
<td>

冷やす<br />(ひやす - hiyasu) [gv] <Play audio="hiyasu" />

</td>
<td>
to cool, to chill, to refrigerate
</td>
<td>

冷える<br />(ひえる - hieru) [iv] <Play audio="hieru" />

</td>
<td>
to grow cold, to get chilly, to cool down
</td>
</tr>
<tr>
<td>

落とす<br />(おとす - otosu) [gv] <Play audio="otosu" />

</td>
<td>
to drop something, to let fall
</td>
<td>

落ちる<br />(おちる - ochiru) [iv] <Play audio="ochiru" />

</td>
<td>
to fall down, to drop
</td>
</tr>
<tr>
<td>

消す<br />(けす - kesu) [gv] <Play audio="kesu" />

</td>
<td>
to erase, to delete, to put out
</td>
<td>

消える<br />(きえる - kieru) [iv] <Play audio="kieru" />

</td>
<td>
to go out, to be extinguished
</td>
</tr>
<tr>
<td>

降ろす<br />(おろす - orosu) [gv] <Play audio="orosu" />

</td>
<td>
to let (a person) off, to unload
</td>
<td>

降りる<br />(おりる - oriru) [iv] <Play audio="oriru" />

</td>
<td>
to step down, to get off, to disembark, to dismount
</td>
</tr>
<tr>
<td>

下ろす<br />(おろす - orosu) [gv] <Play audio="orosu" />

</td>
<td>
to take down, to lower
</td>
<td>

下りる<br />(おりる - oriru) [iv] <Play audio="oriru" />

</td>
<td>
to descend, to go down, to come down
</td>
</tr>
<tr>
<td>

倒す<br />(たおす - taosu) [gv] <Play audio="taosu" />

</td>
<td>
to knock down
</td>
<td>

倒れる<br />(たおれる - taoreru) [iv] <Play audio="taoreru" />

</td>
<td>
to fall, to collapse
</td>
</tr>
<tr>
<td>

壊す<br />(こわす - kowasu) [gv] <Play audio="kowasu" />

</td>
<td>
to break something, to destroy, to demolish
</td>
<td>

壊れる<br />(こわれる - kowareru) [iv] <Play audio="kowareru" />

</td>
<td>
to be broken, to break
</td>
</tr>
<tr>
<td>

離す<br />(はなす - hanasu) [gv] <Play audio="hanasu" />

</td>
<td>
to separate, to divide something
</td>
<td>

離れる<br />(はなれる - hanareru) [iv] <Play audio="hanareru" />

</td>
<td>
to be separated from, to leave, to go away
</td>
</tr>
<tr>
<td>

放す<br />(はなす - hanasu) [gv] <Play audio="hanasu" />

</td>
<td>
to release, to let go, to set free
</td>
<td>

放れる<br />(はなれる - hanareru) [iv] <Play audio="hanareru" />

</td>
<td>
to get free
</td>
</tr>
<tr>
<td>

汚す<br />(よごす - yogosu) [gv] <Play audio="yogosu" />

</td>
<td>
to pollute, to contaminate, to make dirty
</td>
<td>

汚れる<br />(よごれる - yogoreru) [iv] <Play audio="yogoreru" />

</td>
<td>
to get dirty, to become dirty
</td>
</tr>
<tr>
<td>

通す<br />(とおす - toosu) [gv] <Play audio="toosu" />

</td>
<td>
to let pass, to make way for
</td>
<td>

通る<br />(とおる - tooru) [gv] <Play audio="tooru" />

</td>
<td>
to go by, to go past, to pass through
</td>
</tr>
<tr>
<td>

直す<br />(なおす - naosu) [gv] <Play audio="naosu" />

</td>
<td>
to fix, to correct
</td>
<td>

直る<br />(なおる - naoru) [gv] <Play audio="naoru" />

</td>
<td>
to be repaired, to be fixed
</td>
</tr>
<tr>
<td>

治す<br />(なおす - naosu) [gv] <Play audio="naosu" />

</td>
<td>
to cure, to heal
</td>
<td>

治る<br />(なおる - naoru) [gv] <Play audio="naoru" />

</td>
<td>
to be cured, to get well, to be healed
</td>
</tr>
<tr>
<td>

返す<br />(かえす - kaesu) [gv] <Play audio="kaesu" />

</td>
<td>
to return something, to restore, to put back
</td>
<td>

返る<br />(かえる - kaeru) [gv] <Play audio="kaeru" />

</td>
<td>
to return, to go back
</td>
</tr>
<tr>
<td>

帰す<br />(かえす - kaesu) [gv] <Play audio="kaesu" />

</td>
<td>
to send someone home
</td>
<td>

帰る<br />(かえる - kaeru) [gv] <Play audio="kaeru" />

</td>
<td>
to return home, to go home
</td>
</tr>
<tr>
<td>

回す<br />(まわす - mawasu) [gv] <Play audio="mawasu" />

</td>
<td>
to turn something, to rotate something
</td>
<td>

回る<br />(まわる - mawaru) [gv] <Play audio="mawaru" />

</td>
<td>
to turn, to revolve
</td>
</tr>
<tr>
<td>

立てる<br />(たてる - tateru) [iv] <Play audio="tateru" />

</td>
<td>
to stand something up
</td>
<td>

立つ<br />(たつ - tatsu) [gv] <Play audio="tatsu" />

</td>
<td>
to stand up
</td>
</tr>
<tr>
<td>

変える<br />(かえる - kaeru) [iv] <Play audio="kaeru" />

</td>
<td>
to change something, to alter something
</td>
<td>

変わる<br />(かわる - kawaru) [gv] <Play audio="kawaru" />

</td>
<td>
to change, to be transformed, to be altered
</td>
</tr>
<tr>
<td>

代える<br />(かえる - kaeru) [iv] <Play audio="kaeru" />

</td>
<td>
to substitute something
</td>
<td>

代わる<br />(かわる - kawaru) [gv] <Play audio="kawaru" />

</td>
<td>
to take the place of, to substitute for
</td>
</tr>
<tr>
<td>

開ける<br />(あける - akeru) [iv] <Play audio="akeru" />

</td>
<td>
to open (a door, package, etc.)
</td>
<td>

開く<br />(あく - aku) [gv] <Play audio="aku" />

</td>
<td>
to be open
</td>
</tr>
<tr>
<td>

閉める<br />(しめる - shimeru) [iv] <Play audio="shimeru" />

</td>
<td>
to close (a door, etc.)
</td>
<td>

閉まる<br />(しまる - shimaru) [gv] <Play audio="shimaru" />

</td>
<td>
to be shut, to be closed
</td>
</tr>
<tr>
<td>

上げる<br />(あげる - ageru) [iv] <Play audio="ageru" />

</td>
<td>
to raise something, to elevate
</td>
<td>

上がる<br />(あがる - agaru) [gv] <Play audio="agaru" />

</td>
<td>
to go up, to ascend, to be raised
</td>
</tr>
<tr>
<td>

下げる<br />(さげる - sageru) [iv] <Play audio="sageru" />

</td>
<td>
to lower something
</td>
<td>

下がる<br />(さがる - sagaru) [gv] <Play audio="sagaru" />

</td>
<td>
to be lowered
</td>
</tr>
<tr>
<td>

高める<br />(たかめる - takameru) [iv] <Play audio="takameru" />

</td>
<td>
to raise, to boost, to enhance
</td>
<td>

高まる<br />(たかまる - takamaru) [gv] <Play audio="takamaru" />

</td>
<td>
to rise, to swell, to be promoted
</td>
</tr>
<tr>
<td>

終える<br />(おえる - oeru) [iv] <Play audio="oeru" />

</td>
<td>
to finish something, to end something
</td>
<td>

終わる<br />(おわる - owaru) [gv] <Play audio="owaru" />

</td>
<td>
to finish, to come to an end
</td>
</tr>
<tr>
<td>

見つける<br />(みつける - mitsukeru) [iv] <Play audio="mitsukeru" />

</td>
<td>
to find something
</td>
<td>

見つかる<br />(みつかる - mitsukaru) [gv] <Play audio="mitsukaru" />

</td>
<td>
to be found, to be discovered
</td>
</tr>
<tr>
<td>

育てる<br />(そだてる - sodateru) [iv] <Play audio="sodateru" />

</td>
<td>
to raise (a child), to rear
</td>
<td>

育つ<br />(そだつ - sodatsu) [gv] <Play audio="sodatsu" />

</td>
<td>
to be raised (e.g. child)
</td>
</tr>
<tr>
<td>

乗せる<br />(のせる - noseru) [iv] <Play audio="noseru" />

</td>
<td>
to place on (something), to give (someone) a ride
</td>
<td>

乗る<br />(のる - noru) [gv] <Play audio="noru" />

</td>
<td>
to get on (train, plane, bus, ship, horse, etc.), to board
</td>
</tr>
<tr>
<td>

決める<br />(きめる - kimeru) [iv] <Play audio="kimeru" />

</td>
<td>
to decide, to choose
</td>
<td>

決まる<br />(きまる - kimaru) [gv] <Play audio="kimaru" />

</td>
<td>
to be decided, to be settled
</td>
</tr>
<tr>
<td>

届ける<br />(とどける - todokeru) [iv] <Play audio="todokeru" />

</td>
<td>
to deliver, to send
</td>
<td>

届く<br />(とどく - todoku) [gv] <Play audio="todoku" />

</td>
<td>
to arrive
</td>
</tr>
<tr>
<td>

助ける<br />(たすける - tasukeru) [iv] <Play audio="tasukeru" />

</td>
<td>
to help, to save, to rescue
</td>
<td>

助かる<br />(たすかる - tasukaru) [gv] <Play audio="tasukaru" />

</td>
<td>
to be helped, to be saved, to be rescued
</td>
</tr>
<tr>
<td>

集める<br />(あつめる - atsumeru) [iv] <Play audio="atsumeru" />

</td>
<td>
to collect something, to gather together, to assemble
</td>
<td>

集まる<br />(あつまる - atsumaru) [gv] <Play audio="atsumaru" />

</td>
<td>
to be gathered, to be collected, to be assembled
</td>
</tr>
<tr>
<td>

寄せる<br />(よせる - yoseru) [iv] <Play audio="yoseru" />

</td>
<td>
to come near, to let someone approach
</td>
<td>

寄る<br />(よる - yoru) [gv] <Play audio="yoru" />

</td>
<td>
to visit, to drop in, to approach
</td>
</tr>
<tr>
<td>

伝える<br />(つたえる - tsutaeru) [iv] <Play audio="tsutaeru" />

</td>
<td>
to convey, to communicate, to tell
</td>
<td>

伝わる<br />(つたわる - tsutawaru) [gv] <Play audio="tsutawaru" />

</td>
<td>
to be introduced, to be transmitted, to be circulated
</td>
</tr>
<tr>
<td>

始める<br />(はじめる - hajimeru) [iv] <Play audio="hajimeru" />

</td>
<td>
to start something, to begin something
</td>
<td>

始まる<br />(はじまる - hajimaru) [gv] <Play audio="hajimaru" />

</td>
<td>
to begin
</td>
</tr>
<tr>
<td>

入れる<br />(いれる - ireru) [iv] <Play audio="ireru" />

</td>
<td>
to put in, to insert
</td>
<td>

入る<br />(はいる - hairu) [gv] <Play audio="hairu" />

</td>
<td>
to enter
</td>
</tr>
<tr>
<td>

見る<br />(みる - miru) [iv] <Play audio="miru" />

</td>
<td>
to see, to look, to watch
</td>
<td>

見える<br />(みえる - mieru) [iv] <Play audio="mieru" />

</td>
<td>
to be seen, to be in sight
</td>
</tr>
<tr>
<td>

焼く<br />(やく - yaku) [gv] <Play audio="yaku" />

</td>
<td>
to bake something, to grill something, to burn something
</td>
<td>

焼ける<br />(やける - yakeru) [iv] <Play audio="yakeru" />

</td>
<td>
to be burned, to be roasted, to be heated
</td>
</tr>
<tr>
<td>

聞く<br />(きく - kiku) [gv] <Play audio="kiku" />

</td>
<td>
to hear, to ask
</td>
<td>

聞こえる<br />(きこえる - kikoeru) [iv] <Play audio="kikoeru" />

</td>
<td>
to be heard, to be audible
</td>
</tr>
<tr>
<td>

切る<br />(きる - kiru) [gv] <Play audio="kiru" />

</td>
<td>
to cut, to sever
</td>
<td>

切れる<br />(きれる - kireru) [iv] <Play audio="kireru" />

</td>
<td>
to be cut
</td>
</tr>
<tr>
<td>

売る<br />(うる - uru) [gv] <Play audio="uru" />

</td>
<td>
to sell something
</td>
<td>

売れる<br />(うれる - ureru) [iv] <Play audio="ureru" />

</td>
<td>
to sell (well)
</td>
</tr>
<tr>
<td>

折る<br />(おる - oru) [gv] <Play audio="oru" />

</td>
<td>
to break something, to fracture something
</td>
<td>

折れる<br />(おれる - oreru) [iv] <Play audio="oreru" />

</td>
<td>
to be broken, to fracture
</td>
</tr>
<tr>
<td>

取る<br />(とる - toru) [gv] <Play audio="toru" />

</td>
<td>
to take, to pick up
</td>
<td>

取れる<br />(とれる - toreru) [iv] <Play audio="toreru" />

</td>
<td>
to come off, to be removed
</td>
</tr>
<tr>
<td>

割る<br />(わる - waru) [gv] <Play audio="waru" />

</td>
<td>
to divide something, to break something, to split something
</td>
<td>

割れる<br />(われる - wareru) [iv] <Play audio="wareru" />

</td>
<td>
to break, to be smashed, to split, to crack
</td>
</tr>
<tr>
<td>

破る<br />(やぶる - yaburu) [gv] <Play audio="yaburu" />

</td>
<td>
to tear, to defeat, to destroy
</td>
<td>

破れる<br />(やぶれる - yabureru) [iv] <Play audio="yabureru" />

</td>
<td>
to get torn, to wear out
</td>
</tr>
<tr>
<td>

脱ぐ<br />(ぬぐ - nugu) [gv] <Play audio="nugu" />

</td>
<td>
to take off (clothes, shoes, etc.), to undress
</td>
<td>

脱げる<br />(ぬげる - nugeru) [iv] <Play audio="nugeru" />

</td>
<td>
to come off
</td>
</tr>
</tbody></table>

<p>Here are some example sentences (all in plain form):</p>

<Table data={transitiveone} />

<h2>Vocab Breakdown</h2>

<Table data={transitivetwo} />

<h2>Passive Form</h2>

<p>The passive form of a verb changes it from transitive to intransitive and also allows for an indirect object that performed the action. The direct object of the transitive sentence becomes the topic/subject (typically the subject and marked by "ga") and the topic/subject of the transitive sentence becomes the indirect object. It is the difference between "she ate the apple" and "the apple was eaten by her". The passive form for godan verbs is Base 1 + "reru" and the passive form for ichidan verbs is Base 1 + "rareu" (which is also the "can do" form for ichidan verbs). Here are some example sentences (all in plain form):</p>

<Table data={transitivethree} />

<h2>Vocab Breakdown</h2>

<Table data={transitivefour} />

    </>
  )
}

export default Transitive