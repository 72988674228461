import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Grade 3 Kanji - Part 5 of 5"

const GradeThreeFive = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Advanced" category="Kanji" lesson={title} />

      <h1>{title}</h1>

      <p>Below are Kanji the Japanese learn in grade 3 of elementary school.
Be sure to read the <Link to="/members/a/kanji/intro/">Introduction to Kanji</Link> if you haven't already.</p>

<p>Legend:</p>
<p>[n] = Noun</p>
<p>[dv] = Descriptive word (true adjective) - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[dn] = Descriptive noun - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[gv] = Godan verb - see <Link to="/members/b/grammar/godan-verbs/">Verb Conjugation (Godan)</Link></p>
<p>[iv] = Ichidan verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[irv] = Irregular verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[c] = Counter</p>

<table>
<thead>
  <tr>
<th>漢字<br /><Play audio="kanji" /></th>
<th>音読み <Play audio="onyomi" /><br />"On" Reading</th>
<th>訓読み <Play audio="kunyomi" /><br />"Kun" Reading</th>
<th>English Meaning</th>
</tr>
  </thead>
<tbody>
<tr>
<th><span style={{fontSize:`4rem`}}>葉</span></th>
<td>ヨウ (you)</td>
<td>は (ha)</td>
<td>
Leaf
<br />
<table><tbody><tr><td>Examples:
<br />
紅葉 (こうよう - kouyou - autumn colours, fall colors, leaves changing color, leaves turning red, red leaves) [n] <Play audio="kouyou" />
<br />
葉 (は - ha - leaf) [n] <Play audio="ha" />
<br />
葉っぱ (はっぱ - happa - leaf (more commonly used to avoid confusion with 歯 (ha - tooth)) [n] <Play audio="happa" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>薬</span></th>
<td>ヤク (yaku)</td>
<td>くすり (kusuri)</td>
<td>
Medicine
<br />
<table><tbody><tr><td>Examples:
<br />
薬剤 (やくざい - yakuzai - medicine, drug) [n] <Play audio="yakuzai" />
<br />
薬剤師 (やくざいし - yakuzaishi - chemist, pharmacist) [n] <Play audio="yakuzaishi" />
<br />
薬 (くすり - kusuri - medicine, pharmaceuticals, (legal) drugs) [n] <Play audio="kusuri" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>血</span></th>
<td>ケツ (ketsu)</td>
<td>ち (chi)</td>
<td>
Blood
<br />
<table><tbody><tr><td>Examples:
<br />
血圧 (けつあつ - ketsuatsu - blood pressure) [n] <Play audio="ketsuatsu" />
<br />
血 (ち - chi - blood) [n] <Play audio="chi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>表</span></th>
<td>ヒョウ (hyou)</td>
<td>おもて (omote)<br />あらわ・す (arawasu)<br />あらわ・れる (arawareru)</td>
<td>
List or Surface
<br />
<table><tbody><tr><td>Examples:
<br />
表紙 (ひょうし - hyoushi - front cover, binding) [n] <Play audio="hyoushi" />
<br />
表 (おもて - omote - surface, face (i.e. the visible side of an object), front (of a building, etc.), exterior, appearance) [n] <Play audio="omote" />
<br />
表す (あらわす - arawasu - to represent, to signify, to stand for) [gv] <Play audio="arawasu" />
<br />
表れる (あらわれる - arawareru - to appear, to come in sight, to become visible) [iv] <Play audio="arawareru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>詩</span></th>
<td>シ (shi)</td>
<td></td>
<td>
Poem
<br />
<table><tbody><tr><td>Examples:
<br />
詩 (し - shi - poem, verse of poetry) [n] <Play audio="shi" />
<br />
詩人 (しじん - shijin - poet) [n] <Play audio="shijin" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>調</span></th>
<td>チョウ (hotsu)</td>
<td>しら・べる (shiraberu)<br />しら・べ (shirabe)<br />ととの・う (totonou)<br />ととの・える (totonoeru)</td>
<td>
Investigate
<br />
<table><tbody><tr><td>Examples:
<br />
調査 (ちょうさ - chousa - investigation, examination, inquiry, survey) [n] <Play audio="chousa" />
<br />
調べる (しらべる - shiraberu - to examine, to investigate, to check up, to sense, to study, to inquire, to search) [iv] <Play audio="shiraberu" />
<br />
調べ (しらべ - shirabe - investigation, inspection, examination) [n] <Play audio="shirabe" />
<br />
調う (ととのう - totonou - to be prepared, to be in order, to be put in order, to be arranged) [gv] <Play audio="totonou" />
<br />
調える (ととのえる - totonoeru - to put in order, to arrange, to adjust, to get ready, to prepare) [iv] <Play audio="totonoeru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>談</span></th>
<td>ダン (dan)</td>
<td></td>
<td>
Discuss
<br />
<table><tbody><tr><td>Examples:
<br />
相談 (そうだん - soudan - consultation, discussion) [n] <Play audio="soudan" />
<br />
談話 (だんわ - danwa - a talk, conversation) [n] <Play audio="danwa" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>豆</span></th>
<td>ズ (zu)<br />トウ (tou)</td>
<td>まめ (mame)</td>
<td>
Beans
<br />
<table><tbody><tr><td>Examples:
<br />
大豆 (だいず - daizu - soybean) [n] <Play audio="daizu" />
<br />
豆腐 (とうふ - toufu - tofu, bean-curd) [n] <Play audio="toufu" />
<br />
豆 (まめ - mame - legume, beans, peas) [n] <Play audio="mame" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>負</span></th>
<td>フ (fu)</td>
<td>ま・ける (makeru)<br />ま・け (make)<br />ま・かす (makasu)<br />お・う (ou)</td>
<td>
Lose
<br />
<table><tbody><tr><td>Examples:
<br />
負担 (ふたん - futan - burden, charge, responsibility) [n] <Play audio="futan" />
<br />
負ける (まける - makeru - to lose, to be defeated) [iv] <Play audio="makeru" />
<br />
負け (まけ - make - defeat, loss, losing (a game)) [n] <Play audio="make" />
<br />
負かす (まかす - makasu - to defeat) [gv] <Play audio="makasu" />
<br />
負う (おう - ou - to bear, to take responsibility for, to accept a duty, to be injured) [gv] <Play audio="ou" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>起</span></th>
<td>キ (ki)</td>
<td>お・きる (okiru)<br />お・こる (okoru)<br />お・こす (okosu)</td>
<td>
Wake Up
<br />
<table><tbody><tr><td>Examples:
<br />
発起 (ほっき - hokki - proposal, resolution) [n] <Play audio="hokki" />
<br />
起きる (おきる - okiru - to get up, to rise, to wake up) [iv] <Play audio="okiru" />
<br />
起こる (おこる - okoru - to occur, to happen) [gv] <Play audio="okoru" />
<br />
起こす (おこす - okosu - to raise, to cause, to wake someone) [gv] <Play audio="okosu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>路</span></th>
<td>ロ (ro)</td>
<td>じ (ji)</td>
<td>
Road
<br />
<table><tbody><tr><td>Examples:
<br />
道路 (どうろ - douro - road, highway) [n] <Play audio="douro" />
<br />
路 (じ - ji - route, road) (suffix) <Play audio="ji" />
<br />
波路 (なみじ - namiji - sea route) [n] <Play audio="namiji" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>身</span></th>
<td>シン (shin)</td>
<td>み (mi)</td>
<td>
Body
<br />
<table><tbody><tr><td>Examples:
<br />
身長 (しんちょう - shinchou - height (of body), stature) [n] <Play audio="shinchou" />
<br />
自身 (じしん - jishin - oneself, by oneself, personally) [n] <Play audio="jishin" />
<br />
刺身 (さしみ - sashimi - sashimi (raw sliced fish, shellfish or crustaceans)) [n] <Play audio="sashimi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>転</span></th>
<td>テン (ten)</td>
<td>ころ・がる (korogaru)<br />ころ・げる (korogeru)<br />ころ・がす (korogasu)<br />ころ・ぶ (korobu)</td>
<td>
Revolve
<br />
<table><tbody><tr><td>Examples:
<br />
運転する (うんてんする - unten suru - to operate, to drive (a motored vehicle)) [n] <Play audio="unten suru" />
<br />
転がる (ころがる - korogaru - to roll, to tumble, to fall over, to roll over) [gv] <Play audio="korogaru" />
<br />
転げる (ころげる - korogeru - to roll over, to tumble, to roll about) [iv] <Play audio="korogeru" />
<br />
転がす (ころがす - korogasu - to roll, to turn over, to tip over, to throw down) [gv] <Play audio="korogasu" />
<br />
転ぶ (ころぶ - korobu - to fall down, to fall over) [gv] <Play audio="korobu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>軽</span></th>
<td>ケイ (kei)</td>
<td>かる・い (karui)<br />かろ・やか (karoyaka)<br />かろ・んずる (karonzuru)</td>
<td>
Light (i.e. not heavy)
<br />
<table><tbody><tr><td>Examples:
<br />
軽快 (けいかい - keikai - casual (e.g. dress), light, nimble, jaunty, rhythmical (e.g. melody)) [dn] <Play audio="keikai" />
<br />
軽い (かるい - karui - light (i.e. not heavy), feeling light (i.e. offering little resistance, moving easily), light (i.e. of foot), nimble, agile, non-serious, minor, unimportant, trivial) [dv] <Play audio="karui" />
<br />
軽やか (かろやか - karoyaka - light, easy, non-serious, minor) [dn] <Play audio="karoyaka" />
<br />
軽んずる (かろんずる - karonzuru - (see "karonjiru") to despise, to belittle, to look down on) [gv] <Play audio="karonzuru" />
<br />
軽んじる (かろんじる - karonjiru - to look down on, to make light of) [iv] <Play audio="karonjiru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>農</span></th>
<td>ノウ (nou)</td>
<td></td>
<td>
Farming
<br />
<table><tbody><tr><td>Examples:
<br />
農家 (のうか - nouka - farmer, farm family) [n] <Play audio="nouka" />
<br />
農場 (のうじょう - noujou - farm (agriculture)) [n] <Play audio="noujou" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>返</span></th>
<td>ヘン (hen)</td>
<td>かえ・す (kaesu)<br />かえ・る (kaeru)<br />かえ・し (kaeshi)</td>
<td>
Return
<br />
<table><tbody><tr><td>Examples:
<br />
返済 (へんさい - hensai - repayment, reimbursement, refund, redemption) [n] <Play audio="hensai" />
<br />
返す (かえす - kaesu - to return (something), to restore, to put back) [gv] <Play audio="kaesu" />
<br />
返る (かえる - kaeru - to return, to come back, to go back) [gv] <Play audio="kaeru" />
<br />
返し (かえし - kaeshi - reversal, return) [n] <Play audio="kaeshi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>追</span></th>
<td>ツイ (tsui)</td>
<td>お・う (ou)</td>
<td>
Follow
<br />
<table><tbody><tr><td>Examples:
<br />
追求 (ついきゅう - tsuikyuu - pursuing (goal), pursuit, seeking, search) [n] <Play audio="tsuikyuu" />
<br />
追う (おう - ou - to chase, to run after, to pursue) [gv] <Play audio="ou" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>送</span></th>
<td>ソウ (sou)</td>
<td>おく・る (okuru)<br />おく・り (okuri)</td>
<td>
Send
<br />
<table><tbody><tr><td>Examples:
<br />
配送 (はいそう - haisou - delivery) [n] <Play audio="haisou" />
<br />
送る (おくる - okuru - to send (a thing), to dispatch, to take or escort (a person somewhere)) [gv] <Play audio="okuru" />
<br />
送り (おくり - okuri - seeing off, sending off) [n] <Play audio="okuri" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>速</span></th>
<td>ソク (soku)</td>
<td>はや・い (hayai)<br />はや・める (hayameru)<br />すみ・やか (sumiyaka)</td>
<td>
Fast
<br />
<table><tbody><tr><td>Examples:
<br />
迅速 (じんそく - jinsoku - quick, fast, rapid, swift, prompt, streamlined, expedited, expeditious) [dn] <Play audio="jinsoku" />
<br />
速い (はやい - hayai - fast, quick, hasty, brisk) [dv] <Play audio="hayai" />
<br />
速める (はやめる - hayameru - to hasten, to quicken, to expedite, to accelerate) [iv] <Play audio="hayameru" />
<br />
速やか (すみやか - sumiyaka - speedy, prompt, smooth) [dn] <Play audio="sumiyaka" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>進</span></th>
<td>シン (shin)</td>
<td>すす・む (susumu)<br />すす・める (susumeru)</td>
<td>
Advance
<br />
<table><tbody><tr><td>Examples:
<br />
進路 (しんろ - shinro - route, course) [n] <Play audio="shinro" />
<br />
進む (すすむ - susumu - to advance, to go forward, to precede, to go ahead (of), to make progress, to improve) [gv] <Play audio="susumu" />
<br />
進める (すすめる - susumeru - to advance, to promote, to hasten) [iv] <Play audio="susumeru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>遊</span></th>
<td>ユ (yu)<br />ユウ (yuu)</td>
<td>あそ・ぶ (asobu)<br />あそ・ばす (asobasu)<br />あそ・び (asobi)</td>
<td>
Play
<br />
<table><tbody><tr><td>Examples:
<br />
遊山 (ゆさん - yusan - excursion, outing, picnic) [n] <Play audio="yusan" />
<br />
交遊 (こうゆう - kouyuu - friend, friendship, companionship, fraternization, fraternity, comradeship, acquaintance) [n] <Play audio="kouyuu" />
<br />
遊ぶ (あそぶ - asobu - to play, to enjoy oneself, to have a good time) [gv] <Play audio="asobu" />
<br />
遊ばす (あそばす - asobasu - to let one play, to leave idle) [gv] <Play audio="asobasu" />
<br />
遊び (あそび - asobi - playing) [n] <Play audio="asobi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>運</span></th>
<td>ウン (un)</td>
<td>はこ・ぶ (hakobu)</td>
<td>
Carry
<br />
<table><tbody><tr><td>Examples:
<br />
運命 (うんめい - unmei - fate, destiny) [n] <Play audio="unmei" />
<br />
運動 (うんどう - undou - motion, exercise) [n] <Play audio="undou" />
<br />
運ぶ (はこぶ - hakobu - to carry, to transport, to move, to convey) [gv] <Play audio="hakobu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>部</span></th>
<td>ブ (bu)<br />ヘ (he - rarely used outside of "heya")</td>
<td></td>
<td>
Part
<br />
<table><tbody><tr><td>Examples:
<br />
一部 (いちぶ - ichibu - one part, one portion, one section, some) [n] <Play audio="ichibu" />
<br />
部下 (ぶか - buka - subordinate person) [n] <Play audio="buka" />
<br />
部屋 (へや - heya - room) [n] <Play audio="heya" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>都</span></th>
<td>ツ (tsu)<br />ト (to)</td>
<td>みやこ (miyako)</td>
<td>
Metropolis
<br />
<table><tbody><tr><td>Examples:
<br />
都度 (つど - tsudo - each (every) time, whenever) [n] <Play audio="tsudo" />
<br />
都会 (とかい - tokai - city) [n] <Play audio="tokai" />
<br />
京都 (きょうと - kyouto - Kyoto city) [n] <Play audio="kyouto" />
<br />
都 (みやこ - miyako - capital, metropolis) [n] <Play audio="miyako" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>配</span></th>
<td>ハイ (hai)</td>
<td>くば・る (kubaru)</td>
<td>
Distribute
<br />
<table><tbody><tr><td>Examples:
<br />
配達 (はいたつ - haitatsu - delivery, distribution) [n] <Play audio="haitatsu" />
<br />
配慮 (はいりょ - hairyo - consideration, concern, forethought) [n] <Play audio="hairyo" />
<br />
配る (くばる - kubaru - to distribute, to deliver) [gv] <Play audio="kubaru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>酒</span></th>
<td>シュ (shu)</td>
<td>さけ (sake)<br />さか (saka)</td>
<td>
Rice Wine
<br />
<table><tbody><tr><td>Examples:
<br />
飲酒 (いんしゅ - inshu - drinking alcohol (sake)) [n] <Play audio="inshu" />
<br />
酒 (さけ - sake - alcohol, sake) [n] <Play audio="sake" />
<br />
酒樽 (さかだる - sakadaru - wine cask or barrel) [n] <Play audio="sakadaru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>重</span></th>
<td>ジュウ (juu)<br />チョウ (chou)</td>
<td>え (e)<br />おも・い (omoi)<br />かさ・ねる (kasaneru)<br />かさ・なる (kasanaru)</td>
<td>
Heavy
<br />
<table><tbody><tr><td>Examples:
<br />
体重 (たいじゅう - taijuu - body weight (usually one's own)) [n] <Play audio="taijuu" />
<br />
貴重 (きちょう - kichou - precious, valuable) [dn] <Play audio="kichou" />
<br />
二重 (ふたえ - futae - double, two-fold, two layers, duplex) [n] <Play audio="futae" />
<br />
重い (おもい - omoi - heavy, weighty, massive) [dv] <Play audio="omoi" />
<br />
重ねる (かさねる - kasaneru - to pile up, to put something on another, to heap up, to repeat) [iv] <Play audio="kasaneru" />
<br />
重なる (かさなる - kasanaru - to be piled up, to lie on top of one another, to overlap each other) [gv] <Play audio="kasanaru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>鉄</span></th>
<td>テツ (tetsu)</td>
<td></td>
<td>
Iron
<br />
<table><tbody><tr><td>Examples:
<br />
鉄 (てつ - tetsu - iron) [n] <Play audio="tetsu" />
<br />
鉄棒 (てつぼう - tetsubou - iron rod, crowbar, horizontal bar (gymnastics)) [n] <Play audio="tetsubou" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>銀</span></th>
<td>ギン (gin)</td>
<td></td>
<td>
Silver
<br />
<table><tbody><tr><td>Examples:
<br />
銀 (ぎん - gin - silver) [n] <Play audio="gin" />
<br />
水銀 (すいぎん - suigin - mercury) [n] <Play audio="suigin" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>開</span></th>
<td>カイ (kai)</td>
<td>ひら・く (hiraku)<br />ひら・ける (hirakeru)<br />あ・く (aku)<br />あ・ける (akeru)</td>
<td>
Open
<br />
<table><tbody><tr><td>Examples:
<br />
満開 (まんかい - mankai - full bloom) [n] <Play audio="mankai" />
<br />
開く (ひらく - hiraku - to open, to spread out, to open up) [gv] <Play audio="hiraku" />
<br />
開ける (ひらける - hirakeru - to become opened up, to improve, to get better, to develop, to progress) [iv] <Play audio="hirakeru" />
<br />
開く (あく - aku - to open (e.g. doors, business, etc.)) [gv] <Play audio="aku" />
<br />
開ける (あける - akeru - to open (a door, for business, etc.), to unwrap (e.g. parcel, package)) [iv] <Play audio="akeru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>院</span></th>
<td>イン (in)</td>
<td></td>
<td>
Institution
<br />
<table><tbody><tr><td>Examples:
<br />
病院 (びょういん - byouin - hospital) [n] <Play audio="byouin" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>陽</span></th>
<td>ヨウ (you)</td>
<td></td>
<td>
Sunshine
<br />
<table><tbody><tr><td>Examples:
<br />
太陽 (たいよう - taiyou - sun, solar) [n] <Play audio="taiyou" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>階</span></th>
<td>カイ (kai)</td>
<td></td>
<td>
Floor of a building
<br />
<table><tbody><tr><td>Examples:
<br />
階段 (かいだん - kaidan - stairs, stairway, staircase) [n] <Play audio="kaidan" />
<br />
一階 (いっかい - ikkai - one floor, first floor) [n] <Play audio="ikkai" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>集</span></th>
<td>シュウ (shuu)</td>
<td>あつ・まる (atsumaru)<br />あつ・める (atsumeru)<br />つど・う (tsudou)<br />つど・い (tsudoi)</td>
<td>
Collect
<br />
<table><tbody><tr><td>Examples:
<br />
集合 (しゅうごう - shuugou - gathering, assembly, meeting) [n] <Play audio="shuugou" />
<br />
集まる (あつまる - atsumaru - to gather, to collect, to assemble) [gv] <Play audio="atsumaru" />
<br />
集める (あつめる - atsumeru - to collect, to assemble, to gather) [iv] <Play audio="atsumeru" />
<br />
集う (つどう - tsudou - to meet, to assemble, to congregate) [gv] <Play audio="tsudou" />
<br />
集い (つどい - tsudoi - meeting, get-together) [n] <Play audio="tsudoi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>面</span></th>
<td>メン (men)</td>
<td>おも (omo)<br />おもて (omote)<br />つら (tsura)</td>
<td>
Face
<br />
<table><tbody><tr><td>Examples:
<br />
面接 (めんせつ - mensetsu - interview) [n] <Play audio="mensetsu" />
<br />
面 (おも - omo - face, surface) [n] <Play audio="omo" />
<br />
面影 (おもかげ - omokage - face, looks, vestiges, trace) [n] <Play audio="omokage" />
<br />
面 (おもて - omote - face, surface, mask) [n] <Play audio="omote" />
<br />
面 (つら - tsura - face (often derogatory), mug, surface, surrounding area, cheek(s)) [n] <Play audio="tsura" />
<br />
泣き面 (なきつら - nakitsura - tearful face) [n] <Play audio="nakitsura" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>題</span></th>
<td>ダイ (dai)</td>
<td></td>
<td>
Topic
<br />
<table><tbody><tr><td>Examples:
<br />
問題 (もんだい - mondai - problem, question) [n] <Play audio="mondai" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>飲</span></th>
<td>イン (in)</td>
<td>の・む (nomu)</td>
<td>
Drink
<br />
<table><tbody><tr><td>Examples:
<br />
飲食 (いんしょく - inshoku - food and drink, eating and drinking) [n] <Play audio="inshoku" />
<br />
飲む (のむ - nomu - to drink, to gulp, to swallow) [gv] <Play audio="nomu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>館</span></th>
<td>カン (kan)</td>
<td></td>
<td>
Public Building
<br />
<table><tbody><tr><td>Examples:
<br />
旅館 (りょかん - ryokan - Japanese hotel, inn) [n] <Play audio="ryokan" />
<br />
図書館 (としょかん - toshokan - library) [n] <Play audio="toshokan" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>駅</span></th>
<td>エキ (eki)</td>
<td></td>
<td>
Station
<br />
<table><tbody><tr><td>Examples:
<br />
駅 (えき - eki - station) [n] <Play audio="eki" />
<br />
終着駅 (しゅうちゃくえき - shuuchakueki - terminal station) [n] <Play audio="shuuchakueki" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>鼻</span></th>
<td>ビ (bi)</td>
<td>はな (hana)</td>
<td>
Nose
<br />
<table><tbody><tr><td>Examples:
<br />
耳鼻咽喉科 (じびいんこうか - jibiinkouka - otorhinolaryngology, ENT (ear, nose and throat)) [n] <Play audio="jibiinkouka" />
<br />
鼻 (はな - hana - nose) [n] <Play audio="hana" />
</td></tr></tbody></table></td>
</tr>
</tbody></table>

    </>
  )
}

export default GradeThreeFive