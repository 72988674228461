import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Business Vocab"

const businessone = [
  {"漢字 - Kanji":"職業","ひらがな - Hiragana":"しょくぎょう","Romaji (English Letters)":"shokugyou","audio":"shokugyou","English Meaning":"occupation, business"},
 {"漢字 - Kanji":"会社","ひらがな - Hiragana":"かいしゃ","Romaji (English Letters)":"kaisha","audio":"kaisha","English Meaning":"company, corporation, workplace"},
 {"漢字 - Kanji":"通勤する","ひらがな - Hiragana":"つうきんする","Romaji (English Letters)":"tsuukin suru","audio":"tsuukin suru","English Meaning":"commute to work [irv]"},
 {"漢字 - Kanji":"社長","ひらがな - Hiragana":"しゃちょう","Romaji (English Letters)":"shachou","audio":"shachou","English Meaning":"company president, manager, director"},
 {"漢字 - Kanji":"上司","ひらがな - Hiragana":"じょうし","Romaji (English Letters)":"joushi","audio":"joushi","English Meaning":"superior authorities, boss"},
 {"漢字 - Kanji":"部下","ひらがな - Hiragana":"ぶか","Romaji (English Letters)":"buka","audio":"buka","English Meaning":"subordinate person"},
 {"漢字 - Kanji":"係長","ひらがな - Hiragana":"かかりちょう","Romaji (English Letters)":"kakarichou","audio":"kakarichou","English Meaning":"chief clerk"},
 {"漢字 - Kanji":"部長","ひらがな - Hiragana":"ぶちょう","Romaji (English Letters)":"buchou","audio":"buchou","English Meaning":"head of a section or department"},
 {"漢字 - Kanji":"課長","ひらがな - Hiragana":"かちょう","Romaji (English Letters)":"kachou","audio":"kachou","English Meaning":"section manager, section chief"},
 {"漢字 - Kanji":"社員","ひらがな - Hiragana":"しゃいん","Romaji (English Letters)":"shain","audio":"shain","English Meaning":"company employee"},
 {"漢字 - Kanji":"給料","ひらがな - Hiragana":"きゅうりょう","Romaji (English Letters)":"kyuuryou","audio":"kyuuryou","English Meaning":"salary, wages"},
 {"漢字 - Kanji":"昇格","ひらがな - Hiragana":"しょうかく","Romaji (English Letters)":"shoukaku","audio":"shoukaku","English Meaning":"raising of status, promotion"},
 {"漢字 - Kanji":"昇給","ひらがな - Hiragana":"しょうきゅう","Romaji (English Letters)":"shoukyuu","audio":"shoukyuu","English Meaning":"salary raise"},
 {"漢字 - Kanji":"定年退職","ひらがな - Hiragana":"ていねんたいしょく","Romaji (English Letters)":"teinentaishoku","audio":"teinentaishoku","English Meaning":"retirement"},
 {"漢字 - Kanji":"有給休暇","ひらがな - Hiragana":"ゆうきゅうきゅうか","Romaji (English Letters)":"yuukyuukyuuka","audio":"yuukyuukyuuka","English Meaning":"paid vacation, leave"},
 {"漢字 - Kanji":"欠勤","ひらがな - Hiragana":"けっきん","Romaji (English Letters)":"kekkin","audio":"kekkin","English Meaning":"absence (from work)"},
 {"漢字 - Kanji":"残業","ひらがな - Hiragana":"ざんぎょう","Romaji (English Letters)":"zangyou","audio":"zangyou","English Meaning":"overtime"},
 {"漢字 - Kanji":"残業手当","ひらがな - Hiragana":"ざんぎょうてあて","Romaji (English Letters)":"zangyouteate","audio":"zangyouteate","English Meaning":"overtime pay"},
 {"漢字 - Kanji":"勤務時間","ひらがな - Hiragana":"きんむじかん","Romaji (English Letters)":"kinmujikan","audio":"kinmujikan","English Meaning":"office hours"},
 {"漢字 - Kanji":"営業時間","ひらがな - Hiragana":"えいぎょうじかん","Romaji (English Letters)":"eigyoujikan","audio":"eigyoujikan","English Meaning":"business hours"},
 {"漢字 - Kanji":"転勤する","ひらがな - Hiragana":"てんきんする","Romaji (English Letters)":"tenkin suru","audio":"tenkin suru","English Meaning":"to transfer, to relocate [irv]"},
 {"漢字 - Kanji":"秘書","ひらがな - Hiragana":"ひしょ","Romaji (English Letters)":"hisho","audio":"hisho","English Meaning":"secretary"},
 {"漢字 - Kanji":"解雇","ひらがな - Hiragana":"かいこ","Romaji (English Letters)":"kaiko","audio":"kaiko","English Meaning":"discharge, dismissal"},
 {"漢字 - Kanji":"解雇手当","ひらがな - Hiragana":"かいこてあて","Romaji (English Letters)":"kaikoteate","audio":"kaikoteate","English Meaning":"severance pay"},
 {"漢字 - Kanji":"首になる","ひらがな - Hiragana":"くびになる","Romaji (English Letters)":"kubi ni naru","audio":"kubi ni naru","English Meaning":"to be fired [gv]"},
 {"漢字 - Kanji":"首にする","ひらがな - Hiragana":"くびにする","Romaji (English Letters)":"kubi ni suru","audio":"kubi ni suru","English Meaning":"to fire someone [irv]"},
 {"漢字 - Kanji":"休職になる","ひらがな - Hiragana":"きゅうしょくになる","Romaji (English Letters)":"kyuushoku ni naru","audio":"kyuushoku ni naru","English Meaning":"to be suspended [gv]"},
 {"漢字 - Kanji":"出張","ひらがな - Hiragana":"しゅっちょう","Romaji (English Letters)":"shucchou","audio":"shucchou","English Meaning":"business trip"},
 {"漢字 - Kanji":"求職","ひらがな - Hiragana":"きゅうしょく","Romaji (English Letters)":"kyuushoku","audio":"kyuushoku","English Meaning":"job hunting, seeking employment"},
 {"漢字 - Kanji":"就職","ひらがな - Hiragana":"しゅうしょく","Romaji (English Letters)":"shuushoku","audio":"shuushoku","English Meaning":"finding employment"},
 {"漢字 - Kanji":"事務所","ひらがな - Hiragana":"じむしょ","Romaji (English Letters)":"jimusho","audio":"jimusho","English Meaning":"office"},
 {"漢字 - Kanji":"名刺","ひらがな - Hiragana":"めいし","Romaji (English Letters)":"meishi","audio":"meishi","English Meaning":"business card"},
 {"漢字 - Kanji":"会議","ひらがな - Hiragana":"かいぎ","Romaji (English Letters)":"kaigi","audio":"kaigi","English Meaning":"meeting, conference"},
 {"漢字 - Kanji":"書類","ひらがな - Hiragana":"しょるい","Romaji (English Letters)":"shorui","audio":"shorui","English Meaning":"documents, official papers"},
 {"漢字 - Kanji":"署名","ひらがな - Hiragana":"しょめい","Romaji (English Letters)":"shomei","audio":"shomei","English Meaning":"signature"},
 {"漢字 - Kanji":"顧客","ひらがな - Hiragana":"こきゃく","Romaji (English Letters)":"kokyaku","audio":"kokyaku","English Meaning":"customer, client, patron"},
 {"漢字 - Kanji":"依頼人","ひらがな - Hiragana":"いらいにん","Romaji (English Letters)":"irainin","audio":"irainin","English Meaning":"client"},
 {"漢字 - Kanji":"契約","ひらがな - Hiragana":"けいやく","Romaji (English Letters)":"keiyaku","audio":"keiyaku","English Meaning":"contract, agreement"},
 {"漢字 - Kanji":"予算","ひらがな - Hiragana":"よさん","Romaji (English Letters)":"yosan","audio":"yosan","English Meaning":"estimate, budget"},
 {"漢字 - Kanji":"警察官","ひらがな - Hiragana":"けいさつかん","Romaji (English Letters)":"keisatsukan","audio":"keisatsukan","English Meaning":"policemen, policeman, police officer"},
 {"漢字 - Kanji":"消防士","ひらがな - Hiragana":"しょうぼうし","Romaji (English Letters)":"shouboushi","audio":"shouboushi","English Meaning":"fireman, firefighter"},
 {"漢字 - Kanji":"歯科医","ひらがな - Hiragana":"しかい","Romaji (English Letters)":"shikai","audio":"shikai","English Meaning":"dentist"},
 {"漢字 - Kanji":"操縦士","ひらがな - Hiragana":"そうじゅうし","Romaji (English Letters)":"soujuushi","audio":"soujuushi","English Meaning":"pilot"},
 {"漢字 - Kanji":"警備員","ひらがな - Hiragana":"けいびいん","Romaji (English Letters)":"keibiin","audio":"keibiin","English Meaning":"security guard"},
 {"漢字 - Kanji":"弁護士","ひらがな - Hiragana":"べんごし","Romaji (English Letters)":"bengoshi","audio":"bengoshi","English Meaning":"lawyer, attorney"},
 {"漢字 - Kanji":"薬剤師","ひらがな - Hiragana":"やくざいし","Romaji (English Letters)":"yakuzaishi","audio":"yakuzaishi","English Meaning":"pharmacist"},
 {"漢字 - Kanji":"宇宙飛行士","ひらがな - Hiragana":"うちゅうひこうし","Romaji (English Letters)":"uchuuhikoushi","audio":"uchuuhikoushi","English Meaning":"astronaut"},
 {"漢字 - Kanji":"建築家","ひらがな - Hiragana":"けんちくか","Romaji (English Letters)":"kenchikuka","audio":"kenchikuka","English Meaning":"architect"},
 {"漢字 - Kanji":"会計士","ひらがな - Hiragana":"かいけいし","Romaji (English Letters)":"kaikeishi","audio":"kaikeishi","English Meaning":"accountant"},
 {"漢字 - Kanji":"生物学者","ひらがな - Hiragana":"せいぶつがくしゃ","Romaji (English Letters)":"seibutsugakusha","audio":"seibutsugakusha","English Meaning":"biologist"},
 {"漢字 - Kanji":"考古学者","ひらがな - Hiragana":"こうこがくしゃ","Romaji (English Letters)":"koukogakusha","audio":"koukogakusha","English Meaning":"archaeologist"},
 {"漢字 - Kanji":"脳外科医","ひらがな - Hiragana":"のうげかい","Romaji (English Letters)":"nougekai","audio":"nougekai","English Meaning":"brain surgeon"}
]
const businesstwo = [
  {"Kanji":"父は電車で通勤する(します)","Hiragana":"ちちはでんしゃでつうきんする(します)","Romaji":"Chichi wa densha de tsuukin suru (shimasu).","audio":"chichi wa densha de tsuukin suru (shimasu)","English Meaning":"My dad takes the train to work."},
 {"Kanji":"私の部下は病気で欠勤している(います)","Hiragana":"わたしのぶかはびょうきでけっきんしている(います)","Romaji":"Watashi no buka wa byouki de kekkin shite iru (imasu).","audio":"watashi no buka wa byouki de kekkin shite iru (imasu)","English Meaning":"My employee (subordinate) is home sick."},
 {"Kanji":"今日、残業しなくてはいけない(いけません)","Hiragana":"きょう、ざんぎょうしなくてはいけない(いけません)","Romaji":"Kyou, zangyou shinakute wa ikenai (ikemasen).","audio":"kyou zangyou shinakute wa ikenai (ikemasen)","English Meaning":"I have to work overtime today."},
 {"Kanji":"私の父は、来月退職する(します)","Hiragana":"わたしのちちは、らいげつたいしょくする(します)","Romaji":"Watashi no chichi wa, raigetsu taishoku suru (shimasu).","audio":"watashi no chichi wa, raigetsu taishoku suru (shimasu)","English Meaning":"My dad retires next month."},
 {"Kanji":"兄は，十日間の有給休暇がある(あります)","Hiragana":"あには，とおかかんのゆうきゅうきゅうかがある(あります)","Romaji":"Ani wa, tookakan no yuukyuukyuuka ga aru (arimasu).","audio":"ani wa tookakan no yuukyuukyuuka ga aru (arimasu)","English Meaning":"My (older) brother has 10 vacation days saved up."},
 {"Kanji":"母は、週末出張するので留守にする(します)","Hiragana":"ははは、しゅうまつしゅっちょうするのでるすにする(します)","Romaji":"Haha wa, shuumatsu shucchou suru node rusu ni suru (shimasu).","audio":"haha wa, shuumatsu shucchou suru node rusu ni suru (shimasu)","English Meaning":"My mother will be away from home on business this weekend.(My mother will be away from home this weekend because she will be on a business trip.)"}
]
const businessthree = [
  {"Kanji":"電車","Hiragana":"でんしゃ","Romaji":"densha","English Meaning":"(electric) train","audio":"densha"},
 {"Kanji":"病気","Hiragana":"びょうき","Romaji":"byouki","English Meaning":"illness, disease, sickness","audio":"byouki"},
 {"Kanji":"","Hiragana":"Base 1 + なくてはいけない","Romaji":"Base 1 + nakute wa ikenai","English Meaning":"must verb) ","audio":"Base 1 + nakute wa ikenai"},
 {"Kanji":"来月","Hiragana":"らいげつ","Romaji":"raigetsu","English Meaning":"next month","audio":"raigetsu"},
 {"Kanji":"退職する","Hiragana":"たいしょくする","Romaji":"taishoku suru","English Meaning":"to retire　[irv]","audio":"taishoku suru"},
 {"Kanji":"週末","Hiragana":"しゅうまつ","Romaji":"shuumatsu","English Meaning":"weekend","audio":"shuumatsu"},
 {"Kanji":"ので","Hiragana":"ので","Romaji":"node","English Meaning":"because","audio":"node"},
 {"Kanji":"留守","Hiragana":"るす","Romaji":"rusu","English Meaning":"being away from home","audio":"rusu"}
]

const BusinessVocab = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Beginner" category="Vocabulary" lesson="Business Vocab" />
      <h1>{title}</h1>
      <p>Below are a number of Japanese vocabulary words associated with business. All words are nouns unless otherwise specified. Please note that the example sentences will be done in plain form with the polite form in parentheses.</p>

<Table data={businessone} />

      <p>Here are some example sentences:</p>

      <table>
<tbody><tr>
<td><strong>Japanese</strong><br />(In Kanji, Hiragana, and Romaji)</td>
<td><strong>English Meaning</strong></td>
</tr>
<tr>
<td>

父は電車で通勤する(します)。
<br />
ちちはでんしゃでつうきんする(します)。
<br />
<Play audio="chichi wa densha de tsuukin suru" /> (<Play audio="chichi wa densha de tsuukin shimasu" />)

</td>
<td>
My dad takes the train to work.
</td>
</tr>
<tr>
<td>

私の部下は病気で欠勤している(います)。
<br />
わたしのぶかはびょうきでけっきんしている(います)。
<br />
<Play audio="watashi no buka wa byouki de kekkin shite iru" /> (<Play audio="watashi no buka wa byouki de kekkin shite imasu" />)

</td>
<td>
My employee (subordinate) is home sick.
</td>
</tr>
<tr>
<td>

今日、残業しなくてはいけない(いけません)。
<br />
きょう、ざんぎょうしなくてはいけない(いけません)。
<br />
<Play audio="kyou zangyou shinakute wa ikenai" /> (<Play audio="kyou zangyou shinakute wa ikemasen" />)

</td>
<td>
I have to work overtime today.
</td>
</tr>
<tr>
<td>

私の父は、来月退職する(します)。
<br />
わたしのちちは、らいげつたいしょくする(します)。
<br />
<Play audio="watashi no chichi wa raigetsu taishoku suru" /> (<Play audio="watashi no chichi wa raigetsu taishoku shimasu" />)

</td>
<td>
My dad retires next month.
</td>
</tr>
<tr>
<td>

兄は，十日間の有給休暇がある(あります)。
<br />
あには，とおかかんのゆうきゅうきゅうかがある(あります)。
<br />
<Play audio="ani wa tookakan no yuukyuukyuuka ga aru" /> (<Play audio="ani wa tookakan no yuukyuukyuuka ga arimasu" />)

</td>
<td>
My (older) brother has 10 vacation days saved up.
</td>
</tr>
<tr>
<td>

母は、週末出張するので留守にする(します)。
<br />
ははは、しゅうまつしゅっちょうするのでるすにする(します)。
<br />
<Play audio="haha wa shuumatsu shucchou suru node rusu ni suru" /> (<Play audio="haha wa shuumatsu shucchou suru node rusu ni shimasu" />)

</td>
<td>
My mother will be away from home on business this weekend.
<br />
(My mother will be away from home this weekend because she will be on a business trip.)
</td>
</tr>
</tbody></table>

<h2>Vocab Breakdown</h2>

<table>
<tbody>
<tr>
<td>

電車 (でんしゃ - densha - (electric) train) <Play audio="densha" />
<br />
病気 (びょうき - byouki - illness, disease, sickness) <Play audio="byouki" />
<br />
Base 1 + なくてはいけない (Base 1 + nakute wa ikenai (ikemasen for polite form) - must verb) <Play audio="nakute wa ikenai" /> <Play audio="nakute wa ikemasen" />
<br />
来月 (らいげつ - raigetsu - next month) <Play audio="raigetsu" />

</td>
<td>

退職する (たいしょくする - taishoku suru - to retire)　[irv] <Play audio="taishoku suru" />
<br />
週末 (しゅうまつ - shuumatsu - weekend) <Play audio="shuumatsu" />
<br />
ので (ので - node - because) <Play audio="node" />
<br />
留守 (るす - rusu - being away from home) <Play audio="rusu" />

</td>
</tr>
</tbody></table>

    </>
  )
}

export default BusinessVocab