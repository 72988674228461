import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Using the Verb Bases Part 2"

const basestwo1 = [
  {"ひらがな - Hiragana":"Base \"te\" + あげる","Romaji (English Letters)":"Base \"te\" + ageru","English Meaning":"you verb for someone else(\"ageru\" is an Ichidan verb)","audio":"ageru"},
 {"ひらがな - Hiragana":"Base \"te\" + くれる","Romaji (English Letters)":"Base \"te\" + kureru","English Meaning":"someone else verbs for you or another person(\"kureru\" is an Ichidan verb)","audio":"kureru"},
 {"ひらがな - Hiragana":"Base 1 + せる","Romaji (English Letters)":"Base 1 + seru","English Meaning":"force someone to verb (Godan verbs only)(verb now becomes an Ichidan verb)","audio":"seru"},
 {"ひらがな - Hiragana":"Base 1 + させる","Romaji (English Letters)":"Base 1 + saseru","English Meaning":"force someone to verb (Ichidan verbs only)(verb remains an Ichidan verb - \"suru\" becomes \"saseru\" and \"kuru\" becomes \"kosaseru\")","audio":"saseru"},
 {"ひらがな - Hiragana":"Base 1 + せてあげる","Romaji (English Letters)":"Base 1 + sete ageru","English Meaning":"allow someone else to verb (Godan verbs only)(follows the Base \"te\" + ageru rule above)","audio":"sete ageru"},
 {"ひらがな - Hiragana":"Base 1 + させてあげる","Romaji (English Letters)":"Base 1 + sasete ageru","English Meaning":"allow someone else to verb (Ichidan verbs only)(follows the Base \"te\" + ageru rule above)","audio":"sasete ageru"},
 {"ひらがな - Hiragana":"Base 1 + せてくれる","Romaji (English Letters)":"Base 1 + sete kureru","English Meaning":"someone else allows you to verb (Godan verbs only)(follows the Base \"te\" + kureru rule above)","audio":"sete kureru"},
 {"ひらがな - Hiragana":"Base 1 + させてくれる","Romaji (English Letters)":"Base 1 + sasete kureru","English Meaning":"someone else allows you to verb (Ichidan verbs only)(follows the Base \"te\" + kureru rule above)","audio":"sasete kureru"},
 {"ひらがな - Hiragana":"Base 1 + れる","Romaji (English Letters)":"Base 1 + reru","English Meaning":"to be verbed (Godan verbs only)(verb now becomes an Ichidan verb)","audio":"reru"},
 {"ひらがな - Hiragana":"Base 1 + られる","Romaji (English Letters)":"Base 1 + rareru","English Meaning":"to be verbed (Ichidan verbs only)(verb remains an Ichidan verb - \"suru\" becomes \"sareru\" and \"kuru\" becomes \"korareru\")","audio":"rareru"},
 {"ひらがな - Hiragana":"Base \"te\" + はならない","Romaji (English Letters)":"Base \"te\" + wa naranai","English Meaning":"must not verb(polite form is Base \"te\" + wa narimasen)","audio":"wa naranai"},
 {"ひらがな - Hiragana":"Base \"te\" + はいけない","Romaji (English Letters)":"Base \"te\" + wa ikenai","English Meaning":"must not verb (stronger)(polite form is Base \"te\" + wa ikemasen)","audio":"wa ikenai"},
 {"ひらがな - Hiragana":"Base 1 + なくてはならない","Romaji (English Letters)":"Base 1 + nakute wa naranai","English Meaning":"must verb(polite form is Base 1 + nakute wa narimasen)","audio":"nakute wa naranai"},
 {"ひらがな - Hiragana":"Base 1 + なくてはいけない","Romaji (English Letters)":"Base 1 + nakute wa ikenai","English Meaning":"must verb (stronger)(polite form is Base 1 + nakute wa ikemasen)","audio":"nakute wa ikenai"},
 {"ひらがな - Hiragana":"Base 1 + ないで","Romaji (English Letters)":"Base 1 + naide","English Meaning":"negative command form(add \"kudasai\" at the end for polite form)","audio":"naide"},
 {"ひらがな - Hiragana":"Base 2 + ながら","Romaji (English Letters)":"Base 2 + nagara","English Meaning":"while verbing","audio":"nagara"},
 {"ひらがな - Hiragana":"Base 2 + に行く","Romaji (English Letters)":"Base 2 + ni iku","English Meaning":"go to verb, go and verb","audio":"ni iku"},
 {"ひらがな - Hiragana":"Base 3 + と","Romaji (English Letters)":"Base 3 + to","English Meaning":"as soon as one verbs...","audio":"to"},
 {"ひらがな - Hiragana":"Base \"te\" + から","Romaji (English Letters)":"Base \"te\" + kara","English Meaning":"after verbing","audio":"kara"},
 {"ひらがな - Hiragana":"Base \"te\" + も","Romaji (English Letters)":"Base \"te\" + mo","English Meaning":"even though one verbs","audio":"mo"},
 {"ひらがな - Hiragana":"Base \"te\" + もいい","Romaji (English Letters)":"Base \"te\" + mo ii","English Meaning":"ok to verb","audio":"mo ii"},
 {"ひらがな - Hiragana":"Base 1 + なくても","Romaji (English Letters)":"Base 1 + nakute mo","English Meaning":"even though one doesn't verb(add \"ii\" at the end for \"ok to not verb\")","audio":"nakute mo"}
]
const basestwo2 = [
  {"漢字 - Kanji": "", "ひらがな - Hiragana": "", "Romaji (English Letters)": "", "English Meaning": "", "audio": ""}
]
const basestwo3 = [
  {"漢字 - Kanji": "", "ひらがな - Hiragana": "", "Romaji (English Letters)": "", "English Meaning": "", "audio": ""}
]

const BasesTwo = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Beginner" category="Grammar" lesson={title} />
     <div className={'title'}>
     {title}
      </div>
      <h1>{title}</h1>

      <p>Here are some additional ways that the verb bases can be used. You may want to regularly refer to <Link to="/members/b/grammar/godan-verbs/">Verb Conjugation (Godan)</Link> and <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link> while learning these. The following chart applies to all verbs unless otherwise noted. Be sure to check out the example sentences at the end of the lesson. Please note that the example sentences will all be done in plain form.</p>

      <Table data={basestwo1} />

<h2>Example sentences:</h2>

<table>
<thead>
<tr>
<th>Japanese(In Kanji, Hiragana, and Romaji)</th>
<th>English Meaning</th>
</tr>
</thead>
<tbody>
<tr>
<td>

学校まで送ってあげる。
<br />
がっこうまでおくってあげる。
<br />
<Play audio="gakkou made okutte ageru" />

</td>
<td>
I will take you to school.
</td>
</tr>
<tr>
<td>

お母さんは学校まで送ってくれた。
<br />
おかあさんはがっこうまでおくってくれた。
<br />
<Play audio="okaasan wa gakkou made okutte kureta" />

</td>
<td>
My mother brought me to school.
</td>
</tr>
<tr>
<td>

先生は五冊の本を読ませた。
<br />
せんせいはごさつのほんをよませた。
<br />
<Play audio="sensei wa gosatsu no hon o yomaseta" />

</td>
<td>
My teacher made me read 5 books.
</td>
</tr>
<tr>
<td>

お父さんは子供たちに林檎を食べさせた。
<br />
おとうさんはこどもたちにりんごをたべさせた。
<br />
<Play audio="otousan wa kodomotachi ni ringo o tabesaseta" />

</td>
<td>
My dad made us kids eat some apples.
</td>
</tr>
<tr>
<td>

友達にこの五冊の本を読ませてあげる。
<br />
ともだちにこのごさつのほんをよませてあげる。
<br />
<Play audio="tomodachi ni kono gosatsu no hon o yomasete ageru" />

</td>
<td>
I'm going to let my friend read these 5 books.
</td>
</tr>
<tr>
<td>

お父さんに林檎を食べさせてあげた。
<br />
おとうさんにりんごをたべさせてあげた。
<br />
<Play audio="otousan ni ringo o tabesasete ageta" />

</td>
<td>
I let my dad eat some apples.
</td>
</tr>
<tr>
<td>

先生は五冊の本を読ませてくれた。
<br />
せんせいはごさつのほんをよませてくれた。
<br />
<Play audio="sensei wa gosatsu no hon o yomasete kureta" />

</td>
<td>
My teacher let me read 5 books.
</td>
</tr>
<tr>
<td>

お父さんは子供たちに林檎を食べさせてくれた。
<br />
おとうさんはこどもたちにりんごをたべさせてくれた。
<br />
<Play audio="otousan wa kodomotachi ni ringo o tabesasete kureta" />

</td>
<td>
My dad let us kids eat some apples.
</td>
</tr>
<tr>
<td>

彼がその本を読んだ。
<br />
かれがそのほんをよんだ。
<br />
<Play audio="kare ga sono hon o yonda" />

</td>
<td>
He read that book.
</td>
</tr>
<tr>
<td>

その本が彼に読まれた。
<br />
そのほんがかれによまれた。
<br />
<Play audio="sono hon ga kare ni yomareta" />

</td>
<td>
That book was read by him.
</td>
</tr>
<tr>
<td>

彼女たちが林檎を食べた。
<br />
かのじょたちがりんごをたべた。
<br />
<Play audio="kanojotachi ga ringo o tabeta" />

</td>
<td>
They (only women) ate the apple(s).
</td>
</tr>
<tr>
<td>

林檎が彼女たちに食べられた。
<br />
りんごがかのじょたちにたべられた。
<br />
<Play audio="ringo ga kanojotachi ni taberareta" />

</td>
<td>
The apple(s) was/were eaten by them (only women).
</td>
</tr>
<tr>
<td>

図書館では話してはならない。
<br />
としょかんでははなしてはならない。
<br />
<Play audio="toshokan dewa hanashite wa naranai" />

</td>
<td>
You shouldn't talk in the library.
</td>
</tr>
<tr>
<td>

熱いオーブンを触ってはいけない。
<br />
あついオーブンをさわってはいけない。
<br />
<Play audio="atsui o-bun o sawatte wa ikenai" />

</td>
<td>
You must not touch the oven when it's hot.
</td>
</tr>
<tr>
<td>

テレビを見る前に宿題をしなくてはならない。
<br />
テレビをみるまえにしゅくだいをしなくてはならない。
<br />
<Play audio="terebi o miru mae ni shukudai o shinakute wa naranai" />

</td>
<td>
You need to do your homework before you watch TV.
</td>
</tr>
<tr>
<td>

信号が赤の時は車を止まらなくてはいけない。
<br />
しんごうがあかのときはくるまをとまらなくてはいけない。
<br />
<Play audio="shingou ga aka no toki wa kuruma o tomaranakute wa ikenai" />

</td>
<td>
You must stop your car when the traffic light is red.
</td>
</tr>
<tr>
<td>

馬鹿にしないで！
<br />
ばかにしないで！
<br />
<Play audio="baka ni shinaide" />

</td>
<td>
Stop making fun of me!
</td>
</tr>
<tr>
<td>

朝ご飯を食べながら宿題をしている。
<br />
あさごはんをたべながらしゅくだいをしている。
<br />
<Play audio="asagohan o tabenagara shukudai o shite iru" />

</td>
<td>
I'm doing my homework while eating breakfast.
</td>
</tr>
<tr>
<td>

今晩、映画を見に行くつもりだ。
<br />
こんばん、えいがをみにいくつもりだ。
<br />
<Play audio="konban eiga o mi ni iku tsumori da" />

</td>
<td>
I'm planning on going to see a movie tonight.
</td>
</tr>
<tr>
<td>

家に帰ると電話が鳴った。
<br />
いえにかえるとでんわがなった。
<br />
<Play audio="ie ni kaeru to denwa ga natta" />

</td>
<td>
The phone rang as soon as I got home.
</td>
</tr>
<tr>
<td>

買い物をしてから家に帰る。
<br />
かいものをしてからいえにかえる。
<br />
<Play audio="kaimono o shite kara ie ni kaeru" />

</td>
<td>
I'm going home after I go shopping.<br />(After I go shopping I'm going to return home.)
</td>
</tr>
<tr>
<td>

雪が降っても子供たちは外で遊びたい。
<br />
ゆきがふってもこどもたちはそとであそびたい。
<br />
<Play audio="yuki ga futte mo kodomotachi wa soto de asobitai" />

</td>
<td>
Even though it's snowing the children want to play outside.
</td>
</tr>
<tr>
<td>

映画を見に行ってもいいよ。
<br />
えいがをみにいってもいいよ。
<br />
<Play audio="eiga o mi ni itte mo ii yo" />

</td>
<td>
You can go see a movie if you'd like.
</td>
</tr>
<tr>
<td>

毎日、友達と遊ばなくても電話で話す。
<br />
まいにち、ともだちとあそばなくてもでんわではなす。
<br />
<Play audio="mainichi tomodachi to asobanakute mo denwa de hanasu" />

</td>
<td>
Even though I don't play with my friend every day, we talk on the phone.
</td>
</tr>
</tbody></table>

    </>
  )
}

export default BasesTwo