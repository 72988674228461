import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Grade 4 Kanji - Part 4 of 5"

const GradeFourFour = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Advanced" category="Kanji" lesson={title} />
     
      <h1>{title}</h1>

      <p>Below are Kanji the Japanese learn in grade 4 of elementary school.
Be sure to read the <Link to="/members/a/kanji/intro/">Introduction to Kanji</Link> if you haven't already.</p>

<p>Legend:</p>
<p>[n] = Noun</p>
<p>[dv] = Descriptive word (true adjective) - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[dn] = Descriptive noun - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[gv] = Godan verb - see <Link to="/members/b/grammar/godan-verbs/">Verb Conjugation (Godan)</Link></p>
<p>[iv] = Ichidan verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[irv] = Irregular verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[c] = Counter</p>

<table>
<thead>
  <tr>
<th>漢字<br /><Play audio="kanji" /></th>
<th>音読み <Play audio="onyomi" /><br />"On" Reading</th>
<th>訓読み <Play audio="kunyomi" /><br />"Kun" Reading</th>
<th>English Meaning</th>
</tr>
  </thead>
<tbody>
<tr>
<th><span style={{fontSize:`4rem`}}>泣</span></th>
<td>キュウ (kyuu)</td>
<td>な・く (naku)<br />な・き (naki)</td>
<td>
Cry
<br />
<table><tbody><tr><td>Examples:
<br />
号泣 (ごうきゅう - goukyuu - crying aloud, lamentation, wailing) [n] <Play audio="goukyuu" />
<br />
泣く (なく - naku - to cry, to weep, to sob, to howl) [gv] <Play audio="naku" />
<br />
泣き (なき - naki - weeping, lamenting) [n] <Play audio="naki" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>浅</span></th>
<td>セン (sen)</td>
<td>あさ・い (asai)</td>
<td>
Shallow
<br />
<table><tbody><tr><td>Examples:
<br />
浅見 (せんけん - senken - shallow view, superficial idea) [n] <Play audio="senken" />
<br />
浅い (あさい - asai - shallow, superficial) [dv] <Play audio="asai" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>浴</span></th>
<td>ヨク (yoku)</td>
<td>あ・びる (abiru)<br />あ・びせる (abiseru)</td>
<td>
Bathe
<br />
<table><tbody><tr><td>Examples:
<br />
浴槽 (よくそう - yokusou - bathtub) [n] <Play audio="yokusou" />
<br />
浴びる (あびる - abiru - to dash over oneself (e.g. water), to bathe, to bask in (e.g. the sun), to be flooded with (e.g. light), to shower) [iv] <Play audio="abiru" />
<br />
浴びせる (あびせる - abiseru - to pour on) [iv] <Play audio="abiseru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>清</span></th>
<td>ショウ (shou - rarely used)<br />セイ (sei)</td>
<td>きよ・い (kiyoi)<br />きよ・まる (kiyomaru)<br />きよ・める (kiyomeru)<br />きよ・らか (kiyoraka)</td>
<td>
Pure
<br />
<table><tbody><tr><td>Examples:
<br />
清掃 (せいそう - seisou - cleaning) [n] <Play audio="seisou" />
<br />
清い (きよい - kiyoi - clear, pure, noble) [dv] <Play audio="kiyoi" />
<br />
清まる (きよまる - kiyomaru - to be purified, to be cleansed) [gv] <Play audio="kiyomaru" />
<br />
清める (きよめる - kiyomeru - to purify, to cleanse, to exorcise) [iv] <Play audio="kiyomeru" />
<br />
清らか (きよらか - kiyoraka - clean, pure, chaste) [dn] <Play audio="kiyoraka" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>満</span></th>
<td>マン (man)</td>
<td>み・ちる (michiru)<br />み・たす (mitasu)</td>
<td>
Full
<br />
<table><tbody><tr><td>Examples:
<br />
満足 (まんぞく - manzoku - satisfaction) [dn] <Play audio="manzoku" />
<br />
満ちる (みちる - michiru - to be full, to rise (e.g. tide), to mature) [iv] <Play audio="michiru" />
<br />
満たす (みたす - mitasu - to satisfy, to fulfill, to appease, to fill (e.g. a cup), to pack, to reach (a certain number)) [gv] <Play audio="mitasu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>漁</span></th>
<td>ギョ (gyo)<br />リョウ (ryou)</td>
<td>あさ・る (asaru)</td>
<td>
Fishing
<br />
<table><tbody><tr><td>Examples:
<br />
漁船 (ぎょせん - gyosen - fishing boat) [n] <Play audio="gyosen" />
<br />
大漁 (たいりょう - tairyou - big catch) [n] <Play audio="tairyou" />
<br />
漁る (あさる - asaru - to fish, to look for, to search for, to hunt for, to scavenge, to scrounge) [gv] <Play audio="asaru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>灯</span></th>
<td>トウ (tou)</td>
<td>ひ (hi)</td>
<td>
Lamp
<br />
<table><tbody><tr><td>Examples:
<br />
灯台 (とうだい - toudai - lighthouse) [n] <Play audio="toudai" />
<br />
灯 (ひ - hi - light, lamp, torch) [n] <Play audio="hi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>無</span></th>
<td>ム (mu)<br />ブ (bu)</td>
<td>な・い (nai)<br />な・くす (nakusu)<br />な・くなる (naku naru)</td>
<td>
Nothing
<br />
<table><tbody><tr><td>Examples:
<br />
無縁 (むえん - muen - unrelated, irrelevant, indifferent) [dn] <Play audio="muen" />
<br />
無器用 (ぶきよう - bukiyou - awkward, clumsy, unskillfulness, lack of ability) [dn] <Play audio="bukiyou" />
<br />
無い (ない - nai - nonexistent, not being (there)) [dv] <Play audio="nai" />
<br />
無くす (なくす - nakusu - to lose something, to get rid of) [gv] <Play audio="nakusu" />
<br />
無くなる (なくなる - naku naru - to disappear, to get lost) [gv] <Play audio="naku naru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>然</span></th>
<td>ネン (nen)<br />ゼン (zen)</td>
<td>しか・し (shikashi)</td>
<td>
So
<br />
<table><tbody><tr><td>Examples:
<br />
天然 (てんねん - tennen - nature, spontaneity) [n] <Play audio="tennen" />
<br />
当然 (とうぜん - touzen - natural, as a matter of course) [dn] <Play audio="touzen" />
<br />
然し (しかし - shikashi - however, but) <Play audio="shikashi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>焼</span></th>
<td>ショウ (shou)</td>
<td>や・く (yaku)<br />や・ける (yakeru)</td>
<td>
Bake
<br />
<table><tbody><tr><td>Examples:
<br />
焼却 (しょうきゃく - shoukyaku - incineration, destroy by fire) [n] <Play audio="shoukyaku" />
<br />
焼く (やく - yaku - to bake, to grill, to burn, to scorch) [gv] <Play audio="yaku" />
<br />
焼ける (やける - yakeru - to burn, to be roasted, to be heated) [iv] <Play audio="yakeru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>照</span></th>
<td>ショウ (shou)</td>
<td>て・る (teru)<br />て・らす (terasu)<br />て・れる (tereru)</td>
<td>
Illuminate
<br />
<table><tbody><tr><td>Examples:
<br />
対照 (たいしょう - taishou - contrast, antithesis, comparison) [n] <Play audio="taishou" />
<br />
照る (てる - teru - to shine) [gv] <Play audio="teru" />
<br />
照らす (てらす - terasu - to shine on, to illuminate) [gv] <Play audio="terasu" />
<br />
照れる (てれる - tereru - to be shy, to be bashful, to feel awkward, to feel embarrassed) [iv] <Play audio="tereru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>熱</span></th>
<td>ネツ (netsu)</td>
<td>あつ・い (atsui)</td>
<td>
Heat
<br />
<table><tbody><tr><td>Examples:
<br />
熱 (ねつ - netsu - heat, fever, temperature, zeal, passion, enthusiasm) [n] <Play audio="netsu" />
<br />
熱心 (ねっしん - nesshin - zeal, enthusiasm) [dn] <Play audio="nesshin" />
<br />
熱い (あつい - atsui - hot (thing)) [dv] <Play audio="atsui" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>牧</span></th>
<td>ボク (boku)</td>
<td>まき (maki)</td>
<td>
Breed
<br />
<table><tbody><tr><td>Examples:
<br />
牧場 (ぼくじょう - bokujou - farm (livestock), ranch, pasture land, meadow, grazing land) [n] <Play audio="bokujou" />
<br />
牧 (まき - maki - pasture, grazing land) [n] <Play audio="maki" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>特</span></th>
<td>トク (toku)</td>
<td></td>
<td>
Special
<br />
<table><tbody><tr><td>Examples:
<br />
特別 (とくべつ - tokubetsu - special) [dn] <Play audio="tokubetsu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>産</span></th>
<td>サン (san)</td>
<td>う・む (umu)<br />う・まれる (umareru)<br />うぶ (ubu)</td>
<td>
Give Birth
<br />
<table><tbody><tr><td>Examples:
<br />
名産 (めいさん - meisan - noted product, local specialty) [n] <Play audio="meisan" />
<br />
不動産 (ふどうさん - fudousan - real estate) [n] <Play audio="fudousan" />
<br />
産む (うむ - umu - to give birth, to deliver, to produce) [gv] <Play audio="umu" />
<br />
産まれる (うまれる - umareru - to be born) [iv] <Play audio="umareru" />
<br />
産 (うぶ - ubu - birth-) <Play audio="ubu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>的</span></th>
<td>テキ (teki)</td>
<td>まと (mato)</td>
<td>
Target
<br />
<table><tbody><tr><td>Examples:
<br />
-的 (てき - teki - -like) [dn] <Play audio="teki" />
<br />
知的 (ちてき - chiteki - intellectual) [dn] <Play audio="chiteki" />
<br />
的 (まと - mato - mark, target) [n] <Play audio="mato" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>省</span></th>
<td>ショウ (shou)<br />セイ (sei)</td>
<td>かえり・みる (kaerimiru)<br />はぶ・く (habuku)</td>
<td>
Government Ministry
<br />
<table><tbody><tr><td>Examples:
<br />
省 (しょう - shou - (suffix) ministry, department) [n] <Play audio="shou" />
<br />
省略 (しょうりゃく - shouryaku - omission, abbreviation, abridgment, abridgement) [n] <Play audio="shouryaku" />
<br />
反省 (はんせい - hansei - reflection, reconsideration, introspection, meditation, contemplation) [n] <Play audio="hansei" />
<br />
省みる (かえりみる - kaerimiru - to look back (e.g. over shoulder or at the past), to turn around, to review, to reflect, to reconsider, to consider (usu. used in negative), to concern oneself about) [iv] <Play audio="kaerimiru" />
<br />
省く (はぶく - habuku - to omit, to leave out, to exclude, to eliminate, to curtail, to save, to cut down, to economize) [gv] <Play audio="habuku" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>祝</span></th>
<td>シュウ (shuu)<br />シュク (shuku)</td>
<td>いわ・う (iwau)<br />いわ・い (iwai)</td>
<td>
Celebrate
<br />
<table><tbody><tr><td>Examples:
<br />
祝儀 (しゅうぎ - shuugi - congratulations, celebration, congratulatory gift, tip) [n] <Play audio="shuugi" />
<br />
祝祭 (しゅくさい - shukusai - festivals, feasts) [n] <Play audio="shukusai" />
<br />
祝う (いわう - iwau - to congratulate, to celebrate, to observe (a festival)) [gv] <Play audio="iwau" />
<br />
祝い (いわい - iwai - congratulation, congratulations, celebration, festival, congratulatory gift) [n] <Play audio="iwai" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>票</span></th>
<td>ヒョウ (hyou)</td>
<td></td>
<td>
Ballot
<br />
<table><tbody><tr><td>Examples:
<br />
投票 (とうひょう - touhyou - voting, poll) [n] <Play audio="touhyou" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>種</span></th>
<td>シュ (shu)</td>
<td>たね (tane)</td>
<td>
Kind or Seed
<br />
<table><tbody><tr><td>Examples:
<br />
種類 (しゅるい - shurui - variety, kind, type, category; counter for different sorts of things) [n][c] <Play audio="shurui" />
<br />
種 (たね - tane - seed, kind, variety, theme) [n] <Play audio="tane" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>積</span></th>
<td>セキ (seki)</td>
<td>つ・む (tsumu)<br />つ・もる (tsumoru)</td>
<td>
Accumulate
<br />
<table><tbody><tr><td>Examples:
<br />
積載 (せきさい - sekisai - loading, carrying) [n] <Play audio="sekisai" />
<br />
積む (つむ - tsumu - to pile up, to stack, to load (car, ship, etc.), to pack) [gv] <Play audio="tsumu" />
<br />
積もる (つもる - tsumoru - to pile up, to accumulate) [gv] <Play audio="tsumoru" />
<br />
見積もる (みつもる - mitsumoru - to estimate) [gv] <Play audio="mitsumoru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>競</span></th>
<td>キョウ (kyou)<br />ケイ (kei)</td>
<td>きそ・う (kisou)<br />せ・る (seru)</td>
<td>
Emulate
<br />
<table><tbody><tr><td>Examples:
<br />
競争 (きょうそう - kyousou - competition, contest) [n] <Play audio="kyousou" />
<br />
競馬 (けいば - keiba - horse racing) [n] <Play audio="keiba" />
<br />
競う (きそう - kisou - to compete with, to emulate) [gv] <Play audio="kisou" />
<br />
競る (せる - seru - to compete, to bid, to sell at auction) [gv] <Play audio="seru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>笑</span></th>
<td>ショウ (shou)</td>
<td>わら・う (warau)<br />わら・い (warai)<br />え・む (emu)<br />え・み (emi)</td>
<td>
Laugh
<br />
<table><tbody><tr><td>Examples:
<br />
微笑 (びしょう - bishou - smile) [n] <Play audio="bishou" />
<br />
笑う (わらう - warau - to laugh, to smile) [gv] <Play audio="warau" />
<br />
笑い (わらい - warai - laugh, laughter, smile) [n] <Play audio="warai" />
<br />
笑む (えむ - emu - to smile) [gv] <Play audio="emu" />
<br />
笑み (えみ - emi - smile) [n] <Play audio="emi" />
<br />
笑顔 (えがお - egao - smiling face, smile) [n] <Play audio="egao" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>管</span></th>
<td>カン (kan)</td>
<td>くだ (kuda)</td>
<td>
Pipe
<br />
<table><tbody><tr><td>Examples:
<br />
血管 (けっかん - kekkan - blood vessel) [n] <Play audio="kekkan" />
<br />
管 (くだ - kuda - pipe, tube) [n] <Play audio="kuda" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>節</span></th>
<td>セチ (sechi)<br />セツ (setsu)</td>
<td>ふし (fushi)</td>
<td>
Node
<br />
<table><tbody><tr><td>Examples:
<br />
お節料理 (おせちりょうり - osechiryouri - food served during the New Year's Holidays) [n] <Play audio="osechiryouri" />
<br />
節 (せつ - setsu - occasion, time, section (of a literary work), paragraph, verse, stanza, passage) [n] <Play audio="setsu" />
<br />
関節 (かんせつ - kansetsu - joints (knee joint, elbow joint, etc.)) [n] <Play audio="kansetsu" />
<br />
節 (ふし - fushi - joint, knuckle) [n] <Play audio="fushi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>粉</span></th>
<td>フン (fun)</td>
<td>こ (ko)<br />こな (kona)</td>
<td>
Flour
<br />
<table><tbody><tr><td>Examples:
<br />
花粉 (かふん - kafun - pollen) [n] <Play audio="kafun" />
<br />
パン粉 (ぱんこ - panko - breadcrumbs, panko, bread flour) [n] <Play audio="panko" />
<br />
粉 (こな - kona - flour, meal, powder) [n] <Play audio="kona" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>紀</span></th>
<td>キ (ki)</td>
<td></td>
<td>
Chronicle
<br />
<table><tbody><tr><td>Examples:
<br />
世紀 (せいき - seiki - century, era) [n] <Play audio="seiki" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>約</span></th>
<td>ヤク (yaku)</td>
<td></td>
<td>
Promise
<br />
<table><tbody><tr><td>Examples:
<br />
約束 (やくそく - yakusoku - arrangement, promise, appointment, pact, engagement) [n] <Play audio="yakusoku" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>結</span></th>
<td>ケツ (ketsu)</td>
<td>むす・ぶ (musubu)<br />ゆ・う (yuu)<br />ゆ・わえる (yuwaeru)</td>
<td>
Tie
<br />
<table><tbody><tr><td>Examples:
<br />
結婚 (けっこん - kekkon - marriage) [n] <Play audio="kekkon" />
<br />
結ぶ (むすぶ - musubu - to tie, to bind, to link) [gv] <Play audio="musubu" />
<br />
結う (ゆう - yuu - to do up (hair), to braid, to fasten) [gv] <Play audio="yuu" />
<br />
結わえる (ゆわえる - yuwaeru - to bind, to fasten, to tie up) [iv] <Play audio="yuwaeru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>給</span></th>
<td>キュウ (kyuu)</td>
<td>たま・う (tamau)</td>
<td>
Salary
<br />
<table><tbody><tr><td>Examples:
<br />
給料 (きゅうりょう - kyuuryou - salary, wages) [n] <Play audio="kyuuryou" />
<br />
給う (たまう - tamau - to grant, to bestow, to award) [gv] <Play audio="tamau" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>続</span></th>
<td>ゾク (zoku)</td>
<td>つづ・く (tsuzuku)<br />つづ・き (tsuzuki)<br />つづ・ける (tsuzukeru)</td>
<td>
Continue
<br />
<table><tbody><tr><td>Examples:
<br />
接続 (せつぞく - setsuzoku - connection, attachment, union, join, joint, link) [n] <Play audio="setsuzoku" />
<br />
続く (つづく - tsuzuku - to continue, to last, to go on) [gv] <Play audio="tsuzuku" />
<br />
続き (つづき - tsuzuki - sequel, continuation) [n] <Play audio="tsuzuki" />
<br />
続ける (つづける - tsuzukeru - to continue, to keep up, to keep on) [iv] <Play audio="tsuzukeru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>置</span></th>
<td>チ (chi)</td>
<td>お・く (oku)</td>
<td>
Put
<br />
<table><tbody><tr><td>Examples:
<br />
配置 (はいち - haichi - arrangement (of resources), deployment, stationing, posting, disposition) [n] <Play audio="haichi" />
<br />
置く (おく - oku - to put, to place, to do something in advance (following te-form verb)) [gv] <Play audio="oku" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>老</span></th>
<td>ロウ (rou)</td>
<td>お・いる (oiru)<br />お・い (oi)<br />ふ・ける (fukeru)</td>
<td>
Old Man
<br />
<table><tbody><tr><td>Examples:
<br />
老朽 (ろうきゅう - roukyuu - superannuated, decrepitude) [n] <Play audio="roukyuu" />
<br />
老いる (おいる - oiru - to age, to grow old) [iv] <Play audio="oiru" />
<br />
老い (おい - oi - old age, old person, the old, the aged) [n] <Play audio="oi" />
<br />
老ける (ふける - fukeru - to age, to grow old) [iv] <Play audio="fukeru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>胃</span></th>
<td>イ (i)</td>
<td></td>
<td>
Stomach
<br />
<table><tbody><tr><td>Examples:
<br />
胃酸 (いさん - isan - stomach acid, gastric acid) [n] <Play audio="isan" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>脈</span></th>
<td>ミャク (myaku)</td>
<td></td>
<td>
Vein
<br />
<table><tbody><tr><td>Examples:
<br />
静脈 (じょうみゃく - joumyaku - vein) [n] <Play audio="joumyaku" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>腸</span></th>
<td>チョウ (chou)</td>
<td></td>
<td>
Intestines
<br />
<table><tbody><tr><td>Examples:
<br />
盲腸 (もうちょう - mouchou - appendix) [n] <Play audio="mouchou" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>臣</span></th>
<td>ジン (jin)<br />シン (shin)</td>
<td></td>
<td>
Retainer
<br />
<table><tbody><tr><td>Examples:
<br />
大臣 (だいじん - daijin - cabinet minister) [n] <Play audio="daijin" />
<br />
臣下 (しんか - shinka - retainer) [n] <Play audio="shinka" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>航</span></th>
<td>コウ (kou)</td>
<td></td>
<td>
Cruise
<br />
<table><tbody><tr><td>Examples:
<br />
回航 (かいこう - kaikou - navigation, cruise) [n] <Play audio="kaikou" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>良</span></th>
<td>リョウ (ryou)</td>
<td>よ・い (yoi)<br />よ・し (yoshi)</td>
<td>
Good
<br />
<table><tbody><tr><td>Examples:
<br />
良心 (りょうしん - ryoushin - conscience) [n] <Play audio="ryoushin" />
<br />
良い (よい - yoi - good, excellent, fine, nice, pleasant, agreeable) [dv] <Play audio="yoi" />
<br />
良し (よし - yoshi - alright, all right, right on, looking good, OK, okay (when said on its own, the final "i" sound is typically not pronounced)) <Play audio="yosh" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>芸</span></th>
<td>ゲイ (gei)</td>
<td></td>
<td>
Art
<br />
<table><tbody><tr><td>Examples:
<br />
芸術 (げいじゅつ - geijutsu - (fine) art, the arts) [n] <Play audio="geijutsu" />
</td></tr></tbody></table></td>
</tr>
</tbody></table>

    </>
  )
}

export default GradeFourFour