import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Describing and Comparing"

const describingone = [
  {"Kanji":"駅","Hiragana":"えき","Romaji":"eki","English Meaning":"train station","audio":"eki"},
 {"Kanji":"運動","Hiragana":"うんどう","Romaji":"undou","English Meaning":"motion, exercise","audio":"undou"},
 {"Kanji":"運動する","Hiragana":"うんどうする","Romaji":"undou suru","English Meaning":"to exercise","audio":"undou suru"},
 {"Kanji":"もっと","Hiragana":"もっと","Romaji":"motto","English Meaning":"more, even more, longer, further","audio":"motto"},
 {"Kanji":"速い","Hiragana":"はやい","Romaji":"hayai","English Meaning":"fast, quick, hasty, brisk","audio":"hayai"},
 {"Kanji":"走る","Hiragana":"はしる","Romaji":"hashiru","English Meaning":"to run (this is a godan that sounds like an ichidan)","audio":"hashiru"},
 {"Kanji":"買う","Hiragana":"かう","Romaji":"kau","English Meaning":"to buy, to purchase","audio":"kau"},
 {"Kanji":"氷","Hiragana":"こおり","Romaji":"koori","English Meaning":"ice","audio":"koori"},
 {"Kanji":"冷たい","Hiragana":"つめたい","Romaji":"tsumetai","English Meaning":"cold (to the touch), chilly, icy, freezing","audio":"tsumetai"},
 {"Kanji":"本当","Hiragana":"ほんとう","Romaji":"hontou","English Meaning":"truth, reality","audio":"hontou"},
 {"Kanji":"本当に","Hiragana":"ほんとうに","Romaji":"hontou ni","English Meaning":"really, truly","audio":"hontou ni"},
 {"Kanji":"寒い","Hiragana":"さむい","Romaji":"samui","English Meaning":"cold (weather)","audio":"samui"},
 {"Kanji":"親切","Hiragana":"しんせつ","Romaji":"shinsetsu","English Meaning":"kindness, gentleness","audio":"shinsetsu"},
 {"Kanji":"直ぐ","Hiragana":"すぐ","Romaji":"sugu","English Meaning":"soon, before long, shortly","audio":"sugu"},
 {"Kanji":"夏","Hiragana":"なつ","Romaji":"natsu","English Meaning":"summer","audio":"natsu"},
 {"Kanji":"暑い","Hiragana":"あつい","Romaji":"atsui","English Meaning":"hot (weather)","audio":"atsui"},
 {"Kanji":"風邪","Hiragana":"かぜ","Romaji":"kaze","English Meaning":"cold (illness), common cold","audio":"kaze"},
 {"Kanji":"風邪を引く","Hiragana":"かぜをひく","Romaji":"kaze o hiku","English Meaning":"to catch a cold","audio":"kaze o hiku"},
 {"Kanji":"気を付ける","Hiragana":"きをつける","Romaji":"ki o tsukeru","English Meaning":"to be careful, to pay attention, to take care","audio":"ki o tsukeru"},
 {"Kanji":"沢山","Hiragana":"たくさん","Romaji":"takusan","English Meaning":"many, a lot, much","audio":"takusan"},
 {"Kanji":"物","Hiragana":"もの","Romaji":"mono","English Meaning":"thing, object","audio":"mono"},
 {"Kanji":"作る","Hiragana":"つくる","Romaji":"tsukuru","English Meaning":"to make, to produce, to prepare (food)","audio":"tsukuru"},
 {"Kanji":"つもり","Hiragana":"つもり","Romaji":"tsumori","English Meaning":"intention, plan","audio":"tsumori"}
]
const describingtwo = [
  {"Kanji":"冬","Hiragana":"ふゆ","Romaji":"fuyu","English Meaning":"winter","audio":"fuyu"},
 {"Kanji":"高い","Hiragana":"たかい","Romaji":"takai","English Meaning":"high, tall, expensive","audio":"takai"},
 {"Kanji":"背が高い","Hiragana":"せがたかい","Romaji":"se ga takai","English Meaning":"tall (in stature)","audio":"se ga takai"},
 {"Kanji":"梨","Hiragana":"なし","Romaji":"nashi","English Meaning":"Japanese pear, Asian pear","audio":"nashi"},
 {"Kanji":"美味しい","Hiragana":"おいしい","Romaji":"oishii","English Meaning":"delicious, tasty","audio":"oishii"},
 {"Kanji":"思う","Hiragana":"おもう","Romaji":"omou","English Meaning":"to think","audio":"omou"}
]
const describingthree = [
  {"Kanji":"秋","Hiragana":"あき","Romaji":"aki","English Meaning":"autumn, fall","audio":"aki"},
 {"Kanji":"林檎","Hiragana":"りんご","Romaji":"ringo","English Meaning":"apple","audio":"ringo"},
 {"Kanji":"靴","Hiragana":"くつ","Romaji":"kutsu","English Meaning":"shoes, footwear","audio":"kutsu"},
 {"Kanji":"安い","Hiragana":"やすい","Romaji":"yasui","English Meaning":"cheap, inexpensive","audio":"yasui"},
 {"Kanji":"レストラン","Hiragana":"れすとらん","Romaji":"resutoran","English Meaning":"restaurant (katakana)","audio":"resutoran"},
 {"Kanji":"程","Hiragana":"ほど","Romaji":"hodo","English Meaning":"as much as","audio":"hodo"},
 {"Kanji":"人気","Hiragana":"にんき","Romaji":"ninki","English Meaning":"popularity","audio":"ninki"}
]

const DescribingComparing = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Beginner" category="Grammar" lesson={title} />

      <h1>{title}</h1>

      <p>This lesson focuses on how to describe and compare objects in Japanese. It will mainly focus on the words <Play audio="you" />, <Play audio="fuu" />, <Play audio="sou" />, <Play audio="hou" />, and <Play audio="yori" />. Descriptive words will be heavily used in this lesson so be sure to refer to the Descriptive Words - Part 1 lesson as needed. Please note that the example sentences will be done in plain form with the polite form in parentheses.</p>

<h2>You</h2>

<p><Play audio="you" /> (よう) is a Japanese word that is kind of difficult to explain because it is not a tangible idea. It is in the realm of "way", "seems", "like", "so that", etc. It also has a lot of varied uses. One common way that "you" is used is in one of the ways to say "how" in Japanese - "dono you ni"  ("dono" means "which" so <Play audio="dono you ni" /> literally means "by which way"). Change the "ni" to a "na" to make it "what kind of ____". Similar phrases (and possible answers) are <Play audio="kono you ni" />  ("like this" or "this way") and <Play audio="sono you ni" />  ("like that" or "that way"). It can also be used with nouns and adjectives to describe objects. The kanji is 様 but is typically written in hiragana so as not to be confused with the name suffix "sama" (same kanji). Here are some example sentences:</p>

<table>
<thead>
<tr>
<th>Japanese (In Kanji, Hiragana, and Romaji)</th>
<th>English Meaning</th>
</tr>
</thead>
<tbody>
<tr>
<td>

どのように駅に行くの(行きますか)。
<br />
どのようにえきにいくの(いきますか)。
<br />
<Play audio="dono you ni eki ni iku no" /> (<Play audio="dono you ni eki ni ikimasu ka" />)

</td>
<td>
How do I get to the train station?
</td>
</tr>
<tr>
<td>

このように運動したらもっと速く走ることができる(できます)。
<br />
このようにうんどうしたらもっとはやくはしることができる(できます)。
<br />
<Play audio="kono you ni undou shitara motto hayaku hashiru koto ga dekiru" /> (<Play audio="kono you ni undou shitara motto hayaku hashiru koto ga dekimasu" />)

</td>
<td>
If you exercise like this you will be able to run faster.
</td>
</tr>
<tr>
<td>

そのような犬を買いたい(買いたいです)。
<br />
そのようないぬをかいたい(かいたいです)。
<br />
<Play audio="sono you na inu o kaitai" /> (<Play audio="sono you na inu o kaitai desu" />)

</td>
<td>
I want to buy a dog like that.
<br />
I want to buy that kind of dog.
</td>
</tr>
<tr>
<td>

あなたの手は氷のように冷たい(冷たいです)。
<br />
あなたのてはこおりのようにつめたい(つめたいです)。
<br />
<Play audio="anata no te wa koori no you ni tsumetai" /> (<Play audio="anata no te wa koori no you ni tsumetai desu" />)

</td>
<td>
Your hands are cold like ice.
<br /><br />
[noun] no you ni = like [noun]
</td>
</tr>
<tr>
<td>

北海道は今、本当に寒いようだ(です)。
<br />
ほっかいどうはいま、ほんとうにさむいようだ(です)。
<br />
<Play audio="hokkaidou wa ima hontou ni samui you da" /> (<Play audio="hokkaidou wa ima hontou ni samui you desu" />)

</td>
<td>
Hokkaido seems really cold right now.
<br /><br />
[true adjective (dv)] you da/desu = seems [true adjective (dv)]
</td>
</tr>
<tr>
<td>

彼のお母さんは親切であるようだ(です)。
<br />
かれのおかあさんはしんせつであるようだ(です)。
<br />
<Play audio="kare no okaasan wa shinsetsu de aru you da" /> (<Play audio="kare no okaasan wa shinsetsu de aru you desu" />)

</td>
<td>
His mother seems nice.
<br />
(You could also say "Kare no okaasan wa shinsetsu na you da.")
<br /><br />
[descriptive noun (dn)] de aru (or "na") you da/desu = seems [descriptive noun (dn)]
</td>
</tr>
<tr>
<td>

直ぐ、雨が降るようだ(です)。
<br />
すぐ、あめがふるようだ(です)。
<br />
<Play audio="sugu ame ga furu you da" /> (<Play audio="sugu ame ga furu you desu" />)

</td>
<td>
It seems like it will rain soon.
<br /><br />
Base 3 + you da/desu = seems like will verb
</td>
</tr>
<tr>
<td>

夏になったように暑い(暑いです)。
<br />
なつになったようにあつい(あついです)。
<br />
<Play audio="natsu ni natta you ni atsui" /> (<Play audio="natsu ni natta you ni atsui desu" />)

</td>
<td>
It feels hot like summer.
<br />
(It's hot like it has become summer.)
<br /><br />
Base "ta" + you ni [descriptive word] = [descriptive word] like verbed
</td>
</tr>
<tr>
<td>

風邪を引かないように気を付けて(付けて下さい)。
<br />
かぜをひかないようにきをつけて(つけてください)。
<br />
<Play audio="kaze o hikanai you ni ki o tsukete" /> (<Play audio="kaze o hikanai you ni ki o tsukete kudasai" />)

</td>
<td>
Be careful not to catch a cold.
<br />
(Be careful so that you don't catch a cold.)
<br /><br />
Base 1 + nai you ni　... = ... so that won't verb
</td>
</tr>
<tr>
<td>

子供が沢山食べるように好きな物だけを作るつもりだ(です)。
<br />
こどもがたくさんたべるようにすきなものだけをつくるつもりだ(です)。
<br />
<Play audio="kodomo ga takusan taberu you ni suki na mono dake o tsukuru tsumori da" /> (<Play audio="kodomo ga takusan taberu you ni suki na mono dake o tsukuru tsumori desu" />)

</td>
<td>
I intend on only making things my child/children like so that they will eat a lot.
<br /><br />
Base 3 + you ni　... = ... so that will verb
</td>
</tr>
</tbody></table>

<h3>Vocab breakdown</h3>

<Table data={describingone} />

<h2>Fuu</h2>

<p><Play audio="fuu" /> (ふう) is very similar to "you"  but can't be used in as many ways. The kanji is 風 (kaze - wind) but it is typically written in hiragana. Another one of the ways to say "how" in Japanese, <Play audio="dou iu fuu ni" /> , is essentially the same as <Play audio="dono you ni" />  but not quite as polite. Here are some example sentences (we will use similar example sentences to those above to show the similarities between "you" and "fuu"):</p>

<table>
<thead>
<tr>
<th>Japanese (In Kanji, Hiragana, and Romaji)</th>
<th>English Meaning</th>
</tr>
</thead>
<tbody>
<tr>
<td>

どういうふうに駅に行くの(行きますか)。
<br />
どういうふうにえきにいくの(いきますか)。
<br />
<Play audio="dou iu fuu ni eki ni iku no" /> (<Play audio="dou iu fuu ni eki ni ikimasu ka" />)

</td>
<td>
How do I get to the train station?
</td>
</tr>
<tr>
<td>

こういうふうに運動したらもっと速く走ることができる(できます)。
<br />
こういうふうにうんどうしたらもっとはやくはしることができる(できます)。
<br />
<Play audio="kou iu fuu ni undou shitara motto hayaku hashiru koto ga dekiru" /> (<Play audio="kou iu fuu ni undou shitara motto hayaku hashiru koto ga dekimasu" />)

</td>
<td>
If you exercise like this you will be able to run faster.
</td>
</tr>
<tr>
<td>

そういうふうな犬を買いたい(買いたいです)。
<br />
そういうふうないぬをかいたい(かいたいです)。
<br />
<Play audio="sou iu fuu na inu o kaitai" /> (<Play audio="sou iu fuu na inu o kaitai desu" />)

</td>
<td>
I want to buy a dog like that.
<br />
I want to buy that kind of dog.
</td>
</tr>
</tbody></table>

<h2>Sou</h2>

<p><Play audio="sou" /> (そう) is also very similar to <Play audio="you" />  but in different ways than "fuu" is. While "you" can express how something seems, "sou" can express hearsay (something you heard or read - like saying "apparently..."). It can also be used to describe how something looks. Here are some example sentences (we will use similar example sentences to those above to show the similarities between "you" and "sou"):</p>

<table>
<thead>
<tr>
<th>Japanese (In Kanji, Hiragana, and Romaji)</th>
<th>English Meaning</th>
</tr>
</thead>
<tbody>
<tr>
<td>

北海道は今、本当に寒いそうだ(です)。
<br />
ほっかいどうはいま、ほんとうにさむいそうだ(です)。
<br />
<Play audio="hokkaidou wa ima hontou ni samui sou da" /> (<Play audio="hokkaidou wa ima hontou ni samui sou desu" />)

</td>
<td>
I heard (or read) that Hokkaido is really cold right now.
<br /><br />
... sou da/desu = heard or read ...
</td>
</tr>
<tr>
<td>

北海道は今、本当に寒そうだ(です)。
<br />
ほっかいどうはいま、ほんとうにさむそうだ(です)。
<br />
<Play audio="hokkaidou wa ima hontou ni samusou da" /> (<Play audio="hokkaidou wa ima hontou ni samusou desu" />)

</td>
<td>
Hokkaido looks really cold right now.
<br /><br />
[true adjective (dv)] drop the い ("i") and add そう ("sou") = looks [true adjective (dv)]
<br />
(The negative of this is drop the "i" and add "kunasasou")
</td>
</tr>
<tr>
<td>

彼のお母さんは親切であるそうだ(です)。
<br />
かれのおかあさんはしんせつであるそうだ(です)。
<br />
<Play audio="kare no okaasan wa shinsetsu de aru sou da" /> (<Play audio="kare no okaasan wa shinsetsu de aru sou desu" />)

</td>
<td>
I heard his mother is nice.
<br />
(You could also say "Kare no okaasan wa shinsetsu na sou da.")
</td>
</tr>
<tr>
<td>

彼のお母さんは親切そうだ(です)。
<br />
かれのおかあさんはしんせつそうだ(です)。
<br />
<Play audio="kare no okaasan wa shinsetsusou da" /> (<Play audio="kare no okaasan wa shinsetsusou desu" />)

</td>
<td>
His mother looks nice.
<br />
(His mother looks like a nice person.)
<br /><br />
[descriptive noun (dn)] + sou = looks [descriptive noun (dn)]
</td>
</tr>
<tr>
<td>

直ぐ、雨が降るそうだ(です)。
<br />
すぐ、あめがふるそうだ(です)。
<br />
<Play audio="sugu ame ga furu sou da" /> (<Play audio="sugu ame ga furu sou desu" />)

</td>
<td>
I heard (or read) it will rain soon.
</td>
</tr>
<tr>
<td>

雨が降りそうだ(です)。
<br />
あめがふりそうだ(です)。
<br />
<Play audio="ame ga furisou da" /> (<Play audio="ame ga furisou desu" />)

</td>
<td>
It looks like it's going to rain.
<br /><br />
Base 2 + sou = looks like will verb
</td>
</tr>
</tbody></table>

<h2>Hou</h2>

<p><Play audio="hou" /> is typically a comparison word. It literally means "side" (like you're taking one side over another). The kanji is 方 which can be a little confusing because, by itself, it can either be read "hou" (side, direction, way) or "kata" (person, lady, gentleman) depending on the context. Here are some example sentences:</p>

<table>
<thead>
<tr>
<th>Japanese (In Kanji, Hiragana, and Romaji)</th>
<th>English Meaning</th>
</tr>
</thead>
<tbody>
<tr>
<td>

冬の方が好きだ(です)。
<br />
ふゆのほうがすきだ(です)。
<br />
<Play audio="fuyu no hou ga suki da" /> (<Play audio="fuyu no hou ga suki desu" />)

</td>
<td>
I like winter more.
</td>
</tr>
<tr>
<td>

彼の方が背が高い(高いです)。
<br />
かれのほうがせがたかい(たかいです)。
<br />
<Play audio="kare no hou ga se ga takai" /> (<Play audio="kare no hou ga se ga takai desu" />)

</td>
<td>
He is taller.
</td>
</tr>
<tr>
<td>

梨の方が美味しいと思う(思います)。
<br />
なしのほうがおいしいとおもう(おもいます)。
<br />
<Play audio="nashi no hou ga oishii to omou" /> (<Play audio="nashi no hou ga oishii to omoimasu" />)

</td>
<td>
I think nashi (Asian pears) are tastier.
</td>
</tr>
</tbody></table>

<h3>Vocab breakdown</h3>

<Table data={describingtwo} />

<h2>Yori</h2>

<p><Play audio="yori" /> is often used in conjunction with <Play audio="hou" />. It means "more than" (or "less than" depending on the context). When doing a negative comparison, one would use "hodo" (as much as) instead. Here are the same example sentences as above with "yori" incorporated as well (plus some additional sentences):</p>

<table>
<thead>
<tr>
<th>Japanese (In Kanji, Hiragana, and Romaji)</th>
<th>English Meaning</th>
</tr>
</thead>
<tbody>
<tr>
<td>

秋より冬の方が好きだ(です)。
<br />
あきよりふゆのほうがすきだ(です)。
<br />
<Play audio="aki yori fuyu no hou ga suki da" /> (<Play audio="aki yori fuyu no hou ga suki desu" />)

</td>
<td>
I like winter more than autumn/fall.
</td>
</tr>
<tr>
<td>

彼女より彼の方が背が高い(高いです)。
<br />
かのじょよりかれのほうがせがたかい(たかいです)。
<br />
<Play audio="kanojo yori kare no hou ga se ga takai" /> (<Play audio="kanojo yori kare no hou ga se ga takai desu" />)

</td>
<td>
He is taller than her.
</td>
</tr>
<tr>
<td>

林檎より梨の方が美味しいと思う(思います)。
<br />
りんごよりなしのほうがおいしいとおもう(おもいます)。
<br />
<Play audio="ringo yori nashi no hou ga oishii to omou" /> (<Play audio="ringo yori nashi no hou ga oishii to omoimasu" />)

</td>
<td>
I think nashi (Asian pears) are tastier than apples.
</td>
</tr>
<tr>
<td>

この靴はその靴より安い(安いです)。
<br />
このくつはそのくつよりやすい(やすいです)。
<br />
<Play audio="kono kutsu wa sono kutsu yori yasui" /> (<Play audio="kono kutsu wa sono kutsu yori yasui desu" />)

</td>
<td>
These shoes are cheaper than those shoes.
</td>
</tr>
<tr>
<td>

今晩食べるレストランは先週食べたレストラン程人気がない(ありません)。
<br />
こんばんたべるレストランはせんしゅうたべたレストランほどにんきがない(ありません)。
<br />
<Play audio="konban taberu resutoran wa senshuu tabeta resutoran hodo ninki ga nai" /> (<Play audio="konban taberu resutoran wa senshuu tabeta resutoran hodo ninki ga arimasen" />)

</td>
<td>
The restaurant we're eating at tonight is not as popular as the restaurant we ate at last week.
</td>
</tr>
</tbody></table>

<h3>Vocab breakdown</h3>

<Table data={describingthree} />

    </>
  )
}

export default DescribingComparing