import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Grade 2 Kanji  - Part 3 of 4"

const GradeTwoThree = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Advanced" category="Kanji" lesson={title} />

      <h1>{title}</h1>

      <p>Below are Kanji the Japanese learn in grade 2 of elementary school.
Be sure to read the <Link to="/members/a/kanji/intro/">Introduction to Kanji</Link> if you haven't already.</p>

<p><strong>Legend:</strong></p>
<p>[n] = Noun</p>
<p>[dv] = Descriptive word (true adjective) - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[dn] = Descriptive noun - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[gv] = Godan verb - see <Link to="/members/b/grammar/godan-verbs/">Verb Conjugation (Godan)</Link></p>
<p>[iv] = Ichidan verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[irv] = Irregular verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[c] = Counter</p>

<table>
<thead>
  <tr>
<th>漢字<br /><Play audio="kanji" /></th>
<th>音読み <Play audio="onyomi" /><br />"On" Reading</th>
<th>訓読み <Play audio="kunyomi" /><br />"Kun" Reading</th>
<th>English Meaning</th>
</tr>
  </thead>
<tbody>
<tr>
<th><span style={{fontSize:`4rem`}}>星</span></th>
<td>ショウ (shou - rarely used)<br />セイ (sei)</td>
<td>ほし (hoshi)</td>
<td>
Star
<br />
<table><tbody><tr><td>Examples:
<br />
惑星 (わくせい - wakusei - planet) [n] <Play audio="wakusei" />
<br />
星空 (ほしぞら - hoshizora - starry sky) [n] <Play audio="hoshizora" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>室</span></th>
<td>シツ (shitsu)</td>
<td></td>
<td>
Room
<br />
<table><tbody><tr><td>Examples:
<br />
教室 (きょうしつ - kyoushitsu - classroom) [n] <Play audio="kyoushitsu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>戸</span></th>
<td>コ (ko)</td>
<td>と (to)</td>
<td>
Door
<br />
<table><tbody><tr><td>Examples:
<br />
戸籍 (こせき - koseki - census, family register) [n] <Play audio="koseki" />
<br />
瀬戸 (せと - seto - strait, channel) [n] <Play audio="seto" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>家</span></th>
<td>ケ (ke)<br />カ (ka)</td>
<td>いえ (ie)<br />や (ya)</td>
<td>
House
<br />
<table><tbody><tr><td>Examples:
<br />
両家 (りょうけ - ryouke - both families) [n] <Play audio="ryouke" />
<br />
家畜 (かちく - kachiku - domestic animals, livestock, cattle) [n] <Play audio="kachiku" />
<br />
家 (いえ - ie - house, residence, dwelling) [n] <Play audio="ie" />
<br />
家主 (やぬし - yanushi - landlord) [n] <Play audio="yanushi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>寺</span></th>
<td>ジ (ji)</td>
<td>てら (tera)</td>
<td>
Buddhist Temple
<br />
<table><tbody><tr><td>Examples:
<br />
寺院 (じいん - jiin - temple) [n] <Play audio="jiin" />
<br />
寺 (てら - tera - temple (Buddhist)) [n] <Play audio="tera" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>通</span></th>
<td>ツウ (tsuu)</td>
<td>とお・る (tooru)<br />とお・す (toosu)<br />かよ・う (kayou)</td>
<td>
Pass Through
<br />
<table><tbody><tr><td>Examples:
<br />
通勤 (つうきん - tsuukin - work commute) [n] <Play audio="tsuukin" />
<br />
通る (とおる - tooru - to pass (by), to go through) [gv] <Play audio="tooru" />
<br />
通す (とおす - toosu - to let pass, to overlook, to continue, to make way for, to persist in) [gv] <Play audio="toosu" />
<br />
通う (かよう - kayou - to go back and forth, to commute) [gv] <Play audio="kayou" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>門</span></th>
<td>モン (mon)</td>
<td>かど (kado)</td>
<td>
Gates
<br />
<table><tbody><tr><td>Examples:
<br />
専門 (せんもん - senmon - speciality, specialty, subject of study) [n] <Play audio="senmon" />
<br />
御門 (みかど - mikado - (the gates of an) imperial residence) [n] <Play audio="mikado" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>道</span></th>
<td>ドウ (dou)</td>
<td>みち (michi)</td>
<td>
Road
<br />
<table><tbody><tr><td>Examples:
<br />
歩道 (ほどう - hodou - foot path) [n] <Play audio="hodou" />
<br />
舗道 (ほどう - hodou - pavement, paved street) [n] <Play audio="hodou" />
<br />
道 (みち - michi - road, street, way, path, course) [n] <Play audio="michi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>話</span></th>
<td>ワ (wa)</td>
<td>はな・す (hanasu)<br />はなし (hanashi)</td>
<td>
Talk
<br />
<table><tbody><tr><td>Examples:
<br />
手話 (しゅわ - shuwa - sign language) [n] <Play audio="shuwa" />
<br />
話す (はなす - hanasu - to speak) [gv] <Play audio="hanasu" />
<br />
話 (はなし - hanashi - talk, speech, chat, story, conversation) [n] <Play audio="hanashi" />
<br />
長話 (ながばなし - nagabanashi - long talk) [n] <Play audio="nagabanashi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>言</span></th>
<td>ゴン (gon)<br />ゲン (gen)</td>
<td>い・う (iu)<br />こと (koto)</td>
<td>
Say
<br />
<table><tbody><tr><td>Examples:
<br />
遺言 (ゆいごん - yuigon - will, testament, last request) [n] <Play audio="yuigon" />
<br />
助言 (じょげん - jogen - advice, suggestion) [n] <Play audio="jogen" />
<br />
言う (いう - iu - to say) [gv] <Play audio="iu" />
<br />
言葉 (ことば - kotoba - language, dialect, word, words, phrase, term, expression, remark) [n] <Play audio="kotoba" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>答</span></th>
<td>トウ (tou)</td>
<td>こた・える (kotaeru)</td>
<td>
Answer
<br />
<table><tbody><tr><td>Examples:
<br />
応答 (おうとう - outou - reply, answer, response) [n] <Play audio="outou" />
<br />
答える (こたえる - kotaeru - to answer, to reply) [iv] <Play audio="kotaeru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>声</span></th>
<td>セイ (sei)</td>
<td>こえ (koe)</td>
<td>
Voice
<br />
<table><tbody><tr><td>Examples:
<br />
声援 (せいえん - seien - encouragement, cheering, support) [n] <Play audio="seien" />
<br />
声 (こえ - koe - voice) [n] <Play audio="koe" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>聞</span></th>
<td>モン (mon)<br />ブン (bun)</td>
<td>き・く (kiku)</td>
<td>
Hear
<br />
<table><tbody><tr><td>Examples:
<br />
前代未聞 (ぜんだいみもん - zendaimimon - unheard-of, unprecedented, unparallelled) [n] <Play audio="zendaimimon" />
<br />
新聞紙 (しんぶんし - shinbunshi - newspaper) [n] <Play audio="shinbunshi" />
<br />
聞く (きく - kiku - to hear, to ask) [gv] <Play audio="kiku" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>語</span></th>
<td>ゴ (go)</td>
<td>かた・る (kataru)<br />かた・らう (katarau)</td>
<td>
Language
<br />
<table><tbody><tr><td>Examples:
<br />
語学 (ごがく - gogaku - language study) [n] <Play audio="gogaku" />
<br />
語る (かたる - kataru - to talk, to tell, to recite) [gv] <Play audio="kataru" />
<br />
語らう (かたらう - katarau - to talk, to tell, to recite, to pledge, to conspire with) [gv] <Play audio="katarau" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>読</span></th>
<td>ドク (doku)<br />トク (toku)<br />トウ (tou)</td>
<td>よ・む (yomu)</td>
<td>
Read
<br />
<table><tbody><tr><td>Examples:
<br />
読者 (どくしゃ - dokusha - reader) [n] <Play audio="dokusha" />
<br />
読本 (とくほん - tokuhon - reading-book) [n] <Play audio="tokuhon" />
<br />
句読 (くとう - kutou - punctuation, pause) [n] <Play audio="kutou" />
<br />
読む (よむ - yomu - to read) [gv] <Play audio="yomu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>書</span></th>
<td>ショ (sho)</td>
<td>か・く (kaku)</td>
<td>
Write
<br />
<table><tbody><tr><td>Examples:
<br />
書類 (しょるい - shorui - documents, official papers) [n] <Play audio="shorui" />
<br />
書く (かく - kaku - to write) [gv] <Play audio="kaku" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>記</span></th>
<td>キ (ki)</td>
<td>しる・す (shirusu)</td>
<td>
Write Down
<br />
<table><tbody><tr><td>Examples:
<br />
記録 (きろく - kiroku - record) [n] <Play audio="kiroku" />
<br />
記す (しるす - shirusu - to write down, to note) [gv] <Play audio="shirusu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>紙</span></th>
<td>シ (shi)</td>
<td>かみ (kami)</td>
<td>
Paper
<br />
<table><tbody><tr><td>Examples:
<br />
印紙 (いんし - inshi - stamp) [n] <Play audio="inshi" />
<br />
紙 (かみ - kami - paper) [n] <Play audio="kami" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>画</span></th>
<td>カク (kaku)<br />ガ (ga)</td>
<td></td>
<td>
Picture
<br />
<table><tbody><tr><td>Examples:
<br />
画然 (かくぜん - kakuzen - distinct, clear-cut, sharp) [n] <Play audio="kakuzen" />
<br />
名画 (めいが - meiga - famous picture, masterpiece) [n] <Play audio="meiga" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>絵</span></th>
<td>エ (e)<br />カイ (kai)</td>
<td></td>
<td>
Picture
<br />
<table><tbody><tr><td>Examples:
<br />
絵本 (えほん - ehon - picture book) [n] <Play audio="ehon" />
<br />
絵画 (かいが - kaiga - picture, painting) [n] <Play audio="kaiga" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>図</span></th>
<td>ズ (zu)<br />ト (to)</td>
<td>はか・る (hakaru)</td>
<td>
Drawing
<br />
<table><tbody><tr><td>Examples:
<br />
地図 (ちず - chizu - map) [n] <Play audio="chizu" />
<br />
図書館 (としょかん - toshokan - library) [n] <Play audio="toshokan" />
<br />
図る (はかる - hakaru - to plot, to plan, to design) [gv] <Play audio="hakaru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>工</span></th>
<td>ク (go)<br />コウ (kou)</td>
<td></td>
<td>
Craft
<br />
<table><tbody><tr><td>Examples:
<br />
工夫 (くふう - kufuu - scheme, device) [n] <Play audio="kufuu" />
<br />
工作 (こうさく - kousaku - work, construction, handicraft) [n] <Play audio="kousaku" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>教</span></th>
<td>キョウ (kyou)</td>
<td>おし・える (oshieru)<br />おし・え (oshie)<br />おそ・わる (osowaru)</td>
<td>
Teach
<br />
<table><tbody><tr><td>Examples:
<br />
教授 (きょうじゅ - kyouju - professor) [n] <Play audio="kyouju" />
<br />
教える (おしえる - oshieru - to teach, to inform, to instruct) [iv] <Play audio="oshieru" />
<br />
教え (おしえ - oshie - teachings, precept, lesson, doctrine) [n] <Play audio="oshie" />
<br />
教わる (おそわる - osowaru - to be taught) [gv] <Play audio="osowaru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>晴</span></th>
<td>セイ (sei)</td>
<td>は・れる (hareru)</td>
<td>
Fine
<br />
<table><tbody><tr><td>Examples:
<br />
快晴 (かいせい - kaisei - good weather) [n] <Play audio="kaisei" />
<br />
晴れる (はれる - hareru - to clear up, to clear away, to be sunny, to stop raining) [iv] <Play audio="hareru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>思</span></th>
<td>シ (shi)</td>
<td>おも・う (omou)</td>
<td>
Think
<br />
<table><tbody><tr><td>Examples:
<br />
意思 (いし - ishi - intention, purpose) [n] <Play audio="ishi" />
<br />
思う (おもう - omou - to think, to consider, to believe) [gv] <Play audio="omou" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>考</span></th>
<td>コウ (kou)</td>
<td>かんが・える (kangaeru)<br />かんが・え (kangae)</td>
<td>
Think About
<br />
<table><tbody><tr><td>Examples:
<br />
考慮　(こうりょ - kouryo - consideration, taking into account) [n] <Play audio="kouryo" />
<br />
考える (かんがえる - kangaeru - to consider, to think about) [iv] <Play audio="kangaeru" />
<br />
考え (かんがえ - kangae - thinking, thought, ideas, intention) [n] <Play audio="kangae" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>知</span></th>
<td>チ (chi)</td>
<td>し・る (shiru)</td>
<td>
Know
<br />
<table><tbody><tr><td>Examples:
<br />
知恵 (ちえ - chie - wisdom, intelligence) [n] <Play audio="chie" />
<br />
知る (しる - shiru - to know, to be acquainted with) [gv] <Play audio="shiru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>才</span></th>
<td>サイ (sai)</td>
<td></td>
<td>
Ability
<br />
<table><tbody><tr><td>Examples:
<br />
天才 (てんさい - tensai - genius, prodigy) [n] <Play audio="tensai" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>理</span></th>
<td>リ (ri)</td>
<td></td>
<td>
Reason
<br />
<table><tbody><tr><td>Examples:
<br />
理解 (りかい - rikai - understanding, comprehension) [n] <Play audio="rikai" />
<br />
理由 (りゆう - riyuu - reason, pretext, motive) [n] <Play audio="riyuu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>算</span></th>
<td>サン (san)</td>
<td></td>
<td>
Calculate
<br />
<table><tbody><tr><td>Examples:
<br />
予算 (よさん - yosan - estimate, budget) [n] <Play audio="yosan" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>作</span></th>
<td>サク (saku)<br />サ (sa)</td>
<td>つく・る (tsukuru)</td>
<td>
Make
<br />
<table><tbody><tr><td>Examples:
<br />
作文 (さくぶん - sakubun - writing, composition) [n] <Play audio="sakubun" />
<br />
作業 (さぎょう - sagyou - work, operation, manufacturing) [n] <Play audio="sagyou" />
<br />
作る (つくる - tsukuru - to make, to produce, to manufacture) [gv] <Play audio="tsukuru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>元</span></th>
<td>ガン (gan)<br />ゲン (gen)</td>
<td>もと (moto)</td>
<td>
Origin
<br />
<table><tbody><tr><td>Examples:
<br />
元旦 (がんたん - gantan - New Year's Day) [n] <Play audio="gantan" />
<br />
元素 (げんそ - genso - chemical element) [n] <Play audio="genso" />
<br />
元 (もと - moto - origin, source) [n] <Play audio="moto" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>食</span></th>
<td>ジキ (jiki)<br />ショク (shoku)</td>
<td>く・う (kuu)<br />く・らう (kurau)<br />た・べる (taberu)</td>
<td>
Eat Meal
<br />
<table><tbody><tr><td>Examples:
<br />
断食 (だんじき - danjiki - fasting) [n] <Play audio="danjiki" />
<br />
食欲 (しょくよく - shokuyoku - appetite (for food)) [n] <Play audio="shokuyoku" />
<br />
食う (くう - kuu - to eat (very informal)) [gv] <Play audio="kuu" />
<br />
食らう (くらう - kurau - to eat, to drink (very informal)) [gv] <Play audio="kurau" />
<br />
食べる (たべる - taberu - to eat) [n] <Play audio="taberu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>肉</span></th>
<td>ニク (niku)</td>
<td></td>
<td>
Meat
<br />
<table><tbody><tr><td>Examples:
<br />
筋肉 (きんにく - kinniku - muscle, sinew) [n] <Play audio="kinniku" />
<br />
肉 (にく - niku - flesh, meat) [n] <Play audio="niku" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>馬</span></th>
<td>バ (ba)</td>
<td>うま (uma)<br />ま (ma - rarely used)</td>
<td>
Horse
<br />
<table><tbody><tr><td>Examples:
<br />
馬耳東風 (ばじとうふう - bajitoufuu - utter indifference, talking to the wall, praying to deaf ears) [n] <Play audio="bajitoufuu" />
<br />
馬 (うま - uma - horse) [n] <Play audio="uma" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>牛</span></th>
<td>ギュウ (gyuu)</td>
<td>うし (ushi)</td>
<td>
Cow
<br />
<table><tbody><tr><td>Examples:
<br />
牛乳 (ぎゅうにゅう - gyuunyuu - (cow's) milk) [n] <Play audio="gyuunyuu" />
<br />
牛 (うし - ushi - cattle, cow, ox, oxen) [n] <Play audio="ushi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>魚</span></th>
<td>ギョ (gyo)</td>
<td>うお (uo)<br />さかな (sakana)</td>
<td>
Fish
<br />
<table><tbody><tr><td>Examples:
<br />
金魚 (きんぎょ - kingyo - goldfish) [n] <Play audio="kingyo" />
<br />
魚市場 (うおいちば - uoichiba - fish market) [n] <Play audio="uoichiba" />
<br />
魚 (さかな - sakana - fish) [n] <Play audio="sakana" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>鳥</span></th>
<td>チョウ (chou)</td>
<td>とり (tori)</td>
<td>
Bird
<br />
<table><tbody><tr><td>Examples:
<br />
白鳥 (はくちょう - hakuchou - swan) [n] <Play audio="hakuchou" />
<br />
鳥 (とり - tori - bird) [n] <Play audio="tori" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>羽</span></th>
<td>ウ (u)</td>
<td>は (ha)<br />はね (hane)</td>
<td>
Feather
<br />
<table><tbody><tr><td>Examples:
<br />
羽毛 (うもう - umou - feathers, plumage, down) [n] <Play audio="umou" />
<br />
羽織 (はおり - haori - haori (Japanese formal coat)) [n] <Play audio="haori" />
<br />
羽 (はね - hane - feather, plume, wing) [n] <Play audio="hane" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>鳴</span></th>
<td>メイ (mei)</td>
<td>な・く (naku)<br />な・る (naru)<br />な・らす (narasu)</td>
<td>
Chirp
<br />
<table><tbody><tr><td>Examples:
<br />
共鳴 (きょうめい - kyoumei - resonance, sympathetic (sound), (being in) sympathy) [n] <Play audio="kyoumei" />
<br />
鳴く (なく - naku - to sing or chirp (bird), to bark, to purr, to make sound (animal)) [gv] <Play audio="naku" />
<br />
鳴る (なる - naru - to sound, to ring, to resound, to echo) [gv] <Play audio="naru" />
<br />
鳴らす (ならす - narasu - to ring, to sound, to chime) [gv] <Play audio="narasu" />
</td></tr></tbody></table></td>
</tr>
</tbody></table>

    </>
  )
}

export default GradeTwoThree