import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Going to a Restaurant"

const restaurantone = [
  {"漢字 - Kanji":"和食","ひらがな - Hiragana":"わしょく","Romaji (English Letters)":"washoku","audio":"washoku","English Meaning":"Japanese food"},
 {"漢字 - Kanji":"洋食","ひらがな - Hiragana":"ようしょく","Romaji (English Letters)":"youshoku","audio":"youshoku","English Meaning":"Western food"},
 {"漢字 - Kanji":"イタリア料理","ひらがな - Hiragana":"いたりありょうり","Romaji (English Letters)":"itaria ryouri","audio":"itaria ryouri","English Meaning":"Italian food"},
 {"漢字 - Kanji":"フランス料理","ひらがな - Hiragana":"ふらんすりょうり","Romaji (English Letters)":"furansu ryouri","audio":"furansu ryouri","English Meaning":"French food"},
 {"漢字 - Kanji":"ドイツ料理","ひらがな - Hiragana":"どいつりょうり","Romaji (English Letters)":"doitsu ryouri","audio":"doitsu ryouri","English Meaning":"German food"},
 {"漢字 - Kanji":"アメリカ料理","ひらがな - Hiragana":"あめりかりょうり","Romaji (English Letters)":"amerika ryouri","audio":"amerika ryouri","English Meaning":"American food"},
 {"漢字 - Kanji":"インド料理","ひらがな - Hiragana":"いんどりょうり","Romaji (English Letters)":"indo ryouri","audio":"indo ryouri","English Meaning":"Indian food"},
 {"漢字 - Kanji":"ギリシャ料理","ひらがな - Hiragana":"ぎりしゃりょうり","Romaji (English Letters)":"girisha ryouri","audio":"girisha ryouri","English Meaning":"Greek food"},
 {"漢字 - Kanji":"メキシコ料理","ひらがな - Hiragana":"めきしこりょうり","Romaji (English Letters)":"mekishiko ryouri","audio":"mekishiko ryouri","English Meaning":"Mexican food"},
 {"漢字 - Kanji":"中華料理","ひらがな - Hiragana":"ちゅうかりょうり","Romaji (English Letters)":"chuuka ryouri","audio":"chuuka ryouri","English Meaning":"Chinese food"},
 {"漢字 - Kanji":"軽食","ひらがな - Hiragana":"けいしょく","Romaji (English Letters)":"keishoku","audio":"keishoku","English Meaning":"light meal"},
 {"漢字 - Kanji":"軽食堂","ひらがな - Hiragana":"けいしょくどう","Romaji (English Letters)":"keishokudou","audio":"keishokudou","English Meaning":"snack bar"},
 {"漢字 - Kanji":"居酒屋","ひらがな - Hiragana":"いざかや","Romaji (English Letters)":"izakaya","audio":"izakaya","English Meaning":"bar, pub, tavern"},
 {"漢字 - Kanji":"焼き鳥屋","ひらがな - Hiragana":"やきとりや","Romaji (English Letters)":"yakitoriya","audio":"yakitoriya","English Meaning":"restaurant that serves yakitori(chicken pieces grilled on a skewer)"},
 {"漢字 - Kanji":"定食","ひらがな - Hiragana":"ていしょく","Romaji (English Letters)":"teishoku","audio":"teishoku","English Meaning":"set meal, special (of the day)"},
 {"漢字 - Kanji":"定食屋","ひらがな - Hiragana":"ていしょくや","Romaji (English Letters)":"teishokuya","audio":"teishokuya","English Meaning":"diner"},
 {"漢字 - Kanji":"丼","ひらがな - Hiragana":"どんぶり","Romaji (English Letters)":"donburi","audio":"donburi","English Meaning":"a bowl of rice with food on top(very popular Japanese meal)"},
 {"漢字 - Kanji":"牛丼","ひらがな - Hiragana":"ぎゅうどん","Romaji (English Letters)":"gyuudon","audio":"gyuudon","English Meaning":"beef bowl(rice covered with beef and vegetables)"},
 {"漢字 - Kanji":"カツ丼","ひらがな - Hiragana":"かつどん","Romaji (English Letters)":"katsudon","audio":"katsudon","English Meaning":"pork bowl(breaded pork on rice)"},
 {"漢字 - Kanji":"親子丼","ひらがな - Hiragana":"おやこどん","Romaji (English Letters)":"oyakodon","audio":"oyakodon","English Meaning":"chicken bowl(chicken and egg on rice - means \"parent and child donburi\")"},
 {"漢字 - Kanji":"牛丼屋","ひらがな - Hiragana":"ぎゅうどんや","Romaji (English Letters)":"gyuudonya","audio":"gyuudonya","English Meaning":"beef bowl shop(serves other types of donburi as well)"},
 {"漢字 - Kanji":"醤油ラーメン","ひらがな - Hiragana":"しょうゆらーめん","Romaji (English Letters)":"shouyu ra-men","audio":"shouyu ra-men","English Meaning":"soy sauce ramen(the most common flavor in Japan)"},
 {"漢字 - Kanji":"ラーメン屋","ひらがな - Hiragana":"らーめんや","Romaji (English Letters)":"ra-menya","audio":"ra-menya","English Meaning":"ramen shop"},
 {"漢字 - Kanji":"焼肉屋","ひらがな - Hiragana":"やきにくや","Romaji (English Letters)":"yakinikuya","audio":"yakinikuya","English Meaning":"restaurant that serves yakiniku(Japanese dish of fried meat, Korean barbecue)"},
 {"漢字 - Kanji":"喫茶店","ひらがな - Hiragana":"きっさてん","Romaji (English Letters)":"kissaten","audio":"kissaten","English Meaning":"coffee lounge, coffee shop"},
 {"漢字 - Kanji":"屋台","ひらがな - Hiragana":"やたい","Romaji (English Letters)":"yatai","audio":"yatai","English Meaning":"food cart"},
 {"漢字 - Kanji":"飲食店","ひらがな - Hiragana":"いんしょくてん","Romaji (English Letters)":"inshokuten","audio":"inshokuten","English Meaning":"restaurant, shops serving food and drink"},
 {"漢字 - Kanji":"レストラン","ひらがな - Hiragana":"れすとらん","Romaji (English Letters)":"resutoran","audio":"resutoran","English Meaning":"restaurant"},
 {"漢字 - Kanji":"食べ放題","ひらがな - Hiragana":"たべほうだい","Romaji (English Letters)":"tabehoudai","audio":"tabehoudai","English Meaning":"all-you-can-eat, smorgasbord"},
 {"漢字 - Kanji":"ハンバーガー","ひらがな - Hiragana":"はんばーがー","Romaji (English Letters)":"hamba-ga-","audio":"hamba-ga-","English Meaning":"hamburger (on a bun)"},
 {"漢字 - Kanji":"チーズバーガー","ひらがな - Hiragana":"ちーずばーがー","Romaji (English Letters)":"chi-zuba-ga-","audio":"chi-zuba-ga-","English Meaning":"cheeseburger"},
 {"漢字 - Kanji":"フレンチフライ","ひらがな - Hiragana":"ふれんちふらい","Romaji (English Letters)":"furenchi furai","audio":"furenchi furai","English Meaning":"fried potato, French fries, chips"},
 {"漢字 - Kanji":"ブレックファースト","ひらがな - Hiragana":"ぶれっくふぁーすと","Romaji (English Letters)":"burekkufa-suto","audio":"burekkufa-suto","English Meaning":"breakfast"},
 {"漢字 - Kanji":"朝食","ひらがな - Hiragana":"ちょうしょく","Romaji (English Letters)":"choushoku","audio":"choushoku","English Meaning":"breakfast"},
 {"漢字 - Kanji":"朝ご飯","ひらがな - Hiragana":"あさごはん","Romaji (English Letters)":"asagohan","audio":"asagohan","English Meaning":"breakfast"},
 {"漢字 - Kanji":"ランチ","ひらがな - Hiragana":"らんち","Romaji (English Letters)":"ranchi","audio":"ranchi","English Meaning":"lunch"},
 {"漢字 - Kanji":"昼食","ひらがな - Hiragana":"ちゅうしょく","Romaji (English Letters)":"chuushoku","audio":"chuushoku","English Meaning":"lunch"},
 {"漢字 - Kanji":"昼ご飯","ひらがな - Hiragana":"ひるごはん","Romaji (English Letters)":"hirugohan","audio":"hirugohan","English Meaning":"lunch"},
 {"漢字 - Kanji":"夕食","ひらがな - Hiragana":"ゆうしょく","Romaji (English Letters)":"yuushoku","audio":"yuushoku","English Meaning":"dinner"},
 {"漢字 - Kanji":"夜ご飯","ひらがな - Hiragana":"よるごはん","Romaji (English Letters)":"yorugohan","audio":"yorugohan","English Meaning":"dinner"},
 {"漢字 - Kanji":"寿司屋","ひらがな - Hiragana":"すしや","Romaji (English Letters)":"sushiya","audio":"sushiya","English Meaning":"sushi shop, sushi restaurant"},
 {"漢字 - Kanji":"寿司","ひらがな - Hiragana":"すし","Romaji (English Letters)":"sushi","audio":"sushi","English Meaning":"sushi - anything made with vinegared rice(may also contain vegetables, spices, fish, or other delicacies)"},
 {"漢字 - Kanji":"刺身","ひらがな - Hiragana":"さしみ","Romaji (English Letters)":"sashimi","audio":"sashimi","English Meaning":"sashimi(raw sliced fish, shellfish or crustaceans)"},
 {"漢字 - Kanji":"巻き寿司","ひらがな - Hiragana":"まきずし","Romaji (English Letters)":"makizushi","audio":"makizushi","English Meaning":"suchi roll(sushi made rolled in nori seaweed with a core of filling)"},
 {"漢字 - Kanji":"握り寿司","ひらがな - Hiragana":"にぎりずし","Romaji (English Letters)":"nigirizushi","audio":"nigirizushi","English Meaning":"hand-rolled sushi, sushi ball"},
 {"漢字 - Kanji":"稲荷寿司","ひらがな - Hiragana":"いなりずし","Romaji (English Letters)":"inarizushi","audio":"inarizushi","English Meaning":"sushi wrapped in fried tofu"},
 {"漢字 - Kanji":"ちらし寿司","ひらがな - Hiragana":"ちらしずし","Romaji (English Letters)":"chirashizushi","audio":"chirashizushi","English Meaning":"sushi rice in a box or bowl with a variety of ingredients sprinkled on top"},
 {"漢字 - Kanji":"回転寿司","ひらがな - Hiragana":"かいてんずし","Romaji (English Letters)":"kaitenzushi","audio":"kaitenzushi","English Meaning":"\"conveyor belt\" sushi bar, sushi-go-round"},
 {"漢字 - Kanji":"うどん屋","ひらがな - Hiragana":"うどんや","Romaji (English Letters)":"udonya","audio":"udonya","English Meaning":"restaurant that serves udon(thick Japanese wheat noodles)"},
 {"漢字 - Kanji":"カレー屋","ひらがな - Hiragana":"かれーや","Romaji (English Letters)":"kare-ya","audio":"kare-ya","English Meaning":"restaurant that serves curry"},
 {"漢字 - Kanji":"カレーライス","ひらがな - Hiragana":"かれーらいす","Romaji (English Letters)":"kare- raisu","audio":"kare- raisu","English Meaning":"curry on top of rice"},
 {"漢字 - Kanji":"カレーうどん","ひらがな - Hiragana":"かれーうどん","Romaji (English Letters)":"kare- udon","audio":"kare- udon","English Meaning":"udon cooked with curry topping"},
 {"漢字 - Kanji":"小","ひらがな - Hiragana":"しょう","Romaji (English Letters)":"shou","audio":"shou","English Meaning":"small (size prefix)"},
 {"漢字 - Kanji":"中","ひらがな - Hiragana":"ちゅう","Romaji (English Letters)":"chuu","audio":"chuu","English Meaning":"medium (size prefix)"},
 {"漢字 - Kanji":"大","ひらがな - Hiragana":"だい","Romaji (English Letters)":"dai","audio":"dai","English Meaning":"big (size prefix)"},
 {"漢字 - Kanji":"スモール","ひらがな - Hiragana":"すもーる","Romaji (English Letters)":"sumo-ru","audio":"sumo-ru","English Meaning":"small (size)"},
 {"漢字 - Kanji":"ミディアム","ひらがな - Hiragana":"みでぃあむ","Romaji (English Letters)":"midiamu","audio":"midiamu","English Meaning":"medium (size)"},
 {"漢字 - Kanji":"ラージ","ひらがな - Hiragana":"らーじ","Romaji (English Letters)":"ra-ji","audio":"ra-ji","English Meaning":"large (size)"},
 {"漢字 - Kanji":"オレンジジュース","ひらがな - Hiragana":"おれんじじゅーす","Romaji (English Letters)":"orenji ju-su","audio":"orenji ju-su","English Meaning":"orange juice"},
 {"漢字 - Kanji":"ミルクシェイク","ひらがな - Hiragana":"みるくしぇいく","Romaji (English Letters)":"mirukusheiku","audio":"mirukusheiku","English Meaning":"milkshake"},
 {"漢字 - Kanji":"コーヒー","ひらがな - Hiragana":"こーひー","Romaji (English Letters)":"ko-hi-","audio":"ko-hi-","English Meaning":"coffee"},
 {"漢字 - Kanji":"お茶","ひらがな - Hiragana":"おちゃ","Romaji (English Letters)":"ocha","audio":"ocha","English Meaning":"tea (usually green tea)"},
 {"漢字 - Kanji":"紅茶","ひらがな - Hiragana":"こうちゃ","Romaji (English Letters)":"koucha","audio":"koucha","English Meaning":"black tea"},
 {"漢字 - Kanji":"アイスクリーム","ひらがな - Hiragana":"あいすくりーむ","Romaji (English Letters)":"aisu kuri-mu","audio":"aisu kuri-mu","English Meaning":"ice cream"},
 {"漢字 - Kanji":"チョコレートアイスクリーム","ひらがな - Hiragana":"ちょこれーとあいすくりーむ","Romaji (English Letters)":"chokore-to aisu kuri-mu","audio":"chokore-to aisu kuri-mu","English Meaning":"chocolate ice cream"},
 {"漢字 - Kanji":"バニラアイスクリーム","ひらがな - Hiragana":"ばにらあいすくりーむ","Romaji (English Letters)":"banira aisu kuri-mu","audio":"banira aisu kuri-mu","English Meaning":"vanilla ice cream"},
 {"漢字 - Kanji":"ホットケーキ","ひらがな - Hiragana":"ほっとけーき","Romaji (English Letters)":"hottoke-ki","audio":"hottoke-ki","English Meaning":"hotcakes, pancakes"},
 {"漢字 - Kanji":"シロップ","ひらがな - Hiragana":"しろっぷ","Romaji (English Letters)":"shiroppu","audio":"shiroppu","English Meaning":"syrup"},
 {"漢字 - Kanji":"トースト","ひらがな - Hiragana":"とーすと","Romaji (English Letters)":"to-suto","audio":"to-suto","English Meaning":"toast"},
 {"漢字 - Kanji":"蜂蜜","ひらがな - Hiragana":"はちみつ","Romaji (English Letters)":"hachimitsu","audio":"hachimitsu","English Meaning":"honey"},
 {"漢字 - Kanji":"目玉焼き","ひらがな - Hiragana":"めだまやき","Romaji (English Letters)":"medamayaki","audio":"medamayaki","English Meaning":"sunny-side-up fried eggs"},
 {"漢字 - Kanji":"スクランブルエッグ","ひらがな - Hiragana":"すくらんぶるえっぐ","Romaji (English Letters)":"sukurambu eggu","audio":"sukurambu eggu","English Meaning":"scrambled eggs"},
 {"漢字 - Kanji":"ゆで卵","ひらがな - Hiragana":"ゆでたまご","Romaji (English Letters)":"yude tamago","audio":"yude tamago","English Meaning":"boiled egg"},
 {"漢字 - Kanji":"スパゲティ","ひらがな - Hiragana":"すぱげてぃ","Romaji (English Letters)":"supageti","audio":"supageti","English Meaning":"spaghetti"},
 {"漢字 - Kanji":"烏賊","ひらがな - Hiragana":"いか","Romaji (English Letters)":"ika","audio":"ika","English Meaning":"squid"},
 {"漢字 - Kanji":"蛸","ひらがな - Hiragana":"たこ","Romaji (English Letters)":"tako","audio":"tako","English Meaning":"octopus"},
 {"漢字 - Kanji":"タコス","ひらがな - Hiragana":"たこす","Romaji (English Letters)":"takosu","audio":"takosu","English Meaning":"tacos"},
 {"漢字 - Kanji":"味噌汁","ひらがな - Hiragana":"みそしる","Romaji (English Letters)":"miso shiru","audio":"miso shiru","English Meaning":"miso soup(soup made from miso bean paste)"},
 {"漢字 - Kanji":"醤油","ひらがな - Hiragana":"しょうゆ","Romaji (English Letters)":"shouyu","audio":"shouyu","English Meaning":"soy sauce"},
 {"漢字 - Kanji":"わさび","ひらがな - Hiragana":"わさび","Romaji (English Letters)":"wasabi","audio":"wasabi","English Meaning":"green Japanese horseradish"},
 {"漢字 - Kanji":"お好み焼き","ひらがな - Hiragana":"おこのみやき","Romaji (English Letters)":"okonomiyaki","audio":"okonomiyaki","English Meaning":"savory cabbage pancake with various ingredients(literally means \"fried stuff you like\")"},
 {"漢字 - Kanji":"豚カツ","ひらがな - Hiragana":"とんかつ","Romaji (English Letters)":"tonkatsu","audio":"tonkatsu","English Meaning":"pork cutlet"},
 {"漢字 - Kanji":"煎餅","ひらがな - Hiragana":"せんべい","Romaji (English Letters)":"senbei","audio":"senbei","English Meaning":"rice cookie, Japanese cracker"},
 {"漢字 - Kanji":"ご飯","ひらがな - Hiragana":"ごはん","Romaji (English Letters)":"gohan","audio":"gohan","English Meaning":"cooked white rice, meal"},
 {"漢字 - Kanji":"米","ひらがな - Hiragana":"こめ","Romaji (English Letters)":"kome","audio":"kome","English Meaning":"uncooked rice"},
 {"漢字 - Kanji":"カルピス","ひらがな - Hiragana":"かるぴす","Romaji (English Letters)":"karupisu","audio":"karupisu","English Meaning":"Calpis (popular Japanese milk-based soft drink - sounds like \"cow piss\")"},
 {"漢字 - Kanji":"ポカリスエット","ひらがな - Hiragana":"ぽかりすえっと","Romaji (English Letters)":"pokari suetto","audio":"pokari suetto","English Meaning":"Pocari Sweat (another popular Japanese drink with a very interesting name)"}
]
const restauranttwo = [
  {"Kanji":"いらっしゃいませ","Hiragana":"いらっしゃいませ","Romaji":"Irasshaimase.","audio":"irasshaimase","English Meaning":"Welcome/Come on in!(Something you might hear as you enter a restaurant)"},
 {"Kanji":"ご注文はございますか","Hiragana":"ごちゅうもんはございますか","Romaji":"Gochuumon wa gozaimasu ka.","audio":"gochuumon wa gozaimasu ka","English Meaning":"Are you ready to order?"},
 {"Kanji":"ご注文をお願いします","Hiragana":"ごちゅうもんをおねがいします","Romaji":"Gochuumon o onegai shimasu.","audio":"gochuumon o onegai shimasu","English Meaning":"Go ahead with your order, please."},
 {"Kanji":"ご注文を承ります","Hiragana":"ごちゅうもんをうけたまわります","Romaji":"Gochuumon o uketamawarimasu.","audio":"gochuumon o uketamawarimasu","English Meaning":"I will take your order now.(Similar to above but much more polite)"},
 {"Kanji":"お飲み物は何が宜しいですか","Hiragana":"おのみものはなにがよろしいですか","Romaji":"Onomimono wa nani ga yoroshii desu ka.","audio":"onomimono wa nani ga yoroshii desu ka","English Meaning":"What would you like to drink?"},
 {"Kanji":"______をお願いします","Hiragana":"______をおねがいします","Romaji":"______ o onegai shimasu.","audio":"______ o onegai shimasu","English Meaning":"I will have ______, please."},
 {"Kanji":"お代わりをお願いします","Hiragana":"おかわりをおねがいします","Romaji":"Okawari o onegai shimasu.","audio":"okawari o onegai shimasu.","English Meaning":"I would like seconds (or a refill), please."},
 {"Kanji":"どこで食べに行きましょうか","Hiragana":"どこでたべにいきましょうか","Romaji":"Doko de tabe ni ikimashou ka.","audio":"doko de tabe ni ikimashou ka","English Meaning":"Where shall we go eat?"},
 {"Kanji":"何が食べたいですか","Hiragana":"なにがたべたいですか","Romaji":"Nani ga tabetai desu ka.","audio":"nani ga tabetai desu ka","English Meaning":"What would you like to eat?"}
]
const restaurantthree = [
  {"Kanji":"注文","Hiragana":"ちゅうもん","Romaji":"chuumon","English Meaning":"order, request [n]","audio":"chuumon"},
 {"Kanji":"願う","Hiragana":"ねがう","Romaji":"negau","English Meaning":"to desire, to wish, to hope, to request, to implore [gv]","audio":"negau"},
 {"Kanji":"承る","Hiragana":"うけたまわる","Romaji":"uketamawaru","English Meaning":"(honorific) to hear, to be told, to know, to receive (order), to undertake [gv]","audio":"uketamawaru"},
 {"Kanji":"飲み物","Hiragana":"のみもの","Romaji":"nomimono","English Meaning":"drink, beverage [n]","audio":"nomimono"},
 {"Kanji":"宜しい","Hiragana":"よろしい","Romaji":"yoroshii","English Meaning":"honorific form of \"ii\" or \"yoi\" (good, OK, all right, fine, very well) [dv]","audio":"yoroshii"},
 {"Kanji":"代わり","Hiragana":"かわり","Romaji":"kawari","English Meaning":"substitute, replacement [n]","audio":"kawari"},
 {"Kanji":"お代わり","Hiragana":"おかわり","Romaji":"okawari","English Meaning":"second helping, another cup, seconds [n]","audio":"okawari"}
]

const Restaurant = () => {

  return (
    <>
      <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Beginner" category="Vocabulary" lesson="Going to a Restaurant" />
     <div className={'title'}>
     Going to a Restaurant
      </div>
      <h1>{title}</h1>

<p>There are many different types of restaurants in Japan but, since the Japanese typically prefer to eat Japanese food, Japanese restaurants are the most common. Japanese restaurants are typically specialty shops, however, and don't serve a wide variety of different foods - for instance, if you want ramen you go to a ramen shop. Beef is fairly expensive in Japan (and not commonly eaten). Fish and other sea food are more commonly eaten and much more inexpensive. McDonald's (マクドナルド - <Play audio="makudonarudo" /> ) is probably the most numerous non-Japanese restaurant in Japan but don't expect the food to taste exactly the same. I would hope though that if you make it clear to Japan that you don't eat at McDonald's but focus on Japanese food instead. Be sure to try melon soda if you see it!</p>

<p>Below are a number of Japanese vocabulary words and phrases associated with going to a restaurant. All words are nouns unless otherwise specified. Please note that the example sentences will be done in polite form since it is appropriate for most of them.</p>

<Table data={restaurantone} />

<p>Here are some example sentences:</p>

<Table data={restauranttwo} />

<h2>Vocab breakdown</h2>

<Table data={restaurantthree} />

      </>
  )
}

export default Restaurant