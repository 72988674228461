import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Grade 5 Kanji - Part 1 of 5"

const GradeFiveOne = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Advanced" category="Kanji" lesson={title} />
     
      <h1>{title}</h1>

      <p>Below are Kanji the Japanese learn in grade 5 of elementary school.
Be sure to read the <Link to="/members/a/kanji/intro/">Introduction to Kanji</Link> if you haven't already.</p>

<p>Legend:</p>
<p>[n] = Noun</p>
<p>[dv] = Descriptive word (true adjective) - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[dn] = Descriptive noun - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[gv] = Godan verb - see <Link to="/members/b/grammar/godan-verbs/">Verb Conjugation (Godan)</Link></p>
<p>[iv] = Ichidan verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[irv] = Irregular verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[c] = Counter</p>

<table>
<thead>
  <tr>
<th>漢字<br /><Play audio="kanji" /></th>
<th>音読み <Play audio="onyomi" /><br />"On" Reading</th>
<th>訓読み <Play audio="kunyomi" /><br />"Kun" Reading</th>
<th>English Meaning</th>
</tr>
  </thead>
<tbody>
<tr>
<th><span style={{fontSize:`4rem`}}>久</span></th>
<td>ク (ku - rarely used)<br />キュウ (kyuu)</td>
<td>ひさ・しい (hisashii)</td>
<td>
Long time
<br />
<table><tbody><tr><td>Examples:
<br />
永久 (えいきゅう - eikyuu - eternity, perpetuity, immortality) [dn] <Play audio="eikyuu" />
<br />
久しい (ひさしい - hisashii - long, long-continued, old (story)) [dv] <Play audio="hisashii" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>仏</span></th>
<td>フツ (futsu - rarely used)<br />ブツ (butsu)</td>
<td>ほとけ</td>
<td>
Buddha
<br />
<table><tbody><tr><td>Examples:
<br />
仏教 (ぶっきょう - bukkyou - Buddhism) [n] <Play audio="bukkyou" />
<br />
仏像 (ぶつぞう - butsuzou - statue of Buddha, image of Buddha) [n] <Play audio="butsuzou" />
<br />
仏 (ほとけ - hotoke - Buddha, merciful person, Buddhist image, the dead) [n] <Play audio="hotoke" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>仮</span></th>
<td>ケ (ke)<br />カ (ka)</td>
<td>かり (kari)<br />かり・に (karini)</td>
<td>
Sham
<br />
<table><tbody><tr><td>Examples:
<br />
仮病 (けびょう - kebyou - feigned illness) [n] <Play audio="kebyou" />
<br />
仮装 (かそう - kasou - costume, fancy dress, masquerade, disguise) [n] <Play audio="kasou" />
<br />
仮 (かり - kari - temporary, provisional, interim, fictitious, assumed (name), alias) [n] <Play audio="kari" />
<br />
仮に (かりに - karini - temporarily, provisionally, for example, for argument's sake) [adverb] <Play audio="karini" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>件</span></th>
<td>ケン (ken)</td>
<td></td>
<td>
Affair
<br />
<table><tbody><tr><td>Examples:
<br />
件 (けん - ken - matter, case, item) [n] <Play audio="ken" />
<br />
件 (けん - ken - counter for cases, matters, etc) [c] <Play audio="ken" />
<br />
条件 (じょうけん - jouken - condition, conditions, term, terms, requirement, requirements) [n] <Play audio="jouken" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>任</span></th>
<td>ニン (nin)</td>
<td>まか・す (makasu)<br />まか・せる (makaseru)</td>
<td>
Responsibility
<br />
<table><tbody><tr><td>Examples:
<br />
責任 (せきにん - sekinin - duty, responsibility (incl. supervision of staff), liability) [n] <Play audio="sekinin" />
<br />
任す (まかす - makasu - to entrust, to leave to a person) [gv] <Play audio="makasu" />
<br />
任せる (まかせる - makaseru - to entrust (e.g. a task) to another, to leave to) [iv] <Play audio="makaseru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>似</span></th>
<td>ジ (ji)</td>
<td>に・る (niru)<br />に・せる (niseru)</td>
<td>
Becoming
<br />
<table><tbody><tr><td>Examples:
<br />
類似 (るいじ - ruiji - resemblance, similarity, analogous, quasi) [n] <Play audio="ruiji" />
<br />
似る (にる - niru - to resemble, to look like, to take after, to be similar (in status, condition, etc.), to be close, to be alike, to be like) [iv] <Play audio="niru" />
<br />
似せる (にせる - niseru - to copy, to imitate, to counterfeit, to forge) [iv] <Play audio="niseru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>余</span></th>
<td>ヨ (yo)</td>
<td>あま・る (amaru)<br />あま・り (amari)<br />あま・す (amasu)</td>
<td>
Too much
<br />
<table><tbody><tr><td>Examples:
<br />
余暇 (よか - yoka - leisure, leisure time, spare time) [n] <Play audio="yoka" />
<br />
余る (あまる - amaru - to remain, to be left over, to be in excess, to be too many) [gv] <Play audio="amaru" />
<br />
余り (あまり - amari - remainder, rest, balance, remains, scraps, residue, remnant, not very (with negative sentence), not much, surplus, excess, fullness, too much) [dn] <Play audio="amari" />
<br />
余す (あます - amasu - to save, to leave over, to spare) [gv] <Play audio="amasu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>価</span></th>
<td>カ (ka)</td>
<td>あたい (atai)</td>
<td>
Value
<br />
<table><tbody><tr><td>Examples:
<br />
価値 (かち - kachi - value, worth, merit) [n] <Play audio="kachi" />
<br />
価 (あたい - atai - price, cost, value, worth, merit) [n] <Play audio="atai" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>保</span></th>
<td>ホ (ho)</td>
<td>たも・つ (tamotsu)</td>
<td>
Preserve
<br />
<table><tbody><tr><td>Examples:
<br />
保険 (ほけん - hoken - insurance, guarantee) [n] <Play audio="hoken" />
<br />
保つ (たもつ - tamotsu - to keep, to preserve, to hold, to retain, to maintain, to support, to sustain, to last, to endure, to keep well (food), to wear well, to be durable) [gv] <Play audio="tamotsu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>修</span></th>
<td>シュ (shu)<br />シュウ (shuu)</td>
<td>おさ・める (osameru)<br />おさ・まる (osamaru)</td>
<td>
Discipline
<br />
<table><tbody><tr><td>Examples:
<br />
修行 (しゅぎょう - shugyou - training, practice, discipline, study) [n] <Play audio="shugyou" />
<br />
研修 (けんしゅう - kenshuu - training (esp. in-service), induction course) [n] <Play audio="kenshuu" />
<br />
修める (おさめる - osameru - to study, to complete (a course), to cultivate, to master, to order (one's life), to repair (a fault one has committed)) [iv] <Play audio="osameru" />
<br />
修まる (おさまる - osamaru - to govern oneself, to conduct oneself well) [gv] <Play audio="osamaru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>俵</span></th>
<td>ヒョウ (hyou)</td>
<td>たわら (tawara)</td>
<td>
Straw bag
<br />
<table><tbody><tr><td>Examples:
<br />
土俵 (どひょう - dohyou - arena (esp. in sumo), forum (e.g. for discussion)) [n] <Play audio="dohyou" />
<br />
俵 (たわら - tawara - straw bag, bale, sack) [n] <Play audio="tawara" />
<br />
俵 (ひょう - hyou - counter for sacks, bales, and bags) [c] <Play audio="hyou" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>個</span></th>
<td>コ (ko)</td>
<td></td>
<td>
Individual
<br />
<table><tbody><tr><td>Examples:
<br />
個人 (こじん - kojin - individual, private person, personal, private) [n] <Play audio="kojin" />
<br />
個 (こ - ko - counter for articles) [c] <Play audio="ko" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>備</span></th>
<td>ビ (bi)</td>
<td>そな・える (sonaeru)<br />そな・わる (sonawaru)</td>
<td>
Provide
<br />
<table><tbody><tr><td>Examples:
<br />
準備 (じゅんび - junbi - preparation, setup, arrangements, provision, reserve) [n] <Play audio="junbi" />
<br />
備える (そなえる - sonaeru - to furnish, to provide for, to equip, to install, to have ready, to prepare for) [iv] <Play audio="sonaeru" />
<br />
備わる (そなわる - sonawaru - to be furnished with, to be endowed with) [gv] <Play audio="sonawaru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>像</span></th>
<td>ゾウ (zou)</td>
<td></td>
<td>
Statue
<br />
<table><tbody><tr><td>Examples:
<br />
像 (ぞう - zou - statue, figure) [n] <Play audio="zou" />
<br />
画像 (がぞう - gazou - image, picture, portrait) [n] <Play audio="gazou" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>再</span></th>
<td>サイ (sai)<br />サ (sa)</td>
<td>ふたた・び (futatabi)</td>
<td>
Again
<br />
<table><tbody><tr><td>Examples:
<br />
再会 (さいかい - saikai - another meeting, meeting again, reunion) [n] <Play audio="saikai" />
<br />
再来年 (さらいねん - sarainen - year after next) [n] <Play audio="sarainen" />
<br />
再び (ふたたび - futatabi - again, once more, a second time) <Play audio="futatabi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>刊</span></th>
<td>カン (kan)</td>
<td></td>
<td>
Publish
<br />
<table><tbody><tr><td>Examples:
<br />
月刊 (げっかん - gekkan - monthly publication, monthly issue) [n] <Play audio="gekkan" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>判</span></th>
<td>ハン (han)<br />バン (ban)</td>
<td>わか・る (wakaru)</td>
<td>
Judge
<br />
<table><tbody><tr><td>Examples:
<br />
判定 (はんてい - hantei - judgement, decision, adjudication, award, verdict, determination) [n] <Play audio="hantei" />
<br />
裁判官 (さいばんかん - saibankan - judge) [n] <Play audio="saibankan" />
<br />
判る (わかる - wakaru - to be understood, to be known, to be realized) [gv] <Play audio="wakaru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>制</span></th>
<td>セイ (sei)</td>
<td></td>
<td>
Control
<br />
<table><tbody><tr><td>Examples:
<br />
制服 (せいふく - seifuku - uniform) [n] <Play audio="seifuku" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>券</span></th>
<td>ケン (ken)</td>
<td></td>
<td>
Ticket
<br />
<table><tbody><tr><td>Examples:
<br />
車券 (しゃけん - shaken - bike-race (betting) ticket) [n] <Play audio="shaken" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>則</span></th>
<td>ソク (soku)</td>
<td>のっと・る (nottoru)</td>
<td>
Rule
<br />
<table><tbody><tr><td>Examples:
<br />
原則 (げんそく - gensoku - principle, general rule) [n] <Play audio="gensoku" />
<br />
則る (のっとる - nottoru - to conform to, to be in accordance with, to follow (rule, tradition, example, etc.)) [gv] <Play audio="nottoru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>効</span></th>
<td>コウ (kou)</td>
<td>き・く (kiku)</td>
<td>
Effect
<br />
<table><tbody><tr><td>Examples:
<br />
効果 (こうか - kouka - effect, effectiveness, efficacy, result) [n] <Play audio="kouka" />
<br />
効く (きく - kiku - to be effective, to show effect) [n] <Play audio="kiku" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>務</span></th>
<td>ム (mu)</td>
<td>つと・める (tsutomeru)<br />つと・め (tsutome)</td>
<td>
Duty
<br />
<table><tbody><tr><td>Examples:
<br />
義務 (ぎむ - gimu - duty, obligation, responsibility) [n] <Play audio="gimu" />
<br />
務める (つとめる - tsutomeru - to serve (as), to act (as), to fill (the position of), to play the role (of)) [iv] <Play audio="tsutomeru" />
<br />
務め (つとめ - tsutome - service, duty, business, responsibility, task) [n] <Play audio="tsutome" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>勢</span></th>
<td>セイ (sei)</td>
<td>いきお・い (ikioi)</td>
<td>
Power
<br />
<table><tbody><tr><td>Examples:
<br />
勢力 (せいりょく - seiryoku - influence, power, might, strength, potency, force, energy) [n] <Play audio="seiryoku" />
<br />
勢い (いきおい - ikioi - force, vigor, energy, spirit, life, influence, authority, power, might, impetus, momentum, course (of events)) [n] <Play audio="ikioi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>厚</span></th>
<td>コウ (kou)</td>
<td>あつ・い (atsui)</td>
<td>
Thick
<br />
<table><tbody><tr><td>Examples:
<br />
温厚 (おんこう - onkou - gentle) [dn] <Play audio="onkou" />
<br />
厚い (あつい - atsui - thick, deep, heavy, kind, cordial, hospitable, warm, faithful, abundant) [dv] <Play audio="atsui" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>句</span></th>
<td>ク (ku)</td>
<td></td>
<td>
Phrase
<br />
<table><tbody><tr><td>Examples:
<br />
句点 (くてん - kuten - period, full stop) [n] <Play audio="kuten" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>可</span></th>
<td>カ (ka)</td>
<td></td>
<td>
Possible
<br />
<table><tbody><tr><td>Examples:
<br />
可能性 (かのうせい - kanousei - potentiality, likelihood, possibility, availability) [n] <Play audio="kanousei" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>営</span></th>
<td>エイ (ei)</td>
<td>いとな・む (itonamu)<br />いとな・み (itonami)</td>
<td>
Manage
<br />
<table><tbody><tr><td>Examples:
<br />
自営 (じえい - jiei - independent, self-employed, self-supporting, operating own business) [n] <Play audio="jiei" />
<br />
営む (いとなむ - itonamu - to carry on (e.g. in ceremony), to run a business) [gv] <Play audio="itonamu" />
<br />
営み (いとなみ - itonami - work, life) [n] <Play audio="itonami" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>因</span></th>
<td>イン (in)</td>
<td>よ・る (yoru)</td>
<td>
Cause
<br />
<table><tbody><tr><td>Examples:
<br />
原因 (げんいん - gen'in - cause, origin, source) [n] <Play audio="gen-in" />
<br />
因る (よる - yoru - to be based on, to come from) [gv] <Play audio="yoru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>団</span></th>
<td>ダン (dan)<br />トン (ton)</td>
<td></td>
<td>
Group
<br />
<table><tbody><tr><td>Examples:
<br />
団体 (だんたい - dantai - organization, association) [n] <Play audio="dantai" />
<br />
布団 (ふとん - futon - futon (quilted Japanese-style mattress laid out on the floor)) [n] <Play audio="futon" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>圧</span></th>
<td>アツ (atsu)</td>
<td></td>
<td>
Pressure
<br />
<table><tbody><tr><td>Examples:
<br />
圧迫 (あっぱく - appaku - pressure, coercion, oppression) [n] <Play audio="appaku" />
<br />
血圧 (けつあつ - ketsuatsu - blood pressure) [n] <Play audio="ketsuatsu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>在</span></th>
<td>ザイ (zai)</td>
<td>あ・る (aru)</td>
<td>
Exist
<br />
<table><tbody><tr><td>Examples:
<br />
存在 (そんざい - sonzai - existence, being) [n] <Play audio="sonzai" />
<br />
在る (ある - aru - to be (usu. of inanimate objects), to exist, to have, to be located) [gv] <Play audio="aru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>均</span></th>
<td>キン (kin)</td>
<td></td>
<td>
Level
<br />
<table><tbody><tr><td>Examples:
<br />
均等 (きんとう - kintou - equality, uniformity, evenness) [dn] <Play audio="kintou" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>基</span></th>
<td>キ (ki)</td>
<td>もと (moto)<br />もとい (motoi)<br />もと・づく (motozuku)</td>
<td>
Foundation
<br />
<table><tbody><tr><td>Examples:
<br />
基礎 (きそ - kiso - foundation, basis) [n] <Play audio="kiso" />
<br />
基 (もと - moto - base, basis, foundation, root) [n] <Play audio="moto" />
<br />
基 (もとい - motoi - basis, foundation, cause (old)) [n] <Play audio="motoi" />
<br />
基づく (もとづく - motozuku - to be grounded on, to be based on, to be due to, to originate from) [gv] <Play audio="motozuku" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>報</span></th>
<td>ホウ (hou)</td>
<td>むく・いる (mukuiru)<br />むく・い (mukui)</td>
<td>
Report
<br />
<table><tbody><tr><td>Examples:
<br />
報告 (ほうこく - houkoku - report, information) [n] <Play audio="houkoku" />
<br />
報いる (むくいる - mukuiru - to reward, to recompense, to repay) [iv] <Play audio="mukuiru" />
<br />
報い (むくい - mukui - reward, recompense, return, punishment, retribution) [n] <Play audio="mukui" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>境</span></th>
<td>キョウ (kyou)<br />ケイ (kei)</td>
<td>さかい (sakai)</td>
<td>
Boundary
<br />
<table><tbody><tr><td>Examples:
<br />
環境 (かんきょう - kankyou - environment, circumstance) [n] <Play audio="kankyou" />
<br />
境内 (けいだい - keidai - grounds (esp. of shrines and temples), compound, churchyard, precincts) [n] <Play audio="keidai" />
<br />
境 (さかい - sakai - border, boundary) [n] <Play audio="sakai" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>墓</span></th>
<td>ボ (bo)</td>
<td>はか (haka)</td>
<td>
Grave
<br />
<table><tbody><tr><td>Examples:
<br />
墓石 (ぼせき - boseki - tombstone, gravestone) [n] <Play audio="boseki" />
<br />
墓 (はか - haka - gravesite, tomb) [n] <Play audio="haka" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>増</span></th>
<td>ゾウ (zou)</td>
<td>ま・す (masu)<br />ま・し (mashi)<br />ふ・える (fueru)<br />ふ・やす (fuyasu)</td>
<td>
Increase
<br />
<table><tbody><tr><td>Examples:
<br />
急増 (きゅうぞう - kyuuzou - rapid increase, proliferation, surge, explosion) [n] <Play audio="kyuuzou" />
<br />
増す (ます - masu - to increase, to grow) [gv] <Play audio="masu" />
<br />
増し (まし - mashi - better, less objectionable, preferable, more, increase, extra) [dn] <Play audio="mashi" />
<br />
増える (ふえる - fueru - to increase, to multiply) [iv] <Play audio="fueru" />
<br />
増やす (ふやす - fuyasu - to increase, to add to, to augment) [gv] <Play audio="fuyasu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>夢</span></th>
<td>ム (mu)</td>
<td>ゆめ (yume)</td>
<td>
Dream
<br />
<table><tbody><tr><td>Examples:
<br />
悪夢 (あくむ - akumu - nightmare, bad dream) [n] <Play audio="akumu" />
<br />
夢 (ゆめ - yume - dream) [n] <Play audio="yume" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>妻</span></th>
<td>サイ (sai)</td>
<td>つま (tsuma)</td>
<td>
Wife
<br />
<table><tbody><tr><td>Examples:
<br />
夫妻 (ふさい - fusai - man and wife, married couple) [n] <Play audio="fusai" />
<br />
妻 (つま - tsuma - wife) [n] <Play audio="tsuma" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>婦</span></th>
<td>フ (fu)</td>
<td></td>
<td>
Lady
<br />
<table><tbody><tr><td>Examples:
<br />
夫婦 (ふうふ - fuufu - married couple, spouses, husband and wife, couple, pair) [n] <Play audio="fuufu" />
</td></tr></tbody></table></td>
</tr>
</tbody></table>


    </>
  )
}

export default GradeFiveOne