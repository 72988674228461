import React from 'react';
import styled from "styled-components"

export default class Play extends React.Component {

    constructor(props){
      super(props);
    }

    getAudio = (clipUrl) => {
        var audio = new Audio("/audio/" + clipUrl + ".mp3");
        return audio.play();
    };

    render() {
        return (
          <PlayButton onClick={() => this.getAudio(this.props.audio)}>{this.props.audio}
          </PlayButton>
          
        );
    }
}

const PlayButton = styled.span`
  background: #464646;
  color: #fff;
  padding: 2px 7px;
  border-radius:25px;
  line-height:1.8em;
  white-space: nowrap;
  cursor:pointer;
  font-size:.8rem;
  &:before {
    content: '🔊 ';
    font-size: 80%;
  }
  transition: box-shadow 0.3s ease-in-out;
  &:hover {
    box-shadow: 0px 0px 6px 0px rgba(50, 50, 50, 0.61);
  }
`
