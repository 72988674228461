import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Grade 5 Kanji - Part 5 of 5"

const GradeFiveFive = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Advanced" category="Kanji" lesson={title} />
     
      <h1>{title}</h1>

      <p>Below are Kanji the Japanese learn in grade 5 of elementary school.
Be sure to read the <Link to="/members/a/kanji/intro/">Introduction to Kanji</Link> if you haven't already.</p>

<p>Legend:</p>
<p>[n] = Noun</p>
<p>[dv] = Descriptive word (true adjective) - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[dn] = Descriptive noun - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[gv] = Godan verb - see <Link to="/members/b/grammar/godan-verbs/">Verb Conjugation (Godan)</Link></p>
<p>[iv] = Ichidan verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[irv] = Irregular verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[c] = Counter</p>

<table>
<thead>
  <tr>
<th>漢字<br /><Play audio="kanji" /></th>
<th>音読み <Play audio="onyomi" /><br />"On" Reading</th>
<th>訓読み <Play audio="kunyomi" /><br />"Kun" Reading</th>
<th>English Meaning</th>
</tr>
  </thead>
<tbody>
<tr>
<th><span style={{fontSize:`4rem`}}>資</span></th>
<td>シ (shi)</td>
<td></td>
<td>
Resources
<br />
<table><tbody><tr><td>Examples:
<br />
資金 (しきん - shikin - funds, capital) [n] <Play audio="shikin" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>賛</span></th>
<td>サン (san)</td>
<td></td>
<td>
Approve
<br />
<table><tbody><tr><td>Examples:
<br />
賛成 (さんせい - sansei - approval, agreement, support, favor) [n] <Play audio="sansei" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>質</span></th>
<td>シチ (shichi)<br />シツ (shitsu)<br />チ (chi)</td>
<td></td>
<td>
Quality
<br />
<table><tbody><tr><td>Examples:
<br />
人質 (ひとじち - hitojichi - hostage, prisoner) [n] <Play audio="hitojichi" />
<br />
神経質 (しんけいしつ - shinkeishitsu - nervousness, (being) highly strung, sensitiveness, neurotic) [dn] <Play audio="shinkeishitsu" />
<br />
言質 (げんち - genchi - commitment, pledge or promise) [n] <Play audio="genchi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>輸</span></th>
<td>ユ (yu)</td>
<td></td>
<td>
Transport
<br />
<table><tbody><tr><td>Examples:
<br />
輸血 (ゆけつ - yuketsu - blood transfusion) [n] <Play audio="yuketsu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>述</span></th>
<td>ジュツ (jutsu)</td>
<td>の・べる (noberu)<br /></td>
<td>
Mention
<br />
<table><tbody><tr><td>Examples:
<br />
述語 (じゅつご - jutsugo - predicate) [n] <Play audio="jutsugo" />
<br />
述べる (のべる - noberu - to state, to express, to mention) [iv] <Play audio="noberu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>迷</span></th>
<td>メイ (mei)</td>
<td>まよ・う (mayou)</td>
<td>
Astray
<br />
<table><tbody><tr><td>Examples:
<br />
迷路 (めいろ - meiro - maze, labyrinth, blind alley) [n] <Play audio="meiro" />
<br />
迷う (まよう - mayou - to lose one's way, to waver, to hesitate, to be puzzled, to be perplexed, to lose control of oneself) [gv] <Play audio="mayou" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>退</span></th>
<td>タイ (tai)</td>
<td>しりぞ・く (shirizoku)<br />しりぞ・ける (shirizokeru)</td>
<td>
Retreat
<br />
<table><tbody><tr><td>Examples:
<br />
退職 (たいしょく - taishoku - retirement (from office)) [n] <Play audio="taishoku" />
<br />
退く (しりぞく - shirizoku - to step back, to move back, to leave (the presence of a superior), to withdraw, to retreat, to concede, to resign, to retire, to quit) [gv] <Play audio="shirizoku" />
<br />
退ける (しりぞける - shirizokeru - to repel, to drive away, to repulse, to reject) [iv] <Play audio="shirizokeru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>逆</span></th>
<td>ギャク (gyaku)</td>
<td>さか (saka)<br />さか・さ (sakasa)<br />さか・らう (sakarau)</td>
<td>
Inverted
<br />
<table><tbody><tr><td>Examples:
<br />
逆転 (ぎゃくてん - gyakuten - change, reversal, turn-around, coming from behind (baseball)) [n] <Play audio="gyakuten" />
<br />
逆様 (さかさま - sakasama - inversion, upside down) [dn] <Play audio="sakasama" />
<br />
逆さ (さかさ - sakasa - reverse, inversion, upside down) [n] <Play audio="sakasa" />
<br />
逆らう (さからう - sakarau - to go against, to oppose, to disobey, to defy) [gv] <Play audio="sakarau" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>造</span></th>
<td>ゾウ (zou)</td>
<td>つく・る (tsukuru)</td>
<td>
Create
<br />
<table><tbody><tr><td>Examples:
<br />
構造 (こうぞう - kouzou - structure, construction) [n] <Play audio="kouzou" />
<br />
造る (つくる - tsukuru - (usu. for large-scale building, manufacturing, etc.) to make, to produce, to manufacture, to build, to construct) [gv] <Play audio="tsukuru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>過</span></th>
<td>カ (ka)</td>
<td>す・ぎる (sugiru)<br />す・ごす (sugosu)<br />あやま・つ (ayamatsu)<br />あやま・ち (ayamachi)</td>
<td>
Go Beyond
<br />
<table><tbody><tr><td>Examples:
<br />
通過 (つうか - tsuuka - passage through (e.g. train skipping a station), transit, passing (e.g. exam, bill through parliament)) [n] <Play audio="tsuuka" />
<br />
過ぎる (すぎる - sugiru - to pass through, to pass by, to go beyond, to pass (i.e. of time), to elapse, to have expired, to have ended, to be over, to exceed, to surpass) [iv] <Play audio="sugiru" />
<br />
過ごす (すごす - sugosu - to pass/spend (time), to overdo, to do too much) [gv] <Play audio="sugosu" />
<br />
過つ (あやまつ - ayamatsu - to err) [gv] <Play audio="ayamatsu" />
<br />
過ち (あやまち - ayamachi - fault, error, indiscretion, faux pas) [n] <Play audio="ayamachi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>適</span></th>
<td>テキ (teki)</td>
<td></td>
<td>
Suitable
<br />
<table><tbody><tr><td>Examples:
<br />
快適 (かいてき - kaiteki - pleasant, agreeable, comfortable) [dn] <Play audio="kaiteki" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>酸</span></th>
<td>サン (san)</td>
<td>す・い (sui)</td>
<td>
Acid
<br />
<table><tbody><tr><td>Examples:
<br />
酸味 (さんみ - sanmi - sourness, acidity) [n] <Play audio="sanmi" />
<br />
酸い (すい - sui - sour, acid) [dv] <Play audio="sui" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>鉱</span></th>
<td>コウ (kou)</td>
<td></td>
<td>
Mineral
<br />
<table><tbody><tr><td>Examples:
<br />
鉱物 (こうぶつ - koubutsu - mineral) [n] <Play audio="koubutsu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>銅</span></th>
<td>ドウ (dou)</td>
<td></td>
<td>
Copper
<br />
<table><tbody><tr><td>Examples:
<br />
銅 (どう - dou - copper) [n] <Play audio="dou" />
<br />
銅貨 (どうか - douka - copper coin) [n] <Play audio="douka" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>銭</span></th>
<td>セン (sen)</td>
<td>ぜに (zeni)</td>
<td>
Coin
<br />
<table><tbody><tr><td>Examples:
<br />
金銭 (きんせん - kinsen - money, cash) [n] <Play audio="kinsen" />
<br />
銭 (ぜに - zeni - round coin with a (square) hole in the center, coin made of non-precious materials, money) [n] <Play audio="zeni" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>防</span></th>
<td>ボウ (bou)</td>
<td>ふせ・ぐ (fusegu)</td>
<td>
Prevent
<br />
<table><tbody><tr><td>Examples:
<br />
防御 (ぼうぎょ - bougyo - defense, safeguard, protection) [n] <Play audio="bougyo" />
<br />
防ぐ (ふせぐ - fusegu - to defend (against), to protect, to prevent) [gv] <Play audio="fusegu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>限</span></th>
<td>ゲン (gen)</td>
<td>かぎ・る (kagiru)<br />かぎ・り (kagiri)</td>
<td>
Limit
<br />
<table><tbody><tr><td>Examples:
<br />
限定 (げんてい - gentei - limit, restriction) [n] <Play audio="gentei" />
<br />
限る (かぎる - kagiru - to restrict, to limit, to confine, to be restricted to, to be limited to, to be confined to) [gv] <Play audio="kagiru" />
<br />
限り (かぎり - kagiri - limit, limits, bounds, degree, extent, scope, as far as possible, as much as possible, to the best of one's ability, very much) [n] <Play audio="kagiri" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>険</span></th>
<td>ケン (ken)</td>
<td>けわ・しい (kewashii)</td>
<td>
Precipitous
<br />
<table><tbody><tr><td>Examples:
<br />
危険 (きけん - kiken - danger, peril, hazard, dangerous, hazardous) [dn] <Play audio="kiken" />
<br />
険しい (けわしい - kewashii - precipitous, rugged, inaccessible, impregnable, steep, grim, severe, stern) [dv] <Play audio="kewashii" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>際</span></th>
<td>サイ (sai)</td>
<td>きわ (kiwa)</td>
<td>
Occasion
<br />
<table><tbody><tr><td>Examples:
<br />
実際 (じっさい - jissai - practicality, practical, reality, actuality, actual conditions) [n] <Play audio="jissai" />
<br />
際 (きわ - kiwa - edge, brink, verge, side, time, moment of) [n] <Play audio="kiwa" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>雑</span></th>
<td>ザツ (zatsu)<br />ゾウ (zou)</td>
<td></td>
<td>
Miscellaneous
<br />
<table><tbody><tr><td>Examples:
<br />
複雑 (ふくざつ - fukuzatsu - complex, complicated, mixed (feelings)) [dn] <Play audio="fukuzatsu" />
<br />
雑言 (ぞうごん - zougon - foul language, abuse) [n] <Play audio="zougon" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>非</span></th>
<td>ヒ (hi)</td>
<td>あら・ず (arazu)</td>
<td>
Negative
<br />
<table><tbody><tr><td>Examples:
<br />
非難 (ひなん - hinan - criticism, blame, censure, attack, reproach) [dn] <Play audio="hinan" />
<br />
非ず (あらず - arazu - no, never mind) <Play audio="arazu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>預</span></th>
<td>ヨ (yo)</td>
<td>あず・ける (azukeru)<br />あず・かる (azukaru)</td>
<td>
Deposit
<br />
<table><tbody><tr><td>Examples:
<br />
預金 (よきん - yokin - deposit, bank account) [n] <Play audio="yokin" />
<br />
預ける (あずける - azukeru - to give into custody, to leave (a child) in the care of, to entrust, to deposit, to lean on, to put one's weight on) [iv] <Play audio="azukeru" />
<br />
預かる (あずかる - azukaru - to look after, to take care of, to keep, to hold on to, to keep in custody, to take charge of, to be entrusted with, to receive on deposit, to reserve (judgment), to leave undecided) [gv] <Play audio="azukaru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>領</span></th>
<td>リョウ (ryou)</td>
<td></td>
<td>
Territory
<br />
<table><tbody><tr><td>Examples:
<br />
大統領 (だいとうりょう - daitouryou - president, chief executive) [n] <Play audio="daitouryou" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>額</span></th>
<td>ガク (gaku)</td>
<td>ひたい (hitai)</td>
<td>
Amount
<br />
<table><tbody><tr><td>Examples:
<br />
金額 (きんがく - kingaku - amount of money) [n] <Play audio="kingaku" />
<br />
額 (ひたい - hitai - forehead, brow) [n] <Play audio="hitai" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>飼</span></th>
<td>シ (shi)</td>
<td>か・う (kau)</td>
<td>
Domesticate
<br />
<table><tbody><tr><td>Examples:
<br />
飼育 (しいく - shiiku - breeding, raising, rearing) [n] <Play audio="shiiku" />
<br />
飼う (かう - kau - to keep (a pet or other animal), to raise, to have, to own, to feed) [gv] <Play audio="kau" />
</td></tr></tbody></table></td>
</tr>
</tbody></table>

    </>
  )
}

export default GradeFiveFive