import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"

const title = "Using the Verb Bases Part 1"

const basesone = [
  {"ひらがな - Hiragana":"Base 1 + ない","Romaji (English Letters)":"Base 1 + nai","English Meaning":"plain form negative (will not verb)","audio":"nai"},
 {"ひらがな - Hiragana":"Base 1 + なかった","Romaji (English Letters)":"Base 1 + nakatta","English Meaning":"plain form past negative (did not verb)","audio":"nakatta"},
 {"ひらがな - Hiragana":"Base 2 + ます","Romaji (English Letters)":"Base 2 + masu","English Meaning":"polite form present/future tense","audio":"masu"},
 {"ひらがな - Hiragana":"Base 2 + ました","Romaji (English Letters)":"Base 2 + mashita","English Meaning":"polite form past tense","audio":"mashita"},
 {"ひらがな - Hiragana":"Base 2 + ません","Romaji (English Letters)":"Base 2 + masen","English Meaning":"polite form negative (will not verb)","audio":"masen"},
 {"ひらがな - Hiragana":"Base 2 + ませんでした","Romaji (English Letters)":"Base 2 + masen deshita","English Meaning":"polite form past negative (did not verb)","audio":"masen deshita"},
 {"ひらがな - Hiragana":"Base 2 + たい","Romaji (English Letters)":"Base 2 + tai","English Meaning":"want to verb (add です (desu)  to make it polite)","audio":"tai"},
 {"ひらがな - Hiragana":"Base 3","Romaji (English Letters)":"Base 3","English Meaning":"plain form present/future tense"},
 {"ひらがな - Hiragana":"Base 4 + ば","Romaji (English Letters)":"Base 4 + ba","English Meaning":"if verb","audio":"ba"},
 {"ひらがな - Hiragana":"Base 4 + る","Romaji (English Letters)":"Base 4 + ru","English Meaning":"plain form can verb (Godan verbs only)(verb now becomes an Ichidan verb)","audio":"ru"},
 {"ひらがな - Hiragana":"Base 4 + ます","Romaji (English Letters)":"Base 4 + masu","English Meaning":"polite form can verb (can be changed like above)","audio":"masu"},
 {"ひらがな - Hiragana":"Base 5 + とする","Romaji (English Letters)":"Base 5 + to suru","English Meaning":"try to verb(this suru  is the same verb learned in Verb Conjugation (Ichidan and Irregular) (to do something))","audio":"to suru"},
 {"ひらがな - Hiragana":"Base \"te\" + 欲しい","Romaji (English Letters)":"Base \"te\" + hoshii","English Meaning":"want someone else to verb(hoshii  is an adjective which will be covered in the Descriptive Words - Part 1 lesson)","audio":"hoshii"},
 {"ひらがな - Hiragana":"Base \"te\"","Romaji (English Letters)":"Base \"te\"","English Meaning":"plain form command","audio":"te"},
 {"ひらがな - Hiragana":"Base \"te\" + 下さい","Romaji (English Letters)":"Base \"te\" + kudasai","English Meaning":"polite form command","audio":"kudasai"},
 {"ひらがな - Hiragana":"Base \"te\" + いる","Romaji (English Letters)":"Base \"te\" + iru","English Meaning":"plain form presently verbing(this iru  is the same verb learned in Verb Conjugation (Ichidan and Irregular) (to exist (animate))","audio":"iru"},
 {"ひらがな - Hiragana":"Base \"te\" + います","Romaji (English Letters)":"Base \"te\" + imasu","English Meaning":"polite form presently verbing (can be changed like above)","audio":"imasu"},
 {"ひらがな - Hiragana":"Base \"ta\"","Romaji (English Letters)":"Base \"ta\"","English Meaning":"plain form past tense","audio":"ta"},
 {"ひらがな - Hiragana":"Base \"ta\" + ら","Romaji (English Letters)":"Base \"ta\" + ra","English Meaning":"if and when I verb (similar to Base 4 + ba)","audio":"ra"},
 {"ひらがな - Hiragana":"Base \"ta\" + りする","Romaji (English Letters)":"Base \"ta\" + ri suru","English Meaning":"do such things as...(this suru  is also the same verb learned in Verb Conjugation (Ichidan and Irregular) (to do something))","audio":"ri suru"}
]

const BasesOne = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Beginner" category="Grammar" lesson={title} />
     <div className={'title'}>
     {title}
      </div>
      <h1>{title}</h1>

      <p>Now that you know about 30 Japanese verbs and can conjugate them, I'll show you what you can do with those verb bases. You may want to regularly refer to <Link to="/members/b/grammar/godan-verbs/">Verb Conjugation (Godan)</Link> and <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link> while learning these. The following chart applies to all Japanese verbs unless otherwise noted.</p>

      <Table data={basesone} />

    </>
  )
}

export default BasesOne