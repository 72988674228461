import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Basic Grammar"

const basicgrammar = [
  {"漢字 - Kanji": "", "ひらがな - Hiragana": "", "Romaji (English Letters)": "", "English Meaning": "", "audio": ""}
]

const BasicGrammar = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Beginner" category="Grammar" lesson={title} />
     <div className={'title'}>
     {title}
      </div>
      <h1>{title}</h1>

      <p>Japanese grammar is quite simple and straight forward but very different from English grammar so most English speakers find it rather confusing. For instance, in Japanese the verb always comes at the end. The best thing you can do when learning Japanese is to learn it from the bottom up and not compare it to English grammar.</p>

<p>The Japanese language uses what we will refer to as particles to mark the various parts of the sentence. The main particles are: (These particles will be described in more detail below)</p>

<table>
<tbody><tr>
<td align="center" bgcolor="white" width="25%">は</td>
<td>wa <Play audio="wa" /><br />(as mentioned in <Link to="/members/b/basics/hiragana/">the Hiragana lesson</Link>, the Hiragana "ha" is pronounced "wa" when it immediately follows the topic)</td>
<td>topic marker</td>
</tr>
<tr>
<td>が</td>
<td>ga <Play audio="ga" /></td>
<td>subject marker</td>
</tr>
<tr>
<td>を</td>
<td>wo <Play audio="o" /><br />(pronounced "o")</td>
<td>direct object marker</td>
</tr>
<tr>
<td>に</td>
<td>ni <Play audio="ni" /></td>
<td>direction marker, time marker, indirect object marker</td>
</tr>
<tr>
<td>へ</td>
<td>e <Play audio="e" /><br />(as mentioned in <Link to="/members/b/basics/hiragana/">the Hiragana lesson</Link>, the Hiragana "he" is pronounced "e" when it immediately follows a place or direction)</td>
<td>direction marker</td>
</tr>
</tbody></table>


<p><strong>The particles "wa" (は) and "ga" (が):</strong> The particle "wa" marks the topic of the sentence and the particle "ga" marks the subject of the sentence. In the example, "I know where you live" <Play audio="watashi wa anata ga doko ni sunde iru ka shitte iru" />, "I" would be the topic while "you" would be the subject.</p>

<p>Not all Japanese sentences have both a topic and subject and, in many cases, the topic is implied in Japanese (for example, the "I" <Play audio="watashi wa" /> would be left out of this sentence because it is implied that since I am talking I am the one that knows where you live). Many Japanese books and teachers teach that "wa" and "ga" are the same thing and it doesn't matter which you use when. This is not the case but I wouldn't worry too much about keeping them straight at first - it will come with time.</p>

<p><strong>The particle "wo" (を):</strong> The particle "wo" (or "o") marks the direct object of a Japanese sentence. In the example, "I'm going to take her home" <Play audio="watashi wa kanojo o ie ni okuru" />, "her" would be the direct object.</p>

<p><strong>The particle "ni" (に):</strong> The particle "ni" can be used to mark the direction, time, or the indirect object of a Japanese sentence.</p>

<p>An example of a direction marker can be seen in the previous example "I'm going to take her home" <Play audio="watashi wa kanojo o ie ni okuru" />. In this case, the "ni" acts like a "to" - "I'm going to take her 'to' home". The particle "e" (へ) can be used in this way as well but usually implies more of a general direction as opposed to a specific place.</p>

<p>The particle "ni" is also used to mark time in a Japanese sentence. For example, "I'm leaving at 3 o'clock" <Play audio="watashi wa sanji ni hanareru" />.</p>

<p>The final use for the particle "ni" in Japanese grammar is that of indirect object marker. In the example, "I was taken home by him" <Play audio="watashi wa kare ni ie made okurareta" />, "him" is the indirect object.</p>


<p><strong>Note:</strong> The <Play audio="watashi wa" /> in all of the example sentences used above would normally be left out as it is implied.</p>

    </>
  )
}

export default BasicGrammar