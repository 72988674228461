import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Grade 2 Kanji  - Part 2 of 4"

const GradeTwoTwo = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Advanced" category="Kanji" lesson={title} />

      <h1>{title}</h1>

      <p>Below are Kanji the Japanese learn in grade 2 of elementary school.
Be sure to read the <Link to="/members/a/kanji/intro/">Introduction to Kanji</Link> if you haven't already.</p>

<p><strong>Legend:</strong></p>
<p>[n] = Noun</p>
<p>[dv] = Descriptive word (true adjective) - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[dn] = Descriptive noun - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[gv] = Godan verb - see <Link to="/members/b/grammar/godan-verbs/">Verb Conjugation (Godan)</Link></p>
<p>[iv] = Ichidan verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[irv] = Irregular verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[c] = Counter</p>

<table>
<thead>
  <tr>
<th>漢字<br /><Play audio="kanji" /></th>
<th>音読み <Play audio="onyomi" /><br />"On" Reading</th>
<th>訓読み <Play audio="kunyomi" /><br />"Kun" Reading</th>
<th>English Meaning</th>
</tr>
  </thead>
<tbody>
<tr>
<th><span style={{fontSize:`4rem`}}>朝</span></th>
<td>チョウ (chou)</td>
<td>あさ (asa)</td>
<td>
Morning
<br />
<table><tbody><tr><td>Examples:
<br />
朝食 (ちょうしょく - choushoku - breakfast) [n] <Play audio="choushoku" />
<br />
朝ご飯 (あさごはん - asagohan - breakfast) [n] <Play audio="asagohan" />
<br />
朝 (あさ - asa - morning) [n] <Play audio="asa" />
<br />
今朝 (けさ - kesa - this morning) [n] <Play audio="kesa" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>昼</span></th>
<td>チュウ (chuu)</td>
<td>ひる (hiru)</td>
<td>
Daytime
<br />
<table><tbody><tr><td>Examples:
<br />
昼食 (ちゅうしょく - chuushoku - lunch) [n] <Play audio="chuushoku" />
<br />
昼ご飯 (ひるごはん - hirugohan - lunch) [n] <Play audio="hirugohan" />
<br />
昼 (ひる - hiru - daytime, midday) [n] <Play audio="hiru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>夜</span></th>
<td>ヤ (ya)</td>
<td>よ (yo)<br />よる (yoru)</td>
<td>
Night
<br />
<table><tbody><tr><td>Examples:
<br />
夜食 (やしょく - yashoku - dinner) [n] <Play audio="yashoku" />
<br />
夜ご飯 (よるごはん - yorugohan - dinner) [n] <Play audio="yorugohan" />
<br />
月夜 (つきよ - tsukiyo - moonlit night) [n] <Play audio="tsukiyo" />
<br />
夜中 (よなか - yonaka - midnight, dead of night) [n] <Play audio="yonaka" />
<br />
夜 (よる - yoru - night) [n] <Play audio="yoru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>分</span></th>
<td>ブン (bun)<br />フン (fun)</td>
<td>わ・ける (wakeru)<br />わ・かれる (wakareru)<br />わ・かる (wakaru)<br />わ・かつ (wakatsu)</td>
<td>
Minute, Understand
<br />
<table><tbody><tr><td>Examples:
<br />
部分 (ぶぶん - bubun - portion, section, part) [n] <Play audio="bubun" />
<br />
一分 (いっぷん - ippun - one minute) [n] <Play audio="ippun" />
<br />
二分 (にふん - nifun - two minutes (also "fun" for 5, 7 (nana), 8, 9)) [n] <Play audio="nifun" />
<br />
三分 (さんぷん - sanpun - three minutes (also "pun" for 4 (yon))) [n] <Play audio="sanpun" />
<br />
六分 (ろっぷん - roppun - six minutes) [n] <Play audio="roppun" />
<br />
十分 (じゅっぷん - juppun - ten minutes) [n] <Play audio="juppun" />
<br />
分ける (わける - wakeru - to divide, to separate, to make distinctions, to differentiate between) [iv] <Play audio="wakeru" />
<br />
分かれる (わかれる - wakareru - to separate, to split, to divide, to disperse, to scatter) [iv] <Play audio="wakareru" />
<br />
分かる (わかる - wakaru - to be understood, to be known, to be realized) [gv] <Play audio="wakaru" />
<br />
分かつ (わかつ - wakatsu - to divide, to separate, to distribute, to distinguish) [gv] <Play audio="wakatsu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>週</span></th>
<td>シュウ (shuu)</td>
<td></td>
<td>
Week
<br />
<table><tbody><tr><td>Examples:
<br />
一週間 (いっしゅうかん - isshuukan - one week) [n] <Play audio="isshuukan" />
<br />
今週 (こんしゅう - konshuu - this week) [n] <Play audio="konshuu" />
<br />
先週 (せんしゅう - senshuu - last week) [n] <Play audio="senshuu" />
<br />
来週 (らいしゅう - raishuu - next week) [n] <Play audio="raishuu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>春</span></th>
<td>シュン (shun)</td>
<td>はる (haru)</td>
<td>
Spring
<br />
<table><tbody><tr><td>Examples:
<br />
春分 (しゅんぶん - shunbun - vernal equinox (the beginning of spring)) [n] <Play audio="shunbun" />
<br />
春 (はる - haru - spring, springtime) [n] <Play audio="haru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>夏</span></th>
<td>ゲ (ge)<br />カ (ka)</td>
<td>なつ (natsu)</td>
<td>
Summer
<br />
<table><tbody><tr><td>Examples:
<br />
夏至 (げし - geshi - summer solstice (the beginning of summer)) [n] <Play audio="geshi" />
<br />
初夏 (しょか - shoka - early summer) [n] <Play audio="shoka" />
<br />
夏 (なつ - natsu - summer) [n] <Play audio="natsu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>秋</span></th>
<td>シュウ (shuu)</td>
<td>あき (aki)</td>
<td>
Autumn
<br />
<table><tbody><tr><td>Examples:
<br />
秋分 (しゅうぶん - shuubun - autumn equinox (the beginning of autumn)) [n] <Play audio="shuubun" />
<br />
秋 (あき - aki - autumn) [n] <Play audio="aki" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>冬</span></th>
<td>トウ (tou)</td>
<td>ふゆ (fuyu)</td>
<td>
Winter
<br />
<table><tbody><tr><td>Examples:
<br />
冬眠 (とうみん - toumin - hibernation) [n] <Play audio="toumin" />
<br />
冬 (ふゆ - fuyu - winter) [n] <Play audio="fuyu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>今</span></th>
<td>コン (kon)<br />キン (kin - rarely used)</td>
<td>いま (ima)</td>
<td>
Now
<br />
<table><tbody><tr><td>Examples:
<br />
今度 (こんど - kondo - this time, next time, another time) [n] <Play audio="kondo" />
<br />
今 (いま - ima - now, the present time, immediately) [n] <Play audio="ima" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>新</span></th>
<td>シン (shin)</td>
<td>あたら・しい (atarashii)<br />あら (ara)<br />あら・た (arata)<br />にい (nii)</td>
<td>
New
<br />
<table><tbody><tr><td>Examples:
<br />
新聞紙 (しんぶんし - shinbunshi - newspaper) [n] <Play audio="shinbunshi" />
<br />
新しい (あたらしい - atarashii - new) [dv] <Play audio="atarashii" />
<br />
新手 (あらて - arate - fresh supply of troops, newcomer, new method) [n] <Play audio="arate" />
<br />
新た (あらた - arata - new, fresh) [dn] <Play audio="arata" />
<br />
新妻 (にいづま - niizuma - new wife) [n] <Play audio="niizuma" />
<br />
新潟 (にいがた - niigata - Niigata city (Niigata-shi), Niigata prefecture (Niigata-ken)) [n] <Play audio="niigata" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>古</span></th>
<td>コ (ko)</td>
<td>ふる (furu)<br />ふる・い (furui)<br />ふる・す (furusu)<br />ふる・びる (furubiru)</td>
<td>
Old
<br />
<table><tbody><tr><td>Examples:
<br />
考古学 (こうこがく - koukogaku - archaeology) [n] <Play audio="koukogaku" />
<br />
古本屋 (ふるほんや - furuhonya - second hand bookstore) [n] <Play audio="furuhonya" />
<br />
古い (ふるい - furui - old (not for people)) [dv] <Play audio="furui" />
<br />
古す (ふるす - furusu - to wear out) [gv] <Play audio="furusu" />
<br />
古びる (ふるびる - furubiru - to look old, to get old (not for people)) [iv] <Play audio="furubiru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>間</span></th>
<td>ケン (ken)<br />カン (kan)</td>
<td>あいだ (aida)<br />ま (ma)</td>
<td>
Interval
<br />
<table><tbody><tr><td>Examples:
<br />
世間 (せけん - seken - world, society) [n] <Play audio="seken" />
<br />
時間 (じかん - jikan - time, hours) [n] <Play audio="jikan" />
<br />
間 (あいだ - aida - space (between), gap, interval, time (between), pause, break) [n] <Play audio="aida" />
<br />
隙間 (すきま - sukima - crevice, crack, gap, opening) [n] <Play audio="sukima" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>方</span></th>
<td>ホウ (hou)</td>
<td>かた (kata)</td>
<td>
Direction
<br />
<table><tbody><tr><td>Examples:
<br />
方法 (ほうほう - houhou - method, process, manner, way, means, technique) [n] <Play audio="houhou" />
<br />
御方 (おかた - okata - this lady or gentleman) [n] <Play audio="okata" />
<br />
貴方 (あなた - anata - you (kanji rarely used)) [n] <Play audio="anata" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>北</span></th>
<td>ホク (hoku)</td>
<td>きた (kita)</td>
<td>
North
<br />
<table><tbody><tr><td>Examples:
<br />
北斗星 (ほくとせい - hokutosei - the Big Dipper) [n] <Play audio="hokutosei" />
<br />
北 (きた - kita - north) [n] <Play audio="kita" />
<br />
北風 (きたかぜ - kitakaze - north wind) [n] <Play audio="kitakaze" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>南</span></th>
<td>ナン (nan)<br />ナ (na - rarely used)</td>
<td>みなみ (minami)</td>
<td>
South
<br />
<table><tbody><tr><td>Examples:
<br />
南極 (なんきょく - nankyoku - South Pole) [n] <Play audio="nankyoku" />
<br />
南 (みなみ - minami - south) [n] <Play audio="minami" />
<br />
南アメリカ (みなみアメリカ - minami amerika - South America) [n] <Play audio="minami amerika" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>東</span></th>
<td>トウ (tou)</td>
<td>ひがし (higashi)<br />あずま (azuma - old reading)</td>
<td>
East
<br />
<table><tbody><tr><td>Examples:
<br />
東京 (とうきょう - toukyou - Tokyo) [n] <Play audio="toukyou" />
<br />
東 (ひがし - higashi - east) [n] <Play audio="higashi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>西</span></th>
<td>サイ (sai)<br />セイ (sei)</td>
<td>にし (nishi)</td>
<td>
West
<br />
<table><tbody><tr><td>Examples:
<br />
関西 (かんさい - kansai - Kansai (south-western half of Japan - including Osaka and Kobe)) [n] <Play audio="kansai" />
<br />
西暦 (せいれき - seireki - Christian Era, CE, anno domini, AD) [n] <Play audio="seireki" />
<br />
西 (にし - nishi - west) [n] <Play audio="nishi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>遠</span></th>
<td>オン (on - rarely used)<br />エン (en)</td>
<td>とお・い (tooi)<br />とお・ざける (toozakeru)</td>
<td>
Far
<br />
<table><tbody><tr><td>Examples:
<br />
遠足 (えんそく - ensoku - excursion, outing, trip) [n] <Play audio="ensoku" />
<br />
遠い (とおい - tooi - far, distant) [dv] <Play audio="tooi" />
<br />
遠ざける (とおざける - toozakeru - to keep away, to keep at a distance) [iv] <Play audio="toozakeru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>近</span></th>
<td>キン (kin)</td>
<td>ちか・い (chikai)<br />ちか・づく (chikazuku)<br />ちか・しい (chikashii)</td>
<td>
Near
<br />
<table><tbody><tr><td>Examples:
<br />
近所 (きんじょ - kinjo - neighborhood) [n] <Play audio="kinjo" />
<br />
近い (ちかい - chikai - near, close) [dv] <Play audio="chikai" />
<br />
近づく (ちかづく - chikazuku - to approach, to draw near, to go near) [gv] <Play audio="chikazuku" />
<br />
近しい (ちかしい - chikashii - intimate, close) [dv] <Play audio="chikashii" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>前</span></th>
<td>ゼン (zen)</td>
<td>まえ (mae)</td>
<td>
In Front
<br />
<table><tbody><tr><td>Examples:
<br />
前進 (ぜんしん - zenshin - progress) [n] <Play audio="zenshin" />
<br />
前 (まえ - mae - ago, previously, in front (of), before, fore part) [n] <Play audio="mae" />
<br />
前歯 (まえば - maeba - front tooth/teeth) [n] <Play audio="maeba" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>後</span></th>
<td>ゴ (go)<br />コウ (kou)</td>
<td>のち (nochi)<br />うし・ろ (ushiro)<br />あと (ato)<br />おく・れる (okureru)</td>
<td>
Behind
<br />
<table><tbody><tr><td>Examples:
<br />
午後 (ごご - gogo - afternoon, p.m.) [n] <Play audio="gogo" />
<br />
後方 (こうほう - kouhou - behind, in the rear, in back) [n] <Play audio="kouhou" />
<br />
後程 (のちほど - nochihodo - later on, eventually, afterwards) [n] <Play audio="nochihodo" />
<br />
後ろ (うしろ - ushiro - back, behind, rear) [n] <Play audio="ushiro" />
<br />
その後 (そのあと - sono ato - after that, afterwards, thereafter) [n] <Play audio="sono ato" />
<br />
後れる (おくれる - okureru - to be late, to be delayed, to fall behind schedule, to be overdue) [iv] <Play audio="okureru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>内</span></th>
<td>ナイ (nai)<br />ダイ (dai)</td>
<td>うち (uchi)</td>
<td>
Inside
<br />
<table><tbody><tr><td>Examples:
<br />
内容 (ないよう - naiyou - contents, substance, detail) [n] <Play audio="naiyou" />
<br />
内裏 (だいり - dairi - imperial palace) [n] <Play audio="dairi" />
<br />
内 (うち - uchi - inside, within, while, among, amongst, between) [n] <Play audio="uchi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>外</span></th>
<td>ゲ (ge)<br />ガイ (gai)</td>
<td>そと (soto)<br />ほか (hoka - rarely used)<br />はず・す (hazusu)<br />はず・れる (hazureru)</td>
<td>
Outside
<br />
<table><tbody><tr><td>Examples:
<br />
外科医 (げかい - gekai - surgeon) [n] <Play audio="gekai" />
<br />
外人 (がいじん - gaijin - gaijin) [n] <Play audio="gaijin" />
<br />
外 (そと - soto - outside, exterior) [n] <Play audio="soto" />
<br />
外す (はずす - hazusu - to unfasten, to undo, to remove) [gv] <Play audio="hazusu" />
<br />
外れる (はずれる - hazureru - to be disconnected, to get out of place) [iv] <Play audio="hazureru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>場</span></th>
<td>ジョウ (jou)</td>
<td>ば (ba)</td>
<td>
Place
<br />
<table><tbody><tr><td>Examples:
<br />
劇場 (げきじょう - gekijou - theater, playhouse) [n] <Play audio="gekijou" />
<br />
場所 (ばしょ - basho - place, location) [n] <Play audio="basho" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>地</span></th>
<td>ジ (ji)<br />チ (chi)</td>
<td></td>
<td>
Ground
<br />
<table><tbody><tr><td>Examples:
<br />
地震　(じしん - jishin - earthquake) [n] <Play audio="jishin" />
<br />
地球 (ちきゅう - chikyuu - the earth) [n] <Play audio="chikyuu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>国</span></th>
<td>コク (koku)</td>
<td>くに (kuni)</td>
<td>
Country
<br />
<table><tbody><tr><td>Examples:
<br />
国際 (こくさい - kokusai - international) [n] <Play audio="kokusai" />
<br />
国 (くに - kuni - country) [n] <Play audio="kuni" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>園</span></th>
<td>エン (en)</td>
<td>その (sono)</td>
<td>
Garden
<br />
<table><tbody><tr><td>Examples:
<br />
公園 (こうえん - kouen - park) [n] <Play audio="kouen" />
<br />
園　(その - sono - garden, orchard, plantation) [n] <Play audio="sono" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>谷</span></th>
<td>コク (koku)</td>
<td>たに (tani)</td>
<td>
Valley
<br />
<table><tbody><tr><td>Examples:
<br />
峡谷 (きょうこく - kyoukoku - ravine, gorge, canyon) [n] <Play audio="kyoukoku" />
<br />
谷間 (たにま - tanima - valley, ravine, chasm, cleavage) [n] <Play audio="tanima" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>野</span></th>
<td>ヤ (ya)</td>
<td>の (no)</td>
<td>
Field
<br />
<table><tbody><tr><td>Examples:
<br />
野球 (やきゅう - yakyuu - baseball (the sport)) [n] <Play audio="yakyuu" />
<br />
野原 (のはら - nohara - field) [n] <Play audio="nohara" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>原</span></th>
<td>ゲン (gen)</td>
<td>はら (hara)</td>
<td>
Field, Origin
<br />
<table><tbody><tr><td>Examples:
<br />
原因 (げんいん - genin (gen'in) - cause, origin, source) [n] <Play audio="gen-in" />
<br />
野原 (のはら - nohara - field) [n] <Play audio="nohara" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>里</span></th>
<td>リ (ri)</td>
<td>さと (sato)</td>
<td>
Village
<br />
<table><tbody><tr><td>Examples:
<br />
郷里 (きょうり - kyouri - birth-place, home town) [n] <Play audio="kyouri" />
<br />
古里 (ふるさと - furusato - home town, birthplace, historic village) [n] <Play audio="furusato" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>市</span></th>
<td>シ (shi)</td>
<td>いち (ichi)</td>
<td>
City
<br />
<table><tbody><tr><td>Examples:
<br />
市長 (しちょう - shichou - mayor) [n] <Play audio="shichou" />
<br />
朝市 (あさいち - asaichi - morning market) [n] <Play audio="asaichi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>京</span></th>
<td>キョウ (kyou)<br />ケイ (kei)</td>
<td></td>
<td>
Capital
<br />
<table><tbody><tr><td>Examples:
<br />
東京 (とうきょう - toukyou - Tokyo) [n] <Play audio="toukyou" />
<br />
京都 (きょうと - kyouto - Kyoto) [n] <Play audio="kyouto" />
<br />
京阪神 (けいはんしん - keihanshin - Kyoto-Osaka-Kobe) [n] <Play audio="keihanshin" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>風</span></th>
<td>フウ (fuu)<br />フ (fu)</td>
<td>かぜ (kaze)<br />かざ (kaza)</td>
<td>
Wind
<br />
<table><tbody><tr><td>Examples:
<br />
風潮 (ふうちょう - fuuchou - tide, current, tendency) [n] <Play audio="fuuchou" />
<br />
風呂 (ふろ - furo - bath, bathtub) [n] <Play audio="furo" />
<br />
風 (かぜ - kaze - wind, breeze) [n] <Play audio="kaze" />
<br />
風邪 (かぜ - kaze - cold (illness), common cold) [n] <Play audio="kaze" />
<br />
風車 (かざぐるま - kazaguruma - windmill, pinwheel) [n] <Play audio="kazaguruma" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>雪</span></th>
<td>セツ (setsu)</td>
<td>ゆき (yuki)</td>
<td>
Snow
<br />
<table><tbody><tr><td>Examples:
<br />
雪辱 (せつじょく - setsujoku - vindication of honor, revenge) [n] <Play audio="setsujoku" />
<br />
雪 (ゆき - yuki - snow) [n] <Play audio="yuki" />
<br />
雪達磨 (ゆきだるま - yukidaruma - snowman) [n] <Play audio="yukidaruma" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>雲</span></th>
<td>ウン (un)</td>
<td>くも (kumo)</td>
<td>
Cloud
<br />
<table><tbody><tr><td>Examples:
<br />
雷雲 (らいうん - raiun - thunder cloud) [n] <Play audio="raiun" />
<br />
雲 (くも - kumo - cloud) [n] <Play audio="kumo" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>池</span></th>
<td>チ (chi)</td>
<td>いけ (ike)</td>
<td>
Pond
<br />
<table><tbody><tr><td>Examples:
<br />
電池 (でんち - denchi - battery) [n] <Play audio="denchi" />
<br />
池 (いけ - ike - pond) [n] <Play audio="ike" />
<br />
溜池 (ためいけ - tameike - reservoir, pond) [n] <Play audio="tameike" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>海</span></th>
<td>カイ (kai)</td>
<td>うみ (umi)</td>
<td>
Sea
<br />
<table><tbody><tr><td>Examples:
<br />
海賊 (かいぞく - kaizoku - pirate) [n] <Play audio="kaizoku" />
<br />
海 (うみ - umi - sea, ocean) [n] <Play audio="umi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>岩</span></th>
<td>ガン (gan)</td>
<td>いわ (iwa)</td>
<td>
Rock
<br />
<table><tbody><tr><td>Examples:
<br />
溶岩 (ようがん - yougan - lava) [n] <Play audio="yougan" />
<br />
岩 (いわ - iwa - rock, crag) [n] <Play audio="iwa" />
</td></tr></tbody></table></td>
</tr>
</tbody></table>

    </>
  )
}

export default GradeTwoTwo