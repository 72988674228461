import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Grade 4 Kanji - Part 3 of 5"

const GradeFourThree = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Advanced" category="Kanji" lesson={title} />
     
      <h1>{title}</h1>

      <p>Below are Kanji the Japanese learn in grade 4 of elementary school.
Be sure to read the <Link to="/members/a/kanji/intro/">Introduction to Kanji</Link> if you haven't already.</p>

<p>Legend:</p>
<p>[n] = Noun</p>
<p>[dv] = Descriptive word (true adjective) - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[dn] = Descriptive noun - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[gv] = Godan verb - see <Link to="/members/b/grammar/godan-verbs/">Verb Conjugation (Godan)</Link></p>
<p>[iv] = Ichidan verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[irv] = Irregular verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[c] = Counter</p>

<table>
<thead>
  <tr>
<th>漢字<br /><Play audio="kanji" /></th>
<th>音読み <Play audio="onyomi" /><br />"On" Reading</th>
<th>訓読み <Play audio="kunyomi" /><br />"Kun" Reading</th>
<th>English Meaning</th>
</tr>
  </thead>
<tbody>
<tr>
<th><span style={{fontSize:`4rem`}}>念</span></th>
<td>ネン (nen)</td>
<td></td>
<td>
Thought
<br />
<table><tbody><tr><td>Examples:
<br />
残念 (ざんねん - zannen - deplorable, bad luck, regret, disappointment, "That's too bad") [dn] <Play audio="zannen" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>愛</span></th>
<td>アイ (ai)</td>
<td></td>
<td>
Love
<br />
<table><tbody><tr><td>Examples:
<br />
愛 (あい - ai - love, affection) [n] <Play audio="ai" />
<br />
愛車 (あいしゃ - aisha - (one's) beloved car) [n] <Play audio="aisha" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>成</span></th>
<td>ジョウ (jou)<br />セイ (sei)</td>
<td>な・る (naru)<br />な・す (nasu)</td>
<td>
Become
<br />
<table><tbody><tr><td>Examples:
<br />
成就 (じょうじゅ - jouju - fulfillment, realization, completion) [n] <Play audio="jouju" />
<br />
賛成 (さんせい - sansei - approval, agreement, support, favor) [n] <Play audio="sansei" />
<br />
成る (なる - naru - to become) [gv] <Play audio="naru" />
<br />
成す (なす - nasu - to build up, to establish, to form) [gv] <Play audio="nasu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>戦</span></th>
<td>セン (sen)</td>
<td>いくさ (ikusa)<br />たたか・う (tatakau)<br />たたか・い (tatakai)</td>
<td>
War
<br />
<table><tbody><tr><td>Examples:
<br />
観戦 (かんせん - kansen - watching a (sports) game, spectating, observing (military operations)) [n] <Play audio="kansen" />
<br />
戦争 (せんそう - sensou - war) [n] <Play audio="sensou" />
<br />
戦 (いくさ - ikusa - war, battle, campaign, fight) [n] <Play audio="ikusa" />
<br />
戦う (たたかう - tatakau - to fight, to battle, to combat, to struggle against, to wage war, to engage in contest) [gv] <Play audio="tatakau" />
<br />
戦い (たたかい - tatakai - battle, fight, struggle, conflict) [n] <Play audio="tatakai" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>折</span></th>
<td>セツ (setsu)</td>
<td>お・る (oru)<br />お・れる (oreru)<br />おり (ori)</td>
<td>
Fold
<br />
<table><tbody><tr><td>Examples:
<br />
屈折 (くっせつ - kussetsu - bending, indentation, refraction, inflection, warping (e.g. of opinion, reasoning, etc.), distortion) [n] <Play audio="kussetsu" />
<br />
折る (おる - oru - to break, to fracture, to break off, to snap off, to fold, to bend) [gv] <Play audio="oru" />
<br />
折れる (おれる - oreru - to be broken, to snap, to be fractured, to be folded) [iv] <Play audio="oreru" />
<br />
折 (おり - ori - opportunity, chance, occasion, time) [n] <Play audio="ori" />
<br />
折り (おり - ori - fold, pleat, crease) [n] <Play audio="ori" />
<br />
折り紙 (おりがみ - origami - origami (paper folding)) [n] <Play audio="origami" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>挙</span></th>
<td>キョ (kyo)</td>
<td>あ・げる (ageru)<br />あ・がる (agaru)</td>
<td>
Raise
<br />
<table><tbody><tr><td>Examples:
<br />
選挙 (せんきょ - senkyo - election) [n] <Play audio="senkyo" />
<br />
挙げる (あげる - ageru - to raise, to elevate) [iv] <Play audio="ageru" />
<br />
挙がる (あがる - agaru - to rise, to ascend, to be raised) [gv] <Play audio="agaru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>改</span></th>
<td>カイ (kai)</td>
<td>あらた・める (aratameru)<br />あらた・まる (aratamaru)</td>
<td>
Reformation
<br />
<table><tbody><tr><td>Examples:
<br />
改善 (かいぜん - kaizen - betterment, improvement) [n] <Play audio="kaizen" />
<br />
改める (あらためる - aratameru - to change, to alter, to revise, to replace, to reform, to correct, to improve, to do formally) [iv] <Play audio="aratameru" />
<br />
改まる (あらたまる - aratamaru - to be renewed, to be formal) [gv] <Play audio="aratamaru" />
<br />
悔い改める (くいあらためる - kuiaratameru - to repent, to be penitent) [iv] <Play audio="kuiaratameru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>救</span></th>
<td>キュウ (kyuu)</td>
<td>すく・う (sukuu)<br />すく・い (sukui)</td>
<td>
Salvation
<br />
<table><tbody><tr><td>Examples:
<br />
救出 (きゅうしゅつ - kyuushutsu - rescue, extricate, reclaim, deliverance) [n] <Play audio="kyuushutsu" />
<br />
救う (すくう - sukuu - to rescue from, to help out of, to save) [gv] <Play audio="sukuu" />
<br />
救い (すくい - sukui - help, aid, relief, (religious) salvation, (Christian) grace, providence) [n] <Play audio="sukui" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>敗</span></th>
<td>ハイ (hai)</td>
<td>やぶ・れる (yabureru)</td>
<td>
Failure
<br />
<table><tbody><tr><td>Examples:
<br />
勝敗 (しょうはい - shouhai - victory or defeat, issue (of battle), outcome) [n] <Play audio="shouhai" />
<br />
敗れる (やぶれる - yabureru - to be defeated, to be beaten, to be unsuccessful, to lose) [iv] <Play audio="yabureru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>散</span></th>
<td>サン (san)</td>
<td>ち・る (chiru)<br />ち・らす (chirasu)<br />ち・らかす (chirakasu)<br />ち・らかる (chirakaru)</td>
<td>
Scatter
<br />
<table><tbody><tr><td>Examples:
<br />
解散 (かいさん - kaisan - breakup, dissolution) [n] <Play audio="kaisan" />
<br />
散る (ちる - chiru - to scatter, to be dispersed, to disappear, to dissolve, to break up) [gv] <Play audio="chiru" />
<br />
散らす (ちらす - chirasu - to scatter, to disperse, to distribute, to spread, to resolve (a symptom, condition, etc.), to relieve, to get rid of) [gv] <Play audio="chirasu" />
<br />
散らかす (ちらかす - chirakasu - to scatter around, to leave untidy) [gv] <Play audio="chirakasu" />
<br />
散らかる (ちらかる - chirakaru - to be in disorder, to lie scattered around) [gv] <Play audio="chirakaru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>料</span></th>
<td>リョウ (ryou)</td>
<td></td>
<td>
Fee
<br />
<table><tbody><tr><td>Examples:
<br />
給料 (きゅうりょう - kyuuryou - salary, wages) [n] <Play audio="kyuuryou" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>旗</span></th>
<td>キ (ki)</td>
<td>はた (hata)</td>
<td>
Flag
<br />
<table><tbody><tr><td>Examples:
<br />
国旗 (こっき - kokki - national flag) [n] <Play audio="kokki" />
<br />
旗 (はた - hata - flag) [n] <Play audio="hata" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>昨</span></th>
<td>サク (saku)</td>
<td></td>
<td>
Previous
<br />
<table><tbody><tr><td>Examples:
<br />
昨年 (さくねん - sakunen - last year) [n] <Play audio="sakunen" />
<br />
昨日 (さくじつ - sakujitsu - yesterday (another way to read "kinou")) [n] <Play audio="sakujitsu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>景</span></th>
<td>ケイ (kei)</td>
<td></td>
<td>
Scenery
<br />
<table><tbody><tr><td>Examples:
<br />
背景 (はいけい - haikei - background, scenery, backdrop, setting, circumstance, context) [n] <Play audio="haikei" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>最</span></th>
<td>サイ (sai)</td>
<td>も (mo)<br />もっと・も (mottomo)</td>
<td>
Most
<br />
<table><tbody><tr><td>Examples:
<br />
最近 (さいきん - saikin - most recent, these days, right now, recently, nowadays) <Play audio="saikin" />
<br />
最初 (さいしょ - saisho - beginning, outset, first, onset) <Play audio="saisho" />
<br />
最早 (もはや - mohaya - already, now, (with negative verb) no longer, not any more) <Play audio="mohaya" />
<br />
最も (もっとも - mottomo - most, extremely) <Play audio="mottomo" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>望</span></th>
<td>モウ (mou - rarely used)<br />ボウ (bou)</td>
<td>のぞ・む (nozomu)<br />のぞ・み (nozomi)<br />のぞ・ましい (nozomashii)</td>
<td>
Hope
<br />
<table><tbody><tr><td>Examples:
<br />
希望 (きぼう - kibou - hope, wish, aspiration) [n] <Play audio="kibou" />
<br />
望遠鏡 (ぼうえんきょう - bouenkyou - telescope) [n] <Play audio="bouenkyou" />
<br />
望む (のぞむ - nozomu - to desire, to wish for) [gv] <Play audio="nozomu" />
<br />
望み (のぞみ - nozomi - wish, desire, hope) [n] <Play audio="nozomi" />
<br />
望ましい (のぞましい - nozomashii - desirable, hoped for) [dv] <Play audio="nozomashii" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>未</span></th>
<td>ミ (mi)</td>
<td>いま・だ (imada)</td>
<td>
Un-
<br />
<table><tbody><tr><td>Examples:
<br />
未成年 (みせいねん - miseinen - minor, not of age) [n] <Play audio="miseinen" />
<br />
未だ (いまだ - imada - as yet, hitherto, still, not yet (with negative verb)) <Play audio="imada" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>末</span></th>
<td>マツ (matsu)<br />バツ (batsu - rarely used)</td>
<td>すえ (sue)</td>
<td>
End
<br />
<table><tbody><tr><td>Examples:
<br />
世紀末 (せいきまつ - seikimatsu - end of a century) [n] <Play audio="seikimatsu" />
<br />
末 (すえ - sue - end, close (e.g. close of the month)) [n] <Play audio="sue" />
<br />
末っ子 (すえっこ - suekko - youngest child) [n] <Play audio="suekko" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>札</span></th>
<td>サツ (satsu)</td>
<td>ふだ (fuda)</td>
<td>
Tag
<br />
<table><tbody><tr><td>Examples:
<br />
表札 (ひょうさつ - hyousatsu - nameplate, doorplate) [n] <Play audio="hyousatsu" />
<br />
札 (ふだ - fuda - token, label, card) [n] <Play audio="fuda" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>材</span></th>
<td>ザイ (zai)</td>
<td></td>
<td>
Lumber
<br />
<table><tbody><tr><td>Examples:
<br />
材木 (ざいもく - zaimoku - lumber, timber) [n] <Play audio="zaimoku" />
<br />
取材 (しゅざい - shuzai - collecting data (e.g. for a newspaper article), covering an event) [n] <Play audio="shuzai" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>束</span></th>
<td>ソク (soku)</td>
<td>たば (taba)<br />つか (tsuka)<br />たば・ねる (tabaneru)</td>
<td>
Bundle
<br />
<table><tbody><tr><td>Examples:
<br />
束縛 (そくばく - sokubaku - restraint, shackles, restriction, confinement, binding) [n] <Play audio="sokubaku" />
<br />
束 (たば - taba - bundle, bunch, sheaf) [n] <Play audio="taba" />
<br />
束 (つか - tsuka - strut, short vertical post) [n] <Play audio="tsuka" />
<br />
束ねる (たばねる - tabaneru - to tie up in a bundle (e.g. straw, hair, bills, letters), to bundle, to sheathe) [iv] <Play audio="tabaneru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>松</span></th>
<td>ショウ (shou)</td>
<td>まつ (matsu)</td>
<td>
Pine
<br />
<table><tbody><tr><td>Examples:
<br />
松竹梅 (しょうちくばい - shouchikubai - high, middle, and low ranking (pine, bamboo, and plum)) [n] <Play audio="shouchikubai" />
<br />
松 (まつ - matsu - pine tree, highest (of a three-tier ranking system)) [n] <Play audio="matsu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>果</span></th>
<td>カ (ka)</td>
<td>は・たす (hatasu)<br />は・てる (hateru)<br />は・て (hate)<br />は・たして (hatashite)</td>
<td>
Fruit, Accomplish
<br />
<table><tbody><tr><td>Examples:
<br />
果樹 (かじゅ - kaju - fruit tree) [n] <Play audio="kaju" />
<br />
果たす (はたす - hatasu - to accomplish, to achieve, to carry out, to fulfill, to execute, to perform) [gv] <Play audio="hatasu" />
<br />
果てる (はてる - hateru - to end, to be finished, to perish) [iv] <Play audio="hateru" />
<br />
果て (はて - hate - the end, the extremity, the limit) [n] <Play audio="hate" />
<br />
果たして (はたして - hatashite - as was expected, just as one thought, sure enough) <Play audio="hatashite" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>栄</span></th>
<td>エイ (ei)</td>
<td>さか・える (sakaeru)</td>
<td>
Prosperity
<br />
<table><tbody><tr><td>Examples:
<br />
栄養 (えいよう - eiyou - nutrition, nourishment) [n] <Play audio="eiyou" />
<br />
栄える (さかえる - sakaeru - to prosper, to flourish) [iv] <Play audio="sakaeru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>案</span></th>
<td>アン (an)</td>
<td></td>
<td>
Plan
<br />
<table><tbody><tr><td>Examples:
<br />
案の定 (あんのじょう - an no jou - just as one thought, as usual) <Play audio="an no jou" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>梅</span></th>
<td>バイ (bai)</td>
<td>うめ (ume)</td>
<td>
Plum
<br />
<table><tbody><tr><td>Examples:
<br />
梅雨 (ばいう - baiu - rainy season, rain during the rainy season) [n] <Play audio="baiu" />
<br />
梅 (うめ - ume - plum, lowest (of a three-tier ranking system)) [n] <Play audio="ume" />
<br />
梅干 (うめぼし - umeboshi - pickled plum (popular in Japanese cuisine)) [n] <Play audio="umeboshi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>械</span></th>
<td>カイ (kai)</td>
<td></td>
<td>
Contraption
<br />
<table><tbody><tr><td>Examples:
<br />
機械 (きかい - kikai - machine, mechanism) [n] <Play audio="kikai" />
<br />
器械 (きかい - kikai - instrument, appliance, apparatus) [n] <Play audio="kikai" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>極</span></th>
<td>ゴク (goku)<br />キョク (kyoku)</td>
<td>きわ・める (kiwameru)<br />きわ・まる (kiwamaru)<br />きわ・めて (kiwamete)<br />きわ・み (kiwami)</td>
<td>
Pole, Extreme
<br />
<table><tbody><tr><td>Examples:
<br />
極秘 (ごくひ - gokuhi - absolute secrecy) [dn] <Play audio="gokuhi" />
<br />
北極 (ほっきょく - hokkyoku - North Pole) [n] <Play audio="hokkyoku" />
<br />
究極 (きゅうきょく - kyuukyoku - ultimate, extreme, final, eventual) [n] <Play audio="kyuukyoku" />
<br />
極める (きわめる - kiwameru - to carry to extremes, to go to the end of something) [iv] <Play audio="kiwameru" />
<br />
極まる (きわまる - kiwamaru - to terminate, to reach an extreme, to be at an end) [gv] <Play audio="kiwamaru" />
<br />
極めて (きわめて - kiwamete - exceedingly, extremely, decisively) <Play audio="kiwamete" />
<br />
極み (きわみ - kiwami - height, acme, extremity) [n] <Play audio="kiwami" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>標</span></th>
<td>ヒョウ (hyou)</td>
<td></td>
<td>
Signpost
<br />
<table><tbody><tr><td>Examples:
<br />
道路標識 (どうろひょうしき - dourohyoushiki - road sign, signpost) [n] <Play audio="dourohyoushiki" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>機</span></th>
<td>キ (ki)</td>
<td>はた (hata)</td>
<td>
Machine
<br />
<table><tbody><tr><td>Examples:
<br />
機械 (きかい - kikai - machine, mechanism) [n] <Play audio="kikai" />
<br />
機能 (きのう - kinou - function, facility, faculty, feature) [n] <Play audio="kinou" />
<br />
機 (はた - hata - loom) [n] <Play audio="hata" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>欠</span></th>
<td>ケツ (ketsu)</td>
<td>か・ける (kakeru)<br />か・く (kaku)</td>
<td>
Lack
<br />
<table><tbody><tr><td>Examples:
<br />
補欠 (ほけつ - hoketsu - filling a vacancy, supplementation, substitute, deputy, alternate, spare) [n] <Play audio="hoketsu" />
<br />
欠席 (けっせき - kesseki - absence, non-attendance) [n] <Play audio="kesseki" />
<br />
欠ける (かける - kakeru - to be chipped, to be damaged, to be broken, to be lacking, to be missing, to be insufficient, to be deficient, to be negligent toward) [iv] <Play audio="kakeru" />
<br />
欠く (かく - kaku - to chip, to nick, to break, to crack, to lack) [gv] <Play audio="kaku" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>歴</span></th>
<td>レキ (reki)</td>
<td></td>
<td>
History
<br />
<table><tbody><tr><td>Examples:
<br />
履歴 (りれき - rireki - personal history, background, career, log, record) [n] <Play audio="rireki" />
<br />
歴史 (れきし - rekishi - history) [n] <Play audio="rekishi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>残</span></th>
<td>ザン (zan)</td>
<td>のこ・る (nokoru)<br />のこ・す (nokosu)<br />のこ・り (nokori)</td>
<td>
Remainder
<br />
<table><tbody><tr><td>Examples:
<br />
残念 (ざんねん - zannen - deplorable, bad luck, regret, disappointment, "That's too bad") [dn] <Play audio="zannen" />
<br />
残る (のこる - nokoru - to remain, to be left) [gv] <Play audio="nokoru" />
<br />
残す (のこす - nokosu - to leave (behind, over), to bequeath, to reserve) [gv] <Play audio="nokosu" />
<br />
残り (のこり - nokori - remnant, residue, remaining, left-over) [n] <Play audio="nokori" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>殺</span></th>
<td>サイ (sai)<br />セツ (setsu - rarely used)<br />サツ (satsu)</td>
<td>ころ・す (korosu)<br />ころ・し (koroshi)</td>
<td>
Kill
<br />
<table><tbody><tr><td>Examples:
<br />
相殺 (そうさい - sousai - offsetting each other, plus and minus equal zero, counterbalancing) [n] <Play audio="sousai" />
<br />
殺人 (さつじん - satsujin - murder) [n] <Play audio="satsujin" />
<br />
殺す (ころす - korosu - to kill) [gv] <Play audio="korosu" />
<br />
殺し (ころし - koroshi - a murder) [n] <Play audio="koroshi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>毒</span></th>
<td>ドク (doku)</td>
<td></td>
<td>
Poison
<br />
<table><tbody><tr><td>Examples:
<br />
毒 (どく - doku - poison, toxicant) [n] <Play audio="doku" />
<br />
毒舌 (どくぜつ - dokuzetsu - wicked tongue, abusive language) [n] <Play audio="dokuzetsu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>氏</span></th>
<td>シ (shi)</td>
<td>うじ (uji)</td>
<td>
Family Name
<br />
<table><tbody><tr><td>Examples:
<br />
氏名 (しめい - shimei - full name, identity) [n] <Play audio="shimei" />
<br />
氏 (うじ - uji - family name, lineage) [n] <Play audio="uji" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>民</span></th>
<td>ミン (min)</td>
<td>たみ (tami)</td>
<td>
People
<br />
<table><tbody><tr><td>Examples:
<br />
国民 (こくみん - kokumin - nationality, people of a nation, citizen) [n] <Play audio="kokumin" />
<br />
民 (たみ - tami - people) [n] <Play audio="tami" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>求</span></th>
<td>キュウ (kyuu)</td>
<td>もと・む (motomu)<br />もと・める (motomeru)<br />もと・め (motome)</td>
<td>
Request
<br />
<table><tbody><tr><td>Examples:
<br />
請求 (せいきゅう - seikyuu - claim, demand, charge, application, request, billing (for a service)) [n] <Play audio="seikyuu" />
<br />
求む (もとむ - motomu - to want, to seek, to request) [gv] <Play audio="motomu" />
<br />
求める (もとめる - motomeru - to want, to wish for, to request, to demand, to seek, to search for) [iv] <Play audio="motomeru" />
<br />
求め (もとめ - motome - request, appeal, claim, demand) [n] <Play audio="motome" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>治</span></th>
<td>ジ (ji)<br />チ (chi)</td>
<td>おさ・める (osameru)<br />おさ・まる (osamaru)<br />なお・る (naoru)<br />なお・す (naosu)</td>
<td>
Govern
<br />
<table><tbody><tr><td>Examples:
<br />
政治 (せいじ - seiji - politics, government) [n] <Play audio="seiji" />
<br />
治療 (ちりょう - chiryou - medical treatment, cure) [n] <Play audio="chiryou" />
<br />
治める (おさめる - osameru - to govern, to manage, to subdue) [iv] <Play audio="osameru" />
<br />
治まる (おさまる - osamaru - to be at peace, to lessen (storm, terror, anger)) [gv] <Play audio="osamaru" />
<br />
治る (なおる - naoru - to be cured, to get well, to be healed) [gv] <Play audio="naoru" />
<br />
治す (なおす - naosu - to cure, to heal) [gv] <Play audio="naosu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>法</span></th>
<td>ホウ (hou)<br />ハツ (hatsu)</td>
<td></td>
<td>
Method
<br />
<table><tbody><tr><td>Examples:
<br />
法則 (ほうそく - housoku - law, rule) [n] <Play audio="housoku" />
<br />
方法 (ほうほう - houhou - method, process, way, means, technique) [n] <Play audio="houhou" />
<br />
法度 (はっと - hatto - law, ban, prohibition, ordinance) [n] <Play audio="hatto" />
</td></tr></tbody></table></td>
</tr>
</tbody></table>

    </>
  )
}

export default GradeFourThree