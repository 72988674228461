import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Meeting Someone New and Other Common Phrases"

const tabledata = [
  {"漢字 - Kanji":"誕生日おめでとうございます ","ひらがな - Hiragana":"たんじょうびおめでとうございます ","Romaji (English Letters)":"tanjoubi omedetou gozaimasu ","English Meaning":"Happy Birthday!","audio":"tanjoubi omedetou gozaimasu"},
 {"漢字 - Kanji":"明けましておめでとうございます ","ひらがな - Hiragana":"あけましておめでとうございます ","Romaji (English Letters)":"akemashite omedetou gozaimasu ","English Meaning":"Happy New Year!","audio":"akemashite omedetou gozaimasu"},
 {"漢字 - Kanji":"おめでとうございます ","ひらがな - Hiragana":"おめでとうございます ","Romaji (English Letters)":"omedetou gozaimasu ","English Meaning":"Congratulations!","audio":"omedetou gozaimasu"},
 {"漢字 - Kanji":"お休みなさい ","ひらがな - Hiragana":"おやすみなさい ","Romaji (English Letters)":"oyasuminasai ","English Meaning":"Good night.(used when someone is going to bed)","audio":"oyasuminasai"},
 {"漢字 - Kanji":"御免なさい ","ひらがな - Hiragana":"ごめんなさい ","Romaji (English Letters)":"gomennasai ","English Meaning":"I'm sorry.","audio":"gomennasai"},
 {"漢字 - Kanji":"すみません ","ひらがな - Hiragana":"すみません ","Romaji (English Letters)":"sumimasen ","English Meaning":"Excuse me.","audio":"sumimasen"},
 {"漢字 - Kanji":"トイレは何処ですか ","ひらがな - Hiragana":"トイレはどこですか ","Romaji (English Letters)":"toire wa doko desu ka ","English Meaning":"Where is the bathroom?","audio":"toire wa doko desu ka"},
 {"漢字 - Kanji":"お腹が空いています ","ひらがな - Hiragana":"おなかがすいています ","Romaji (English Letters)":"onaka ga suite imasu ","English Meaning":"I am hungry.(\"My stomach is empty.\")","audio":"onaka ga suite imasu"}
]

const Meeting = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Beginner" category="Phrases" lesson={title} />
     <div className={'title'}>
     {title}
      </div>
      <h1>{title}</h1>

      <p>We are often asked by our students learning Japanese how to introduce yourself to a Japanese person. This interaction is normally very formal and filled with a few bows. Below is a typical Japanese conversation/introduction between two people meeting for the first time:</p>

      <table>
      <thead>
<tr>
<th></th>
<th>Japanese(In Kanji, Hiragana, and Romaji)</th>
<th>English Meaning</th>
</tr>
</thead>
<tbody>
<tr>
<td>
<b>Person 1:</b>
</td>
<td>

こんにちは。始めまして。
<br />
こんにちは。はじめまして。
<br />
<Play audio="konnichiwa" /> <Play audio="hajimemashite" />

</td>
<td>
Hello.  Nice to meet you.
</td>
</tr>
<tr>
<td>
<b>Person 2:</b>
</td>
<td>

始めまして。お元気ですか。
<br />
はじめまして。おげんきですか。
<br />
<Play audio="hajimemashite" /> <Play audio="o-genki desu ka" />

</td>
<td>
Nice to meet you.<br />How are you?
</td>
</tr>
<tr>
<td>
<b>Person 1:</b>
</td>
<td>

はい、元気です。
<br />
はい、げんきです。
<br />
<Play audio="hai, genki desu" />

</td>
<td>
I am fine.
</td>
</tr>
<tr>
<td>
<b>Person 1:</b>
</td>
<td>

私は______と申します。あなたのお名前は？
<br />
わたしは______ともうします。あなたのおなまえは？
<br />
<Play audio="watashi wa ______ to moushimasu" /> <Play audio="anata no o-namae wa" />

</td>
<td>
My name is ______.<br />What is your name?
</td>
</tr>
<tr>
<td>
<b>Person 2:</b>
</td>
<td>

私は______と言います。宜しくお願いします。
<br />
わたしは______といいます。よろしくおねがいします。
<br />
<Play audio="watashi wa ______ to iimasu" /> <Play audio="yoroshiku o-negai shimasu" />

</td>
<td>
My name is ______.<br />Pleased to make your acquaintance.
</td>
</tr>
<tr>
<td>
<b>Person 1:</b>
</td>
<td>

宜しくお願いします。
<br />
よろしくおねがいします。
<br />
<Play audio="yoroshiku o-negai shimasu" />

</td>
<td>
Pleased to make your acquaintance.
</td>
</tr>
</tbody></table>

<p class="main">
<b>Explanation:</b> <Play audio="hajimemashite" /> literally means "it is a beginning" but would be the equivalent of "Nice to meet you" in English.  It would only be used the first time meeting someone.  As learned in <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link>, <Play audio="genki" /> means "in good spirits".  <Play audio="o-genki desu ka" /> is literally asking "Are you in good spirits?".  This is the most common way of asking "How are you?" in Japanese.  The other person responds "Yes, I am in good spirits".  <Play audio="mousu" /> is the ultra polite form of the verb <Play audio="iu" /> (to say).  Both people are literally saying "I am said/called _____".  <Play audio="namae" /> is the word for "name".  An <Play audio="o" /> is added in front of it (and other words throughout this exchange) to show respect.  This is not done when talking about yourself. <Play audio="yoroshii" /> means "good/fine" and <Play audio="negai" /> is a wish or request. <Play audio="yoroshiku o-negai shimasu" /> literally means "I request/wish kindly of you". It is basically like saying "Please treat me well".  It can be used in other situations as well - such as when asking for a favor.
</p>

<h2>Other Common Japanese Phrases</h2>

<p class="main">
We are often asked how to say "I love you" in Japanese.  This would be <Play audio="anata o ai shite imasu" /> BUT be advised that the Japanese typically don't use the word for love (愛 - あい - ai) <Play audio="ai" /> when talking about their feelings for someone else (not even a boyfriend, girlfriend, spouse, child, parent, etc.).  They would typically say <Play audio="anata no koto ga suki desu" /> or <Play audio="anata ga daisuki desu" />. <Play audio="suki" /> is Japanese for "like" and <Play audio="daisuki" /> means "favorite".  This may seem strange but this is just what they say for "I love you".
</p>


<Table data={tabledata} />

<p>See <Link to="/members/b/vocab/vocab01/">Beginning Vocabulary - Part 1</Link> for other common Japanese phrases.</p>

    </>
  )
}

export default Meeting