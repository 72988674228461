import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Grade 2 Kanji  - Part 4 of 4"

const GradeTwoFour = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Advanced" category="Kanji" lesson={title} />

      <h1>{title}</h1>

      <p>Below are Kanji the Japanese learn in grade 2 of elementary school.
Be sure to read the <Link to="/members/a/kanji/intro/">Introduction to Kanji</Link> if you haven't already.</p>

<p><strong>Legend:</strong></p>
<p>[n] = Noun</p>
<p>[dv] = Descriptive word (true adjective) - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[dn] = Descriptive noun - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[gv] = Godan verb - see <Link to="/members/b/grammar/godan-verbs/">Verb Conjugation (Godan)</Link></p>
<p>[iv] = Ichidan verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[irv] = Irregular verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[c] = Counter</p>

<table>
<thead>
  <tr>
<th>漢字<br /><Play audio="kanji" /></th>
<th>音読み <Play audio="onyomi" /><br />"On" Reading</th>
<th>訓読み <Play audio="kunyomi" /><br />"Kun" Reading</th>
<th>English Meaning</th>
</tr>
  </thead>
<tbody>
<tr>
<th><span style={{fontSize:`4rem`}}>麦</span></th>
<td>バク (baku)</td>
<td>むぎ (mugi)</td>
<td>
Wheat
<br />
<table><tbody><tr><td>Examples:
<br />
全麦 (ぜんばく - zenbaku - whole-wheat) [n] <Play audio="zenbaku" />
<br />
麦 (むぎ - mugi - wheat) [n] <Play audio="mugi" />
<br />
大麦 (おおむぎ - oomugi - barley) [n] <Play audio="oomugi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>米</span></th>
<td>マイ (mai)<br />ベイ (bei)</td>
<td>こめ (kome)</td>
<td>
Rice
<br />
<table><tbody><tr><td>Examples:
<br />
玄米 (げんまい - genmai - unpolished rice, unmilled rice, brown rice) [n] <Play audio="genmai" />
<br />
米国 (べいこく - beikoku - America, USA) [n] <Play audio="beikoku" />
<br />
米 (こめ - kome - rice (uncooked)) [n] <Play audio="kome" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>茶</span></th>
<td>サ (sa)<br />チャ (cha)</td>
<td></td>
<td>
Tea
<br />
<table><tbody><tr><td>Examples:
<br />
喫茶店 (きっさてん - kissaten - coffee lounge, coffee shop) [n] <Play audio="kissaten" />
<br />
麦茶 (むぎちゃ - mugicha - wheat tea) [n] <Play audio="mugicha" />
<br />
無茶苦茶 (むちゃくちゃ - muchakucha - confused, jumbled, mixed up, unreasonable) [dn] <Play audio="muchakucha" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>色</span></th>
<td>シキ (shiki)<br />ショク (shoku)</td>
<td>いろ (iro)</td>
<td>
Color
<br />
<table><tbody><tr><td>Examples:
<br />
色素 (しきそ - shikiso - pigment, coloring) [n] <Play audio="shikiso" />
<br />
脱色 (だっしょく - dasshoku - decolorization, bleaching) [n] <Play audio="dasshoku" />
<br />
色 (いろ - iro - color) [n] <Play audio="iro" />
<br />
色々 (いろいろ - iroiro - various) [dn] <Play audio="iroiro" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>黄</span></th>
<td>オウ (ou)<br />コウ (kou)</td>
<td>き (ki)</td>
<td>
Yellow
<br />
<table><tbody><tr><td>Examples:
<br />
硫黄 (いおう - iou - sulfur) [n] <Play audio="iou" />
<br />
黄葉 (こうよう - kouyou - yellow leaves, autumn colors) [n] <Play audio="kouyou" />
<br />
黄色 (きいろ - kiiro - yellow) [n] <Play audio="kiiro" />
<br />
黄色い (きいろい - kiiroi - yellow) [dv] <Play audio="kiiroi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>黒</span></th>
<td>コク (koku)</td>
<td>くろ (kuro)<br />くろ・い (kuroi)</td>
<td>
Black
<br />
<table><tbody><tr><td>Examples:
<br />
黒板 (こくばん - kokuban - blackboard) [n] <Play audio="kokuban" />
<br />
黒 (くろ - kuro - black, dark) [n] <Play audio="kuro" />
<br />
黒い (くろい - kuroi - black, dark) [dv] <Play audio="kuroi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>来</span></th>
<td>ライ (rai)</td>
<td>く・る (kuru)<br />きた・る (kitaru)<br />きた・す (kitasu)</td>
<td>
Come
<br />
<table><tbody><tr><td>Examples:
<br />
来客 (らいきゃく - raikyaku - visitor) [n] <Play audio="raikyaku" />
<br />
来る (くる - kuru - to come, to approach, to arrive) [irv] <Play audio="kuru" />
<br />
来る/来たる (きたる - kitaru - to come, to arrive, to be due to) [gv] <Play audio="kitaru" />
<br />
来す/来たす (きたす - kitasu - to cause, to induce, to bring about a result or state, to produce) [gv] <Play audio="kitasu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>行</span></th>
<td>ギョウ (gyou)<br />コウ (kou)<br />アン (an)</td>
<td>い・く (iku)<br />ゆ・く (yuku)<br />おこな・う (okonau)<br />おこな・い (okonai)</td>
<td>
Go
<br />
<table><tbody><tr><td>Examples:
<br />
行儀 (ぎょうぎ - gyougi - manners, behavior) [n] <Play audio="gyougi" />
<br />
進行 (しんこう - shinkou - progress) [n] <Play audio="shinkou" />
<br />
行火 (あんか - anka - bed warmer, foot warmer) [n] <Play audio="anka" />
<br />
行く (いく - iku - to go) [gv] <Play audio="iku" />
<br />
行く (ゆく - yuku - to go (old)) [gv] <Play audio="yuku" />
<br />
行う (おこなう - okonau - to perform, to do, to conduct oneself, to carry out) [gv] <Play audio="okonau" />
<br />
行い (おこない - okonai - deed, act, action, conduct, behavior) [n] <Play audio="okonai" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>帰</span></th>
<td>キ (ki)</td>
<td>かえ・る (kaeru)<br />かえ・す (kaesu)<br />かえ・り (kaeri)</td>
<td>
Return Home
<br />
<table><tbody><tr><td>Examples:
<br />
帰国 (きこく - kikoku - return to one's country) [n] <Play audio="kikoku" />
<br />
帰る (かえる - kaeru - to return, to come home, to go home, to go back) [gv] <Play audio="kaeru" />
<br />
帰す (かえす - kaesu - to send (someone) back, to send (someone) home) [gv] <Play audio="kaesu" />
<br />
帰り (かえり - kaeri - return, coming back) [n] <Play audio="kaeri" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>歩</span></th>
<td>ブ (bu)<br />ホ (ho)<br />フ (fu - rarely used)</td>
<td>ある・く (aruku)<br />あゆ・む (ayumu)</td>
<td>
Walk
<br />
<table><tbody><tr><td>Examples:
<br />
歩合 (ぶあい - buai - rate, ratio, commission, percentage, poundage) [n] <Play audio="buai" />
<br />
歩調 (ほちょう - hochou - pace, step, cadence) [n] <Play audio="hochou" />
<br />
歩く (あるく - aruku - to walk) [gv] <Play audio="aruku" />
<br />
歩む (あゆむ - ayumu - to walk, to go on foot) [gv] <Play audio="ayumu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>走</span></th>
<td>ソウ (sou)</td>
<td>はし・る (hashiru)</td>
<td>
Run
<br />
<table><tbody><tr><td>Examples:
<br />
競走 (きょうそう - kyousou - race) [n] <Play audio="kyousou" />
<br />
走る (はしる - hashiru - to run, to travel (movement of vehicles)) [gv] <Play audio="hashiru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>止</span></th>
<td>シ (shi)</td>
<td>と・まる (tomaru)<br />と・める (tomeru)</td>
<td>
Stop
<br />
<table><tbody><tr><td>Examples:
<br />
禁止 (きんし - kinshi - prohibition, inhibition, ban) [n] <Play audio="kinshi" />
<br />
止まる (とまる - tomaru - to stop, to halt, to come to a halt) [gv] <Play audio="tomaru" />
<br />
止める (とめる - tomeru - to stop (something or someone), to turn off) [iv] <Play audio="tomeru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>活</span></th>
<td>カツ (katsu)</td>
<td></td>
<td>
Active
<br />
<table><tbody><tr><td>Examples:
<br />
活発 (かっぱつ - kappatsu - vigor, active, lively) [dn] <Play audio="kappatsu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>店</span></th>
<td>テン (ten)</td>
<td>みせ (mise)</td>
<td>
Store
<br />
<table><tbody><tr><td>Examples:
<br />
開店 (かいてん - kaiten - opening (of a shop)) [n] <Play audio="kaiten" />
<br />
店 (みせ - mise - store, shop, establishment) [n] <Play audio="mise" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>買</span></th>
<td>バイ (bai)</td>
<td>か・う (kau)</td>
<td>
Buy
<br />
<table><tbody><tr><td>Examples:
<br />
買収 (ばいしゅう - baishuu - buying, purchase, corruption, bribery) [n] <Play audio="baishuu" />
<br />
買う (かう - kau - to buy, to purchase) [gv] <Play audio="kau" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>売</span></th>
<td>バイ (bai)</td>
<td>う・る (uru)</td>
<td>
Sell
<br />
<table><tbody><tr><td>Examples:
<br />
売買 (ばいばい - baibai - trade, buying and selling) [n] <Play audio="baibai" />
<br />
売る (うる - uru - to sell) [gv] <Play audio="uru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>午</span></th>
<td>ゴ (go)</td>
<td></td>
<td>
Noon
<br />
<table><tbody><tr><td>Examples:
<br />
午後 (ごご - gogo - afternoon, p.m.) [n] <Play audio="gogo" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>汽</span></th>
<td>キ (ki)</td>
<td></td>
<td>
Steam
<br />
<table><tbody><tr><td>Examples:
<br />
汽車 (きしゃ - kisha - train (steam)) [n] <Play audio="kisha" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>弓</span></th>
<td>キュウ (kyuu)</td>
<td>ゆみ (yumi)</td>
<td>
Bow
<br />
<table><tbody><tr><td>Examples:
<br />
弓道 (きゅうどう - kyuudou - (Japanese) archery) [n] <Play audio="kyuudou" />
<br />
弓 (ゆみ - yumi - bow (and arrow)) [n] <Play audio="yumi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>回</span></th>
<td>エ (e - rarely used)<br />カイ (kai)</td>
<td>まわ・る (mawaru)<br />まわ・す (mawasu)</td>
<td>
-Times, To Revolve
<br />
<table><tbody><tr><td>Examples:
<br />
一回 (いっかい - ikkai - once, one time) [n] <Play audio="ikkai" />
<br />
回転 (かいてん - kaiten - rotation, revolution, turning) [n] <Play audio="kaiten" />
<br />
回る (まわる - mawaru - to turn, to revolve) [gv] <Play audio="mawaru" />
<br />
回す (まわす - mawasu - to turn, to rotate, to circulate, to send around) [gv] <Play audio="mawasu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>会</span></th>
<td>エ (e)<br />カイ (kai)</td>
<td>あ・う (au)<br />あ・わす (awasu)<br />あ・わせる (awaseru)</td>
<td>
Association
<br />
<table><tbody><tr><td>Examples:
<br />
会釈 (えしゃく - eshaku - nod, salutation, greeting, recognition, bow) [n] <Play audio="eshaku" />
<br />
会議 (かいぎ - kaigi - meeting, conference, session, assembly, council, convention, congress) [n] <Play audio="kaigi" />
<br />
会う (あう - au - to meet, to encounter) [gv] <Play audio="au" />
<br />
会わす (あわす - awasu - to bring (people) together) [gv] <Play audio="awasu" />
<br />
会わせる (あわせる - awaseru - to make (someone) to meet, to let (someone) meet, to expose to, to subject to) [iv] <Play audio="awaseru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>組</span></th>
<td>ソ (so)</td>
<td>く・む (kumu)<br />くみ (kumi)</td>
<td>
Association, Team
<br />
<table><tbody><tr><td>Examples:
<br />
組織 (そしき - soshiki - organization) [n] <Play audio="soshiki" />
<br />
組む (くむ - kumu - to cross (legs or arms), to link (arms), to put together, to assemble, to braid, to unite, to issue (e.g. money order)) [gv] <Play audio="kumu" />
<br />
組合 (くみあい - kumiai - association, union) [n] <Play audio="kumiai" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>船</span></th>
<td>セン (sen)</td>
<td>ふね (fune)<br />ふな (funa)</td>
<td>
Ship
<br />
<table><tbody><tr><td>Examples:
<br />
船長 (せんちょう - senchou - ship's captain) [n] <Play audio="senchou" />
<br />
船 (ふね - fune - ship, boat, watercraft, vessel) [n] <Play audio="fune" />
<br />
船底 (ふなぞこ - funazoko - ship's bottom, bilge) [n] <Play audio="funazoko" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>明</span></th>
<td>ミョウ (myou)<br />メイ (mei)</td>
<td>あ・かり (akari)<br />あか・るい (akarui)<br />あか・るむ (akarumu)<br />あか・らむ (akaramu)<br />あき・らか (akiraka)<br />あ・ける (akeru)<br />あ・く (aku)<br />あ・くる (akuru)<br />あ・かす (akasu)</td>
<td>
Bright
<br />
<table><tbody><tr><td>Examples:
<br />
明朝 (みょうちょう - myouchou - tomorrow morning) [n] <Play audio="myouchou" />
<br />
明確 (めいかく - meikaku - clear, precise, definite, distinct) [dn] <Play audio="meikaku" />
<br />
明かり (あかり - akari - light, illumination, glow, gleam) [n] <Play audio="akari" />
<br />
明るい (あかるい - akarui - bright, colorful, cheerful) [dv] <Play audio="akarui" />
<br />
明るむ (あかるむ - akarumu - to brighten, to be refreshed) [gv] <Play audio="akarumu" />
<br />
明らむ (あからむ - akaramu - to become luminous at dawn) [gv] <Play audio="akaramu" />
<br />
明らか (あきらか - akiraka - obvious, evident, clear, plain) [dn] <Play audio="akiraka" />
<br />
明ける (あける - akeru - to dawn, to grow light) [iv] <Play audio="akeru" />
<br />
明く (あく - aku - to be open (e.g. neckline, etc.), to have been opened (of one's eyes, mouth, etc.), to open (one's eyes, mouth, etc.)) [gv] <Play audio="aku" />
<br />
明くる (あくる - akuru - next, following) [n] <Play audio="akuru" />
<br />
明かす (あかす - akasu - to pass (e.g. the night), to spend, to reveal, to divulge) [gv] <Play audio="akasu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>社</span></th>
<td>シャ (sha)</td>
<td>やしろ (yashiro)</td>
<td>
Company
<br />
<table><tbody><tr><td>Examples:
<br />
社長 (しゃちょう - shachou - company president, manager, director) [n] <Play audio="shachou" />
<br />
社 (やしろ - yashiro - shrine (usually Shinto)) [n] <Play audio="yashiro" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>切</span></th>
<td>セツ (setsu)<br />サイ (sai)</td>
<td>き・る (kiru)<br />き・り (kiri)<br />き・れる (kireru)<br />き・れ (kire)</td>
<td>
Cut
<br />
<table><tbody><tr><td>Examples:
<br />
大切　(たいせつ - taisetsu - important, valuable, worthy of care) [dn] <Play audio="taisetsu" />
<br />
一切合切　(いっさいがっさい - issaigassai - any and every thing, altogether, the whole shooting match, the whole kit and caboodle, without reserve) [n] <Play audio="issaigassai" />
<br />
切る (きる - kiru - to cut, to sever (connections, ties), to turn off (i.e. the light), to hang up (the phone), to disconnect, to discount) [gv] <Play audio="kiru" />
<br />
切り (きり - kiri - limits, end, bounds, period, place to leave off, closing sentence, all there is, only, since) [n] <Play audio="kiri" />
<br />
切れる (きれる - kireru - to cut well, to be sharp, to break (off), to snap, to wear out, to be injured, to burst, to collapse, to be disconnected, to be out of, to expire, to sever (connections) with, to be shrewd, to have a sharp mind, to drop under (a certain figure), to beat (e.g. a time)) [iv] <Play audio="kireru" />
<br />
切れ (きれ - kire - piece, slice, strip, scrap) [n] <Play audio="kire" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>電</span></th>
<td>デン (den)</td>
<td></td>
<td>
Electricity
<br />
<table><tbody><tr><td>Examples:
<br />
電気 (でんき - denki - electricity, (electric) light) [n] <Play audio="denki" />
<br />
電撃 (でんげき - dengeki - electric shock, blitz, lightning attack) [n] <Play audio="dengeki" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>毎</span></th>
<td>マイ (mai)</td>
<td></td>
<td>
Every
<br />
<table><tbody><tr><td>Examples:
<br />
毎度 (まいど - maido - each time) [n] <Play audio="maido" />
<br />
毎日 (まいにち - mainichi - every day) [n] <Play audio="mainichi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>合</span></th>
<td>ゴウ (gou)<br />カッ (ka - with a small "tsu")<br />ガッ (ga - with a small "tsu")</td>
<td>あ・う (au)<br />あ・わさる (awasaru)<br />あ・わす (awasu)<br />あ・わせる (awaseru)</td>
<td>
Fit
<br />
<table><tbody><tr><td>Examples:
<br />
集合 (しゅうごう - shuugou - gathering, assembly, meeting) [n] <Play audio="shuugou" />
<br />
合羽 (かっぱ - kappa - raincoat) [n] <Play audio="kappa" />
<br />
合唱 (がっしょう - gasshou - chorus, singing in a chorus) [n] <Play audio="gasshou" />
<br />
合う (あう - au - to come together, to merge, to unite, to meet, to fit, to match, to suit, to agree with, to be correct) [gv] <Play audio="au" />
<br />
合わさる (あわさる - awasaru - to get together, to unite) [gv] <Play audio="awasaru" />
<br />
合わす (あわす - awasu - to bring (objects) together) [gv] <Play audio="awasu" />
<br />
合わせる (あわせる - awaseru - to match (rhythm, speed, etc.), to join together, to unite, to combine, to add up) [iv] <Play audio="awaseru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>当</span></th>
<td>トウ (tou)</td>
<td>あ・たる (ataru)<br />あ・たり (atari)<br />あ・てる (ateru)<br />あ・て (ate)</td>
<td>
Hit
<br />
<table><tbody><tr><td>Examples:
<br />
当然 (とうぜん - touzen - natural, as a matter of course) [dn] <Play audio="touzen" />
<br />
当たる (あたる - ataru - to be hit, to strike, to touch, to be in contact, to be equivalent to, to be applicable, to apply to) [gv] <Play audio="ataru" />
<br />
当たり (あたり - atari - hit, success, reaching the mark, vicinity, neighborhood) [n] <Play audio="atari" />
<br />
当てる (あてる - ateru - to hit, to expose, to apply (e.g. patch), to allot, to call on someone (e.g. in class), to guess (an answer)) [iv] <Play audio="ateru" />
<br />
当て (あて - ate - object, aim, end, hopes, expectations) [n] <Play audio="ate" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>台</span></th>
<td>ダイ (dai)<br />タイ (tai)</td>
<td></td>
<td>
Base
<br />
<table><tbody><tr><td>Examples:
<br />
台所 (だいどころ - daidokoro - kitchen) [n] <Play audio="daidokoro" />
<br />
台風 (たいふう - taifuu - typhoon, hurricane) [n] <Play audio="taifuu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>楽</span></th>
<td>ガク (gaku)<br />ラク (raku)</td>
<td>たの・しい (tanoshii)<br />たの・しむ (tanoshimu)</td>
<td>
Pleasure
<br />
<table><tbody><tr><td>Examples:
<br />
音楽 (おんがく - ongaku - music) [n] <Play audio="ongaku" />
<br />
娯楽 (ごらく - goraku - pleasure; amusement) [n] <Play audio="goraku" />
<br />
楽しい (たのしい - tanoshii - enjoyable, fun) [dv] <Play audio="tanoshii" />
<br />
楽しむ (たのしむ - tanoshimu - to enjoy oneself) [gv] <Play audio="tanoshimu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>公</span></th>
<td>コウ (kou)</td>
<td>おおやけ (ooyake)</td>
<td>
Public
<br />
<table><tbody><tr><td>Examples:
<br />
公務員 (こうむいん - koumuin - government worker, public (civil) servant) [n] <Play audio="koumuin" />
<br />
公 (おおやけ - ooyake - official, public, formal, open, governmental) [n] <Play audio="ooyake" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>引</span></th>
<td>イン (in)</td>
<td>ひ・く (hiku)<br />ひ・ける (hikeru)<br />ひ・き (hiki)</td>
<td>
Pull
<br />
<table><tbody><tr><td>Examples:
<br />
引退する (いんたいする - intai suru - to retire) [irv] <Play audio="intai suru" />
<br />
引く (ひく - hiku - to pull, to draw (attention, etc.), to attract (interest, etc.), to draw back, to draw (a card), to draw (plan, line, etc.), to catch (cold)) [gv] <Play audio="hiku" />
<br />
引ける (ひける - hikeru - to close, to be over, to break up (e.g. school)) [iv] <Play audio="hikeru" />
<br />
引き (ひき - hiki - pull, patronage, influence, tug, discount) [n] <Play audio="hiki" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>科</span></th>
<td>カ (ka)</td>
<td></td>
<td>
Section
<br />
<table><tbody><tr><td>Examples:
<br />
科学 (かがく - kagaku - science) [n] <Play audio="kagaku" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>歌</span></th>
<td>カ (ka)</td>
<td>うた (uta)<br />うた・う (utau)</td>
<td>
Song
<br />
<table><tbody><tr><td>Examples:
<br />
四面楚歌 (しめんそか - shimensoka - being surrounded by enemies on all sides, being betrayed (forsaken) by everybody) [n] <Play audio="shimensoka" />
<br />
歌 (うた - uta - song) [n] <Play audio="uta" />
<br />
歌う (うたう - utau - to sing) [gv] <Play audio="utau" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>刀</span></th>
<td>トウ (tou)</td>
<td>かたな (katana)</td>
<td>
Sword
<br />
<table><tbody><tr><td>Examples:
<br />
単刀直入 (たんとうちょくにゅう - tantouchokunyuu - going right to the point, point-blank, without beating about the bush, frankness) [dn] <Play audio="tantouchokunyuu" />
<br />
刀 (かたな - katana - (single-edged) sword) [n] <Play audio="katana" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>番</span></th>
<td>バン (ban)</td>
<td></td>
<td>
Number
<br />
<table><tbody><tr><td>Examples:
<br />
番組 (ばんぐみ - bangumi - program (e.g. TV)) [n] <Play audio="bangumi" />
<br />
一番 (いちばん - ichiban - best, first, number one) [n] <Play audio="ichiban" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>用</span></th>
<td>ヨウ (you)</td>
<td>もち・いる (mochiiru)</td>
<td>
Use
<br />
<table><tbody><tr><td>Examples:
<br />
用途 (ようと - youto - use, usefulness, utility, service, purpose) [n] <Play audio="youto" />
<br />
用いる (もちいる - mochiiru - to use, to make use of, to utilize) [iv] <Play audio="mochiiru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>何</span></th>
<td>カ (ka - barely used)</td>
<td>なに (nani)<br />なん (nan)</td>
<td>
What
<br />
<table><tbody><tr><td>Examples:
<br />
何故 (なにゆえ - naniyue - why, how) <Play audio="naniyue" />
<br />
何日 (なんにち - nannichi - what day, how many days) <Play audio="nannichi" />
</td></tr></tbody></table></td>
</tr>
</tbody></table>

    </>
  )
}

export default GradeTwoFour