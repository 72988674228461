import React, { useState } from "react"
import styled from "styled-components"
import { getProfile, isAuthenticated } from "../utils/auth"
import getStripe from "../utils/stripe"

const Checkout = ({ text = "Upgrade Now" }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const redirectToCheckout = async (event) => {
    event.preventDefault()
    setLoading(true)
    setError(null)

    try {
      if (!isAuthenticated()) {
        throw new Error("user not authenticated")
      }

      const user = getProfile()

      const stripe = await getStripe()

      const response = await fetch(
        "/.netlify/functions/start-checkout-session",
        { method: "POST", body: user.sub }
      )
      const sessionId = await response.text()
      const { error } = await stripe.redirectToCheckout({ sessionId })

      if (error) {
        throw new Error(error)
      }
    } catch (e) {
      setError(
        typeof e === "string"
          ? e
          : ((e && e.message) || "").replace(
              /stripe\.redirectToCheckout\: Invalid value for sessionId\. You specified /gi,
              ""
            ) || "unknown error"
      )
      setLoading(false)
      return
    }
  }

  return (
    <>
      {!error ? null : <div style={{ marginBottom: 8 }}>{error}</div>}

      <BuyButton disabled={loading} onClick={redirectToCheckout}>
        {text}
      </BuyButton>
    </>
  )
}

export default Checkout

const BuyButton = styled.button`
  background-color: #f44336;
  border-radius: 2px;
  color: #fff !important;
  transition: 0.3s ease-in-out;
  font-family: "Jost";
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 10px 25px;

  cursor: pointer;
  border: 0;
  outline: 0;

  &:hover {
    box-shadow: rgba(244, 67, 54, 0.3) 0px 4px 50px 0px,
      rgba(244, 67, 54, 0.3) 0px 6px 50px 0px;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`
