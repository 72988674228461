import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "100 Most Useful Phrases Part 3 of 4"

const commonthree1 = [
  {"漢字 - Kanji":"お誕生日おめでとうございます ","ひらがな - Hiragana":"おたんじょうびおめでとうございます ","Romaji (English Letters)":"otanjoubi omedetou gozaimasu","English Meaning":"Happy Birthday", "audio": "otanjoubi omedetou gozaimasu"}
]
const commonthree2 = [
  {"漢字 - Kanji":"明けましておめでとうございます ","ひらがな - Hiragana":"あけましておめでとうございます ","Romaji (English Letters)":"akemashite omedetou gozaimasu","English Meaning":"Happy New Year", "audio": "akemashite omedetou gozaimasu"}
]
const commonthree3 = [
  {"漢字 - Kanji":"どこに住んでいますか ","ひらがな - Hiragana":"どこにすんでいますか ","Romaji (English Letters)":"doko ni sunde imasu ka","English Meaning":"Where do you live?", "audio": "doko ni sunde imasu ka"}
]
const commonthree4 = [
  {"漢字 - Kanji":"大丈夫ですか ","ひらがな - Hiragana":"だいじょうぶですか ","Romaji (English Letters)":"daijoubu desu ka","English Meaning":"Is it ok?/Are you ok?", "audio": "daijoubu desu ka"}
]
const commonthree5 = [
  {"漢字 - Kanji":"ドアを開けてください ","ひらがな - Hiragana":"どあをあけてください ","Romaji (English Letters)":"doa o akete kudasai","English Meaning":"Please open the door", "audio": "doa o akete kudasai"}
]
const commonthree6 = [
  {"漢字 - Kanji":"ドアを閉めてください ","ひらがな - Hiragana":"どあをしめてください ","Romaji (English Letters)":"doa o shimete kudasai","English Meaning":"Please close/shut the door", "audio": "doa o shimete kudasai"}
]
const commonthree7 = [
  {"漢字 - Kanji":"電気をつけてください ","ひらがな - Hiragana":"でんきをつけてください ","Romaji (English Letters)":"denki o tsukete kudasai","English Meaning":"Please turn on the light", "audio": "denki o tsukete kudasai"}
]
const commonthree8 = [
  {"漢字 - Kanji":"電気を消してください ","ひらがな - Hiragana":"でんきをけしてください ","Romaji (English Letters)":"denki o keshite kudasai","English Meaning":"Please turn off the light", "audio": "denki o keshite kudasai"}
]
const commonthree9 = [
  {"漢字 - Kanji":"洗濯物があります ","ひらがな - Hiragana":"せんたくものがあります ","Romaji (English Letters)":"sentakumono ga arimasu","English Meaning":"I have laundry to do/I need to do my laundry", "audio": "sentakumono ga arimasu"}
]
const commonthree10 = [
  {"漢字 - Kanji":"宿題があります ","ひらがな - Hiragana":"しゅくだいがあります ","Romaji (English Letters)":"shukudai ga arimasu","English Meaning":"I have homework", "audio": "shukudai ga arimasu"}
]
const commonthree11 = [
  {"漢字 - Kanji":"何時に戻りますか ","ひらがな - Hiragana":"なんじにもどりますか ","Romaji (English Letters)":"nanji ni modorimasu ka","English Meaning":"What time will you/we return/go back?", "audio": "nanji ni modorimasu ka"}
]
const commonthree12 = [
  {"漢字 - Kanji":"今帰ります ","ひらがな - Hiragana":"いまかえります ","Romaji (English Letters)":"ima kaerimasu","English Meaning":"I'm going home now", "audio": "ima kaerimasu"}
]
const commonthree13 = [
  {"漢字 - Kanji":"今仕事が終わりました ","ひらがな - Hiragana":"いましごとがおわりました ","Romaji (English Letters)":"ima shigoto ga owarimashita","English Meaning":"I just finished work", "audio": "ima shigoto ga owarimashita"}
]
const commonthree14 = [
  {"漢字 - Kanji":"会社に行きます ","ひらがな - Hiragana":"かいしゃにいきます ","Romaji (English Letters)":"kaisha ni ikimasu","English Meaning":"I'm going to work/the office", "audio": "kaisha ni ikimasu"}
]
const commonthree15 = [
  {"漢字 - Kanji":"お疲れ様でした ","ひらがな - Hiragana":"おつかれさまでした ","Romaji (English Letters)":"otsukaresama deshita","English Meaning":"You are a hard worker", "audio": "otsukaresama deshita"}
]
const commonthree16 = [
  {"漢字 - Kanji":"これを借りてもいいですか ","ひらがな - Hiragana":"これをかりてもいいですか ","Romaji (English Letters)":"kore o karite mo ii desu ka","English Meaning":"May I borrow this?", "audio": "kore o karite mo ii desu ka"}
]
const commonthree17 = [
  {"漢字 - Kanji":"四時に家に迎えに来てくれますか ","ひらがな - Hiragana":"よじにいえにむかえにきてくれますか ","Romaji (English Letters)":"yoji ni ie ni mukae ni kite kuremasu ka","English Meaning":"Could you please come pick me up at my house at 4:00?", "audio": "yoji ni ie ni mukae ni kite kuremasu ka"}
]
const commonthree18 = [
  {"漢字 - Kanji":"私の携帯電話に電話してください ","ひらがな - Hiragana":"わたしのけいたいでんわにでんわしてください ","Romaji (English Letters)":"watashi no keitaidenwa ni denwa shite kudasai","English Meaning":"Please call my cell phone", "audio": "watashi no keitaidenwa ni denwa shite kudasai"}
]
const commonthree19 = [
  {"漢字 - Kanji":"郵便局に寄ってから買い物に行ってきます ","ひらがな - Hiragana":"ゆうびんきょくによってからかいものにいってきます ","Romaji (English Letters)":"yuubinkyoku ni yotte kara kaimono ni itte kimasu","English Meaning":"I'm going shopping after I stop by the post office", "audio": "yuubinkyoku ni yotte kara kaimono ni itte kimasu"}
]
const commonthree20 = [
  {"漢字 - Kanji":"あの店はとても人気があります ","ひらがな - Hiragana":"あのみせはとてもにんきがあります ","Romaji (English Letters)":"ano mise wa totemo ninki ga arimasu","English Meaning":"That store is very popular", "audio": "ano mise wa totemo ninki ga arimasu"}
]
const commonthree21 = [
  {"漢字 - Kanji":"今日は気分がよくないので欠勤します ","ひらがな - Hiragana":"きょうはきぶんがよくないのでけっきんします ","Romaji (English Letters)":"kyou wa kibun ga yokunai node kekkin shimasu","English Meaning":"I'm not feeling well today so I will be taking a sick day", "audio": "kyou wa kibun ga yokunai node kekkin shimasu"}
]
const commonthree22 = [
  {"漢字 - Kanji":"お腹の調子がよくないです ","ひらがな - Hiragana":"おなかのちょうしがよくないです ","Romaji (English Letters)":"onaka no choushi ga yokunai desu","English Meaning":"My stomach is upset", "audio": "onaka no choushi ga yokunai desu"}
]
const commonthree23 = [
  {"漢字 - Kanji":"熱が少しあります ","ひらがな - Hiragana":"ねつがすこしあります ","Romaji (English Letters)":"netsu ga sukoshi arimasu","English Meaning":"I have a bit of a fever/temperature", "audio": "netsu ga sukoshi arimasu"}
]
const commonthree24 = [
  {"漢字 - Kanji":"頭痛があるので帰ります ","ひらがな - Hiragana":"ずつうがあるのでかえります ","Romaji (English Letters)":"zutsuu ga aru node kaerimasu","English Meaning":"I have a headache so I'm going home", "audio": "zutsuu ga aru node kaerimasu"}
]
const commonthree25 = [
  {"漢字 - Kanji":"具合はどうですか ","ひらがな - Hiragana":"ぐあいはどうですか ","Romaji (English Letters)":"guai wa dou desu ka","English Meaning":"How are you feeling?", "audio": "guai wa dou desu ka"}
]

const CommonThree = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Beginner" category="Phrases" lesson={title} />
     <div className={'title'}>
     {title}
      </div>
      <h1>{title}</h1>

      <p>Below are 25 of the most common/useful phrases in the Japanese language and a breakdown/explanation of each. To some extent, this lesson assumes that you have already gone through <Link to="/members/b/common/common01/">Part 1</Link> and <Link to="/members/b/common/common01/">Part 2</Link>.</p>

      <Table data={commonthree1} />

      <p><strong>Explanation:</strong></p>
      <p>"omedetou" by itself means "congratulations" and can be used by itself in plain form. Adding "gozaru" (the honorific form of "aru" (to exist - inanimate)) makes it polite form. "omedetou gozaimasu" can also be used by itself to wish general congratulations.</p>

      <Table data={commonthree2} />

      <p><strong>Explanation:</strong></p>
      <p>The verb 明ける <Play audio="akeru" /> has a dual meaning. It means both "to dawn, to grow light" and "to end" so "akemashite omedetou gozaimasu" literally means "it is ending and dawning, congratulations". Pretty perfect for the beginning of a new year. Japan celebrates January 1st as the beginning of a new year and does not celebrate Chinese New Year.</p>

      <Table data={commonthree3} />

      <p><strong>Explanation:</strong></p>
      <p>The verb 住む <Play audio="sumu" /> means "to reside". Since the topic is often left off of a Japanese sentence, this could be "Where does he live?", "Where does she live?", or "Where do they live?" if "he", "she", or "they" has already been established as the topic of the conversation. If not, then the topic would be the person you're talking to and it would be "Where do you live?" with no "anata wa" necessary.</p>

      <Table data={commonthree4} />

      <p><strong>Explanation:</strong></p>
      <p>"daijoubu" means "ok". The characters are "big", "stature", and "husband". I guess it means that everything is ok as long as you have a husband that is large in stature. ? Again, the topic of the conversation determines who or what we are asking about.</p>

      <Table data={commonthree5} />

      <p><strong>Explanation:</strong></p>
      <p>"doa" (taken from the English word "door") is the primary word for "door". This 開ける <Play audio="akeru" /> means "to open" (a door, package, or a store).</p>

      <Table data={commonthree6} />

      <p><strong>Explanation:</strong></p>
      <p>The verb 閉める <Play audio="shimeru" /> means "to close, to shut". You may notice how similar the kanji is to 開ける (akeru). 門 (mon), which can be seen in both of these kanji, means "gate".</p>

      <Table data={commonthree7} />

      <p><strong>Explanation:</strong></p>
      <p>The verb "tsukeru" could be written 付ける or 点ける (which is probably more correct) but, since most people are unsure which kanji to use, it is typically written in hiragana. "denki" means "electricity" but has also come to be used for electric lights as well.</p>

      <Table data={commonthree8} />

      <p><strong>Explanation:</strong></p>
      <p>As learned in <Link to="/members/b/grammar/godan-verbs/">Verb Conjugation (Godan)</Link>, the verb "kesu" means "to erase" or "to turn off".</p>

      <Table data={commonthree9} />

      <p><strong>Explanation:</strong></p>
      <p>"sentakumono" (or simply "sentaku") means "laundry". 洗濯する <Play audio="sentaku suru" /> means "to do laundry". Saying that you have laundry implies that it needs to be done. This is very common in Japanese.</p>

      <Table data={commonthree10} />

      <p><strong>Explanation:</strong></p>
      <p>Similar to above but with "shukudai" (school homework) instead.</p>

      <Table data={commonthree11} />

      <p><strong>Explanation:</strong></p>
      <p>The verb 戻る <Play audio="modoru" /> means "to return" or "to go back". Again, whether we're asking about "you" or "we" depends on the context or established topic.</p>

      <Table data={commonthree12} />

      <p><strong>Explanation:</strong></p>
      <p>The verb 帰る <Play audio="kaeru" /> means "to return home" and is different from "modoru" in that the destination is always "home". "modoru" is much more generic and can be used with any destination (as long as you were there recently).</p>

      <Table data={commonthree13} />

      <p><strong>Explanation:</strong></p>
      <p>仕事 <Play audio="shigoto" /> means "work, job, occupation, employment". The verb 終わる <Play audio="owaru" /> means "to come to an end". You can substitute "shigoto" with any task or event to indicate that it has ended or is over.</p>

      <Table data={commonthree14} />

      <p><strong>Explanation:</strong></p>
      <p>会社 <Play audio="kaisha" /> means "company, corporation, workplace". We wouldn't use "shigoto" in its place because it isn't a destination.</p>

      <Table data={commonthree15} />

      <p><strong>Explanation:</strong></p>
      <p>"otsukaresama deshita" doesn't translate into English very well. It is basically "thank you for a hard day's work!" or "you are/were a hard worker". The verb 疲れる <Play audio="tsukareru" /> means "to get tired, to tire" and the noun form, 疲れ <Play audio="tsukare" />, means "tiredness, fatigue". The "sama" (or "san" - not as polite) makes it apply to an individual and basically means "one who is worn out from work".</p>

      <Table data={commonthree16} />

      <p><strong>Explanation:</strong></p>
      <p>The verb 借りる <Play audio="kariru" /> means "to borrow". Base "te" + mo means "even though I verb" and "ii" means "good" (this changes to the equivalent "yoi" when conjugated (i.e. "yoku", "yokunai", etc.)). Together they mean "may I verb" in question form or "you may verb" in statement form.</p>

      <Table data={commonthree17} />

      <p><strong>Explanation:</strong></p>
      <p>The verb 迎える <Play audio="mukaeru" /> means "to go out to meet, to receive, to welcome". Base 2 + "kuru" means "to come to verb" (similar to base 2 + "iku" which means "to go to verb") so the combination of "mukae ni kuru" means "to call for somebody, to come for someone, to pick someone up". Base "te" + "kureru" is someone else doing something for you. A more polite way to ask this same question would be to replace "kuremasu" with "itadakemasu" ("itadakeru" is the "can do" form of the verb "itadaku" which means "to receive").</p>
      
      <Table data={commonthree18} />

      <p><strong>Explanation:</strong></p>
      <p><Play audio="denwa" />  means "phone" and <Play audio="keitaidenwa" />  means "cellular phone". <Play audio="denwa suru" />  means "to call someone".</p>

      <Table data={commonthree19} />

      <p><strong>Explanation:</strong></p>
      <p>The verb 寄る <Play audio="yoru" /> means "to visit, to drop in" and base "te" + "kara" means "after verbing". "kaimono ni iku" basically means "to go to shopping" but is what is said for "to go shopping".</p>

      <Table data={commonthree20} />

      <p><strong>Explanation:</strong></p>
      <p><Play audio="totemo" />  means "very, exceedingly" when used in a positive way and "(not) at all, by no means" when used in a negative way. <Play audio="ninki" />  means "popularity". "ninki ga aru" (has popularity) is how you would say that something is popular.</p>

      <Table data={commonthree21} />

      <p><strong>Explanation:</strong></p>
      <p>"kibun" means "feeling, mood". "yokunai" is the negative form of "ii" and means "not good". "kibun ga yokunai" combined together means "not feeling well". "node" means "because" and the verb 欠勤する <Play audio="kekkin suru" /> means "to be absent".</p>

      <Table data={commonthree22} />

      <p><strong>Explanation:</strong></p>
      <p>Similar to the above sentence but "choushi" means "condition, state of health". 腹 by itself is read "hara" and means "abdomen, belly" but when the honorific "o" is added the reading changes to "naka" and "onaka" refers to the stomach.</p>

      <Table data={commonthree23} />

      <p><strong>Explanation:</strong></p>
      <p>"netsu" is "fever" and <Play audio="sukoshi" />  means "little, few". This is where the English word "skosh" comes from.</p>

      <Table data={commonthree24} />

      <p><strong>Explanation:</strong></p>
      <p>"zutsuu" means "headache". The characters are "head" (atama) and "pain" (same character as the adjective 痛い <Play audio="itai" /> which means "painful, sore".</p>

      <Table data={commonthree25} />

      <p><strong>Explanation:</strong></p>
      <p>"guai" is another word similar to "kibun" and "choushi" and means "condition, state, manner, health". "guai wa dou desu ka" literally means "How is your condition/state?"</p>


    </>
  )
}

export default CommonThree