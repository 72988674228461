import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Grade 5 Kanji - Part 3 of 5"

const GradeFiveThree = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Advanced" category="Kanji" lesson={title} />
  
      <h1>{title}</h1>

      <p>Below are Kanji the Japanese learn in grade 5 of elementary school.
Be sure to read the <Link to="/members/a/kanji/intro/">Introduction to Kanji</Link> if you haven't already.</p>

<p>Legend:</p>
<p>[n] = Noun</p>
<p>[dv] = Descriptive word (true adjective) - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[dn] = Descriptive noun - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[gv] = Godan verb - see <Link to="/members/b/grammar/godan-verbs/">Verb Conjugation (Godan)</Link></p>
<p>[iv] = Ichidan verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[irv] = Irregular verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[c] = Counter</p>

<table>
<thead>
  <tr>
<th>漢字<br /><Play audio="kanji" /></th>
<th>音読み <Play audio="onyomi" /><br />"On" Reading</th>
<th>訓読み <Play audio="kunyomi" /><br />"Kun" Reading</th>
<th>English Meaning</th>
</tr>
  </thead>
<tbody>
<tr>
<th><span style={{fontSize:`4rem`}}>条</span></th>
<td>ジョウ (jou)</td>
<td></td>
<td>
Clause
<br />
<table><tbody><tr><td>Examples:
<br />
条件 (じょうけん - jouken - condition, conditions, term, terms, requirement, requirements) [n] <Play audio="jouken" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>枝</span></th>
<td>シ (shi)</td>
<td>えだ (eda)</td>
<td>
Branch
<br />
<table><tbody><tr><td>Examples:
<br />
枝葉 (しよう - shiyou - leaves and branches, foliage) [n] <Play audio="shiyou" />
<br />
枝 (えだ - eda - branch, bow, bough, twig, limb) [n] <Play audio="eda" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>査</span></th>
<td>フ (fu)<br />フウ (fuu)</td>
<td>と・む (tomu)<br />とみ (tomi)</td>
<td>
Investigate
<br />
<table><tbody><tr><td>Examples:
<br />
検査 (けんさ - kensa - inspection (e.g. customs, factory), examination, scan (e.g. MRI, PET, etc.)) [n] <Play audio="kensa" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>格</span></th>
<td>カク (kaku)<br />コウ (kou)</td>
<td></td>
<td>
Status
<br />
<table><tbody><tr><td>Examples:
<br />
厳格 (げんかく - genkaku - severe, rigid, strictness, rigor, austerity) [dn] <Play audio="genkaku" />
<br />
格子 (こうし - koushi - (crystal) lattice, grid pattern) [n] <Play audio="koushi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>桜</span></th>
<td>オウ (ou)</td>
<td>さくら (sakura)<br /></td>
<td>
Cherry
<br />
<table><tbody><tr><td>Examples:
<br />
桜花 (おうか - ouka - cherry blossom) [n] <Play audio="ouka" />
<br />
桜 (さくら - sakura - cherry tree, cherry blossom) [n] <Play audio="sakura" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>検</span></th>
<td>ケン (ken)</td>
<td></td>
<td>
Examine
<br />
<table><tbody><tr><td>Examples:
<br />
点検 (てんけん - tenken - inspection, examination, checking) [n] <Play audio="tenken" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>構</span></th>
<td>コウ (kou)</td>
<td>かま・える (kamaeru)<br />かま・う (kamau)<br />かま・え (kamae)</td>
<td>
Construct
<br />
<table><tbody><tr><td>Examples:
<br />
結構 (けっこう - kekkou - splendid, nice, wonderful, sufficient, fine (in the sense of "I'm fine"), (by implication) no thank you, well enough, OK, tolerable) [dn] <Play audio="kekkou" />
<br />
構える (かまえる - kamaeru - to set up (e.g. a building), to prepare in advance (e.g. a meal), to prepare to use something (e.g. a camera), to plan, to scheme) [iv] <Play audio="kamaeru" />
<br />
構う (かまう - kamau - to mind, to care about, to be concerned about, to care for, to look after, to prepare for) [gv] <Play audio="kamau" />
<br />
構え (かまえ - kamae - posture (e.g. in martial arts), pose, style, stance) [n] <Play audio="kamae" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>武</span></th>
<td>ム (mu)<br />ブ (bu)</td>
<td></td>
<td>
Military
<br />
<table><tbody><tr><td>Examples:
<br />
武者 (むしゃ - musha - warrior) [n] <Play audio="musha" />
<br />
武道 (ぶどう - budou - martial arts, military arts, Bushido) [n] <Play audio="budou-art" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>比</span></th>
<td>ヒ (hi)</td>
<td>くら・べる (kuraberu)</td>
<td>
Compare
<br />
<table><tbody><tr><td>Examples:
<br />
比較 (ひかく - hikaku - comparison) [n] <Play audio="hikaku" />
<br />
比べる (くらべる - kuraberu - to compare, to make a comparison) [iv] <Play audio="kuraberu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>永</span></th>
<td>エイ (ei)</td>
<td>なが・い (nagai)</td>
<td>
Eternity
<br />
<table><tbody><tr><td>Examples:
<br />
永久 (えいきゅう - eikyuu - eternity, perpetuity, immortality) [dn] <Play audio="eikyuu" />
<br />
永い (ながい - nagai - long (time), lengthy) [dv] <Play audio="nagai" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>河</span></th>
<td>カ (ka)<br />ガ (ga)</td>
<td>かわ (kawa)</td>
<td>
River
<br />
<table><tbody><tr><td>Examples:
<br />
河港 (かこう - kakou - river port) [n] <Play audio="kakou" />
<br />
氷河 (ひょうが - hyouga - glacier) [n] <Play audio="hyouga" />
<br />
河 (かわ - kawa - river, stream) [n] <Play audio="kawa" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>液</span></th>
<td>エキ (eki)</td>
<td></td>
<td>
Fluid
<br />
<table><tbody><tr><td>Examples:
<br />
血液 (けつえき - ketsueki - blood) [n] <Play audio="ketsueki" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>混</span></th>
<td>コン (kon)</td>
<td>ま・じる (majiru)<br />ま・ざる (mazaru)<br />ま・ぜる (mazeru)</td>
<td>
Mix
<br />
<table><tbody><tr><td>Examples:
<br />
混雑 (こんざつ - konzatsu - confusion, congestion) [n] <Play audio="konzatsu" />
<br />
混じる (まじる - majiru - to be mixed, to be blended with, to associate with, to mingle with) [gv] <Play audio="majiru" />
<br />
混ざる (まざる - mazaru - to be mixed, to be blended with, to associate with, to mingle with) [gv] <Play audio="mazaru" />
<br />
混ぜる (まぜる - mazeru - to mix, to stir, to blend) [iv] <Play audio="mazeru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>減</span></th>
<td>ゲン (gen)</td>
<td>へ・る (heru)<br />へ・らす (herasu)</td>
<td>
Decrease
<br />
<table><tbody><tr><td>Examples:
<br />
減量 (げんりょう - genryou - loss in quantity) [n] <Play audio="genryou" />
<br />
減る (へる - heru - to decrease (in size or number), to diminish, to abate) [gv] <Play audio="heru" />
<br />
減らす (へらす - herasu - to abate, to decrease, to diminish, to shorten) [gv] <Play audio="herasu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>測</span></th>
<td>ソク (soku)</td>
<td>はか・る (hakaru)</td>
<td>
Fathom
<br />
<table><tbody><tr><td>Examples:
<br />
測定 (そくてい - sokutei - measurement) [n] <Play audio="sokutei" />
<br />
測る (はかる - hakaru - to measure, to weigh, to survey, to time (sound, gauge, estimate), to conjecture, to infer, to surmise) [gv] <Play audio="hakaru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>準</span></th>
<td>ジュン (jun)</td>
<td></td>
<td>
Standard
<br />
<table><tbody><tr><td>Examples:
<br />
準備 (じゅんび - junbi - preparation, setup, arrangements, provision, reserve) [n] <Play audio="junbi" />
<br />
準じる (じゅんじる - junjiru - to follow, to conform) [iv] <Play audio="junjiru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>演</span></th>
<td>エン (en)</td>
<td></td>
<td>
Perform
<br />
<table><tbody><tr><td>Examples:
<br />
演説 (えんぜつ - enzetsu - speech, address) [n] <Play audio="enzetsu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>潔</span></th>
<td>ケツ (ketsu)</td>
<td>いさぎよ・い (isagiyoi)</td>
<td>
Undefiled
<br />
<table><tbody><tr><td>Examples:
<br />
清潔 (せいけつ - seiketsu - clean, hygienic, sanitary, pure, virtuous, immaculate) [dn] <Play audio="seiketsu" />
<br />
潔い (いさぎよい - isagiyoi - manly, sportsmanlike, gracious, gallant, resolute, brave, pure (heart, actions, etc.), upright, blameless) [dv] <Play audio="isagiyoi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>災</span></th>
<td>サイ (sai)</td>
<td>わざわ・い (wazawai)</td>
<td>
Disaster
<br />
<table><tbody><tr><td>Examples:
<br />
災害 (さいがい - saigai - calamity, disaster, misfortune) [n] <Play audio="saigai" />
<br />
災い (わざわい - wazawai - calamity, catastrophe) [n] <Play audio="wazawai" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>燃</span></th>
<td>ネン (nen)</td>
<td>も・える (moeru)<br />も・やす (moyasu)<br />も・す (mosu)</td>
<td>
Burn
<br />
<table><tbody><tr><td>Examples:
<br />
燃焼 (ねんしょう - nenshou - burning, combustion) [n] <Play audio="nenshou" />
<br />
燃える (もえる - moeru - to burn, to get fired up) [iv] <Play audio="moeru" />
<br />
燃やす (もやす - moyasu - to burn) [gv] <Play audio="moyasu" />
<br />
燃す (もす - mosu - to burn) [gv] <Play audio="mosu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>版</span></th>
<td>ハン (han)</td>
<td></td>
<td>
Printing Block
<br />
<table><tbody><tr><td>Examples:
<br />
改版 (かいはん - kaihan - revision, revised edition) [n] <Play audio="kaihan" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>犯</span></th>
<td>ハン (han)</td>
<td>おか・す (okasu)</td>
<td>
Crime
<br />
<table><tbody><tr><td>Examples:
<br />
防犯 (ぼうはん - bouhan - prevention of crime) [n] <Play audio="bouhan" />
<br />
犯す (おかす - okasu - to commit (e.g. crime), to perpetrate, to transgress) [gv] <Play audio="okasu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>状</span></th>
<td>ジョウ (jou)</td>
<td></td>
<td>
Form
<br />
<table><tbody><tr><td>Examples:
<br />
状況 (じょうきょう - joukyou - state of affairs (around you), situation, circumstances) [n] <Play audio="joukyou" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>独</span></th>
<td>ドク (doku)</td>
<td>ひと・り (hitori)</td>
<td>
Alone
<br />
<table><tbody><tr><td>Examples:
<br />
独立 (どくりつ - dokuritsu - independence (e.g. Independence Day), self-support) [dn] <Play audio="dokuritsu" />
<br />
独り (ひとり - hitori - alone, unmarried, solitary) [n] <Play audio="hitori" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>率</span></th>
<td>リツ (ritsu)<br />ソツ (sotsu)</td>
<td>ひき・いる (hikiiru)</td>
<td>
Rate
<br />
<table><tbody><tr><td>Examples:
<br />
確率 (かくりつ - kakuritsu - probability, likelihood, chances) [n] <Play audio="kakuritsu" />
<br />
率先 (そっせん - sossen - taking the initiative) [n] <Play audio="sossen" />
<br />
率いる (ひきいる - hikiiru - to lead, to spearhead (a group), to command (troops)) [iv] <Play audio="hikiiru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>現</span></th>
<td>ゲン (gen)</td>
<td>あらわ・れる (arawareru)<br />あらわ・す (arawasu)</td>
<td>
Appear
<br />
<table><tbody><tr><td>Examples:
<br />
現実 (げんじつ - genjitsu - reality) [n] <Play audio="genjitsu" />
<br />
現れる (あらわれる - arawareru - to appear, to come in sight, to become visible) [iv] <Play audio="arawareru" />
<br />
現す (あらわす - arawasu - to reveal, to show, to display) [gv] <Play audio="arawasu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>留</span></th>
<td>ル (ru)<br />リュウ (ryuu)</td>
<td>とど・まる (todomaru)</td>
<td>
Detain
<br />
<table><tbody><tr><td>Examples:
<br />
留守 (るす - rusu - absence, being away from home, house-sitting, house-sitter) [n] <Play audio="rusu" />
<br />
留学 (りゅうがく - ryuugaku - studying abroad (usu. at university level)) [n] <Play audio="ryuugaku" />
<br />
留まる (とどまる - todomaru - to remain, to abide, to stay (in the one place), to be limited to, to be confined to) [gv] <Play audio="todomaru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>略</span></th>
<td>リャク (ryaku)</td>
<td></td>
<td>
Abbreviation
<br />
<table><tbody><tr><td>Examples:
<br />
省略 (しょうりゃく - shouryaku - omission, abbreviation, abridgment) [n] <Play audio="shouryaku" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>益</span></th>
<td>ヤク (yaku - rarely used)<br />エキ (eki)</td>
<td></td>
<td>
Benefit
<br />
<table><tbody><tr><td>Examples:
<br />
利益 (りえき - rieki - profit, gains, benefit, advantage, interest (of the public, etc.)) [n] <Play audio="rieki" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>眼</span></th>
<td>ゲン (gen - rarely used)<br />ガン (gan)</td>
<td>まなこ (manako)<br />め (me)</td>
<td>
Eyeball
<br />
<table><tbody><tr><td>Examples:
<br />
双眼 (そうがん - sougan - both eyes, binocular) [n] <Play audio="sougan" />
<br />
眼 (まなこ - manako - eye, eyeball) [n] <Play audio="manako" />
<br />
眼 (め - me - eye, eyeball) [n] <Play audio="me" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>破</span></th>
<td>ハ (ha)</td>
<td>やぶ・る (yaburu)<br />やぶ・れる (yabureru)</td>
<td>
Rend
<br />
<table><tbody><tr><td>Examples:
<br />
破産 (はさん - hasan - bankruptcy, insolvency) [n] <Play audio="hasan" />
<br />
破る (やぶる - yaburu - to tear, to violate, to defeat, to smash, to destroy, to break (e.g. password)) [gv] <Play audio="yaburu" />
<br />
破れる (やぶれる - yabureru - to get torn, to tear, to rip, to break, to wear out, to be broken off (of negotiations, etc.), to break down, to collapse, to fall into ruin) [iv] <Play audio="yabureru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>確</span></th>
<td>カク (kaku)</td>
<td>たし・か (tashika)<br />たし・かめる (tashikameru)</td>
<td>
Certain
<br />
<table><tbody><tr><td>Examples:
<br />
確認 (かくにん - kakunin - affirmation, confirmation, validation) [n] <Play audio="kakunin" />
<br />
確か (たしか - tashika - certain, sure, definite) [dn] <Play audio="tashika" />
<br />
確かめる (たしかめる - tashikameru - to ascertain, to check, to make sure) [iv] <Play audio="tashikameru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>示</span></th>
<td>ジ (ji)<br />シ (shi)</td>
<td>しめ・す (shimesu)<br />しめ・し (shimeshi)</td>
<td>
Indicate
<br />
<table><tbody><tr><td>Examples:
<br />
示談 (じだん - jidan - settlement out of court) [n] <Play audio="jidan" />
<br />
示唆 (しさ - shisa - suggestion, hint, implication) [n] <Play audio="shisa" />
<br />
示す (しめす - shimesu - to denote, to show, to point out, to indicate, to exemplify) [gv] <Play audio="shimesu" />
<br />
示し (しめし - shimeshi - discipline, revelation) [n] <Play audio="shimeshi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>祖</span></th>
<td>ソ (so)</td>
<td></td>
<td>
Ancestor
<br />
<table><tbody><tr><td>Examples:
<br />
祖国 (そこく - sokoku - motherland, fatherland, native country) [n] <Play audio="sokoku" />
<br />
祖父母 (そふぼ - sofubo - grandparents) [n] <Play audio="sofubo" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>禁</span></th>
<td>キン (kin)</td>
<td></td>
<td>
Prohibition
<br />
<table><tbody><tr><td>Examples:
<br />
禁煙 (きんえん - kin'en - abstaining from smoking, quitting smoking, (on a sign) No Smoking!, Smoking Prohibited!) [n] <Play audio="kin-en" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>移</span></th>
<td>イ (i)</td>
<td>うつ・る (utsuru)<br />うつ・す (utsusu)</td>
<td>
Shift
<br />
<table><tbody><tr><td>Examples:
<br />
移住 (いじゅう - ijuu - migration, immigration) [n] <Play audio="ijuu" />
<br />
移る (うつる - utsuru - to move (house), to transfer (department), to change the target of interest or concern) [gv] <Play audio="utsuru" />
<br />
移す (うつす - utsusu - to change, to swap, to substitute, to transfer, to change the object of one's interest or focus) [gv] <Play audio="utsusu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>程</span></th>
<td>テイ (tei)</td>
<td>ほど (hodo)</td>
<td>
Extent
<br />
<table><tbody><tr><td>Examples:
<br />
程度 (ていど - teido - degree, amount, grade, standard, of the order of (following a number)) [n] <Play audio="teido" />
<br />
程 (ほど - hodo - degree, extent, bounds, limit) [n] <Play audio="hodo" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>税</span></th>
<td>ゼイ (zei)</td>
<td></td>
<td>
Tax
<br />
<table><tbody><tr><td>Examples:
<br />
税金 (ぜいきん - zeikin - tax, duty) [n] <Play audio="zeikin" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>築</span></th>
<td>チク (chiku)</td>
<td>きず・く (kizuku)</td>
<td>
Fabricate
<br />
<table><tbody><tr><td>Examples:
<br />
改築 (かいちく - kaichiku - structural alteration of building, reconstruction) [n] <Play audio="kaichiku" />
<br />
築く (きずく - kizuku - to build, to pile up, to amass) [gv] <Play audio="kizuku" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>精</span></th>
<td>ショウ (shou)<br />セイ (sei)</td>
<td></td>
<td>
Refined
<br />
<table><tbody><tr><td>Examples:
<br />
精進 (しょうじん - shoujin - concentration, diligence, devotion) [n] <Play audio="shoujin" />
<br />
精製 (せいせい - seisei - refining, purification) [n] <Play audio="seisei" />
</td></tr></tbody></table></td>
</tr>
</tbody></table>

    </>
  )
}

export default GradeFiveThree