import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Grade 2 Kanji  - Part 1 of 4"

const GradeTwoOne = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Advanced" category="Kanji" lesson={title} />

      <h1>{title}</h1>

      <p>Below are Kanji the Japanese learn in grade 2 of elementary school.
Be sure to read the <Link to="/members/a/kanji/intro/">Introduction to Kanji</Link> if you haven't already.</p>

<p>Legend:</p>
<p>[n] = Noun</p>
<p>[dv] = Descriptive word (true adjective) - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[dn] = Descriptive noun - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[gv] = Godan verb - see <Link to="/members/b/grammar/godan-verbs/">Verb Conjugation (Godan)</Link></p>
<p>[iv] = Ichidan verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[irv] = Irregular verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[c] = Counter</p>

<table>
<thead>
  <tr>
<th>漢字<br /><Play audio="kanji" /></th>
<th>音読み <Play audio="onyomi" /><br />"On" Reading</th>
<th>訓読み <Play audio="kunyomi" /><br />"Kun" Reading</th>
<th>English Meaning</th>
</tr>
  </thead>
<tbody>
<tr>
<th><span style={{fontSize:`4rem`}}>数</span></th>
<td>スウ (suu)</td>
<td>かず (kazu)<br />かぞ・える (kazoeru)</td>
<td>
Number
<br />
<table><tbody><tr><td>Examples:
<br />
数学 (すうがく - suugaku - mathematics) [n] <Play audio="suugaku" />
<br />
数 (かず - kazu - number, amount) [n] <Play audio="kazu" />
<br />
数える (かぞえる - kazoeru - to count) [iv] <Play audio="kazoeru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>多</span></th>
<td>タ (ta)</td>
<td>おお・い (ooi)</td>
<td>
Many, Much
<br />
<table><tbody><tr><td>Examples:
<br />
多数 (たすう - tasuu - great number, countless, majority) [n] <Play audio="tasuu" />
<br />
多い (おおい - ooi - many, numerous) [dv] <Play audio="ooi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>少</span></th>
<td>ショウ (shou)</td>
<td>すく・ない (sukunai)<br />すこ・し (sukoshi)</td>
<td>
A Few, A Little
<br />
<table><tbody><tr><td>Examples:
<br />
少数 (しょうすう - shousuu - minority, few) [n] <Play audio="shousuu" />
<br />
少ない (すくない - sukunai - few, a little, scarce, seldom) [dv] <Play audio="sukunai" />
<br />
少し (すこし - sukoshi - small quantity, little, few) [n] <Play audio="sukoshi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>万</span></th>
<td>マン (man)<br />バン (ban)</td>
<td>よろず (yorozu)</td>
<td>
Ten Thousand
<br />
<table><tbody><tr><td>Examples:
<br />
三万 (さんまん - sanman - 30,000) [n] <Play audio="sanman" />
<br />
万歳 (ばんざい - banzai - banzai (a celebratory cheer), hurrah (hooray, hurray)) [n] <Play audio="banzai" />
<br />
万 (よろず - yorozu - ten thousand, everything, all) [n] <Play audio="yorozu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>半</span></th>
<td>ハン (han)</td>
<td>なか・ば (nakaba)</td>
<td>
Half
<br />
<table><tbody><tr><td>Examples:
<br />
三時半 (さんじはん - sanjihan - 3:30) [n] <Play audio="sanjihan" />
<br />
半ば (なかば - nakaba - middle, half, semi, halfway, partly) [dn] <Play audio="nakaba" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>形</span></th>
<td>ギョウ (gyou)<br />ケイ (kei)</td>
<td>かた (kata)<br />かたち (katachi)</td>
<td>
Shape
<br />
<table><tbody><tr><td>Examples:
<br />
形相 (ぎょうそう - gyousou - features, look, aspect) [n] <Play audio="gyousou" />
<br />
三角形 (さんかっけい - sankakkei - triangle) [n] <Play audio="sankakkei" />
<br />
形無し (かたなし - katanashi - spoiled, ruined) [n] <Play audio="katanashi" />
<br />
形 (かたち - katachi - form, shape, figure) [n] <Play audio="katachi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>太</span></th>
<td>タイ (tai)<br />タ (ta)</td>
<td>ふと・い (futoi)<br />ふと・る (futoru)</td>
<td>
Fat, Very Big
<br />
<table><tbody><tr><td>Examples:
<br />
太鼓 (たいこ - taiko - drum) [n] <Play audio="taiko" />
<br />
太刀 (たち - tachi - long sword) [n] <Play audio="tachi" />
<br />
太い (ふとい - futoi - fat, thick) [dv] <Play audio="futoi" />
<br />
太る (ふとる - futoru - to become fat, to gain weight) [gv] <Play audio="futoru" />
<br />
太股 (ふともも - futomomo - thigh) [n] <Play audio="futomomo" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>細</span></th>
<td>サイ (sai)</td>
<td>ほそ・い (hosoi)<br />ほそ・る (hosoru)<br />こま・か (komaka)<br />こま・かい (komakai)</td>
<td>
Thin
<br />
<table><tbody><tr><td>Examples:
<br />
詳細 (しょうさい - shousai - detail, particulars) [dn] <Play audio="shousai" />
<br />
細い (ほそい - hosoi - thin, slender) [dv] <Play audio="hosoi" />
<br />
細る (ほそる - hosoru - to get thin, to lose weight) [gv] <Play audio="hosoru" />
<br />
細か (こまか - komaka - small, fine, detailed) [dn] <Play audio="komaka" />
<br />
細かい (こまかい - komakai - small, fine, minor) [dv] <Play audio="komakai" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>広</span></th>
<td>コウ (kou)</td>
<td>ひろ・い (hiroi)<br />ひろ・がる (hirogaru)<br />ひろ・げる (hirogeru)<br />ひろ・まる (hiromaru)<br />ひろ・める (hiromeru)</td>
<td>
Wide
<br />
<table><tbody><tr><td>Examples:
<br />
広範囲 (こうはんい - kouhani (houhan'i) - extensive, vast range, wide scope) [dn] <Play audio="kouhan-i" />
<br />
広い (ひろい - hiroi - spacious, vast, wide) [dv] <Play audio="hiroi" />
<br />
広がる　(ひろがる - hirogaru - to spread out, to extend) [gv] <Play audio="hirogaru" />
<br />
広げる (ひろげる - hirogeru - to enlarge, to widen) [iv] <Play audio="hirogeru" />
<br />
広まる　(ひろまる - hiromaru - to spread, to be propagated) [gv] <Play audio="hiromaru" />
<br />
広める (ひろめる - hiromeru - to broaden, to propagate) [iv] <Play audio="hiromeru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>長</span></th>
<td>チョウ (chou)</td>
<td>なが・い (nagai)</td>
<td>
Long
<br />
<table><tbody><tr><td>Examples:
<br />
長寿 (ちょうじゅ - chouju - longevity) [n] <Play audio="chouju" />
<br />
長い (ながい - nagai - long, lengthy) [dv] <Play audio="nagai" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>点</span></th>
<td>テン (ten)</td>
<td></td>
<td>
Point
<br />
<table><tbody><tr><td>Examples:
<br />
出発点 (しゅっぱつてん - shuppatsuten - starting point, point of departure) [n] <Play audio="shuppatsuten" />
<br />
点字 (てんじ - tenji - Braille) [n] <Play audio="tenji" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>丸</span></th>
<td>ガン (gan)</td>
<td>まる (maru)<br />まる・い (marui)</td>
<td>
Circle
<br />
<table><tbody><tr><td>Examples:
<br />
一丸となって (いちがんとなって - ichigan to natte - lumped in one) <Play audio="ichigan　to natte" />
<br />
弾丸 (だんがん - dangan - bullet, shot) [n] <Play audio="dangan" />
<br />
丸 (まる - maru - circle) [n] <Play audio="maru" />
<br />
丸い (まるい - marui - round, circular) [dv] <Play audio="marui" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>交</span></th>
<td>コウ (kou)</td>
<td>まじ・わる (majiwaru)<br />まじ・える (majieru)<br />まじ・る (majiru)<br />ま・ざる (mazaru)<br />ま・ぜる (mazeru)<br />か・う (kau)<br />か・わす (kawasu)</td>
<td>
Mix
<br />
<table><tbody><tr><td>Examples:
<br />
交通 (こうつう - koutsuu - transportation, traffic) [n] <Play audio="koutsuu" />
<br />
交わる (まじわる - majiwaru - to cross, to intersect) [gv] <Play audio="majiwaru" />
<br />
交える (まじえる - majieru - to mix, to combine) [iv] <Play audio="majieru" />
<br />
交る (まじる - majiru - to be mixed, to be blended with) [gv] <Play audio="majiru" />
<br />
交ざる (まざる - mazaru - to be mixed, to be blended with) [gv] <Play audio="mazaru" />
<br />
交ぜる　(まぜる - mazeru - to mix, to stir, to blend) [iv] <Play audio="mazeru" />
<br />
交う (かう - kau - to take turns) [gv] <Play audio="kau" />
<br />
交わす (かわす - kawasu - to exchange) [gv] <Play audio="kawasu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>光</span></th>
<td>コウ (kou)</td>
<td>ひか・る (hikaru)<br />ひかり (hikari)</td>
<td>
Light
<br />
<table><tbody><tr><td>Examples:
<br />
日光 (にっこう - nikkou - sunlight) [n] <Play audio="nikkou" />
<br />
光る (ひかる - hikaru - to shine, to be bright) [gv] <Play audio="hikaru" />
<br />
光 (ひかり - hikari - light) [n] <Play audio="hikari" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>角</span></th>
<td>カク (kaku)</td>
<td>かど (kado)<br />つの (tsuno)<br />すみ (sumi - rarely used)</td>
<td>
Angle, Corner
<br />
<table><tbody><tr><td>Examples:
<br />
角度 (かくど - kakudo - angle) [n] <Play audio="kakudo" />
<br />
曲がり角 (まがりかど - magarikado - street corner, turning point) [n] <Play audio="magarikado" />
<br />
犀の角 (さいのつの - sai no tsuno - rhinoceros horn) [n] <Play audio="sai no tsuno" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>計</span></th>
<td>ケイ (kei)</td>
<td>はか・る (hakaru)<br />はか・らう (hakarau)</td>
<td>
Measure
<br />
<table><tbody><tr><td>Examples:
<br />
計画 (けいかく - keikaku - plan, project, schedule) [n] <Play audio="keikaku" />
<br />
計る (はかる - hakaru - to measure) [gv] <Play audio="hakaru" />
<br />
計らう (はからう - hakarau - to manage, to arrange) [gv] <Play audio="hakarau" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>直</span></th>
<td>ジキ (jiki)<br />チョク (choku)</td>
<td>ただ・ちに (tadachini)<br />なお・す (naosu)<br />なお・る (naoru)</td>
<td>
Straight, Correct
<br />
<table><tbody><tr><td>Examples:
<br />
正直 (しょうじき - shoujiki - honesty, integrity) [dn] <Play audio="shoujiki" />
<br />
直接 (ちょくせつ - chokusetsu - direct, immediate) [dn] <Play audio="chokusetsu" />
<br />
直ちに (ただちに - tadachini - at once, immediately, directly, in person) <Play audio="tadachini" />
<br />
直す (なおす - naosu - to fix, to correct) [gv] <Play audio="naosu" />
<br />
直る (なおる - naoru - to be repaired, to be fixed) [gv] <Play audio="naoru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>線</span></th>
<td>セン (sen)</td>
<td></td>
<td>
Line
<br />
<table><tbody><tr><td>Examples:
<br />
点線 (てんせん - tensen - dotted line, perforated line) [n] <Play audio="tensen" />
<br />
新幹線 (しんかんせん - shinkansen - bullet train) [n] <Play audio="shinkansen" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>矢</span></th>
<td>シ (shi)</td>
<td>や (ya)</td>
<td>
Arrow
<br />
<table><tbody><tr><td>Examples:
<br />
嚆矢 (こうし - koushi - the start of things) [n] <Play audio="koushi" />
<br />
矢印 (やじるし - yajirushi - arrow (mark or symbol), directional marker or indicator) [n] <Play audio="yajirushi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>弱</span></th>
<td>ジャク (jaku)</td>
<td>よわ・い (yowai)<br />よわ・る (yowaru)<br />よわ・まる (yowamaru)<br />よわ・める (yowameru)</td>
<td>
Weak
<br />
<table><tbody><tr><td>Examples:
<br />
弱点 (じゃくてん - jakuten - weak point, weakness) [n] <Play audio="jakuten" />
<br />
弱い (よわい - yowai - weak, frail, delicate) [dv] <Play audio="yowai" />
<br />
弱る (よわる - yowaru - to weaken, to be troubled, to be downcast, to be emaciated, to be dejected, to be perplexed, to impair) [gv] <Play audio="yowaru" />
<br />
弱まる (よわまる - yowamaru - to abate, to weaken, to be emaciated, to be dejected, to be perplexed) [gv] <Play audio="yowamaru" />
<br />
弱める (よわめる - yowameru - to weaken) [iv] <Play audio="yowameru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>強</span></th>
<td>ゴウ (gou)<br />キョウ (kyou)</td>
<td>つよ・い (tsuyoi)<br />つよ・まる (tsuyomaru)<br />つよ・める (tsuyomeru)<br />し・いる (shiiru)</td>
<td>
Strong
<br />
<table><tbody><tr><td>Examples:
<br />
強引 (ごういん - gouin - overbearing, coercive, pushy) [dn] <Play audio="gouin" />
<br />
勉強する (べんきょうする - benkyou suru - to study) [irv] <Play audio="benkyou suru" />
<br />
強い (つよい - tsuyoi - strong, powerful, mighty, potent) [dv] <Play audio="tsuyoi" />
<br />
強まる (つよまる - tsuyomaru - to get strong, to gain strength) [gv] <Play audio="tsuyomaru" />
<br />
強める (つよめる - tsuyomeru - to strengthen, to emphasize, to emphasise) [iv] <Play audio="tsuyomeru" />
<br />
強いる (しいる - shiiru - to force, to compel, to coerce) [iv] <Play audio="shiiru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>高</span></th>
<td>コウ (kou)</td>
<td>たか (taka)<br />たか・い (takai)<br />たか・まる (takamaru)<br />たか・める (takameru)</td>
<td>
Tall, High
<br />
<table><tbody><tr><td>Examples:
<br />
高速道路 (こうそくどうろ - kousokudouro - highway, freeway) [n] <Play audio="kousokudouro" />
<br />
高上がり (たかあがり - takaagari - climb high, more expensive than expected) [n] <Play audio="takaagari" />
<br />
高い (たかい - takai - high, tall, expensive) [dv] <Play audio="takai" />
<br />
高まる (たかまる - takamaru - to rise, to swell, to be promoted) [gv] <Play audio="takamaru" />
<br />
高める (たかめる - takameru - to raise, to lift, to boost, to enhance) [iv] <Play audio="takameru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>同</span></th>
<td>ドウ (dou)</td>
<td>おな・じ (onaji)</td>
<td>
Same
<br />
<table><tbody><tr><td>Examples:
<br />
同級 (どうきゅう - doukyuu - the same grade, same class) [n] <Play audio="doukyuu" />
<br />
同級生 (どうきゅうせい - doukyuusei - classmate, classmates) [n] <Play audio="doukyuusei" />
<br />
同じ (おなじ - onaji - same, identical, equal) [n] <Play audio="onaji" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>親</span></th>
<td>シン (shin)</td>
<td>おや (oya)<br />した・しい (shitashii)<br />した・しむ (shitashimu)</td>
<td>
Parent
<br />
<table><tbody><tr><td>Examples:
<br />
両親 (りょうしん - ryoushin - parents, both parents) [n] <Play audio="ryoushin" />
<br />
親 (おや - oya - parent, parents) [n] <Play audio="oya" />
<br />
親しい (したしい - shitashii - intimate, close (e.g. friend)) [dv] <Play audio="shitashii" />
<br />
親しむ (したしむ - shitashimu - to befriend) [gv] <Play audio="shitashimu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>母</span></th>
<td>ボ (bo)</td>
<td>はは (haha)<br />お・かあ・さん (okaasan)</td>
<td>
Mother
<br />
<table><tbody><tr><td>Examples:
<br />
保母 (ほぼ - hobo - day-care worker) [n] <Play audio="hobo" />
<br />
母 (はは - haha - mother (what you would call your own mother when talking to others)) [n] <Play audio="haha" />
<br />
お母さん (おかあさん - okaasan - mother (what you would call someone else's mother or your own mother when talking to her)) [n] <Play audio="okaasan" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>父</span></th>
<td>フ (fu)</td>
<td>ちち (chichi)<br />お・とう・さん (otousan)</td>
<td>
Father
<br />
<table><tbody><tr><td>Examples:
<br />
父兄　(ふけい - fukei - guardians, parents and older brothers) [n] <Play audio="fukei" />
<br />
父 (ちち - chichi - father (what you would call your own father when talking to others)) [n] <Play audio="chichi" />
<br />
お父さん (おとうさん - otousan - mother (what you would call someone else's father or your own father when talking to him)) [n] <Play audio="otousan" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>姉</span></th>
<td>シ (shi)</td>
<td>あね (ane)<br />お・ねえ・さん (oneesan)</td>
<td>
Older Sister
<br />
<table><tbody><tr><td>Examples:
<br />
姉妹 (しまい - shimai - sisters) [n] <Play audio="shimai" />
<br />
姉 (あね - ane - older sister (what you would call your own older sister when talking to others)) [n] <Play audio="ane" />
<br />
お姉さん (おねえさん - oneesan - older sister (what you would call someone else's older sister or your own older sister when talking to her)) [n] <Play audio="oneesan" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>兄</span></th>
<td>キョウ (kyou)<br />ケイ (kei)</td>
<td>あに (ani)<br />お・にい・さん (oniisan)</td>
<td>
Older Brother
<br />
<table><tbody><tr><td>Examples:
<br />
兄弟 (きょうだい - kyoudai - brothers, siblings) [n] <Play audio="kyoudai" />
<br />
父兄　(ふけい - fukei - guardians, parents and older brothers) [n] <Play audio="fukei" />
<br />
兄 (あに - ani - older brother (what you would call your own older brother when talking to others)) [n] <Play audio="ani" />
<br />
お兄さん (おにいさん - oniisan - older brother (what you would call someone else's older brother or your own older brother when talking to him)) [n] <Play audio="oniisan" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>弟</span></th>
<td>デ (de)<br />ダイ (dai)<br />テイ (tei)</td>
<td>おとうと (otouto)</td>
<td>
Younger Brother
<br />
<table><tbody><tr><td>Examples:
<br />
弟子 (でし - deshi - pupil, disciple, follower) [n] <Play audio="deshi" />
<br />
兄弟 (きょうだい - kyoudai - brothers, siblings) [n] <Play audio="kyoudai" />
<br />
師弟 (してい - shitei - teacher and student) [n] <Play audio="shitei" />
<br />
弟 (おとうと - otouto - younger brother) [n] <Play audio="otouto" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>妹</span></th>
<td>マイ (mai)</td>
<td>いもうと (imouto)</td>
<td>
Younger Sister
<br />
<table><tbody><tr><td>Examples:
<br />
姉妹 (しまい - shimai - sisters) [n] <Play audio="shimai" />
<br />
妹 (いもうと - imouto - younger sister) [n] <Play audio="imouto" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>自</span></th>
<td>ジ (ji)<br />シ (shi - rarely used)</td>
<td>みずか・ら (mizukara)</td>
<td>
Oneself
<br />
<table><tbody><tr><td>Examples:
<br />
自分 (じぶん - jibun - myself, yourself, oneself) [n] <Play audio="jibun" />
<br />
自ら (みずから - mizukara - for one's self, personally) [n] <Play audio="mizukara" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>友</span></th>
<td>ユウ (yuu)</td>
<td>とも (tomo)</td>
<td>
Friend
<br />
<table><tbody><tr><td>Examples:
<br />
友人 (ゆうじん - yuujin - friend) [n] <Play audio="yuujin" />
<br />
友達 (ともだち - tomodachi - friend, companion) [n] <Play audio="tomodachi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>体</span></th>
<td>タイ (tai)<br />テイ (tei)</td>
<td>からだ (karada)</td>
<td>
Body
<br />
<table><tbody><tr><td>Examples:
<br />
体格 (たいかく - taikaku - physique) [n] <Play audio="taikaku" />
<br />
体裁 (ていさい - teisai - decency, style, appearance) [n] <Play audio="teisai" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>毛</span></th>
<td>モウ (mou)</td>
<td>け (ke)</td>
<td>
Hair
<br />
<table><tbody><tr><td>Examples:
<br />
毛布 (もうふ - moufu - blanket) [n] <Play audio="moufu" />
<br />
髪の毛 (かみのけ - kami no ke - hair (on the head)) [n] <Play audio="kami no ke" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>頭</span></th>
<td>トウ (tou)</td>
<td>あたま (atama)</td>
<td>
Head
<br />
<table><tbody><tr><td>Examples:
<br />
口頭 (こうとう - koutou - oral) [n] <Play audio="koutou" />
<br />
頭 (あたま - atama - head) [n] <Play audio="atama" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>顔</span></th>
<td>ガン (gan)</td>
<td>かお (kao)</td>
<td>
Face
<br />
<table><tbody><tr><td>Examples:
<br />
洗顔する (せんがんする - sengan suru - to wash one's face) [irv] <Play audio="sengan suru" />
<br />
顔 (かお - kao - face) [n] <Play audio="kao" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>首</span></th>
<td>シュ (shu)</td>
<td>くび (kubi)</td>
<td>
Neck
<br />
<table><tbody><tr><td>Examples:
<br />
首相 (しゅしょう - shushou - Prime Minister, Chancellor, Premier) [n] <Play audio="shushou" />
<br />
首 (くび - kubi - neck) [n] <Play audio="kubi" />
<br />
首にする (くびにする - kubi ni suru - to fire from a job) [irv] <Play audio="kubi ni suru" />
<br />
首になる (くびになる - kubi ni naru - to be fired from a job) [gv] <Play audio="kubi ni naru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>心</span></th>
<td>シン (shin)</td>
<td>こころ (kokoro)</td>
<td>
Heart
<br />
<table><tbody><tr><td>Examples:
<br />
心臓 (しんぞう - shinzou - heart (the organ)) [n] <Play audio="shinzou" />
<br />
心 (こころ - kokoro - mind, heart, spirit) [n] <Play audio="kokoro" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>時</span></th>
<td>ジ (ji)</td>
<td>とき (toki)</td>
<td>
Time
<br />
<table><tbody><tr><td>Examples:
<br />
時期 (じき - jiki - time, season, period) [n] <Play audio="jiki" />
<br />
六時 (ろくじ - rokuji - 6:00) [n] <Play audio="rokuji" />
<br />
時 (とき - toki - time, hour, occasion, moment) [n] <Play audio="toki" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>曜</span></th>
<td>ヨウ (you)</td>
<td></td>
<td>
Weekday
<br />
<table><tbody><tr><td>Examples:
<br />
土曜日 (どようび - doyoubi - Saturday) [n] <Play audio="doyoubi" />
</td></tr></tbody></table></td>
</tr>
</tbody></table>

    </>
  )
}

export default GradeTwoOne