import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "100 Most Useful Phrases Part 4 of 4"

const commonfour1 = [
  {"漢字 - Kanji":"足元に気をつけてください ","ひらがな - Hiragana":"あしもとにきをつけてください ","Romaji (English Letters)":"ashimoto ni ki o tsukete kudasai","English Meaning":"Please watch your step", "audio": "ashimoto ni ki o tsukete kudasai"}
]
const commonfour2 = [
  {"漢字 - Kanji":"寄り道をしないで帰って来て ","ひらがな - Hiragana":"よりみちをしないでかえってきて ","Romaji (English Letters)":"yorimichi o shinaide kaette kite","English Meaning":"Come straight home", "audio": "yorimichi o shinaide kaette kite"}
]
const commonfour3 = [
  {"漢字 - Kanji":"今日は雨が降るから傘を忘れないで ","ひらがな - Hiragana":"きょうはあめがふるからかさをわすれないで ","Romaji (English Letters)":"kyou wa ame ga furu kara kasa o wasurenaide","English Meaning":"It's going to rain today so don't forget an umbrella", "audio": "kyou wa ame ga furu kara kasa o wasurenaide"}
]
const commonfour4 = [
  {"漢字 - Kanji":"今日はとても蒸し暑いです ","ひらがな - Hiragana":"きょうはとてもむしあついです ","Romaji (English Letters)":"kyou wa totemo mushi atsui desu","English Meaning":"It is very hot and humid today", "audio": "kyou wa totemo mushi atsui desu"}
]
const commonfour5 = [
  {"漢字 - Kanji":"夕立になりそうな天気です ","ひらがな - Hiragana":"ゆうだちになりそうなてんきです ","Romaji (English Letters)":"yuudachi ni narisou na tenki desu","English Meaning":"It looks like it's going to rain tonight", "audio": "yuudachi ni narisou na tenki desu"}
]
const commonfour6 = [
  {"漢字 - Kanji":"台風が近づいています ","ひらがな - Hiragana":"たいふうがちかづいています ","Romaji (English Letters)":"taifuu ga chikazuite imasu","English Meaning":"A typhoon is getting close", "audio": "taifuu ga chikazuite imasu"}
]
const commonfour7 = [
  {"漢字 - Kanji":"今日は曇りのち晴れです ","ひらがな - Hiragana":"きょうはくもりのちはれです ","Romaji (English Letters)":"kyou wa kumori nochi hare desu","English Meaning":"Today will be cloudy but clear later", "audio": "kyou wa kumori nochi hare desu"}
]
const commonfour8 = [
  {"漢字 - Kanji":"今晩は風がとても強いです ","ひらがな - Hiragana":"こんばんはかぜがとてもつよいです ","Romaji (English Letters)":"konban wa kaze ga totemo tsuyoi desu","English Meaning":"We will have very strong winds tonight", "audio": "konban wa kaze ga totemo tsuyoi desu"}
]
const commonfour9 = [
  {"漢字 - Kanji":"最近、何の映画を見ましたか ","ひらがな - Hiragana":"さいきん、なんのえいがをみましたか ","Romaji (English Letters)":"saikin, nan no eiga o mimashita ka","English Meaning":"What movies have you seen recently?", "audio": "saikin nan no eiga o mimashita ka"}
]
const commonfour10 = [
  {"漢字 - Kanji":"趣味は何ですか ","ひらがな - Hiragana":"しゅみはなんですか ","Romaji (English Letters)":"shumi wa nan desu ka","English Meaning":"What are your hobbies?", "audio": "shumi wa nan desu ka"}
]
const commonfour11 = [
  {"漢字 - Kanji":"どういたしまして ","ひらがな - Hiragana":"どういたしまして ","Romaji (English Letters)":"douitashimashite","English Meaning":"You're welcome", "audio": "douitashimashite"}
]
const commonfour12 = [
  {"漢字 - Kanji":"さよなら ","ひらがな - Hiragana":"さよなら ","Romaji (English Letters)":"sayonara","English Meaning":"Good-bye", "audio": "sayonara"}
]
const commonfour13 = [
  {"漢字 - Kanji":"結構です ","ひらがな - Hiragana":"けっこうです ","Romaji (English Letters)":"kekkou desu","English Meaning":"It's fine, I'm fine, No thank you", "audio": "kekkou desu"}
]
const commonfour14 = [
  {"漢字 - Kanji":"はい , いいえ , いや ","ひらがな - Hiragana":"はい , いいえ , いや ","Romaji (English Letters)":"hai , iie , iya","English Meaning":"Yes, No, No", "audio": "hai iie iya"}
]
const commonfour15 = [
  {"漢字 - Kanji":"もしもし ","ひらがな - Hiragana":"もしもし ","Romaji (English Letters)":"moshi moshi","English Meaning":"Hello", "audio": "moshi moshi"}
]
const commonfour16 = [
  {"漢字 - Kanji":"どうぞ、お入りください ","ひらがな - Hiragana":"どうぞ、おはいりください ","Romaji (English Letters)":"douzo, ohairi kudasai","English Meaning":"Please come on in", "audio": "douzo ohairi kudasai"}
]
const commonfour17 = [
  {"漢字 - Kanji":"この席は空いていますか ","ひらがな - Hiragana":"このせきはあいていますか ","Romaji (English Letters)":"kono seki wa aite imasu ka","English Meaning":"Is this seat taken?", "audio": "kono seki wa aite imasu ka"}
]
const commonfour18 = [
  {"漢字 - Kanji":"この道をまっすぐ行くと突き当たります ","ひらがな - Hiragana":"このみちをまっすぐいくとつきあたります ","Romaji (English Letters)":"kono michi o massugu iku to tsukiatarimasu","English Meaning":"If you go straight on this road you'll reach your destination", "audio": "kono michi o massugu iku to tsukiatarimasu"}
]
const commonfour19 = [
  {"漢字 - Kanji":"この四つ角を右に曲がってください ","ひらがな - Hiragana":"このよつかどをみぎにまがってください ","Romaji (English Letters)":"kono yotsukado o migi ni magatte kudasai","English Meaning":"Please turn right at the next intersection", "audio": "kono yotsukado o migi ni magatte kudasai"}
]
const commonfour20 = [
  {"漢字 - Kanji":"車を道路わきに寄せます ","ひらがな - Hiragana":"くるまをどうろわきによせます ","Romaji (English Letters)":"kuruma o dourowaki ni yosemasu","English Meaning":"I'm going to pull the car over", "audio": "kuruma o dourowaki ni yosemasu"}
]
const commonfour21 = [
  {"漢字 - Kanji":"信号が青になったら行きます ","ひらがな - Hiragana":"しんごうがあおになったらいきます ","Romaji (English Letters)":"shingou ga ao ni nattara ikimasu","English Meaning":"I will go when the traffic light turns green", "audio": "shingou ga ao ni nattara ikimasu"}
]
const commonfour22 = [
  {"漢字 - Kanji":"身分証明書を見せてくれますか ","ひらがな - Hiragana":"みぶんしょうめいしょをみせてくれますか ","Romaji (English Letters)":"mibunshoumeisho o misete kuremasu ka","English Meaning":"Will you please show me your ID?", "audio": "mibunshoumeisho o misete kuremasu ka"}
]
const commonfour23 = [
  {"漢字 - Kanji":"すみません、これを落としましたよ ","ひらがな - Hiragana":"すみません、これをおとしましたよ ","Romaji (English Letters)":"sumimasen, kore o otoshimashita yo","English Meaning":"Excuse me but you dropped this", "audio": "sumimasen kore o otoshimashita yo"}
]
const commonfour24 = [
  {"漢字 - Kanji":"この近くにレストランはありますか ","ひらがな - Hiragana":"このちかくにれすとらんはありますか ","Romaji (English Letters)":"kono chikaku ni resutoran wa arimasu ka","English Meaning":"Is there a restaurant near here?", "audio": "kono chikaku ni resutoran wa arimasu ka"}
]
const commonfour25 = [
  {"漢字 - Kanji":"一番好きな食べ物は何ですか ","ひらがな - Hiragana":"いちばんすきなたべものはなんですか ","Romaji (English Letters)":"ichiban suki na tabemono wa nan desu ka","English Meaning":"What is your favorite food?", "audio": "ichiban suki na tabemono wa nan desu ka"}
]

const CommonFour = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Beginner" category="Phrases" lesson={title} />
     <div className={'title'}>
     {title}
      </div>
      <h1>{title}</h1>

      <p>Below are 25 of the most common/useful phrases in the Japanese language and a breakdown/explanation of each. To some extent, this lesson assumes that you have already gone through <Link to="">Part 1</Link>, <Link to="">Part 2</Link>, and <Link to="">Part 3</Link>.</p>

      <Table data={commonfour1} />

      <p><strong>Explanation:</strong></p>
<p>足 (ashi) means "foot" and 元 (moto) means "base". Put together they mean "at one's feet, underfoot". The verb 気をつける <Play audio="ki o tsukeru" /> means "to be careful, to pay attention, to take care" and is very commonly used.</p>

      <Table data={commonfour2} />

      <p><strong>Explanation:</strong></p>
      <p>The verb 寄る <Play audio="yoru" /> means "to visit, to drop in, to approach" and 道 (michi) means "road, street, way, path" and, when put together as "yorimichi", it means "dropping in on the way, breaking one's journey, going out of one's way". Base 1 + "naide" is the negative command form so "shinaide" is the negative command form for the verb "suru" (to do). The literal translation of this sentence would be "come home and don't make any stops along the way". Make this sentence polite form by adding "kudasai" on the end.</p>

      <Table data={commonfour3} />

      <p><strong>Explanation:</strong></p>
      <p>The verb 降る <Play audio="furu" /> means "to precipitate, to fall" and is usually only used with rain (雨 - ame), snow, etc. The verb 忘れる <Play audio="wasureru" /> means "to forget" and, like above, this is the negative command form (Base 1 + naide). Make this sentence polite form by adding "kudasai" on the end.</p>

      <Table data={commonfour4} />

      <p><strong>Explanation:</strong></p>
      <p>Japan has very hot and humid summers so this is a pretty common phrase. 暑い <Play audio="atsui" /> means "hot (weather)". There is a different "atsui" (熱い) for an item (like a stove) that is "hot" and another "atsui" (厚い) that means "thick, deep, heavy" so be sure to use the correct one when writing. The kanji 蒸 means "steam" so "mushi atsui" literally means "steaming hot". You may also hear "mushi mushi" which basically means the same thing.</p>

      <Table data={commonfour5} />

      <p><strong>Explanation:</strong></p>
      <p>Japan also has a very intense rainy season (some places have two of them). 夕立 <Play audio="yuudachi" /> is a word specific to "(sudden) evening showers" and 天気 <Play audio="tenki" /> means "weather". The verb なる <Play audio="naru" /> means "to become" and Base 2 + "sou" means "looks like will verb" which then becomes a "dn" (descriptive noun - <Link to="/members/b/grammar/descriptive01/">Descriptive Words Part 1</Link>) and can modify a noun by adding "na".</p>

      <Table data={commonfour6} />

      <p><strong>Explanation:</strong></p>
      <p>The word 台風 (taifuu) literally means "big wind" and is where the English word "typhoon" comes from. They can be fairly common in Japan. The verb 近づく <Play audio="chikazuku" /> means "to approach, to draw near, to get close".</p>
      
      <Table data={commonfour7} />

      <p><strong>Explanation:</strong></p>
      <p>The verb 曇る <Play audio="kumoru" /> means "to get cloudy, to cloud over, to become overcast" and in base 2 ("noun form") it means "cloudiness, cloudy weather". "nochi" can be written 後 but this is typically read "ato". It means "later, afterwards, since". The verb 晴れる (<Play audio="hareru" />  - not to be confused with 腫れる (hareru - to swell)) means "to clear up, to be sunny, to stop raining" and in base 2 ("noun form") it means "clear weather" so "kumori nochi hare" means "cloudy weather now, clear weather later" and is a common way to describe the weather.</p>

      <Table data={commonfour8} />

      <p><strong>Explanation:</strong></p>
      <p>This is a good example of how Japanese present tense can also be used for future tense by specifying a time in the future. 風 (kaze - not to be confused with 風邪 (kaze - cold (illness), common cold)) means "wind". This kanji can be seen a couple sentences above in the word "taifuu".</p>
      
      <Table data={commonfour9} />

      <p><strong>Explanation:</strong></p>
      <p>映画 <Play audio="eiga" /> is "movie" and the verb 見る <Play audio="miru" /> means "to see". Combined as 映画を見る <Play audio="eiga o miru" /> means "to watch a movie". 最近 <Play audio="saikin" /> means "most recent, these days, right now, recently, nowadays". 何, as seen in other sentences, can be read "nani" (what) but can also be read "nan" depending on where in the sentence it is used or what comes after it.</p>

      <Table data={commonfour10} />

      <p><strong>Explanation:</strong></p>
      <p>Some of the biggest topics of conversation in Japan are weather, health, and hobbies. 何 is once again read "nan" instead of "nani" here. It typically is when right before "desu".</p>

      <Table data={commonfour11} />

      <p><strong>Explanation:</strong></p>
      <p>Since politeness is very important in Japanese, this is a very good phrase to learn. This may not make much sense but, "douitashimashite" is made up of "dou" (how, in what way, how about) and the verb 致す <Play audio="itasu" /> which is the honorific form of "suru" (to do).</p>

      <Table data={commonfour12} />

      <p><strong>Explanation:</strong></p>
      <p>This is one of the more polite ways to say "good-bye". Elongating the "yo" sound <Play audio="sayounara" /> when speaking increases the politeness. More informal ("plain form") ways to say "good-bye" include <Play audio="jaa ne" />  and <Play audio="mata ne" />  ("jaa" means "well..., so..., well then..." and "mata" means "again").</p>

      <Table data={commonfour13} />

      <p><strong>Explanation:</strong></p>
      <p>"kekkou" is a "dn" descriptive word that means "nice, sufficient, fine" and is used quite commonly. If we replace "totemo" in the wind sentence above with "kekkou", it would change from "very strong" to "pretty strong, rather strong". "kekkou desu" means two very different things depending on how it's said. Said it in a positive way, you could be agreeing to whatever someone might be trying to give you or do for you. Said in a negative way (especially with a hand wave in front of your face or by prefacing the phrase with "iie" or "iya" (see below)), it can mean "no thank you". A parallel phrase is "ii desu" (It's good, I'm good, No thank you).</p>

      <Table data={commonfour14} />

      <p><strong>Explanation:</strong></p>
      <p>What could be more common than "yes" and "no"? "iie" is most commonly taught for "no" but this is more often used for turning down thanks instead of for answering "no" to a question. While more of an informal version of "iie", "iya" (or "iiya") is more commonly used when answering "no" to something (even in polite form).</p>

      <Table data={commonfour15} />

      <p><strong>Explanation:</strong></p>
      <p>One of the most misused Japanese phrases, "moshi moshi" means "hello" but it is ONLY used to answer the telephone or check to see if the other person is still there (Japanese people are used to the other person giving off small grunts to confirm that they are there and still listening). <Play audio="moshi" />  by itself means "if" and it's very important to pronounce this with an "o" sound and not a "u" sound (a very common mistake) as "mushi"  means "bug, insect". "moshi moshi" might sound more like "mushi mushi" but I assure you, it's not.</p>

      <Table data={commonfour16} />

      <p><strong>Explanation:</strong></p>
      <p><Play audio="douzo" />  is another commonly misused word. It is often taught as "please" but it is not a "please perform this action" kind of "please" but, instead, a "please go ahead" kind of "please". It would also fit "go ahead" or "by all means". For example, if someone were to ask if they could use your cell phone, you might reply "hai, douzo" (yes, go right ahead). The verb 入る <Play audio="hairu" /> means "to enter" and "o" + base 2 + "kudasai" is a very polite command form. Despite how it sounds, "hairu" is a godan verb so base 2 would be "hairi".</p>

      <Table data={commonfour17} />

      <p><strong>Explanation:</strong></p>
      <p>空く can be read "suku" (which we've seen previously with "I'm hungry") and "aku" so we have to rely on context here. The verb 空く <Play audio="aku" /> means "to be empty, to be vacant, to be available, to be free". As you've likely determined, 席 (<Play audio="seki" />  - not to be confused with 咳 (seki - cough)) means "chair, seat".</p>

      <Table data={commonfour18} />

      <p><strong>Explanation:</strong></p>
      <p>This is the same "michi" as above. <Play audio="massugu" />  means "straight (ahead), direct, upright" and can also be written 真っ直ぐ (真 means "pure, genuine, true" and 直ぐ, "sugu", means "immediately, at once, directly" so, combined, it means "completely direct" (straight)). Base 3 + "to" is very similar to the "if" forms base 4 + "ba" and base "ta" + "ra" but has more of a "thereupon", "and then" feeling. The verb 突く <Play audio="tsuku" /> means "to stab, to hit, to strike" and the verb 当たる <Play audio="ataru" /> can mean a lot of different things including "to be hit, to strike, to touch, to be in contact, to hit the intended target, etc." but, when combined into "tsukiataru", it means "to run into, to collide with, to come to the end of (a street)". In this case it's "coming to the end of" the journey.</p>

      <Table data={commonfour19} />

      <p><strong>Explanation:</strong></p>
      <p>"yotsukado" literally means "4 corners". The verb 曲がる <Play audio="magaru" /> means "to bend, to curve, to turn". While "kono" technically means "this", what we're really talking about is the "next" or the "upcoming" intersection. Replace "migi" with 左 <Play audio="hidari" /> for "left".</p>

      <Table data={commonfour20} />

      <p><strong>Explanation:</strong></p>
      <p>車 <Play audio="kuruma" /> is "car", 道路 <Play audio="douro" /> is "road", and "waki" means "beside, close to, near, by, aside, to the side, out of the way" so 道路わき <Play audio="dourowaki" /> means "side of the road". The verb 寄る (yoru) was covered previously and 寄せる <Play audio="yoseru" /> is the transitive form of this verb. It means "to come near, to let near" but, all together, "kuruma o dourowaki ni yoseru" means "to pull a car over". To change this into command form, take the "masu" off the end and replace it with "te kudasai".</p>

      <Table data={commonfour21} />

      <p><strong>Explanation:</strong></p>
      <p>Then kanji in 信号 <Play audio="shingou" /> mean "honesty, fidelity, trust, reliance, faith" and "number, edition" but together they somehow mean "traffic light". While in English we would call it a green light, in Japanese they call it a blue light. 青 <Play audio="ao" /> is the color blue and 青い <Play audio="aoi" /> is the adjective "blue". "Red" (the color) is 赤 <Play audio="aka" /> and "yellow" is 黄色 <Play audio="kiiro" />. Add an "i" to either of these to make it an adjective. Base "ta" + "ra" creates a "when verb" form which can also act like an "if verb".</p>

      <Table data={commonfour22} />

      <p><strong>Explanation:</strong></p>
      <p>身分 <Play audio="mibun" /> means "social position, social status"; 証明 <Play audio="shoumei" /> means "proof, verification"; and 書 <Play audio="sho" /> means "document, book" (this is the kanji for 書く (kaku - to write)). Together they mean "identification papers" or "ID". 見せる <Play audio="miseru" /> is the transitive form of 見る (miru - to see) and means "to show, to display".</p>

      <Table data={commonfour23} />

      <p><strong>Explanation:</strong></p>
      <p>The verb 落とす <Play audio="otosu" /> means "to drop".</p>

      <Table data={commonfour24} />

      <p><strong>Explanation:</strong></p>
      <p>The "dv" adjective 近い <Play audio="chikai" /> means "near, close". While "chikaku" is the adverb form, it is also a noun on its own which means "near, vicinity".</p>

      <Table data={commonfour25} />

      <p><strong>Explanation:</strong></p>
      <p>Technically, "favorite" would be 大好き <Play audio="daisuki" /> but 一番好き <Play audio="ichiban suki" />, which would mean "favorite-est" if that were a word, is basically the same thing. Feel free to use "daisuki" instead if you'd like. 食べ物 <Play audio="tabemono" /> means "food" and is made up of 食べ, which is the "noun form" of the verb 食べる (taberu - to eat), and 物 (mono - thing, object).</p>


    </>
  )
}

export default CommonFour