import React from "react"

import { getProfile } from "../src/utils/auth"

const Profile = () => {
  const user = getProfile()

  return (
    <>
      <h1>Your profile</h1>
      <ul>
        {/* TODO: correct these... */}
        <li>Name: {user.name}</li>
        <li>E-mail: {user.email}</li>
      </ul>
    </>
  )
}

export default Profile
