import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../../components/NavBar"
import Table from "@components/Table"

const title = "L Names In Japanese"

export const namesL = [
  {"Name": "Aaron", "Katakana": "アーロン", "Romaji": "a-ron", "audio": "a-ron"}
]

const LNamesInJapanese = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Beginner" category="Vocab" lesson={title} />
     <div className={'title'}>
     {title}
      </div>
      <h1>{title}</h1>

      <Table data={namesL} />

    </>
  )
}

export default LNamesInJapanese