import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Japanese Sound Words (Onomatopoeia)"

const soundwordsone = [
  {"Katakana":"ワンワン","Romaji":"wan wan","audio":"wan wan","English Meaning":"The sound a dog makes(bow wow, bark bark, woof woof)"},
 {"Katakana":"ニャーニャー","Romaji":"nya- nya-","audio":"nya- nya-","English Meaning":"The sound a cat makes(meow meow)"},
 {"Katakana":"ブーブー","Romaji":"bu- bu-","audio":"bu- bu-","English Meaning":"The sound a pig makes(oink oink)"},
 {"Katakana":"ヒンヒン","Romaji":"hin hin","audio":"hin hin","English Meaning":"The sound a horse makes(neigh neigh)"},
 {"Katakana":"ゲロゲロ","Romaji":"gero gero","audio":"gero gero","English Meaning":"The sound a frog makes(ribbit ribbit)"}
]

const soundwordstwo = [
  {"Kanji":"夜中","Hiragana":"よなか","Romaji":"yonaka","English Meaning":"middle of the night","audio":"yonaka"},
 {"Kanji":"となり","Hiragana":"となり","Romaji":"tonari","English Meaning":"beside, next to, next door","audio":"tonari"},
 {"Kanji":"犬","Hiragana":"いぬ","Romaji":"inu","English Meaning":"dog","audio":"inu"},
 {"Kanji":"吠える","Hiragana":"ほえる","Romaji":"hoeru","English Meaning":"to bark","audio":"hoeru"},
 {"Kanji":"寝る","Hiragana":"ねる","Romaji":"neru","English Meaning":"to sleep","audio":"neru"},
 {"Kanji":"日本","Hiragana":"にほん","Romaji":"nihon","English Meaning":"Japan","audio":"nihon"},
 {"Kanji":"梅雨","Hiragana":"つゆ","Romaji":"tsuyu","English Meaning":"rainy season","audio":"tsuyu"},
 {"Kanji":"蛙","Hiragana":"かえる","Romaji":"kaeru","English Meaning":"frog","audio":"kaeru"},
 {"Kanji":"なく","Hiragana":"なく","Romaji":"naku","English Meaning":"to cry, make a sound","audio":"naku"},
 {"Kanji":"うるさい","Hiragana":"うるさい","Romaji":"urusai","English Meaning":"noisy","audio":"urusai"}
]

const soundwordsthree = [
  {"Kanji":"日","Hiragana":"ひ","Romaji":"hi","English Meaning":"sun","audio":"hi"},
 {"Kanji":"照る","Hiragana":"てる","Romaji":"teru","English Meaning":"to shine","audio":"teru"},
 {"Kanji":"雨","Hiragana":"あめ","Romaji":"ame","English Meaning":"rain","audio":"ame"},
 {"Kanji":"降る","Hiragana":"ふる","Romaji":"furu","English Meaning":"to precipitate, to fall (rain)","audio":"furu"},
 {"Kanji":"風","Hiragana":"かぜ","Romaji":"kaze","English Meaning":"wind","audio":"kaze"},
 {"Kanji":"吹く","Hiragana":"ふく","Romaji":"fuku","English Meaning":"to blow","audio":"fuku"},
 {"Kanji":"雷","Hiragana":"かみなり","Romaji":"kaminari","English Meaning":"thunder","audio":"kaminari"},
 {"Kanji":"鳴る","Hiragana":"なる","Romaji":"naru","English Meaning":"to sound, to echo","audio":"naru"}
]

const soundwordsfour = [
  {"Katakana":"イライラ","Romaji":"ira ira","audio":"ira ira","English Meaning":"Irritated or on edge"},
 {"Katakana":"ワクワク","Romaji":"waku waku","audio":"waku waku","English Meaning":"The sound of being excited"},
 {"Katakana":"ドキドキ","Romaji":"doki doki","audio":"doki doki","English Meaning":"The sound of a throbing heart beat(Nervous)"},
 {"Katakana":"ヒリヒリ","Romaji":"hiri hiri","audio":"hiri hiri","English Meaning":"Stinging pain"},
 {"Katakana":"カンカン","Romaji":"kan kan","audio":"kan kan","English Meaning":"Anger (or intense heat)"}
]

const soundwordsfive = [
  {"Kanji":"子供たち","Hiragana":"こどもたち","Romaji":"kodomotachi","English Meaning":"children","audio":"kodomotachi"},
 {"Kanji":"言う","Hiragana":"いう","Romaji":"iu","English Meaning":"to say","audio":"iu"},
 {"Kanji":"聞く","Hiragana":"きく","Romaji":"kiku","English Meaning":"to hear, to listen, to ask","audio":"kiku"},
 {"Kanji":"から","Hiragana":"から","Romaji":"kara","English Meaning":"because (when following a verb)","audio":"kara"},
 {"Kanji":"お母さん","Hiragana":"おかあさん","Romaji":"okaasan","English Meaning":"mother (someone else's)","audio":"okaasan"},
 {"Kanji":"クリスマス","Hiragana":"くりすます","Romaji":"kurisumasu","English Meaning":"Christmas","audio":"kurisumasu"},
 {"Kanji":"待つ","Hiragana":"まつ","Romaji":"matsu","English Meaning":"to wait","audio":"matsu"},
 {"Kanji":"彼女","Hiragana":"かのじょ","Romaji":"kanojo","English Meaning":"she/her","audio":"kanojo"},
 {"Kanji":"顔","Hiragana":"かお","Romaji":"kao","English Meaning":"face","audio":"kao"},
 {"Kanji":"心臓","Hiragana":"しんぞう","Romaji":"shinzou","English Meaning":"heart (the organ)","audio":"shinzou"},
 {"Kanji":"子","Hiragana":"こ","Romaji":"ko","English Meaning":"child","audio":"ko"},
 {"Kanji":"怪我","Hiragana":"けが","Romaji":"kega","English Meaning":"injury","audio":"kega"},
 {"Kanji":"膝","Hiragana":"ひざ","Romaji":"hiza","English Meaning":"knee(s)","audio":"hiza"},
 {"Kanji":"彼","Hiragana":"かれ","Romaji":"kare","English Meaning":"he/him","audio":"kare"},
 {"Kanji":"怒る","Hiragana":"おこる","Romaji":"okoru","English Meaning":"to get angry","audio":"okoru"}
]

const soundwordssix = [
  {"Kanji":"英語","Hiragana":"えいご","Romaji":"eigo","English Meaning":"the English language","audio":"eigo"},
 {"Kanji":"話す","Hiragana":"はなす","Romaji":"hanasu","English Meaning":"to speak","audio":"hanasu"},
 {"Kanji":"食べる","Hiragana":"たべる","Romaji":"taberu","English Meaning":"to eat","audio":"taberu"},
 {"Kanji":"日本人","Hiragana":"にほんじん","Romaji":"nihonjin","English Meaning":"Japanese person/people","audio":"nihonjin"},
 {"Kanji":"人","Hiragana":"ひと","Romaji":"hito","English Meaning":"person","audio":"hito"},
 {"Kanji":"蕎麦","Hiragana":"そば","Romaji":"soba","English Meaning":"wheat noodles","audio":"soba"},
 {"Kanji":"吸う","Hiragana":"すう","Romaji":"suu","English Meaning":"to suck","audio":"suu"},
 {"Kanji":"誰か","Hiragana":"だれか","Romaji":"dareka","English Meaning":"someone","audio":"dareka"},
 {"Kanji":"ドア","Hiragana":"どあ","Romaji":"doa","English Meaning":"door","audio":"doa"},
 {"Kanji":"叩く","Hiragana":"たたく","Romaji":"tataku","English Meaning":"to strike, to hit","audio":"tataku"},
 {"Kanji":"水溜り","Hiragana":"みずたまり","Romaji":"mizutamari","English Meaning":"puddle","audio":"mizutamari"},
 {"Kanji":"上","Hiragana":"うえ","Romaji":"ue","English Meaning":"top","audio":"ue"},
 {"Kanji":"歩く","Hiragana":"あるく","Romaji":"aruku","English Meaning":"to walk","audio":"aruku"},
 {"Kanji":"好き","Hiragana":"すき","Romaji":"suki","English Meaning":"liking, fondness","audio":"suki"}
]

const soundwordsseven = [
  {"Katakana":"ペコペコ","Romaji":"peko peko","audio":"peko peko","English Meaning":"The sound an empty stomach makes"},
  {"Katakana":"ベタベタ","Romaji":"beta beta","audio":"beta beta","English Meaning":"The sound of something sticky(Like sticky fingers pulling apart)"},
  {"Katakana":"ブカブカ","Romaji":"buka buka","audio":"buka buka","English Meaning":"Too big, baggy"},
  {"Katakana":"グショグショ","Romaji":"gusho gusho","audio":"gusho gusho","English Meaning":"The sound of something soaking wet"},
  {"Katakana":"バラバラ","Romaji":"bara bara","audio":"bara bara","English Meaning":"Messy, disorganized"}
]

const soundwordseight = [
  {"Kanji":"お腹","Hiragana":"おなか","Romaji":"onaka","English Meaning":"belly, stomach","audio":"onaka"},
 {"Kanji":"飴","Hiragana":"あめ","Romaji":"ame","English Meaning":"candy","audio":"ame"},
 {"Kanji":"手","Hiragana":"て","Romaji":"te","English Meaning":"hand(s)","audio":"te"},
 {"Kanji":"なる","Hiragana":"なる","Romaji":"naru","English Meaning":"to become","audio":"naru"},
 {"Kanji":"服","Hiragana":"ふく","Romaji":"fuku","English Meaning":"clothes","audio":"fuku"},
 {"Kanji":"大きい","Hiragana":"おおきい","Romaji":"ookii","English Meaning":"big, large","audio":"ookii"},
 {"Kanji":"過ぎる","Hiragana":"すぎる","Romaji":"sugiru","English Meaning":"to exceed, to surpass","audio":"sugiru"},
 {"Kanji":"新聞紙","Hiragana":"しんぶんし","Romaji":"shinbunshi","English Meaning":"newspaper","audio":"shinbunshi"}
]

const SoundWords = () => {

  return (
    <>
      <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Beginner" category="Vocabulary" lesson="Sound Words" />

      <h1>{title}</h1>
      
      <p>Sound words, or what we might call Onomatopoeia in English, are well used in the Japanese language. In addition to animal sounds and other things we might also use sound words for, in Japanese sound words can be used to describe how one might be walking or the weather, for instance. Sound words are one of the few things that are typically written in Katakana . We will list them by category.</p>
<p>Please note that the example sentences will be done in plain form with the polite form in parentheses.</p>

<Table data={soundwordsone} />

<p>Here are some example sentences:</p>

<table>
<thead>
<tr>
<th>Japanese (In Kanji, Hiragana, and Romaji)</th>
<th>English Meaning</th>
</tr>
</thead>
<tbody>
<tr>
<td>

夜中、となりの犬がワンワンと吠えていて寝ることができなかった(できませんでした)。
<br />
よなか、となりのいぬがワンワンとほえていてねることができなかった(できませんでした)。
<br />
<Play audio="yonaka, tonari no inu ga wan wan to hoete ite neru koto ga dekinakatta" /> (<Play audio="yonaka, tonari no inu ga wan wan to hoete ite neru koto ga dekimasen deshita" />)

</td>
<td>
The neighor's dog was barking in the middle of the night and I couldn't sleep.
</td>
</tr>
<tr>
<td>

日本の梅雨は蛙がゲロゲロとないてうるさい(です)。
<br />
にほんのつゆはかえるがゲロゲロとないてうるさい(です)。
<br />
<Play audio="nihon no tsuyu wa kaeru ga gero gero to naite urusai" /> (<Play audio="nihon no tsuyu wa kaeru ga gero gero to naite urusai desu" />)

</td>
<td>
The Japanese rainy season is loud from the sound of frogs ribbiting.
</td>
</tr>
</tbody></table>

<h2>Vocab breakdown</h2>

<Table data={soundwordstwo} />

<table>
<thead>
<tr>
<th>Japanese (In Katakana and Romaji)</th>
<th>English Meaning</th>
</tr>
</thead>
<tbody>
<tr>
<td>

カンカン 
<br />
<Play audio="kan kan" />

</td>
<td>
Intense heat (or anger)
</td>
</tr>
<tr>
<td>

パラパラ 
<br />
<Play audio="para para" />

</td>
<td>
The sound of rain drops pattering
</td>
</tr>
<tr>
<td>

ザーザー 
<br />
<Play audio="za- za-" />

</td>
<td>
The sound of a lot of water flowing
</td>
</tr>
<tr>
<td>

ビュービュー 
<br />
<Play audio="byu- byu-" />

</td>
<td>
The sound of a whistle (like wind)
<br />
(Stronger than hyu- hyu- <Play audio="hyu- hyu-" /> but weaker than pyu- pyu- <Play audio="pyu- pyu-" />)
</td>
</tr>
<tr>
<td>

ゴロゴロ 
<br />
<Play audio="goro goro" />

</td>
<td>
The sound of thunder
</td>
</tr>
</tbody></table>

<p>Here are some example sentences:</p>

<table>
<thead>
<tr>
<th>Japanese (In Kanji, Hiragana, and Romaji)</th>
<th>English Meaning</th>
</tr>
</thead>
<tbody>
<tr>
<td>

日がカンカンに照っている(います)。
<br />
ひがカンカンにてっている(います)。
<br />
<Play audio="hi ga kan kan ni tette iru" /> (<Play audio="hi ga kan kan ni tette imasu" />)

</td>
<td>
The heat of the sun is beating down on us.
</td>
</tr>
<tr>
<td>

雨がパラパラと降っている(います)。
<br />
あめがパラパラとふっている(います)。
<br />
<Play audio="ame ga para para to futte iru" /> (<Play audio="ame ga para para to futte imasu" />)

</td>
<td>
The rain is sprinkling down.
</td>
</tr>
<tr>
<td>

雨がザーザーと降っている(います)。
<br />
あめがザーザーとふっている(います)。
<br />
<Play audio="ame ga za- za- to futte iru" /> (<Play audio="ame ga za- za- to futte imasu" />)

</td>
<td>
The rain is pouring down.
</td>
</tr>
<tr>
<td>

風がビュービューと吹いている(います)。
<br />
かぜがビュービューとふいている(います)。
<br />
<Play audio="kaze ga byu- byu- to fuite iru" /> (<Play audio="kaze ga byu- byu- to fuite imasu" />)

</td>
<td>
The wind is blowing pretty hard.
</td>
</tr>
<tr>
<td>

雷がゴロゴロと鳴っている(います)。
<br />
かみなりがゴロゴロとなっている(います)。
<br />
<Play audio="kaminari ga goro goro to natte iru" /> (<Play audio="kaminari ga goro goro to natte imasu" />)

</td>
<td>
The thunder is rumbling.
</td>
</tr>
</tbody></table>

<Table data={soundwordsthree} />

<h2>Emotions</h2>

<Table data={soundwordsfour} />

<p>Here are some example sentences:</p>

<table>
<thead>
<tr>
<th>Japanese (In Kanji, Hiragana, and Romaji)</th>
<th>English Meaning</th>
</tr>
</thead>
<tbody>
<tr>
<td>

子供たちが言うことを聞かないからお母さんはイライラしている(います)。
<br />
こどもたちがいうことをきかないからおかあさんはイライラしている(います)。
<br />
<Play audio="kodomotachi ga iu koto o kikanai kara okaasan wa ira ira shite iru" /> (<Play audio="kodomotachi ga iu koto o kikanai kara okaasan wa ira ira shite imasu" />)

</td>
<td>
The children don't listen to what their mother tells them so she is upset with them.
</td>
</tr>
<tr>
<td>

子供たちはクリスマスをワクワクしながら待つ(待ちます)。
<br />
こどもたちはクリスマスをワクワクしながらまつ(まちます)。
<br />
<Play audio="kodomotachi wa kurisumasu o waku waku shinagara matsu" /> (<Play audio="kodomotachi wa kurisumasu o waku waku shinagara machimasu" />)

</td>
<td>
While excited for Christmas, the children continue to wait.
</td>
</tr>
<tr>
<td>

彼女の顔を見ると心臓がドキドキとする(します)。
<br />
かのじょのかおをみるとしんぞうがドキドキとする(します)。
<br />
<Play audio="kanojo no kao o miru to shinzou ga doki doki to suru" /> (<Play audio="kanojo no kao o miru to shinzou ga doki doki to shimasu" />)

</td>
<td>
When I see her face, my heart starts pounding like crazy.
<br />
(Get really nervous)
</td>
</tr>
<tr>
<td>

あの子は怪我をして膝がヒリヒリしている(います)。
<br />
あのこはけがをしてひざがヒリヒリしている(います)。
<br />
<Play audio="ano ko wa kega o shite hiza ga hiri hiri shite iru" /> (<Play audio="ano ko wa kega o shite hiza ga hiri hiri shite imasu" />)

</td>
<td>
That kid fell down and skinned up his knees.
</td>
</tr>
<tr>
<td>

彼はカンカンに怒っている(います)。
<br />
かれはカンカンにおこっている(います)。
<br />
<Play audio="kare wa kan kan ni okotte iru" /> (<Play audio="kare wa kan kan ni okotte imasu" />)

</td>
<td>
He is really ticked off.
</td>
</tr>
</tbody></table>

<Table data={soundwordsfive} />

<h2>Actions</h2>

<table>
<thead>
<tr>
<th>Japanese (In Katakana and Romaji)</th>
<th>English Meaning</th>
</tr>
</thead>
<tbody>
<tr>
<td>

ペラペラ 
<br />
<Play audio="pera pera" />

</td>
<td>
The sound of words being spoken quickly
<br />
(Fluent)
</td>
</tr>
<tr>
<td>

モクモク 
<br />
<Play audio="moku moku" />

</td>
<td>
Silent
</td>
</tr>
<tr>
<td>

ズルズル 
<br />
<Play audio="zuru zuru" />

</td>
<td>
The sound of something dragging
</td>
</tr>
<tr>
<td>

トントン 
<br />
<Play audio="ton ton" />

</td>
<td>
The sound of tapping
<br />
(Not as strong as don don <Play audio="don don" /> - pounding)
</td>
</tr>
<tr>
<td>

バシャバシャ 
<br />
<Play audio="basha basha" />

</td>
<td>
The sound of splashing water
</td>
</tr>
</tbody></table>

<p>Here are some example sentences:</p>

<table>
<thead>
<tr>
<th>Japanese (In Kanji, Hiragana, and Romaji)</th>
<th>English Meaning</th>
</tr>
</thead>
<tbody>
<tr>
<td>

彼は英語をペラペラに話す(話します)。
<br />
かれはえいごをペラペラにはなす(はなします)。
<br />
<Play audio="kare wa eigo o pera pera ni hanasu" /> (<Play audio="kare wa eigo o pera pera ni hanashimasu" />)

</td>
<td>
He speaks English fluently.
</td>
</tr>
<tr>
<td>

彼女はモクモクと食べている(います)。
<br />
かのじょはモクモクとたべている(います)。
<br />
<Play audio="kanojo wa moku moku to tabete iru" /> (<Play audio="kanojo wa moku moku to tabete imasu" />)

</td>
<td>
She is eating very quietly.
</td>
</tr>
<tr>
<td>

日本人の人は蕎麦をズルズルと吸って食べる(食べます)。
<br />
にほんじんのひとはそばをズルズルとすってたべる(たべます)。
<br />
<Play audio="nihonjin no hito wa soba o zuru zuru to sutte taberu" /> (<Play audio="nihonjin no hito wa soba o zuru zuru to sutte tabemasu" />)

</td>
<td>
The Japanese people slurp their soba noodles.
</td>
</tr>
<tr>
<td>

誰かがドアをトントンと叩いている(います)。
<br />
だれかがドアをトントンとたたいている(います)。
<br />
<Play audio="dareka ga doa o ton ton to tataite iru" /> (<Play audio="dareka ga doa o ton ton to tataite imasu" />)

</td>
<td>
Someone is knocking on the door.
</td>
</tr>
<tr>
<td>

子供たちは水溜りの上をバシャバシャと歩くことが好きだ(です)。
<br />
こどもたちはみずたまりのうえをバシャバシャとあるくことがすきだ(です)。
<br />
<Play audio="kodomotachi wa mizutamari no ue o basha basha to aruku koto ga suki da" /> (<Play audio="kodomotachi wa mizutamari no ue o basha basha to aruku koto ga suki desu" />)

</td>
<td>
Children like to splash through puddles.
</td>
</tr>
</tbody></table>

<Table data={soundwordssix} />

<h2>Other</h2>

<Table data={soundwordsseven} />

<p>Here are some example sentences:</p>

<table>
<thead>
<tr>
<th>Japanese (In Kanji, Hiragana, and Romaji)</th>
<th>English Meaning</th>
</tr>
</thead>
<tbody>
<tr>
<td>

お腹がペコペコだ(です)。
<br />
おなかがペコペコだ(です)。
<br />
<Play audio="onaka ga peko peko da" /> (<Play audio="onaka ga peko peko desu" />)

</td>
<td>
I am hungry.
</td>
</tr>
<tr>
<td>

飴を食べたら手がベタベタになった(なりました)。
<br />
あめをたべたらてがベタベタになった(なりました)。
<br />
<Play audio="ame o tabetara te ga beta beta ni natta" /> (<Play audio="ame o tabetara te ga beta beta ni narimashita" />)

</td>
<td>
Eating candy made my hands sticky.
</td>
</tr>
<tr>
<td>

この服は大き過ぎてブカブカしている(います)。
<br />
このふくはおおきすぎてブカブカしている(います)。
<br />
<Play audio="kono fuku wa ookisugite buka buka shite iru" /> (<Play audio="kono fuku wa ookisugite buka buka shite imasu" />)

</td>
<td>
These clothes are too big and baggy.
</td>
</tr>
<tr>
<td>

雨で服がグショグショになった(なりました)。
<br />
あめでふくがグショグショになった(なりました)。
<br />
<Play audio="ame de fuku ga gusho gusho ni natta" /> (<Play audio="ame de fuku ga gusho gusho ni narimashita" />)

</td>
<td>
The rain got my clothes soaking wet.
</td>
</tr>
<tr>
<td>

風で新聞紙がバラバラになった(なりました)。
<br />
かぜでしんぶんしがバラバラになった(なりました)。
<br />
<Play audio="kaze de shinbunshi ga bara bara ni natta" /> (<Play audio="kaze de shinbunshi ga bara bara ni narimashita" />)

</td>
<td>
The wind blew the newspapers all over the place.
</td>
</tr>
</tbody></table>

<Table data={soundwordseight} />

    </>
  )
}

export default SoundWords