import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Words That Sound Similar"

const similarone = [
  {"漢字 - Kanji":"紙","ひらがな - Hiragana":"かみ","Romaji (English Letters)":"kami","audio":"kami","English Meaning":"paper"},
 {"漢字 - Kanji":"髪","ひらがな - Hiragana":"かみ","Romaji (English Letters)":"kami","audio":"kami","English Meaning":"hair"},
 {"漢字 - Kanji":"神","ひらがな - Hiragana":"かみ","Romaji (English Letters)":"kami","audio":"kami","English Meaning":"god"},
 {"漢字 - Kanji":"花","ひらがな - Hiragana":"はな","Romaji (English Letters)":"hana","audio":"hana","English Meaning":"flower"},
 {"漢字 - Kanji":"鼻","ひらがな - Hiragana":"はな","Romaji (English Letters)":"hana","audio":"hana","English Meaning":"nose"},
 {"漢字 - Kanji":"蛸","ひらがな - Hiragana":"たこ","Romaji (English Letters)":"tako","audio":"tako","English Meaning":"octopus"},
 {"漢字 - Kanji":"凧","ひらがな - Hiragana":"たこ","Romaji (English Letters)":"tako","audio":"tako","English Meaning":"kite"},
 {"漢字 - Kanji":"胼胝","ひらがな - Hiragana":"たこ","Romaji (English Letters)":"tako","audio":"tako","English Meaning":"corn (type of foot sore)"},
 {"漢字 - Kanji":"箸","ひらがな - Hiragana":"はし","Romaji (English Letters)":"hashi","audio":"hashi","English Meaning":"chopsticks"},
 {"漢字 - Kanji":"橋","ひらがな - Hiragana":"はし","Romaji (English Letters)":"hashi","audio":"hashi","English Meaning":"bridge"},
 {"漢字 - Kanji":"雨","ひらがな - Hiragana":"あめ","Romaji (English Letters)":"ame","audio":"ame","English Meaning":"rain"},
 {"漢字 - Kanji":"飴","ひらがな - Hiragana":"あめ","Romaji (English Letters)":"ame","audio":"ame","English Meaning":"candy"}
]
const similartwo = [
  {"漢字 - Kanji":"住所","ひらがな - Hiragana":"じゅうしょ","Romaji (English Letters)":"juusho","audio":"juusho","English Meaning":"address"},
 {"漢字 - Kanji":"受章","ひらがな - Hiragana":"じゅしょう","Romaji (English Letters)":"jushou","audio":"jushou","English Meaning":"reception of a decoration or order"},
 {"漢字 - Kanji":"受賞","ひらがな - Hiragana":"じゅしょう","Romaji (English Letters)":"jushou","audio":"jushou","English Meaning":"winning (a prize)"},
 {"漢字 - Kanji":"授賞","ひらがな - Hiragana":"じゅしょう","Romaji (English Letters)":"jushou","audio":"jushou","English Meaning":"awarding a prize"},
 {"漢字 - Kanji":"綬章","ひらがな - Hiragana":"じゅしょう","Romaji (English Letters)":"jushou","audio":"jushou","English Meaning":"ribbon"},
 {"漢字 - Kanji":"樹梢","ひらがな - Hiragana":"じゅしょう","Romaji (English Letters)":"jushou","audio":"jushou","English Meaning":"treetop"},
 {"漢字 - Kanji":"重傷","ひらがな - Hiragana":"じゅうしょう","Romaji (English Letters)":"juushou","audio":"juushou","English Meaning":"serious wound or injury"},
 {"漢字 - Kanji":"重症","ひらがな - Hiragana":"じゅうしょう","Romaji (English Letters)":"juushou","audio":"juushou","English Meaning":"serious illness"},
 {"漢字 - Kanji":"銃傷","ひらがな - Hiragana":"じゅうしょう","Romaji (English Letters)":"juushou","audio":"juushou","English Meaning":"gunshot wound"},
 {"漢字 - Kanji":"重唱","ひらがな - Hiragana":"じゅうしょう","Romaji (English Letters)":"juushou","audio":"juushou","English Meaning":"singing in parts"}
]
const similarthree = [
  {"漢字 - Kanji":"遺書","ひらがな - Hiragana":"いしょ","Romaji (English Letters)":"isho","audio":"isho","English Meaning":"will, testament"},
 {"漢字 - Kanji":"医書","ひらがな - Hiragana":"いしょ","Romaji (English Letters)":"isho","audio":"isho","English Meaning":"medical book"},
 {"漢字 - Kanji":"衣装","ひらがな - Hiragana":"いしょう","Romaji (English Letters)":"ishou","audio":"ishou","English Meaning":"clothing, costume, outfit, garment"},
 {"漢字 - Kanji":"異称","ひらがな - Hiragana":"いしょう","Romaji (English Letters)":"ishou","audio":"ishou","English Meaning":"alias, pseudonym, pen name"},
 {"漢字 - Kanji":"異象","ひらがな - Hiragana":"いしょう","Romaji (English Letters)":"ishou","audio":"ishou","English Meaning":"vision, strange phenomenon"},
 {"漢字 - Kanji":"一緒","ひらがな - Hiragana":"いっしょ","Romaji (English Letters)":"issho","audio":"issho","English Meaning":"together, at the same time, same, identical"},
 {"漢字 - Kanji":"一所","ひらがな - Hiragana":"いっしょ","Romaji (English Letters)":"issho","audio":"issho","English Meaning":"one place, the same place"},
 {"漢字 - Kanji":"逸書","ひらがな - Hiragana":"いっしょ","Romaji (English Letters)":"issho","audio":"issho","English Meaning":"lost book"},
 {"漢字 - Kanji":"一書","ひらがな - Hiragana":"いっしょ","Romaji (English Letters)":"issho","audio":"issho","English Meaning":"one letter, one book"},
 {"漢字 - Kanji":"一生","ひらがな - Hiragana":"いっしょう","Romaji (English Letters)":"isshou","audio":"isshou","English Meaning":"whole life, a lifetime, all through life"},
 {"漢字 - Kanji":"一将","ひらがな - Hiragana":"いっしょう","Romaji (English Letters)":"isshou","audio":"isshou","English Meaning":"military general"},
 {"漢字 - Kanji":"一勝","ひらがな - Hiragana":"いっしょう","Romaji (English Letters)":"isshou","audio":"isshou","English Meaning":"one win or victory"},
 {"漢字 - Kanji":"一升","ひらがな - Hiragana":"いっしょう","Romaji (English Letters)":"isshou","audio":"isshou","English Meaning":"unit of old Japanese liquid measurement(1800 cc)"}
]
const similarfour = [
  {"漢字 - Kanji":"婚約","ひらがな - Hiragana":"こんやく","Romaji (English Letters)":"konyaku","audio":"konyaku","English Meaning":"engagement"},
 {"漢字 - Kanji":"蒟蒻","ひらがな - Hiragana":"こんにゃく","Romaji (English Letters)":"konnyaku (typically written in katakana)","audio":"konnyaku","English Meaning":"solidified jelly made from the rhizome of devil's tongue - used in a lot of Japanese meals"},
 {"漢字 - Kanji":"コニャック","ひらがな - Hiragana":"こにゃっく","Romaji (English Letters)":"konyakku (would be written in katakana)","audio":"konyakku","English Meaning":"cognac"}
]
const similarfive = [
  {"漢字 - Kanji":"お祖母さん","ひらがな - Hiragana":"おばあさん","Romaji (English Letters)":"obaasan","audio":"obaasan","English Meaning":"grandma, grandmother"},
 {"漢字 - Kanji":"伯母さん叔母さん","ひらがな - Hiragana":"おばさん","Romaji (English Letters)":"obasan","audio":"obasan","English Meaning":"aunt(the first (伯母さん) is older than one's parent and the second (叔母さん) is younger)"},
 {"漢字 - Kanji":"お祖父さん","ひらがな - Hiragana":"おじいさん","Romaji (English Letters)":"ojiisan","audio":"ojiisan","English Meaning":"grandpa, grandfather"},
 {"漢字 - Kanji":"伯父さん叔父さん","ひらがな - Hiragana":"おじさん","Romaji (English Letters)":"ojisan","audio":"ojisan","English Meaning":"uncle(the first (伯父さん) is older than one's parent and the second (叔父さん) is younger)"}
]

const SimilarOne = () => {

  return (
    <>
      <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Beginner" category="Vocabulary" lesson={title} />
     <div className={'title'}>
     Words That Sound Similar
      </div>
      <h1>{title}</h1>

      <p>Many words in the Japanese language sound similar or even exactly the same. While some might be written the same in romaji, they may differ in how they would be written in hiragana. All have different kanji, however.</p>

      <p>This is one of the problems with relying on romaji (Romanized Japanese) as it isn't possible to distinquish which word is which in many cases. And, since "ou" in words is often reduced to simply "o" in romaji, many words that are not pronounced the same may look like they are. Some examples of where "ou" is reduced to "o" in romaji is in the major Japanese cities of Tokyo and Kyoto. "Tokyo" (if written with all hiragana characters represented) would be <Play audio="toukyou" />  while "Kyoto" would be <Play audio="kyouto" /> . Notice the concise pronunciation of "to" at the end of "Kyoto" whereas the other vowel sounds in both words are elongated.</p>

      <p>It is very important in Japanese to give each hiragana character that would represent a word equal time when spoken. Elongating a vowel that is not supposed to be elongated could change the meaning completely. Each hiragana character that would represent a word if written out in hiragana represents one syllable. Combinations count as one syllable.</p>

      <p>Let's start with words that sound exactly (or almost exactly alike) and would be written the same in hiragana.</p>

<Table data={similarone} />

<p>These words show the importance of kanji in written Japanese. In spoken Japanese, context is relied upon to know which word is being used.</p>

<p>You might notice a slight pronunciation difference between "hashi" (chopsticks) and "hashi" (bridge) as well as "ame" (rain) and "ame" (candy). Typically, the stress is put on the first syllable (first hiragana character that would represent a word) of a Japanese word. In the case of "hashi" (bridge) and "ame" (candy), however, the stress is on the second syllable ("shi" and "me" respectively). Listen to the audio for each and see if you can hear the difference.</p>

<p>Now let's look at some words that sound very similar and would likely be written the same in romaji (except on this site) but are actually very different.</p>

<Table data={similartwo} />

<p>Above we have "juusho" (3 syllables), some different words that are all pronounced "jushou" (3 syllables), and some different words that are all pronounced "juushou" (4 syllables). All of these would likely be written "jusho" in romaji in most situations. With "juusho" it is very important to elongate the "ju" but keep the "sho" concise. With "jushou" it is the other way around - the "ju" is concise and the "sho" is elongated. With "juushou", both "ju" and "sho" are elongated. It can take some time to get the hang of this.</p>

<p>This is just one of many examples. Here's another.</p>

<Table data={similarthree} />

<p>Above we have a couple different words that are pronounced "isho" (2 syllables), some different words that are all pronounced "ishou" (3 syllables), some different words that are all pronounced "issho" (3 syllables), and some different words that are all pronounced "isshou" (4 syllables). All of these would likely be written in romaji with the "ou" reduced to "o" in most situations. With "isho" it is very important to keep the "sho" concise and not elongate the "s" sound. With "ishou" you would elongate the "sho" but still keep the "s" sound concise. With "issho" you would elongate the "s" sound (the small "tsu" causes a syllable length "s" sound in this case) but keep the "sho" concise. With "isshou", both the "s" sound and "sho" are elongated.</p>

<p>Now let's look at some words that sound almost exactly alike but are very different and are common mistakes for people learning the Japanese language.</p>

<Table data={similarfour} />

<p>It is difficult to even represent these words in romaji because "n""ya" looks exactly like "nya" but "n""ya" is 2 syllables and "nya" is only 1. This is the key behind pronouncing these words correctly.</p>

<p>"konyaku" (engagement) is 4 syllables - "ko" "n" "ya" "ku".</p>
<p>"konnyaku" (the food) is also 4 but the third syllable is "nya" instead of "ya" - "ko" "n" "nya" "ku".</p>
<p>"konyakku" (cognac) is 4 syllables as well (the small "tsu" causes a syllable length pause with hard consonant sounds such as "k") - "ko" "nya" pause "ku".</p>

<p>It is very important that each syllable be distinct. For instance, with "konyaku" (engagement) it is very important that the "n" sound end before "ya" begins. With "konnyaku" (the food) it is very important that the "n" and "nya" have equal time creating a double "n" sound.</p>

<Table data={similarfive} />

<p>As you can see, elongating the middle vowel sounds in the words above makes a big difference. It is important that "obaasan" and "ojiisan" have 5 syllables with the "a" and "i" sounds (respectively) elongated and that "obasan" and "ojisan" have 4 syllables with a concise "a" and "i" sound (respectively).</p>

<p>Practicing each syllable slowly will help quite a bit in learning to master the pronunciation of similar sounding words.</p>

    </>
  )
}

export default SimilarOne