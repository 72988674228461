import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Grade 5 Kanji - Part 4 of 5"

const GradeFiveFour = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Advanced" category="Kanji" lesson={title} />
    
      <h1>{title}</h1>

      <p>Below are Kanji the Japanese learn in grade 5 of elementary school.
Be sure to read the <Link to="/members/a/kanji/intro/">Introduction to Kanji</Link> if you haven't already.</p>

<p>Legend:</p>
<p>[n] = Noun</p>
<p>[dv] = Descriptive word (true adjective) - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[dn] = Descriptive noun - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[gv] = Godan verb - see <Link to="/members/b/grammar/godan-verbs/">Verb Conjugation (Godan)</Link></p>
<p>[iv] = Ichidan verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[irv] = Irregular verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[c] = Counter</p>

<table>
<thead>
  <tr>
<th>漢字<br /><Play audio="kanji" /></th>
<th>音読み <Play audio="onyomi" /><br />"On" Reading</th>
<th>訓読み <Play audio="kunyomi" /><br />"Kun" Reading</th>
<th>English Meaning</th>
</tr>
  </thead>
<tbody>
<tr>
<th><span style={{fontSize:`4rem`}}>素</span></th>
<td>ス (su)<br />ソ (so)</td>
<td>もと</td>
<td>
Elementary
<br />
<table><tbody><tr><td>Examples:
<br />
素敵 (すてき - suteki - lovely, dreamy, beautiful, great, fantastic, superb, cool) [dn] <Play audio="suteki" />
<br />
素材 (そざい - sozai - raw materials, subject matter) [n] <Play audio="sozai" />
<br />
素 (もと - moto - ingredient, material) [n] <Play audio="moto" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>経</span></th>
<td>キョウ (kyou)<br />ケイ (kei)</td>
<td>へ・る (heru)</td>
<td>
Manage
<br />
<table><tbody><tr><td>Examples:
<br />
経典 (きょうてん - kyouten - sacred books, sutras, scriptures, Bible) [n] <Play audio="kyouten" />
<br />
経緯 (けいい - keii - details, particulars, whole story, sequence of events, chronology, circumstances) [n] <Play audio="keii" />
<br />
経る (へる - heru - to pass, to elapse, to experience) [iv] <Play audio="heru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>統</span></th>
<td>トウ (tou)</td>
<td>す・べる (suberu)</td>
<td>
Unite
<br />
<table><tbody><tr><td>Examples:
<br />
統括 (とうかつ - toukatsu - unification, bringing together, generalization) [n] <Play audio="toukatsu" />
<br />
統べる (すべる - suberu - to control, to supervise) [iv] <Play audio="suberu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>絶</span></th>
<td>ゼツ (zetsu)</td>
<td>た・える (taeru)<br />た・やす (tayasu)<br />た・つ (tatsu)</td>
<td>
Discontinue
<br />
<table><tbody><tr><td>Examples:
<br />
絶命 (ぜつめい - zetsumei - end of life, death, annihilation) [n] <Play audio="zetsumei" />
<br />
絶対 (ぜったい - zettai - absolute, unconditional, unmistakable) <Play audio="zettai" />
<br />
絶える (たえる - taeru - to die out, to become extinct, to cease, to be stopped, to be discontinued, to be cut off) [iv] <Play audio="taeru" />
<br />
絶やす (たやす - tayasu - to exterminate, to eradicate) [gv] <Play audio="tayasu" />
<br />
絶つ (たつ - tatsu - to sever, to cut off, to suppress, to abstain (from)) [gv] <Play audio="tatsu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>綿</span></th>
<td>メン (men)</td>
<td>わた (wata)<br /></td>
<td>
Cotton
<br />
<table><tbody><tr><td>Examples:
<br />
木綿 (もめん - momen - cotton (material)) [n] <Play audio="momen" />
<br />
綿 (わた - wata - cotton plant, batting, wadding, padding) [n] <Play audio="wata" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>総</span></th>
<td>ソウ (sou)</td>
<td></td>
<td>
Whole
<br />
<table><tbody><tr><td>Examples:
<br />
総括 (そうかつ - soukatsu - synthesis, recap, generalize) [n] <Play audio="soukatsu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>編</span></th>
<td>ヘン (hen)</td>
<td>あ・む (amu)</td>
<td>
Compile
<br />
<table><tbody><tr><td>Examples:
<br />
編集 (へんしゅう - henshuu - editing, compilation, editorial (e.g. committee)) [n] <Play audio="henshuu" />
<br />
編む (あむ - amu - to knit, to plait, to braid, to compile (anthology, dictionary, etc.), to edit) [gv] <Play audio="amu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>績</span></th>
<td>セキ (seki)</td>
<td></td>
<td>
Exploits
<br />
<table><tbody><tr><td>Examples:
<br />
業績 (ぎょうせき - gyouseki - achievement, performance, results, work, contribution) [n] <Play audio="gyouseki" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>織</span></th>
<td>シキ (shiki)<br />ショク (shoku)</td>
<td>お・る (oru)</td>
<td>
Weave
<br />
<table><tbody><tr><td>Examples:
<br />
組織 (そしき - soshiki - organization, structure, construction) [n] <Play audio="soshiki" />
<br />
紡織 (ぼうしょく - boushoku - spinning and weaving) [n] <Play audio="boushoku" />
<br />
織る (おる - oru - to weave) [gv] <Play audio="oru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>罪</span></th>
<td>ザイ (zai)</td>
<td>つみ (tsumi)</td>
<td>
Guilt
<br />
<table><tbody><tr><td>Examples:
<br />
謝罪 (しゃざい - shazai - apology) [n] <Play audio="shazai" />
<br />
罪 (つみ - tsumi - crime, fault, indiscretion, sin) [dn] <Play audio="tsumi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>群</span></th>
<td>グン (gun)</td>
<td>む・れ (mure)<br />む・れる (mureru)<br />むら (mura)<br />むら・がる (muragaru)</td>
<td>
Flock
<br />
<table><tbody><tr><td>Examples:
<br />
群衆 (ぐんしゅう - gunshuu - group (of people), crowd, horde, throng, mob, multitude) [n] <Play audio="gunshuu" />
<br />
群れ (むれ - mure - group, crowd, flock, herd, bevy, school (e.g. of fish), swarm, cluster (e.g. of stars), clump, pack (e.g. of dogs)) [n] <Play audio="mure" />
<br />
群れる (むれる - mureru - to crowd, to flock, to swarm) [iv] <Play audio="mureru" />
<br />
群 (むら - mura - gathering) [n] <Play audio="mura" />
<br />
群がる (むらがる - muragaru - to swarm, to gather) [gv] <Play audio="muragaru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>義</span></th>
<td>ギ (gi)</td>
<td></td>
<td>
Righteousness
<br />
<table><tbody><tr><td>Examples:
<br />
愛国主義 (あいこくしゅぎ - aikokushugi - nationalism, patriotism) [n] <Play audio="aikokushugi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>耕</span></th>
<td>コウ (kou)</td>
<td>たがや・す (tagayasu)</td>
<td>
Till
<br />
<table><tbody><tr><td>Examples:
<br />
耕作 (こうさく - kousaku - cultivation, farming) [n] <Play audio="kousaku" />
<br />
耕す (たがやす - tagayasu - to till, to plow, to cultivate) [gv] <Play audio="tagayasu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>職</span></th>
<td>ショク (shoku)</td>
<td></td>
<td>
Employment
<br />
<table><tbody><tr><td>Examples:
<br />
辞職 (じしょく - jishoku - resignation) [n] <Play audio="jishoku" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>肥</span></th>
<td>ヒ (hi)</td>
<td>こ・える (koeru)<br />こえ (koe)<br />こ・やす (koyasu)<br />こ・やし (koyashi)</td>
<td>
Fertilizer
<br />
<table><tbody><tr><td>Examples:
<br />
肥満 (ひまん - himan - corpulence, fatness, obesity) [n] <Play audio="himan" />
<br />
肥える (こえる - koeru - to grow fat, to gain weight, to put on weight, (of soil) to grow fertile) [iv] <Play audio="koeru" />
<br />
肥 (こえ - koe - manure, night soil, dung, fertilizer) [n] <Play audio="koe" />
<br />
肥やす (こやす - koyasu - to fertilize, to manure, to enrich) [gv] <Play audio="koyasu" />
<br />
肥やし (こやし - koyashi - manure, night soil, dung, fertilizer, something that will help one develop in the future) [n] <Play audio="koyashi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>能</span></th>
<td>ノウ (nou)</td>
<td></td>
<td>
Ability
<br />
<table><tbody><tr><td>Examples:
<br />
可能 (かのう - kanou - potential, possible, practicable, feasible) [dn] <Play audio="kanou" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>興</span></th>
<td>コウ (kou)<br />キョウ (kyou)</td>
<td>おこ・る (okoru)<br />おこ・す (okosu)</td>
<td>
Entertain
<br />
<table><tbody><tr><td>Examples:
<br />
興行 (こうぎょう - kougyou - entertainment industry, show business) [n] <Play audio="kougyou" />
<br />
興味 (きょうみ - kyoumi - interest (in something)) [n] <Play audio="kyoumi" />
<br />
興る (おこる - okoru - to rise, to flourish) [gv] <Play audio="okoru" />
<br />
興す (おこす - okosu - to revive, to retrieve (fortunes), to raise up) [gv] <Play audio="okosu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>舌</span></th>
<td>ゼツ (zetsu)</td>
<td>した (shita)</td>
<td>
Tongue
<br />
<table><tbody><tr><td>Examples:
<br />
弁舌 (べんぜつ - benzetsu - speech) [n] <Play audio="benzetsu" />
<br />
舌 (した - shita - tongue, tongue-like object, clapper (of a bell), talon (of a lock)) [n] <Play audio="shita" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>舎</span></th>
<td>シャ (sha)</td>
<td></td>
<td>
Cottage
<br />
<table><tbody><tr><td>Examples:
<br />
校舎 (こうしゃ - kousha - school building) [n] <Play audio="kousha" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>術</span></th>
<td>ジュツ (jutsu)</td>
<td>すべ (sube)</td>
<td>
Art
<br />
<table><tbody><tr><td>Examples:
<br />
芸術 (げいじゅつ - geijutsu - (fine) art, the arts) [n] <Play audio="geijutsu" />
<br />
忍術 (にんじゅつ - ninjutsu - fighting art of the ninja, ninja skills in secrecy, stealth and combat techniques) [n] <Play audio="ninjutsu" />
<br />
術 (すべ - sube - way, method, means) [n] <Play audio="sube" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>衛</span></th>
<td>エイ (ei)</td>
<td></td>
<td>
Defense
<br />
<table><tbody><tr><td>Examples:
<br />
衛星 (えいせい - eisei - satellite) [n] <Play audio="eisei" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>製</span></th>
<td>セイ (sei)</td>
<td></td>
<td>
Manufacture
<br />
<table><tbody><tr><td>Examples:
<br />
製作 (せいさく - seisaku - manufacture, production) [n] <Play audio="seisaku" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>複</span></th>
<td>フク (fuku)</td>
<td></td>
<td>
Duplicate
<br />
<table><tbody><tr><td>Examples:
<br />
複雑 (ふくざつ - fukuzatsu - complex, complicated, mixed (feelings)) [dn] <Play audio="fukuzatsu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>規</span></th>
<td>キ (ki)</td>
<td></td>
<td>
Rule
<br />
<table><tbody><tr><td>Examples:
<br />
規則 (きそく - kisoku - rules, regulations, conventions) [n] <Play audio="kisoku" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>解</span></th>
<td>ゲ (ge)<br />カイ (kai)</td>
<td>と・く (toku)</td>
<td>
Untie
<br />
<table><tbody><tr><td>Examples:
<br />
解熱剤 (げねつざい - genetsuzai - fever medicine, antipyretic, antifebrile) [n] <Play audio="genetsuzai" />
<br />
解散 (かいさん - kaisan - breakup, dissolution) [n] <Play audio="kaisan" />
<br />
理解 (りかい - rikai - understanding, comprehension, appreciation, sympathy) [n] <Play audio="rikai" />
<br />
解く (とく - toku - to untie, to unfasten, to unwrap, to undo, to unbind, to unpack) [gv] <Play audio="toku" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>設</span></th>
<td>セツ (setsu)</td>
<td>もう・ける (moukeru)</td>
<td>
Establish
<br />
<table><tbody><tr><td>Examples:
<br />
設備 (せつび - setsubi - equipment, device, facilities, installation) [n] <Play audio="setsubi" />
<br />
設ける (もうける - moukeru - to create, to establish) [iv] <Play audio="moukeru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>許</span></th>
<td>キョ (kyo)</td>
<td>ゆる・す (yurusu)<br />ゆる・し (yurushi)</td>
<td>
Permit
<br />
<table><tbody><tr><td>Examples:
<br />
許可 (きょか - kyoka - permission, approval, authorization, license) [n] <Play audio="kyoka" />
<br />
許す (ゆるす - yurusu - to permit, to allow, to approve, to tolerate, to exempt (from fine), to excuse (from), to pardon, to forgive, to release, to let off) [gv] <Play audio="yurusu" />
<br />
許し (ゆるし - yurushi - pardon, forgiveness, exemption, permission) [n] <Play audio="yurushi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>証</span></th>
<td>ショウ (shou)</td>
<td></td>
<td>
Evidence
<br />
<table><tbody><tr><td>Examples:
<br />
証明 (しょうめい - shoumei - proof, verification) [n] <Play audio="shoumei" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>評</span></th>
<td>ヒョウ (hyou)</td>
<td></td>
<td>
Evaluate
<br />
<table><tbody><tr><td>Examples:
<br />
評価 (ひょうか - hyouka - valuation, estimation, assessment, evaluation) [n] <Play audio="hyouka" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>講</span></th>
<td>コウ (kou)</td>
<td></td>
<td>
Lecture
<br />
<table><tbody><tr><td>Examples:
<br />
講演 (こうえん - kouen - lecture, address, speech) [n] <Play audio="kouen" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>謝</span></th>
<td>シャ (sha)</td>
<td>あやま・る (ayamaru)</td>
<td>
Apologize
<br />
<table><tbody><tr><td>Examples:
<br />
感謝 (かんしゃ - kansha - thanks, gratitude) [dn] <Play audio="kansha" />
<br />
謝る (あやまる - ayamaru - to apologize) [gv] <Play audio="ayamaru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>識</span></th>
<td>シキ (shiki)</td>
<td></td>
<td>
Discriminating
<br />
<table><tbody><tr><td>Examples:
<br />
常識 (じょうしき - joushiki - common sense, good sense, common knowledge, general knowledge, common practice, accepted practice, social etiquette) [n] <Play audio="joushiki" />
<br />
意識 (いしき - ishiki - consciousness, awareness, sense) [n] <Play audio="ishiki" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>護</span></th>
<td>ゴ (go)</td>
<td>まも・る (mamoru)</td>
<td>
Safeguard
<br />
<table><tbody><tr><td>Examples:
<br />
援護 (えんご - engo - covering, protection, backing) [n] <Play audio="engo" />
<br />
護る (まもる - mamoru - to protect, to guard, to defend, to keep (i.e. a promise), to abide (by the rules), to observe, to obey) [gv] <Play audio="mamoru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>豊</span></th>
<td>ホウ (hou)</td>
<td>ゆた・か (yutaka)</td>
<td>
Bountiful
<br />
<table><tbody><tr><td>Examples:
<br />
豊富 (ほうふ - houfu - abundance, wealth, plenty, bounty) [dn] <Play audio="houfu" />
<br />
豊か (ゆたか - yutaka - abundant, wealthy, plentiful, rich, affluent) [dn] <Play audio="yutaka" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>財</span></th>
<td>ザイ (zai)<br />サイ (sai)</td>
<td></td>
<td>
Wealth
<br />
<table><tbody><tr><td>Examples:
<br />
財産 (ざいさん - zaisan - property, fortune, assets) [n] <Play audio="zaisan" />
<br />
財布 (さいふ - saifu - purse, handbag, wallet) [n] <Play audio="saifu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>貧</span></th>
<td>ビン (bin)<br />ヒン (hin)</td>
<td>まず・しい (mazushii)</td>
<td>
Poor
<br />
<table><tbody><tr><td>Examples:
<br />
貧乏 (びんぼう - binbou - poverty, destitute, poor) [dn] <Play audio="binbou" />
<br />
貧血 (ひんけつ - hinketsu - anemia) [n] <Play audio="hinketsu" />
<br />
貧しい (まずしい - mazushii - poor, needy) [dv] <Play audio="mazushii" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>責</span></th>
<td>セキ (seki)</td>
<td>せ・める (semeru)<br />せ・め (seme)</td>
<td>
Blame
<br />
<table><tbody><tr><td>Examples:
<br />
責任 (せきにん - sekinin - duty, responsibility (incl. supervision of staff), liability) [n] <Play audio="sekinin" />
<br />
責める (せめる - semeru - to condemn, to blame, to criticize, to reproach, to accuse, to urge, to press, to pester, to torture, to torment, to persecute) [iv] <Play audio="semeru" />
<br />
責め (せめ - seme - persecution, blame, responsibility) [n] <Play audio="seme" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>貸</span></th>
<td>タイ (tai)</td>
<td>か・す (kasu)<br />か・し (kashi)</td>
<td>
Lend
<br />
<table><tbody><tr><td>Examples:
<br />
賃貸 (ちんたい - chintai - lease, rent) [n] <Play audio="chintai" />
<br />
貸す (かす - kasu - to lend, to loan, to rent out, to hire out) [gv] <Play audio="kasu" />
<br />
貸し (かし - kashi - loan, lending, favor, debt, obligation) [n] <Play audio="kashi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>貿</span></th>
<td>ボウ (bou)</td>
<td></td>
<td>
Trade
<br />
<table><tbody><tr><td>Examples:
<br />
貿易 (ぼうえき - boueki - trade (foreign)) [n] <Play audio="boueki" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>賀</span></th>
<td>ガ (ga)</td>
<td></td>
<td>
Congratulations
<br />
<table><tbody><tr><td>Examples:
<br />
年賀状 (ねんがじょう - nengajou - New Year's card) [n] <Play audio="nengajou" />
</td></tr></tbody></table></td>
</tr>
</tbody></table>

    </>
  )
}

export default GradeFiveFour