import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Grade 3 Kanji - Part 2 of 5"

const GradeThreeTwo = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Advanced" category="Kanji" lesson={title} />

      <h1>{title}</h1>

      <p>Below are Kanji the Japanese learn in grade 3 of elementary school.
Be sure to read the <Link to="/members/a/kanji/intro/">Introduction to Kanji</Link> if you haven't already.</p>

<p>Legend:</p>
<p>[n] = Noun</p>
<p>[dv] = Descriptive word (true adjective) - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[dn] = Descriptive noun - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[gv] = Godan verb - see <Link to="/members/b/grammar/godan-verbs/">Verb Conjugation (Godan)</Link></p>
<p>[iv] = Ichidan verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[irv] = Irregular verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[c] = Counter</p>

<table>
<thead>
  <tr>
<th>漢字<br /><Play audio="kanji" /></th>
<th>音読み <Play audio="onyomi" /><br />"On" Reading</th>
<th>訓読み <Play audio="kunyomi" /><br />"Kun" Reading</th>
<th>English Meaning</th>
</tr>
  </thead>
<tbody>
<tr>
<th><span style={{fontSize:`4rem`}}>央</span></th>
<td>オウ (ou)</td>
<td></td>
<td>
Center
<br />
<table><tbody><tr><td>Examples:
<br />
中央 (ちゅうおう - chuuou - central, center, middle) [n] <Play audio="chuuou" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>始</span></th>
<td>シ (shi)</td>
<td>はじ・める (hajimeru)<br />はじ・まる (hajimaru)<br />はじ・め (hajime)<br />はじ・まり (hajimari)</td>
<td>
Begin
<br />
<table><tbody><tr><td>Examples:
<br />
始末 (しまつ - shimatsu - management, dealing, settlement, cleaning up, getting rid of, end result) [n] <Play audio="shimatsu" />
<br />
始める (はじめる - hajimeru - to start, to begin, to commence, to initiate, to originate) [iv] <Play audio="hajimeru" />
<br />
始まる (はじまる - hajimaru - to begin) [gv] <Play audio="hajimaru" />
<br />
始め (はじめ - hajime - beginning, start, origin) [n] <Play audio="hajime" />
<br />
始まり (はじまり - hajimari - origin, beginning) [n] <Play audio="hajimari" />
<br />
始めまして (はじめまして - hajimemashite - "Nice to meet you") <Play audio="hajimemashite" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>委</span></th>
<td>イ (i)</td>
<td>ゆだ・ねる (yudaneru)</td>
<td>
Committee
<br />
<table><tbody><tr><td>Examples:
<br />
委員 (いいん - iin - committee member) [n] <Play audio="iin" />
<br />
委ねる (ゆだねる - yudaneru - to entrust to, to devote oneself to, to abandon oneself to) [iv] <Play audio="yudaneru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>守</span></th>
<td>シュ (shu)<br />ス (su)</td>
<td>まも・る (mamoru)<br />まも・り (mamori)<br />もり (mori)</td>
<td>
Protect
<br />
<table><tbody><tr><td>Examples:
<br />
守備 (しゅび - shubi - defense, defence) [n] <Play audio="shubi" />
<br />
留守 (るす - rusu - absence, being away from home) [n] <Play audio="rusu" />
<br />
留守番電話 (るすばんでんわ - rusubandenwa - answering machine) [n] <Play audio="rusubandenwa" />
<br />
守る (まもる - mamoru - to protect, to guard, to defend) [gv] <Play audio="mamoru" />
<br />
守り (まもり - mamori - protection, defense, defence) [n] <Play audio="mamori" />
<br />
守 (もり - mori - babysitting, babysitter, protecting, keeping, keeper) [n] <Play audio="mori" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>安</span></th>
<td>アン (an)</td>
<td>やす・い (yasui)<br />やす・らか (yasuraka)</td>
<td>
Cheap, Calm
<br />
<table><tbody><tr><td>Examples:
<br />
不安 (ふあん - fuan - anxiety, uneasiness, insecurity, suspense) [dn] <Play audio="fuan" />
<br />
安全 (あんぜん - anzen - safety, security) [dn] <Play audio="anzen" />
<br />
安い (やすい - yasui - cheap, inexpensive) [dv] <Play audio="yasui" />
<br />
安らか (やすらか - yasuraka - peaceful, tranquil, calm, restful) [dn] <Play audio="yasuraka" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>定</span></th>
<td>ジョウ (jou)<br />テイ (tei)</td>
<td>さだ・める (sadameru)<br />さだ・まる (sadamaru)</td>
<td>
Fixed, Set
<br />
<table><tbody><tr><td>Examples:
<br />
定規 (じょうぎ - jougi - (measuring) ruler) [n] <Play audio="jougi" />
<br />
定職 (ていしょく - teishoku - occupation (i.e. field of regular employment), steady job, regular work) [n] <Play audio="teishoku" />
<br />
定める (さだめる - sadameru - to decide, to establish, to determine) [iv] <Play audio="sadameru" />
<br />
定まる (さだまる - sadamaru - to become settled, to be fixed) [gv] <Play audio="sadamaru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>実</span></th>
<td>ジツ (jitsu)</td>
<td>み (mi)<br />みの・る (minoru)</td>
<td>
Fruit or Content
<br />
<table><tbody><tr><td>Examples:
<br />
忠実 (ちゅうじつ - chuujitsu - faithful, loyal, devoted) [dn] <Play audio="chuujitsu" />
<br />
実 (み - mi - fruit, nut, seed, content, substance) [n] <Play audio="mi" />
<br />
実る (みのる - minoru - to bear fruit, to ripen) [gv] <Play audio="minoru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>客</span></th>
<td>キャク (kyaku)<br />カク (kaku - rarely used)</td>
<td></td>
<td>
Guest
<br />
<table><tbody><tr><td>Examples:
<br />
観客 (かんきゃく - kankyaku - audience, spectator, spectators) [n] <Play audio="kankyaku" />
<br />
お客様 (おきゃくさま - okyakusama - guest, visitor, customer) [n] <Play audio="okyakusama" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>宮</span></th>
<td>ク (ku)<br />キュウ (kyuu)<br />グウ (guu)</td>
<td>みや (miya)</td>
<td>
Shinto Shrine or Prince (Princess)
<br />
<table><tbody><tr><td>Examples:
<br />
宮内庁 (くないちょう - kunaichou - Imperial Household Agency) [n] <Play audio="kunaichou" />
<br />
王宮 (おうきゅう - oukyuu - royal palace) [n] <Play audio="oukyuu" />
<br />
皇宮 (こうぐう - kouguu - the Imperial Palace) [n] <Play audio="kouguu" />
<br />
宮 (みや - miya - shrine, palace, imperial residence) [n] <Play audio="miya" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>宿</span></th>
<td>シュク (shuku)</td>
<td>やど (yado)<br />やど・る (yadoru)<br />やど・す (yadosu)</td>
<td>
Inn
<br />
<table><tbody><tr><td>Examples:
<br />
宿泊 (しゅくはく - shukuhaku - lodging) [n] <Play audio="shukuhaku" />
<br />
宿 (やど - yado - inn, lodging) [n] <Play audio="yado" />
<br />
宿る (やどる - yadoru - to lodge, to dwell, to be pregnant) [gv] <Play audio="yadoru" />
<br />
宿す (やどす - yadosu - to keep (a guest), to conceive, to carry (a virus), to entrust) [gv] <Play audio="yadosu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>寒</span></th>
<td>カン (kan)</td>
<td>さむ・い (samui)</td>
<td>
Cold
<br />
<table><tbody><tr><td>Examples:
<br />
三寒四温 (さんかんしおん - sankanshion - alternation of three cold and four warm days) [n] <Play audio="sankanshion" />
<br />
寒い (さむい - samui - cold (e.g. weather)) [dv] <Play audio="samui" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>対</span></th>
<td>ツイ (tsui)<br />タイ (tai)</td>
<td></td>
<td>
Opposite
<br />
<table><tbody><tr><td>Examples:
<br />
好一対 (こういっつい - kouittsui - well-matched) [dn] <Play audio="kouittsui" />
<br />
対決 (たいけつ - taiketsu - confrontation, showdown) [n] <Play audio="taiketsu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>局</span></th>
<td>キョク (kyoku)</td>
<td></td>
<td>
Office
<br />
<table><tbody><tr><td>Examples:
<br />
局面 (きょくめん - kyokumen - aspect of an affair, situation) [n] <Play audio="kyokumen" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>屋</span></th>
<td>オク (oku)</td>
<td>や (ya)</td>
<td>
Premise
<br />
<table><tbody><tr><td>Examples:
<br />
屋外 (おくがい - okugai - outdoors, alfresco) [n] <Play audio="okugai" />
<br />
屋根 (やね - yane - roof) [n] <Play audio="yane" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>岸</span></th>
<td>ガン (gan)</td>
<td>きし (kishi)</td>
<td>
Shore
<br />
<table><tbody><tr><td>Examples:
<br />
岸壁 (がんぺき - ganpeki - wharf, breakwater, steep cliff) [n] <Play audio="ganpeki" />
<br />
岸 (きし - kishi - bank, coast, shore) [n] <Play audio="kishi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>島</span></th>
<td>トウ (tou)</td>
<td>しま (shima)</td>
<td>
Island
<br />
<table><tbody><tr><td>Examples:
<br />
諸島 (しょとう - shotou - archipelago, group of islands) [n] <Play audio="shotou" />
<br />
島 (しま - shima - island) [n] <Play audio="shima" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>州</span></th>
<td>シュウ (shuu)<br />ス (su)</td>
<td></td>
<td>
State
<br />
<table><tbody><tr><td>Examples:
<br />
五大州 (ごだいしゅう - godaishuu - the Five Continents) [n] <Play audio="godaishuu" />
<br />
中州 (なかす - nakasu - sandbank (in a river), sandbar) [n] <Play audio="nakasu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>帳</span></th>
<td>チョウ (chou)</td>
<td></td>
<td>
Account Book
<br />
<table><tbody><tr><td>Examples:
<br />
手帳 (てちょう - techou - notebook, memo pad) [n] <Play audio="techou" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>平</span></th>
<td>ビョウ (byou)<br />ヘイ (hei)</td>
<td>たい・ら (taira)<br />たい・らげる (tairageru)<br />ひら (hira)<br />ひら・に (hirani)<br />ひら・たい (hiratai)</td>
<td>
Flat
<br />
<table><tbody><tr><td>Examples:
<br />
平等 (びょうどう - byoudou - equality, impartiality, evenness) [dn] <Play audio="byoudou" />
<br />
平然 (へいぜん - heizen - calm, composed, cool, quiet) <Play audio="heizen" />
<br />
平ら (たいら - taira - flatness, level, smooth, calm, plain) [dn] <Play audio="taira" />
<br />
平らげる (たいらげる - tairageru - to subjugate, to put down (trouble), to consume (food), to eat up) [iv] <Play audio="tairageru" />
<br />
平 (ひら - hira - something broad and flat, palm of the hand, common, ordinary) [n] <Play audio="hira" />
<br />
平に (ひらに - hirani - earnestly, humbly) [adverb] <Play audio="hirani" />
<br />
平たい (ひらたい - hiratai - flat, even, level, simple, plain (speech, etc.)) [dv] <Play audio="hiratai" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>幸</span></th>
<td>コウ (kou)</td>
<td>さいわ・い (saiwai)<br />さち (sachi)<br />しあわ・せ (shiawase)</td>
<td>
Happiness
<br />
<table><tbody><tr><td>Examples:
<br />
幸運 (こううん - kouun - good luck, fortune) [dn] <Play audio="kouun" />
<br />
幸い (さいわい - saiwai - happiness, blessedness) [dn] <Play audio="saiwai" />
<br />
幸 (さち - sachi - good luck, fortune, happiness) [n] <Play audio="sachi" />
<br />
幸せ (しあわせ - shiawase - happiness, good fortune, luck, blessing) [dn] <Play audio="shiawase" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>度</span></th>
<td>ド (do)<br />ト (to)<br />タク (taku)</td>
<td>たび (tabi)</td>
<td>
Degrees
<br />
<table><tbody><tr><td>Examples:
<br />
温度 (おんど - ondo - temperature) [n] <Play audio="ondo" />
<br />
屹度 (きっと - kitto - surely, undoubtedly, almost certainly, most likely - usually written in hiragana) <Play audio="kitto" />
<br />
支度 (したく - shitaku - preparation, arrangements) [n] <Play audio="shitaku" />
<br />
度 (たび - tabi - time (three times, each time, etc.), times) [n][c] <Play audio="tabi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>庫</span></th>
<td>コ (ko)<br />ク (ku)</td>
<td></td>
<td>
Warehouse
<br />
<table><tbody><tr><td>Examples:
<br />
金庫 (きんこ - kinko - safe, vault, treasury, provider of funds) [n] <Play audio="kinko" />
<br />
庫裏 (くり - kuri - monastery kitchen, priests' quarters) [n] <Play audio="kuri" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>庭</span></th>
<td>テイ (tei)</td>
<td>にわ (niwa)</td>
<td>
Garden
<br />
<table><tbody><tr><td>Examples:
<br />
家庭 (かてい - katei - home, family, household) [n] <Play audio="katei" />
<br />
庭 (にわ - niwa - garden) [n] <Play audio="niwa" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>式</span></th>
<td>シキ (shiki)</td>
<td></td>
<td>
Style
<br />
<table><tbody><tr><td>Examples:
<br />
形式 (けいしき - keishiki - form (as opposed to substance), method, system, style) [n] <Play audio="keishiki" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>役</span></th>
<td>ヤク (yaku)<br />エキ (eki)</td>
<td></td>
<td>
Role
<br />
<table><tbody><tr><td>Examples:
<br />
役割 (やくわり - yakuwari - part, assigning (allotment of) parts, role, duties) [n] <Play audio="yakuwari" />
<br />
現役 (げんえき - geneki (gen'eki) - active duty, active service) [n] <Play audio="gen-eki" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>待</span></th>
<td>タイ (tai)</td>
<td>ま・つ (matsu)</td>
<td>
Wait
<br />
<table><tbody><tr><td>Examples:
<br />
待機　(たいき - taiki - alert, standby, await an opportunity, wait for orders) [n] <Play audio="taiki" />
<br />
待つ　(まつ - matsu - to wait, to await, to look forward to, to anticipate) [gv] <Play audio="matsu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>急</span></th>
<td>キュウ (kyuu)</td>
<td>いそ・ぐ　(isogu)</td>
<td>
Hurry
<br />
<table><tbody><tr><td>Examples:
<br />
急用 (きゅうよう - kyuuyou - urgent business) [n] <Play audio="kyuuyou" />
<br />
急ぐ (いそぐ - isogu - to hurry, to rush, to hasten, to make something happen sooner) [gv] <Play audio="isogu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>息</span></th>
<td>ソク (soku)</td>
<td>いき (iki)<br />いき・む (ikimu)</td>
<td>
Breath
<br />
<table><tbody><tr><td>Examples:
<br />
利息 (りそく - risoku - interest (on a loan)) [n] <Play audio="risoku" />
<br />
息 (いき - iki - breath, breathing) [n] <Play audio="iki" />
<br />
息む (いきむ - ikimu - to strain (e.g. with constipation), to bear or push down (during childbirth)) [gv] <Play audio="ikimu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>悪</span></th>
<td>オ (o)<br />アク (aku)</td>
<td>わる・い (warui)</td>
<td>
Bad
<br />
<table><tbody><tr><td>Examples:
<br />
嫌悪 (けんお - keno (ken'o) - disgust, hate, repugnance) [n] <Play audio="ken-o" />
<br />
最悪 (さいあく - saiaku - the worst) [dn] <Play audio="saiaku" />
<br />
悪い (わるい - warui - bad, poor, inferior, evil, sinful, unprofitable, unbeneficial, at fault, to blame, in the wrong) [dv] <Play audio="warui" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>悲</span></th>
<td>ヒ (hi)</td>
<td>かな・しい (kanashii)<br />かな・しむ (kanashimu)<br />かな・しみ (kanashimi)</td>
<td>
Sad
<br />
<table><tbody><tr><td>Examples:
<br />
悲鳴 (ひめい - himei - shriek, scream) [n] <Play audio="himei" />
<br />
悲しい (かなしい - kanashii - sad, sorrowful) [dv] <Play audio="kanashii" />
<br />
悲しむ (かなしむ - kanashimu - to be sad, to mourn for, to regret) [gv] <Play audio="kanashimu" />
<br />
悲しみ (かなしみ - kanashimi - sadness, sorrow, grief) [n] <Play audio="kanashimi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>想</span></th>
<td>ソウ (sou)<br />ソ (so)</td>
<td></td>
<td>
Thought
<br />
<table><tbody><tr><td>Examples:
<br />
感想 (かんそう - kansou - impressions, thoughts) [n] <Play audio="kansou" />
<br />
愛想 (あいそ - aiso - civility, courtesy, compliments, sociability, graces) [n] <Play audio="aiso" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>意</span></th>
<td>イ (i)</td>
<td></td>
<td>
Idea
<br />
<table><tbody><tr><td>Examples:
<br />
意味 (いみ - imi - meaning, significance) [n] <Play audio="imi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>感</span></th>
<td>カン (kan)</td>
<td></td>
<td>
Feel
<br />
<table><tbody><tr><td>Examples:
<br />
敏感 (びんかん - binkan - sensibility, susceptibility, sensitive (to), well attuned to) [dn] <Play audio="binkan" />
<br />
感じる (かんじる - kanjiru - to feel, to sense, to experience) [iv] <Play audio="kanjiru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>所</span></th>
<td>ショ (sho)</td>
<td>ところ (tokoro)</td>
<td>
Place
<br />
<table><tbody><tr><td>Examples:
<br />
長所 (ちょうしょ - chousho - strong point, merit, virtue, advantage) [n] <Play audio="chousho" />
<br />
所 (ところ - tokoro - place, spot, scene, site, district, area, locality) [n] <Play audio="tokoro" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>打</span></th>
<td>ダ (da)</td>
<td>う・つ (utsu)</td>
<td>
Hit
<br />
<table><tbody><tr><td>Examples:
<br />
打撲 (だぼく - daboku - blow, hit (on the body), beating) [n] <Play audio="daboku" />
<br />
打つ (うつ - utsu - to hit (something inanimate), to strike, to beat (on something), to type, to tap (e.g. a key)) [gv] <Play audio="utsu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>投</span></th>
<td>トウ (tou)</td>
<td>な・げる (nageru)</td>
<td>
Throw
<br />
<table><tbody><tr><td>Examples:
<br />
投手 (とうしゅ - toushu - (baseball) pitcher) [n] <Play audio="toushu" />
<br />
投げる (なげる - nageru - to throw, to cast away) [iv] <Play audio="nageru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>拾</span></th>
<td>ジュウ (juu)<br />シュウ (shuu)</td>
<td>ひろ・う (hirou)</td>
<td>
Pick Up
<br />
<table><tbody><tr><td>Examples:
<br />
拾万円 (じゅうまんえん - juumanen (juuman'en) - 100,000 Yen) [n] <Play audio="juuman-en" />
<br />
収拾 (しゅうしゅう - shuushuu - control, settling, coping) [n] <Play audio="shuushuu" />
<br />
拾う (ひろう - hirou - to pick up, to find, to gather) [gv] <Play audio="hirou" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>持</span></th>
<td>ジ (ji)</td>
<td>も・つ (motsu)</td>
<td>
Hold
<br />
<table><tbody><tr><td>Examples:
<br />
持参 (じさん - jisan - bringing, taking, carrying) [n] <Play audio="jisan" />
<br />
持つ (もつ - motsu - to hold, to carry, to possess) [gv] <Play audio="motsu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>指</span></th>
<td>シ (shi)</td>
<td>ゆび (yubi)<br />さ・す (sasu)</td>
<td>
Finger
<br />
<table><tbody><tr><td>Examples:
<br />
指圧 (しあつ - shiatsu - finger pressure massage or therapy, acupressure) [n] <Play audio="shiatsu" />
<br />
指 (ゆび - yubi - finger, toe, digit) [n] <Play audio="yubi" />
<br />
指す (さす - sasu - to point, to nominate, to select someone, to specify some person, to identify, to indicate, to point out) [gv] <Play audio="sasu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>放</span></th>
<td>ホウ (hou)</td>
<td>はな・す (hanasu)<br />はな・つ (hanatsu)<br />はな・れる (hanareru)</td>
<td>
Release
<br />
<table><tbody><tr><td>Examples:
<br />
放置 (ほうち - houchi - leave as is, leave to chance, leave alone, neglect) [n] <Play audio="houchi" />
<br />
放す (はなす - hanasu - to separate, to set free, to turn loose) [gv] <Play audio="hanasu" />
<br />
放つ (はなつ - hanatsu - to fire (e.g. an arrow), to hit (e.g. baseball), to free, to release) [gv] <Play audio="hanatsu" />
<br />
放れる (はなれる - hanareru - to leave, to get free, to cut oneself off) [iv] <Play audio="hanareru" />
</td></tr></tbody></table></td>
</tr>
</tbody></table>

    </>
  )
}

export default GradeThreeTwo