import React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import NavBar from "../../components/NavBar"
import Table from "@components/Table"
import Play from "@components/Play"

const title = "Grade 4 Kanji - Part 5 of 5"

const GradeFourFive = () => {

  return (
    <>
    <Helmet htmlAttributes={{
    lang: 'en',
    }}>
    {title ? <title>{title}</title> : null }
    {title ? <meta name="title" content={title} /> : null}
    </Helmet>
    <NavBar level="Advanced" category="Kanji" lesson={title} />
    
      <h1>{title}</h1>

      <p>Below are Kanji the Japanese learn in grade 4 of elementary school.
Be sure to read the <Link to="/members/a/kanji/intro/">Introduction to Kanji</Link> if you haven't already.</p>

<p>Legend:</p>
<p>[n] = Noun</p>
<p>[dv] = Descriptive word (true adjective) - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[dn] = Descriptive noun - see <Link to="/members/b/grammar/descriptive01/">Descriptive Words - Part 1</Link></p>
<p>[gv] = Godan verb - see <Link to="/members/b/grammar/godan-verbs/">Verb Conjugation (Godan)</Link></p>
<p>[iv] = Ichidan verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[irv] = Irregular verb - see <Link to="/members/b/grammar/ichidan-verbs/">Verb Conjugation (Ichidan and Irregular)</Link></p>
<p>[c] = Counter</p>

<table>
<thead>
  <tr>
<th>漢字<br /><Play audio="kanji" /></th>
<th>音読み <Play audio="onyomi" /><br />"On" Reading</th>
<th>訓読み <Play audio="kunyomi" /><br />"Kun" Reading</th>
<th>English Meaning</th>
</tr>
  </thead>
<tbody>
<tr>
<th><span style={{fontSize:`4rem`}}>芽</span></th>
<td>ガ (ga)</td>
<td>め (me)</td>
<td>
Bud
<br />
<table><tbody><tr><td>Examples:
<br />
発芽 (はつが - hatsuga - burgeoning) [n] <Play audio="hatsuga" />
<br />
芽 (め - me - sprout) [n] <Play audio="me" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>英</span></th>
<td>エイ (ei)</td>
<td></td>
<td>
England
<br />
<table><tbody><tr><td>Examples:
<br />
英雄 (えいゆう - eiyuu - hero, heroine, great person) [n] <Play audio="eiyuu" />
<br />
英語 (えいご - eigo - English (language)) [n] <Play audio="eigo" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>菜</span></th>
<td>サイ (sai)</td>
<td>な (na)</td>
<td>
Vegetable
<br />
<table><tbody><tr><td>Examples:
<br />
野菜 (やさい - yasai - vegetable) [n] <Play audio="yasai" />
<br />
菜種梅雨 (なたねづゆ - natanezuyu - long spell of rain in early spring) [n] <Play audio="natanezuyu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>街</span></th>
<td>カイ (kai)<br />ガイ (gai)</td>
<td>まち (machi)</td>
<td>
City
<br />
<table><tbody><tr><td>Examples:
<br />
街道 (かいどう - kaidou - highway, main road) [n] <Play audio="kaidou" />
<br />
街頭 (がいとう - gaitou - in the street) [n] <Play audio="gaitou" />
<br />
街 (まち - machi - town, block, neighborhood, street, road) [n] <Play audio="machi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>衣</span></th>
<td>イ (i)</td>
<td>ころも (koromo)</td>
<td>
Clothes
<br />
<table><tbody><tr><td>Examples:
<br />
衣服 (いふく - ifuku - clothes) [n] <Play audio="ifuku" />
<br />
衣 (ころも - koromo - clothes, garment, gown, robe) [n] <Play audio="koromo" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>要</span></th>
<td>ヨウ (you)</td>
<td>い・る (iru)</td>
<td>
Need
<br />
<table><tbody><tr><td>Examples:
<br />
主要 (しゅよう - shuyou - chief, main, principal, major) [dn] <Play audio="shuyou" />
<br />
必要 (ひつよう - hitsuyou - necessary, needed, essential, indispensable, necessity, need, requirement) [dn] <Play audio="hitsuyou" />
<br />
要る (いる - iru - to need, to want) [gv] <Play audio="iru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>覚</span></th>
<td>カク (kaku)</td>
<td>おぼ・える (oboeru)<br />さ・ます (samasu)<br />さ・める (sameru)</td>
<td>
Memorize
<br />
<table><tbody><tr><td>Examples:
<br />
自覚 (じかく - jikaku - self-consciousness, self-awareness) [n] <Play audio="jikaku" />
<br />
覚える (おぼえる - oboeru - to memorize, to commit to memory, to learn by heart, to bear in mind, to remember) [iv] <Play audio="oboeru" />
<br />
覚ます (さます - samasu - to awaken) [gv] <Play audio="samasu" />
<br />
覚める (さめる - sameru - to wake, to wake up) [iv] <Play audio="sameru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>観</span></th>
<td>カン (kan)</td>
<td>み・る (miru)</td>
<td>
Observe
<br />
<table><tbody><tr><td>Examples:
<br />
観光 (かんこう - kankou - sightseeing) [n] <Play audio="kankou" />
<br />
観る (みる - miru - to observe, to view (e.g. flowers, movie)) [iv] <Play audio="miru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>訓</span></th>
<td>クン (kun)</td>
<td></td>
<td>
Instruction
<br />
<table><tbody><tr><td>Examples:
<br />
訓練 (くんれん - kunren - practice, training) [n] <Play audio="kunren" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>試</span></th>
<td>シ (shi)</td>
<td>こころ・みる (kokoromiru)<br />ため・す (tamesu)</td>
<td>
Test
<br />
<table><tbody><tr><td>Examples:
<br />
試験 (しけん - shiken - examination, test, study, trial, experiment) [n] <Play audio="shiken" />
<br />
試みる (こころみる - kokoromiru - to try, to test) [iv] <Play audio="kokoromiru" />
<br />
試す (ためす - tamesu - to attempt, to test, to try out) [gv] <Play audio="tamesu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>説</span></th>
<td>セツ (setsu)<br />ゼイ (zei)</td>
<td>と・く (toku)</td>
<td>
Theory
<br />
<table><tbody><tr><td>Examples:
<br />
小説 (しょうせつ - shousetsu - novel, (short) story) [n] <Play audio="shousetsu" />
<br />
遊説 (ゆうぜい - yuuzei - election tour, election campaign, stumping) [n] <Play audio="yuuzei" />
<br />
説く (とく - toku - to explain, to advocate, to preach, to persuade) [gv] <Play audio="toku" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>課</span></th>
<td>カ (ka)</td>
<td></td>
<td>
Section
<br />
<table><tbody><tr><td>Examples:
<br />
課税 (かぜい - kazei - taxation) [n] <Play audio="kazei" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>議</span></th>
<td>ギ (gi)</td>
<td></td>
<td>
Deliberation
<br />
<table><tbody><tr><td>Examples:
<br />
議論 (ぎろん - giron - argument, discussion, dispute, controversy) [n] <Play audio="giron" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>象</span></th>
<td>ゾウ (zou)<br />ショウ (shou)</td>
<td></td>
<td>
Elephant
<br />
<table><tbody><tr><td>Examples:
<br />
象 (ぞう - zou - elephant) [n] <Play audio="zou" />
<br />
象牙 (ぞうげ - zouge - ivory) [n] <Play audio="zouge" />
<br />
象徴 (しょうちょう - shouchou - symbol) [n] <Play audio="shouchou" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>貨</span></th>
<td>カ (ka)</td>
<td></td>
<td>
Freight
<br />
<table><tbody><tr><td>Examples:
<br />
貨幣 (かへい - kahei - money, currency, coinage) [n] <Play audio="kahei" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>貯</span></th>
<td>チョ (cho)</td>
<td>た・める (tameru)</td>
<td>
Savings
<br />
<table><tbody><tr><td>Examples:
<br />
貯金 (ちょきん - chokin - (bank) savings) [n] <Play audio="chokin" />
<br />
貯める (ためる - tameru - to save (i.e. in a bank)) [iv] <Play audio="tameru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>費</span></th>
<td>ヒ (hi)</td>
<td>つい・やす (tsuiyasu)<br />つい・える (tsuieru)<br />つい・え (tsuie)</td>
<td>
Expense
<br />
<table><tbody><tr><td>Examples:
<br />
食費 (しょくひ - shokuhi - food expense) [n] <Play audio="shokuhi" />
<br />
費やす (ついやす - tsuiyasu - to spend, to devote, to waste) [gv] <Play audio="tsuiyasu" />
<br />
費える (ついえる - tsuieru - to use up (one's savings), to be wasted (time, effort, etc)) [iv] <Play audio="tsuieru" />
<br />
費え (ついえ - tsuie - wasteful expenses) [n] <Play audio="tsuie" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>賞</span></th>
<td>ショウ (shou)</td>
<td></td>
<td>
Prize
<br />
<table><tbody><tr><td>Examples:
<br />
賞与 (しょうよ - shouyo - reward, prize, bonus) [n] <Play audio="shouyo" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>軍</span></th>
<td>グン (gun)</td>
<td></td>
<td>
Army
<br />
<table><tbody><tr><td>Examples:
<br />
軍隊 (ぐんたい - guntai - army, troops) [n] <Play audio="guntai" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>輪</span></th>
<td>リン (rin)</td>
<td>わ (wa)</td>
<td>
Wheel
<br />
<table><tbody><tr><td>Examples:
<br />
五輪 (ごりん - gorin - the Olympics) [n] <Play audio="gorin" />
<br />
輪郭 (りんかく - rinkaku - contour, outline, border, silhouette, summary, sketch, looks, features, appearance) [n] <Play audio="rinkaku" />
<br />
指輪 (ゆびわ - yubiwa - (finger) ring) [n] <Play audio="yubiwa" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>辞</span></th>
<td>ジ (ji)</td>
<td>や・める (yameru)</td>
<td>
Resign
<br />
<table><tbody><tr><td>Examples:
<br />
辞職 (じしょく - jishoku - resignation) [n] <Play audio="jishoku" />
<br />
辞典 (じてん - jiten - dictionary) [n] <Play audio="jiten" />
<br />
辞める (やめる - yameru - to resign, to retire, to quit, to leave (one's job, etc.)) [iv] <Play audio="yameru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>辺</span></th>
<td>ヘン (hen)</td>
<td>あた・り (atari)<br />べ (be)</td>
<td>
Environs
<br />
<table><tbody><tr><td>Examples:
<br />
周辺 (しゅうへん - shuuhen - circumference, outskirts, environs, around, in the area of, in the vicinity of) [n] <Play audio="shuuhen" />
<br />
辺り (あたり - atari - (in the) neighborhood, neighborhood, vicinity, nearby) [n] <Play audio="atari" />
<br />
海辺 (うみべ - umibe - beach, seashore) [n] <Play audio="umibe" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>連</span></th>
<td>レン (ren)</td>
<td>つら・なる (tsuranaru)<br />つら・ねる (tsuraneru)<br />つ・れる (tsureru)</td>
<td>
Take Along
<br />
<table><tbody><tr><td>Examples:
<br />
関連 (かんれん - kanren - relation, connection, relevance) [n] <Play audio="kanren" />
<br />
連なる (つらなる - tsuranaru - to extend, to stretch out, to stand in a row) [gv] <Play audio="tsuranaru" />
<br />
連ねる (つらねる - tsuraneru - to line up, to put in a row) [iv] <Play audio="tsuraneru" />
<br />
連れる (つれる - tsureru - to lead, to take (a person)) [iv] <Play audio="tsureru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>達</span></th>
<td>タツ (tatsu)<br />タチ (tachi)</td>
<td></td>
<td>
Attain
<br />
<table><tbody><tr><td>Examples:
<br />
達人 (たつじん - tatsujin - master, expert) [n] <Play audio="tatsujin" />
<br />
私達 (わたしたち - watashitachi - we, us) [n] <Play audio="watashitachi" />
<br />
友達 (ともだち - tomodachi - friend, companion) [n] <Play audio="tomodachi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>選</span></th>
<td>セン (sen)</td>
<td>えら・ぶ (erabu)</td>
<td>
Choose
<br />
<table><tbody><tr><td>Examples:
<br />
選択 (せんたく - sentaku - selection, choice, option) [n] <Play audio="sentaku" />
<br />
選ぶ (えらぶ - erabu - to choose, to select) [gv] <Play audio="erabu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>郡</span></th>
<td>グン (gun)</td>
<td></td>
<td>
County
<br />
<table><tbody><tr><td>Examples:
<br />
郡 (ぐん - gun - district, county) [n] <Play audio="gun" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>量</span></th>
<td>リョウ (ryou)</td>
<td></td>
<td>
Quantity
<br />
<table><tbody><tr><td>Examples:
<br />
重量 (じゅうりょう - juuryou - weight) [n] <Play audio="juuryou" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>録</span></th>
<td>ロク (roku)</td>
<td></td>
<td>
Record
<br />
<table><tbody><tr><td>Examples:
<br />
記録 (きろく - kiroku - record, document) [n] <Play audio="kiroku" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>鏡</span></th>
<td>キョウ (kyou)</td>
<td>かがみ (kagami)</td>
<td>
Mirror
<br />
<table><tbody><tr><td>Examples:
<br />
鏡台 (きょうだい - kyoudai - dresser) [n] <Play audio="kyoudai" />
<br />
鏡 (かがみ - kagami - mirror) [n] <Play audio="kagami" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>関</span></th>
<td>カン (kan)</td>
<td>せき (seki)</td>
<td>
Related
<br />
<table><tbody><tr><td>Examples:
<br />
関係 (かんけい - kankei - relation, relationship, connection) [n] <Play audio="kankei" />
<br />
玄関 (げんかん - genkan - entranceway, entry hall, vestibule, foyer, entryway, mud room) [n] <Play audio="genkan" />
<br />
関 (せき - seki - barrier, gate) [n] <Play audio="seki" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>陸</span></th>
<td>リク (riku)</td>
<td></td>
<td>
Land
<br />
<table><tbody><tr><td>Examples:
<br />
大陸 (たいりく - tairiku - continent) [n] <Play audio="tairiku" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>隊</span></th>
<td>タイ (tai)</td>
<td></td>
<td>
Group
<br />
<table><tbody><tr><td>Examples:
<br />
兵隊 (へいたい - heitai - soldier, sailor) [n] <Play audio="heitai" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>静</span></th>
<td>ジョウ (jou)<br />セイ (sei)</td>
<td>しず (shizu)<br />しず・か (shizuka)<br />しず・まる (shizumaru)<br />しず・める (shizumeru)</td>
<td>
Quiet
<br />
<table><tbody><tr><td>Examples:
<br />
静脈 (じょうみゃく - joumyaku - vein) [n] <Play audio="joumyaku" />
<br />
静寂 (せいじゃく - seijaku - silence, stillness, quietness) [dn] <Play audio="seijaku" />
<br />
静岡 (しずおか - shizuoka - Shizuoka (prefecture and city)) [n] <Play audio="shizuoka" />
<br />
静か (しずか - shizuka - quiet, peaceful) [dn] <Play audio="shizuka" />
<br />
静まる (しずまる - shizumaru - to quieten down, to calm down, to subside, to die down, to abate, to be suppressed) [gv] <Play audio="shizumaru" />
<br />
静める (しずめる - shizumeru - to appease, to suppress, to calm) [iv] <Play audio="shizumeru" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>順</span></th>
<td>ジュン (jun)</td>
<td></td>
<td>
Obey
<br />
<table><tbody><tr><td>Examples:
<br />
順番 (じゅんばん - junban - turn (in line), order of things, sequential order) [n] <Play audio="junban" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>願</span></th>
<td>ガン (gan)</td>
<td>ねが・う (negau)</td>
<td>
Request
<br />
<table><tbody><tr><td>Examples:
<br />
願望 (がんぼう - ganbou - desire, wish, aspiration) [n] <Play audio="ganbou" />
<br />
願う (ねがう - negau - to desire, to wish, to hope, to beg, to request, to implore) [gv] <Play audio="negau" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>類</span></th>
<td>ルイ (rui)</td>
<td></td>
<td>
Sort
<br />
<table><tbody><tr><td>Examples:
<br />
種類 (しゅるい - shurui - variety, kind, type, category) [n] <Play audio="shurui" />
<br />
書類 (しょるい - shorui - documents, official papers) [n] <Play audio="shorui" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>飛</span></th>
<td>ヒ (hi)</td>
<td>と・ぶ (tobu)<br />と・ばす (tobasu)</td>
<td>
Fly
<br />
<table><tbody><tr><td>Examples:
<br />
飛行機 (ひこうき - hikouki - airplane, aircraft) [n] <Play audio="hikouki" />
<br />
飛ぶ (とぶ - tobu - to fly, to soar) [gv] <Play audio="tobu" />
<br />
飛ばす (とばす - tobasu - to fly, to fire, to hurl, to launch, to skip over, to omit) [gv] <Play audio="tobasu" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>飯</span></th>
<td>ハン (han)</td>
<td>めし (meshi)</td>
<td>
Meal
<br />
<table><tbody><tr><td>Examples:
<br />
日常茶飯 (にちじょうさはん - nichijousahan - an everyday occurrence) [n] <Play audio="nichijousahan" />
<br />
飯 (めし - meshi - cooked rice, meal, food) [n] <Play audio="meshi" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>養</span></th>
<td>ヨウ (you)</td>
<td>やしな・う (yashinau)</td>
<td>
Foster
<br />
<table><tbody><tr><td>Examples:
<br />
栄養 (えいよう - eiyou - nutrition, nourishment) [n] <Play audio="eiyou" />
<br />
養う (やしなう - yashinau - to rear, to maintain, to support (e.g. family), to cultivate) [gv] <Play audio="yashinau" />
</td></tr></tbody></table></td>
</tr>
<tr>
<th><span style={{fontSize:`4rem`}}>験</span></th>
<td>ゲン (gen - rarely used)<br />ケン (ken)</td>
<td></td>
<td>
Test
<br />
<table><tbody><tr><td>Examples:
<br />
体験 (たいけん - taiken - personal experience) [n] <Play audio="taiken" />
<br />
試験 (しけん - shiken - examination, test, study, trial, experiment) [n] <Play audio="shiken" />
</td></tr></tbody></table></td>
</tr>
</tbody></table>

    </>
  )
}

export default GradeFourFive